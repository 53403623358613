import React from "react";
import styled, { css } from "styled-components";
import { YumiBaby, AddToCartButton, InnerGridTemplate } from "../assets";
import { oatCreamy, yumiOrange } from "../../../styledcomponents/colors";
import { H1 } from "../../../styledcomponents";
import "styled-components/macro";

export const LetsStart = ({ width, background = "white" }) => (
  <StartContainer background={background}>
    <InnerGridTemplate
      gridDimensions="0.3fr 0.8fr 1.5fr 0.3fr"
      leftChildren={(
        <React.Fragment>
          <img
            src={YumiBaby}
            alt="baby"
            css="width:400px; border-radius:8px;"
          />
        </React.Fragment>
)}
      rightStyling={css`
        @media (max-width: 800px) {
          height: 300px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <H1
            css={css`
              font-size: 80px;
              color: ${yumiOrange};
              padding-bottom: 40px;

              @media (max-width: 1200px) {
                font-size: 65px;
              }
              @media (max-width: 800px) {
                font-size: 55px;
              }
            `}
          >
            Let's start your babe on solids together.
          </H1>
          {width > 800 && AddToCartButton}
        </React.Fragment>
)}
    />
  </StartContainer>
);

const StartContainer = styled.div`
  width: 100%;
  height: 700px;
  overflow: hidden;
  align-items: center;
  display: flex;
  background: ${({ background }) => (background || "white")};
`;
