import React from "react";
import styled, { css } from "styled-components";
import {
  InnerGridTemplate,
  VarPack,
  LittleCarrot,
  LittleTomato,
  Berry,
  Banana
} from "../assets";
import { H1, yumiOrange, oatCreamy } from "../../../styledcomponents";
import "styled-components/macro";

export const FreshMadeWeekly = ({ width, image = VarPack }) => (
  <FreshContainer>
    {width > 800 ? (
      <InnerGridTemplate
        leftStyling={css`
          display: flex;
          align-items: center;
        `}
        leftChildren={(
          <React.Fragment>
            <H1
              css={css`
                font-size: 150px;
                color: ${yumiOrange};
                position: relative;
                line-height: 1em;
              `}
            >
              Freshly <br /> made <br /> weekly
              <img
                src={LittleCarrot}
                alt="carrot"
                css="position:absolute; margin-top:-388px;margin-left:-553px;"
              />
              <img
                src={LittleTomato}
                alt="tomate"
                css="position:absolute; margin-left:-274px;margin-top:-125px"
              />
              <img
                src={Berry}
                alt="berry"
                css="position:absolute; margin-top:-240px;margin-left:-152px;"
              />
              <img
                src={Banana}
                alt="ban"
                css="position:absolute; margin-top:50px;margin-left:-520px;"
              />
            </H1>
          </React.Fragment>
)}
        rightChildren={(
          <React.Fragment>
            <img src={image} css="width:490px; height:513px" alt="variety" />
          </React.Fragment>
)}
        gridDimensions="0.3fr 1fr 1fr 0.3fr"
      />
    ) : (
      <InnerGrid gridDimensions="0.3fr 1fr 1fr 0.3fr">
        <div />
        <Left>
          <img
            src={image}
            css="width:300px; height:303px; margin-left:-100px;"
            alt="variety"
          />
        </Left>
        <Right>
          {" "}
          <H1
            css={css`
              font-size: 70px;
              color: ${yumiOrange};
              position: relative;
              line-height: 1em;
              margin-left: -80px;
              margin-top: 50px;
            `}
          >
            Freshly <br /> made <br /> weekly
            <img
              src={LittleCarrot}
              alt="carrot"
              css="position:absolute; margin-top:-168px;margin-left:-263px; width:100px;"
            />
            <img
              src={LittleTomato}
              alt="tomate"
              css="position:absolute; margin-left:-124px;margin-top:-55px; width:30px;"
            />
            <img
              src={Berry}
              alt="berry"
              css="position:absolute; margin-top:-120px;margin-left:-62px; width:30px;"
            />
            <img
              src={Banana}
              alt="ban"
              css="position:absolute; margin-top:50px;margin-left:-250px; width:60px;"
            />
          </H1>
        </Right>
        <div />
      </InnerGrid>
    )}
  </FreshContainer>
);

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ gridDimensions }) =>
    gridDimensions && gridDimensions};
`;
const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    padding-left: 15px;
    width: 95%;
  }
`;

const FreshContainer = styled.div`
  width: 100%;
  height: 605px;
  overflow: hidden;
  background: ${oatCreamy};
  align-items: center;
  display: flex;

  @media (max-width: 800px) {
    background: white;
    height: 405px;
  }
`;
