import styled from "styled-components";
import React from "react";

export const Children = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  font-family: "Avenir", "Helvetica Neue", sans-serif;
  font-weight: lighter;
`;
export const InputField = styled.input`
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.07);
  border-width: 1px;
  margin-top: 5px;
  margin-bottom: 32px;
  padding-bottom: 5px;
  line-height: 1;
  font-size: 15px;
  font-family: "Avenir", sans-serif;
  opacity: 1;
  text-align: left;
  font-weight: 400;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalButton = styled.input`
  color: ${props => (props.cancel ? "black" : "white")};
  background: ${props => (props.cancel ? "white" : "#241A1B")};
  border-radius: 50px;
  cursor: pointer;
  border-color: ${props => (props.cancel ? "rgba(0,0,0,.08)" : "grey")};
  border-style: solid;
  flex-basis: 47%;
  font-weight: 100;
  border-width: 1px;
  padding: auto;
  height: 53px;
  font-size: 14px;
  font-family: "Platform-Medium", sans-serif;

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;
export const XButton = styled.input`
  align-self: flex-end;
  cursor: pointer;
`;

const CardStyle = styled.button`
  border-style: solid;
  border-color: grey;
  background: white;
  margin-left: 7.5px;
  margin-right: 7.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-basis: auto;
  cursor: pointer;
  font-family: "Avenir", sans-serif;
  border: 1px solid #e5e8ed;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 346px;
  &:hover {
    border-color: blue;
  }
  flex-grow: 1;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 7px;
  padding-right: 7px;
  @media (max-width: 1000px) {
    max-width: 300px;
  }
`;
export const Card = ({ icon, children, onClick }) => (
  <CardStyle onClick={onClick}>
    <div style={{ display: "flex", marginLeft: "32px", marginRight: "32px" }}>
      <img src={icon} alt={icon} width="15px" height="15px" />
    </div>
    <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
  </CardStyle>
);
export const CardTitle = styled.p`
  font-size: 15px;
  font-family: "Platform-medium", sans-serif;
  text-align: left;
  line-height: 1;
  padding: 0;
  margin: 0;
`;
export const CardSubtitle = styled.p`
  font-size: 15px;
  font-family: "Avenir-medium", sans-serif;
  color: grey;
  text-align: left;
  line-height: 1;
  padding: 0;
  margin: 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: space-between;
  justify-content: space-between;
  font-weight: lighter;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    width: 300px;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    margin-top: auto;
  }
`;

export const CardContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1100px;
  justify-content: center;
  margin-top: 128px;
  margin-bottom: 64px;

  @media (max-width: 1000px) {
    margin-top: 33px;
    margin-bottom: 12px;
  }
`;

export const Label = styled.label`
  margin-bottom: 2.5px;
  margin-top: 5px;
  color: grey;
  font-family: "Avenir medium", sans-serif;
  opacity: 0.4;
  font-size: 15px;

  @media (max-width: 500px) {
    margin-top: 32px;
  }
`;

export const Page = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f2f2f2;
  min-height: 90vh;
`;
const EditHeaderText = styled.span`
  font-size: 24px;
  font-family: "Platform-Medium", sans-serif;
`;
const EditHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const EditHeader = ({ close, children }) => (
  <React.Fragment>
    <EditHeaderContainer>
      <EditHeaderText>{children}</EditHeaderText>
      <XButton
        onClick={e => {
          e.stopPropagation();
          close();
        }}
        type="button"
        value="x"
      />
    </EditHeaderContainer>

    <div
      style={{
        width: "100%",
        height: "1px",
        position: "relative",
        margin: "16px 0 32px"
      }}
    >
      <div
        style={{
          width: "558px",
          height: "1px",
          backgroundColor: "rgba(0,0,0,.07)",
          positon: "absolute",
          marginLeft: "-32px"
        }}
      >
        {" "}
      </div>
    </div>
  </React.Fragment>
);

export const PlanCardContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PlanCard = styled.div`
  display: flex;
  background: ${props => props.color};
  flex-basis: 300px;
  margin-left: 15px;
  margin-right: 15px;
  flex-direction: column;
  padding-top: 86px;
  padding-bottom: 86px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.5s ease;
  transform: ${props =>
    props.selected ? "translateY(-3px) scale(1.005) translateZ(0)" : "unset"};
  box-shadow: ${props =>
    props.selected ? "0 6px 40px 0 rgba(0, 0, 0, 0.4)" : "unset"};

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 500px;
    margin-top: 20px;
    padding: 32px;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
`;
export const PlanSelectButton = styled.button`
  background: white;
  height: 53px;
  width: 200px;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 50px;
  font-family: "Platform-medium", sans-serif;
  font-size: 14px;
  color: ${props => props.color};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: inherit;
    color: white;
    border: 1px solid white;
  }

  @media (max-width: 1000px) {
    width: 80%;
    margin-top: 25px;
  }
`;

export const PlanSize = styled.p`
  font-family: "Platform-Medium", sans-serif;
  margin-bottom: 50px;
  color: white;
  font-size: 32px;
  @media (max-width: 1000px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
`;
export const PlanWeeklyTotal = styled.span`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: white;

  @media (max-width: 1000px) {
    font-size: 14px;
    font-family: "Avenir medium", sans-serif;
  }
`;
export const PlanPrice = styled.span`
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  color: white;
  @media (max-width: 1000px) {
    font-family: "Avenir", sans-serif;
    font-size: 14px;
  }
`;
export const PlanPerMeal = styled.span`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: white;
  @media (max-width: 1000px) {
    font-family: "Avenir", sans-serif;
    font-size: 14px;
  }
`;
export const ChooseYourPlan = styled.h1`
  font-family: "Platform-medium", sans-serif;
  font-size: 56px;
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

export const CopyText = styled.p`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;
export const PlanPage = styled.section`
  display: flex;
  flex-direction: column;
`;

export const CancelPage = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: 10%;
  background: #f2f2f2;
`;

export const CancelTitle = styled.h1`
  font-family: "Platform-Medium", sans-serif;
  font-size: 56px;
  align-self: center;
  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

export const CancelExplanation = styled.p`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  width: 631px;
  @media (max-width: 1000px) {
    width: 70%;
  }
`;

export const CancelLabel = styled.label`
  font-family: "Avenir";
  font-size: 16px;
  margin-left: 10px;
`;
export const CancelForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 70%;
  }
`;

export const ContinueCancelButton = styled.button`
  font-family: "Platform-Medium", sans-serif;
  margin-top: 70px;
  margin-bottom: 60px;
  background: black;
  color: white;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 631px;
  align-self: center;
  cursor: pointer;

  @media (max-width: 1000px) {
    width: 100%; 
  }
`;

export const ResubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
  padding-top: 40px;
`;

export const ResubTitle = styled.h1`
  font-family: "Platform-Medium";
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #241a1b;
  margin: 0;
  margin-bottom: 12px;
  @media (max-width: 600px) {
    font-size: 42px;
  }
`;

export const ResubDesc = styled.span`
  font-family: "Avenir", sans-serif;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  width: 543px;
  text-align: center;
  color: #000;
  margin-bottom: 83px;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const ResubPlanContainer = styled.div`
  margin-bottom: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LogoutButton = ({ logout }) => (
  <LogoutButtonWrapper onClick={logout}>
    <img src={require("../../../assets/orders/logout-icon.svg")} />
    Logout
  </LogoutButtonWrapper>
);

const LogoutButtonWrapper = styled.div`
  width: 200px;
  height: 52.4px;
  border-radius: 45px;
  border: solid 1px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Platform-Medium;
  font-size: 15px;
  margin-top: 48px;
  margin-bottom: 100px;
  cursor: pointer;
  &:hover {
    border-color: grey;
  }
  img {
    margin-right: 12px;
  }
`;
