import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import _ from "lodash";

export * from "./useFetch";
export * from "./useFormState";
export * from "./useMergeState";
export * from "./useRedirect";
export * from "./useWindowSize";
export * from "./useDisableScroll";

export const useEffectOnChange = (func, memo) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    func();
  }, memo);
};

export const updateLocalStorage = function useUpdateLocalStorageOnStateChange({
  couponState
}) {
  useEffect(
    () => {
      window.localStorage.newCheckout2 = JSON.stringify({
        couponState
      });
    },
    [couponState]
  );
};

export const getCouponFromQueryString = function useGetCouponFromQueryString({
  couponState,
  location,
  setCouponState
}) {
  useEffect(() => {
    const couponString = _.get(
      queryString.parse(location.search),
      "coupon",
      _.get(couponState, "couponString", "")
    );
    setCouponState({ couponString });
  }, []);
};

export const useHover = function useGetHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
};
