import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import spacetime from "spacetime";
import {theme, H6, H1, Button} from '@team-yumi/ui'
import PlanItem from "../../../styledcomponents/PlanItem";
import { Box, BigButton, H5, H3, Body1 } from "../../../styledcomponents";
import { MostPopularHeader } from "../../../styledcomponents/PlanCard";
import { useRedirect } from "../../../hooks";
import { updatePlanAction } from "../editMenuReducer";
import "styled-components/macro";
import checkImg from "../../../../assets/icons/blackCheck.svg";
import circlePlus from "../../../../assets/icons/circle-plus-black.svg";
import { format } from "../../../utils/image";

const questions = [
  {
    question: "How long does Yumi stay fresh for?",
    answer:
      "Our meals are prepared weekly and stay fresh in the fridge for 6 days. You’ll find an expiration date under the lid and we color-code our deliveries so you can easily spot last week’s jars in the fridge. Our jars are sealed using modified atmosphere packaging (MAP) which displaces oxygen with inert gas to make sure freshness lasts the full week."
  },
  {
    question: "Can I freeze my jars?",
    answer:
      "You can keep our jars in the freezer for up to 2 months. When you’re ready to serve the jar, just be sure to defrost overnight in the fridge and reheat in a microwave safe dish. Once the jar is defrosted make sure to consume within 24 hours."
  },
  {
    question: "How many ounces of food in one jar?",
    answer:
      "One jar is 4 oz. If your baby is just starting solids, keep in mind that you’ll be able to get 2-3 servings out of each jar."
  }
];

export const StepTwo = ({
  plans,
  setPlanState,
  birthday,
  setMaxStep,
  selectedPlan,
  dispatch,
  zone,
  planState,
  trackEvent
}) => {
  const [routeToStepThree, shouldRedirect] = useRedirect("/checkout_new/3");
  const { numMeals } = selectedPlan;

  const { diff } = spacetime(new Date()).since(birthday, "month");
  const month = diff.months + 12 * diff.years;

  let mealCount = 1;
  if (month > 6) mealCount = 2;
  if (month >= 9) mealCount = 3;

  useEffect(() => {
    trackEvent("CheckoutStepTwoLoaded", { step: "planSelect" });
  }, []);

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "90%",
        marginTop: "2em",
        marginBottom: "6em"
      }}
    >
      <Step2Title
        style={{
          width: "30%",
          textAlign: "center",
          marginTop: "0",
          marginBottom: "1em"
        }}
      >
        <span>
          At {month} months old, most kids start with{" "}
          <span
            style={{
              color: "#658FCA"
            }}
          >
            {mealCount} meal{mealCount > 1 ? "s" : ""} per day
          </span>
        </span>
      </Step2Title>

      <PlanBox>
        {plans.map(
          (plan, index) =>
            zone <= plan.maxZone ? (
              <PlanItem
                key={plan.name}
                count={plan.count}
                color="#F9F5EE"
                image={format(plan.image, { w: 600 })}
                selected={selectedPlan.planId === plan.planId}
                select={() => setPlanState({ selectedPlanIndex: index })}
                margin={index !== 2 ? "46" : "unset"}
                planHeader={plan.mostPopular ? <MostPopularHeader /> : null}
              />
            ) : null
        )}
      </PlanBox>

      <Box
        row
        style={{
          width: "80%",
          justifyContent: "center",
          marginTop: "2em",
          marginBottom: "1em",
          alignItems: "center"
        }}
      >
        <Box style={{ alignItems: "flex-end", marginRight: "20px" }}>
          <H1>
            {" "}
            ${(
              selectedPlan.total /
              (planState.billingPeriod === "monthly" ? numMeals * 4 : numMeals)
            ).toFixed(2)}
          </H1>
          <H6>per meal</H6>
        </Box>
        <Step2Desc css="text-align: left;">
          <Body1>
            <img src={checkImg} alt="" css="margin-right: 5px; width: 10px;" />{" "}
            Free shipping.
          </Body1>
          <Body1>
            <img src={checkImg} alt="" css="margin-right: 5px; width: 10px;" />{" "}
            The First 1,000 Days™
          </Body1>
          <Body1>
            <img src={checkImg} alt="" css="margin-right: 5px; width: 10px;" />{" "}
            Skip or cancel any time.
          </Body1>
        </Step2Desc>
      </Box>
      <Button
        type="tertiary"
        onClick={() => {
          dispatch(updatePlanAction(selectedPlan));
          setMaxStep(3);
          shouldRedirect(true);

          trackEvent("CheckoutStepTwoCompleted", {
            ecommerce: {
              detail: {
                products: [
                  {
                    name: selectedPlan.planId
                  }
                ]
              }
            }
          });
        }}
      >Next
      </Button>

      {routeToStepThree()}
    </Container>
  );
};

const PlanBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 650px) {
    flex-direction: row;
  }
`;

const Step2Title = styled(H3)`
  font-family: Ginger;
  font-size: 26px;
  line-height: 32px;
  
  ${theme.tablet} {
     font-size: 24px;
  line-height: 30px;
  }
  color: rgba(0, 0, 0, 0.87);
  @media (max-width: 1400px) {
    width: 80% !important;
  }
  @media (max-width: 1000px) {
    font-size: 23.69px;
    width: 80% !important;
  }
`;

const Questions = styled.div`
  width: 90vw;
  .question_container.q1:hover div.answer1,
  .question_container.q1:active div.answer1 {
    display: block !important;
  }
  .question_container.q2:hover div.answer2,
  .question_container.q2:active div.answer2 {
    display: block !important;
  }
  .question_container.q3:hover div.answer3,
  .question_container.q3:active div.answer3 {
    display: block !important;
  }
`;

const Container = styled.div`
  h1, h5 {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Step2Desc = styled.span`
  margin-top: 1em;
  margin-bottom: 2em;
  font-family: Avenir;
  font-size: 18.95px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.005em;
  @media (max-width: 1000px) {
    width: 60% !important;
  }
  @media (max-width: 500px) {
    width: 80% !important;
  }
`;
