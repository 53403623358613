import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_MilestonePage/${name}${extension}`;

export const MilestoneHero = getImageURL("MilestonePage_GrowingBaby2", ".gif");

export const MilestoneText = getImageURL("MilestonePage_Textwithguide", ".gif");

export const CoachingSessions = getImageURL(
  "MilestonePage_CoachingSessions",
  ".svg"
);

export const PageSource = getImageURL("MilestonePage_Source", ".svg");

export const AgeAndDev = getImageURL("MilestonePage_AgeAndDevelopment", ".svg");

export const Planet = getImageURL("MilestonePage_Planet", ".svg");

export const FutureAthlete = getImageURL("MilestonePage_FutureAthlete", ".svg");
export const FutureExplorer = getImageURL(
  "MilestonePage_FutureExplorer",
  ".svg"
);
export const FutureAstronaut = getImageURL(
  "MilestonePage_FutureAstronaught",
  ".svg"
);
export const FutureBuilder = getImageURL("MilestonePage_FutureBuilder", ".svg");

export const Baby = getImageURL("MilestonePage_TastePreference", ".svg");

export const LightBulb = getImageURL("MilestonePage_LightBulb", ".svg");

export const Builder = getImageURL("MilestonePage_Builder", ".svg");
