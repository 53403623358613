import React from "react";
import styled, { css } from "styled-components";
import { BLWKiwi } from "../assets";
import {
  H1,
  blackberry,
  Body1,
  BlueButton,
  HyperLink
} from "../../../styledcomponents";
import "styled-components/macro";
import { useWindowSize } from "../../../hooks";
import { Body } from "../../../styledcomponents/typefaces";

export const Mashable =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/Mashable.svg";

export const NYT =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/nyt.svg";

export const goop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/goop.svg";

export const TalkOfTheTown = () => {
  const { width } = useWindowSize();
  return (
    <PageLayout>
      {" "}
      <img
        src={BLWKiwi}
        alt="KIWI"
        css={css`
          width: 80px;
          margin-top: 10px;
          @media (max-width: 1200px) {
            width: 55px;
            margin-top: 100px;
          }
          @media (max-width: 1000px) {
            width: 55px;
            margin-top: 50px;
          }
        `}
      />
      <H1
        css={css`
          color: ${blackberry};
          font-size: 35pt;
          padding-top: 32px;
          width: 100%;
          display: flex;
          justify-content: center;
          @media (max-width: 1000px) {
            width: 100%;
          }
        `}
      >
        {" "}
        Talk of The Town{" "}
      </H1>
      {width > 1000 ? (
        <React.Fragment>
          <Body1 css="padding-top:32px;font-size:17pt; width: 60%; text-align:center; @media(max-width:1000px){font-size:13pt}">
            {" "}
            "Yumi, an adorable new pediatrician/nutritionist-approved baby-food
            delivery company, makes the whole experience a pleasure."
          </Body1>
          <TalkRow>
            <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
              <img src={goop} alt="goop" />
            </HyperLink>
            <HyperLink
              css="padding-left:50px"
              href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html"
            >
              {" "}
              <img src={NYT} alt="mash" />
            </HyperLink>
            <HyperLink
              css="padding-left:50px"
              href="https://mashable.com/article/yumi-baby-food-subscription/"
            >
              <img src={Mashable} alt="nyt" css="width:120px;" />{" "}
            </HyperLink>
          </TalkRow>
          <BlueButton
            css={css`
              background: ${blackberry};
              color: white;
              padding: 20px;
              width: 150px;
              margin-top: 55px;
            `}
            value="GET STARTED"
          />
        </React.Fragment>
      ) : (
        <QuoteColumn>
          <QuoteItem>
            {" "}
            <Body>“Watch out, Gerber, for the goo-goo gourmets.”</Body>
            <HyperLink href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html">
              <img
                src={NYT}
                alt="mash"
                css={css`
                  margin-top: 10px;
                  width: 170px;
                  opacity: 0.6;
                  &:hover {
                    opacity: 1;
                  }
                `}
              />
            </HyperLink>
          </QuoteItem>
          <QuoteItem>
            <Body>
              {" "}
              “Admit it, if you order these meals your baby will eat more
              adventurously than you do.”
            </Body>
            <HyperLink href="https://mashable.com/article/yumi-baby-food-subscription/">
              <img
                src={Mashable}
                alt="nyt"
                css={css`
                  margin-top: 10px;
                  width: 110px;
                  opacity: 0.4;
                  &:hover {
                    opacity: 1;
                  }
                `}
              />
            </HyperLink>
          </QuoteItem>
          <QuoteItem>
            <Body>
              {" "}
              "Yumi, an adorable new pediatrician/nutritionist-approved
              baby-food delivery company, makes the whole experience a
              pleasure."
            </Body>
            <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
              <img
                src={goop}
                alt="goop"
                css={css`
                  margin-top: 10px;
                  width: 90px;
                  opacity: 0.4;
                  &:hover {
                    opacity: 1;
                  }
                `}
              />
            </HyperLink>
          </QuoteItem>
        </QuoteColumn>
      )}
    </PageLayout>
  );
};

const QuoteColumn = styled.div`
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 150px 150px 150px;
  margin-top: 50px;
  justify-content: center;
  text-align: center;
`;
const QuoteItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TalkRow = styled.div`
  display: flex;
  padding-top: 50px;
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
