import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import spacetime from "spacetime";
import { connect } from "react-redux";
import { registerReferralUser } from "../../../usecases";
import { updateUserId } from "../../../redux/actions/Checkout";

import {
  H3,
  BlueButton,
  blackberry,
  Body1,
  H6,
  grapefruit,
  InputField,
  H4,
  Form,
  oatDark
} from "../../../styledcomponents";
import { MenuCard } from "../../../styledcomponents/containers/MenuCard";
import { AddressForm } from "../../../styledcomponents/AddressForm";
import StripeBillingForm from "../../../styledcomponents/containers/StripeBillingForm";
import { GiftCheckoutContext } from "./GiftCheckoutContext";
import { useFormState, useRequest } from "../../../hooks";
import { getNextDeliveryDate } from "../../../utils/dates";
import { Plan } from "../../../styledcomponents/Plan";
import { Box } from "../../../styledcomponents/layout";
import { getBillingPlans } from "../../../usecases/users";

const coachingSessions = `https://email.helloyumi.com/assets/_MilestonePage/MilestonePage_CoachingSessions.svg`;
const ageAndDev = `https://email.helloyumi.com/assets/_MilestonePage/MilestonePage_AgeAndDevelopment.svg`;

async function registerWithoutPayment({
  email,
  name,
  phone,
  child_birthday,
  referral_code,
  address,
  billing_plan,
  timeline,
  special_menu_id,
  order_attributes,
  order_quads,
  dispatch,
  history,
  formatted_delivery_date
}) {
  const userResponse = await registerReferralUser({
    email,
    name,
    phone,
    child_birthday: spacetime(child_birthday).format("YYYY-MM-DD"),
    referral_code,
    address,
    billing_plan,
    timeline,
    special_menu_id,
    order_attributes,
    order_quads
  });
  dispatch({
    type: "SUBMIT_WITHOUT_PAYMENT",
    userResponse,
    stage: 3,
    deliveryDate: formatted_delivery_date
  });

  history.push("/gift-checkout/3");
  return userResponse;
}

async function registerWithPayment({
  email,
  name,
  phone,
  stripe_card_token,
  child_birthday,
  referral_code,
  address,
  billing_plan,
  timeline,
  special_menu_id,
  order_attributes,
  order_quads,
  dispatch,
  history,
  formatted_delivery_date
}) {
  const userResponse = await registerReferralUser({
    email,
    name,
    phone,
    stripe_card_token,
    child_birthday: spacetime(child_birthday).format("YYYY-MM-DD"),
    referral_code,
    address,
    billing_plan,
    timeline,
    special_menu_id,
    order_attributes,
    order_quads
  });
  dispatch({
    type: "SUBMIT_PAYMENT_INFO",
    userResponse,
    stage: 3,
    deliveryDate: formatted_delivery_date
  });
  history.push("/gift-checkout/3/payment");
  return userResponse;
}

const mapDispatchToProps = dispatch => ({
  addUserToGlobalStore: payload => dispatch(updateUserId(payload))
});

export const StepTwo = connect(
  () => ({}),
  mapDispatchToProps
)(({ addUserToGlobalStore }) => {
  const { milestoneMenu, birthday, progress, history, dispatch } = useContext(
    GiftCheckoutContext
  );

  const [errors, setErrors] = useState(false);

  const { referral_code } = progress;

  const child_birthday = spacetime(birthday).format("iso");

  const { order_quads } = milestoneMenu;
  const { special_menu_id } = milestoneMenu;
  const order_attributes = order_quads.reduce(
    (acc, e) => acc.concat(e.meals),
    []
  );

  const [shippingFormState, setShippingField, setShippingForm] = useFormState({
    street: "",
    street2: "",
    city: "",
    state: "",
    postcode: ""
  });

  const [newReferredUserForm, setNewReferredUserField] = useFormState({
    email: "",
    phone: ""
  });

  const formatted_delivery_date = `${spacetime(getNextDeliveryDate()).format(
    "day"
  )}, ${spacetime(getNextDeliveryDate()).format("month-short")}. ${spacetime(
    getNextDeliveryDate()
  ).format("date")}`;

  const [planData, plansLoading] = useRequest(getBillingPlans);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);

  const [getStripeData, setStripeSubmit] = useState(null);

  if (plansLoading) return <h2>loading...</h2>;
  if (!planData) return <div>It appears an error occurred.</div>;
  const plans = planData.weekly.filter(
    p => p.plan_name && !p.plan_name.includes("snacks") && p.plan_id <= 161
  );

  return (
    <div style={{ width: "100%", alignItems: "flex-start", padding: "40px" }}>
      <H3 style={{ color: blackberry }}>Your Gift</H3>
      <Body1 style={{ display: "flex", overflow: "scroll" }}>
        {order_attributes.map(attr => (
          <p style={{ margin: "1rem" }} key={attr.meal_id}>
            {attr.meal_name}(x{attr.quantity})
          </p>
        ))}
      </Body1>
      <OrderSection>
        {order_attributes.map(order => (
          <MenuCard key={order.meal_id} item={order} />
        ))}
      </OrderSection>
      <FormSection>
        <FormBlock errors={errors}>
          <H6 style={{ marginBottom: "25px" }}>Shipping Address</H6>
          <AddressForm
            formState={shippingFormState}
            setField={setShippingField}
            setForm={setShippingForm}
          />
        </FormBlock>
        <FormBlock errors={errors}>
          <Form
            onSubmit={() =>
              registerWithoutPayment({
                ...newReferredUserForm,
                child_birthday,
                referral_code,
                address: shippingFormState,
                billing_plan: planData.weekly[selectedPlanIndex].plan_name,
                timeline: "weekly",
                order_attributes,
                order_quads,
                dispatch,
                history,
                formatted_delivery_date
              })
                .then(response => addUserToGlobalStore(response))
                .catch(e => setErrors(true))
            }
          >
            <H6 style={{ marginBottom: "25px" }}>Estimated Delivery Date</H6>
            <Body1>{formatted_delivery_date}</Body1>
            <BlueButton
              style={{
                background: blackberry,
                color: "white",
                width: "100%",
                marginTop: "40px",
                marginBottom: "30px",
                padding: "15px",
                fontSize: "14px"
              }}
              type="submit"
            >
              CLAIM YOUR GIFT
            </BlueButton>
            <hr style={{ color: "#eee" }} />
            <H6 style={{ marginBottom: "25px", marginTop: "25px" }}>
              Get fresh baby food delivered weekly.
            </H6>
            <Body1>
              Create an account with us today and get 5% off all future orders.
              Skip or cancel anytime!
            </Body1>

            <Link style={{ textDecoration: "none" }} to="/checkout/1">
              <BlueButton
                style={{
                  background: grapefruit,
                  color: "white",
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "30px",
                  padding: "15px",
                  fontSize: "14px"
                }}
              >
                {`SIGN UP - GIFT & 5% OFF FUTURE ORDERS`}
              </BlueButton>
            </Link>
          </Form>
        </FormBlock>
        <FormBlock errors={errors}>
          <H6 style={{ marginBottom: "25px" }}>Additional Account Info</H6>
          <InputField
            placeholder="Email"
            value={newReferredUserForm.email}
            onChange={setNewReferredUserField("email")}
          />{" "}
          <InputField
            placeholder="Name"
            value={newReferredUserForm.name}
            onChange={setNewReferredUserField("name")}
          />
          <InputField
            type="tel"
            placeholder="Phone"
            value={newReferredUserForm.phone}
            onChange={setNewReferredUserField("phone")}
            label="Phone number"
            errorMessage="Please enter a 10 digit US phone number."
            required
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
          />
        </FormBlock>
        {errors && <ErrorText>Missing or incomplete fields.</ErrorText>}
      </FormSection>

      <SubscribeSection>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%"
          }}
        >
          <H3 style={{ color: blackberry, marginTop: "100px" }}>Subscribe</H3>
          <Body1 style={{ marginTop: "20px", fontSize: "20pt" }}>
            Create an account with us today and get 5% off all future orders.
          </Body1>
          <H6 style={{ marginTop: "56px", fontSize: "15pt" }}>
            Why subscribe?
          </H6>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px"
            }}
          >
            <img src={ageAndDev} alt="ageAndDev" style={{ width: "50px" }} />
            <Body1 style={{ fontSize: "15pt" }}>
              Meals selected for your baby's age and development.
            </Body1>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
          >
            <img src={coachingSessions} alt="coach" style={{ width: "50px" }} />
            <Body1 style={{ fontSize: "15pt" }}>
              5 Free Nutritional Coaching Sessions.
            </Body1>
          </div>

          <PlanBox
            row
            css="
          align-self:center;
            margin-top: 56px;
          justify-content: center;
          background: white;
          align-items: space-between;
          padding: 20px;  "
          >
            {plans.map((plan, index) => (
              <Plan
                key={plan.plan_name}
                jarCount={plan.meal_count}
                color="white"
                selected={selectedPlanIndex === index}
                select={() => setSelectedPlanIndex(index)}
                margin={4}
                style={{
                  border: "1px solid #eee"
                }}
                smaller
              />
            ))}
          </PlanBox>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PaymentSection>
            <H4 style={{ marginBottom: "30px" }}>Payment Information</H4>
            <StripeBillingForm
              hideAddress
              setStripeSubmit={setStripeSubmit}
              amount={1000}
              applePayMessage="Payment processed successfully. Click SUBMIT to complete your order."
            />{" "}
          </PaymentSection>

          <PaymentForm
            onSubmit={async () =>
              registerWithPayment({
                ...newReferredUserForm,
                stripe_card_token: (await getStripeData()).token.id,
                child_birthday,
                referral_code,
                address: shippingFormState,
                billing_plan: planData.weekly[selectedPlanIndex].plan_name,
                timeline: "weekly",
                special_menu_id,
                order_attributes,
                order_quads,
                dispatch,
                history,
                formatted_delivery_date
              })
                .then(response => addUserToGlobalStore(response))
                .catch(e => setErrors(true))
            }
          >
            <BlueButton
              style={{
                background: blackberry,
                color: "white",
                padding: "20px",
                width: "100%"
              }}
              type="submit"
            >
              TRY YUMI FOR FREE
            </BlueButton>
          </PaymentForm>
          <Body1
            style={{
              opacity: "0.8",
              fontSize: "15pt",
              maxWidth: "500px",
              marginTop: "25px"
            }}
          >
            Your subscription will automatically renew at the end of each pay
            period until you cancel. If you cancel after the weekly cutoff
            (Tuesday 11:59pm PT) or after the monthly cutoff (Tuesday 11:59 pm
            PT of your third week), you will still receive and be charged for
            the next shipment.
          </Body1>
          <hr
            style={{
              background: oatDark,
              width: "100%",
              opacity: "0.8",
              marginTop: "25px"
            }}
          />
          <Body1
            style={{
              opacity: "0.5",
              color: oatDark,
              fontSize: "25pt",
              maxWidth: "500px",
              marginTop: "25px"
            }}
          >
            Not interested in subscribing?
          </Body1>
          <Form
            onSubmit={async () =>
              registerWithoutPayment({
                ...newReferredUserForm,
                child_birthday,
                referral_code,
                address: shippingFormState,
                billing_plan: planData.weekly[selectedPlanIndex].plan_name,
                timeline: "weekly",
                order_attributes,
                dispatch,
                history,
                formatted_delivery_date
              })
                .then(response => addUserToGlobalStore(response))
                .catch(e => setErrors(true))
            }
          >
            <BlueButton
              type="submit"
              value="CLAIM GIFT"
              style={{
                width: "100%",
                color: blackberry,
                marginTop: "20px",
                padding: "15px",
                border: `1px solid ${blackberry}`
              }}
            />
          </Form>
        </div>
      </SubscribeSection>
    </div>
  );
});

const ErrorText = styled(Body1)`
  color: red;
  font-size: 20px;
`;

const PaymentForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const PaymentSection = styled.div`
  background: white;
  padding: 80px;
  @media (max-width: 1500px) {
    margin-top: 15px;
  }
`;
const PlanBox = styled(Box)``;

const FormSection = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0px;
  }
`;

const SubscribeSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  align-items: flex-end;
  padding-bottom: 100px;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0px;
  }
`;

const FormBlock = styled.div`
  background: white;
  padding: 40px;
  flex: 0 0 calc(33% - 15px);
  max-width: 100%;
  border: ${({ errors }) => errors && "1px solid red"};
  @media (max-width: 1000px) {
    margin-top: 15px;
  }
`;
const OrderSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 1 0 100%;
  align-self: flex-start;
`;
