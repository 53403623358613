export const green = "#OA6D43";
export const yellow = "#F6DA71";
export const blue = "#658FCA";

export const plum = "#645394";
export const grapefruit = "#F1A68C";
export const oat = "#FAF5ED";
export const oatLight = "#F9EEE5";
export const oatDark = "#75403E";
export const oatAdjusted = "#F9EEE5";
export const oatCreamy = "#FFF9F0";

export const superGreen = "#2FAB4F";
export const kiwi = "#7DBB54";
export const kale = "#0A6D43";
export const squash = "#EEC74E";
export const banana = "#F6DA71";
export const pepper = "#EE5A37";

export const beet = "#C47084";
export const dragonfruit = "#F592A4";
export const grape = "#A46794";
export const blueberry = "#658FCA";
export const lightBlue = "#8DB9DB";
export const blackberry = "#334897";

export const yumiGreen = "#0A6D43";
export const yumiLime = "#F7FADB";

export const lightPink = "#F5E0E2";

export const yumiOrange = "#EE5A37";

export const texasOrange = "#EE5A37";
export const texasOat = "#FEF0E8";
export const texasOat2 = "#FDEFE6";
export const texasSherbert = "#FFE2D1";
export const texasBrown = "#75403E";
export const texasLightOrange = "#F1A68C";
export const texasPink = "#C47084";

export const oatNew = "#FFF1E8";
export const softPurple = "#C4BBF2";

export const yumiPurple = "#645394";
export const yumiLavender = "#A46794";
