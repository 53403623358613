import React from "react";
import ReactModal from "react-modal";
import "styled-components/macro";
import { useWindowSize, useDisableScroll } from "../hooks";
import { Box } from "./layout";

ReactModal.setAppElement("body");
const defaultStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "50%",
    marginTop: "10px",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "560px",
    paddingBottom: "0",
    paddingLeft: "0",
    paddingTop: 0,
    paddingRight: "0",
    borderRadius: "10px",
    maxHeight: "80vh",
    overflow: "scroll"
  },
  overlay: {
    zIndex: "2500",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};
const mobileStyle = {
  content: {
    ...defaultStyle.content,
    marginRight: "50%",
    marginTop: "10px",
    width: "100%",
    maxWidth: "560px",
    height: "25%"
  },
  overlay: {
    zIndex: "2500",
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  }
};

export const Modal = ({
  isOpen,
  children,
  close,
  mobile = mobileStyle,
  desktop = defaultStyle,
  boxStyle
}) => {
  const windowSize = useWindowSize();
  const styles =
    windowSize.width < 800
      ? { ...mobileStyle, ...mobile }
      : { ...defaultStyle, ...desktop };
  useDisableScroll(isOpen);
  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={e => {
        e.stopPropagation();
        close();
      }}
    >
      <Box css="-webkit-overflow-scrolling: touch;">
        <Box style={{ width: "100%", ...boxStyle }}>{children}</Box>
      </Box>
    </ReactModal>
  );
};

export default Modal;
