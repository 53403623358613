import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
import {
  PlanCardContainer,
  PlanCard,
  PlanSelectButton,
  PlanSize,
  PlanWeeklyTotal,
  PlanPrice,
  PlanPerMeal,
  ResubDesc,
  ResubTitle,
  ResubContainer,
  ResubPlanContainer
} from "../components/styledComponents";
import { subscribe, getUser } from "../../../usecases/users";
import { getCoupon } from "../../../usecases/checkout";
import Check from "../../../../assets/icons/Dashboard-icons/white-confirmed-icon.svg";
import { Receipt } from "../components/Receipt";
import { getNextDeliveryDate, formatDatePretty } from "../../../utils/dates";
import { sleep } from "../../../utils/sleep";
import { SuccessIndicator } from "../components/SuccessIndicator";
import BillingForm from "../../../styledcomponents/containers/StripeBillingForm";
import { formatStripeResponse } from "../../../utils/formatStripeResponse";
import { useRequest } from "../../../hooks/useFetch";
import { useMergeState } from "../../../hooks/useMergeState";
import { couponDiscount } from "../../../utils/coupon";

const planData = {
  "bento-five": {
    numMeals: 7,
    weeklyTotal: 35.0,
    perMeal: 35 / 7,
    planId: 121,
    plan: "bento-five",
    timeline: "weekly",
    color: "#0A6D43",
    tax: 3.33
  },
  "bento-ten": {
    numMeals: 14,
    weeklyTotal: 65.0,
    perMeal: 65 / 14,
    planId: 141,
    plan: "bento-ten",
    timeline: "weekly",
    color: "#334897",
    tax: 6.18
  },
  "bento-twenty": {
    numMeals: 21,
    weeklyTotal: 90.0,
    perMeal: 90 / 21,
    planId: 161,
    plan: "bento-twenty",
    timeline: "weekly",
    color: "#ee5a37",
    tax: 8.55
  }
};

const Plan = ({
  color,
  jarCount,
  weeklyPrice,
  perMeal,
  updatePlan,
  selected
}) => (
  <PlanCard color={color} selected={selected}>
    <PlanSize>{jarCount} jars</PlanSize>
    <div>
      <PlanPrice>${weeklyPrice}/</PlanPrice>
      <PlanWeeklyTotal>weekly total</PlanWeeklyTotal>
    </div>
    <PlanPerMeal>${perMeal}/per meal</PlanPerMeal>
    {selected ? (
      <img src={Check} alt="checkMark" style={{ marginTop: "63px" }} />
    ) : (
      <PlanSelectButton color={color} onClick={updatePlan}>
        Select
      </PlanSelectButton>
    )}
  </PlanCard>
);

export const Resubscribe = ({ user }) => {
  const [userData, , getUserError] = useRequest(() => getUser(user.id));
  const [state, setState] = useMergeState({
    selectedPlan: "bento-ten",
    updateStatus: false,
    couponCode: "",
    processingStatus: null,
    successfulCouponResponse: "",
    shouldRedirect: false,
    errors: []
  });
  const {
    selectedPlan,
    updateStatus,
    couponCode,
    processingStatus,
    successfulCouponResponse,
    shouldRedirect,
    errors
  } = state;

  const changePlan = planName => () => setState({ selectedPlan: planName });
  const updateCouponCode = e => setState({ couponCode: e.target.value });
  const pushError = err => setState({ errors: errors.concat(err) });

  const submitResubscribe = async formattedStripeResponse => {
    setState({ errors: [], processingStatus: "updating" });
    subscribe(user.id, {
      stripe_card_token: formattedStripeResponse.stripe_card_token,
      billing_plan: selectedPlan,
      timeline: "weekly",
      postcode: formattedStripeResponse.billing_zip,
      email: userData.email,
      stripe_id: userData.stripe_id,
      coupon_id: _.get(successfulCouponResponse, "id", null)
    })
      .then(async () => {
        setState({
          errors: [],
          processingStatus: "updated",
          updateStatus: true
        });
        await sleep(4000);
        setState({ shouldRedirect: true });
      })
      .catch(() =>
        setState({
          errors: errors.concat("SubscriptionError"),
          processingStatus: null
        })
      );
  };

  const requestCouponFromServer = async e => {
    if (e) e.preventDefault();
    getCoupon(couponCode)
      .then(coupon => setState({ successfulCouponResponse: coupon }))
      .catch(() => pushError("InvalidCoupon"));
  };
  const { tax, weeklyTotal, numMeals } = planData[selectedPlan];
  const total =
    weeklyTotal +
    tax -
    couponDiscount(weeklyTotal + tax, successfulCouponResponse);

  if (getUserError)
    return "It looks like an error occurred. Please refresh the page and try again";
  return (
    <div>
      {updateStatus && (
        <SuccessIndicator
          updatedField="subscription"
          close={() => setState({ updateStatus: false })}
        />
      )}
      <ResubContainer>
        <ResubPlanContainer>
          <ResubTitle>1. Choose your plan</ResubTitle>
          <ResubDesc>
            Your daily dose of fruits and vegetables. Skip a delivery, change
            your box size and try new foods at any time.
          </ResubDesc>
          <PlanCardContainer>
            {Object.values(planData).map(plan => (
              <Plan
                key={plan.planId}
                user={user}
                jarCount={plan.numMeals}
                weeklyPrice={plan.weeklyTotal}
                selected={selectedPlan === plan.plan}
                color={plan.color}
                updatePlan={changePlan(plan.plan)}
                perMeal={(plan.weeklyTotal / plan.numMeals).toFixed(2)}
              />
            ))}
          </PlanCardContainer>
        </ResubPlanContainer>

        <ResubTitle>2. Add Payment</ResubTitle>
        <PaymentContainer>
          <BillingForm
            applePayMessage="Apple Pay proccessed successfully. Click the 'Subscribe Now' button to confirm your subscription."
            amount={total}
            button={(requestStripeData, disable_via_billing_form) => (
              <React.Fragment>
                <CouponContainer>
                  <CouponTitle>Coupon</CouponTitle>
                  <CouponInputContainer>
                    <CouponInput
                      onChange={updateCouponCode}
                      value={couponCode}
                    />
                    <ApplyButton onClick={requestCouponFromServer}>
                      Apply
                    </ApplyButton>
                  </CouponInputContainer>
                  {errors.includes("InvalidCoupon") ? (
                    <CouponTitle>Invalid Coupon</CouponTitle>
                  ) : null}
                </CouponContainer>
                <ReceiptContainer>
                  <Receipt
                    date={formatDatePretty(getNextDeliveryDate())}
                    plan={selectedPlan}
                    coupon={successfulCouponResponse}
                    total={total}
                    tax={tax}
                    weeklyTotal={weeklyTotal}
                    numMeals={numMeals}
                  />
                </ReceiptContainer>

                <SubscribeButton
                  onClick={async e => {
                    submitResubscribe(
                      formatStripeResponse(await requestStripeData(e))
                    );
                  }}
                  disabled={disable_via_billing_form}
                >
                  Subscribe Now
                </SubscribeButton>
              </React.Fragment>
            )}
          />
        </PaymentContainer>

        {errors.includes("StripeError")
          ? "Could not create Stripe subscription for account"
          : null}
        {errors.includes("SubscriptionError")
          ? "Could not create subscription"
          : null}
        {processingStatus === "updating" ? "Updating subscription..." : null}
        {processingStatus === "updated" ? "Added subscription!" : null}
        <DiscContainer>
          Your subscription will automatically renew at the end of each pay
          period until you cancel. If you cancel after the weekly cutoff
          (Tuesday 11:59pm PT) or after the monthly cutoff (Tuesday 11:59pm PT
          of your third week), you will still receive and be charged for the
          next shipment.
        </DiscContainer>
      </ResubContainer>
      {shouldRedirect && <Redirect to="/profile/settings" />}
    </div>
  );
};

const CouponTitle = styled.span`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
`;

const DiscContainer = styled.div`
  font-family: "Avenir", sans-serif;
  margin-top: 80px;
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
  @media (min-width: 1000px) {
    width: 35%;
  }
`;

const PaymentContainer = styled.div`
  margin-top: 40px;
  width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 640px;
`;

const ReceiptContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const CouponInput = styled.input`
  font-family: "Avenir", sans-serif;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 26px;
  height: 52px;
  padding-left: 15px;
  margin-left: 15%;
  width: 80%;
  &:focus {
    outline: 0;
  }
  @media (max-width: 600px) {
    margin-left: unset;
    margin-bottom: 20px;
  }
`;

const CouponContainer = styled.div`
  border-radius: 8px;
  padding: 20px 25px 20px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-top: 32px;
`;

const ApplyButton = styled.div`
  width: 103px;
  height: 52px;
  vertical-align: center;
  border-radius: 26px;
  background-color: #000000;
  color: #fff;
  line-height: 52px;
  font-family: "Avenir", sans-serif;
  cursor: pointer;
  position: relative;
  left: -86px;
  @media (max-width: 600px) {
    left: unset;
  }
`;

const CouponInputContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SubscribeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  vertical-align: center;
  font-size: 16px;
  line-height: 53px;
  letter-spacing: 1.3px;
  color: #fff;
  width: 300px;
  height: 52.4px;
  border-radius: 45px;
  background-color: #22439d;
  font-family: "Platform-Medium", sans-serif;
  margin-top: 100px;

  &:hover {
    background: #3e5aa5;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    &:hover {
      background: #22439d;
    }
  }
`;
