import styled, { css } from "styled-components";
import "styled-components/macro";
import React from "react";
import Toggle from "react-toggle";
import "./dangerousCSS.css";

const BigButtonStyle = styled.input`
  font-family: "Ginger", sans-serif;
  border-radius: 0;
  font-size: 13.82px;
  letter-spacing: 1.25px;
  border: 1px solid transparent;
  width: 200px;
  text-align: center;
  ${props =>
    props.nofill
      ? "background: transparent; color:black"
      : "background: black; color:white;"};
  text-transform: uppercase;
  height: 53px;
  cursor: pointer;
  ${props => (props.smallpadding ? "padding: 0 40px;" : "padding: 0px;")};
  box-sizing: content-box;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &:hover {
    opacity: 0.8;
    background: black;
    color: whitesmoke;
    border: 1px solid black;
  }
`;

export const BigButton = ({
  nofill = false,
  type = "button",
  disabled = "",
  formsubmitted = "",
  ...props
}) => (
  <BigButtonStyle
    nofill={nofill}
    type={type}
    disabled={
      [disabled].includes(true) ||
      [disabled, formsubmitted].includes("disabled")
        ? "disabled"
        : ""
    }
    {...props}
  />
);

export const SmallButton = ({ ...props }) => (
  <BigButton {...props} style={{ height: "2.125em" }} />
);

// #e7d5ca

export const Toggler = ({ ...props }) => (
  <Toggle
    css={css`
      .react-toggle--checked .react-toggle-track {
        background: #334897;
      }
    `}
    className="toggler"
    {...props}
  />
);

export default BigButton;
