import React, { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { LazyImage } from "../../../styledcomponents";

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "50%",
    marginTop: "10px",
    transform: "translate(-50%, -50%)",
    padding: "20px 0 0 0",
    width: "600px",
    overflow: "hidden",
    backgroundColor: "#f1e8df",
    backgroundSize: "cover",
    backgroundRepeat: "none",
    backgroundPosition: "center top"
  },
  overlay: {
    zIndex: "3000"
  }
};

const mobileStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "50%",
    marginTop: "10px",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    padding: "20px 0 0 0",
    borderRadius: "10px",
    backgroundColor: "#f1e8df"
  },
  overlay: {
    zIndex: "3000"
  }
};

export const PopupModal = ({ width }) => {
  const [shouldShowModal, setModal] = useState(true);
  const close = () => {
    window.localStorage.texasModal = "closed";
    setModal(false);
  };
  if (window.localStorage.texasModal === "closed") return null;
  return (
    <ReactModal
      isOpen={shouldShowModal}
      style={width < 700 ? mobileStyle : style}
      onRequestClose={close}
    >
      <div
        style={{ positon: "absolute", right: "0", textAlign: "right" }}
        onClick={close}
      >
        {" "}
        x{" "}
      </div>
      <CardContainer onClick={close}>
        <LazyImage
          style={{ width: "100%", marginTop: "-45px" }}
          src="https://email.helloyumi.com/assets/_homepage/Texas.gif"
          alt="Texas gif"
        />
      </CardContainer>
    </ReactModal>
  );
};
