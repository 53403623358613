import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import "styled-components/macro";
import {
  Button,
  H2,
  H5,
  Image,
  Caption1,
  theme,
  Row,
  Box,
  loading,
  toast,
} from "@team-yumi/ui";
import { submitFrozenPacks } from "../../../usecases";
import ButtonContainer from "./ButtonContainer";

export const Frozen = ({
  isLoading,
  setStep,
  setIsLoading,
  setShowNotification,
  userId,
}) => {
  const [frozenStage, setFrozenStage] = useState(0);

  const stepOneOptions = [
    {
      id: 104,
      stage: 1,
      ingredients: "Lentils, Blueberry, Mango, Broccoli, Kabocha Squash",
      image: "_Dashboard/one-jar.png",
    },
    {
      id: 107,
      stage: 2,
      ingredients:
        "Apple Pie, All Star Soup, Lebanese Lentil Soup, Kabocha Squash Soup",
      image: "_Dashboard/two-jars.png",
    },
    {
      id: 110,
      stage: 3,
      ingredients:
        "Bell Pepper Bowl, Broccoli Mash Bowl, Mango Chia Pudding, Hobat Pat Juk",
      image: "_Dashboard/three-jars.png",
    },
  ];

  const addFrozen = async () => {
    loading.show(
      "We're processing your purchase. Please don't close this page."
    );

    try {
      await submitFrozenPacks(userId, {
        special_menu_id: stepOneOptions[frozenStage].id,
        plan: "frozen-32",
      });
    } catch (err) {
      loading.hide();
      toast.error("Error Adding Purchasing Frozen Box");
      return;
    }

    setIsLoading(false);
    loading.hide();
    setStep(2);
    setShowNotification(true);
  };
  return (
    <Row p="0">
      {" "}
      <Box xl={12} center p="0">
        <Box center style={{ textAlign: "center" }}>
          <Header color={theme.charcoal}>Stock up on Frozen Blends</Header>
          <SubHead>
            For a limited time, you can now stock up on our frozen purees. This
            is a one time shipment and will arrive with dry ice.
          </SubHead>
        </Box>
        <StageContainer>
          {stepOneOptions.map((item, index) => (
            <Step1Container
              onClick={() => setFrozenStage(index)}
              key={item.id}
              xl={4}
              lg={10}
              md={12}
              start={{ lg: 2, md: 1 }}
            >
              <AddOnContainer active={frozenStage === index} xl={12}>
                <Box xl={12} lg={4} sm={5}>
                  <Image
                    src={item.image}
                    width={{ xl: "277px", lg: "unset", sm: "140px" }}
                    height={{ xl: "277px", lg: "159px", sm: "140px" }}
                    of="cover"
                  />
                </Box>
                <TextContainer
                  xl={12}
                  lg={8}
                  sm={7}
                  height={{ xl: "147px", lg: "136px" }}
                  vCenter
                  fd="column"
                  ai="flex-start"
                >
                  {" "}
                  <TextHead active={frozenStage === index} m="0">
                    Stage {item.stage} Purees
                  </TextHead>
                  <AddOnBox active={frozenStage === index} m="5px 0 0 0">
                    {stepOneOptions[index].ingredients}
                  </AddOnBox>
                </TextContainer>
              </AddOnContainer>
            </Step1Container>
          ))}
        </StageContainer>
      </Box>
      <Box xl={12} center mt={{ xl: "50px", lg: 0 }}>
        <ButtonContainer>
          <Box
            center
            width={{ xl: "100%", lg: "50%" }}
            mr={{ xl: "30px", lg: 0 }}
          >
            <Button
              type="link-tertiary"
              color="#61AACE"
              onClick={() => {
                setStep(2);
              }}
            >
              No, Thanks
            </Button>
          </Box>

          <Box center width={{ xl: "100%", lg: "50%" }}>
            <Button
              disabled={isLoading}
              onClick={() => addFrozen()}
              width="100%"
              height="50px"
              type="primary"
              backgroundColor="#61AACE"
              style={{ backgroundColor: "#61AACE" }}
            >
              Purchase for $100
            </Button>
          </Box>
        </ButtonContainer>
      </Box>
    </Row>
  );
};

const StageContainer = styled(Row)`
  padding: 0 !important;
`;

const Header = styled(H2)`
  margin: 15px;
  ${theme.mobile} {
    margin: 0;
  }
`;

const SubHead = styled.p`
  color: #4a4a4a;
  font-family: "Ginger", sans-serif;
  margin-bottom: 50px;
  max-width: 480px;

  ${theme.mobile} {
    margin: 5px 0 0 0;
  }
`;

const Step1Container = styled(Box)`
  margin: 20px;

  ${theme.mobile} {
    margin: 15px 0 0 0;
  }
`;

const AddOnContainer = styled(Row)`
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  background: ${(props) => (props.active ? theme.papayaLight : "white")};
  padding: 0 !important;
  max-width: 277px;
  align-items: center;

  ${theme.smallDesktop} {
    max-width: 100%;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const TextContainer = styled(Box)`
  padding: 30px;
  align-items: flex-start;
  max-width: 277px;

  ${theme.smallDesktop} {
    max-width: 100%;
    justify-content: center;
  }

  ${theme.mobile} {
    padding: 15px;
  }
`;

const TextHead = styled(Caption1)`
  color: ${(props) => (props.active ? "white" : "black")};
  margin: 0 !important;
`;

const AddOnBox = styled(H5)`
  text-align: left;
  color: ${(props) => (props.active ? "white" : "black")};
  margin: 5px 0 0 0 !important;
`;
