import React from "react";
import { PrivateRoute } from "../../containers/App/PrivateRoute";
import { OrdersPage, EditOrdersPage } from "./Pages";

const RoutedOrderPage = ({ isAuthenticated, user, location }) => (
  <React.Fragment>
    <PrivateRoute
      exact
      path="/orders"
      component={() => <OrdersPage location={location} user={user.id} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      path="/orders/edit"
      component={() => <EditOrdersPage user={user.id} />}
      isAuthenticated={isAuthenticated}
    />
  </React.Fragment>
);

export default RoutedOrderPage;
