import React from "react";
import styled, { css } from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { Circle, Image } from "@team-yumi/ui";
import { Box, Subtitle2 } from "../../../styledcomponents";
import YumiLogo from "../../../../assets/icons/Yumi_logo.svg";

export const Stepper = ({ step, maxStep, headers }) => {
  const logo = YumiLogo;
  return (
    <React.Fragment>
      <Box
        row
        nowrap
        style={{ width: "100%", background: "white" }}
        name="outer box"
      >
        <StyledBox
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "1em",
            textAlign: "center",
            background: "white",
            maxWidth: "900px"
          }}
          mobileStyle="height: 58px; padding: 10px;"
          name="stepper bar"
        >
          <Link to="/" style={{ alignSelf: "center", marginRight: "40px" }}>
            <Logo src={logo} height="18px" />
          </Link>
          <div
            style={{
              display: "inline-flex"
            }}
          >
            {step > 1 &&
              Array(5)
                .fill(null)
                .map((_, index) => index)
                .map(index => (
                  <Link
                    to={index < 3 ? `/checkout/${index + 1}` : "/checkout/none"}
                  >
                    <Circle width="10px" mr="12px" half={step - 2 < index} />
                  </Link>
                ))}
          </div>
        </StyledBox>
      </Box>
    </React.Fragment>
  );
};

const StyledBox = styled(Box)`
  justify-content: space-between;
`;
const Logo = styled.img`
  @media (max-width: 500px) {
    height: 12px;
  }
`;
const AvailableLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const IndexCircle = styled.div`
  border-style: solid;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  margin-right: 8px;
  border: 1.5px solid black;
  font-family: "Platform-Regular", sans-serif;
  color: black;
  ${props => props.current && "background: #334897; color: white;"};

  @media (max-width: 1000px) {
    margin-right: 0px;
    margin-bottom: 5px;
  }
`;

const PageLabel = styled(Subtitle2)`
  text-transform: uppercase;
  color: black;
  padding-top: 5px;
  font-size: 12px;
  @media (max-width: 1000px) {
    font-size: 9px;
    width: 100%;
    font-family: Platform-Medium, sans-serif;
  }
`;

const LinkBox = styled(Box)`
  border-bottom: 1px solid transparent;
  flex: 0 0 25%;
  justify-content: center;
  align-items: baseline;
`;
