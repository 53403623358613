import React from "react";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import styled, { css } from "styled-components";

function Header({
  date,
  updateOrder,
  orderId,
  cart,
  fixed,
  removeAll,
  dispatch
}) {
  const totalItems = cart.items.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const orderSize = cart.capacity;
  const { wasEdited } = cart;
  const canSave = cart.capacity === totalItems;
  return (
    <div className={`orders_header_container${fixed ? " fixed" : ""}`}>
      <div className="orders_header_progress orders_header_section">
        <div>Your box</div>
        <div
          className={`orders_header_progress_number${canSave ? "" : " error"}`}
        >
          {totalItems}
        </div>
        <div>of</div>
        <div className="orders_header_progress_number">{orderSize}</div>
      </div>
      <div className="orders_header_date orders_header_section">{date}</div>
      <div className="orders_header_buttons_container orders_header_section">
        <Link className="orders_header_link" to="/orders">
          <Button>Cancel</Button>
        </Link>
        <Button
          disabled={!wasEdited || !canSave}
          onClick={
            !wasEdited || !canSave ? () => {} : () => updateOrder(orderId, cart)
          }
        >
          Save
        </Button>
      </div>
      <div
        onClick={() => dispatch(removeAll())}
        className="orders_header_removeAll orders_header_section"
      >
        Remove All
      </div>
    </div>
  );
}

const Button = styled.div`
  border-radius: 20px;
  border: solid 1px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 8px 12px;
  color: #241a1b;
  font-family: Platform-Medium;
  font-size: 16px;
  cursor: pointer;
  margin: 0px 12px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      border: 0px;
      cursor: not-allowed;
    `};
`;

export default Header;
