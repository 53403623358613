import React from "react";
import styled, { css } from "styled-components";
import {
  BlueButton,
  yumiOrange,
  oatCreamy,
  blue,
  blueberry
} from "../../styledcomponents";
import { Body } from "../../styledcomponents/typefaces";
import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_OneOffPurchase/${name}${extension}`;

// components
export const AddToCartButton = ({
  width,
  padding,
  fontSize,
  setIsShelfOpen
}) => (
  <OrangeButton
    width={width}
    padding={padding}
    fontSize={fontSize}
    value="ADD TO CART"
    onClick={() => setIsShelfOpen(old => !old)}
  />
);

const OrangeButton = styled(BlueButton)`
  color: white;
  font-size: ${({ fontSize }) => fontSize || "12px"};
  padding: ${({ padding }) => padding || "10px"};
  background: ${yumiOrange};
  width: ${({ width }) => width || "200px"};
  box-shadow: 0px 1px 3px gray;
`;

export const InnerGridTemplate = ({
  leftChildren,
  rightChildren,
  rightStyling = {},
  leftStyling = {},
  gridDimensions = {},
  gridMidDimensions = {}
}) => (
  <InnerGrid
    gridDimensions={gridDimensions}
    gridMidDimensions={gridMidDimensions}
  >
    <div />
    <Left css={leftStyling}>{leftChildren}</Left>
    <Right css={rightStyling}>{rightChildren}</Right>
    <div />
  </InnerGrid>
);

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ gridDimensions }) =>
    gridDimensions && gridDimensions};

  @media (max-width: 1200px) {
    grid-template-columns: ${({ gridMidDimensions, gridDimensions }) =>
      gridMidDimensions || gridDimensions};
  }

  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;
const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    padding-left: 15px;
    width: 95%;
  }
`;

// receipt
export const Receipt = ({
  selectedPlan,
  selectedQuantity,
  price,
  big = false,
  noBorder = false
}) => (
  <ReceiptRow noBorder={noBorder} big={big}>
    <BundleImage big={big}>
      <BundleCol big={big}>
        <img
          src={Hazmat}
          alt="haz"
          css={css`
            width: ${big ? "46px" : "24px"};
          `}
        />
        <img
          src={Nutrition}
          alt="haz"
          css={css`
            width: ${big ? "46px" : "24px"};
          `}
        />
        <img
          src={Calendar}
          alt="haz"
          css={css`
            width: ${big ? "46px" : "24px"};
          `}
        />
      </BundleCol>
      <img
        src={FirstFoodsMini}
        alt="first"
        css={css`
          width: ${big ? "141px" : "76px"};
        `}
      />
    </BundleImage>
    <div css="display:flex;flex-direction:column; width:100%;">
      <CartBody css="width:100%" big={big}>
        {selectedPlan.label}
        {selectedPlan.value === "weekly"
          ? "(One Delivery)"
          : "(Four Deliveries)"}
      </CartBody>
      {big && (
        <CartBody
          css={css`
            color: gray;
            font-size: 12pt;
          `}
        >
          Includes:
        </CartBody>
      )}
      <CartBody
        big={big}
        css={css`
          color: gray;
          font-size: ${big ? `11pt` : `8pt`};
          padding-top: 4px;
          @media (max-width: 800px) {
            padding-top: 2px;
            padding-bottom: 10px;
          }
        `}
      >
        Variety Pack - <BlueberrySpan>({8 * selectedQuantity})</BlueberrySpan>{" "}
        jars <br />
        Baby Hazmat Suit - <BlueberrySpan>(1)</BlueberrySpan>
        <br /> Nutritional Coaching Session -
        <BlueberrySpan>(1)</BlueberrySpan> <br />Intro to Solids Guide -{" "}
        <BlueberrySpan>(1)</BlueberrySpan>
      </CartBody>
    </div>

    {!big && <CartBody>{price > 0 ? `$${price}` : "Free"}</CartBody>}
  </ReceiptRow>
);

const BlueberrySpan = styled.span`
  color: ${blueberry};
`;

const CartBody = styled(Body)`
  font-size: ${({ big }) => (big ? "15pt" : "9pt;")};
  line-height: 1.5em;
  color: black;

  @media (max-width: 800px) {
    font-size: 9pt;
    line-height: 1em;
  }
`;
const BundleImage = styled.div`
  display: grid;
  grid-template-columns: ${({ big }) => (big ? "50px 150px" : "27px 77px;")};
`;
const BundleCol = styled.div`
  display: grid;
  grid-template-rows: ${({ big }) =>
    big ? "47px 47px 47px" : "26px 26px 24px"};
`;
const ReceiptRow = styled.div`
  display: grid;
  border-top: ${({ big, noBorder }) =>
    big || noBorder ? "none" : "1px solid gray"};
  padding-top: 10px;
  margin-top: 10px;
  grid-template-columns: ${({ big }) =>
    big ? "210px 400px" : "120px 170px 50px;"};
  align-items: flex-start;
  justify-content: ${({ big }) => big && "center"};
`;

// images and more
export const OrangeAddIcon = getImageURL("AddOrange", ".svg");
export const YumiBaby = getImageURL("YumiBaby", ".jpeg");
export const VarPack = getImageURL("VarPackMin", ".png");
export const LittleCarrot = getImageURL("LittleCarrot", ".svg");
export const Berry = getImageURL("Berry", ".svg");
export const LittleTomato = getImageURL("LittleTomato", ".svg");
export const Banana = getImageURL("Nanner", ".svg");
export const Pine = getImageURL("Pine", ".svg");
export const PinkThing = getImageURL("HugeThing", ".svg");
export const GiantAvocad = getImageURL("GiantAvocad", ".svg");
export const Kiwi = getImageURL("DaKiwi", ".svg");

// crowd pleaser
export const NewYork = getImageURL("nytone", ".svg");
export const FoodNWine = getImageURL("FoodNWinesvg", ".svg");
export const CNN = getImageURL("CNNone", ".svg");
export const goop = getImageURL("goopone", ".svg");
export const Inc = getImageURL("IncTwo", ".svg");

export const BabyTime = getImageURL("Nutrients_StopMotion", ".gif");
export const CuriousKid = getImageURL("CuriousKidGif", ".mp4");

export const AllJars = getImageURL("AllJarce", ".jpeg");
export const StarterPack = getImageURL("HugeStarter", ".png");
export const Calendar = getImageURL("Intro-min", ".png");
export const SecondGif = getImageURL("BabySecond", ".gif");

export const Hazmat = getImageURL("HazmatFunny", ".jpeg");
export const Nutrition = getImageURL("EmailBoi", ".png");
export const FirstFoodsMini = getImageURL("BluejARSS", ".jpeg");

export const YumiLogoSmall = getImageURL("YumiLogoSmall", ".svg");
export const ApplePayLogo = getImageURL("ApplePsay", ".png");
export const DownArrow = getImageURL("angle-arrow-down", ".svg");

// confirmation page
export const BabySmall = getImageURL("smallbaby-min", ".png");
export const Broccoli = getImageURL("Brocco", ".jpeg");
export const YellowQuad = getImageURL("Yello-min", ".png");
export const BigCarrot = getImageURL("GiantCare", ".svg");
export const SilverBend = getImageURL("SilverBend", ".svg");

// menu styles
export const menuStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    display: "none"
  },
  bmBurgerBars: {
    background: "#373a47"
  },
  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
    display: "none"
  },
  bmCross: {
    background: "#bdc3c7",
    display: "none"
  },
  bmMenuWrap: {
    position: "fixed",
    top: "0",
    minWidth: "380px",
    maxWidth: "380px"
  },
  bmMenu: {
    background: oatCreamy,
    padding: "0.0em 0.0em 0",
    fontSize: "1.15em",
    overflow: "hidden"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad"
  },
  bmItem: {
    display: "inline-block"
  },
  bmOverlay: {
    top: "0",
    background: "rgba(0, 0, 0, 0.3)",
    position: "fixed"
  }
};
