import React, { useState } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { CarrotChop, Sorghum, Ginger, Sumac } from "../assets";
import { yumiGreen } from "../../../styledcomponents";
import { H3, Body } from "../../../styledcomponents/typefaces";

const ingredients = [
  {
    title: `Sorghum Berry`,
    image: Sorghum,
    description: `Sorghum berries are a powerful ancient grain and antioxidant. Jordan
            is obsessed with sourcing grains that are unrefined and produced in
  small quantities.Sorghum is rich in niacin and thiamin, two
B - vitamins that help metabolize and absorb nutrients.`
  },
  {
    title: `Ginger`,
    image: Ginger,
    description: `Ginger has been used for thousands of years for medicinal purposes.
            Ginger is known to relieve gastrointestinal irritation and
            constipation.`
  },
  {
    title: `Sumac`,
    image: Sumac,
    description: `Sumac is a Mediterranean and Middle Eastern spice made from dried
            sumac berries, known for it's tart and earthy flavors. It's also a
            powerful antioxidant.`
  }
];
export const HighlightsFromKitchen = ({ width }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <HighlightsCol>
      <CarrotSlice>
        <img
          src={CarrotChop}
          alt="Car"
          css="height:554px; object-fit:cover;width:100%;
          @media(max-width:1000px){
          width:100%
          height:149px;
          }
          @media(max-width:500px){
            width:100%;
            height:149px;
          }
          "
        />
      </CarrotSlice>
      <HighlightText>
        <H3
          css={css`
            font-size: 40px;
            padding-bottom: 70px;
            padding-top: 80px;

            @media (max-width: 1000px) {
              font-size: 25px;
            }
            @media (max-width: 500px) {
              font-size: 19px;
            }
          `}
        >
          Highlights From Our Test Kitchen
        </H3>
        <HighlightsSection>
          {ingredients.map((ingredient, i) => (
            <ImageAndText onClick={() => setActiveIndex(i)}>
              <img
                src={ingredient.image}
                alt="sor"
                css="width:200px;@media(max-width:1000px){
            width:150px;
          }@media(max-width:500px){
            width:100px;
          }"
              />
              <H3
                css={css`
                  font-size: 40px;
                  padding-bottom: 5px;

                  padding-top: 20px;

                  @media (max-width: 1000px) {
                    font-size: 15px;

                    &:hover {
                      cursor: pointer;
                    }
                  }
                `}
              >
                {ingredient.title}
              </H3>
              <BorderBottom active={i === activeIndex} />
              <Body
                css="font-size:16px;line-height:1.5em;width:80%; @media (max-width: 1000px) {
            display: none;
            }"
              >
                {ingredient.description}
              </Body>
            </ImageAndText>
          ))}
        </HighlightsSection>
        {width < 1000 && (
          <Body css="width:90%;">{ingredients[activeIndex].description}</Body>
        )}
      </HighlightText>
    </HighlightsCol>
  );
};

const BorderBottom = styled.div`
  width: 0px;
  @media (max-width: 1000px) {
    width: ${({ active }) => active && "70px"};
    border-bottom: 2px solid black;
    transition: width 0.1s ease-in;
  }
`;

const ImageAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HighlightText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    padding-bottom: 40px;
  }
`;
const CarrotSlice = styled.div`
  object-fit: cover;
  @media (min-width: 500px) {
    height: 554px;
  }
`;
const HighlightsCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const HighlightsSection = styled.div`
  display: grid;
  justify-content: Center;
  text-align: center;
  padding-bottom: 70px;
  grid-template-columns: 350px 350px 350px;
  @media (max-width: 1000px) {
    grid-template-columns: 190px 190px 190px;

    padding-bottom: 20px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 120px 120px 120px;

    padding-bottom: 20px;
  }
`;
