import NativeSlider, { Settings } from "react-slick";
import React from "react";
import styled from "styled-components";
import { Button, H2, H4, H3, H5 } from "@team-yumi/ui";
import "./styles.css";
import { formatDatePretty } from "../../../utils/dates";

const box = "https://email.helloyumi.com/assets/Add-On/box1.png";
const box2 = "https://email.helloyumi.com/assets/Add-On/SkiporEdit.png";
const box3 = "https://email.helloyumi.com/assets/Add-On/Delivery.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

const Slider = ({ orderData }) => (
  <StyledSlider {...settings}>
    <SliderItem>
      <SliderImage src={box3} />
      <H4 style={{ textAlign: "center" }}>First Delivery</H4>
      <IntroP>{formatDatePretty(orderData.delivery_date)}</IntroP>
    </SliderItem>
    <SliderItem>
      <SliderImage src={box2} />
      <H4 style={{ textAlign: "center" }}>Skip or Edit Future Orders</H4>
      <IntroP>
        Just be sure to make any changes before our weekly cut-off time,
        Tuesdays at 11:59pm PT{" "}
      </IntroP>
    </SliderItem>
    <SliderItem>
      <SliderImage src={box3} />
      <H4 style={{ textAlign: "center" }}>Made Fresh Weekly</H4>
      <IntroP>
        We make our food fresh every week. Our trays and jar labels change
        colors to help you identify and toss old jars in the fridge.
      </IntroP>
    </SliderItem>
  </StyledSlider>
);

const StyledSlider = styled(NativeSlider)`
  width: 30%;
  *:focus {
    outline: 0;
    outline: none;
  }
  @media (max-width: 1100px) {
    width: 80%;
  }
`;

const SliderImage = styled.img`
  width: 100%;
  height: 200px;
  width: auto;
  margin: auto;
`;

const IntroP = styled.p`
  font-family: "Ginger";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  align-self: center;
  margin: 0px 10px 40px 0px;
  width: 100%;
  color: #4a4a4a;
`;

const SliderItem = styled.div`
  padding: 2px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: #000000;
`;
export default Slider;
