import styled, { css } from "styled-components";
import React from "react";
import { getCouponText } from "../../../utils/coupon";

export const Receipt = ({
  date,
  coupon,
  tax,
  weeklyTotal,
  numMeals,
  total
}) => (
  <ReceiptContainer>
    <ReceiptHeader>
      <ReceiptRow>Estimated Delivery</ReceiptRow>
      <ReceiptRow bold>{date}</ReceiptRow>
    </ReceiptHeader>
    <ReceiptBody>
      <ReceiptRow>
        <ReceiptField>{numMeals} jars</ReceiptField>
        <ReceiptField>${weeklyTotal}/weekly</ReceiptField>
      </ReceiptRow>
      <ReceiptRow>
        <ReceiptField>Tax</ReceiptField>
        <ReceiptField>${tax}</ReceiptField>
      </ReceiptRow>
      <ReceiptRow>
        <ReceiptField>Shipping</ReceiptField>
        <ReceiptField>Free</ReceiptField>
      </ReceiptRow>
      {coupon ? (
        <ReceiptRow>
          <ReceiptField>Coupon</ReceiptField>
          <ReceiptField>{getCouponText(coupon)}</ReceiptField>
        </ReceiptRow>
      ) : null}
      <ReceiptRow bold>
        <ReceiptField>Total</ReceiptField>
        <ReceiptField>${total.toFixed(2)}</ReceiptField>
      </ReceiptRow>
    </ReceiptBody>
  </ReceiptContainer>
);

const ReceiptContainer = styled.div`
  width: 100%;
  padding: 48px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 220px;
`;

const ReceiptBody = styled.div``;

const ReceiptField = styled.div``;

const ReceiptHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    margin: 2px;
  }
`;

const ReceiptRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0px;
  color: #000000;
  opacity: 0.5;
  font-family: Avenir-Roman;
  font-size: 15px;

  ${props =>
    props.bold &&
    css`
      font-family: Platform-Medium;
      font-size: 15px;
      opacity: 1;
    `};
`;
