import spacetime from "spacetime";
import * as types from "../types";

export const updateCheckoutInfo = payload => ({
  type: types.UPDATE_CHECKOUT_INFO,
  payload: { ...payload }
});

export const updateUserId = payload => async dispatch => {
  await dispatch({
    type: types.ADD_USER,
    payload: {
      id: payload.id,
      expiresAt: spacetime
        .now("America/Los_Angeles")
        .add(30, "minutes")
        .format("iso")
    }
  });
  return true;
};

export const clearCheckout = () => ({
  type: types.CLEAR_CHECKOUT_INFO
});
