import React from "react";
import styled from "styled-components";
import "styled-components/macro";
import { useWindowSize } from "../../hooks";
import { oatCreamy } from "../../styledcomponents";
import {
  KeepThemOut,
  Carrots,
  HowTheyGetIn,
  AMapToTheMetals,
  HowWereDifferent,
  AGuide,
} from "./sections";
import { FreshMadeWeekly, LetsStart } from "../OneOffPurchase/sections";
import { BellPepperTwo, OrangeLinkButton } from "./assets";
import { CheckOurSources } from "../Milestones/sections";

const sources = [
  {
    link:
      "https://www.consumerreports.org/food-safety/heavy-metals-in-baby-food/",
    citation: "Consumer Reports",
  },
  {
    link:
      "https://www.eatright.org/health/wellness/preventing-illness/how-to-fight-lead-exposure-with-nutrition",
    citation: "Academy of Dietetics and Nutrition",
  },
  {
    link: "https://www.hindawi.com/journals/isrn/2011/402647/",
    citation: "Heavy Metals in Contaminated Soils",
  },
  {
    link: "https://www.sciencedaily.com/releases/2012/06/120629005456.html",
    citation: "What You Eat Can Prevent Arsenic Overload",
  },
  {
    link:
      "https://www.consumerreports.org/cro/magazine/2015/01/how-much-arsenic-is-in-your-rice/index.htm",
    citation: "Consumer Reports: How Much Arsenic is in Your Brown Rice",
  },
  {
    link:
      "https://www.dhhs.tas.gov.au/__data/assets/pdf_file/0008/56384/Appendix_1b_-_WHAT_YOU_NEED_TO_KNOW_ABOUT_CADMIUM",
    citation:
      "What You Need To Know About Cadmium, How It Affects You And What You Can Do To Reduce Your Risk",
  },
  {
    link: "https://www.ncbi.nlm.nih.gov/pubmed/19106447",
    citation: "Cadmium & Its Adverse Effects on Human Health",
  },
  {
    link:
      "https://www.omicsonline.org/proceedings/anthocyanin-acts-as-scavenger-for-heavy-metal-ions-attack-cancer-cell-and-interacts-with-uric-acid-and-urea-to-expel-it--74817.html",
    citation: "Anthocyanin Acts as Scavenger for Heavy Metal Ions...",
  },
  {
    link:
      "https://www.omicsonline.org/proceedings/anthocyanin-acts-as-scavenger-for-heavy-metal-ions-attack-cancer-cell-and-interacts-with-uric-acid-and-urea-to-expel-it--74817.html",
    citation: "EWG Breakfast With A Dose of Weed Killer?",
  },
  {
    link: "https://www.cleanlabelproject.org/infant-formula-baby-food/",
    citation: "Infant Formula and Babyfood Study",
  },
];
export const HeavyMetals = () => {
  const { width } = useWindowSize();
  return (
    <LandingSections>
      {width < 500 && <OrangeLinkButton fixed value="LEARN MORE" to="/" />}
      <KeepThemOut />
      <Carrots width={width} />
      <HowWereDifferent width={width} />
      <HowTheyGetIn width={width} />
      <AMapToTheMetals width={width} />
      <AGuide width={width} />
      <FreshMadeWeekly width={width} image={BellPepperTwo} />
      <CheckOurSources alt sources={sources} />
      <LetsStart width={width} background={oatCreamy} />
    </LandingSections>
  );
};

const LandingSections = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
