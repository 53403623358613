/* eslint-disable camelcase */
import { reject } from "q";
import { request, post, postV2, put, register } from "../utils/request";

/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} email The email we are trying to verify
 * @param  {string}  password the password hash we are trying to verify
 *
 * @return {boolean} indicated whether or not signin worked
 */
const signIn = async (email, passwordHash) => {
  // Add login to tell if signIn is true or not
  post("/users", { email: "user@user.com", password: "password123!" });
};

export const getZipcode = async placeId => {};

/**
 * @param   {string}    email - The email the user entered during checkout
 * @param   {string}    birthday - Baby's birthday
 * @param   {number}   zipcode - Zipcode of customer
 *
 * @return  {string}   info.delivery - Date of next delivery
 * @return  {boolean}  info.servicable - Indicates if postal code is servicable
 */
/*
 * TODO: POST LEADS
 * Will return delivery date/whether or not servicable
 *  -> For now using hardcoded delivery date value
 *  -> Once this endpoint is updated, an error screen can be created for checkout flow
 *     if postal code is not servicable
 */
export const postLead = async (email, zipCode) => {
  const segmentUserId = window.analytics
    ? window.analytics.user().anonymousId()
    : "id-blocked";
  return postV2("leads", { email, segmentUserId, zipCode });
};

/**
 * @param   {string}    date - The date of the next delivery
 * @param   {string}    birthday - Baby's birthday
 * @param   {Number}    mealPlan - ID of meal plan (quantity/week)
 *
 * @returns {Array}     collection of special menus
 *
 *
 */

export const placeOneOffOrder = async ({
  email,
  name,
  phone,
  address,
  stripe_card_token,
  billing_plan,
  timeline,
  special_menu_id,
  segment_user_id,
  count,
  child_birthday
}) =>
  post("auth/register/one-off", {
    email,
    name,
    phone,
    address,
    stripe_card_token,
    billing_plan,
    timeline,
    special_menu_id,
    segment_user_id,
    count,
    child_birthday
  });

const special_menus = [
  {
    id: 31,
    name: "Variety Pack",
    size: 7,
    type: "custom",
    priority: false,
    age: 0,
    start_date: "2019-08-12T00:00:00.000Z",
    menus: [
      {
        meal_id: 470,
        meal_name: "Cubano Bowl",
        menu_id: 1,
        stage: 3,
        quantity: 1,
        ingredients: "Flax, Kale, Banana, Black Beans"
      },
      {
        meal_id: 473,
        meal_name: "Mango Buckwheat Bowl",
        menu_id: 5,
        stage: 3,
        quantity: 1,
        ingredients: "Mango, Sweet Potato, Coconut Milk, Buckwheat Meal"
      },
      {
        meal_id: 480,
        meal_name: "Kiwi Chia Pudding",
        menu_id: 4,
        stage: 3,
        quantity: 1,
        ingredients: "Kiwi, Coconut Milk, Quinoa, Banana"
      },
      {
        meal_id: 482,
        meal_name: "Bell Pepper Bowl",
        menu_id: 2,
        stage: 3,
        quantity: 1,
        ingredients: "Sweet Potato, Red Bell Pepper, Apple, Chard"
      },
      {
        meal_id: 485,
        meal_name: "Baby Borscht Soup",
        menu_id: 6,
        stage: 2,
        quantity: 1,
        ingredients: "Beets, Flax, Coconut Milk, Spinach"
      },
      {
        meal_id: 1141,
        meal_name: "Texas Chili",
        menu_id: 7,
        stage: 2,
        quantity: 1,
        ingredients: "Sweet Potato, Apple, Black Beans, Cinnamon"
      },
      {
        meal_id: 1221,
        meal_name: "Apple Pie",
        menu_id: 3,
        stage: 2,
        quantity: 2,
        ingredients: "Apple, Japanese Sweet Potato, Coconut Milk, White Beans"
      }
    ]
  },
  {
    id: 39,
    name: "Starters Only",
    size: 7,
    type: "rec",
    priority: false,
    age: 0,
    start_date: "2019-08-12T00:00:00.000Z",
    menus: [
      {
        meal_id: 1360,
        meal_name: "Mango",
        menu_id: 15,
        stage: 1,
        quantity: 1,
        ingredients: null
      },
      {
        meal_id: 502,
        meal_name: "Sweet Potato",
        menu_id: 11,
        stage: 1,
        quantity: 1,
        ingredients: "Sweet Potato"
      },
      {
        meal_id: 541,
        meal_name: "Beet",
        menu_id: 16,
        stage: 1,
        quantity: 1,
        ingredients: "Beets"
      },
      {
        meal_id: 781,
        meal_name: "Kiwi",
        menu_id: 12,
        stage: 1,
        quantity: 1,
        ingredients: "Kiwi"
      },
      {
        meal_id: 821,
        meal_name: "Black Beans",
        menu_id: 14,
        stage: 1,
        quantity: 1,
        ingredients: "Black Beans"
      },
      {
        meal_id: 841,
        meal_name: "Apple",
        menu_id: 13,
        stage: 1,
        quantity: 1,
        ingredients: "Apple"
      },
      {
        meal_id: 901,
        meal_name: "Japanese Sweet Potato",
        menu_id: 10,
        stage: 1,
        quantity: 1,
        ingredients: "Japanese Sweet Potato"
      },
      {
        meal_id: 1300,
        meal_name: "Chard",
        menu_id: 9,
        stage: 1,
        quantity: 1,
        ingredients: "Chard"
      }
    ]
  }
];

export const getSpecialMenusOld = async () =>
  new Promise((resolve, _) => resolve(special_menus));

export const getSpecialMenus = async (date, birthday, mealPlan) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }special-menus?date=${date}&baby_birthday=${birthday}&meal_plan_id=${mealPlan}&type=all`
  );

export const getFrozenSpecialMenus = async (date, birthday, mealPlan) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }special-menus?type=frozen&meal_plan_id=${mealPlan}&date=${date}&baby_birthday=${birthday}`
  );

export const getQuads = async (date, isAddOn = false) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}quads?date=${date}&isAddOn=${isAddOn}`
  );

export const getCoupon = async ({
  coupon,
  zip,
  bundle,
  billing_plan,
  timeline,
  resub = false
}) =>
  post(`coupon`, {
    coupon,
    zip,
    billing_plan,
    timeline,
    bundle,
    resub
  });

export const registerUser = async ({
  email,
  name,
  phone,
  address,
  stripe_card_token,
  billing_plan,
  timeline,
  order_quads,
  order_attributes,
  coupon_id,
  referral_code,
  gift_code,
  special_menu_id,
  segment_user_id,
  child_birthday
}) =>
  register("auth/register", {
    email,
    name,
    phone,
    address,
    stripe_card_token,
    billing_plan,
    timeline,
    order_quads,
    gift_code,
    order_attributes,
    coupon_id,
    referral_code,
    special_menu_id,
    segment_user_id,
    child_birthday
  });

export const registerUserWithBundle = async ({
  email,
  name,
  phone,
  address,
  stripe_card_token,
  billing_plan,
  deliveries,
  timeline,
  order_quads,
  order_attributes,
  coupon_id,
  referral_code,
  special_menu_id,
  segment_user_id,
  child_birthday
}) =>
  register("auth/register/bundle", {
    email,
    name,
    phone,
    address,
    stripe_card_token,
    billing_plan,
    deliveries,
    timeline,
    order_quads,
    order_attributes,
    coupon_id,
    referral_code,
    special_menu_id,
    segment_user_id,
    child_birthday
  });

export const confirmUser = async (
  userId,
  babyName,
  babyId,
  password,
  referred_by
) => {
  let baby;
  if (babyId > 0) {
    baby = { name: babyName, child_id: babyId };
  } else {
    baby = null;
  }

  return put(`users/${userId}`, {
    baby,
    password,
    referred_by
  });
};

export const setPassword = async (userId, password) =>
  put(`users/${userId}`, {
    password
  });

export const getReferral = async referral_code =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}coupon/referral/${referral_code}`
  );

export const getSurveySource = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}sources`
  );

export const postSurveySource = async source =>
  post("sources", {
    source
  });

export const purchaseCredit = async ({
  deliveries,
  meal_plan,
  stripe_card_token,
  coupon_id
}) =>
  post("credits/purchase", {
    deliveries,
    meal_plan,
    stripe_card_token,
    coupon_id
  });
