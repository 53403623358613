import React from "react";
import _ from "lodash";
import "./styles/styles.css";
import { MenuCard } from "../../../../../../styledcomponents/containers/MenuCard";
import "styled-components/macro";
import { Box, Page } from "../../../../../../styledcomponents";

const computeTotalItems = state =>
  state.items.reduce((total, item) => total + item.quantity, 0);

function EditMenuContainer({
  menu,
  addToOrder,
  decrementItem,
  cart,
  dispatch,
  incrementItem
}) {
  const totalItems = computeTotalItems(cart);
  return (
    <Page css="align-items: center;">
      <Box row wrap css="max-width: 1100px; margin-bottom: 60px;">
        {menu.map(
          item =>
            item.stage !== 4 && (
              <MenuCard
                key={item.meal_id}
                item={item}
                special={[7, 19, 20].includes(item.id)}
                addToCart={() => dispatch(addToOrder(item.meal_id))}
                incrementItem={() => dispatch(incrementItem(item.meal_id))}
                decrementItem={() => dispatch(decrementItem(item.meal_id))}
                isItemInCart={cart.items.some(
                  ({ meal_id }) => meal_id === item.meal_id
                )}
                quantity={_.get(
                  cart.items.find(({ meal_id }) => meal_id === item.meal_id),
                  "quantity",
                  0
                )}
                disabled={totalItems === cart.capacity}
              />
            )
        )}
      </Box>
    </Page>
  );
}

export default EditMenuContainer;
