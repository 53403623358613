import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "../../containers/App/PrivateRoute";
import { InfoPage, CheckoutPage } from "./pages";

const BitesRoutes = ({ user, isAuthenticated }) => (
  <React.Fragment>
    <Route
      exact
      path="/bites"
      component={() => <Redirect to="/bites/info" />}
    />
    <PrivateRoute
      path="/bites/info"
      component={() => <InfoPage user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      path="/bites/checkout"
      component={() => <CheckoutPage user={user} />}
      isAuthenticated={isAuthenticated}
    />
  </React.Fragment>
);
export default BitesRoutes;
