import produce from "immer";
import _ from "lodash";

const computeTotalItems = state =>
  state.items.reduce((total, item) => total + item.quantity, 0);

export const defaultState = {
  capacity: 0,
  items: [],
  menu: [],
  special_menu_id: null,
  initialStateSet: false
};

const getCapacityFromMealPlanId = id => ({ 121: 8, 141: 15, 161: 23 }[id]);

export const editMenuReducer = (state, action) => {
  const { menu, capacity } = state;
  const itemSharesMealId = item => item.meal_id === action.meal_id;
  const totalItems = computeTotalItems(state);
  return produce(state, draft => {
    if (totalItems > capacity || totalItems < 0) {
      draft.items = [];
      return;
    }
    switch (action.type) {
      case "ADD_TO_CART": {
        const itemFromMenu = menu.find(itemSharesMealId);
        draft.items.push({
          menu_id: itemFromMenu.id,
          meal_id: itemFromMenu.meal_id,
          meal_name: itemFromMenu.name,
          quantity: action.quantity
        });
        if (computeTotalItems(draft) > capacity) draft.items = [];
        break;
      }
      case "INCREMENT_ITEM": {
        const item = draft.items.find(itemSharesMealId);
        item.quantity += 2;
        if (
          computeTotalItems(draft) % 2 !== 0 ||
          computeTotalItems(draft) > capacity ||
          item.quantity % 2 !== 0
        )
          draft.items = [];
        break;
      }
      case "DECREMENT_ITEM": {
        const item = draft.items.find(itemSharesMealId);
        item.quantity -= 2;
        draft.items = draft.items.filter(i => i.quantity > 0);
        if (item.quantity % 2 !== 0) draft.items = [];
        break;
      }
      case "REMOVE_ALL": {
        draft.items = [];
        draft.special_menu_id = null;
        break;
      }
      case "SET_INITIAL_STATE": {
        draft.items = action.initialState.items.filter(
          item => ![1521, 1522, 1523, 1524].includes(item.meal_id)
        );
        draft.menu = action.initialState.menu;
        draft.capacity = getCapacityFromMealPlanId(
          action.initialState.meal_plan
        );
        draft.initialStateSet = true;
        draft.special_menu_id = null;
        break;
      }
      case "REMOVE_ITEM": {
        draft.items = draft.items.filter(
          item => item.meal_id !== action.meal_id
        );
        break;
      }
      case "UPDATE_MENU": {
        draft.menu = action.menu;
        const menuMealIDs = new Set(_.map(draft.menu, "meal_id"));
        if (draft.items.some(({ meal_id }) => !menuMealIDs.has(meal_id))) {
          draft.items = [];
          draft.special_menu_id = null;
        }

        break;
      }
      case "UPDATE_MEAL_PLAN": {
        const oldCapacity = draft.capacity;
        draft.capacity = getCapacityFromMealPlanId(action.plan.planId);
        if (draft.capacity !== oldCapacity) {
          draft.items = [];
          draft.special_menu_id = null;
        }
        break;
      }
      case "FILL_SPECIAL_MENU": {
        draft.items = [];
        draft.quads = action.special_menu.quads;
        action.special_menu.menus.forEach(item => {
          draft.items.push({
            menu_id: item.menu_id,
            meal_id: item.meal_id,
            meal_name: item.meal_name,
            quantity: item.quantity
          });
        });
        draft.special_menu_id = action.special_menu.id;
        break;
      }
      default: {
        throw Error(`Unknown action: ${action.type}`);
      }
    }
  });
};

export const addToOrderAction = (meal_id, quantity = 2) => ({
  type: "ADD_TO_CART",
  meal_id,
  quantity
});

export const fillSpecialMenuAction = special_menu => ({
  type: "FILL_SPECIAL_MENU",
  special_menu
});

export const incrementItemAction = meal_id => ({
  type: "INCREMENT_ITEM",
  meal_id
});

export const decrementItemAction = meal_id => ({
  type: "DECREMENT_ITEM",
  meal_id
});

export const removeItemAction = meal_id => ({
  type: "REMOVE_ITEM",
  meal_id
});

export const removeAllAction = () => ({
  type: "REMOVE_ALL"
});

export const setInitialStateAction = state => ({
  type: "SET_INITIAL_STATE",
  initialState: state
});

export const updateMenuAction = menu => ({
  type: "UPDATE_MENU",
  menu
});

export const updatePlanAction = plan => ({
  type: "UPDATE_MEAL_PLAN",
  plan
});
