export function format(src, options) {
  const path = src.split(('assets/'))[1]

  const dimensionHandle = function() {
    if (!options) return '';

    const {w, h } = options
    if (!w && !h) return "";

    let dimensionStr = "";
    if (w && h) dimensionStr = `w_${w},h_${h}`;

    if (w && !h) dimensionStr = `w_${w}`;

    if (h && !w) dimensionStr = `h_${h}`;

    return `${dimensionStr  }/`;
  };

  const prefix =
    "https://res.cloudinary.com/helloyumi/image/upload/q_auto/";

  return `${prefix}${dimensionHandle()}s3/${path}`
}