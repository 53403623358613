import React, { useState, useReducer, useMemo, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import _ from "lodash";
import {
  Stepper,
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive
} from "./steps";
import { Page, oat } from "../../styledcomponents";
import {
  useFormState,
  useMergeState,
  useEffectOnChange,
  useRequest
} from "../../hooks";
import { isValidJSON } from "../../utils/isValidJSON";
import { editMenuReducer, removeAllAction } from "./editMenuReducer";
import {
  updateLocalStorage,
  getCouponFromQueryString,
  useClearCartAfterBirthdayChange,
  getReferralFromQueryString,
  getGiftCodeFromQueryString
} from "./hooks";
import { md5 } from "../../utils/crypto";

const getFieldFromLocalStorage = (field, defaultValue) =>
  isValidJSON(window.localStorage.newCheckout2)
    ? _.get(JSON.parse(window.localStorage.newCheckout2), field, defaultValue)
    : defaultValue;

const planData = [
  {
    count: 1,
    numMeals: 7,
    weekly: { total: 35.0, tax: 3.33 },
    monthly: { total: 112, tax: 10.64 },
    planId: 121,
    image: "https://email.helloyumi.com/assets/_GiftCard/BellPepperOne.jpeg",
    name: "bento-five",
    maxZone: 50,
    mostPopular: false
  },
  {
    count: 2,
    numMeals: 14,
    weekly: { total: 65.0, tax: 6.18 },
    monthly: { total: 208, tax: 19.76 },
    planId: 141,
    image: "https://email.helloyumi.com/assets/_GiftCard/BellPepperTwo.jpeg",
    name: "bento-ten",
    maxZone: 50,
    mostPopular: true
  },
  {
    count: 3,
    numMeals: 21,
    weekly: { total: 90.0, tax: 8.55 },
    monthly: { total: 288.0, tax: 27.36 },
    planId: 161,
    image: "https://email.helloyumi.com/assets/_GiftCard/BellPepperThree.jpeg",
    name: "bento-twenty",
    maxZone: 50,
    mostPopular: false
  }
];

const analyticsStep = ["check", "planSelect", "box", "payment"];

const CheckoutPages = ({ location, match }) => {
  const [formState, setField, setForm] = useFormState(
    getFieldFromLocalStorage("formState", {
      email: "",
      zipcode: "",
      birthday: "",
      phone: ""
    })
  );

  const [maxStep, setMaxStep] = useState(
    getFieldFromLocalStorage("maxStep", 1)
  );

  const [zone, setZone] = useState(getFieldFromLocalStorage("zone", 6));

  const [planState, setPlanState] = useMergeState(
    getFieldFromLocalStorage("planState", {
      selectedPlanIndex: 1,
      billingPeriod: "weekly",
      billingType: "bundle",
      deliveries: 1
    })
  );

  const [cart, dispatch] = useReducer(
    editMenuReducer,
    getFieldFromLocalStorage("cart", {
      capacity: 0,
      items: [],
      wasEdited: false,
      menu: [],
      initialStateSet: "false"
    })
  );

  const [couponState, setCouponState] = useMergeState(
    getFieldFromLocalStorage("couponState", {
      couponString: "",
      couponData: {}
    })
  );

  const [referralState, setReferralState] = useMergeState(
    getFieldFromLocalStorage("referralState", {
      referralString: "",
      referralData: {}
    })
  );

  const [giftState, setGiftCodeState] = useMergeState(
    getFieldFromLocalStorage("giftState", {
      giftString: null,
      giftData: {}
    })
  );

  useClearCartAfterBirthdayChange({ dispatch, removeAllAction, formState });

  updateLocalStorage({
    formState,
    maxStep,
    planState,
    cart,
    couponState,
    zone
  });

  const planIndex = planState.selectedPlanIndex;
  const selectedPlan = useMemo(
    () =>
      _.omit(
        {
          ...planData[planState.selectedPlanIndex],
          ...planData[planState.selectedPlanIndex][planState.billingPeriod]
        },
        "weekly",
        "monthly"
      ),
    [planState]
  );

  const trackEvent = (event, props) => {

  };

  useEffectOnChange(
    () => {
      setMaxStep(Math.min(2, maxStep));
    },
    [planIndex]
  );

  getGiftCodeFromQueryString({ giftState, location, setGiftCodeState });
  getReferralFromQueryString({ referralState, location, setReferralState });
  getCouponFromQueryString({ couponState, location, setCouponState });

  const redirectToMaxStep = () => (
    <Redirect to={`/checkout_new/${maxStep}${location.search}`} />
  );

  return (
    <React.Fragment>
      <Route exact path="/checkout_new" component={redirectToMaxStep} />
      <Route
        path="/checkout_new/:step"
        render={() => (
          <React.Fragment>
            {!["1", "2", "3", "4", "5"].includes(match.params.step) ||
            maxStep < match.params.step
              ? redirectToMaxStep()
              : null}
            <Page
              style={{
                background: oat
              }}
              id="page"
            >
              <Stepper
                step={match.params.step}
                maxStep={maxStep}
                headers={[
                  "Welcome",
                  "Plan",
                  "First Box",
                  "Billing",
                  "Checkout"
                ]}
              />
              <Switch>
                <Route
                  path="/checkout_new/1"
                  render={() => (
                    <StepOne
                      setPlanState={setPlanState}
                      trackEvent={trackEvent}
                      formState={formState}
                      setField={setField}
                      setForm={setForm}
                      setMaxStep={setMaxStep}
                      setZone={setZone}
                      giftState={giftState}
                    />
                  )}
                />
                <Route
                  path="/checkout_new/2"
                  render={() => (
                    <StepTwo
                      birthday={formState.birthday}
                      trackEvent={trackEvent}
                      plans={planData}
                      planState={planState}
                      setPlanState={setPlanState}
                      setMaxStep={setMaxStep}
                      selectedPlan={selectedPlan}
                      dispatch={dispatch}
                      zone={zone}
                    />
                  )}
                />
                <Route
                  path="/checkout_new/3"
                  render={() => (
                    <StepThree
                      trackEvent={trackEvent}
                      birthday={formState.birthday}
                      planState={planState}
                      plans={planData}
                      selectedPlan={selectedPlan}
                      setMaxStep={setMaxStep}
                      maxStep={maxStep}
                      cart={cart}
                      dispatch={dispatch}
                    />
                  )}
                />

                <Route
                  exact
                  path="/checkout_new/4"
                  render={() => (
                    <StepFour
                      trackEvent={trackEvent}
                      cart={cart}
                      selectedPlan={selectedPlan}
                      setPlanState={setPlanState}
                      birthday={formState.birthday}
                      planState={planState}
                      plans={planData}
                      selectedPlan={selectedPlan}
                      setMaxStep={setMaxStep}
                      maxStep={maxStep}
                      cart={cart}
                      dispatch={dispatch}
                    />
                  )}
                />

                <Route
                  exact
                  path="/checkout_new/5"
                  render={({ history }) => (
                    <StepFive
                      trackEvent={trackEvent}
                      cart={cart}
                      selectedPlan={selectedPlan}
                      setPlanState={setPlanState}
                      planState={planState}
                      dispatch={dispatch}
                      referralState={referralState}
                      setReferralState={setReferralState}
                      giftState={giftState}
                      setGiftCodeState={setGiftCodeState}
                      couponState={couponState}
                      setCouponState={setCouponState}
                      signupForm={formState}
                      history={history}
                    />
                  )}
                />
              </Switch>
            </Page>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default CheckoutPages;
