import React, { useState } from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import "styled-components/macro";
import { Image } from "@team-yumi/ui";
import { BlueButton, Form } from "../forms";
import { updateRating } from "../../usecases";

import { Box, H5, H6, blackberry } from "../index";
import { Label, ProductName, Detail } from "../typefaces";

import MenuModal from "./MenuModal";
import { getNewImageUrlFromMealId } from "../../utils/images";

const Organic =
  "https://email.helloyumi.com/assets/_HomePageRefresh/Organic.png";

const Kosher = "https://email.helloyumi.com/assets/_HomePageRefresh/Asset.svg";
const KosherDE =
  "https://email.helloyumi.com/assets/_HomePageRefresh/KosherDE.png";

const thumbsUp =
  "https://email.helloyumi.com/assets/_dashboard/Dashboard_UpThumb.svg";
const thumbsDown =
  "https://email.helloyumi.com/assets/_dashboard/Dashboard_DownThumb.svg";
const thumbsUpActive =
  "https://email.helloyumi.com/assets/_dashboard/Dashboard_UpThumbActive.svg";
const thumbsDownActive =
  "https://email.helloyumi.com/assets/_dashboard/Dashboard_DownThumbActive.svg";

export const MenuCard = ({
  item,
  adjustWidth = false,
  bites = false,
  orderId,
  special = false,
  blends = false,
  quad = false,
  quadmenu = false,
  disabled,
  isItemInCart,
  inModal = false,
  incrementItem,
  decrementItem,
  quantity,
  color = 8,
  addToCart = null,
  showUpcoming = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const borderColor = (colorId) => {
    if (bites) {
      return "#C6C3F5";
    }
    if ([17, 18, 19, 20].includes(item.id)) {
      return "#c4bbf2";
    }

    if (colorId === 9) {
      return "#BCE9FA";
    }

    if (colorId === 10) {
      return "#8ce1b2";
    }

    if (colorId === 11) {
      return "#FEEDA6";
    }

    if (colorId === 12) {
      return "#FEB8B8";
    }

    if (colorId === 8) {
      return "#F9EEE5";
    }

    return false;
  };

  const titleColor = (colorId) => {
    if (bites) {
      return "black";
    }
    if ([17, 18, 19, 20].includes(item.id)) {
      return "black";
    }

    if (colorId === 9) {
      return "black";
    }

    if (colorId === 10) {
      return "black";
    }

    if (colorId === 11) {
      return blackberry;
    }

    if (colorId === 12) {
      return "#C47084";
    }

    if (colorId === 8) {
      return "#F9EEE5";
    }

    return false;
  };

  return (
    <OuterContainer
      blends={blends}
      quad={quad}
      inModal={inModal}
      css={css`
        margin: ${(props) => (props.quad ? "0 8px" : "0 11px")};
      `}
      style={{ width: quadmenu ? "100%" : "" }}
    >
      <Box
        bColor={borderColor(color)}
        full
        row
        onClick={() => openModal()}
        style={{
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: "10px",
          background: "white",
          position: "relative",
          borderRadius: "4px",
        }}
        css={css`
          border: ${(props) => (props.bColor ? `2px solid #FDEBE0` : "")};
          max-height: ${inModal ? "154px" : "400px"};
          min-height: ${inModal ? "154px" : "400px"};
          display: ${inModal && "grid"};
          grid-template-columns: ${inModal && "1fr 1fr"};

          transition: all 0.1s ease-in;

          &:hover {
            cursor: pointer;
            box-shadow: 0px 2px 3px lightgray;
          }
          @media (max-width: 500px) {
            flex-wrap: nowrap;
            max-height: 145px;
            min-height: 144px;
          }
        `}
      >
        {!addToCart &&
          quantity > 0 &&
          !quadmenu && (
            <UpperRightQuantityNumber>{quantity}</UpperRightQuantityNumber>
          )}
        <ImageContainer
          inModal={inModal}
          bites={bites}
          blends={blends}
          special={[23, 25].includes(item.id || item.menu_id)}
          item={item}
          onClick={() => openModal()}
          name="image container"
          bg="#FDEBE0"
          style={{ cursor: quadmenu ? "inherit" : "" }}
          css={css`
            background: ${(props) => props.bg};
          `}
        >
          <Image
            autoformat
            mode="scale"
            w={300}
            src={getNewImageUrlFromMealId(item.meal_id)}
            css={css`
              width: 80px !important;
            `}
            alt={`${item.meal_id} jar`}
            item={item}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `https://email.helloyumi.com/assets/0${
                item.meal_id
              }.png`;
            }}
          />
        </ImageContainer>

        <Box full row>
          <Box
            jc="flex-start"
            ai="flex-start"
            css={css`
              text-align: left;
              flex: 1 0 150px;
              align-self: flex-start;
              padding: 0 25px;
              @media (max-width: 900px) {
                margin-top: 10px;
                padding: 0 10px;
                flex: 1 0 130px;
              }

              @media (max-width: 500px) {
                flex: 1 0 60%;
              }
            `}
          >
            <Label
              color="rgba(0, 0, 0, 0.67)"
              css={css`
                width: 100%;

                @media (max-width: 900px) {
                  font-size: 13px;
                }
              `}
            >
              Stage {item.stage}
            </Label>
            <ProductName
              special={special}
              color="black"
              css={css`
                width: 100%;
                height: ${inModal ? "25px" : "40px"};
                font-size: ${inModal ? "14px" : "22px"};
                line-height: ${inModal ? "16px" : ""};
                @media (max-width: 900px) {
                  height: unset;
                  line-height: 20px;
                  font-size: 15px;
                }
              `}
            >
              {item.meal_name}
            </ProductName>
            <Detail
              css={css`
                margin-bottom: 1em;
                height: 54px;
                font-size: ${inModal ? "10px" : "14px"};
                line-height: 1.5em;
                padding-top: ${(!inModal && item.meal_name.length > 19) ||
                (inModal && item.meal_name.length > 25)
                  ? "15px"
                  : "0px"};
                @media (max-width: 900px) {
                  line-height: 15.8px;
                }

                @media (max-width: 500px) {
                  margin-bottom: 0;
                  margin-top: 4px;
                  height: 35px;
                  font-size: 11px;
                  padding-top: 0px;
                  line-height: 1.1em;
                }
              `}
            >
              {item.ingredients}
            </Detail>
          </Box>

          {!addToCart && (
            <Box
              full
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: ${inModal ? "30px" : "70px"};
                align-items: center;

                @media (max-width: 500px) {
                  justify-content: center;
                  padding: 0 2px 0 0;
                  grid-template-rows: 20px;
                }
              `}
              onClick={() => openModal()}
            >
              <Box
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  width: 100%;
                  flex-wrap: ${inModal && "no-wrap"};
                  padding-left: ${inModal ? "0px" : "26px"};
                  z-index: 3;
                  @media (max-width: 500px) {
                    padding-left: 16px;
                  }
                `}
              >
                {[
                  "Super Smash Burger",
                  "Salare",
                  "Hobak Pat Juk",
                  "Broccoli Lentil Bite",
                ].indexOf(item.meal_name) === -1 &&
                  !item.meal_name.includes("Patty") &&
                  getCerts(bites, null, item.meal_name, inModal)}
              </Box>
              <RedButton
                quad={quad}
                css={css`
                  width: 85%;
                  font-size: ${inModal && "13px"};
                  background-color: "white";
                  :hover {
                    background-color: "white";
                  }

                  @media (max-width: 500px) {
                    margin: 0;
                    font-size: 12px;
                    width: 100%;
                  }
                `}
              >
                See More
              </RedButton>
            </Box>
          )}
        </Box>
        {!showUpcoming && (
          <Rating
            rating={item.rating}
            attributesId={item.order_attribute_id}
            orderId={orderId}
          />
        )}
        {addToCart &&
          !isItemInCart && (
            <Box full style={{ padding: "0 26px 13px 26px" }}>
              <RedButton
                type="button"
                disabled={disabled}
                onClick={addToCart}
                style={{
                  height: "42px",
                  alignSelf: "flex-start",
                  padding: 0,
                  margin: 0,
                }}
              >
                ADD
              </RedButton>
            </Box>
          )}
        {isItemInCart &&
          addToCart && (
            <ButtonSet
              quantity={quantity}
              incrementItem={incrementItem}
              decrementItem={decrementItem}
              disabled={disabled}
            />
          )}
        <MenuModal
          color_id={color}
          item={item}
          backUp={[1503, 1641, 1547, 1470].indexOf(item.meal_id) !== -1}
          bites={bites}
          hasCerts={
            ["Super Smash Burger", "Salare"].indexOf(item.meal_name) === -1 &&
            !item.meal_name.includes("Patty")
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          getImageUrlFromMealId={getImageUrlFromMeal}
          special={special}
          getBadges={(badges) => getCerts(badges)}
        />
      </Box>
    </OuterContainer>
  );
};

const getImageUrlFromMeal = (id, menu_id, color) => {
  if ([23, 25].includes(menu_id)) {
    return `https://email.helloyumi.com/assets/_meals/0${id}-Special.png`;
  }
  return `https://res.cloudinary.com/helloyumi/image/upload/fl_lossy,q_auto,f_auto,w_300/s3/_new-jars/1${id}.png`;
};
const getImageBgUrlFromQuadId = (color) =>
  `https://email.helloyumi.com/assets/_meals/${color}-bg.png`;

const getFirstTwoLinesOfIngredients = (ingredients, maxWidth = 200) => {
  if (!ingredients) {
    return "";
  }
  const ingredientsList = ingredients.split(",");
  if (ingredientsList.length === 1) return "";
  const getCharLength = (str) => {
    const ing = document.createElement("p");
    ing.innerHTML = str;
    ing.id = str;
    ing.style.fontFamily = "Freight-Book";
    ing.style.fontSize = "12px";
    ing.style.visibility = "hidden";
    ing.style.display = "inline-block";
    ing.style.visibility = "hidden";
    document.body.appendChild(ing);
    const width = ing.offsetWidth;
    document.body.removeChild(ing);
    return width;
  };

  const { items } = _.last(
    ingredientsList
      .map((_x, i, arr) => ({
        width: getCharLength(arr.slice(0, i).join(",")),
        items: i + 1,
      }))
      .filter((i) => i.width <= maxWidth)
  );

  return `${ingredientsList.slice(0, items).join(",")}...`;
};

export const getCerts = (
  bites = false,
  type = false,
  meal_name,
  inModal = false
) => (
  <React.Fragment>
    {!bites && (
      <Box
        key="organic"
        css={css`
          margin-right: ${type ? "15px" : "0px"};
        `}
      >
        <img
          src={Organic}
          alt="organic"
          css={css`
            width: ${type && !inModal ? "60px" : "30px"};
            margin-right: ${type ? "0px" : "10px"};
            margin-left: ${inModal && "5px"};

            @media (max-width: 500px) {
              width: ${type ? "45px" : "15px"};
              margin-right: ${type ? "0px" : "5px"};

          `}
        />
      </Box>
    )}
    {((bites && meal_name === "Beet Puffs") || !bites) && (
      <Box
        key="kosher"
        css={css`
          margin-right: ${type ? "15px" : "0px"};
        `}
      >
        {meal_name === "Beet Puffs" ? (
          <img
            src={KosherDE}
            alt="organic"
            css={css`
              width: ${type && !inModal ? "60px" : "30px"};
              margin-right: ${type ? "0px" : "10px"};

              @media (max-width: 500px) {
                width: ${type ? "45px" : "15px"};
                margin-right: ${type ? "0px" : "5px"};
              }
            `}
          />
        ) : (
          <img
            src={Kosher}
            alt="organic"
            css={css`
            width: ${type ? "60px" : "30px"};
            margin-right: ${type ? "0px" : "10px"};

            @media (max-width: 500px) {
              width: ${type ? "45px" : "15px"};
              margin-right: ${type ? "0px" : "5px"};`}
          />
        )}
      </Box>
    )}
  </React.Fragment>
);

const getBadges = (badges, type = null) => {
  if (!badges || badges.length < 1) {
    return "";
  }
  const firstTwoBadges = badges.split(",").slice(0, 2);
  return firstTwoBadges.map((badge) => (
    <Box
      key={badge}
      css={css`
        margin-right: ${type ? "15px" : "0px"};
      `}
    >
      <img
        src={`https://email.helloyumi.com/assets/${badge.trim()}.svg`}
        alt={`${badge}`}
        css={css`
          width: ${type ? "60px" : "30px"};
          margin-right: ${type ? "0px" : "10px"};

          @media (max-width: 500px) {
            width: ${type ? "45px" : "25px"};
            margin-right: ${type ? "0px" : "5px"};
          }
        `}
      />
      {type && (
        <Detail
          css={css`
            font-family: "Platform-Medium";
            color: #334897;
            margin-top: 5px;
          `}
        >
          {badge
            .trim()
            .charAt(0)
            .toUpperCase() + badge.trim().slice(1)}
        </Detail>
      )}
    </Box>
  ));
};

export const ButtonSet = ({
  quantity,
  incrementItem,
  decrementItem,
  disabled,
}) => (
  <Box
    row
    style={{
      width: "100%",
      justifyContent: "center",
      height: "55px",
    }}
    name="buttons and quantity"
  >
    {quantity % 2 === 0 && (
      <QuantityButton onClick={decrementItem} type="button">
        -
      </QuantityButton>
    )}
    <H5
      style={{
        textAlign: "center",
        alignSelf: "center",
        color: "#334897",
      }}
      css={css`
        margin-left: 40px;
        margin-right: 40px;
        @media (max-width: 1000px) {
          margin-left: 20px;
          margin-right: 20px;
        }
      `}
    >
      {quantity}
    </H5>

    {quantity % 2 === 0 && (
      <QuantityButton onClick={incrementItem} type="button" disabled={disabled}>
        +
      </QuantityButton>
    )}
  </Box>
);

export const Rating = ({ rating, orderId, attributesId }) => {
  const [currentRating, setRating] = useState(rating);

  return (
    <RatingArea>
      <Form
        onSubmit={() =>
          updateRating(orderId, attributesId, { rating: currentRating })
        }
      >
        <RatingButton
          type="submit"
          noFill={currentRating == null || currentRating === false}
          onClick={() => setRating(true)}
        >
          <img
            src={currentRating ? thumbsUpActive : thumbsUp}
            alt="thumbs up"
          />
        </RatingButton>
      </Form>
      <Form
        onSubmit={() =>
          updateRating(orderId, attributesId, { rating: currentRating })
        }
      >
        <RatingButton
          type="submit"
          noFill={currentRating == null || currentRating === true}
          onClick={() => setRating(false)}
        >
          <img
            src={currentRating === false ? thumbsDownActive : thumbsDown}
            alt="thumbs down"
          />
        </RatingButton>
      </Form>
    </RatingArea>
  );
};

const RatingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 50%;
  justify-content: space-around;
  padding: 1.5rem;
`;

const RatingButton = styled(BlueButton)`
  padding: 10px;
  background: ${({ noFill }) => (noFill ? "white" : blackberry)};
  color: ${({ noFill }) => (noFill ? "black" : "white")};
  border-radius: 20rem;
  border: ${({ noFill }) => (noFill ? "1px solid #eee" : "none")};
`;

const UpperRightQuantityNumber = styled(H6)`
  background: ${blackberry};
  position: absolute;
  color: white;
  top: 5%;
  right: 10%;
  border-radius: 1em;
  bottom: auto;
  left: auto;
  height: 32px;
  width: 32px;
  text-align: center;
  padding: 7px 0px;

  @media (max-width: 500px) {
    height: 28px;
    width: 28px;
    top: 4%;
    left: 2%;
    font-size: 14px;
  }
`;

const QuantityButton = styled.button`
  height: 32px;
  width: 32px;

  text-align: center;
  align-self: center;
  color: #334897;
  border: 1px solid #334897;
  border-radius: 300px !important;
  cursor: pointer;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Platform-Regular", sans-serif;
  line-height: 1;
  padding: 0px;
  &:focus {
    outline: 0;
  }

  &:active {
    transform: scale(1.1);
    outline: 1px solid dodgerblue;
  }

  &:disabled {
    cursor: not-allowed;
    visibility: hidden;
  }
`;

const RedButton = styled.button`
  color: #ee5a37;
  background-color: white;
  font-family: "Platform-Medium";
  width: 95%;
  font-size: 16px;
  text-align: right;
  z-index: 0;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #ee5a37;
    border-color: white;
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ImageContainer = styled(Box)`
  cursor: pointer;
  width: ${({ inModal }) => (inModal ? "75%" : "100%")};
  background-size: cover;
  background-position-y: center;
  width: 100%;

  height: ${({ blends }) => (blends ? "150px" : "244px")};
  height: ${({ bites }) => bites && "150px"};
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    height: 140px;
    width: 140px;
  }
  overflow: ${({ special }) => special && "hidden"};

  img {
    width: ${({ blends }) => blends && "130px !important"};
    width: ${({ special }) => special && "100%"};
    height: ${({ special }) => special && "180px"};

    @media (max-width: 500px) {
      height: 65px;
      margin: 0 27px;
      width: ${({ blends }) => blends && "77px !important"};
    }
  }

  ${(props) =>
    props.item.stage === 4 &&
    css`
      background: #c4bbf2;
    `};
`;

const OuterContainer = styled.div`
  flex: 0 0 calc(25% - 32px);
  max-width: ${({ blends }) => (blends ? "250px" : "250px")};
  max-width: ${({ inModal }) => (inModal ? "400px" : "250px")};
  min-width: ${({ inModal }) => (inModal ? "380px" : "250px")};

  width: ${({ inModal }) => (inModal ? "400px" : "250px")};

  margin: 0 10px;
  @media (max-width: 1000px) {
    margin: 0 8px;
    flex: 0 0 calc(50% - 16px);
    padding: 0px;
  }

  @media (max-width: 500px) {
    flex: 0 0 calc(100% - 24px);
    max-width: ${({ inModal }) => (inModal ? "350px" : "340px")};
    min-width: ${({ inModal }) => (inModal ? "350px" : "340px")};

    width: ${({ inModal }) => (inModal ? "350px" : "340px")};
  }
`;
export default MenuCard;
