import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  blackberry,
  TextInput,
  BlueButton,
  InputField,
  BasicInput,
  HyperLink,
} from "../../styledcomponents";
import { Body, H3 } from "../../styledcomponents/typefaces";
import { useRequest } from "../../hooks";
import { getReferralLink } from "../../usecases";
import tracker from "../../utils/tracker";

const StarPattern2 =
  "https://email.helloyumi.com/assets/_Referral/Referral_StarPattern2.svg";

const Email = "https://email.helloyumi.com/assets/_Referral/Referral_Email.svg";
const Message =
  "https://email.helloyumi.com/assets/_Referral/Referral_Message.svg";
const FaceBook = "https://email.helloyumi.com/assets/_Referral/Referral_FB.svg";
const Twitter =
  "https://email.helloyumi.com/assets/_Referral/Referral_Twitter.svg";

export const Referrals = ({ user }) => {
  const [copied, setCopied] = useState(false);
  const [textBox, setTextBox] = useState("");

  useEffect(() => {
    tracker.event("ReferralPageLoaded", { userId: user.id });
  }, []);

  const [referralLink, error, Loading] = useRequest(getReferralLink);

  if (Loading) return <Loading />;
  if (error || !referralLink) return <h1> </h1>;

  const { link } = referralLink;

  return (
    <ReferralPage>
      <Section
        css={`
      background-position: center;
      background-size: 350px;
      background: ${blackberry}; 
      background-image: url('${StarPattern2}');`}
      >
        <H3 css="color:white; font-size: 30pt; @media(max-width:600px){font-size:20pt}">
          {copied ? `Thanks for sharing` : `Give 5 jars, get 3 jars.`}
        </H3>
        <Body css="color:white; padding:10px; opacity:0.8; @media(max-width:600px){font-size:10pt}">
          {copied
            ? `Once your friend redeems their reward and recieves 2 shipments, we'll apply the credits to your account.`
            : `Earn 3 jar credits ($15 value) for referring a friend. Plus, they'll
          get 5 jars, on us. Win win.`}
        </Body>
      </Section>
      <Section style={{ padding: "80px" }}>
        <BasicInput
          value={link}
          style={{ padding: "25px" }}
          onChange={(e) => setTextBox(e.target.value)}
        />
        <div css="display:flex; width:360px; @media(max-width:600px){width:237px}">
          <CopyToClipboard text={link}>
            <BlueButton
              style={{
                background: blackberry,
                color: "white",
                padding: "23px",
                marginTop: "10px",
                width: "60%",
              }}
              value="COPY LINK"
              onClick={() => setCopied(true)}
            />
          </CopyToClipboard>
          <HyperLink
            style={{
              background: blackberry,
              color: "white",
              padding: "23px",
              marginTop: "10px",
              width: "20%",
              marginLeft: "8px",
              display: "flex",
              justifyContent: "center",
            }}
            href={`sms: &body=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%205%20jars%20free%20when%20you%20use%20this%20link!%20${link}`}
          >
            <img src={Message} alt="msg" style={{ color: "white" }} />
          </HyperLink>
          <HyperLink
            style={{
              background: blackberry,
              color: "white",
              padding: "23px",
              marginTop: "10px",
              width: "20%",
              marginLeft: "8px",
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
            }}
            href={`mailto: yourfriend@example.com?body=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%205%20jars%20free%20when%20you%20use%20this%20link!%20${link}`}
          >
            <img src={Email} alt="email" style={{ color: "white" }} />
          </HyperLink>
        </div>
        <Body style={{ padding: "15px", fontSize: "12pt" }}>
          <div css="display:flex; width:360px; justify-content:center; @media(max-width:600px){width:237px}">
            Or share via
            <HyperLink
              href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            >
              <img
                src={FaceBook}
                alt="fb"
                style={{ marginLeft: "12px", color: "white" }}
              />
            </HyperLink>
            <HyperLink
              href={`http://twitter.com/share?text=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%205%20jars%20free%20when%20you%20use%20this%20link!&url=${link}`}
            >
              <img
                src={Twitter}
                alt="twit"
                style={{ marginLeft: "12px", color: "white" }}
              />
            </HyperLink>
          </div>
        </Body>
      </Section>
    </ReferralPage>
  );
};

const ReferralPage = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Section = styled.div`
  width: 100%;
  padding: 120px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;
