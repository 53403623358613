import { post } from "../utils/request";

/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} email The email we are trying to verify
 * @param  {string}  password the password hash we are trying to verify
 *
 * @return {boolean} indicated whether or not signin worked
 */
export const signIn = async (email, passwordHash) => {
  // Add login to tell if signIn is true or not
  post("/users", { email: "user@user.com", password: "password123!" });
};

export const registerReferralUser = body =>
  post(`auth/register/referral`, body);
