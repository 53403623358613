import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import "./styles/styles.css";
import { BUTTON_ACTIONS } from "../../types";
import tracker from '../../../../utils/tracker'
import {
  skipOrderAction,
  unskipOrderAction,
  getDetailsAction
} from "../../../../pages/NewOrderPage/Pages/OrdersPage/modalAPI/actions";
import { formatDatePretty } from "../../../../utils/dates";
import EditJarsTooltip from "../EditJarsTooltip";

function ActionsButtonGroup({ group, color, order, dispatch, currentPos }) {
  const [tooltip, setTooltip] = useState(false);

  const hideTooltip = action => {
    if (action === BUTTON_ACTIONS.TOOLTIP) {
      setTooltip(false);
    }
  };

  const showTooltip = action => {
    if (action === BUTTON_ACTIONS.TOOLTIP) {
      setTooltip(true);
    }
  };

  const ActionButtons = () =>
    group.buttons.map(button => {
      const isSnacksOnly = order.orderAttributes.every(item =>
        [1521, 1522, 1523, 1524].includes(item.meal_id)
      );
      return (
        <div style={{ position: "relative" }} key={button.action + order.id}>
          {button.action === "EDIT" && !isSnacksOnly ? (
            <EditButton button={button} />
          ) : null}
          {button.action !== "EDIT" ? (
            <Button
              onMouseLeave={
                window.innerWidth < 480
                  ? () => {}
                  : () => hideTooltip(button.action)
              }
              onMouseOver={() => showTooltip(button.action)}
              onFocus={() => showTooltip(button.action)}
              onClick={e => getOnClickFunction(e, button.action)}
              color={color}
              solid={button.style === "FILLED"}
              skipped={group.type === "SKIPPED"}
              rating={group.type === "RATE"}
              text={button.text}
              action={button.action}
              img={button.img}
            />
          ) : null}

          {tooltip ? (
            <EditJarsTooltip
              close={() => hideTooltip(BUTTON_ACTIONS.TOOLTIP)}
            />
          ) : null}
        </div>
      );
    });

  const EditButton = ({ button }) => (
    <Link
      className="orders_button_link"
      to={`/orders/edit?order=${order.id}&date=${order.delivery_date}`}
    >
      <Button
        onClick={e => getOnClickFunction(e, "EDIT")}
        color={color}
        solid={button.style === "FILLED"}
        skipped={group.type === "SKIPPED"}
        rating={group.type === "RATE"}
        text={button.text}
        action={button.action}
        img={button.img}
      />
    </Link>
  );

  const getOnClickFunction = (e, action) => {
    switch (action) {
      case BUTTON_ACTIONS.SKIP:
        e.preventDefault();
        document.body.classList.add("modal-open");

        tracker.event('SkipOrder', {orderId: order.id})

        dispatch(skipOrderAction(order.id, currentPos));
        break;
      case BUTTON_ACTIONS.UNSKIP:
        e.preventDefault();
        document.body.classList.add("modal-open");

        tracker.event('UnSkipOrder', {orderId: order.id})

        dispatch(unskipOrderAction(order.id));
        break;
      case BUTTON_ACTIONS.GET_MORE_OPTIONS:
        e.preventDefault();
        document.body.classList.add("modal-open");
        dispatch(
          getDetailsAction(
            order.id,
            formatDatePretty(order.delivery_date),
            order.meal_plan_id,
            order
          )
        );
        break;
      case BUTTON_ACTIONS.TOOLIP:
        showTooltip(BUTTON_ACTIONS.TOOLIP);
        break;
      default:
        break;
    }
  };

  return (
    <div className="orders_actionButtonGroup">
      {group ? <ActionButtons /> : null}
    </div>
  );
}

export default ActionsButtonGroup;
