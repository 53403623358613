import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { blackberry, softPurple } from "../../../styledcomponents/colors";
import { PizzaBites } from "../assets";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { BlueButton, H4 } from "../../../styledcomponents";
import "styled-components/macro";
import { useWindowSize } from "../../../hooks";

export const OnlyTheGoodStuff = () => {
  const { width } = useWindowSize();
  return (
    <PageLayout>
      {" "}
      <div css="width: 80%; display:flex;@media(max-width:1000px){display:flex; width:100%; align-items:center;}">
        <H1
          css={css`
            color: ${blackberry};
            width: 80%;
            font-size: 35pt;
            padding-top: 110px;
            padding-bottom: 40px;
            @media (max-width: 1000px) {
              width: 60%;
              padding-top: 30px;
              padding-left: 40px;
            }
          `}
        >
          {" "}
          Only the good stuff{" "}
        </H1>
        {width < 1000 && (
          <JarImage
            src={PizzaBites}
            css={css`
              @media (max-width: 1000px) {
                width: 250px;
              }
              @media (max-width: 500px) {
                width: 160px;
              }
            `}
            alt="pix"
          />
        )}
      </div>
      <ContentArea>
        <InfoGrid>
          <InfoItem>
            <MiniHeader>Rice free.</MiniHeader>
            <GridBody1>
              Rice cereals and puffs are common first foods, but brown rice
              (even organic) is often high in arsenic because rice tends to
              absorb arsenic more readily than other grains.
            </GridBody1>
          </InfoItem>
          <InfoItem>
            {" "}
            <MiniHeader>Salt-free.</MiniHeader>
            <GridBody1>
              Your baby's kidneys can't process much salt or sodium before their
              first birthday, so we don't include salt in any of our foods.{" "}
            </GridBody1>
          </InfoItem>
          <InfoItem>
            {" "}
            <MiniHeader>Nutrient-dense.</MiniHeader>
            <GridBody1>
              Each bite has at least 20% of the recommended daily amount in 5
              key nutrients or more. It’s like a multivitamin, but with real
              food.
            </GridBody1>
          </InfoItem>
          <InfoItem>
            {" "}
            <MiniHeader>Organic.</MiniHeader>
            <GridBody1>
              We use fresh, organic ingredients in all of our foods.
            </GridBody1>
          </InfoItem>
          <Link to="/checkout">
            <BlueButton
              css={css`
                background: ${blackberry};
                color: white;
                padding: 20px;
                width: 150px;

                @media (max-width: 1000px) {
                  margin-top: 30px;
                  padding: 20px;
                }
              `}
              value="GET STARTED"
            />
          </Link>
        </InfoGrid>
        {width > 1000 && (
          <JarImage
            src={PizzaBites}
            css={css`
              width: 390px;

              @media (max-width: 1400px) {
                margin-left: -120px;
              }
            `}
            alt="pix"
          />
        )}
      </ContentArea>
    </PageLayout>
  );
};

const GridBody1 = styled(Body)`
  font-size: 12pt;
  padding-top: 8px;

  line-height: 1.5em;
  width: 80%;

  @media (max-width: 1000px) {
    line-height: 1.3em;
    width: 100%;
  }
  @media (max-width: 700px) {
    line-height: 1.3em;
    width: 70%;
    font-size: 11pt;
  }
  @media (max-width: 500px) {
    line-height: 1.3em;
    width: 100%;
    font-size: 13pt;
  }
`;
const MiniHeader = styled(H4)`
  color: ${blackberry};

  @media (max-width: 1000px) {
    font-size: 14pt;
  }
`;

const InfoItem = styled.div`
  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 95%;
  }
`;
const JarImage = styled.img``;

const InfoGrid = styled.div`
  display: grid;
  grid-template-rows: 170px 170px 100px;
  grid-template-columns: 500px 500px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    grid-template-columns: 290px 290px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 280px;
    grid-template-rows: 190px 150px 140px 110px;
  }
`;

const ContentArea = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`;
const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;
  background: ${softPurple};

  overflow: hidden;
`;
