import { useMergeState } from "./useMergeState";

export const useFormState = initialFormState => {
  const [formState, setFormState] = useMergeState(initialFormState);
  return [
    formState,
    field => e => setFormState({ [field]: e.target.value }),
    setFormState
  ];
};

export default useFormState;
