/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { GiftCardFlowContext } from "../GiftCardFlowContext";
import "styled-components/macro";
import { SelectableCards, SelectableBar } from "../SelectableCards";
import {
  PurpleButton,
  LargePurpleHeader,
  BellPepperOne,
  BellPepperTwo,
  BellPepperThree,
} from "../assets";
import { yumiPurple, H6 } from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";
import { useWindowSize } from "../../../hooks";
import useReload from "../../../hooks/useReload";

const Over100 =
  "https://email.helloyumi.com/assets/_HomePageRefresh/Over100.png";

const giftWeeksData = [
  {
    image: BellPepperOne,
    title: "Week",
    body: "$65 covers 2 meals per day 1 week.",
    weekCount: 1,
    discount: 10,
    value: 65,
  },
  {
    image: BellPepperTwo,
    title: "Weeks",
    body: "$130 covers 14 meals per week delivered over 2 weeks.",
    weekCount: 2,
    discount: 35,
    value: 130,
  },
  {
    title: "Weeks",
    image: BellPepperThree,
    body: "$260 covers 14 meals per week delivered over 4 weeks.",
    weekCount: 4,
    discount: 65,
    value: 260,
  },
  {
    title: "Weeks",
    titleChildren: `weeks`,
    weekCount: 8,
    image: Over100,
    body: "$520 covers 14 meals per week delivered over 8 weeks.",
    discount: 140,
    value: 520,
  },
];

export const StepTwo = ({ location }) => {
  const { progress, dispatch, history } = useContext(GiftCardFlowContext);
  const { width } = useWindowSize();
  const [amount, setAmount] = useState({ selected: 65, custom: 0 });
  const [activeIndex, setActiveIndex] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [emptyError, setEmptyError] = useState("");
  const [once, setOnce] = useState(true);
  const [coupon, setCoupon] = useState();

  const setAmountFunction = (custom, amountString) => {
    const number = Number(amountString);
    const re = /^[0-9\b]+$/;

    if (number !== "" || re.test(number)) {
      custom
        ? setAmount({ selected: 0, custom: number })
        : setAmount({ custom: 0, selected: number });
      setOnce(false);
    }
  };

  useReload()

  useEffect(
    () => {
      location.search.includes("coupon") &&
        location.search.split("=")[1].includes("GIFT") &&
        setCoupon({
          code: location.search.split("=")[1],
          amount: Number(location.search.split("=")[1].split("GIFT")[1]) * 100,
        });
      location.search.includes("=") &&
        !location.search.includes("coupon") &&
        location.search.length > 1 &&
        location.search.length < 20 &&
        once &&
        setAmountFunction(false, Number(location.search.split("=")[1]) / 100);

      giftWeeksData.indexOf(
        giftWeeksData.find((item) => item.value === amount.selected)
      ) !== -1
        ? setInitialIndex(
            giftWeeksData.indexOf(
              giftWeeksData.find((item) => item.value === amount.selected)
            )
          )
        : setInitialIndex(0);
    },
    [initialIndex, amount]
  );

  return (
    <StepContainer>
      <LargePurpleHeader>
        How much food would you like to gift?
      </LargePurpleHeader>

      {width > 1000 ? (
        <DesktopSelection>
          <AmountSection>
            <ImageSection>
              <PurpleCircle
                value={amount.custom > 0 ? amount.custom : amount.selected}
              >
                ${amount.custom > 0 ? amount.custom : amount.selected}
              </PurpleCircle>
              <img
                src={BellPepperOne}
                alt="emma"
                css={css`
                  width: 273px;
                  height: 196px;
                  object-fit: cover;
                  border-radius: 21px;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                `}
              />
              <Body
                css={css`
                  font-size: 16px;
                  line-height: 1.5em;
                  padding-top: 30px;
                  padding-left: 16px;
                  width: 80%;
                `}
              >
                {giftWeeksData[activeIndex].body}
              </Body>
            </ImageSection>
          </AmountSection>
          <BarSection>
            <TopBar>
              <H6
                css={css`
                  color: ${yumiPurple};
                  font-size: 16px;
                `}
              >
                Gift Amount
              </H6>{" "}
              <H6 css="padding-top:0px; font-size:16px;">
                Total: ${amount.custom > 0
                  ? amount.custom.toFixed(2)
                  : amount.selected.toFixed(2)}
              </H6>
            </TopBar>
            <SelectableBar
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              data={giftWeeksData}
              currentValue={amount}
              setFunction={setAmountFunction}
              initialIndex={initialIndex}
            />
            <PurpleButton
              value="NEXT"
              onClick={() => {
                dispatch({
                  type: "COMPLETE_STEP_TWO",
                  digital: progress.digital,
                  coupon,
                  amount:
                    amount.custom > 0
                      ? amount.custom * 100
                      : amount.selected * 100,
                });
                amount.selected > 0 || amount.custom > 0
                  ? history.push("/gift-card/2")
                  : setEmptyError(
                      "Please choose an amount greater than $0.00."
                    );
              }}
              css=" width:268px; font-size:20px;"
            />
            <div>
              <BabyListDesc> OR</BabyListDesc>
              <BabyListLink
                href={`javascript: bl.addToRegistry({
              images: 'https://email.helloyumi.com/assets/_GiftCard/BellPepperOne.jpeg',
              price: ${amount.selected > 0 ? amount.selected : amount.custom},
              title: 'Yumi Gift Card' ,
              url: 'http://localhost:3000/gift-card?amount=${
                amount.selected > 0 ? amount.selected : amount.custom
              }00'
              });`}
              >
                Add to Babylist
              </BabyListLink>
            </div>
            <div
              css={css`
                width: 270px !important;
                font-size: 12px !important;
                margin-left: -60px;
                text-align: left !important;
              `}
              id="MyRegistryAddToRegistryButtonHolder"
            />
          </BarSection>
        </DesktopSelection>
      ) : (
        <React.Fragment>
          <SelectableCards
            data={giftWeeksData}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
            initialIndex={initialIndex}
            TitleRow={TitleOnPurple}
            once
            BodyRow={BodyOnPurple}
            currentValue={amount}
            setFunction={setAmountFunction}
            minHeight="400px"
            numbered
            size="100px"
            mobileRows={{ count: 3, size: "50px" }}
            mobileColumns={{ count: 1, size: "300px" }}
          />
        </React.Fragment>
      )}

      {width < 1000 && (
        <FixedRow>
          <H6
            css={css`
              padding-top: 0px;
              color: ${yumiPurple};
            `}
          >
            Total: ${amount.custom > 0
              ? amount.custom.toFixed(2)
              : amount.selected.toFixed(2)}
          </H6>
          <div>
            <PurpleButton
              value="NEXT"
              onClick={() => {
                dispatch({
                  type: "COMPLETE_STEP_TWO",
                  digital: progress.digital,
                  coupon,
                  amount:
                    amount.custom > 0
                      ? amount.custom * 100
                      : amount.selected * 100,
                });
                amount.selected > 0 || amount.custom > 0
                  ? history.push("/gift-card/2")
                  : setEmptyError(
                      "Please choose an amount greater than $0.00."
                    );
              }}
              css="margin-left:20px;width:300px; font-size:20px;@media(max-width:800px){width:200px;}"
            />
            <BabyListContainer>
              <BabyListDesc> OR</BabyListDesc>
              <BabyListLink
                href={`javascript: bl.addToRegistry({
              images: 'https://email.helloyumi.com/assets/_GiftCard/BellPepperOne.jpeg',
              price: ${amount.selected > 0 ? amount.selected : amount.custom},
              title: 'Yumi Gift Card' ,
              url: 'http://helloyumi.com/gift-card?amount=${
                amount.selected > 0 ? amount.selected : amount.custom
              }00'
              });`}
              >
                Add to Babylist
              </BabyListLink>
              <div
                css={css`
                  width: 210px !important;
                  font-size: 10px !important;
                  margin-top: -15px;
                  margin-left: -20px;
                `}
                id="MyRegistryAddToRegistryButtonHolder"
              />
            </BabyListContainer>
          </div>
        </FixedRow>
      )}

      {emptyError && (
        <Body
          css={css`
            color: red;
          `}
        >
          {emptyError}
        </Body>
      )}
    </StepContainer>
  );
};

const BabyListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const BabyListLink = styled.a`
  color: #645394;
  font-family: "Freight-Book";
  margin-left: 8px;
`;

const BabyListDesc = styled.span`
  color: #645394;
  font-family: "Freight-Book";
`;

const FixedRow = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  padding: 30px;
  border: 1px solid ${yumiPurple};
  align-items: center;
  justify-content: space-between;
`;
const PurpleCircle = styled(H6)`
  background: ${yumiPurple};
  color: white;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: Center;
  font-size: 18px;
  position: absolute;
  max-width: ${({ value }) => (value > 999 ? "100px" : "55px")};
  min-width: 54px;
  margin-top: 10px;
  margin-left: 10px;
`;
const ImageSection = styled.div``;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BarSection = styled.div`
  display: grid;
  grid-template-rows: 40px 110px 60px 60px;
  grid-template-columns: 100%;
`;
const DesktopSelection = styled.div`
  display: Grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 300px 50px;
  align-items: center;
`;

const AmountSection = styled.div`
  display: grid;
  grid-template-rows: 200px 100px;
  grid-template-columns: 100%;
`;

const TitleOnPurple = styled(H6)`
  padding-left: 20px;
  padding-top: 35px;
  display: flex;
  align-items: baseline;
  font-size: 16px;
  color: ${({ active }) => (active ? "white" : "black")};
  @media (max-width: 1000px) {
    font-size: 12px;

    padding-top: 22px;
  }
`;
const BodyOnPurple = styled(Body)`
  padding-left: 20px;
  font-size: 12px;
  padding-top: 17px;
  color: ${({ active }) => (active ? "white" : "black")};
  line-height: 1.5em;

  width: 85%;
  @media (max-width: 1000px) {
    font-size: 12px;
    padding-top: 0px;
    line-height: 1.1em;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  padding: 15px;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
