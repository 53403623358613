import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Farmer } from "../assets";
import { H3, Body } from "../../../styledcomponents/typefaces";
import { InnerGridTemplate } from "../../../styledcomponents/LandingPageSliceTemplate";
import {
  yumiOrange,
  oatCreamy,
  BlueButton,
  texasOrange,
} from "../../../styledcomponents";

export const Carrots = () => (
  <KeepContainer>
    {" "}
    <InnerGridTemplate
      gridDimensions="0.3fr 1fr 1.4fr 0.3fr"
      gridMidDimensions="0.3fr 1fr 2fr 0.3fr"
      gridRow="574px"
      gridMobileRows="1fr 400px"
      leftChildren={(
        <img
          src={Farmer}
          alt="95"
          css="width:434px; height:434px; @media(max-width:1000px){
            width:293px;
            height:293px;
        }"
        />
      )}
      leftStyling={css`
        @media (max-width: 1000px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}
      rightStyling={css`
        justify-content: flex-start;
        padding-left: 10px;
        @media (max-width: 1200px) {
          padding-left: 40px;
        }
        @media (max-width: 1000px) {
          padding-left: 40px;
          padding-bottom: 30px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <H3
            css={css`
              color: ${yumiOrange};
              font-size: 35px;
            `}
          >
            We wish we were surprised — but we're not.
          </H3>
          <Body
            css="padding-top:30px; width:85%; @media(max-width:1200px){
              padding-top:15px;
              font-size:20px;
          }"
          >
            This is a major reason why we created Yumi. We're meticulous about
            what we give your baby, and what we don't.
          </Body>
          <Body
            css="padding-top:30px; padding-bottom:30px;width:85%;@media(max-width:1200px){
              padding-top:15px;
                  font-size:20px;
          }"
          >
            Babies' vulnerable bodies and brains are far more sensitive to
            harmful metals and chemicals. Repeated exposure to heavy metals in
            the first 1,000 days of life can have long lasting effects for years
            to come
          </Body>
          <a
            href="https://helloyumi.com"
            alt="home page"
            css="text-decoration: none;"
          >
            <OrangeButton value="LEARN MORE" fixed={false} />
          </a>
        </React.Fragment>
      )}
    />
  </KeepContainer>
);

const KeepContainer = styled.div`
  width: 100%;
  background: ${oatCreamy};
`;

const OrangeButton = styled(BlueButton)`
  background: ${texasOrange};
  color: white;
  box-shadow: 0px 2px 4px #eee;
  padding: 20px;
  font-size: 14pt;
  width: 300px;
  position: ${({ fixed }) => fixed && "fixed"};
  bottom: ${({ fixed }) => fixed && "0"};
  margin-top: 20px;
  z-index: ${({ fixed }) => fixed && "100"};

  @media (max-width: 500px) {
    font-size: 11pt;
    width: ${({ fixed }) => (fixed ? "100%" : "200px")};
    margin-top: 0px;
    height: ${({ fixed }) => fixed && "80px"};
    font-size: ${({ fixed }) => fixed && "20px"};
    padding: 15px;
  }
`;
