import { requestFormatted, post, request } from "../utils/request";

/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} email The email we are trying to verify
 * @param  {string}  password the password hash we are trying to verify
 *
 * @return {boolean} indicated whether or not signin worked
 */

export const getReward = () => requestFormatted(`rewards`);

export const applyReward = (amount) =>
  post(`rewards`, { amount: amount * 100 });

export const getBiweeklyPricingOptions = () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}plans/prices`
  );

export const getBalanceInfo = () => requestFormatted(`balances/info`);

export const getRewards = () => requestFormatted(`earn`);
export const getRedeemable = (id) => requestFormatted(`users/${id}/redeem`);
export const getRedeemed = async (redeemed = "redeemed") =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}earn?type=${redeemed}`
  );

export const getRedeemedHistory = (id) =>
  requestFormatted(`users/${id}/redeem/history`);

export const earnPoints = (body) => post(`earn`, body);

export const redeemReward = (id, body) => post(`users/${id}/redeem`, body);
