import React from "react";
import styled from "styled-components";
import { texasOrange, H6, texasLightOrange } from "../../../styledcomponents";
import "styled-components/macro";
import { Star, HoustonBabe } from "../assets";
import { H1, Body } from "../../../styledcomponents/typefaces";

export const OrganicFreshDense = ({ width }) => (
  <TwoSectionContainer>
    {width < 500 && (
      <ImageWithText>
        <TextOverImage>
          <H1 css="padding-bottom: 10px; font-size:32pt !important">
            Organic.
          </H1>
          <H1 css="padding-bottom: 10px;font-size:32pt !important">Fresh.</H1>
          <H1 css="font-size:32pt !important">Nutrient Dense.</H1>
        </TextOverImage>
        <img
          src={HoustonBabe}
          alt="babe"
          css="width:100%; margin-left:110px;"
        />
      </ImageWithText>
    )}
    <RatingText>
      <StarArea>
        <img
          src={Star}
          alt="star"
          css={`
            width: 435px;
            @media (max-width: 500px) {
              width: 250px;
            }
          `}
        />
      </StarArea>
      <Body
        css="
  padding-top: 25px;font-size: 18pt; width: 55%; color:black; text-align:center; @media(max-width:500px){padding-top:0px;width:100%;font-size:12pt !important;}"
      >
        {" "}
        “I am over-the-moon that I found Yumi foods! My son absolutely loves
        everything and I can rest easy knowing he’s getting top notch
        ingredients in combinations that are furthering the development of his
        body and mind.”
      </Body>
      <H6 css="color:white; padding-top:15pt;">Courtney W</H6>
    </RatingText>
  </TwoSectionContainer>
);

const TextOverImage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${texasOrange};
  position: absolute;
  top: 200px;
  left: 40px;
`;

const StarArea = styled.div`
  display: flex;
`;

const TwoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const RatingText = styled.div`
  display: flex;
  width: 100%;
  min-height: 350px;
  justify-content: center;
  align-items: center;
  background: ${texasLightOrange};
  flex-direction: column;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    background: ${texasLightOrange};
  }
`;
const ImageWithText = styled.div`
  position: relative;
`;
