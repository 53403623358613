import React from "react";
import styled, { css } from "styled-components";
import {
  InnerGridTemplate,
  CuriousKid,
  BabyTime,
  Pine,
  GiantAvocad,
  PinkThing,
  SecondGif
} from "../assets";
import { H5, yumiOrange } from "../../../styledcomponents";
import { H2, Body } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

export const WhyParentsLove = ({ AddToCartButton, width }) => (
  <WhyContainer>
    <img
      src={Pine}
      alt="pine"
      css="top:750px; right:0px;position: absolute; @media(max-width:800px){display:none;}"
    />
    <img
      src={GiantAvocad}
      alt="avocad"
      css="left:0px;margin-top:150px; position: absolute; @media(max-width:800px){display:none;}"
    />
    <img
      src={PinkThing}
      alt="pink"
      css="right:0px;margin-top:-200px; z-index:1000; position: absolute; @media(max-width:800px){display:none;}"
    />
    {width > 800 && <H5 css="padding:50px;">WHY PARENTS LOVE YUMI</H5>}
    <div css="display:flex; flex-direction:column;align-items:center; @media(max-width:800px){padding-top:25px;} ">
      <InnerGridTemplate
        leftStyling={css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          text-align: center;
        `}
        leftChildren={(
          <InnerContainer>
            <H2
              css={css`
                color: ${yumiOrange};
                font-size: 32px !important;
                width: 60%;

                @media (max-width: 1200px) {
                  font-size: 25px !important;
                  line-height: 1.5em;
                }
                @media (max-width: 800px) {
                  width: 95%;
                }
              `}
            >
              Nutrients Paired to Perfection
            </H2>
            <Body
              css="padding-top:20px; padding-bottom:20px; @media (max-width: 1200px) {
                  font-size: 16px !important; line-height:1.5em;
                }"
            >
              Your baby's tummy is like a tiny tank and it only has so much room
              to take in the nutrition it needs, so we aim to use the space
              efficiently. By pairing nutrients together we ensure maximum
              absorption.{" "}
            </Body>

            {width > 800 && AddToCartButton}
          </InnerContainer>
)}
        rightChildren={(
          <React.Fragment>
            <img src={BabyTime} alt="baby time" css="width:400px" />
          </React.Fragment>
)}
        rightStyling={css`
          padding-left: 20px;
          width: 100%;
          align-items: center;
        `}
        gridDimensions="0.3fr 1.2fr 1fr 0.3fr"
      />
      <InnerGridTemplate
        leftStyling={css`
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 800px) {
            order: 2;
          }
        `}
        leftChildren={(
          <React.Fragment>
            <img src={SecondGif} alt="baby time" css="width:400px" />
          </React.Fragment>
)}
        rightChildren={(
          <InnerContainer css="padding-top:60px; @media(max-width:800px){padding-top:20px; padding-bottom:20px;}">
            <H2
              css={css`
                color: ${yumiOrange};
                font-size: 32px !important;
                width: 60%;
                @media (max-width: 1200px) {
                  font-size: 25px !important;
                  line-height: 1.5em;
                }
              `}
            >
              Over 100+ Ingredients
            </H2>
            <Body
              css="padding-top:20px;padding-bottom:20px;@media (max-width: 1200px) {
                  font-size: 16px !important; line-height:1.5em;
                } @media(max-width:800px){padding-top:10px; padding-bottom:10px;}"
            >
              Studies show that early exposure to a wide variety of texture,
              tastes and vegetables can reduce fussiness and instill a love of
              healthy foods later in life. That's why we introduce your babe to
              over 100+ ingredients, including superfoods and spices from around
              the world.{" "}
            </Body>
            {width > 800 && AddToCartButton}
          </InnerContainer>
)}
        rightStyling={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 30px;
          width: 100%;
          text-align: center;

          @media (max-width: 800px) {
            order: 1;
          }
        `}
        gridDimensions="0.3fr 1fr 1.2fr 0.3fr"
      />
    </div>
  </WhyContainer>
);

const InnerContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WhyContainer = styled.div`
  width: 100%;
  height: 1105px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  align-items: center;
  display: flex;

  @media (max-width: 800px) {
    height: 1450px;
  }
`;
