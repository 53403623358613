import React from "react";
import "styled-components/macro";
import styled, { css } from "styled-components";
import { H1, H2, Body } from "../../../styledcomponents/typefaces";
import { Box, Text, LazyImage } from "../../../styledcomponents";
import { TotImage, TotLinkButton } from "../assets";

const Banana = "https://email.helloyumi.com/assets/_Pricing/Pricing_Banana.svg";

export const TotBox = () => (
  <Box css="background-color:#B7AEE5" full row name="Tot box">
    <LeftBox>
      <div
        css={`
          flex-wrap: wrap;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 4vh;
        `}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            width: 75%;
            @media (max-width: 1000px) {
              width: 100%;
              align-self: center;
            }
          `}
        >
          <div
            style={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1.5rem"
            }}
          >
            <img src={Banana} alt="banana" style={{ margin: "-3.5rem" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "8vh"
              }}
            >
              <Text
                m={H1}
                d={H2}
                color="#A46794"
                css="font-size: 35pt;margin-bottom: 32px; @media (max-width: 800px) { width: 100%; font-size: 20pt; text-align: left; margin-top: 30px; }"
              >
                The Tot Box
              </Text>
              <Body css="width: 90%;font-size: 11.5pt; line-height: 24px; margin-bottom: 30px; @media (max-width: 800px) { width: 100%; font-size: 12pt;  padding-right: 15%; text-align: left; margin-bottom: 30px; }">
                Nutritious bites tailor made for babes and tots, with all the
                good stuff and none of the bad.
              </Body>

              <TotLinkButton
                background="white"
                color="#A46794"
                value="LEARN MORE"
                css={`
                  @media (max-width: 1000px) {
                    padding: 0px;
                  }
                `}
              />
            </div>
          </div>
        </div>
      </div>
    </LeftBox>
    <Box name="image half" css="flex: 1 1 50%;">
      <LazyImage
        src={TotImage}
        width="100%"
        css="max-width: 100%;"
        alt="baby tot"
      />
    </Box>
  </Box>
);

const LeftBox = styled(Box)`
  display: flex;

  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  @media (max-width: 1000px) {
    align-items: center;
    text-align: center;
    order: 2;
  }
`;
