import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { BellPepperThree, OrangeLinkButton } from "../assets";
import { texasOrange, H4 } from "../../../styledcomponents";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { InnerGridTemplate } from "../../../styledcomponents/LandingPageSliceTemplate";

export const HowWereDifferent = ({ width }) => (
  <KeepContainer>
    <InnerGridTemplate
      gridRow="100%"
      gridMobileRows="50px"
      leftStyling={css`
        padding-left: 20px;
        padding-bottom: 30px;
      `}
      gridDimensions="0.1fr 1.4fr 1fr 0.1fr"
      gridMidDimensions="0.3fr 2fr 1fr 0.3fr"
      leftChildren={(
        <React.Fragment>
          <H1
            css={css`
              color: ${texasOrange};
              padding-bottom: 40px;
              font-size: 50px;

              @media (max-width: 1200px) {
                font-size: 45px;
              }
            `}
          >
            How we're different
          </H1>
          <H4
            css={css`
              color: ${texasOrange};
              padding-bottom: 20px;

              @media (max-width: 1200px) {
                font-size: 25px;
              }
            `}
          >
            Trusted sources
          </H4>
          <Body
            css="width:85%; padding-bottom:20px;@media (max-width: 1200px) {
                font-size: 18px;

                  line-height: 1.5em;
              }"
          >
            We partner with trusted farms who are USDA Certified Organic,
            Non-GMO Project Verified and follow rigorous food safety testing. We
            also look at federal data on soil contamination to find the best
            farms.{" "}
          </Body>
          <H4
            css={css`
              color: ${texasOrange};
              padding-bottom: 20px;
              @media (max-width: 1200px) {
                font-size: 25px;
              }
            `}
          >
            Ingredient Selection
          </H4>
          <Body
            css="width:85%; padding-bottom:20px; @media (max-width: 1200px) {
                font-size: 18px;

                  line-height: 1.5em;
              }"
          >
            We carefully choose our ingredients, avoiding some such as rice,
            which is known to be high in arsenic. Unfortunately, rice is one of
            the most popular ingredients in store-bought baby food because it is
            abundant and cheap. We believe families — especially babies —
            deserve higher standards.
          </Body>
          <H4
            css={css`
              color: ${texasOrange};
              padding-bottom: 20px;
              @media (max-width: 1200px) {
                font-size: 25px;
              }
            `}
          >
            Regular Testing
          </H4>
          <Body
            css="width:85%; padding-bottom:20px; @media (max-width: 1200px) {
                font-size: 18px;

                  line-height: 1.5em;
              }"
          >
            We regularly test our ingredients and finished products for heavy
            metals.
          </Body>

          <H4
            css={css`
              color: ${texasOrange};
              padding-bottom: 20px;
              @media (max-width: 1200px) {
                font-size: 25px;
              }
            `}
          >
            Cooking & Handling
          </H4>
          <Body
            css="width:85%; padding-bottom:20px; @media (max-width: 1200px) {
                font-size: 18px;

                  line-height: 1.5em;
              }"
          >
            As our families know, we make our meals in small batches and use
            light processing, to keep your food tasting like, well, real food.
          </Body>

          <H4
            css={css`
              color: ${texasOrange};
              padding-bottom: 20px;
              @media (max-width: 1200px) {
                font-size: 25px;
              }
            `}
          >
            No Questionable Additives
          </H4>
          <Body
            css="width:85%; padding-bottom:20px; @media (max-width: 1200px) {
                font-size: 18px;

                  line-height: 1.5em;
              }"
          >
            Our meals are not fortified and do not include artificial additives.
            The 2021 government report noted that certain additives, such as
            poorly vetted vitamin mixes, can lead to high heavy metal results in
            baby food products.
          </Body>

          {width > 1000 && <OrangeLinkButton to="/checkout" value="GET YUMI" />}
        </React.Fragment>
      )}
      rightChildren={(
        <img
          src={BellPepperThree}
          alt="95"
          css="width:541px; height:763px; @media(max-width:1000px){width:100%; object-fit:cover; width:394px; height:556px;}"
        />
      )}
    />{" "}
  </KeepContainer>
);

const KeepContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
`;
