import React from "react";
import styled, { css } from "styled-components";
import { H2 } from "../../../styledcomponents/typefaces";
import { NewYork, FoodNWine, CNN, goop, Inc } from "../assets";
import { yumiOrange, HyperLink } from "../../../styledcomponents";
import "styled-components/macro";

export const CrowdPleaser = ({ width }) => (
  <CrowdContainer>
    <H2
      css={css`
        color: ${yumiOrange};
      `}
    >
      Crowd Pleaser
    </H2>

    <CrowdRow>
      <HyperLink href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html">
        <img
          src={NewYork}
          alt="new yak"
          css="width:300px;@media(max-width:800px){padding-top:10px; width:190px;}"
        />
      </HyperLink>
      <HyperLink href="https://www.foodandwine.com/news/yumi-organic-baby-food-subscription-delivery">
        <img
          src={FoodNWine}
          alt="food"
          css="width:200px;@media(max-width:800px){padding-top:20px; width:120px;}"
        />
      </HyperLink>

      <HyperLink href="https://money.cnn.com/video/technology/2018/02/06/yumi-subscription-delivery-baby-food.cnnmoney/index.html">
        {" "}
        <img
          src={CNN}
          alt="cnn"
          css="width:80px; @media(max-width:800px){padding-top:20px; width:60px;}"
        />
      </HyperLink>
      <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
        <img
          src={goop}
          alt="goop"
          css="width:110px;@media(max-width:800px){padding-top:20px; width:70px;}"
        />
      </HyperLink>
      <img
        src={Inc}
        alt="inc"
        css="width:100px;@media(max-width:800px){padding-top:20px; width:60px;}"
      />
    </CrowdRow>
  </CrowdContainer>
);

const CrowdRow = styled.div`
  display: flex;
  width: 90%;
  padding-top: 50px;
  justify-content: space-evenly;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
  }
`;
const CrowdContainer = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
  align-items: center;
  display: flex;

  @media (max-width: 800px) {
    height: 305px;
  }
`;
