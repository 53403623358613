import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { H3, H5, BigButton, Body1, Body2 } from "../../../styledcomponents";
import { blackberry, oat } from "../../../styledcomponents/colors";
import { useRequest } from "../../../hooks";
import "styled-components/macro";

import { getBillingPlans } from "../../../usecases";

const PricingSplashPhoto =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_RassieChia.png";

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";

const PricingJar =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Jar.svg";

const getPerJar = (per_day) => ({ 1: "4.30", 2: "4.06", 3: "3.75" }[per_day]);

export const PricingOptions = () => {
  const [billingPlans, error, Loading] = useRequest(getBillingPlans);

  if (Loading) return <h1>loading...</h1>;
  if (error || !billingPlans)
    return <H3> Billing Plans could not be fetched.</H3>;

  const billingPlansWeekly = billingPlans.weekly.slice(0, 3);

  return (
    <PricingOptionsContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "64px",
        }}
      >
        <H3
          style={{
            color: blackberry,
            fontSize: "45pt",
          }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 30pt !important;
            }
          `}
        >
          Pricing
        </H3>
        <Body1
          style={{
            margin: "16px",
            fontSize: "19pt",
            maxWidth: "50%",
            textAlign: "center",
          }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
            }
          `}
        >
          Organic produce, clean ingredients, and delicious recipes delivered.
        </Body1>
        <PricingImage
          style={{ marginLeft: "-0.5rem" }}
          css={css`
            @media (max-width: 1000px) {
              max-width: 450px !important;
              max-height: 120px !important;
            }
          `}
          src={PricingSplashPhoto}
          alt="baby food"
        />
        <PricingOptionsArea>
          {billingPlansWeekly.map((plan) => <PricingOption plan={plan} />)}
        </PricingOptionsArea>
      </div>
    </PricingOptionsContainer>
  );
};
const PricingOption = ({ plan }) => (
  <PricingOptionBox>
    <WhiteBackground>
      <H3
        style={{
          fontSize: "5vh",
          margin: "3vh",
          color: blackberry,
          marginBottom: "1.2vh",
        }}
      >
        {`${plan.per_day} ${plan.per_day === 1 ? `meal` : `meals`} a day`}
      </H3>
      <MealIconArea>
        {[...Array(plan.per_day)].map((_) => (
          <img
            src={PricingJar}
            alt="meal jar"
            style={{ height: "5vh", margin: "0.75rem" }}
          />
        ))}
      </MealIconArea>
      <PricingPerMealAndWeekly>
        <PriceLabel>
          <H5 style={{ fontSize: "3vh" }}>${getPerJar(plan.per_day)}</H5>
          <Body2 style={{ fontSize: "1.75vh" }}>/meal</Body2>
        </PriceLabel>
        <PriceLabel>
          <H5 style={{ fontSize: "3vh" }}>${plan.total}.00</H5>
          <Body2 style={{ fontSize: "1.75vh" }}>/week</Body2>
        </PriceLabel>
      </PricingPerMealAndWeekly>
      <FreeShipping style={{ padding: "14px" }}>
        <img src={PricingFast} style={{ height: "3.5vh" }} alt="shipping" />
        <Body1 style={{ marginLeft: "0.5rem", fontSize: "2.5vh" }}>
          Free Shipping
        </Body1>
      </FreeShipping>
    </WhiteBackground>
    <div style={{ height: "1rem" }}>
      <Link to="/checkout">
        <BigButton
          value="Select"
          style={{
            marginTop: "-10vh",
            fontSize: "2vh",
            background: blackberry,
            height: "7vh",
            maxWidth: "20vh",
          }}
        />
      </Link>
    </div>
  </PricingOptionBox>
);

const WhiteBackground = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const FreeShipping = styled.div`
  display: flex;
  align-items: center;
`;
const PriceLabel = styled.div`
  display: flex;
  align-items: center;
`;
const PricingPerMealAndWeekly = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-between;
`;

const MealIconArea = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
const PricingOptionsArea = styled.div`
  display: flex;
  max-width: 120rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const PricingOptionBox = styled.div`
  min-width: 47vh;
  min-height: 38vh;
  margin: 1.35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PricingOptionsContainer = styled.div`
  background: ${oat};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PricingImage = styled.img`
  margin: 1rem;
  height: 170px;
`;
