import { useState } from "react";

export const useMergeState = initialState => {
  const [state, updateState] = useState(initialState);
  return [
    state,
    newState => updateState(oldState => ({ ...oldState, ...newState }))
  ];
};

export default useMergeState;
