import React from "react";
import injectSheet from "react-jss";
import { css } from "styled-components";
import { Header, Nutrients, Vitamins } from "../index";
import "styled-components/macro";

export const styles = {
  root: {
    display: "flex",
    flexFlow: "column",
    padding: "0px 4px 20px 4px",
    backgroundColor: "white",
    border: "1px solid black",
    fontFamily: "'Libre Franklin', sans-serif",
    maxWidth: "220px"
  },
  attribute: {
    fontWeight: 400,
    fontSize: 12,
    margin: "0"
  },
  italic: {
    fontStyle: "italic",
    fontSize: 12
  }
};

const dailyValues = {
  // default calorie intake
  calorieIntake: 839.67,

  // these are the recommended daily intake values
  totalFat: 30,
  satFat: 10.88,
  cholesterol: 0,
  sodium: 320,
  potassium_milligrams: { name: "Potassium", value: 700 },
  potassium_2018: 700,
  carb: 95,
  fiber: 19,
  calcium_milligrams: { name: "Calcium", value: 260 },
  iron_milligrams: { name: "Iron", value: 11 },
  vitamin_c_milligrams: { name: "Vitamin C", value: 50 },
  vitamin_d_IU: { name: "Vitamin D", value: 10 },
  vitamin_a_IU: { name: "Vitamin A", value: 500 },
  addedSugar: 20,
  sugar: 15,
  proteins: 11,

  vitamin_e_milligrams: { name: "Vitamin E", value: 5 },
  vitamin_k_micrograms: { name: "Vitamin K", value: 2.5 },
  vitamin_b12_micrograms: { name: "Vitamin B12", value: 0.5 },
  vitamin_b6_milligrams: { name: "Vitamin B6", value: 0.3 },
  riboflavin_milligrams: { name: "Riboflavin", value: 0.4 },
  zinc_milligrams: { name: "Zinc", value: 3 },
  magnesium_milligrams: { name: "Magnesium", value: 75 },
  manganese_milligrams: { name: "Manganese", value: 0.6 },
  selenium_micrograms: { name: "Selenium", value: 20 },
  folate_micrograms: { name: "Folate", value: 80 },
  phosphorus_milligrams: { name: "Phosphorus", value: 275 },
  copper_milligrams: { name: "Copper", value: 0.2 },
  thiamin_milligrams: { name: "Thiamin", value: 0.3 },
  niacin_milligrams: { name: "Niacin", value: 4 }
};

const NutritionLabel = ({
  classes,
  servingSize,
  calories,
  totalFat,
  saturatedFat,
  transFat,
  serving_size,
  cholesterol,
  sodium,
  name,
  totalCarbs,
  dietaryFiber,
  sugars,
  protein,
  vitaminA,
  vitaminC,
  calcium,
  iron,
  ingredients,
  data
}) => (
  <div className={classes.root}>
    <Header servingSize={servingSize} serving_size={serving_size} name={name} />

    <Nutrients calories={calories} caloriesFromFat={totalFat * 9}>
      <ul>
        <li>
          <div
            css={css`
              justify-content: space-between;
              flex-direction: row;
              display: flex;
            `}
          >
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                Total Fat
              </b>{" "}
              {totalFat}g
            </span>
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                {Math.floor((totalFat / dailyValues.totalFat) * 100)}%
              </b>
            </span>
          </div>

          <ul>
            <li className={classes.attribute}>Saturated Fat {saturatedFat}g</li>
            <li className={classes.attribute}>Trans Fat {transFat}g</li>
          </ul>
        </li>
        <li className={classes.attribute}>
          <b className={classes.attribute} style={{ fontWeight: 800 }}>
            Cholesterol
          </b>{" "}
          {cholesterol}mg
        </li>
        <li className={classes.attribute}>
          <b className={classes.attribute} style={{ fontWeight: 800 }}>
            Sodium
          </b>{" "}
          {sodium}mg
        </li>
        <li>
          <div
            css={css`
              justify-content: space-between;
              flex-direction: row;
              display: flex;
            `}
          >
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                Total Carbohydrates
              </b>{" "}
              {totalCarbs}g
            </span>
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                {Math.floor((totalCarbs / dailyValues.carb) * 100)}%
              </b>
            </span>
          </div>

          <ul>
            <li className={classes.attribute}>Dietary Fiber {dietaryFiber}g</li>
            <li className={classes.attribute}>Sugars {sugars}g</li>
          </ul>
        </li>
        <li>
          <div
            css={css`
              justify-content: space-between;
              flex-direction: row;
              display: flex;
            `}
          >
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                Protein
              </b>{" "}
              {protein}g
            </span>
            <span className={classes.attribute}>
              <b className={classes.attribute} style={{ fontWeight: 800 }}>
                {Math.floor((protein / dailyValues.proteins) * 100)}%
              </b>
            </span>
          </div>
        </li>
      </ul>
    </Nutrients>
    <Vitamins
      collection={Object.keys(data).map(label => {
        if (dailyValues[label]) {
          return {
            name: dailyValues[label].name,
            value: `${Math.floor(
              (data[label] / dailyValues[label].value) * 100
            )}%`
          };
        }
      })}
      ingredients={ingredients}
    />
  </div>
);

export default injectSheet(styles)(NutritionLabel);
