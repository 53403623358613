import React from "react";

import styled, { css } from "styled-components";
import { H2 } from "../../../styledcomponents/typefaces";
import { LittleTomato } from "../assets";
import "styled-components/macro";

export const FavoriteFoods = ({ width }) => (
  <FavoriteContainer>
    <img
      src={LittleTomato}
      alt="tomate"
      css={css`
        width: 57px;
        @media (max-width: 500px) {
          width: 40px;
        }
      `}
    />
    <H2
      css="text-align:center; font-size:48px; padding-top:22px; color:#0A6D43;

      @media (max-width: 1100px) {
    width:80%;
      }
    @media(max-width:500px){
      font-size:24px;
      padding:20px;
      padding-top:7px;
      width:100%;

    }
    "
    >
      Your favorite foods, tailored to your growing babe.
    </H2>
  </FavoriteContainer>
);

const FavoriteContainer = styled.div`
  width: 100%;
  display: flex;
  height: 320px;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    height: 80px;
    margin-top: 50px;
  }
`;
