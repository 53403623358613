import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { blackberry, blueberry } from "../../../styledcomponents/colors";
import { H6, BlueButton } from "../../../styledcomponents";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { H1, Body } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

export const HowTo = () => {
  const { width } = useWindowSize();
  return (
    <HowToContainer>
      {width > 1000 && (
        <svg
          width="202"
          height="205"
          viewBox="0 0 202 205"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M199.788 117.336C198.651 113.913 196.278 111.096 195.047 107.73C192.313 100.257 194.608 94.4687 197.505 87.6645C201.129 79.1508 203.128 66.4047 199.277 57.8388C195.701 49.8861 188.838 44.6773 181.215 40.8392C177.789 39.1192 174.05 38.0553 170.553 36.5003C164.19 33.6718 162.267 29.2719 159.59 23.3333C152.176 6.8842 137.442 -4.53122 118.713 1.75115C116.95 2.34286 109.138 5.8868 106.787 7.00245C97.4469 11.4327 89.8152 10.5009 79.3374 7.61436C76.0661 6.71333 71.901 3.56331 64.0069 3.19255C56.304 2.83062 48.002 7.88539 43.7077 13.8323C39.66 19.4401 39.0517 26.7632 35.6718 32.4732C33.4273 36.2651 29.898 38.8575 25.0917 41.0833C17.6839 44.5102 11.2213 49.9665 7.61406 57.3725C4.57349 63.6157 3.48601 67.5473 3.41065 74.4361C3.31844 83.1493 10.834 90.7682 10.1199 100.473C9.60757 107.437 7.5046 111.217 3.77398 117.624C-5.16838 132.973 3.00352 148.63 15.5136 155.492C22.4679 159.307 31.1909 162.993 35.3009 170.34C37.7444 174.707 39.223 179.424 40.7635 184.113C44.3704 192.163 49.4023 197.549 57.8243 200.025C75.403 205.193 80.6379 195.26 101.214 193.672C112.217 192.822 122.859 205.427 135.961 204.989C142.288 204.776 148.706 202.254 154.769 197.618C159.693 193.852 162.462 187.879 164.601 182.18C165.882 178.769 165.764 171.138 171.766 166.096C175.8 162.707 180.9 161.375 185.747 159.269C201.818 152.285 204.783 132.376 199.788 117.336Z"
            fill="#8DB9DB"
          />
          <text
            x="33"
            y="100"
            fill="white"
            style={{
              fontFamily: "Platform-Medium",
              fontSize: "18pt"
            }}
          >
            SURPRISE GIFT
          </text>
          <text
            x="20"
            y="130"
            fill="white"
            style={{ fontFamily: "Platform-Medium", fontSize: "18pt" }}
          >
            WITH EVERY BOX!
          </text>
        </svg>
      )}
      <HowToBox>
        <H1
          style={{ color: blackberry }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 17pt !important;
            }
          `}
        >
          How to get the box
        </H1>
        <Body>
          {" "}
          Our by CHLOE. Chef Box will be available for a limited time starting
          on September 4th.
        </Body>
        <H6 style={{ color: blueberry, opacity: "0.7", paddingTop: "25px" }}>
          New to the Yumi family?
        </H6>
        <Body>You'll find the by CHLOE Chef Box at checkout. </Body>
        <H6 style={{ color: blueberry, opacity: "0.7", paddingTop: "25px" }}>
          Are you a Yumi parent?
        </H6>
        <Body>
          You can add a by CHLOE Chef Box to your next order by logging into
          your account.
        </Body>
        <Link style={{ textDecoration: "none", color: "white" }} to="/checkout">
          <ChefButton>GET THE BOX</ChefButton>
        </Link>
      </HowToBox>
    </HowToContainer>
  );
};

const HowToContainer = styled.div`
  display: flex;
  width: 60%;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100% !important;
    align-items: center;
  }
`;

const ChefButton = styled(BlueButton)`
  width: 150px;
  background: ${blackberry};
  color: white;
  padding: 15px;
  font-size: 11pt;
  margin-top: 25px;

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

const HowToBox = styled.div`
  width: 80%;
  padding-top: 100px;
  padding-left: 50px;

  @media (max-width: 1000px) {
    padding-top: 40px;
    width: 90%;
    padding-left: 30px;
  }
`;
