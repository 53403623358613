import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  blackberry,
  oatAdjusted,
  oatCreamy
} from "../../styledcomponents/colors";
import { H1, H3, Body } from "../../styledcomponents/typefaces";
import {
  H6,
  BlueButton,
  ReviewsCarousel,
  Body1,
  H5
} from "../../styledcomponents";
import { useWindowSize } from "../../hooks";
import {
  ByChloeHero,
  ByChloeBabe,
  ByChloeBannerDesktop,
  ByChloeBannerMobile
} from "./assets";
import { FutureCollabs, TalkOfTheTown, ByChloeLandingBlends } from "./sections";
import "styled-components/macro";
import { HowToLanding } from "./sections/HowToLanding";

export const ByChloeLandingPage = () => {
  const { width } = useWindowSize();
  return (
    <Sections>
      <div
        style={{
          width: "100%",
          paddingTop: "10px",
          maxWidth: "1200px",
          maxHeight: "600px"
        }}
      >
        <img
          src={ByChloeHero}
          alt="big img"
          style={{
            width: "100%",
            maxWidth: "2000px",
            objectFit: "none",
            maxHeight: "600px",
            objectPosition: "right"
          }}
          css={css`
            @media (max-width: 1000px) {
              object-fit: cover !important;
            }
          `}
        />
        <div
          style={{ position: "relative", bottom: "260px", left: "840px" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt;
              width: 62%;
              display: none;
            }
          `}
        >
          <div
            style={{
              background: "white",
              width: "320px",
              height: "250px",
              padding: "15px",
              color: blackberry,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly"
            }}
            css={css`
              @media (max-width: 1000px) {
                display: none;
              }
            `}
          >
            <H3>Let's inspire a future foodie together</H3>
            <Body1>
              Yumi is the only organic, fresh baby food service to partner with
              your favorite chefs.
            </Body1>
            <Link to="/checkout">
              <BlueButton
                value="GET STARTED"
                style={{
                  color: "white",
                  background: blackberry,
                  width: "200px",
                  fontSize: "11pt",
                  paddingTop: "10px",
                  paddingBottom: "10px"
                }}
              />
            </Link>
          </div>
        </div>
      </div>
      {width < 500 && (
        <FutureFoodies>
          <H5
            css={css`
              color: ${blackberry};
            `}
          >
            Let's inspire a future foodie together
          </H5>
          <Body css="padding-top: 20px;">
            Yumi is the only organic, fresh baby food service to partner with
            your favorite chefs to create the most adventurous and delicious
            blends and bites around.
          </Body>
        </FutureFoodies>
      )}
      <ByChloeBanner width={width} />

      <div
        style={{
          display: "flex",
          height: "550px",
          marginTop: "50px",
          background: oatAdjusted
        }}
        css={css`
          @media (max-width: 1000px) {
            flex-direction: column;
            width: 100% !important;
            background: white !important;
            margin-left: 20px !important;
            margin-bottom: 70px !important;
          }
        `}
      >
        <img
          src={ByChloeBabe}
          alt="cutting board"
          style={{
            width: "740px",
            height: "440px",
            position: "relative"
          }}
          css={css`
            @media (max-width: 1000px) {
              width: 440px !important;
              align-self: center;
              height: 260px !important;
              padding-left: 0px !important;
              margin-bottom: 30px !important;
            }
            @media (max-width: 400px) {
              width: 350px !important;
              margin-top: -20px;
              margin-left: -50px;
            }
          `}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-end",
            marginLeft: "-150px"
          }}
          css={css`
            @media (max-width: 1000px) {
              width: 100% !important;
              margin-left: none !important;
              margin-top: 30px;
            }
          `}
        >
          <H6
            style={{
              fontSize: "15pt",
              color: blackberry,
              fontWeight: width < 500 && "bold"
            }}
          >
            {width > 500
              ? `Introducing Our Latest Chef Collab:`
              : `The By CHLOE. Chef Box`}
          </H6>
          <H1
            style={{
              color: blackberry,
              fontSize: "45pt",
              paddingTop: "10px"
            }}
            css={css`
              @media (max-width: 500px) {
                display: none !important;
              }
            `}
          >
            by CHLOE.
          </H1>
          <Body
            style={{
              fontSize: "17pt",
              paddingTop: "17px",
              width: "80%"
            }}
            css={css`
              @media (max-width: 900px) {
                font-size: 14pt !important;
                width: 330px !important;
                padding-top: 25px;
              }
            `}
          >
            By CHLOE. started a food revolution by turning fast-casual favorites
            into nutritious, tasty plant-based alternatives. Since opening in
            2015, they've reached cult-like status in New York, Los Angeles,
            Boston and even across the pond in London and Canada (coming soon!)
            We've partnered with these plant-based trailblazers to create a baby
            burger bite and blend that's sneaky delicious.
          </Body>
        </div>
      </div>
      <ByChloeLandingBlends />
      <ReviewsCarousel />
      <HowToLanding />
      <TalkOfTheTown />
      <FutureCollabs />
    </Sections>
  );
};

const ByChloeBanner = ({ width }) => (
  <BannerContainer>
    {width > 500 ? (
      <img src={ByChloeBannerDesktop} alt="banner" style={{ width: "94%" }} />
    ) : (
      <React.Fragment>
        <H6
          style={{ fontFamily: "SignPainter" }}
          css={css`
            @media (max-width: 1000px) {
              display: flex;
              margin-top: 10%;
            }
          `}
        >
          INTRODUCING OUR LATEST CHEF COLLAB:
        </H6>
        <img
          src={ByChloeBannerMobile}
          alt="banner"
          style={{ width: "350px" }}
        />
      </React.Fragment>
    )}
  </BannerContainer>
);

const BannerContainer = styled.div`
  justify-content: center;
  width: 96%;
  align-self: center;
  justify-self: center;
  margin-top: 55px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
  }
`;
const SurpriseGiftBox = styled.div`
  border: 2px solid black;
  display: none;
  width: 90%;
  margin-top: 70px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;
const VIBImage = styled.img`
  width: 90%;
  object-fit: cover;
  margin-top: 120px;
  height: 320px;

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

const SurpriseImage = styled.img`
  width: 20%;
  outline: 1px solid red;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Sections = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: hidden;
`;

const FutureFoodies = styled.div`
  width: 100%;
  background: ${oatCreamy};
  padding: 35px;
`;
const ChefButton = styled(BlueButton)`
  width: 150px;
  background: ${blackberry};
  color: white;
  padding: 15px;
  font-size: 11pt;
  margin-top: 25px;
`;
