import React, { useState, useContext } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { GiftCardFlowContext } from "../GiftCardFlowContext";
import "styled-components/macro";
import { PurpleButton, LargePurpleHeader, BellPepperThreeAlt } from "../assets";
import { SelectableCards } from "../SelectableCards";
import StripeBillingForm from "../../../styledcomponents/containers/StripeBillingForm";
import {
  InputField,
  Box,
  H5,
  yumiPurple,
  Body1,
} from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";
import { useFormState, useWindowSize } from "../../../hooks";
import { applyGift } from "../../../usecases";
import { Hazmat, Nutrition, Calendar } from "../../OneOffPurchase/assets";

export const StepFour = ({ user }) => {
  const { width } = useWindowSize();
  const { progress, dispatch, history } = useContext(GiftCardFlowContext);
  const [error, setError] = useState("");
  const [purchasing, setPurchasing] = useState(false);
  const [form, setField] = useFormState({
    user_name: "",
    user_email: "",
    cardholder: "",
  });

  const amountExists = progress.coupon && progress.coupon.amount;

  const validCoupon = amountExists && progress.coupon.amount === 2000;

  const discount = amountExists && validCoupon && progress.coupon.amount / 100;

  const isDiscount = discount && discount > 0;

  const discountedAmount = (progress.amount / 100) * (discount / 100);
  const discountedPrice = progress.amount / 100 - discountedAmount;

  const [getStripeData, setStripeSubmit] = useState(null);

  const TwoADay = [
    {
      image: BellPepperThreeAlt,
    },
  ];

  const createGift = async (stripeData) => {
    if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        form.user_email
      )
    ) {
      setError(
        " There seems to be an error with the email you entered under your contact information. Please enter your email again or contact customer support."
      );
      setPurchasing(false);
      return;
    }

    setPurchasing(true);
    try {
      const gift = await applyGift({
        card_type: "digital",
        amount: progress.amount,
        stripe_card_token: stripeData.token.id,
        user_id: user.id,
        coupon: progress.coupon && _.get(progress, "coupon.code"),
        gift_details: {
          message: progress.message,
          name: progress.recepient_name,
          email: progress.recepient_email,
        },
        purchase_details: {
          name: form.user_name,
          email: form.user_email,
        },
      });
      const { gift_code } = gift;
      setPurchasing(false);
      dispatch({
        type: "COMPLETE_STEP_FOUR",
        recepient_name: progress.recepient_name,
        recepient_email: progress.recepient_email,
        amount: progress.amount,
        gift_code,
      });
      history.push("/gift-card/4");
    } catch (err) {
      setPurchasing(false);
      setError(
        "There was an issue purchasing this gift card. Please try again or contact customer service."
      );
      throw err;
    }
  };

  return (
    <StepContainer>
      {" "}
      <LargePurpleHeader>Your Details.</LargePurpleHeader>
      <InnerGrid>
        <div
          css="display:grid;
  grid-template-columns: 1fr 1fr; @media(max-width:800px){
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
          justify-content:center;
          align-items:Center;

  }"
        >
          <ContactAndPayment>
            <H5
              css={css`
                color: ${yumiPurple};
                text-align: center;
                padding-bottom: 15px;
              `}
            >
              Contact Information
            </H5>
            <InputField
              value={form.user_name}
              onChange={setField("user_name")}
              placeholder="Your Name"
              id="yourName"
              css="margin-bottom:20px;"
            />
            <InputField
              value={form.user_email}
              type="email"
              onChange={setField("user_email")}
              placeholder="Your Email"
              id="yourEmail"
            />
            <H5
              css={css`
                color: ${yumiPurple};
                padding-top: 40px;
                text-align: center;

                padding-bottom: 20px;
              `}
            >
              Payment Information
            </H5>
            <Body
              css={css`
                width: 100%;
                font-size: 14px;
                padding-bottom: 20px;
                line-height: 1.5em;
                text-align: center;
              `}
            >
              All transactions are secure and encrypted. Credit card information
              is never stored.
            </Body>
            <StripeBillingForm
              hideAddress
              setStripeSubmit={setStripeSubmit}
              applePayMessage="Payment processed successfully. Click PLACE ORDER to finish signing up."
              amount={progress.amount}
            />
            <Box
              row
              nowrap
              css="width: 100%; margin-top: 0.5em; flex-shrink: 0;"
            >
              <InputField
                type="text"
                id="cardHolder"
                placeholder="Name on card"
                onChange={setField("cardholder")}
                value={form.cardholder}
              />{" "}
            </Box>{" "}
          </ContactAndPayment>

          <SelectableCards
            disabled
            TitleRow={TitleOnPurple}
            BodyRow={BodyOnPurple}
            setActiveIndex={() => void 0}
            setFunction={() => void 0}
            titleChildren={() => (
              <React.Fragment>
                <TitleRow>
                  <TitleOnPurple>
                    ${progress.amount / 100} gift card{"  "}
                    <span
                      css={css`
                        color: ${yumiPurple};
                        font-size: 15px;
                        padding-left: 2px;
                      `}
                    >
                      {isDiscount && `  + ${discount}% discount`}
                    </span>
                  </TitleOnPurple>
                  <TitleOnPurple>
                    ${(isDiscount
                      ? discountedPrice
                      : progress.amount / 100
                    ).toFixed(2)}
                  </TitleOnPurple>
                </TitleRow>
              </React.Fragment>
            )}
            bodyChildren={() => (
              <React.Fragment>
                <BodyOnPurple>Also included in your gift:</BodyOnPurple>
                <BodyOnPurple2>
                  {width > 500 && (
                    <img
                      src={Hazmat}
                      css="width:30px; margin-right:15px;margin-bottom:5px;"
                      alt="haz"
                    />
                  )}Baby Hazmat Suit - x1 <br />{" "}
                  {width > 500 && (
                    <img
                      src={Nutrition}
                      css="width:30px;margin-right:15px;margin-bottom:5px;"
                      alt="haz"
                    />
                  )}{" "}
                  Nutritional Coaching Session - x1 <br />{" "}
                  {width > 500 && (
                    <img
                      src={Calendar}
                      css="width:30px;margin-right:15px;margin-bottom:5px;"
                      alt="haz"
                    />
                  )}Intro to Solids Guide - x1{" "}
                </BodyOnPurple2>
              </React.Fragment>
            )}
            data={TwoADay}
          />
        </div>

        <PurpleButton
          value={purchasing ? "PURCHASING..." : "PURCHASE"}
          css="margin-bottom:30px;width:300px;justify-self:center;
          @media(max-width:800px){
          margin-top:130px;

          }
          "
          onClick={() => {
            !purchasing &&
              getStripeData !== null &&
              getStripeData().then((stripeData) => createGift(stripeData));
          }}
        />

        <span
          css="margin-bottom:30px;width:300px;justify-self:center; color: red;
          @media(max-width:800px){
          margin-top:130px;

          }
          "
        >
          {error}
        </span>
      </InnerGrid>
    </StepContainer>
  );
};

const TitleRow = styled.div`
  display: flex;
  width: 85%;
  justify-content: space-between;
`;

const TitleOnPurple = styled(Body)`
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  align-items: baseline;
  font-size: 20px;
  color: black;
  @media (max-width: 800px) {
    font-size: 14px;
    padding-top: 0px;
  }
`;
const BodyOnPurple = styled(Body)`
  padding-left: 20px;
  font-size: 17px;
  padding-top: 10px;
  color: black;
  line-height: 1.5em;
  @media (max-width: 800px) {
    font-size: 12px;
    padding-top: 0px;
  }
`;
const BodyOnPurple2 = styled(Body)`
  padding-left: 20px;
  font-size: 14px;
  padding-top: 10px;
  color: black;
  line-height: 1.5em;
  @media (max-width: 800px) {
    font-size: 12px;
    padding-top: 0px;
    line-height: 1em;
  }
`;
const InnerGrid = styled.div`
  display: grid;
  grid-template-rows: 475px 100px;

  @media (max-width: 800px) {
    grid-template-rows: 485px 220px;
  }
`;
const ContactAndPayment = styled.div`
  width: 90%;
  padding-bottom: 30px;
  justify-self: center;
`;
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 15px;
  background: #fefefe;
  overflow: hidden;
`;
