import React from "react";
import "./styles/styles.css";
import styled, { css } from "styled-components";

function Button({
  solid,
  skipped,
  rating,
  text,
  img,
  color,
  onClick,
  style,
  onMouseOver,
  onMouseLeave
}) {
  return (
    <ButtonWrapper
      style={style}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={`button_wrapper${skipped ? " skipped" : ""}`}
      onClick={onClick}
      solid={solid}
      skipped={skipped}
      rating={rating}
      color={color}
    >
      {img ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Img src={img} />
          <div>{text}</div>
        </div>
      ) : (
        <div>{text}</div>
      )}
    </ButtonWrapper>
  );
}

const Img = styled.img`
  height: 15px;
  width: auto;
  margin-right: 24px;
`;
const ButtonWrapper = styled.div`
  border-radius: 27px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => (props.solid ? "none" : "rgba(36,26,27, 0.11)")};
  font-family: Platform-Medium;
  font-size: 11px;
  padding: 8px 24px;
  margin: 5px;
  letter-spacing: 0.6px;
  background-color: ${props => (props.solid ? "#241a1b" : "none")};
  color: ${props => (props.solid ? props.color : "#241a1b")};
  cursor: pointer;

  ${props =>
    props.skipped &&
    css`
      background-color: #ee5a37;
      color: white;
      border: 0px;
    `}
`;

export default Button;
