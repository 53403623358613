/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect, useContext } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Body2, H6 } from "./text";
import { Detail } from "./typefaces";
import { blackberry, yumiOrange } from "./colors";
import { Box } from "./layout";
import { Modal } from "./Modal";

const FormContext = React.createContext({});

export const Form = ({
  children,
  onSubmit = () => null,
  errorMessage = null,
  ...props
}) => {
  const [formLoading, setFormLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <StyledForm
      onSubmit={async e => {
        e.preventDefault();
        setFormLoading(true);
        try {
          await onSubmit(e);
        } catch (err) {
          console.log(err);
          setDialogOpen(true);
        }
        setFormLoading(false);
      }}
      {...props}
    >
      <FormContext.Provider value={{ formLoading }}>
        {children}
      </FormContext.Provider>
      {errorMessage &&
        dialogOpen && (
          <Modal isOpen={dialogOpen} close={() => setDialogOpen(false)}>
            <Box jc="center" full>
              {errorMessage} <br />
              Please refresh the page and try again.
              <Box full row>
                <BlueButton
                  type="button"
                  onClick={() => setDialogOpen(false)}
                  value="CLOSE"
                />
              </Box>
            </Box>
          </Modal>
        )}
    </StyledForm>
  );
};

export const BlueButton = ({
  type = "button",
  disabled = false,
  value,
  loading = "",
  children,
  ...props
}) => {
  const { formLoading } = useContext(FormContext);
  const isDisabled =
    formLoading === true || ["disabled", true].includes(disabled)
      ? "disabled"
      : "";
  const innerText = formLoading ? loading : value || children;

  return (
    <BlueButtonStyle {...props} type={type} disabled={isDisabled}>
      {innerText}
    </BlueButtonStyle>
  );
};

const BlueButtonStyle = styled.button`
        ${props =>
          props.small &&
          css`
            height: 32px;
          `}
        ${props =>
          props.medium &&
          css`
            height: 40px;
          `}
        ${props =>
          props.large &&
          css`
            height: 52px;
          `}
        ${props =>
          props.primary &&
          css`
            background: ${blackberry};
            color: white;
          `}
        ${props =>
          props.orange &&
          css`
            background: #ee5a37;
            color: white;
          `}
           ${props =>
             props.plumBorder &&
             css`
               color: #645394;
               border: 1px solid #645394;
               &:hover {
                 color: #645394;
               }
             `}
        ${props =>
          props.fullWidth &&
          css`
            width: 100%;
          `}
        ${props =>
          props.secondary &&
          css`
            background: white;
            color: ${blackberry};
          `}
        ${props =>
          props.tertiary &&
          css`
            background: rgba(0, 0, 0, 0);
            color: white;
            border: 1px solid white;
            &:hover {
              color: ${blackberry};
              background: white;
            }
          `}
          ${props =>
            props.border &&
            css`
              background: white;
              color: ${blackberry};
              border: 0.5px solid rgba(0, 0, 0, 0.12);
              &:hover {
                color: white;
                background: ${blackberry};
                border: none;
              }
            `}
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 3.5vw;
          padding-right: 3.5vw;
        ${props => props.full && "width: 100%;"}
        font-family: "Ginger";
        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &:hover{
          opacity: .8;
        }

`;

export const InputField = ({
  type = "text",
  autocomplete = "",
  required = false,
  pattern,
  orange = false,
  shelf = false,
  width = "100%",
  errorMessage,
  value,
  placeholder,
  onChange,
  label,
  style,
  labelStyle,
  id,
  error,
  onFocus = () => null,
  onBlur = () => null,
  ...props
}) => {
  const inputEl = useRef(null);
  const [isValid, setValid] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const validate = () => setValid(inputEl.current.checkValidity());

  const onType = e => {
    onChange(e);
    if (inputEl.current.checkValidity()) setValid(true);
  };
  useEffect(
    () => {
      if (!value || !shouldValidate) return;
      validate();
      setShouldValidate(false);
    },
    [value, shouldValidate]
  );

  return (
    <React.Fragment>
      <Label htmlFor={id} style={style}>
        {!shelf && (
          <React.Fragment>
            {orange ? (
              <H6
                css={css`
                  color: ${yumiOrange};
                `}
              >
                {label}
              </H6>
            ) : (
              <Body2 style={{ marginBottom: "5px", ...labelStyle }}>
                {" "}
                {label}
              </Body2>
            )}
          </React.Fragment>
        )}

        <Input
          width={width}
          type={type}
          autoComplete={autocomplete}
          required={
            required === true || required === "required" ? "required" : ""
          }
          pattern={pattern}
          ref={inputEl}
          value={value}
          shelf={shelf}
          placeholder={placeholder}
          onChange={onType}
          onFocus={e => {
            onFocus(e);
            setShouldValidate(false);
          }}
          onBlur={e => {
            onBlur(e);
            setShouldValidate(true);
          }}
          name={id}
          id={id}
          error={error}
          {...props}
        />
        {!isValid && <Body2 style={{ color: "#EE5A37" }}>{errorMessage}</Body2>}
        {error && <Body2 style={{ color: "#EE5A37" }}>{error}</Body2>}
      </Label>
    </React.Fragment>
  );
};

export const RadioInput = ({ value, label, id, onChange, ...props }) => (
  <Label
    htmlFor={id}
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      marginTop: "20px",
      marginBottom: "20px",
      width: "100%"
    }}
  >
    {label}
    <input
      type="radio"
      onChange={onChange}
      value={value}
      id={id}
      name={id}
      style={{
        width: "10px",
        backgroundColor: "unset",
        border: "auto",
        appearance: "unset"
      }}
      {...props}
    />
  </Label>
);

export const BasicInput = ({ basicRef, label, ...props }) => {
  const { error } = props;
  return (
    <BasicInputContainer>
      <span>{label}</span>
      <Input ref={basicRef} {...props} />
      {error && <Body2 style={{ color: "#EE5A37" }}>{error}</Body2>}
    </BasicInputContainer>
  );
};

const BasicInputContainer = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  font-weight: 400;
  width: 360px;
  max-width: 100%;
  margin-bottom: 1em;
`;

const Input = styled.input`
  background: #ffffff;
  ${props =>
    props.error
      ? "border: 1px solid #EE5A37;"
      : "border: 1px solid rgba(0, 0, 0, .12);"};
  border-radius: 0;
  box-sizing: border-box;
  font-family: "Ginger";
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: normal;
  letter-spacing: 0.005em;
  color: ${props => props.shelf && "black"};
  height: ${props => (props.shelf ? `30px` : `40px`)};
  padding-left: 15px;
  width: ${props => (props.width ? props.width : "100%")};
  -webkit-appearance: none;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  &::placeholder {
    opacity: 0.75;
  }
`;

const Input2Style = styled(Input)`
  font-family: "Freight-Book";
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputButton = styled.input`
  font-family: "Platform-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13.82px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  background: #000000;
  color: white;
  text-align: center;
`;

export const GreyInput = styled.input`
  width: ${({ width }) => width || "105px"};
  height: ${({ height }) => height || "36px"};
  background: rgba(196, 196, 196, 0.19);
  border: 0.5px solid #979797;
  box-sizing: border-box;
  font-family: "Freight-Book";
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
`;

export const DashboardButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || "174px"};
  height: ${({ height }) => height || "50px"};
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "pointer")};
  font-family: "Platform-Medium";
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background-color: ${({ backgroundColor }) => backgroundColor || "#658FCA"};

  background-color: ${({ backgroundColor, isLoading }) =>
    backgroundColor && !isLoading
      ? backgroundColor
      : isLoading
        ? "#46648d"
        : "658FCA"};
  line-height: 15px;
  letter-spacing: 0.03em;
  &:hover {
    background-color: ${({ backgroundColorHover }) =>
      backgroundColorHover || "#46648d"};
  }
`;

export const Label = styled.label`
  font-family: Ginger, sans-serif;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  margin-bottom: 1em;
`;

const Label2 = styled(Label)`
  font-family: "Freight-Book";
`;

export const TextInput = ({
  type = "text",
  required = false,
  value,
  placeholder,
  onChange,
  label,
  style,
  labelStyle,
  basicRef,
  validate = _.noop,
  onFocus = _.noop,
  onBlur = _.noop,
  ...props
}) => {
  const onType = e => {
    onChange(e);
  };

  return (
    <React.Fragment>
      <Label2 htmlFor={label} style={style}>
        <Detail
          style={{ marginBottom: "5px", fontSize: "12px", ...labelStyle }}
        >
          {" "}
          {label}
        </Detail>
        <Input2Style
          type={type}
          required={
            required === true || required === "required" ? "required" : ""
          }
          ref={basicRef}
          value={value}
          placeholder={placeholder}
          onChange={onType}
          name={label}
          id={label}
          {...props}
        />
      </Label2>
    </React.Fragment>
  );
};

export const SaveBar = ({
  marginTop,
  width,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  mobileWidth,
  isLoading
}) => (
  <ModalRow width={width} marginTop={marginTop} mobileWidth={mobileWidth}>
    {" "}
    <span style={{ cursor: "pointer" }} onClick={onCancel}>
      {cancelText}
    </span>{" "}
    <DashboardButton
      onClick={onSubmit}
      disabled={isLoading}
      isLoading={isLoading}
    >
      {submitText}
    </DashboardButton>
  </ModalRow>
);

export const ModalRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: ${({ width }) => width || "174px"};
  margin-top: ${({ marginTop }) => marginTop || ""};
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  font-family: "Freight-Book";
  @media (max-width: 1000px) {
    width: ${({ mobileWidth }) => mobileWidth || "80%"};
  }
`;

export const StyledDescription = styled.div`
  width: 80%;
  padding-top: 10px;
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-family: "Freight-Book";
  font-size: ${({ fontSize }) => fontSize || "16px"};
  line-height: 21px;
  letter-spacing: 0.0025em;
  min-width: ${({ minWidth }) => minWidth || ""};
`;

export const RewardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 430px;
`;

export const ModalTitle = styled.h1`
  font-family: "Platform-Medium";
  font-style: normal;
  color: ${({ color }) => color || "#000000"};
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || "18px"};
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
`;

export const SummaryTitle = styled.span`
  font-family: "Platform-Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  min-width: 130px;
  letter-spacing: 0.001em;
`;

export const PageTitle = styled.h1`
  font-family: "Platform-Medium";
  font-weight: 500;
  font-size: 22px;
  line-height: 17px;
  width: 100%;
  margin-left: 65px;
  text-align: left;
  letter-spacing: 0.001em;
  color: #ee5a37;
  @media (max-width: 1000px) {
    margin-left: 38px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  width: 100%;
  margin-top: 59px;
  text-align: left;
  letter-spacing: 0.001em;

  @media (max-width: 1000px) {
    margin-top: 60px;
  }
`;
