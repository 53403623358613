import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  blackberry,
  blueberry,
  oatAdjusted
} from "../../../styledcomponents/colors";
import { H6, BlueButton, Body1 } from "../../../styledcomponents";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { H1, H2, Body } from "../../../styledcomponents/typefaces";
import "styled-components/macro";
import { ByChloeLanding, BabyOnOat, BowlOnOat } from "../assets";

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";

export const HowToLanding = () => {
  const { width } = useWindowSize();
  return (
    <HowToContainer>
      <HowFacts>
        <H2
          style={{ color: blackberry }}
          css={css`
            @media (max-width: 1000px) {
              padding-bottom: 20px;
            }
          `}
        >
          How it Works
        </H2>

        <InfoArea>
          {" "}
          <IconRow>
            <img
              src={BabyOnOat}
              alt="fast"
              css="width:80px; @media(max-width:1000px){width:60px}"
            />
          </IconRow>
          <TextArea>
            <H2
              style={{ fontSize: "27pt", color: blueberry }}
              css={css`
                @media (max-width: 1000px) {
                  font-size: 15pt !important;
                }
              `}
            >
              Made for your babe
            </H2>
            <Body1
              style={{ fontSize: "12pt", width: "400px" }}
              css={css`
                @media (max-width: 1000px) {
                  width: 100% !important;
                  font-size: 11pt !important;
                }
              `}
            >
              Our nutritionist will recommend the perfect plan to suit your
              babe's developmental needs.
            </Body1>
          </TextArea>
        </InfoArea>
        <InfoArea>
          <IconRow>
            <img
              src={BowlOnOat}
              alt="fast"
              css="width:80px; @media(max-width:1000px){width:60px}"
            />
          </IconRow>
          <TextArea>
            {" "}
            <H2
              style={{ fontSize: "27pt", color: blueberry }}
              css={css`
                @media (max-width: 1000px) {
                  font-size: 15pt !important;
                }
              `}
            >
              You pick, we cook.
            </H2>
            <Body1
              style={{ fontSize: "12pt", width: "400px" }}
              css={css`
                @media (max-width: 1000px) {
                  width: 100% !important;
                  font-size: 11pt !important;
                }
              `}
            >
              Let us know how many times per day you'd like to serve Yumi.
              You’ll be able to add the chef collab box at checkout.
            </Body1>
          </TextArea>
        </InfoArea>
        <InfoArea>
          <IconRow>
            <img
              src={PricingFast}
              alt="fast"
              css="width:80px; @media(max-width:1000px){width:50px}"
            />
          </IconRow>
          <TextArea>
            <H2
              style={{ padding: "none", fontSize: "27pt", color: blueberry }}
              css={css`
                @media (max-width: 1000px) {
                  font-size: 15pt !important;
                }
              `}
            >
              Delivered fresh weekly
            </H2>
            <Body1
              style={{ fontSize: "12pt", width: "400px" }}
              css={css`
                @media (max-width: 1000px) {
                  width: 100% !important;
                  font-size: 11pt !important;
                }
              `}
            >
              Starting at $35 for a week. You can edit or skip anytime!
            </Body1>
          </TextArea>
        </InfoArea>
        <Link to="/checkout">
          <BlueButton
            style={{
              background: blackberry,
              color: "white",
              paddingTop: "20px",
              paddingBottom: "20px",
              width: "200px"
            }}
            css={css`
              @media (max-width: 1000px) {
                display: none !important;
              }
            `}
            value="EXPLORE PLANS"
          />
        </Link>
      </HowFacts>
      <ByChloeLandingImage>
        <img
          src={ByChloeLanding}
          style={{
            width: "500px",
            height: "620px",
            marginLeft: "50px",
            objectFit: "cover"
          }}
          alt="chloe"
        />
      </ByChloeLandingImage>
    </HowToContainer>
  );
};

const IconRow = styled.div`
  align-self: flex-start;
  padding: none;
`;
const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;
const InfoArea = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;
const HowFacts = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: flex-start;
  padding-left: 100px;
  justify-content: space-evenly;
  @media (max-width: 1000px) {
    order: 2;
    padding-left: 0px;
    justify-content: space-between;
    height: 470px !important;
    width: 100%;
    margin-top: 70px !important;
    align-items: center;
    padding-bottom: 90px !important;
  }
`;

const ByChloeLandingImage = styled.div`
  align-items: center;
  width: 400px;
  display: flex;
  justify-content: center;
  height: 750px;
  @media (max-width: 1000px) {
    order: 1;
    width: 100% !important;
    height: 600px !important;
  }
`;

const HowToContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${oatAdjusted};
  @media (max-width: 1100px) {
    width: 100% !important;
    align-items: center;
    flex-direction: column;
  }
`;
