import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { yumiPurple, InputField, H6 } from "../../styledcomponents";
import { H2 } from "../../styledcomponents/typefaces";

const CustomImage = "https://email.helloyumi.com/assets/_GiftCard/texBox.png";

export const SelectableBar = ({
  data,
  currentValue,
  setFunction,
  activeIndex,
  setActiveIndex,
  initialIndex
}) => {
  useEffect(
    () => {
      setActiveIndex(initialIndex);
    },
    [initialIndex]
  );

  return (
    <BarSection>
      {data.map((item, i) => {
        const active = activeIndex === i && currentValue.custom === 0;
        return (
          <SelectableSection
            active={active}
            onClick={() => {
              setActiveIndex(i);
              setFunction(false, item.value, 0);
            }}
          >
            <NumberedSection active={active}>
              <SmallAmount active={active}>${item.value}</SmallAmount>
              <BigNumber active={active}>{item.weekCount}</BigNumber>
              <WeekText active={active}>{item.title}</WeekText>
            </NumberedSection>
          </SelectableSection>
        );
      })}
      <SelectableSection text>
        <SmallGraySubtitle>Custom Amount</SmallGraySubtitle>
        <InputField
          onChange={e =>
            e.target.value < 1000000000 && setFunction(true, e.target.value)
          }
          value={currentValue.custom}
          placeholder="Enter Amount"
          css={css`
            border: none;
            height: 45%;
            font-size: 16px;
            outline: 0px;
            width: 80%;

            margin-left: 15px;
            border: 1px solid ${yumiPurple};
          `}
        />
      </SelectableSection>
    </BarSection>
  );
};

const SmallAmount = styled(H6)`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
  color: ${({ active }) => (active ? "white" : "black")};
`;
const WeekText = styled(H6)`
  font-size: 15px;
  color: ${({ active }) => (active ? "white" : "black")};
`;
const BigNumber = styled(H2)`
  font-size: 35px;
  padding-right: 10px;

  color: ${({ active }) => (active ? "white" : "black")};
`;

const NumberedSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10px 47px 25px;
  opacity: ${({ active }) => (active ? "1" : "0.5")};

  padding-left: 13px;
`;

const SmallGraySubtitle = styled(H6)`
  color: black;
  font-size: 12px;
  margin-left: 15px;
  opacity: 0.7;
  margin-top: 15px;
`;

const BarSection = styled.div`
  display: flex;
`;
const SelectableSection = styled.div`
  width: ${({ text }) => (text ? "200px" : "102px")};
  height: 84px;
  border: 1px solid lightgray;

  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: none;

  opacity: ${({ active }) => (active ? `1` : `0.8`)};
  transition: all 0.2s ease-out;

  background: ${({ active }) => (active ? yumiPurple : "white")};

  &:hover {
    cursor: pointer;
    ${({ disabled }) => !disabled && "1px 5px 7px rgba(0, 0, 0, 0.25);"};
    transition: all 0.2s ease-in;
    opacity: 1;
  }
`;
export const SelectableCards = ({
  square = false,
  bar = false,
  data,
  activeIndex,
  setActiveIndex,
  TitleRow,
  once = false,
  titleChildren = "",
  BodyRow,
  bodyChildren,
  currentValue,
  sectionWidth = "100%",
  setFunction,
  disabled = false,
  numbered = false,
  grid = false,
  initialIndex = 0,
  size = "400px",
  desktopColumns = { count: "4", size: "235px" },
  mobileColumns = { count: "1", size: "250px" },
  desktopRows = { count: "2", size: "400px" },
  mobileRows = { count: data.length, size: "250px" },
  minHeight = "600px"
}) => {
  useEffect(
    () => {
      setActiveIndex(initialIndex);
    },
    [initialIndex]
  );

  return (
    <CardSection
      sectionWidth={sectionWidth}
      grid={grid}
      bar={bar}
      once={once}
      disabled={disabled}
      length={data.length}
      size={size}
      desktopColumns={desktopColumns}
      mobileColumns={mobileColumns}
      desktopRows={desktopRows}
      mobileRows={mobileRows}
      minHeight={minHeight}
    >
      {data.map((item, i) => (
        <SelectableCard
          onClick={() => {
            if (!disabled) {
              setActiveIndex(i);
              setFunction(false, item.value);
            }
          }}
          disabled={disabled}
          active={
            currentValue && currentValue.custom === 0 && i === activeIndex
          }
          square={square}
          grid={grid}
          css={css`
            @media (max-width: 1000px) {
              margin-top: 20px;
            }
          `}
        >
          {!bar && (
            <ImageContainer square={square} grid={grid} disabled={disabled}>
              <img
                alt="img"
                src={item.image}
                css={css`
                  object-fit: ${!grid && "cover"};
                  height: ${grid ? "111px" : "100%"};
                  width: ${grid && "180px"};
                  object-position: ${item.imagePosition
                    ? item.imagePosition
                    : "bottom"};

                  object-position: ${disabled && "bottom"};
                  @media (max-width: 1000px) {
                    height: ${square ? "202px" : "101px"};
                  }
                `}
                width="100%;"
              />
            </ImageContainer>
          )}
          <BodySection
            disabled={disabled}
            active={i === activeIndex}
            square={square}
          >
            <TitleRow disabled={disabled} active={i === activeIndex}>
              {numbered && <Number>{item.weekCount}</Number>}
              {titleChildren ? titleChildren() : item.title}
            </TitleRow>
            <BodyRow disabled={disabled} active={i === activeIndex}>
              {bodyChildren ? bodyChildren() : item.body}
            </BodyRow>
          </BodySection>
        </SelectableCard>
      ))}
      {once && (
        <SelectableCard
          disabled={disabled}
          square={square}
          grid={grid}
          css={css`
            @media (max-width: 1000px) {
              margin-top: 20px;
            }
          `}
        >
          {" "}
          <ImageContainer>
            <img src={CustomImage} alt="img" />
          </ImageContainer>
          <BodySection>
            <SmallGraySubtitle>Custom Amount</SmallGraySubtitle>
            <InputField
              onChange={e =>
                e.target.value < 1000000000 && setFunction(true, e.target.value)
              }
              value={currentValue && currentValue.custom}
              placeholder="Enter Amount"
              css={css`
                border: none;
                height: 60%;

                font-size: 16px;
                outline: 0px;
                margin: 10px;
                height: 40px;
                width: 80%;
                border: 1px solid ${yumiPurple};
              `}
            />
          </BodySection>
        </SelectableCard>
      )}
    </CardSection>
  );
};

const ImageContainer = styled.div`
  height: ${({ square }) => (square ? "264px" : "169px")};
  height: ${({ disabled }) => disabled && "175px"};
  display: ${({ grid }) => grid && "flex"};
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    height: ${({ square }) => (square ? "194px" : "101px")};
  }
`;
const Number = styled(H2)`
  font-size: 40px;
  padding-right: 10px;
`;
const BodySection = styled.div`
  height: ${({ square }) => (square ? "111px" : "169px")};
  height: ${({ disabled }) => disabled && "230px"};
  height: ${({ disabled }) => disabled && "230px"};
  background: ${({ active, disabled }) =>
    active && !disabled ? yumiPurple : "white"};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: ${({ disabled }) =>
      !disabled && "1px 5px 7px rgba(0, 0, 0, 0.25)"};
    transition: all 0.2s ease-in;
  }
  @media (max-width: 1000px) {
    height: ${({ square }) => (square ? "67px" : "101px")};
  }
`;
const SelectableCard = styled.div`
  width: ${({ square, disabled }) => (square || disabled ? "426px" : "206px")};

  height: ${({ square }) => (square ? "373px" : "338px")};
  height: ${({ disabled }) => disabled && "300px"};

  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: none;

  opacity: ${({ active, disabled }) => (active || disabled ? `1` : `0.8`)};
  transition: all 0.2s ease-out;

  background: ${({ grid, active }) => (grid && active ? yumiPurple : "white")};

  &:hover {
    cursor: pointer;
    ${({ disabled }) => !disabled && "1px 5px 7px rgba(0, 0, 0, 0.25);"};
    transition: all 0.2s ease-in;
    opacity: 1;
  }

  @media (max-width: 1000px) {
    width: ${({ square }) => (square ? "314px" : "336px")};
    height: ${({ square }) => (square ? "201px" : "101px")};

    display: ${({ square }) => (square ? "block" : "grid")};
    grid-template-columns: ${({ square }) => !square && "123px 213px"};
    grid-template-rows: ${({ square }) => !square && "101px"};
  }
`;

const CardSection = styled.div`
  display: ${({ grid }) => (grid ? "grid" : "flex")};
  grid-template-rows: ${({ grid, desktopRows }) =>
    grid && `repeat(${desktopRows.count}, ${desktopRows.size})`};
  grid-template-columns: ${({ grid, desktopColumns }) =>
    grid && `repeat(${desktopColumns.count}, ${desktopColumns.size})`};
  flex-wrap: wrap;
  width: ${({ sectionWidth }) => sectionWidth || "100%"};
  max-width: ${({ sectionWidth }) => sectionWidth || "100%"};
  min-width: ${({ sectionWidth }) => sectionWidth || "100%"};
  justify-content: center;

  background: ${({ bar }) => bar && "red"};
  @media (max-width: 1295px) {
    justify-content: center;
  }
  @media (max-width: 1000px) {
    min-height: ${({ minHeight }) => minHeight && minHeight};
    grid-template-columns: ${({ grid, mobileColumns }) =>
      grid && `repeat(${mobileColumns.count}, ${mobileColumns.size})`};
    grid-template-rows: ${({ grid, mobileRows }) =>
      grid && `repeat(${mobileRows.count}, ${mobileRows.size})`};
    max-width: 100%;
    width: 100%;
  }
`;
