import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { oatCreamy, H5, yumiOrange } from "../../../styledcomponents";
import { InnerGridTemplate } from "../assets";
import { Body } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

export const WhatsIncluded = ({ MenuContext }) => {
  const includedContext = useContext(MenuContext);

  return (
    <WhatsContainer>
      <InnerGridTemplate
        gridDimensions="0.3fr 1fr 1.7fr 0.3fr"
        gridMidDimensions="0.3fr 1fr 1fr 0.3fr"
        leftStyling={css`
          @media (max-width: 800px) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        `}
        leftChildren={(
          <React.Fragment>
            <H5
              css={css`
                font-size: 18pt;
                @media (max-width: 800px) {
                  font-size: 16px;
                }
              `}
            >
              WHAT'S INCLUDED
            </H5>
            <Body css="width:95%; font-size:24px; padding-top:20px; @media(max-width:1200px){font-size:20pt !important;width:300px;}@media(max-width:800px){width:60%; font-size: 12pt !important; text-align:center;}">
              For a limited time, we're offering you the chance to purchase our
              First Foods Pack, typically only available to Yumi members.
            </Body>
          </React.Fragment>
)}
        rightStyling={css`
          @media (max-width: 800px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            text-align: center;
          }
        `}
        rightChildren={(
          <IncludedRow>
            <Item>
              <H5
                css={css`
                  font-size: 30px !important;
                  text-align: center !important;

                  color: ${yumiOrange} !important;

                  @media (max-width: 800px) {
                    font-size: 19px !important;
                  }
                `}
              >
                {includedContext.selectedPlan.value === "weekly"
                  ? `7 jars for 1 week`
                  : `7 jars weekly for 4 weeks`}
              </H5>
              <Body css="width:80% !important; text-align:center;font-size:20px;  line-height:1.5em !important;@media(max-width:800px){padding-top:10px;width:35%;font-size: 15px;}">
                {" "}
                Freshly made, organic and nutrient dense baby food
              </Body>
            </Item>
            <Item>
              <H5
                css={css`
                  text-align: center !important;
                  width: 65%;
                  font-size: 30px !important;
                  color: ${yumiOrange};

                  @media (max-width: 800px) {
                    font-size: 19px !important;
                  }
                `}
              >
                1 free coaching session
              </H5>
              <Body css="width:70%; text-align:center;font-size:20px; line-height:1.5em; @media(max-width:800px){padding-top:10px;width:37%;font-size: 15px;}">
                Parenting doesn't come with a manual, but we give you a
                nutritional coach
              </Body>
            </Item>
            <Item>
              <H5
                css={css`
                  text-align: center;
                  font-size: 30px !important;
                  color: ${yumiOrange};

                  @media (max-width: 800px) {
                    font-size: 19px !important;
                  }
                `}
              >
                1 baby hazmat suit
              </H5>
              <Body css="width:80%; text-align:center; font-size:20px; line-height:1.5em; @media(max-width:800px){padding-top:10px; font-size: 15px;}">
                Because it gets messy, trust us.
              </Body>
            </Item>
          </IncludedRow>
)}
      />
    </WhatsContainer>
  );
};

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    padding-top: 40px;
  }
`;
const IncludedRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 750px;
  height: 180px;

  @media (max-width: 1100px) {
    width: 450px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: space-evenly;
    height: 320px;
  }
`;

const WhatsContainer = styled.div`
  width: 100%;
  height: 458px;
  position: relative;
  overflow: hidden;
  z-index: 99;
  background: ${oatCreamy};
  align-items: center;
  display: flex;

  @media (max-width: 800px) {
    justify-content: center;
    height: 600px;
  }
`;
