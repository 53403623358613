import React from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import "styled-components/macro";
import { Label } from "../../styledcomponents/typefaces";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_tots/${name}${extension}`;

export const PizzaHeader = getImageURL("pizza-bites", ".png");
export const Tomato = getImageURL("Tomato", ".svg");
export const Banana = getImageURL("Banana", ".svg");
export const Broccoli = getImageURL("Broccoli", ".svg");
export const Pineapple = getImageURL("Pineapple", ".svg");
export const StageFour = getImageURL("Stage-4", ".png");
export const Hanimal = getImageURL("hanimal-min", ".png");
export const AllIngredients = getImageURL("all_snacks_ingredients", ".png");
export const List = getImageURL("TotBoxDetails_List", ".svg");
export const Close = getImageURL("TotBoxDetails_Close", ".svg");
export const Calendar = getImageURL("TotBoxDetails_Calendar", ".svg");
export const Add = getImageURL("TotBoxDetails_Add", ".svg");

export const DarkBlue = "#324599";
export const BabyBlue = "#668FCA";

export const LinkButton = ({
  to = "/checkout",
  value = "GET STARTED",
  background = BabyBlue,
  color = "white"
}) => (
  <Link
    to={to}
    css={css`
      text-decoration: none;
      @media (max-width: 500px) {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        padding: 10px;
        z-index: 899;
        box-shadow: 0 2px 5px 0 #e9e9e9;
        background: #f6da71
          url(https://email.helloyumi.com/assets/bannerStarsDesktop.svg)
          no-repeat;
        background-size: contain;
        left: 0px;
      }
    `}
  >
    <button
      type="button"
      value={value}
      css={`
        background: ${background};
        color: ${color};
        padding: 1em 3em 1em 3em;
        font-family: "Platform";
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <Label>{value}</Label>
    </button>
  </Link>
);
