import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { routeToCheckout, routeToHomepage } from "../../redux/actions/Screens";

class RouteWrapper extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

const mapDispatchToProps = dispatch => ({
  routeToCheckout: () => dispatch(routeToCheckout()),
  routeToHomepage: () => dispatch(routeToHomepage())
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(RouteWrapper)
);
