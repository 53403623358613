import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { HalfPineapple, AboutHero, AboutFounders } from "../assets";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { blackberry } from "../../../styledcomponents";

export const OurStory = () => (
  <PageLayout>
    {" "}
    <img src={HalfPineapple} alt="pineapple" style={{ width: "150px" }} />
    <H1
      style={{
        color: blackberry,
        paddingTop: "24px",
        fontSize: "30pt"
      }}
      css={css`
        @media (max-width: 1000px) {
          padding-top: 16px;
        }
      `}
    >
      Our Story
    </H1>
    <StoryBody style={{ paddingTop: "24px" }}>
      We started this company because Angela is a giant nerd. You see, when she
      became pregnant with her first child, she spent months researching
      nutrition. She discovered that scientists consider nutrition in the first
      1,000 days to be the most important environmental factor in development.
    </StoryBody>
    <StoryImage src={AboutHero} />
    <StoryBody>
      And yet, despite this overwhelming research, the grocery store is filled
      with junk. In 100 years, baby food is still shelf-stable, highly
      processed, high in sugar and low in nutrition. In a word, it’s gross.
    </StoryBody>
    <StoryImage
      src={AboutFounders}
      css={css`
        @media (max-width: 1000px) {
          width: 80%;
        }
      `}
    />
    <StoryBody>
      We got angry. We got inspired. We quit respectable jobs. We founded Yumi.
      Whether you use us for all of your meals or none, we hope our content and
      products inspire and empower you – and somehow make life a little bit
      easier.
    </StoryBody>
    <StoryQuote>Let's raise a healthier generation together.</StoryQuote>
    <Body
      style={{
        fontSize: "24pt",
        opacity: "0.6",
        paddingTop: "40px",
        paddingBottom: "80px"
      }}
      css={css`
        @media (max-width: 1000px) {
          font-size: 16pt !important;
        }
      `}
    >
      - Angela {`&`} Evelyn, Founders
    </Body>
  </PageLayout>
);

const StoryQuote = styled(H1)`
  color: ${blackberry};
  padding-top: 80px;
  text-align: center;
  width: 37%;

  @media (max-width: 1000px) {
    padding-top: 32px !important;
    font-size: 25pt !important;
    width: 100%;
  }
`;
const StoryImage = styled.img`
  width: 520px;
  padding-top: 32px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const StoryBody = styled(Body)`
  padding-top: 32px;
  width: 650px;

  @media (max-width: 1000px) {
    width: 300px;
  }
`;
const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
  padding-top: 50px;

  @media (max-width: 1000px) {
    padding-top: 20px !important;
    width: 100% !important;
  }
`;
