import * as types from "../types";

export const routeToCheckout = () => ({
    type: types.ROUTE_TO_CHECKOUT
  });

export const routeToHomepage = () => ({
    type: types.ROUTE_TO_HOMEPAGE
  });

export const routeToAccount = () => ({
    type: types.ROUTE_TO_ACCOUNT
  });
