import produce from "immer";

export const defaultState = {
  capacity: 0,
  items: [],
  wasEdited: false,
  menu: []
};

const getCapacityFromMealPlanId = id => ({ 121: 8, 141: 16, 161: 24 }[id]);
const computeTotalItems = state =>
  state.items.reduce((total, item) => total + item.quantity, 0);

export const editMenuReducer = (state, action) => {
  const { meal_id } = action;
  const { menu, capacity } = state;
  const itemSharesMealId = item => item.meal_id === meal_id;
  const itemFromMenu = menu.find(itemSharesMealId);
  const totalItems = computeTotalItems(state);
  return produce(state, draft => {
    if (totalItems > capacity || totalItems < 0) {
      draft.items = [];
      return;
    }
    draft.wasEdited = true;
    switch (action.type) {
      case "ADD_TO_CART": {
        draft.items.push({
          menu_id: itemFromMenu.id,
          meal_id: itemFromMenu.meal_id,
          meal_name: itemFromMenu.name,
          quantity: 2
        });
        break;
      }
      case "INCREMENT_ITEM": {
        const item = draft.items.find(itemSharesMealId);
        item.quantity += 2;
        if (item.quantity % 2 !== 0) draft.items = [];
        break;
      }
      case "DECREMENT_ITEM": {
        const item = draft.items.find(itemSharesMealId);
        item.quantity -= 2;
        draft.items = draft.items.filter(i => i.quantity > 0);
        if (item.quantity % 2 !== 0) draft.items = [];
        break;
      }
      case "REMOVE_ALL": {
        draft.items = [];
        break;
      }
      case "SET_INITIAL_STATE": {
        draft.items = action.initialState.items.filter(
          item => ![1521, 1522, 1523, 1524].includes(item.meal_id)
        );
        draft.snacks = action.initialState.items.filter(item =>
          [1521, 1522, 1523, 1524].includes(item.meal_id)
        );
        draft.menu = action.initialState.menu;
        draft.capacity = getCapacityFromMealPlanId(
          action.initialState.meal_plan
        );
        break;
      }
      default: {
        throw Error(`Unknown action: ${action.type}`);
      }
    }
  });
};

export const addToOrderAction = meal_id => ({
  type: "ADD_TO_CART",
  meal_id
});

export const incrementItemAction = meal_id => ({
  type: "INCREMENT_ITEM",
  meal_id
});

export const decrementItemAction = meal_id => ({
  type: "DECREMENT_ITEM",
  meal_id
});

export const removeAllAction = () => ({
  type: "REMOVE_ALL"
});

export const setInitialStateAction = state => ({
  type: "SET_INITIAL_STATE",
  initialState: state
});
