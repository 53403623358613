import React from "react";
import "./styles/styles.css";

const UpcomingPreviousToggle = ({ upcoming, showUpcoming, showPrevious }) => (
  <div className="orders_upcoming-toggle_container">
    <div
      className={`orders_upcoming-toggle_option_container${
        upcoming ? " active" : ""
      }`}
    >
      <div
        className={`orders_upcoming-toggle_option${upcoming ? " active" : ""}`}
        onClick={showUpcoming}
      >
        Upcoming
      </div>
    </div>
    <div
      className={`orders_upcoming-toggle_option_container${
        upcoming ? "" : " active"
      }`}
    >
      <div
        className={`orders_upcoming-toggle_option${upcoming ? "" : " active"}`}
        onClick={showPrevious}
      >
        Previous
      </div>
    </div>
  </div>
);

export default UpcomingPreviousToggle;
