import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { H2, H3 } from "@team-yumi/ui";
import Survey from "./components/Survey";

import { setPassword } from "../../usecases/checkout";
import Slider from "./components/Slider";

const check = "https://email.helloyumi.com/assets/check.svg";

export const Step3 = ({ setStep, userId, orderData }) => {
  const [passwordState, setPasswordState] = useState("");

  const runConfirmUser = async () => {
    await setPassword(userId, passwordState);
  };

  return (
    <Step1Container>
      <H2 mb="10px" color="#333333">
        Success!
      </H2>

      <IntroP color="#333333">
        Thanks for placing your order — Your order is in the works! We’ve sent
        an email confirmation to you.
      </IntroP>

      <SearchField>
        <ConfirmInput
          disabled
          placeholder="Password Set!"
          type="password"
          onChange={(e) => setPasswordState(e.target.value)}
          value={passwordState}
        />

        <button
          style={{ border: "none", background: "none" }}
          type="submit"
          onClick={() => runConfirmUser()}
        >
          <img src={check} style={{ paddingRight: "8px" }} />
        </button>
      </SearchField>

      <H3 mt="30px" mb="30px" color="#333333">
        What's Next?
      </H3>
      <Slider orderData={orderData} />
    </Step1Container>
  );
};

const SearchField = styled.div`
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  height: 56px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "Ginger", sans-serif;
  color: #777777;
`;

const ConfirmInput = styled.input`
  background: #ffffff;
  width: 200px;
  height: 100%;
  padding-left: 10px;
  outline: none;
  border-radius: 4px;
  font-weight: bold;
  font-family: "Ginger", sans-serif;
  color: #2fab4f;
  ::placeholder {
    -webkit-text-fill-color: #2fab4f;
    opacity: 1; /* required on iOS */
    color: #2fab4f;
  }
  :disabled {
    -webkit-text-fill-color: #2fab4f;
    opacity: 1; /* required on iOS */
    color: #2fab4f;
  }
`;

const IntroP = styled.p`
  font-family: "Ginger";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  align-self: center;
  margin: 0px 10px 40px 0px;
  max-width: 335px;
  color: #4a4a4a;
`;

const Step1Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
