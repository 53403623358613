import styled from "styled-components";
import React from "react";
import "styled-components/macro";

export const MostPopularHeader = () => (
  <MostPopularContainer
    css="background: #F6DA71 url(
            'https://email.helloyumi.com/assets/bannerStarsDesktop.svg') no-repeat; background-size: contain;
    @media (max-width: 500px) {
    background-url: url('https://email.helloyumi.com/assets/bannerStarsMobile.svg');
    }
         "
  >
    MOST POPULAR
  </MostPopularContainer>
);

const MostPopularContainer = styled.div`
  width: 100%;
  position: absolute;
  margin-top: -105px;
  z-index: 100;
  height: 30px;
  width: 300px;
  text-transform: "uppercase";
  font-family: "Platform-Medium", sans-serif;
  letter-spacing: 0.0125em;
  font-size: 13.82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #334897;
  @media (max-width: 1000px) {
    width: 40vw;
  }
  @media (max-width: 500px) {
    margin-top: -105px;
    width: 80vw;
  }
`;
