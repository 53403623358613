import React from "react";
import styled from "styled-components";

export const Details = ({ title, children, ...props }) => (
  <DetailStyle {...props}>
    <SummaryStyle>
      <div>
        {title}
        <img
          src="https://email.helloyumi.com/assets/down-arrow.svg"
          alt="add"
        />
      </div>
    </SummaryStyle>
    <section>
      <div>{children}</div>
    </section>
  </DetailStyle>
);

const DetailStyle = styled.details`
  background: white;
  transition-duration: 0.3s;
  padding: 0;
  padding: 1em;
  :focus {
    outline: 0;
  }

  div {
    :focus {
      outline: 0;
    }
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      height: 0;
    }
    100% {
      opacity: 1;
      height: 20px;
    }
  }

  section {
    z-index: 3;
    background: white;
  }

  &[open] > section {
    animation-name: slideDown;
    animation-duration: 200ms;
    animation-timing-function: cubic-bezier(0, 0, 1, 0);
    overflow: hidden;
    width: calc(100vw - 2px);

    position: absolute;
    left: 0;
    margin-top: 1em;

    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.12);
  }
`;
const SummaryStyle = styled.summary`
  font-family: "Platform-Medium", sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  letter-spacing: 1%;
  color: #334897;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  ::-webkit-details-marker {
    display: none;
  }

  :focus {
    outline: none;
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media (max-width: 1000px) {
    div {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.0025em;
    }
  }
`;
