import { useLayoutEffect } from "react";

export const useDisableScroll = condition => {
  const { scrollY } = window;
  const cleanUp = () => {
    document.body.style.position = "";
    document.body.style.overflow = "";
    if (window.innerWidth < 500) window.scrollTo({ top: scrollY });
  };
  useLayoutEffect(
    () => {
      if (!condition) return cleanUp;

      if (window.innerWidth < 500) document.body.style.position = "fixed";

      document.body.style.overflow = "hidden";

      return cleanUp;
    },
    [condition]
  );
};
