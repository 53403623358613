import React from "react";
import styled from "styled-components";
import {
  HoustonBanner,
  HoustonTop,
  OrganicFreshDense,
  HowItWorks,
  WhyItMatters,
  JustForYou,
  ScienceBased,
  TalkOfTheTown,
  YouKnowFoodSlice,
} from "./sections";
import { useWindowSize } from "../../hooks";

export const Houston = () => {
  const { width } = useWindowSize();
  return (
    <Sections>
      <HoustonBanner />
      <HoustonTop />
      {width > 500 && <HowItWorks />}
      <OrganicFreshDense width={width} />
      {width < 500 && <HowItWorks />}
      {width > 500 && <YouKnowFoodSlice />}

      <WhyItMatters width={width} />
      <JustForYou width={width} />
      <ScienceBased width={width} />
      <TalkOfTheTown width={width} />
    </Sections>
  );
};

export const Sections = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
