import { getSpecificMenu } from "../../usecases/menus";

/*
 * Fetches menus for date and returns the specifc menu that matches
 * the order's delivery date.
 */
export const getSpecificMenuService = async deliveryDate => {
  const menus = await getSpecificMenu(deliveryDate);

  return menus;
};
