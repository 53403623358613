import React, { useState, useEffect } from "react";
import _ from "lodash";
import spacetime from "spacetime";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import "styled-components/macro";
import {H4} from '@team-yumi/ui'
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Box,
  HR,
  Body2,
  BigButton,
  Toggler,
  Overline2,
  InputField,
  H6,
  BlueButton,
  blueberry,
  blackberry
} from "../../../styledcomponents";
import { updateUserId } from "../../../redux/actions/Checkout";

import StripeBillingForm from "../../../styledcomponents/containers/StripeBillingFormWithoutApplePay";
import { getGiftCard } from "../../../usecases/gift";
import { AddressForm } from "../../../styledcomponents/AddressForm";
import { fetchMenu, updateCartMenuAfterFetch, useRequest } from "../hooks";
import { useFormState, useMergeState } from "../../../hooks";
import {
  getCoupon,
  registerUser,
  registerUserWithBundle,
  getReferral
} from "../../../usecases/checkout";
import {
  getCouponText,
  getReferralText,
  couponDiscount,
  referralDiscount
} from "../../../utils/coupon";
import { getNextDeliveryDate, formatDatePretty } from "../../../utils/dates";
import StripeApplePay from "../../../styledcomponents/containers/StripeApplePay";
import { Body } from "../../../styledcomponents/typefaces";

const getToggledBillingPeriod = bp =>
  ({
    weekly: "monthly",
    monthly: "weekly"
  }[bp]);

const capacityToPlanMap = capacity =>
  ({
    8: 7,
    16: 14,
    24: 21
  }[capacity]);
const capacityToBonusJarsMap = capacity =>
  ({
    8: 1,
    16: 2,
    24: 3
  }[capacity]);

const mapDispatchToProps = dispatch => ({
  addUserToGlobalStore: payload => dispatch(updateUserId(payload))
});

const getImageUrlFromMealId = id =>
  `https://email.helloyumi.com/assets/0${id}.png`;

export const StepFive = connect(
  () => ({}),
  mapDispatchToProps
)(
  ({
    selectedPlan,
    planState,
    setPlanState,
    cart,
    dispatch,
    giftState,
    setGiftCodeState,
    referralState,
    setReferralState,
    couponState,
    setCouponState,
    signupForm,
    addUserToGlobalStore,
    history,
    trackEvent
  }) => {
    const { email, birthday, zipcode } = signupForm;

    const [giftCardError, setGiftCardError] = useState("");
    const [discountText, setDiscount] = useState("");
    const [applepayStripeResponse, setApplepayStripeResponse] = useState("");
    const [phoneNumber, setPhonenumber] = useState("");
    const [cardholder, setCardholder] = useState("");
    const [formError, setFormError] = useState("");
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const [showGift, setShowGift] = useState(false);
    const [shippingFormState, setShippingField, setShippingForm] = useFormState(
      {
        street: "",
        street2: "",
        city: "",
        state: "",
        postcode: ""
      }
    );

    const validateGiftCode = async giftCode => {
      const giftCard = await getGiftCard(giftCode);
      if (giftCard.length === 0) {
        setGiftCardError("This gift card code does not exist.");
        setGiftCodeState({ giftData: giftCard });
      }
      if (giftCard[0].redeemed === 1) {
        setGiftCardError("This gift card code has already been used.");
      }

      if (
        (giftCard && giftCard[0].redeemed === 0) ||
        giftCard[0].redeemed === null
      ) {
        setGiftCodeState({ giftData: giftCard });
        setGiftCardError("");
      }
    };
    const totalJar = cart.items.reduce((acc, item) => acc + item.quantity, 0);
    const submitCoupon = async couponString =>
      getCoupon({
        billing_plan: selectedPlan.name,
        timeline: planState.billingPeriod,
        coupon: couponString,
        bundle: true,
        zip: zipcode
      })
        .then(couponData => {
          setCouponState({
            couponData
          });
          if (!couponData.valid) throw new Error();
          if (couponData.duration === "once")
            setDiscount(
              `Coupon added successfully. You'll receive up to ${getCouponText(
                couponData
              )} on your first order.`
            );
        })
        .catch(e => {
          let errMsg = "Invalid Coupon";
          if (e.details.includes("can't be used")) {
            errMsg = `- Sorry, this code cannot be used for this order but we have applied a 50% off 1 week coupon to your order.\n
- 50OFF1WEEK is replacement coupon`;
          }
          setDiscount(errMsg);
          setCouponState({
            couponData: {},
            couponString: ""
          });
        });

    const submitReferral = async referralString =>
      getReferral(referralString)
        .then(referralData => {
          setReferralState({
            referralData
          });

          if (!referralData.valid) throw new Error();
          if (referralData.duration === "once")
            setDiscount(
              `Referral code ${
                referralData.id
              } applied successfully. You'll receive up to ${getReferralText(
                referralData
              )} on your first order.`
            );
        })
        .catch(() => {
          setDiscount(
            "Invalid Referral. Enter coupon code above or continue with your order."
          );
          setReferralState({
            referralData: {},
            referralString: ""
          });
        });

    const [getStripeData, setStripeSubmit] = useState(null);

    const isFormFull = _.every([
      email,
      cardholder,
      phoneNumber,
      ..._.values({
        ...shippingFormState,
        street2: true
      })
    ]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    useEffect(() => {
      if (couponState.couponString.split("").length > 0) {
        submitCoupon(couponState.couponString);
      }
    }, []);

    useEffect(() => {
      if (referralState.referralString.split("").length > 0) {
        submitReferral(referralState.referralString);
      }
    }, []);

    const convertUserData = user => {
      const plan =
        user.billing && user.billing.current_billing_plan
          ? user.billing.current_billing_plan.split("-")
          : "bento-five-weekly".split("-");

      const planMap = {
        weekly: {
          five: {
            planPrice: "38.33",
            planQuantity: 7
          },
          ten: {
            planPrice: "71.18",
            planQuantity: 14
          },
          twenty: {
            planPrice: "98.55",
            planQuantity: 21
          }
        },
        monthly: {
          five: {
            planPrice: "122.64",
            planQuantity: 7
          },
          ten: {
            planPrice: "227.76",
            planQuantity: 14
          },
          twenty: {
            planPrice: "315.36",
            planQuantity: 21
          }
        }
      };

      const { planPrice } = planMap[plan[2]][plan[1]];

      return {
        name: user.name,
        email: user.email,
        userId: user.id,
        planQuantity: plan[1],
        planName: plan[0],
        userCoupon: user.coupon,
        planPrice,
        ecommerce: {
          purchase: {
            actionField: {
              id: user.email,
              revenue: planPrice,
              tax: "0.00",
              shipping: "0.00",
              coupon: user.billing.extra_billing_information || ""
            },
            products: [
              {
                name: `${plan[0]} jars`,
                price: planPrice,
                quantity: plan[1]
              }
            ]
          }
        }
      };
    };
    const submitRegistration = async stripeData => {
      if (!isFormFull)
        return setFormError(
          "There are required form fields missing. Double check your form and try again"
        );
      setIsButtonClicked(true);

      try {
        setFormError("");
        trackEvent("CheckoutPaymentPressed");
        setIsPlacingOrder(true);
        const userBody = {
          email,
          name: cardholder,
          phone: phoneNumber,
          address: shippingFormState,
          stripe_card_token: stripeData.token.id,
          billing_plan: selectedPlan.name,
          deliveries: planState.deliveries,
          timeline: planState.billingPeriod,
          order_quads: cart.quads,
          order_attributes: cart.items.map(
            ({ quantity, menu_id, meal_id }) => ({
              quantity,
              menu_id,
              meal_id
            })
          ),
          coupon_id: _.get(couponState, "couponData.id", null),
          referral_code: _.get(referralState, "referralData.id", null),
          gift_code: _.get(giftState, "giftData[0].gift_code", null),
          special_menu_id: cart.special_menu_id,
          child_birthday: spacetime(birthday).format("yyyy-mm-dd"),
          segment_user_id: window.analytics
            ? window.analytics.user().anonymousId()
            : "not-found"
        };

        const user =
          planState.billingType === "subscription"
            ? await registerUser(userBody)
            : await registerUserWithBundle(userBody);
        if (!_.get(user, "id", null)) throw new Error(user.details);
        user.coupon = _.get(couponState, "couponData.id", null);
        trackEvent("CheckoutPaymentCompleted", {
          ...convertUserData(user),
          address: shippingFormState
        });
        addUserToGlobalStore(user);
        history.push("/add-on?bundle=1");
      } catch (userError) {
        setIsButtonClicked(false);
        setIsPlacingOrder(false);
        setFormError(userError.message);
      }
    };

    useEffect(() => {
      trackEvent("CheckoutPaymentLoaded", { step: "payment" });
    }, []);

    const price =
      planState.billingType === "bundle"
        ? planState.bundleAmount
        : selectedPlan.total;

    const zip = zipcode.substring(0, 2);

    const subtotal = Number(price).toFixed(2);

    const giftCardAmount =
      giftState && giftState.giftData !== null && giftState.giftData.length > 0
        ? (giftState.giftData[0].gift_amount / 100).toFixed(2)
        : 0;

    const tax =
      Number(subtotal) === Number(giftCardAmount)
        ? 0
        : planState.billingType !== "bundle"
          ? (price * 0.09505).toFixed(2)
          : 0;

    const taxDisplay =
      zip === "97" ? "Free" : `$${(price * 0.09505).toFixed(2)}`;
    const shippingDisplay =
      zip === "97" ? `$${(price * 0.09505).toFixed(2)}` : "Free";

    let couponDiscountAmount = Math.min(
      subtotal,
      couponDiscount(subtotal, couponState.couponData)
    );

    if (couponState.couponData.percent_off === 100) {
      couponDiscountAmount += Number(tax);
    }

    const referralDiscountAmount = Math.min(
      subtotal,
      referralDiscount(subtotal, referralState.referralData)
    );

    if (referralDiscountAmount) {
      couponDiscountAmount = 0
    }

    const total =
      Number(subtotal) +
      Number(tax) -
      Number(couponDiscountAmount) -
      Number(referralDiscountAmount) -
      Number(giftCardAmount);

    const giftCardCredit =
      Number(giftCardAmount) - (Number(subtotal) + Number(tax));

    return (
      <Box
        row
        css="justify-content: center; max-width: 1100px; width: 100%; margin-top: 3em; margin-bottom: 3em; flex-shrink: 0;"
      >
        <Box
          css={css`
            background: white;
            padding: 2.5em 3.5em;
            flex: 0 1 450px;
            margin-bottom: 1em;
            max-width: 100%;
            margin-left: 15px;
            margin-right: 15px;
            @media (max-width: 500px) {
              margin-left: 0px;
              margin-right: 0px;
            }
          `}
          name="left box"
          nowrap
          mobileStyle="padding: 1.5em;"
        >
          <StripeProvider apiKey={process.env.STRIPE_KEY}>
            <Elements>
              <StripeApplePay
                shelf={false}
                setStripeData={stripeResponse =>
                  setApplepayStripeResponse(stripeResponse)
                }
                amount={total}
                applePayMessage={null}
              />
            </Elements>
          </StripeProvider>
          <Box
            nowrap
            css="justify-content: space-around; flex-shrink: 0; align-items: flex-start; width: 100%;"
          >
            <H4>
              Shipping Address{" "}
            </H4>{" "}
            <AddressForm
              formState={shippingFormState}
              setField={setShippingField}
              setForm={setShippingForm}
            />{" "}
            <InputField
              type="tel"
              placeholder=""
              value={phoneNumber}
              onChange={e => setPhonenumber(e.target.value.split("-").join(""))}
              label="Phone number"
              id="formPhone"
              errorMessage="Please enter a 10 digit US phone number."
              required
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            />
          </Box>{" "}
          <Box
            nowrap
            css="justify-content: flex-start; align-items: flex-start; flex-grow: 1; margin-top: 1 em; width: 100%;"
          >
            <H4>
              Payment Information{" "}
            </H4>{" "}
            <StripeBillingForm
              hideAddress
              applepayStripeResponse={applepayStripeResponse}
              setStripeSubmit={setStripeSubmit}
              applePayMessage="Payment processed successfully. Click PLACE ORDER to finish signing up."
              amount={total}
            />{" "}
            <Box row nowrap css="width: 100%; margin-top: 2em; flex-shrink: 0;">
              <InputField
                type="text"
                id="formName"
                label="Name on card"
                placeholder="Name on card "
                onChange={e => setCardholder(e.target.value)}
                value={cardholder}
              />{" "}
            </Box>{" "}
            {planState.billingType !== "bundle" && (
              <Body2 css="display: block; margin-top: 2em; @media(max-width: 500px) {display: none;}">
                {" "}
                Your subscription will automatically renew at the end of each
                pay period until you cancel. If you cancel after the weekly
                cutoff (Tuesday 11: 59 pm PT) or after the monthly cutoff
                (Tuesday 11: 59 pm PT of your third week), you will still
                receive and be charged for the next shipment.{" "}
              </Body2>
            )}
          </Box>{" "}
        </Box>{" "}
        <Receipt
          quantity={totalJar}
          bonus={capacityToBonusJarsMap(cart.capacity)}
          price={price}
          giftData={giftState && giftState.giftData}
          giftCardError={giftCardError}
          validateGiftCode={validateGiftCode}
          taxDisplay={taxDisplay}
          shippingDisplay={shippingDisplay}
          subtotal={subtotal}
          couponDiscountAmount={couponDiscountAmount}
          referralDiscountAmount={referralDiscountAmount}
          total={total}
          planState={planState}
          setPlanState={setPlanState}
          cart={cart}
          giftCardAmount={giftCardAmount}
          giftCardCredit={giftCardCredit}
          giftState={giftState}
          setGiftCodeState={setGiftCodeState}
          requestStripeData={getStripeData}
          couponState={couponState}
          setCouponState={setCouponState}
          submitCoupon={submitCoupon}
          discountText={discountText}
          submitRegistration={submitRegistration}
          isButtonDisabled={!isFormFull || isButtonClicked}
          formError={formError}
          isPlacingOrder={isPlacingOrder}
          showGift={showGift}
          setShowGift={setShowGift}
        />{" "}
      </Box>
    );
  }
);

const getPlan = cart => {
  if (!cart.special_menu_id) {
    return "Custom";
  }

  if (cart.special_menu_id === 31) {
    return "Variety";
  }

  if (cart.special_menu_id >= 39 && cart.special_menu_id <= 41) {
    return "Starters";
  }

  if ([58, 60, 61].includes(cart.special_menu_id)) {
    return "Snack";
  }

  if ([62, 63, 64].includes(cart.special_menu_id)) {
    return "by Chloe.";
  }

  if ([65, 66, 67].includes(cart.special_menu_id)) {
    return "Salare";
  }

  return "Milestone";
};

const Receipt = ({
  quantity,
  setPlanState,
  planState,
  giftData,
  giftCardError,
  taxDisplay,
  validateGiftCode,
  shippingDisplay,
  giftCardAmount,
  giftCardCredit,
  giftState,
  setGiftCodeState,
  total,
  couponState,
  setCouponState,
  submitCoupon,
  discountText,
  couponDiscountAmount,
  referralDiscountAmount,
  subtotal,
  requestStripeData,
  submitRegistration,
  isButtonDisabled,
  formError,
  isPlacingOrder,
  cart,
  showGift,
  setShowGift
}) => (
  <Box
    nowrap
    style={{
      background: "white",
      alignItems: "flex-start",
      flex: "0 2 450px",
      padding: "2.5em 3.5em",
      marginBottom: "1em",
      maxWidth: "100%"
    }}
    mobileStyle="padding: 2.5em 1.5em;"
    css={css`
      margin-left: 15px;
      margin-right: 15px;
      @media (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    `}
  >
    <H4>
      Order Summary{" "}
    </H4>{" "}
    <Box
      style={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%"
      }}
    >
      <Overline2> YOUR PLAN </Overline2>{" "}
      <Body2
        style={{
          marginTop: ".31em",
          marginBottom: "2em"
        }}
      >
        The "{getPlan(cart)} Plan" ({quantity} jars / weekly){" "}
      </Body2>{" "}
      <Overline2> FIRST DELIVERY DATE </Overline2>{" "}
      <Body2
        style={{
          marginTop: ".31em",
          marginBottom: "2em"
        }}
      >
        {" "}
        {formatDatePretty(getNextDeliveryDate())}{" "}
      </Body2>{" "}
    </Box>{" "}
    <Box
      full
      name="list of jars from your first box"
      css="margin-bottom:1em; align-items: flex-start;"
    >
      <Overline2 css="margin-bottom:1em;"> FIRST BOX </Overline2>{" "}
      {cart.items.map(meal => (
        <Box
          row
          full
          css="justify-content:space-between; height: 48px;"
          key={meal.meal_id}
        >
          <Body2>
            <img
              src={getImageUrlFromMealId(meal.meal_id)}
              height="32px"
              width="auto"
              alt="baby food mini jar"
              css="margin-right:.75em;"
            />
            {meal.meal_name}{" "}
          </Body2>{" "}
          <Body2> x {meal.quantity} </Body2>{" "}
        </Box>
      ))}{" "}
    </Box>{" "}
    {!showGift && (
      <GiftCardContainer>
        <GiftCardLink onClick={() => setShowGift(true)}>
          Add a Gift Card
        </GiftCardLink>
      </GiftCardContainer>
    )}
    {(showGift || giftState.giftString !== null) && (
      <React.Fragment>
        <React.Fragment>
          <Body2
            css={css`
              color: red;
              font-size: 11px;
              margin-top: 1em;
            `}
          >
            {giftCardError}
          </Body2>
          <div
            css={css`
              display: flex;
              width: 100%;
              align-items: center;
            `}
          >
            <InputField
              onChange={e => setGiftCodeState({ giftString: e.target.value })}
              value={giftState.giftString}
              placeholder="Add a gift code"
              css={css`
                width: 97%;
              `}
            />
            <BlueButton
              css={css`
                font-size: 11px;
                background: ${blackberry};
                color: white;
                width: 130px;
                padding: 0px;
                height: 40px;
                margin-top: -9px;
              `}
              value="VALIDATE CODE"
              onClick={() => validateGiftCode(giftState.giftString)}
            />
          </div>
        </React.Fragment>
      </React.Fragment>
    )}
    <HR />
    {referralDiscountAmount === 0 && (
      <Box
        row
        name="coupon row"
        css="align-items: flex-start; justify-content: center; width: 100%; height: 40px; margin-top: 1em;"
      >
        <InputField
          labelStyle={{
            display: "none"
          }}
          placeholder="Promo code?"
          value={couponState.couponString}
          onChange={e =>
            setCouponState({
              couponString: e.target.value
            })
          }
          style={{
            flex: "0 0 60%"
          }}
        />
        <input
          type="button"
          value="Add Coupon"
          onClick={() => submitCoupon(couponState.couponString)}
          style={{
            fontFamily: "Ginger",
            height: "100%",
            flex: "1 0 30%",
            background: "black",
            color: "white",
            cursor: "pointer",
            fontSize: ".8125em",
            backgroundColor: "#658FCA"
          }}
        />{" "}
      </Box>
    )}{" "}
    {!!discountText && (
      <Body2
        style={{
          marginTop: ".5em"
        }}
      >
        {" "}
        {discountText}{" "}
      </Body2>
    )}{" "}
    <HR
      style={{
        marginTop: "1em"
      }}
    />{" "}
    <Box
      style={{
        width: "100%",
        flex: "1 0 150px",
        marginTop: "1em"
      }}
      name="pricing container"
    >
      <Box
        row
        css="width: 100%; justify-content: space-between;"
        name="subtotal row"
      >
        <Body2> Subtotal </Body2> <Body2> $ {subtotal} </Body2>{" "}
      </Box>{" "}
      <Box
        row
        style={{
          width: "100%",
          justifyContent: "space-between"
        }}
        name="shipping row"
      >
        <Body2> Shipping </Body2>{" "}
        <Body2>
          <em> {shippingDisplay} </em>{" "}
        </Body2>{" "}
      </Box>{" "}
      {planState.billingType !== "bundle" && (
        <Box
          row
          style={{
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Body2> Estimated Tax </Body2> <Body2> {taxDisplay} </Body2>{" "}
        </Box>
      )}
      {giftData &&
        giftData.length > 0 && (
          <Box
            row
            style={{
              width: "100%",
              justifyContent: "space-between"
            }}
            name="gift row"
          >
            <Body2> Gift Card Amount </Body2> <Body2>-$ {giftCardAmount}</Body2>{" "}
          </Box>
        )}
      {!!couponDiscountAmount && (
        <Box
          row
          style={{
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Body2> Coupon Discount </Body2>{" "}
          <Body2> -$ {couponDiscountAmount.toFixed(2)} </Body2>{" "}
        </Box>
      )}
      {!!referralDiscountAmount && (
        <Box
          row
          style={{
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Body2> Referral Discount </Body2>{" "}
          <Body2> -$ {referralDiscountAmount.toFixed(2)} </Body2>{" "}
        </Box>
      )}{" "}
      <HR
        style={{
          marginTop: "1em",
          marginBottom: "1em"
        }}
      />{" "}
      <Box
        row
        style={{
          width: "100%",
          justifyContent: "space-between"
        }}
      >
        <Body2> Total </Body2>{" "}
        <Body2> $ {total < 0 ? "0.00" : total.toFixed(2)} </Body2>{" "}
      </Box>{" "}
      {giftData &&
        giftData.length > 0 && (
          <React.Fragment>
            <Box
              row
              style={{
                width: "100%",
                justifyContent: "space-between"
              }}
              name="gift row"
            >
              <Body2> Unused Credit Remaining </Body2>{" "}
              <Body2>
                $ {giftCardCredit < 0 ? "0.00" : giftCardCredit.toFixed(2)}{" "}
              </Body2>{" "}
            </Box>
            <span>Any unused credit will be deducted from your next bill.</span>{" "}
          </React.Fragment>
        )}
    </Box>{" "}
    <Box
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        flex: "1 0 50px"
      }}
      nowrap
      name="Complete order button box"
    >
      <Body2 css="margin-bottom: 10px;">
        {" "}
        Skip or cancel any time. Worry free!
      </Body2>{" "}
      <BigButton
        type="Submit"
        value="Place Order"
        disabled={isButtonDisabled}
        style={{
          alignSelf: "center"
        }}
        onClick={() =>
          requestStripeData().then(stripeData => submitRegistration(stripeData))
        }
        className="checkout-place-order"
        css="background-color: #658fca; color: white; flex-shrink: 0; &:hover {background-color: white; color: #334897; border-color: #334897;}"
      />
      <Body2> {isPlacingOrder && "Your order is being placed..."} </Body2>{" "}
      <Body2 css="color: red;"> {formError} </Body2>{" "}
      {planState.billingType !== "bundle" && (
        <Body2 css="margin-top: 2em; display: none; @media(max-width: 500px) {display: block; padding-bottom: 100px;}">
          Your subscription will automatically renew at the end of each pay
          period until you cancel.If you cancel after the weekly cutoff(Tuesday
          11: 59 pm PT) or after the monthly cutoff(Tuesday 11: 59 pm PT of your
          third week), you will still receive and be charged for the next
          shipment.{" "}
        </Body2>
      )}
    </Box>{" "}
  </Box>
);

const GiftCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  padding: 1em 0;
  border: none;
`;

const GiftCardLink = styled.a`
  color: #645394;
  font-family: "Ginger";
  text-decoration: underline;
`;
