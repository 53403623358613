import React from "react";
import styled from "styled-components";
import { texasOrange, texasSherbert } from "../../../styledcomponents/colors";
import { H6 } from "../../../styledcomponents";
import { CowboyHat, Cactus, TexasStar } from "../assets";
import "styled-components/macro";

export const HoustonBanner = () => (
  <BannerContainer>
    <img
      src={CowboyHat}
      alt="hat"
      css="width:200px;  margin-top:-33px;justify-self:flex-start;  @media(max-width:670px){margin-left:-70px;}  @media(max-width:550px){margin-left:-90px;}@media(max-width:500px){width:140px;margin-top:-26px; position: absolute;margin-left:-140px;}"
    />
    <img
      src={TexasStar}
      alt="star"
      css="position:absolute; margin-left:-280px; margin-top:10px; @media(max-width:1300px){margin-left:-200px; width:40px} @media(max-width:1100px){margin-left:-170px}@media(max-width:1199px){margin-left:-200px; width:40px} @media(max-width:1100px){margin-left:-170px}  @media(max-width:1000px){margin-left:-150px;width:40px}@media(max-width:850px){margin-left:-120px;width:30px}@media(max-width:750px){margin-left:-120px;width:20px}@media(max-width:670px){margin-left:-120px;margin-top:20px;width:20px}@media(max-width:500px){width: 40px; margin-top:35px; margin-left:-140px;}"
    />
    <img
      src={TexasStar}
      alt="star"
      css="position:absolute; margin-left:330px; margin-top:-20px; @media(max-width:1199px){margin-left:280px; width:40px}  @media(max-width:1000px){margin-left:200px;width:20px}@media(max-width:750px){margin-left:150px;width:20px}@media(max-width:670px){margin-left:100px; width:20px}@media(max-width:500px){width:16px; margin-left:100px; margin-top:-26px}"
    />
    <BannerText>
      Fresh baby food made weekly in California. Now delivering to Houston.
    </BannerText>
    <img
      src={Cactus}
      alt="hat"
      css="width:100px;transform:rotate(20deg);margin-top:5px;justify-self:flex-end; @media(max-width:500px){width:70px; position:absolute; margin-left:150px}"
    />
  </BannerContainer>
);

const BannerText = styled(H6)`
  color: ${texasOrange};
  font-size: 18pt;
  text-align: center;
  width: 29%;
  align-self: center;
  justify-self: center;
  margin-left: -70px;

  @media (max-width: 1000px) {
    font-size: 12pt;
  }
  @media (max-width: 500px) {
    font-size: 10pt;
    margin-left: 0px;

    width: 60%;
  }
`;
const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 100;
  top: 0;
  height: 100px;
  margin: 0 auto;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  background: ${texasSherbert};

  @media (max-width: 500px) {
    height: 80px;
  }
`;
