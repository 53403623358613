import React, { useState } from "react";
import spacetime from "spacetime";
import {
  Card,
  CardTitle,
  Label,
  InputField,
  EditHeader,
  FormContainer,
  ButtonContainer,
  ModalButton,
  CardSubtitle
} from "../components/styledComponents";
import Modal from "../components/Modal";
import BabyIcon from "../../../../assets/icons/Dashboard-icons/baby-icon.svg";
import { updateChild } from "../../../usecases/users";

export const ChildProfile = ({
  activeModal,
  close,
  openModal,
  success,
  child,
  user
}) => {
  const { name } = child;
  const birthday = spacetime(child.birthday).format("y-m-d");
  const [form, updateForm] = useState({
    name,
    birthday,
    child_id: child.child_id
  });
  return (
    <Card onClick={openModal} icon={BabyIcon}>
      <CardTitle>Child's Profile </CardTitle>
      <CardSubtitle>Name, birthday </CardSubtitle>
      <Modal isOpen={activeModal === "Child's Profile"} close={() => close()}>
        <EditHeader close={close}>Edit child's profile</EditHeader>
        <FormContainer
          onSubmit={e => {
            e.preventDefault();
            updateChild(user.id, form);
            success();
            close();
          }}
        >
          <Label htmlFor="babyName">Name</Label>
          <InputField
            onChange={e => updateForm({ ...form, name: e.target.value })}
            id="babyName"
            value={form.name}
            type="text"
            name="name"
          />

          <Label htmlFor="babyBirthday">Birthday</Label>
          <InputField
            onChange={e => updateForm({ ...form, birthday: e.target.value })}
            id="babyBirthday"
            value={form.birthday}
            type="date"
            name="birthday"
          />
          <ButtonContainer>
            <ModalButton cancel type="button" value="Cancel" onClick={close} />
            <ModalButton type="submit" value="Save" />
          </ButtonContainer>
        </FormContainer>
      </Modal>
    </Card>
  );
};
