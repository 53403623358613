import { updateOrderAddress } from "../../usecases/orders";

/*
 * Updates address for that week
 */
const changeOrderAddressByIdService = async (orderId, address) => {
  const addressUpdate = await updateOrderAddress(orderId, address);
  return addressUpdate;
};

export default changeOrderAddressByIdService;
