import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import importantImg from "../../../assets/orders/important-icon.svg";

const ResubscribeBanner = () => (
  <ResubscribeBannerContainer>
    <BannerDetails header>
      <img src={importantImg} />
        Important Message
    </BannerDetails>
    <BannerDetails>
      <BannerText>
          You are not currently subscribed to any Jar Plan.&nbsp;
      </BannerText>
      <BannerText desktop>
          Click <Link to="/profile/settings/resubscribe">here</Link> to sign up.
      </BannerText>
      <Link to="/profile/settings/resubscribe">
        <BannerText mobile>Subscribe now</BannerText>
      </Link>
    </BannerDetails>
  </ResubscribeBannerContainer>
  );

const ResubscribeBannerContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.07);
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: inherit;
    text-decoration: underline;
    @media (max-width: 480px) {
      width: 100%;
      text-decoration: none;
    }
  }

  @media (max-width: 480px) {
    max-width: 300px;
    border: 1px solid #e5e8ed;
    border-radius: 8px;
    margin-top: 28px;
    height: 110px;
  }
`;

const BannerDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-size: 12px;
  color: #000000;
  width: 100%;

  img {
    height: 12px;
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }

  ${props =>
    props.header &&
    css`
      font-family: Platform-Medium;
      font-size: 14px;
      flex-direction: row !important;
      margin-top: 11px;
    `}
`;

const BannerText = styled.div`
  ${props =>
    props.desktop &&
    css`
      @media (max-width: 480px) {
        display: none;
      }
    `}

  a {
    color: inherit;
    width: 100%;
  }

  ${props =>
    props.mobile &&
    css`
      background-color: #f97f47;
      display: none;
      height: 44px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: Platform-Medium;
      font-size: 13px;
      letter-spacing: 0.7px;
      color: #ffffff;
      margin-top: 14px;
      cursor: pointer;
      @media (max-width: 480px) {
        display: flex;
      }
    `}
`;

export default ResubscribeBanner;
