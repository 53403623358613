import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import "styled-components/macro";
import { Image, H3 as NewH3, H6 as NewH6, H2 } from "@team-yumi/ui";

import { blackberry, BlueButton, H6, blueberry } from "../../styledcomponents";
import { H3 } from "../../styledcomponents/typefaces";
import useReload from "../../hooks/useReload";

const Salare = "_EduardoJordan/Salare.jpeg";
const SalareLogo =
  "https://email.helloyumi.com/assets/_EduardoJordan/SalareLogo.png";

const ByChloe = "_ChefSeries/ByBy.png";
const JuliaSher = "_ChefSeries/Julia.jpeg";
const JonNVinny = "_ChefSeries/Jonny.jpeg";
const Cloud = "https://email.helloyumi.com/assets/chef-series/Cloud.svg";
const ChloeLogo =
  "https://email.helloyumi.com/assets/_ChefSeries/byebyeChloe.png";
const JuliaLogo =
  "https://email.helloyumi.com/assets/_ChefSeries/SaladForPres.png";
const JonLogo = "https://email.helloyumi.com/assets/_ChefSeries/JonnyJon.png";

const Parachute = "_ChefSeries/Parachute.png";
const ParachuteLogo =
  "https://email.helloyumi.com/assets/_BeverlyKim/logo_new.gif";

const installments = [
  {
    image: Parachute,
    title: "Yumi x Parachute",
    logoImage: ParachuteLogo,
    quarter: "SPRING 2020",
    link: "/parachute",
    color: blackberry,
    width: "80px",
    description:
      "This season in celebration of our Chicago launch, we’re partnering with our friend(+ Yumi mom of 3) Beverly Kim of the Michelin star restaurant Parachute, and newly opened Wherewithall, in Avondale, Chicago. This box, inspired by traditional Korean recipes, will introduce new superfoods to your babe’s palette.",
  },
  {
    image: Salare,
    title: "Yumi x Salare",
    logoImage: SalareLogo,
    quarter: "FALL 2019",
    link: "/salare",
    color: "#B5C7AB",
    description:
      "We’ve teamed up with Eduoardo Jordan, Salare owner, chef, and father. Jordan, known for his his use of unusual grains and eclectic take on southern cuisine, is introducing a host of new superfoods to your babe's palette including, sumac, ginger and sorghum berry.",
  },
  {
    image: ByChloe,
    title: "Yumi x By Chloe",
    logoImage: ChloeLogo,
    quarter: "SUMMER 2019",
    link: "/by-chloe",
    color: "black",
    description:
      "Embody and celebrate all things plant based, make plant based eating fun, inviting, they're a go to destination in New York and LA. Catalyst for spreading the word about plant based goodness.",
  },
  {
    image: JuliaSher,
    title: "Baby food for President",
    link: "/julia-sherman",
    logoImage: JuliaLogo,
    quarter: "SPRING 2019",

    color: blackberry,
    description:
      "We have a major crush on Julia Sherman, the artist and mastermind behind Salad for President. Through her vivid site and her book by the same name, Julia has merged the world of art with food and single-handedly changed the reputation of the oft-overlooked salad.",
  },
  {
    image: JonNVinny,
    title: "Ready to Ciao Down?",
    logoImage: JonLogo,
    quarter: "WINTER 2018",

    link: "/jon-&-vinnys",
    color: "#1BC057",
    description:
      "Real food is the best food. Chefs know it, and we know it. We want to show you that baby food should not only be real, but it should also be really delicious.",
  },
];

export const ChefSeries = () => {
  useReload()
  return (
    <Section>
      <Inner>
        <H2
          css={css`
          padding-bottom: 30px;
        `}
        >
          Chef Series
        </H2>
        <InstallmentColumn>
          {installments.map((installment) => (
            <ChefBox
              image={installment.image}
              title={installment.title}
              logoImage={installment.logoImage}
              quarter={installment.quarter}
              link={installment.link}
              description={installment.description}
              color={installment.color}
              width={_.get(installment, "width") || "240px"}
            />
            ))}
        </InstallmentColumn>
      </Inner>
      <FutureCollabsContainer>
        <CloudRow>
          <img
            src={Cloud}
            alt="cloud"
            style={{
                  width: "60px",
                  marginTop: "40px",
                }}
          />
          <H3
            style={{
                  opacity: "none",
                  padding: "20px",
                  order: "0",
                  color: blackberry,
                }}
          >
            Get the Scoop on Future Collabs
          </H3>
          <img
            src={Cloud}
            alt="cloud"
            style={{
                  width: "80px",
                  marginTop: "-70px",
                }}
          />
        </CloudRow>
        <CloudForm>
          <iframe
            title="klaviyo email"
            src="https://email.helloyumi.com/assets/chef-series/julia-sherman/klaviyo.html"
            css="border: none; width:100%; padding: 0; margin-bottom: 0; height: 90px; z-index:10;"
          />
        </CloudForm>
      </FutureCollabsContainer>
    </Section>
  )
};

const ChefBox = ({
  image,
  title,
  quarter,
  logoImage,
  link,
  description,
  color,
  width,
}) => (
  <BoxContainer>
    <Image
      css="width:450px; min-height:415px; max-height:415px; object-fit:cover; @media(max-width:1000px){width:347px; min-height:347px; max-height:347px;}"
      src={image}
      alt="title"
    />
    <Left>
      <img
        src={logoImage}
        alt="logo"
        css={css`
          width: ${width};
          @media (max-width: 500px) {
            display: none;
          }
        `}
      />
      <TextSide>
        <NewH3
          css={css`
            padding-top: 15px;
            font-size: 30pt;
            padding-bottom: 0px;
            margin-bottom: 10px;
            color: ${color};
            @media (max-width: 1000px) {
              font-size: 16pt;
              padding-top: 22px;
            }
          `}
        >
          {title}
        </NewH3>
        <H6
          css={css`
            color: ${blueberry};
            opacity: 0.8;
            font-size: 11pt;
            padding-top: 12px;

            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          {quarter}
        </H6>
        <NewH6
          mb="5px"
          mt="5px"
          css="width:480px; padding-top:10px; font-size:15pt; line-height:1.5em; @media(max-width:1000px){font-size:12pt; width:280px;}"
        >
          {description}
        </NewH6>
        <Link css="text-decoration:none; justify-self:flex-end;" to={link}>
          <LearnMoreBlueButton
            css={css`
              background: ${color};
            `}
            value="LEARN MORE"
          />
        </Link>
      </TextSide>
    </Left>
  </BoxContainer>
);

const TextSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 330px;
  width: 100%;

  @media (max-width: 500px) {
    min-height: 220px;
  }
`;

const CloudForm = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    width: 300px !important;
  }
`;
const CloudRow = styled.div`
  display: flex;
  width: 800px;
  align-items: center;
  justify-content: center;
  height: 150px;
  @media (max-width: 1000px) {
    width: 400px !important;
  }
`;

const FutureCollabsContainer = styled.div`
  background: #8db9db;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    width: 400px !important;
  }
`;

const Left = styled.div`
  @media (min-width: 1000px) {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 450px;
    width: 553px;
  }

  padding-left: 0px;
`;

const LearnMoreBlueButton = styled(BlueButton)`
  color: white;
  background: ${blackberry};
  margin-top: 15px;
  width: 130px;
  padding: 16px;
  font-size: 10pt;
  margin-bottom: 15px;
`;

const InstallmentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 40px;

  @media (max-width: 1000px) {
    padding-top: 0px;
    flex-direction: row;
    justify-content: center;
  }
`;
const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  object-fit: cover;
  padding-bottom: 120px;

  @media (max-width: 1000px) {
    padding-top: 40px;
    flex-direction: column;
    padding-bottom: 10px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;

  justify-content: center;
  width: unset;

  @media (min-width: 1500px) {
    width: 75%;
  }

  @media (max-width: 1000px) {
    padding-top: 0px;
    align-items: center;
  }
`;
const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: hidden;
`;
