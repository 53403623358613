const SKIP_ORDER = "SKIP_ORDER";
const GET_DETAILS = "GET_DETAILS";
const UNSKIP_ORDER = "UNSKIP_ORDER";
const CLOSE = "CLOSE";
const OPEN_ADD_ADDRESS_MODAL = "OPEN_ADD_ADDRESS_MODAL";
const OPEN_CHANGE_ADDRESS_MODAL = "OPEN_CHANGE_ADDRESS_MODAL";
const SHOW_RECEIPT_MODAL = "SHOW_RECEIPT_MODAL";

export const types = {
  SKIP_ORDER,
  GET_DETAILS,
  UNSKIP_ORDER,
  CLOSE,
  OPEN_ADD_ADDRESS_MODAL,
  OPEN_CHANGE_ADDRESS_MODAL,
  SHOW_RECEIPT_MODAL
};

export const skipOrderAction = id => ({
  type: SKIP_ORDER,
  id
});

export const getDetailsAction = (id, date, planId, order) => ({
  type: GET_DETAILS,
  id,
  date,
  planId,
  order
});

export const unskipOrderAction = id => ({
  type: UNSKIP_ORDER,
  id
});

export const closeModalAction = () => ({
  type: CLOSE
});

export const openAddAddressModalAction = id => ({
  type: OPEN_ADD_ADDRESS_MODAL,
  id
});

export const openChangeAddressModalAction = (id, date) => ({
  type: OPEN_CHANGE_ADDRESS_MODAL,
  id,
  date
});

// export const openReceiptAction = (id, date, planId) => {
//   return {
//     type: SHOW_RECEIPT_MODAL,
//     id,
//     date,
//     planId
//   }
// }
