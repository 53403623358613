import React from "react";
import styled from "styled-components";
import { Subtitle1 } from "../../../styledcomponents";
import { PullQuote } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

export const Testimonials = () => (
  <TestimonialSection>
    <PullQuote
      css="font-size: 30pt; 
      margin-bottom: 1.5rem; 
      @media(max-width:700px){
        font-size:14pt;
      }
        "
    >
      {`“Yumi makes mealtime stress free for me especially on busy days! It’s also guilt free because I know he’s getting the best ingredients!”`}{" "}
    </PullQuote>
    <Subtitle1
      css="margin: 15px; 
      align-self: center; 
      font-size: 15pt;
      @media(max-width:700px){
        font-size:10pt;
      }"
    >
      {" "}
      - Allie Rizzo{" "}
    </Subtitle1>
  </TestimonialSection>
);

const TestimonialSection = styled.div`
  background: white;
  padding: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
