import { request } from "../utils/request";

/**
 * @param   {string}    startDate - Start date of menu
 *
 * @returns {Array}     Items for that week's menu
 */
export const getMenu = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}menus`
  );

export const getSpecificMenu = async date =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}menus?date=${date}`
  );

export const getMenuItem = async id =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}menus/${id}`
  );

export const getSnacks = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}snacks`
  );

export const getSpecialMenusAndSnacks = async (date, birthday, mealPlan) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }special-menus?date=${date}&baby_birthday=${birthday}&meal_plan_id=${mealPlan}&type=all`
  );

export const getReferralMenu = async birthday =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }special-menus/referral?child_birthday=${birthday}`
  );

export const getIngredients = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}ingredients`
  );
