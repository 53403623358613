/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Needed for redux-saga es6 generator support
import "babel-polyfill";

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import FontFaceObserver from "fontfaceobserver";
import "sanitize.css/sanitize.css";
import * as Sentry from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import App from "./containers/App";
import SentryBoundary from "./containers/SentryBoundary";

// Import root app

// Load the favicon and the .htaccess file
// import '!file-loader?name=[name].[ext]!./images/favicon.ico'
// import 'file-loader?name=[name].[ext]!./.htaccess' // eslint-disable-line import/extensions

import { store, persistor, history } from "./redux/store";

// Import i18n messages
import { translationMessages } from "./i18n";

// Import CSS reset and Global Styles
import "./global-styles";

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver("Open Sans", {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add("fontLoaded");
});

// const store = configureStore()
const MOUNT_NODE = document.getElementById("app");

const release = process.env.SENTRY_RELEASE;

// persistor.purge();
const render = messages => {
  Sentry.init({
    dsn: "https://52edfa0bbad74629a34fd37d40692811@sentry.io/1319403",
    integrations: [new RewriteFrames()],
    environment: process.env.NODE_ENV,
    release
  });

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} onBeforeLift={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <SentryBoundary history={history}>
            <App />
          </SentryBoundary>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

const stripe_key = process.env.STRIPE_KEY;
// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"));
  })
    .then(() =>
      Promise.all([
        import("intl/locale-data/jsonp/en.js"),
        import("intl/locale-data/jsonp/de.js")
      ])
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install() // eslint-disable-line global-require
// }
