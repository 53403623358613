import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import {
  Page,
  CancelTitle,
  CancelExplanation,
  CancelLabel,
  CancelForm,
  ContinueCancelButton
} from "../components/styledComponents";
import { completeCancellation, getUser } from "../../../usecases/users";
import { useRequest } from "../hooks/useFetch";

const CancelReason = ({ reason, toggle, checked }) => {
  const id = `cancel${reason.description}`;
  return (
    <div>
      <input id={id} type="checkbox" onChange={toggle} checked={checked} />
      <CancelLabel htmlFor={id}>{reason.description}</CancelLabel> <br />
    </div>
  );
};
const EmailToCancel = () => (
  <Page>
    <CancelTitle> We're sad to see you go</CancelTitle>
    <CancelExplanation>
      <a
        href="mailto:support@helloyumi.com"
        style={{ color: "#000", textDecoration: "none" }}
      >
        Please click here to email{" "}
        <a href="mailto:support@helloyumi.com" style={{ color: "dodgerblue" }}>
          support@helloyumi.com
        </a>{" "}
        to cancel your account.
      </a>
    </CancelExplanation>
  </Page>
);

export const CancelSubscription = ({ user }) => {
  const [selectedReason, setSelectedReason] = useState({});
  const [shouldRedirect, setRedirect] = useState(false);
  const [message, setMessage] = useState(null);
  const [userData, loading, error] = useRequest(() => getUser(user.id), null);
  if (loading) return "Loading...";
  if (error) return "Something went wrong. Try to refresh the page.";

  const onSuccessHandler = () => {
    setMessage("Canceled subscription.");
    setTimeout(() => setRedirect(true), 3000);
  };

  const setLoading = () => {
    setMessage("Working...");
  };

  const setErrorHandler = err => {
    setMessage("Something went wrong.");
    setTimeout(() => setRedirect(true), 1000);
  };

  const handleCancel = async e => {
    e.preventDefault();
    if (!selectedReason.id)
      return setMessage("Please select a cancellation reason");
    setLoading();
    const { description, id } = selectedReason;
    try {
      await completeCancellation(user.id, description, id);
      onSuccessHandler();
    } catch (err) {
      setErrorHandler(err);
    }
  };

  const toggle = r => () => {
    setSelectedReason(
      prevState => (prevState.description === r.description ? "" : r)
    );
  };
  if (userData.billing && userData.billing.billing_state !== "CA")
    return <EmailToCancel />;
  return (
    <Page>
      <CancelTitle> We're sad to see you go</CancelTitle>
      <CancelExplanation>
        While we didn't expect to say goodbye so soon, we're excited too have
        been a part of your child's growth and development through great
        nutrition.
      </CancelExplanation>
      <CancelExplanation>
        Please share the main reason why you're cancelling your subscription
        today.
      </CancelExplanation>
      <CancelForm onSubmit={handleCancel}>
        {[
          { description: "Price", id: 1 },
          { description: "Upcoming travel", id: 21 },
          { description: "Don't like subscription services", id: 41 },
          { description: "Just wanted to try", id: 61 },
          { description: "Baby didn't enjoy food", id: 81 },
          { description: "Baby too young for purees", id: 101 },
          { description: "Baby growing out of purees", id: 121 },
          { description: "Didn't offer the right plan size", id: 141 },
          { description: "Delivery issue", id: 161 },
          { description: "Packaging issue", id: 181 },
          { description: "Product issue", id: 201 }
        ].map(r => (
          <CancelReason
            key={r.id}
            checked={selectedReason.description === r.description}
            reason={r}
            toggle={toggle(r)}
          />
        ))}
        <ContinueCancelButton type="submit">Continue</ContinueCancelButton>
        {message && <Message>{message}</Message>}
      </CancelForm>
      {shouldRedirect && <Redirect to="/profile/settings" />}
    </Page>
  );
};

const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  font-size: 20px;
  margin: 20px;
`;
