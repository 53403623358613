import React from "react";
import styled, { css } from "styled-components";
import { blackberry } from "../../../styledcomponents/colors";
import { Mashable, NYT, goop, SmallJar } from "../assets";
import { HyperLink } from "../../../styledcomponents";
import { H3 } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

export const TalkOfTheTown = ({ landing = false }) => (
  <TalkOfTheTownContainer>
    <H3
      style={{
        color: blackberry,
        fontSize: "27pt"
      }}
      css={css`
        @media (max-width: 1000px) {
          padding-bottom: 40px;
        }
      `}
    >
      Talk of the Town
    </H3>
    <div css="display:flex">
      <div
        style={{
          display: "flex",
          padding: "50px",
          flexWrap: "wrap"
        }}
        css={css`
          @media (max-width: 1000px) {
            justify-content: flex-start;
            padding: 0px !important;
          }
        `}
      >
        <HyperLink
          css="text-align: center;"
          href="https://mashable.com/article/yumi-baby-food-subscription/"
        >
          {" "}
          <img
            style={{ width: "140px" }}
            src={Mashable}
            alt="mash"
            css={css`
              @media (max-width: 1000px) {
                width: 140px !important;
                margin-left: 30px !important;
              }
            `}
          />
        </HyperLink>
        <HyperLink
          css="text-align: center;"
          href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html"
        >
          {" "}
          <img
            style={{ width: "190px", marginLeft: "30px" }}
            css={css`
              @media (max-width: 1000px) {
                padding-top: 33px !important;
              }
            `}
            src={NYT}
            alt="nyt"
          />
        </HyperLink>
        <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
          <img
            style={{
              width: "70px",
              marginLeft: "30px",
              position: "relative"
            }}
            css={css`
              @media (max-width: 1000px) {
                padding-top: 30px !important;
              }
            `}
            src={goop}
            alt="goop"
          />
        </HyperLink>
      </div>
      <img
        src={SmallJar}
        alt="small jar"
        css="width:50px;height:60px;align-self:flex-end;"
      />
    </div>
  </TalkOfTheTownContainer>
);

const TalkOfTheTownContainer = styled.div`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 130px;
  padding-top: 90px;
  padding-bottom: 50px;

  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    margin-top: 50px !important;
  }
`;
