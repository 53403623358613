import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { H1, Body } from "../../../styledcomponents/typefaces";
import {
  OrangeLinkButton,
  SweetPotato,
  Broccoli,
  Soy,
  Rice,
  Oats,
  Blueberry,
  FruitJuice
} from "../assets";
import { yumiOrange, H5, H6, oatCreamy } from "../../../styledcomponents";

const fighters = [
  {
    image: Blueberry,
    title: "Binders",
    customWidth: "65px",
    body:
      "Tomatoes, blueberries, spirulina and garlic bind to heavy metals in the digestive track and remove them."
  },
  {
    image: Broccoli,
    title: "Blockers",
    body:
      "Broccoli, kale and beans are high in calcium and iron, two nutrients that block lead absorption. Vitamin C helps boost absorption of iron, so we recommend serving iron rich foods with foods rich in vitamin C like kiwis, papaya, peppers or tomatoes."
  }
];
const avoid = [
  {
    image: Soy,
    title: "No Soy",
    body:
      "Soy based infant formulas have on average 7x more Cadmium than other formulas."
  },
  {
    image: Rice,
    title: "No Rice",
    body:
      "Rice cereals are common first foods, but brown rice is one of the highest forms of arsenic we come in contact with in food."
  },
  {
    image: FruitJuice,
    title: "No Fruit Juice",
    customWidth: "35px",
    body:
      "A study conducted in 2012 found that nearly 10% of 88 apple and grape juices sampled had worrisome levels of arsenic."
  }
];

export const AGuide = ({ width }) => (
  <KeepContainer>
    <H1
      css={css`
        color: ${yumiOrange};
        font-size: 50px;
        padding-bottom: 70px;

        @media (max-width: 1000px) {
          padding-top: 80px;
          padding-bottom: 30px;
        }
      `}
    >
      A Guide to Our Food{" "}
    </H1>
    <GuideCards>
      <GuideCard first items={fighters} title="We Use Heavy Metal Fighters" />
      <GuideCard items={avoid} title="Foods We Avoid" />
    </GuideCards>
    {width > 1000 && <OrangeLinkButton to="/checkout" value="GET STARTED" />}
  </KeepContainer>
);

const GuideCards = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;
const GuideCard = ({ items, title, first = false }) => (
  <GuideCardContainer first={first}>
    <H5
      css={css`
        color: ${yumiOrange};
        text-align: center;
        padding-bottom: 30px;
      `}
    >
      {title}
    </H5>
    <div css="width:100%; display:flex; justify-content:center;">
      <SpecialHR />
    </div>
    <ItemColumn size={items.length}>
      {items.map((item, i) => <ItemSlice item={item} index={i} />)}
    </ItemColumn>
  </GuideCardContainer>
);

const ItemSlice = ({ item, index }) => (
  <ItemSliceContainer>
    <img
      src={item.image}
      alt="img"
      css={css`
        padding-top: 20px;
        width: ${item.customWidth && item.customWidth};
        @media (max-width: 1000px) {
          width: 56px;
        }
      `}
    />
    <BodySection>
      <H6
        css={css`
          color: ${yumiOrange};
          font-size: 24px;
        `}
      >
        {item.title}
      </H6>
      <Body css="padding-top:10px; font-size:18px; line-height:1.5em;">
        {item.body}
      </Body>
    </BodySection>
  </ItemSliceContainer>
);

const ItemSliceContainer = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  align-items: flex-start;
  padding-top: 10px;
`;
const BodySection = styled.div`
  padding-top: 20px;
`;
const ItemColumn = styled.div`
  display: grid;
  grid-template-rows: ${({ size }) => size && `repeat(${size}, fr)`};
`;
const SpecialHR = styled.div`
  border-bottom: 1px solid ${yumiOrange};
  width: 30%;
  text-align: center;
  margin-bottom: 30px;
`;
const GuideCardContainer = styled.div`
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 537px;
  padding: 50px;
  height: 658px;
  margin-left: ${({ first }) => (first ? "0px" : "40px")};

  @media (max-width: 1000px) {
    background: ${oatCreamy};
    box-shadow: none;
    margin-left: 0px;
    padding: 30px;
  }
`;
const KeepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: Center;
  min-height: 1000px;
  justify-content: center;

  @media (max-width: 1000px) {
    padding-bottom: 40px;
    background: ${oatCreamy};
  }
`;
