import { getAddresses } from "../../usecases/users";

/*
 * Updates address for that week
 */
const getAddressesService = async userId => {
  const addresses = await getAddresses(userId);
  return addresses;
};

export default getAddressesService;
