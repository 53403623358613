import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Page } from "../components/styledComponents";
import cubano from "../images/cubano_patty.png";
import { useRequest } from "../../../hooks/useFetch";
import {
  getUserSnacks,
  getUser,
  subscribeSnacks
} from "../../../usecases/users";
import { placeSnackOrder } from "../../../usecases/orders";
import BillingForm from "../../../styledcomponents/containers/StripeBillingForm";
import { formatDatePretty } from "../../../utils/dates";

export const CheckoutPage = ({ user }) => {
  const [snacksData, loading, req_err] = useRequest(() =>
    getUserSnacks(user.id)
  );
  const [userData, ,] = useRequest(() => getUser(user.id));
  const [planIndex, setPlanIndex] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [error, setError] = useState("");
  const [buttonState, setButtonState] = useState("");
  const placeOrder = () => setOrderPlaced(true);

  if (loading) return "Loading...";
  if (req_err)
    return "An error occurred. Please refresh the page and try again.";

  const { plans, is_subscribed, dates: dateData } = snacksData;
  const dates =
    dateData && dateData.length > 0 ? [...new Set(dateData.sort())] : [];
  const currentPlan = plans[planIndex];
  const price = currentPlan.withDiscount;
  const tax = Math.ceil(0.095 * price * 100) / 100;
  const totalPrice = Math.ceil(100 * (tax + Number(price))) / 100;

  const deliveryDate = dates[dateIndex];

  const resubscribeFormPost = (stripeData, selected_plan) => {
    setButtonState("disabled");
    if (stripeData.error) {
      setButtonState("");
      return setError(
        "It looks like your billing information was entered incorrectly. Re-enter your card number and try again"
      );
    }
    const body = {
      billing_plan: selected_plan.plan,
      timeline: "weekly",
      postcode: stripeData.token.card.address_zip,
      email: userData.email,
      stripe_card_token: stripeData.token.id,
      stripe_id: userData.stripe_id
    };
    return subscribeSnacks(user.id, body)
      .then(() => placeOrder())
      .catch(e => {
        if (e.response.status === 406)
          return setError(
            "It looks like you've already ordered snacks. Don't worry. They'll be on their way soon."
          );
        setButtonState("");
        return setError("Something went wrong with placing your order.");
      });
  };

  const addOnFormPost = selected_plan => {
    setButtonState("disabled");
    const body = {
      delivery_date: deliveryDate,
      snacks: selected_plan.snacks.map(snack => ({
        menu_id: snack.menu_id,
        quantity: snack.quantity,
        meal_id: snack.meal_id
      }))
    };
    return placeSnackOrder(body)
      .then(() => placeOrder())
      .catch(e => {
        if (e.response.status === 406)
          return setError(
            "It looks like you've already ordered snacks. Don't worry. They'll be on their way soon."
          );
        setButtonState("");
        return setError("Something went wrong.");
      });
  };
  return (
    <Page style={{ marginBottom: "200px" }}>
      <TopImage>
        <img src={cubano} alt="cubano jar" width="200px" />
      </TopImage>
      <Content>
        <BoxTitle>Yumi Tot Box </BoxTitle>

        <div>
          <Price
            style={{
              fontSize: "14px",
              textDecoration: "line-through",
              fontWeight: 400
            }}
          >
            ${currentPlan.withoutDiscount}
          </Price>
        </div>
        <Price>${currentPlan.withDiscount}</Price>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          {currentPlan.snacks.map(item => (
            <MenuItem key={item.meal_name}>
              ({currentPlan.plan_boxes}x) {item.meal_name}
            </MenuItem>
          ))}
        </div>
        <PlanForm>
          <hr style={{ borderWidth: "1px", opacity: ".12" }} width="100%" />
          {plans.map((option, index) => {
            const { plan_boxes } = option;
            const checked = planIndex === index;
            return (
              <InputLabel
                htmlFor={plan_boxes}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "340px"
                }}
                key={plan_boxes}
              >
                {plan_boxes} {plan_boxes === 1 ? "box" : "boxes"}
                <SizeRadio
                  type="radio"
                  id={plan_boxes}
                  name={plan_boxes}
                  value={plan_boxes}
                  required
                  checked={checked}
                  onChange={() => setPlanIndex(index)}
                />
              </InputLabel>
            );
          })}
          <hr style={{ borderWidth: "1px", opacity: ".12" }} width="100%" />
        </PlanForm>
        <FullHR />
        <BillingFormContainer>
          {dates.length > 0 ? (
            <OrderSummary>Choose your delivery date</OrderSummary>
          ) : null}
          {dates.length > 0
            ? dates.map((date, index) => {
                const checked = dateIndex === index;
                return (
                  <div>
                    <InputLabel
                      htmlFor={date}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "nowrap",
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "340px"
                      }}
                      key={date}
                    >
                      {formatDatePretty(date)}
                      <SizeRadio
                        type="radio"
                        id={date}
                        name={date}
                        value={date}
                        required
                        checked={checked}
                        onChange={() => setDateIndex(index)}
                      />
                    </InputLabel>
                  </div>
                );
              })
            : null}
          <FullHR />
        </BillingFormContainer>
        <CheckoutForm
          is_subscribed={is_subscribed}
          plan={currentPlan}
          user={user}
          resubscribeFormPost={resubscribeFormPost}
          addOnFormPost={() => addOnFormPost(currentPlan)}
          orderPlaced={orderPlaced}
          error={error}
          disabled={buttonState}
          amount={totalPrice}
          deliveryDate={deliveryDate}
        />
      </Content>
    </Page>
  );
};

const CheckoutForm = ({
  plan,
  is_subscribed,
  user,
  resubscribeFormPost,
  addOnFormPost,
  orderPlaced,
  error,
  disabled,
  amount,
  deliveryDate
}) => {
  if (is_subscribed)
    return (
      <BillingFormContainer>
        <ReceiptAndButtons
          plan={plan}
          orderPlaced={orderPlaced}
          submitForm={addOnFormPost}
          error={error}
          disabled={disabled}
          deliveryDate={deliveryDate}
        />
      </BillingFormContainer>
    );
  return (
    <BillingFormContainer>
      <OrderSummary>Billing Information</OrderSummary>
      <BillingForm
        user={user}
        button={(submitStripe, disable_via_billing_form) => (
          <React.Fragment>
            <FullHR />
            <ReceiptAndButtons
              submitForm={() =>
                submitStripe().then(stripeResponse =>
                  resubscribeFormPost(stripeResponse, plan)
                )
              }
              plan={plan}
              orderPlaced={orderPlaced}
              error={error}
              disabled={
                disabled === "disabled" ? disabled : disable_via_billing_form
              }
              deliveryDate={deliveryDate}
            />
          </React.Fragment>
        )}
        applePayMessage={
          "Apple Pay proccessed successfully. Click the 'Complete Preorder' button to place your order."
        }
        amount={amount}
      />
    </BillingFormContainer>
  );
};

const ReceiptAndButtons = ({
  plan,
  submitForm,
  orderPlaced,
  error,
  disabled,
  deliveryDate
}) => (
  <React.Fragment>
    <Receipt
      size={plan.plan_boxes}
      price={plan.withDiscount}
      deliveryDate={deliveryDate}
    />
    {orderPlaced ? (
      <SuccessBox />
    ) : (
      <React.Fragment>
        <span style={{ color: "red" }}>{error}</span>
        <CompletePreorder
          type="submit"
          value="COMPLETE PREORDER"
          onClick={submitForm}
          disabled={disabled}
        />
        <Link to="/orders">
          <CancelPreorder>CANCEL</CancelPreorder>
        </Link>
      </React.Fragment>
    )}
  </React.Fragment>
);

const Receipt = ({ size, price, deliveryDate }) => {
  const tax = Math.ceil(0.095 * price * 100) / 100;
  const totalPrice = Math.floor(100 * (tax + Number(price))) / 100;
  return (
    <ReceiptContainer>
      <OrderSummary>Order Summary</OrderSummary>
      <ReceiptRow style={{ color: "#334897" }}>
        <RowInput>Estimated Delivery Date</RowInput>
        <RowInput>{formatDatePretty(deliveryDate)}</RowInput>
      </ReceiptRow>
      <hr style={{ borderWidth: "1px", opacity: ".12" }} width="100%" />
      <ReceiptRow>
        <RowInput>
          {size} {size === 1 ? "box" : "boxes"}
        </RowInput>
        <RowInput>${price}</RowInput>
      </ReceiptRow>
      <ReceiptRow>
        <RowInput>Estimated Tax</RowInput>
        <RowInput>${tax.toFixed(2)}</RowInput>
      </ReceiptRow>
      <hr style={{ borderWidth: "1px", opacity: ".12" }} width="100%" />
      <ReceiptRow style={{ fontWeight: "900" }}>
        <RowInput>Total</RowInput>
        <RowInput>${totalPrice.toFixed(2)}</RowInput>
      </ReceiptRow>
    </ReceiptContainer>
  );
};

const SuccessBox = () => (
  <SuccessContainer>
    <OrderSummary style={{ color: "#086d43", lineHeight: "1.15" }}>
      Thank you, your order has been placed.
    </OrderSummary>
    <Link to="/orders" style={{ width: "100%" }}>
      <CancelPreorder style={{ background: "#086d43", color: "white" }}>
        BACK TO ORDERS
      </CancelPreorder>
    </Link>
  </SuccessContainer>
);

const SuccessContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 340px;
  align-items: center;
  text-align: center;
  margin-top: 100px;
`;

const CancelPreorder = styled.button`
  width: 100%;
  text-decoration: none;
  background: #f2f2f2;
  font-family: "Avenir", sans-serif;
  font-size: 15.41px;
  font-weight: 500;
  letter-spacing: 1.25px;
  color: black;
  height: 53px;
  text-transform: uppercase;
  border-radius: 50px;
  cursor: pointer;
`;

const CompletePreorder = styled.input`
  width: 100%;
  background: #334897;
  font-family: "Avenir", sans-serif;
  font-size: 15.41px;
  font-weight: 500;
  letter-spacing: 1.25px;
  color: white;
  height: 53px;
  text-transform: uppercase;
  border-radius: 50px;
  margin-top: 66px;
  margin-bottom: 16px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const BillingFormContainer = styled.section`
  width: 340px;
`;
const ReceiptContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const OrderSummary = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 500;
  font-size: 26.75px;
  line-height: 1;
  margin-bottom: 25px;
`;

const ReceiptRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RowInput = styled.span`
  font-family: Avenir, sans-serif;
  font-size: 15.8px;
  line-height: 3;
`;

const SizeRadio = styled.input`
  background: #334897;
  box-shadow: none;
`;
const InputLabel = styled.label`
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 400;
  font-size: 14.8px;
  line-height: 1;
`;
const PlanForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Price = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15px;
`;
const MenuItem = styled.p`
  line-height: 0.8;
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.6;
  letter-spacing: 0.4px;
  text-align: center;
`;
const BoxTitle = styled.span`
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  font-size: 23px;
  letter-spacing: 0.25px;
  margin-top: 60px;
`;

const TopImage = styled.div`
  background: #cdc3fc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 85px;

  @media (max-width: 1000px) {
    padding-top: 150px;
    padding-bottom: 40px;
  }
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

const FullHR = styled.hr`
  border-width: 1px;
  opacity: 0.12;
  width: 200vw;
  margin-left: -50vw;
  height: 1px;
  background-color: black;
  margin-top: 67px;
  margin-bottom: 67px;
`;
