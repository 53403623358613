import React from "react";
import useReload from "../../hooks/useReload";

const Chicago = () => {
  useReload()
  return (
    <div>

    </div>
  );
};

export default Chicago;
