import React from "react";
import styled, { css } from "styled-components";
import Autocomplete from "react-google-autocomplete";
import _ from "lodash";
import { Label, Body2 } from "./typefaces";
import { InputField } from "./forms";
import { Box } from "./layout";
import "styled-components/macro";

const autoCompleteStyle = {
  lineHeight: "1",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  fontSize: "1em",
  fontFamily: "Freight-Book",
  opacity: "1",
  background: "white",
  paddingLeft: "15px",
  height: "40px",
  marginBottom: "1em"
};

const parseAddressFromGoogleMaps = place => {
  const address = place.address_components;
  const getSection = type => address.find(({ types }) => types.includes(type));
  const street = `${_.get(getSection("street_number"), "long_name", "")} ${
    getSection("route").long_name
  }`;
  const postcode = getSection("postal_code").long_name.substring(0, 5);
  const state = getSection("administrative_area_level_1").short_name;
  const city =
    getSection("locality") !== undefined
      ? getSection("locality").long_name
      : getSection("sublocality").long_name;

  return { street, state, city, postcode };
};

export const AddressForm = ({
  formState,
  setField,
  setForm,
  shelf = false,
  modal = false
}) => {
  const mapGooglePlaceToState = place =>
    setForm(parseAddressFromGoogleMaps(place));

  return (
    <Box
      css={css`
        width: ${modal ? "55%" : "100%"};
        max-width: ${modal ? "55%" : "100%"};
        flex-shrink: 0;
        align-items: flex-start;

        @media (max-width: 500px) {
          width: 100%;
          max-width: 100%;
        }
      `}
    >
      {!shelf && (
        <Label
          style={{
            marginBottom: "0px",
            fontFamily: '"Ginger",avenir,sans-serif'
          }}
        >
          {" "}
          Address
        </Label>
      )}
      <Autocomplete
        types={["address"]}
        componentRestrictions={{ country: "us" }}
        style={autoCompleteStyle}
        css={css`
          -webkit-appearance: none;
          border-radius: 0;
          font-size: ${shelf && "12pt !important;"};
          height: ${shelf && "30px !important;"};
          color: ${shelf && "black !important;"};
          margin-bottom: ${shelf && "5px !important"};
          font-family: "Freight-Book";
          @media (max-width: 800px) {
            font-size: ${shelf && "12pt"};
          }
        `}
        placeholder={shelf && "Address"}
        onPlaceSelected={mapGooglePlaceToState}
        value={formState.street}
        onChange={setField("street")}
        required
        id="inputStreet"
      />
      <InputField
        value={formState.street2}
        placeholder={shelf && "Address 2"}
        onChange={setField("street2")}
        label="Apartment"
        id="inputStreet2"
        shelf={shelf}
        style={{ marginBottom: shelf && "10px" }}
        required={false}
      />
      <InputField
        value={formState.city}
        onChange={setField("city")}
        label="City"
        placeholder={shelf && "City"}
        id="inputCity"
        shelf={shelf}
        required
        style={{ marginBottom: shelf && "5px" }}
      />
      <Box row nowrap css="justify-content: space-between; width: 100%">
        <Label
          htmlFor="statePicker"
          style={{ flex: "0 1 calc((100% / 2) - 10px)" }}
        >
          {!shelf && (
            <Body2
              style={{
                marginBottom: "5px",
                fontFamily: '"Ginger",avenir,sans-serif',
                fontSize: "13px",
                lineHeight: "1.5"
              }}
            >
              {" "}
              State
            </Body2>
          )}
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              height: shelf ? "30px" : "40px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: "8px"
            }}
          >
            <select
              value={formState.state}
              onChange={setField("state")}
              id="statePicker"
              name="statePicker"
              css={css`
                font-size: ${shelf ? "12px" : "16px"};
                color: ${shelf && "black"};
                font-family: Ginger;

                @media (max-width: 800px) {
                  font-size: ${shelf && "16px"};
                }
              `}
            >
              <option value="" disabled hidden>
                Select a state
              </option>
              {stateAbbreviations.map(state => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
        </Label>
        <InputField
          value={formState.postcode}
          pattern={"^[0-9]{5}(?:-[0-9]{4})?$"}
          errorMessage="Enter a valid zipcode."
          id="inputZip"
          label="Postcode"
          shelf={shelf}
          placeholder={shelf && "Postal code"}
          onChange={setField("postcode")}
          style={{
            flex: "0 1 calc((100% / 2) - 10px)",
            color: shelf && "black"
          }}
          required
        />
      </Box>
    </Box>
  );
};

const stateAbbreviations = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

export default AddressForm;
