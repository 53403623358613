import React, {useEffect, useState} from "react";
import { SuccessIndicator } from "../components/SuccessIndicator";
import {
  CardContainer,
  Page,
  LogoutButton
} from "../components/styledComponents";
import { useRequest } from "../hooks/useFetch";

import { getUser } from "../../../usecases/users";

import {
  ChildProfile,
  YourProfile,
  BillingInformation,
  Password,
  BillingCard,
  PlanCard,
  CancelCard,
  DeliveryAddress
} from "../SettingsCards";
import ResubscribeBanner from "../components/ResubscribeBanner";

export const Settings = ({ user, logout }) => {
  const [activeModal, setActiveModal] = useState("");
  const [successField, setSuccess] = useState("");
  const successFunction = card => () => setSuccess(card);
  const modalFunction = card => e => {
    if (e) e.stopPropagation();
    setActiveModal(card);
  };

  useEffect(() => {
    window.replace('/dashboard/profile')
  }, [])

  const [userData, isLoading, isError] = useRequest(
    () => getUser(user.id),
    activeModal
  );

  if (isError) return "Error!";
  if (isLoading) return "Loading...";
  const child = userData.children[0];
  const { name, email, id, addresses } = userData;
  const isSubscribed = ["", undefined].includes(userData.subscription.ends_at);
  const isSnackPlan = !!(
    userData.subscription.stripe_plan &&
    userData.subscription.stripe_plan.includes("snacks")
  );

  return (
    <Page>
      {!isSubscribed && <ResubscribeBanner />}
      <SuccessIndicator
        updatedField={successField}
        close={successFunction("")}
      />
      <CardContainer>
        <ChildProfile
          success={successFunction("child's profile")}
          openModal={modalFunction("Child's Profile")}
          activeModal={activeModal}
          close={modalFunction("")}
          user={user}
          child={child}
        />
        <YourProfile
          success={successFunction("your profile")}
          openModal={modalFunction("Your Profile")}
          activeModal={activeModal}
          close={modalFunction("")}
          user={{ name, email, id }}
        />

        <Password
          success={successFunction("password")}
          activeModal={activeModal}
          openModal={modalFunction("Password")}
          close={modalFunction("")}
        />
        {isSubscribed && !isSnackPlan ? <PlanCard /> : null}
        <DeliveryAddress
          success={successFunction("delivery address")}
          activeModal={activeModal}
          openAddModal={modalFunction("AddAddress")}
          openModal={modalFunction("DeliveryAddress")}
          close={modalFunction("")}
          user={{ id, addresses, name }}
        />

        {isSubscribed && (
          <BillingInformation
            user={user}
            success={successFunction("billing information")}
            close={modalFunction("")}
            activeModal={activeModal}
            openModal={modalFunction("Billing Information")}
          />
        )}
        <BillingCard />
        {isSubscribed && <CancelCard />}
      </CardContainer>
      <LogoutButton logout={logout} />
    </Page>
  );
};

export default Settings;
