import { useEffect, useRef } from "react";
import _ from "lodash";
import queryString from "query-string";
import { useRequest, useEffectOnChange } from "../../hooks";
import { getSpecificMenu } from "../../usecases/menus";
import { formatDate, getNextDeliveryDate } from "../../utils/dates";
import { updateMenuAction } from "./editMenuReducer";

export const updateLocalStorage = function useUpdateLocalStorageOnStateChange({
  formState,
  maxStep,
  planState,
  cart,
  couponState,
  referralState,
  zone
}) {
  useEffect(
    () => {
      window.localStorage.newCheckout2 = JSON.stringify({
        formState,
        maxStep,
        planState,
        cart,
        couponState,
        referralState,
        zone
      });
    },
    [formState, maxStep, planState, cart, couponState, referralState, zone]
  );
};

export const continueOnFullCart = function useContinueOnFullCart({
  cart,
  continueStep
}) {
  const totalItemsRef = useRef();
  useEffect(
    () => {
      const previousItemsCount = totalItemsRef.current;
      const updatedItemsCount = computeTotalItems(cart);
      // the totalItems.current here is a reference to the previous state.
      // this is basically saying, "if the cart JUST became full, set display sidebar true"

      if (
        previousItemsCount !== cart.capacity &&
        updatedItemsCount === cart.capacity
      )
        continueStep();
      totalItemsRef.current = updatedItemsCount;
    },
    [cart]
  );
};

export const fetchMenu = function useFetchMenu() {
  const [menu, menuLoading, menuError] = useRequest(
    () => getSpecificMenu(formatDate(getNextDeliveryDate())),
    []
  );

  return { menu, menuLoading, menuError };
};

export const updateCartMenuAfterFetch = function useUpdateCartMenuAfterFetch({
  dispatch,
  menu
}) {
  useEffect(
    () => {
      if (_.isEmpty(menu)) return;
      dispatch(updateMenuAction(menu));
    },
    [menu]
  );
};

export const useClearCartAfterBirthdayChange = ({
  formState,
  dispatch,
  removeAllAction
}) => {
  useEffectOnChange(
    () => {
      dispatch(removeAllAction());
    },
    [formState.birthday]
  );
};

export const getGiftCodeFromQueryString = function useGetGiftCodeFromQueryString({
  giftState,
  location,
  setGiftCodeState
}) {
  useEffect(() => {
    const giftString = _.get(
      queryString.parse(location.search),
      "gift_code",
      _.get(giftState, "giftString", "")
    );
    setGiftCodeState({ giftString });
  }, []);
};
export const getReferralFromQueryString = function useGetReferralFromQueryString({
  referralState,
  location,
  setReferralState
}) {
  useEffect(() => {
    const referralString = _.get(
      queryString.parse(location.search),
      "referral_code",
      _.get(referralState, "referralString", "")
    );
    setReferralState({ referralString });
  }, []);
};

export const getCouponFromQueryString = function useGetCouponFromQueryString({
  couponState,
  location,
  setCouponState
}) {
  useEffect(() => {
    const couponString = _.get(
      queryString.parse(location.search),
      "coupon",
      _.get(couponState, "couponString", "")
    );
    setCouponState({ couponString });
  }, []);
};

const computeTotalItems = state =>
  state.items.reduce((total, item) => total + item.quantity, 0);
