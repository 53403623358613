import { updateOrderAddress } from "../../usecases/orders";

/*
 * Updates address for that week
 */
const setDefaultAddressService = async (orderId, address) => {
  const defaultAddress = address;
  defaultAddress.default = 1;

  const addressUpdate = await updateOrderAddress(orderId, defaultAddress);
  return addressUpdate;
};

export default setDefaultAddressService;
