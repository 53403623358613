import React, { useState, useEffect } from "react";
import spacetime from "spacetime";
import {Button, H2} from "@team-yumi/ui";
import _ from "lodash";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import * as Sentry from "@sentry/browser";

import {
  InputField,
  BasicInput,
  Form,
  BigButton,
  H4,
  Body2,
  Box
} from "../../../styledcomponents";
import { useRedirect, useEffectOnChange } from "../../../hooks";
import "styled-components/macro";
import { postLead } from "../../../usecases/checkout";

export const StepOne = ({
  formState,
  setField,
  setMaxStep,
  setZone,
  setPlanState,
  trackEvent
}) => {
  const [routeToStepTwo, shouldRedirect] = useRedirect("/checkout_new/2");
  const [formError, setFormError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [zipError, setZipError] = useState("");
  const [buttonText, setbuttonText] = useState("");

  const isValidEmailString = email => {
    setEmailError("");
    const emailDomain = _.last(email.split("@"));
    const emailTLD = _.last(emailDomain.split("."));
    if (
      !emailDomain.includes(".") ||
      ["con", "cong", "bong", "sexy", "meme"].includes(emailTLD) ||
      ["gmsil", "gamil"].includes(_.first(emailDomain.split(".")))
    ) {
      setEmailError("This email address isn't supported.");
      return false;
    }
    return true;
  };

  const isValidBabyBirthday = birthday => {
    setBirthdayError("");
    const birthYear = _.first(birthday.split("-"));
    if (
      birthYear < 1997 ||
      spacetime(new Date()).isBefore(spacetime(birthday))
    ) {
      setBirthdayError("It looks like this birthday is wrong.");
      return false;
    }
    return true;
  };

  const isValidZip = zip => {
    setZipError("");
    // eslint-disable-next-line prefer-destructuring
    const length = zip.split("").length;
    if (length < 5 && zip[0] !== 0) {
      setZipError("It looks like your zipcode is incorrect.");
      return false;
    }
    return true;
  };

  const birthdayMask = input => {
    // Determine mask based on month-
    if (input.length === 1 && Number(input) > 1) {
      return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    }

    if (input.length === 2 && input[1] === "/") {
      return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    }

    if (input.length === 5 && input[4] === "/") {
      return ["0", /\d/, "/", "0", /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    }

    // Determine mask based on day
    if (input.length === 4 && Number(input[3]) > 3) {
      return [/\d/, /\d/, "/", "0", /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    }

    // Determine mask based on day
    if (input.length === 9 && Number(input[0]) < 10 && Number(input[0]) !== 1) {
      return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
    }

    // Determine mask based on day
    if (input.length === 8) {
      return [/\d/, /\d/, "/", /\d/, /\d/, "/", "2", "0", /\d/, /\d/];
    }
    // Default
    return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  };

  useEffectOnChange(
    () => {
      shouldRedirect(false);
      setMaxStep(1);
    },
    [formState]
  );

  useEffect(() => {
    setbuttonText("Next");
    trackEvent("CheckoutStepOneLoaded", { step: "check" });
  }, []);

  const postLeadAndGetZone = () => {
    setbuttonText("Loading...");
    postLead(formState.email, formState.zipcode)
      .then(res => res)
      .then(res => {
        const { diff } = spacetime(new Date()).since(
          formState.birthday,
          "month"
        );
        const month = diff.months + 12 * diff.years;

        let selectedIndex = 0;

        if (month > 6) selectedIndex = 1;
        if (month >= 9) selectedIndex = 2;

        setPlanState({ selectedPlanIndex: selectedIndex });

        setZone(res.zone.zone);
        setbuttonText("Next");
        setMaxStep(2);
        shouldRedirect(true);
        trackEvent("CheckoutStepOneCompleted");
        Sentry.configureScope(scope => {
          scope.setUser({ email: formState.email });
        });
      })
      .catch(() => {
        setFormError("Unfortunately, we can't deliver to your area just yet.");
        setbuttonText("Next");
      });
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={() => {
          setFormError("");
          if (
            !isValidEmailString(formState.email) ||
            !isValidBabyBirthday(formState.birthday) ||
            !isValidZip(formState.zipcode)
          )
            return;
          postLeadAndGetZone();
        }}
        style={{
          width: "585px",
          maxWidth: "100%",
          background: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1em"
        }}
        css="padding: 70px; @media(max-width: 321px) {  padding-top: 35px; padding-bottom: 120px; }"
        noValidate
      >
        <H2>
          Let's build a plan for your babe.
        </H2>
        <Box
          style={{
            marginTop: "1.5em",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <InputField
            type="email"
            onChange={setField("email")}
            errorMessage="Please enter a valid email"
            placeholder="Email"
            error={emailError}
            value={formState.email}
            id="checkoutEmailField"
            label="Email"
            style={{ width: "360px", maxWidth: "100%" }}
          />
          <MaskedInput
            mask={birthdayMask}
            guide={false}
            onChange={setField("birthday")}
            placeholder="mm/dd/yyyy"
            value={formState.birthday}
            label="Your baby's birthday"
            id="checkoutBirthdayField"
            error={birthdayError}
            required
            style={{
              width: "360px",
              maxWidth: "100%",
              fontFamily: "Ginger"
            }}
            type="text"
            pattern="\d*"
            render={(ref, props) => <BasicInput basicRef={ref} {...props} />}
          />{" "}
          <InputField
            type="text"
            onChange={setField("zipcode")}
            pattern={"^[0-9]{5}(?:-[0-9]{4})?$"}
            errorMessage="Please enter a valid zipcode"
            placeholder="Zipcode"
            label="Zipcode"
            pattern="\d*"
            value={formState.zipcode}
            error={zipError}
            id="checkoutZipField"
            maxLength="9"
            required
            style={{ width: "360px", maxWidth: "100%" }}
          />
        </Box>
        <br />
        <div
          css="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          @media (max-width: 500px) {
            background-color: rgb(250, 245, 237);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            left: 0;
            bottom: 0;
            width: 100%;
            box-shadow: 0 2px 20px 0 rgb(233, 233, 233);
            position: fixed;
          }"
        >
          <span
            style={{ color: "#EE5A37" }}
            css="margin-bottom: 1em; @media(max-width: 500px) {margin-bottom: 0; width: 80%;}"
          >
            {formError}
          </span>
          <Button
            type="tertiary"
            htmlType="submit"
          >NEXT
          </Button>
          <Box css="margin-top: 1em; @media (max-width: 500px) { width: 90%; margin-top: 8px; }">
            <Body2 css="font-family: Ginger; font-size: 14px;line-height: 20px">
              By clicking above, you agree to our{" "}
              <Link to="/terms">Terms of Use</Link> and consent to our{" "}
              <Link to="/privacy">Privacy Policy</Link>
            </Body2>
          </Box>
        </div>
        <br />
      </Form>
      {routeToStepTwo()}
    </React.Fragment>
  );
};
