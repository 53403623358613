/*
 * FeaturePage
 *
 * List all the features
 */
import React from "react";

class CleanPage extends React.Component {
  componentDidMount() {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
        console.log("clean");
      });
      window.location.reload(true);
      window.location.href = "/";
    }
  }

  render() {
    return <div />;
  }
}

export default CleanPage;
