import React, { useMemo, useEffect } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import Autocomplete from "react-google-autocomplete";
import {
  injectStripe,
  CardElement,
  StripeProvider,
  Elements
} from "react-stripe-elements";

import StripeApplePay from "./StripeApplePay";
import { InputField, Box, Label, Body2 } from "../index";

import { useMergeState, useFormState } from "../../hooks";

const autoCompleteStyle = {
  lineHeight: "1",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  width: "100%",
  fontSize: "16px",
  fontFamily: "Avenir",
  opacity: "1",
  background: "white",
  paddingLeft: "15px",
  borderRadius: "0px",
  height: "40px"
};

const parseAddressFromGoogleMaps = place => {
  const address = place.address_components;
  const getSection = type => address.find(({ types }) => types.includes(type));
  const street = `${_.get(getSection("street_number"), "long_name", "")} ${
    getSection("route").long_name
  }`;
  const postcode = getSection("postal_code").long_name.substring(0, 5);
  const state = getSection("administrative_area_level_1").short_name;
  const city =
    getSection("locality") !== undefined
      ? getSection("locality").long_name
      : getSection("sublocality").long_name;

  return { street, state, city, postcode };
};

const BillingForm = injectStripe(
  ({
    stripe,
    button,
    shelf = false,
    applePayMessage = null,
    amount,
    hideAddress = false,
    setStripeSubmit
  }) => {
    const [form, setField, setForm] = useFormState({
      address_line1: "",
      address_line2: "",
      address_state: "",
      address_city: "",
      address_zip: "",
      name: ""
    });

    const [state, setState] = useMergeState({
      error: "",
      paymentRequestToken: null,
      disabled: "disabled",
      shouldHideOldCheckout: false
    });

    const {
      paymentRequestToken,
      disabled,
      shouldHideOldCheckout,
      error
    } = state;

    const mapGooglePlaceToState = place => {
      setState({ error: "" });
      setForm(parseAddressFromGoogleMaps(place));
    };

    const getRequestStripeToken = formState => async e => {
      setState({ disabled: "" });
      if (e) e.preventDefault();
      if (paymentRequestToken) return paymentRequestToken;
      return stripe
        .createToken(formState)
        .then(stripeResponse => {
          if (stripeResponse.error) throw Error();
          return stripeResponse;
        })
        .catch(err => {
          setState({
            error: "Something went wrong. Recheck your form and try again."
          });
          throw err;
        });
    };

    const requestStripeToken = useMemo(() => getRequestStripeToken(form), [
      form,
      paymentRequestToken
    ]);

    useEffect(
      () => {
        if (setStripeSubmit) setStripeSubmit(() => getRequestStripeToken(form));
      },
      [form, paymentRequestToken, paymentRequestToken]
    );

    return (
      <React.Fragment>
        <BillingFormContainer onSubmit={requestStripeToken}>
          {shouldHideOldCheckout ? (
            <span style={{ fontFamily: "Avenir", fontSize: "16px" }}>
              {applePayMessage}
            </span>
          ) : (
            <React.Fragment>
              <StripeApplePay
                shelf={shelf}
                setStripeData={stripeResponse =>
                  setState({
                    paymentRequestToken: stripeResponse,
                    disabled: "",
                    shouldHideOldCheckout: true
                  })
                }
                amount={amount}
                applePayMessage={applePayMessage}
              />
              {!hideAddress && (
                <Box style={{ width: "100%", marginBottom: "1em" }}>
                  <Label> Address </Label>
                  <Autocomplete
                    types={["address"]}
                    componentRestrictions={{ country: "us" }}
                    style={autoCompleteStyle}
                    css={css`
                      height: ${shelf && "30px"};
                    `}
                    placeholder="Address"
                    onPlaceSelected={mapGooglePlaceToState}
                    value={form.address_line1}
                    onChange={setField("address_line1")}
                    required
                  />

                  <InputField
                    value={form.address_line2}
                    onChange={setField("address_line2")}
                    placeholder="Apt Number"
                    label="Apartment"
                    id="inputStreet2"
                  />
                  <InputField
                    value={form.address_city}
                    onChange={setField("address_city")}
                    placeholder="City"
                    id="inputCity"
                    required
                  />
                  <Box
                    row
                    nowrap
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <Label
                      htmlFor="statePicker"
                      style={{ flex: "0 1 calc((100% / 2) - 10px)" }}
                    >
                      <Body2 style={{ marginBottom: "5px" }}>State</Body2>
                      <div
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          height: "40px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingLeft: "8px",
                          background: "white"
                        }}
                      >
                        <select
                          value={form.address_state}
                          onChange={setField("address_state")}
                          id="statePicker"
                          name="statePicker"
                          style={{
                            fontSize: "1em",
                            fontFamily: "Avenir",
                            background: "white"
                          }}
                        >
                          <option value="" disabled hidden>
                            Select a state
                          </option>
                          {stateAbbreviations.map(_state => (
                            <option value={_state} key={_state}>
                              {_state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Label>
                    <InputField
                      value={form.address_zip}
                      pattern={"^[0-9]{5}(?:-[0-9]{4})?$"}
                      errorMessage="Enter a valid zipcode."
                      id="inputZip"
                      placeholder="Postcode"
                      onChange={setField("address_zip")}
                      style={{ flex: "0 1 calc((100% / 2) - 10px)" }}
                    />
                  </Box>
                </Box>
              )}

              <PaymentContainer>
                <CardElement
                  style={{
                    base: {
                      fontFamily: "Avenir, sans-serif",
                      fontSize: shelf ? "12px" : "16px"
                    }
                  }}
                  onChange={() => setState({ disabled: "" })}
                />
              </PaymentContainer>
            </React.Fragment>
          )}
          {error && (
            <span
              style={{
                color: "red",
                fontFamily: "Avenir",
                fontSize: shelf && "10pt"
              }}
            >
              {error}
            </span>
          )}
        </BillingFormContainer>
        {button && button(requestStripeToken, disabled)}
      </React.Fragment>
    );
  }
);

const BillingFormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const PaymentContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 16px 10px;
  background: white;
`;

export default props => (
  <StripeProvider apiKey={process.env.STRIPE_KEY}>
    <Elements>
      <BillingForm {...props} />
    </Elements>
  </StripeProvider>
);

const stateAbbreviations = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];
