import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";

import _ from "lodash";
import { Image } from "@team-yumi/ui";
import { Box, Body2, blackberry, blueberry } from "../index";
import { Label, ProductName } from "../typefaces";

import { Modal } from "../Modal";
import { NutritionLabel } from "../NutritionLabel/index";
import { getNewImageUrlFromMealId } from "../../utils/images";

const MenuModal = ({
  item,
  isOpen,
  bites = false,
  backUp = false,
  hasCerts = true,
  setIsOpen,
  getImageUrlFromMealId,
  special,
  getBadges,
  color_id,
}) => {
  const headerBG = () => {
    if (bites) {
      return "#C6C3F5";
    }
    if ([17, 18, 19, 20].includes(item.id)) {
      return "#c4bbf2";
    }

    if (color_id === 8) {
      return "#F9EEE5";
    }

    return `url(${getImageBgUrlFromQuadId(color_id)})`;
  };

  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      boxStyle={{ alignItems: "flex-start" }}
      card
      mobile={{
        content: {
          height: "100%",
          width: "100%",
          left: 0,
          top: "23px",
          padding: 0,
          borderRadius: "20px",
        },
      }}
      style={{ padding: "0" }}
    >
      <ImageContainer
        special={[23, 25].includes(item.id || item.menu_id)}
        item={item}
        bg={headerBG()}
        color_id={color_id}
        name="image container"
        css={css`
          border-radius: 8px;
          background: ${(props) => props.bg};
        `}
      >
        <Body2
          css={css`
            padding: 11px 14px;
            position: absolute;
            top: 24px;
            left: 24px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 50%;
            height: 40px;
            width: 40px;
          `}
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(false)}
        >
          <img
            src="https://email.helloyumi.com/assets/back-arrow.svg"
            alt="Close"
          />
        </Body2>
        <Image
          w={300}
          special={[23, 25].includes(item.id || item.menu_id)}
          src={getNewImageUrlFromMealId(item.meal_id)}
          alt={`${item.name} meal`}
          css={css`
            width: ${backUp ? "40px !important" : "130px !important"};
          `}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://email.helloyumi.com/assets/0${
              item.meal_id
            }.png`;
          }}
        />
      </ImageContainer>
      <Box
        css={css`
          padding: 8px 32px;
          justify-content: flex-start;
          align-items: flex-start;
        `}
      >
        {" "}
        <Label
          color={blueberry}
          css={css`
            width: 100%;
          `}
        >
          Stage {item.stage}
        </Label>
        <ProductName
          special={special}
          color={blackberry}
          css={css`
            width: 100%;
            height: 48px;

            @media (max-width: 900px) {
              line-height: 17px;
              height: ${(props) => (props.special ? "40px" : "34px")};
            }

            @media (max-width: 500px) {
              font-size: 22px;
              line-height: 24px;
            }
          `}
        >
          {item.name || item.meal_name}
        </ProductName>
        <Box
          css={css`
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin: 0 0 16px 0;
          `}
        >
          {hasCerts && getBadges(bites)}
        </Box>
        <Box
          css={css`
                font-family: "Freight-Book";
                font-style: normal;
                font-weight: normal
                font-size: 15px;
                line-height: 150%;
                letter-spacing: 0.01em;
              `}
        >
          {item.description}
        </Box>
      </Box>
      <Box
        css={css`
          background: url("https://email.helloyumi.com/assets/menu-pttrn.png");
          width: 100%;
          height: 38px;
          background-size: contain;
          margin: 2em 0;
        `}
      />
      <Box
        full
        css={css`
          margin-bottom: 2em;
        `}
      >
        <RowItemDetails>
          <summary>
            Full Ingredients
            <img
              src="https://email.helloyumi.com/assets/TotBoxDetails_Add.svg"
              alt="add"
            />
          </summary>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              overflow: scroll;
              padding-bottom: 1em;
              @media (max-width: 800px) {
                padding: unset;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            `}
          >
            {item.ingredients &&
              _.uniq(item.ingredients.split(",")).map((ing) => {
                const ingredient = ing
                  .trim()
                  .toLowerCase()
                  .split(" ")
                  .join("-");

                return (
                  <Box
                    css={css`
                      flex-direction: column;
                      font-family: "Platform-Medium";
                      font-size: 13px;
                    `}
                    key={item.meal_id + ingredient}
                  >
                    <div
                      css={css`
                        display: inline-block;
                        height: 120px;
                        width: 120px;
                        background-size: cover;
                        background-position: center;
                        margin-right: 10px;
                        border-radius: 50%;
                        background-repeat: no-repeat;
                        border: 4px solid #f9eee5;
                        margin-bottom: 10px;

                        background-image: url(${`https://email.helloyumi.com/assets/_ingredients/${ingredient}.png`});
                      `}
                    />
                    {ing}
                  </Box>
                );
              })}
          </div>
        </RowItemDetails>

        <RowItemDetails>
          <summary>
            Nutrition Facts
            <img
              src="https://email.helloyumi.com/assets/TotBoxDetails_Add.svg"
              alt="add"
            />
          </summary>
          <div
            css={css`
              width: 280px;
            `}
          >
            {item.nutrition &&
              item.stage < 4 && (
                <NutritionLabel
                  servingSize="1 Jar"
                  serving_size={item.nutrition.serving_size}
                  name={item.name || item.meal_name}
                  calories={Math.floor(item.nutrition.calories)}
                  totalFat={Math.floor(item.nutrition.fat_grams)}
                  saturatedFat={Math.floor(item.nutrition.saturated_fat_grams)}
                  transFat={Math.floor(item.nutrition.trans_fat_grams)}
                  cholesterol={Math.floor(
                    item.nutrition.cholesterol_milligrams
                  )}
                  sodium={Math.floor(item.nutrition.sodium_milligrams)}
                  totalCarbs={Math.floor(item.nutrition.carbohydrate_grams)}
                  dietaryFiber={Math.floor(item.nutrition.fiber_grams)}
                  sugars={Math.floor(item.nutrition.sugar_grams)}
                  protein={Math.floor(item.nutrition.protein_grams)}
                  vitaminA={Math.floor(item.nutrition.vitamin_a_IU)}
                  vitaminC={Math.floor(item.nutrition.vitamin_c_milligrams)}
                  calcium={Math.floor(item.nutrition.calcium_milligrams)}
                  iron={Math.floor(item.nutrition.iron_milligrams)}
                  ingredients={item.ingredients}
                  data={item.nutrition}
                />
              )}
            {item.stage > 3 && (
              <img
                css={css`
                  width: 80%;
                  margin-top: 1em;
                  position: unset;
                `}
                src={`https://email.helloyumi.com/assets/${
                  item.meal_id
                }-Nutrition.png`}
                alt="Nutrition Label"
              />
            )}
          </div>
        </RowItemDetails>
      </Box>
    </Modal>
  );
};

const getImageBgUrlFromQuadId = (color) =>
  `https://email.helloyumi.com/assets/_meals/${color}-bg.png`;

const RowItemDetails = styled.details`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  font-family: "Freight-Book";
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  padding: 16px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
  color: #000000;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  :nth-of-type(1) {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  ::-webkit-details-marker {
    display: none;
  }

  summary {
    :focus {
      outline: none;
    }

    display: flex;
    justify-content: space-between;
    font-family: "Platform-Medium";

    img {
      margin-right: 5px;
      position: absolute;
      right: 10px;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }
`;

const ImageContainer = styled(Box)`
  cursor: pointer;
  width: calc(100% - 36px);
  margin: 18px;
  background-size: cover;
  background-position-y: center;
  padding: 0;
  height: 225px;
  background-color: #f9eee5;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    width: 330px;
  }

  overflow: ${({ special }) => special && "hidden"};

  ${(props) =>
    props.item.stage === 4 &&
    css`
      background: #c4bbf2;
    `};
`;

const JarImage = styled.img`
  width: 85px;

  width: ${({ special }) => special && "100%"};
  margin-top: ${({ special }) => special && "-40px"};
`;

export default MenuModal;
