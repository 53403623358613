import spacetime from "spacetime";
import _ from "lodash";
import { getOrders } from "../../usecases/orders";

/*
 * Fetches and sorts orders.
 */
const getOrdersService = async () => {
  const ORDERS_PAGE = 1;
  const ORDERS_PER_PAGE = 10;
  const ORDERS_SORT_BY = "desc";
  const date = spacetime
    .now()
    .subtract(2, "days")
    .format("yyyy-MM-dd");

  const orders = await getOrders(
    ORDERS_PAGE,
    ORDERS_PER_PAGE,
    ORDERS_SORT_BY,
    date
  );
  return orders;
};

export const getUpcomingOrders = async () =>
  (await getOrdersService())
    .filter(({ delivery_date }) =>
      spacetime(delivery_date).isAfter(spacetime.now().add(-2, "day"))
    )
    .sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));

export const getNextOrder = async () => _.first(await getUpcomingOrders());

export const getOrderByDate = async date =>
  _.find(
    await getOrders(1, 10, "desc"),
    ({ delivery_date }) => spacetime(delivery_date).format("iso-short") === date
  );

export default getOrdersService;
