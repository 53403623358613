import React from "react";
import {
  Card,
  CardTitle,
  ModalButton,
  ButtonContainer,
  EditHeader
} from "../components/styledComponents";
import Modal from "../components/Modal";
import BillingIcon from "../../../../assets/icons/Dashboard-icons/creditcard-icon.svg";
import BillingForm from "../../../styledcomponents/containers/StripeBillingForm";
import { updateBilling } from "../../../usecases/users";
import { formatStripeResponse } from "../../../utils/formatStripeResponse";

export const BillingInformation = ({
  user,
  openModal,
  activeModal,
  close,
  success
}) => (
  <Card onClick={openModal} icon={BillingIcon}>
    <CardTitle>Billing Information</CardTitle>
    <Modal isOpen={activeModal === "Billing Information"} close={close}>
      <EditHeader close={close}>Edit Billing Information</EditHeader>
      <BillingForm
        button={requestStripeToken => (
          <ButtonContainer>
            <ModalButton cancel type="button" value="Cancel" onClick={close} />
            <ModalButton
              type="button"
              value="Save"
              onClick={async e => {
                await updateBilling(
                  user.id,
                  formatStripeResponse(await requestStripeToken(e))
                );
                success();
                close();
              }}
            />
          </ButtonContainer>
        )}
      />
    </Modal>
  </Card>
);
