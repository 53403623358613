import React, { useState } from "react";
import styled, { css } from "styled-components";
import { oat, blackberry } from "../../../styledcomponents/colors";
import { H3, H5, BlueButton, Body1 } from "../../../styledcomponents";
import "styled-components/macro";

const Pineapple =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Pineapple.svg";

const DownArrow =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_mini-chevron-right-solid.svg";

const questions = [
  {
    title: "What makes Yumi different from store bought baby food?",
    answer:
      "We not only use organic and fresh ingredients, we collaborate with a team of doctors, nutritionists, and chefs to continually develop the most nutrient dense purees available.  We first balance our menu to make sure we include a wide range of fruits, legumes, greens, grains, root veggies, spices, and fats. Then, when developing each flavor we continue iterating on each recipe until we have at least 5 micronutrients. (20% of your child's recommended daily allocation) Lastly, we make sure your baby has sustained energy by balancing the amount of sugar and fiber in the blend. We also use Modified Atmospheric Packaging to make sure our fresh fruits and veggies maintain their nutritional value whereas most big brands use heat and dangerous preservatives that destroy the nutritional content of the ingredients. The result is simply smarter babyfood. "
  },
  {
    title: "Are your meals organic?",
    answer: "Yes, we only use certified organic ingredients."
  },
  {
    title: "Are your meals fresh?",
    answer: `Each week, we shop, prep and cook your babies meals using the same preparation methods you'd use at home. So skip the planning, shopping, chopping, steaming, slicing, boiling, baking. We use only the fresh, whole and organic ingredients and NEVER use pre-processed purees like other baby brands.
    \nOur meals are prepared weekly and stay fresh in the fridge for 7 days, 2 months in the freezer and 24 hours once opened. We color-code our deliveries so you can easily spot last week's jars in the fridge; you can also find the expiration date under the lid. While we don’t add any preservatives, our jars are sealed using HPP which ensures that no bacteria gets into your baby’s food.
    To freeze your jars, please remove the seal and be sure to write the date you froze the jar on the lid. Once you're ready to serve, let the jar defrost in the fridge and be sure to serve within 24 hours of unfreezing.`
  },
  {
    title: "When do you deliver?",
    answer: `All of our orders deliver by Wednesday at 9 pm, depending on your location. You can find more detailed tracking info by visiting helloyumi.com/orders.`
  },
  {
    title: "Are the purees shipped frozen?",
    answer: "Nope! Our food is fresh, never frozen."
  }
];

export const CommonQuestions = () => (
  <QuestionsContainer>
    <img
      src={Pineapple}
      alt="pineapple"
      css={`
        @media (max-width: 900px) {
          width: 30px !important;
        }
      `}
    />
    <H3
      style={{
        textAlign: "center",
        color: blackberry,
        marginTop: "34px",
        fontSize: "40pt"
      }}
      css={css`
        @media (max-width: 900px) {
          font-size: 23pt !important;
        }
      `}
    >
      Commonly Asked Questions
    </H3>
    <QuestionWrapper>
      {questions.map(question => (
        <QuestionBox key={question.title} question={question} />
      ))}
    </QuestionWrapper>
  </QuestionsContainer>
);

const QuestionBox = ({ question }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const toggleAnswer = () => setShowAnswer(old => !old);

  return (
    <WhiteDropDownBox visible={showAnswer}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleRow onClick={() => toggleAnswer()}>
          <H5
            style={{
              color: blackberry,
              fontSize: "18pt"
            }}
            css={css`
              @media (max-width: 1000px) {
                font-size: 12pt !important;
              }
            `}
          >
            {question.title}
          </H5>
          <BlueButton style={{ outline: "none" }}>
            {" "}
            <img
              src={DownArrow}
              alt="down-arrow"
              style={{
                height: "3vh",
                transform: showAnswer ? `rotate(180deg)` : ``,
                transitionTiming: `ease-in-out`,
                transition: `all 0.08s`
              }}
            />
          </BlueButton>
        </TitleRow>
        <div>
          <AnswerArea visible={showAnswer}>
            <Body1
              style={{ fontSize: "12pt", marginTop: "10px" }}
              css={`
                @media (max-width: 1000px) {
                  font-size: 10pt !important;
                }
              `}
            >
              {question.answer}
            </Body1>
          </AnswerArea>
        </div>
      </div>
    </WhiteDropDownBox>
  );
};

const AnswerArea = styled.div`
  display: ${({ visible }) => (visible ? `flex` : `none`)};
`;
const TitleRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

const WhiteDropDownBox = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  margin: 1vh;
  width: 550px;
  padding: 50px;
  border-radius: 0.1rem;
  max-height: ${({ visible }) => (visible ? `none` : `16vh`)};
  border: ${({ visible }) => (visible ? `1px solid ${blackberry}` : "")};
  transition: all 0.2s;
  opacity: ${({ visible }) => (visible ? `1` : "0.85")};

  @media (max-width: 1000px) {
    padding: 20px;
    width: 300px;
  }
  @media (max-height: 700px) {
    padding: 20px;

    font-size: 12pt !important;
  }
`;
const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 5vh;
`;
export const QuestionsContainer = styled.div`
  background: ${oat};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
