import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_Houston/${name}${extension}`;

export const StarterPack = getImageURL("StarterPack", ".png");

export const VarietyPack = getImageURL("VarietyPack", ".png");

export const HoustonBabe = getImageURL("HoustonBabe", ".jpeg");

export const TrayOfBites = getImageURL("DoubleTime", ".jpeg");

export const HoustonApricot = getImageURL("HoustonApricot", ".jpg");

export const HoustonBell = getImageURL("HoustonBell", ".jpg");

export const HoustonApricot2 = getImageURL("HoustonApricot2", ".png");

export const HoustonBell2 = getImageURL("HoustonBell2", ".png");

export const Peach = getImageURL("PeachClose", ".png");

export const BabyBlackberry = getImageURL("BabyBlackberry", ".png");

export const PlainPuffs = getImageURL("PlainPuffs", ".png");

export const Rizzo = getImageURL("Rizzo", ".jpeg");

export const Baby = `https://email.helloyumi.com/assets/_MilestonePage/MilestonePage_TastePreference.svg`;

export const CactusTilted = getImageURL("Cactus", ".svg");

export const CowboyHat = getImageURL("Hat2", ".svg");

export const TexasStar = getImageURL("StarOne", ".svg");

export const Cactus = getImageURL("FullCactus", ".svg");

export const PurpleTray = getImageURL("PurpleTray", ".png");

export const FacelessHat = getImageURL("Faceless", ".svg");

export const Star = getImageURL("StarBig", ".svg");
