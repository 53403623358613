export const Mashable =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/Mashable.svg";
export const Goop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/goop.svg";
export const Julia2 =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/julia2.jpg";
export const Julia4 =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/julia4.jpeg";
export const Julia5 =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/julia5.jpeg";

export const Julia6 =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/YUMIxSFP-096_.jpg";
export const NYT =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/nyt.svg";
export const VeggieGroupMobile =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/veggieGroupMobile.svg";
export const VeggieGroupDesktop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/veggieGroupDesktop.svg";
export const JuliaCutting =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/YUMIxSFP-079_.jpg";

export const Cloud = "https://email.helloyumi.com/assets/chef-series/Cloud.svg";
