import styled from "styled-components";
import React from "react";
import "styled-components/macro";
import { blackberry } from "../colors";
import { MenuCard } from "../containers/MenuCard";

export const QuadDisplay = ({
  menu,
  color,
  title = "Explore your first box"
}) => (
  <QuadContainer>
    <FirstOrderTitle>{title}</FirstOrderTitle>
    <MenuContainer menus={menu} color={color} />
  </QuadContainer>
);

export const MenuContainer = ({ menus, color }) => (
  <MenuWrapper>
    {menus.map(menu => (
      <MenuCard
        key={menu.meal_id}
        quad
        blends
        quadmenu
        color={color}
        item={menu}
        quantity={menu.quantity}
        special={[1535, 1537, 1542].includes(menu.meal_id)}
      />
    ))}
  </MenuWrapper>
);

export const MenuWrapper = styled.div`
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1em;
  justify-items: center;
  align-items: center;
  @media (max-width: 1220px) {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 1em;
    padding: 0 4px;
`;

const QuadContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
`;

const FirstOrderTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 110%;
  letter-spacing: 0.01em;
  color: ${blackberry};
  margin-bottom: 0;
  margin-top: 1.25em;
  font-family: "Platform-Medium", sans-serif;

  @media (max-width: 500px) {
    margin-top: 1em;
    font-size: 20pt;
  }
`;
