import React from "react";
import styled from "styled-components";
import { BlueButton } from "../../styledcomponents";
import "styled-components/macro";

export const RoundButton = styled(BlueButton)`
  border-radius: 40px;
`;

export const PinkSlice =
  "https://email.helloyumi.com/assets/Holiday/pinkslice.png";
export const BlueSlice =
  "https://email.helloyumi.com/assets/Holiday/blueslice.png";

export const SmallLogo = "https://email.helloyumi.com/assets/Holiday/small.svg";

export const MobileSnowman =
  "https://email.helloyumi.com/assets/Holiday/MobileSnowman.svg";

export const MobileGinger =
  "https://email.helloyumi.com/assets/Holiday/GingerMan.svg";

export const MobilePenguin =
  "https://email.helloyumi.com/assets/Holiday/MobilePenguin.svg";

export const Building =
  "https://email.helloyumi.com/assets/Holiday/Building.svg";

export const Mistletoe =
  "https://email.helloyumi.com/assets/Holiday/Mistletoe.svg";

export const PinkGift =
  "https://email.helloyumi.com/assets/Holiday/GiftSVG.svg";

export const YumiLogo =
  "https://email.helloyumi.com/assets/Holiday/yumiLogo.svg";

export const Border = "https://email.helloyumi.com/assets/Holiday/Border.svg";
export const SideBorder =
  "https://email.helloyumi.com/assets/Holiday/SideBorder.svg";
