import spacetime from "spacetime";
import * as types from "../types";
import { post } from "../../../utils/request";
import requireAuth from "../../../utils/requireAuth";

export const setLoginPending = (isLoginPending) => ({
  type: types.LOGIN_PENDING,
  isLoginPending,
});

export const setLoginSuccess = (isLoginSuccess) => ({
  type: types.LOGIN_SUCCESS,
  isLoginSuccess,
});

export const setLoginError = (isLoginError) => ({
  type: types.LOGIN_ERROR,
  isLoginError,
});

export const setRegisterPending = (isRegisterPending) => ({
  type: types.REGISTER_PENDING,
  isRegisterPending,
});

export const setRegisterError = (isRegisterError) => ({
  type: types.REGISTER_ERROR,
  isRegisterError,
});

export const setRegisterSuccess = (isRegisterSuccess) => ({
  type: types.REGISTER_SUCCESS,
  isRegisterSuccess,
});

export const setSessionExpired = (sessionExpired) => ({
  type: types.SESSION_EXPIRED,
  sessionExpired,
});

export const login = (email, password) => async (dispatch) => {
  dispatch(setSessionExpired(false));
  dispatch(setLoginPending(true));
  dispatch(setLoginSuccess(false));
  dispatch(setLoginError(false));

  let loginStatus;
  try {
    loginStatus = await sendLoginRequest(email, password);
  } catch (error) {
    // Cannot reach backend for authentication
    dispatch(setLoginError(true));
    dispatch(setLoginPending(false));
    return false;
  }

  if (loginStatus.email) {
    setLoginPending(false);
    setLoginSuccess(true);
    window.localStorage.setItem("feathers-jwt", loginStatus.accessToken);
    await dispatch({
      type: types.ADD_USER,
      payload: {
        id: loginStatus.id,
        expiresAt: spacetime
          .now("America/Los_Angeles")
          .add(30, "minutes")
          .format("iso"),
      },
    });
    return true;
  }
  dispatch(setLoginError(true));
  return false;
};

export const logout = () => async (dispatch) => {
  dispatch(setLoginSuccess(false));
  dispatch(dispatch({ type: types.CLEAR_USER }));
  window.localStorage.removeItem("feathers-jwt");
  window.localStorage.removeItem("userData");
  window.localStorage.removeItem("accessToken");
  requireAuth.deleteCookie("user_id");
  requireAuth.deleteCookie("userId");
  // let loginStatus
  // try {
  //   loginStatus = await sendLogoutRequest()
  // } catch (error) {
  //   // Cannot reach backend for authentication
  //   dispatch(setLoginError(true))
  // }
};

export const logoutSessionExpired = () => async (dispatch) => {
  dispatch(setLoginSuccess(false));
  dispatch(dispatch({ type: types.CLEAR_USER }));
  dispatch(dispatch(setSessionExpired(true)));
};

export const register = (name, email, password) => async (dispatch) => {
  dispatch(setRegisterSuccess(false));
  dispatch(setRegisterPending(true));
  dispatch(setRegisterError(null));
  dispatch(setLoginSuccess(false));
  let loginStatus;
  try {
    loginStatus = await sendRegisterRequest(name, email, password);
  } catch (error) {
    console.log(error);
    dispatch(setLoginError(true));
  }

  if (loginStatus) {
    if (loginStatus.id) {
      dispatch(setRegisterPending(false));
      dispatch(setRegisterError(null));
      dispatch(setRegisterSuccess(true));
      dispatch(setLoginSuccess(true));
    }
  }
};

const sendLoginRequest = (email, password) => {
  const body = {
    email,
    password,
  };
  return post("auth/verify", body);
};

// const sendLogoutRequest = () => {
//   console.log('remove login token')
// }

const sendRegisterRequest = async (name, email, password) => {
  const body = {
    name,
    email,
    password,
    uses_two_factor_auth: 0,
    is_admin: 0,
    password_is_temporary: 0,
    must_change_password: 0,
  };

  return post("users", body);
};
