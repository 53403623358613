import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BlueButton, yumiOrange } from "../../styledcomponents";
import "styled-components/macro";

const getImageURL = (folder = "_HomePageRefresh", name, extension = ".png") =>
  `https://email.helloyumi.com/assets/${folder}/${name}${extension}`;

// new assets

// some old assets
export const TrayOfBites = getImageURL("_Houston", "DoubleTime", ".jpeg");
export const PlainPuffs = getImageURL("_Houston", "PlainPuffs", ".png");
export const PurpleTray = getImageURL("_Houston", "PurpleTray", ".png");
export const Brain = getImageURL("_About", "About_Brain", ".svg");

export const Paired2Perf = getImageURL(
  "_HomePageRefresh",
  "Paired2Perf",
  ".png"
);
export const Balanced = getImageURL("_HomePageRefresh", "Balanced", ".png");
export const Over100 = getImageURL("_HomePageRefresh", "Over100", ".png");
export const RangeOfTextures = getImageURL(
  "_HomePageRefresh",
  "RangeOfTextures",
  ".png"
);
export const MobileBG = getImageURL("_HomePageRefresh", "MOBILEBGg", ".png");
export const WinterBG = getImageURL("_HomePageRefresh", "HolidayHero", ".png");
export const Blueberry = getImageURL("_HomePageRefresh", "Blueberries", ".svg");
export const Berries = getImageURL("_HomePageRefresh", "Berries", ".svg");
export const CrowdCarrot = getImageURL(
  "_HomePageRefresh",
  "CrowdCarrot",
  ".svg"
);

export const Avo = getImageURL("_HomePageRefresh", "Avo", ".svg");
export const BackgroundWave = getImageURL("_HomePageRefresh", "Fill 1", ".svg");
export const SecondGif = getImageURL("_OneOffPurchase", "BabySecond", ".gif");
export const BabyTime = getImageURL(
  "_OneOffPurchase",
  "Nutrients_StopMotion",
  ".gif"
);

export const HalfKiwi = getImageURL("_HomePageRefresh", "HalfKiwi", ".svg");
export const HalfPine = getImageURL("_HomePageRefresh", "HalfPine", ".svg");

export const LittleTomato = getImageURL(
  "_HomePageRefresh",
  "LittleTomate",
  ".svg"
);

export const Starter = getImageURL("_HomePageRefresh", "starter");
export const Variety = getImageURL("_HomePageRefresh", "variety");

export const MobileJar1 = getImageURL("_HomePageRefresh", "MobileJar1", ".png");
export const MobileJar2 = getImageURL("_HomePageRefresh", "MobileJar2", ".png");
export const MobileJar3 = getImageURL("_HomePageRefresh", "MobileJar3", ".png");
export const MobileJar4 = getImageURL("_HomePageRefresh", "MobileJar4", ".png");
export const MobileJar5 = getImageURL("_HomePageRefresh", "MobileJar5", ".png");
export const MobileJar6 = getImageURL("_HomePageRefresh", "MobileJar6", ".png");
export const MobileJar7 = getImageURL("_HomePageRefresh", "MobileJar7", ".png");

// OrangeButton
export const OrangeLinkButton = ({ to, value }) => (
  <Link css="text-decoration:none" to={to}>
    <OrangeButton value={value} />
  </Link>
);

const OrangeButton = styled(BlueButton)`
  background: ${yumiOrange};
  color: white;
  box-shadow: 0px 1px 2px gray;
  padding: 20px;
  font-size: 12pt;
  pointer-events: auto !important;
  width: 170px;
  @media (max-width: 500px) {
    font-size: 11pt;
    width: 200px;
    margin-top: 0px;

    padding: 15px;
  }
`;
