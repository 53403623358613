import React from "react";
import styled, { css } from "styled-components";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { OrangeLinkButton, Brain, BabyTime, HalfKiwi } from "../assets";
import { yumiOrange } from "../../../styledcomponents";
import { LazyImage } from "../../../styledcomponents/LazyImage";
import "styled-components/macro";

export const GrowingMinds = ({ width }) => (
  <GrowContainer>
    <img
      src={HalfKiwi}
      alt="pine"
      css="position:absolute; left:0; @media (max-width: 1100px) {
   width:80px;
      @media (max-width: 800px) {
      width:40px;
      }
  }@media (max-width: 500px) {
   display:NONE;
  }"
    />

    <InnerGrid>
      <div />
      <Left>
        <img
          src={Brain}
          alt="brain"
          css="width:75px; position:absolute; margin-left:190px; margin-top:-220px;

          @media(max-width:1280px){
            margin-top:-230px;
          }
          @media(max-width:1200px){
          margin-top:-245px;
          }
          @media(max-width:1170px){
          margin-top:-265px;
          }
           @media(max-width:1100px){
          margin-top:-220px;
          }
          @media(max-width:1000px){
            display:none;
          }
          @media(max-width:500px){
            display:flex;
            width:50px;margin-top:-175px;
            margin-left:150px;
          }
          "
        />
        <H1
          css={css`
            color: ${yumiOrange};
            padding-bottom: 30px;

            @media (max-width: 750px) {
            }
          `}
        >
          Made for
          <br /> growing minds
        </H1>
        <Body
          css={css`
            padding-bottom: 30px;
            width: 85%;
          `}
        >
          At this moment, your baby's brain is growing faster than it ever will.
          By the first year, the brain doubles in size. The foundations for
          motor skills, social cues, emotional development memory, attention,
          temperament, taste preference and more are built during this time.
        </Body>
        <OrangeLinkButton value="LEARN MORE" to="/milestones" />
      </Left>
      <Right>
        <LazyImage
          src={BabyTime}
          alt="babygif"
          css=" width:600px; @media(max-width:1100px){width:300px;}@media(max-width:750px){width:230px;}@media(max-width:650px){width:190px;}@media(max-width:550px){width:120px;}@media(max-width:500px){width:100%; object-fit:cover;} "
        />
      </Right>
      <div />
    </InnerGrid>
  </GrowContainer>
);

const InnerGrid = styled.div`
  display: grid;
  padding-top: 50px;
  grid-template-columns: 0.5fr 1.2fr 1fr 0.2fr;
  grid-template-rows: 700px;
  align-items: center;
  @media (max-width: 1000px) {
    grid-template-rows: 500px;
  }
  @media (max-width: 500px) {
    padding-top: 0px;
    grid-template-columns: 100%;
    grid-template-rows: 50px 0px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 500px) {
    order: 2;
    padding-left: 30px;
    padding-top: 30px;
  }
`;
const Right = styled.div`
  width: 100%;
  padding-left: 50px;

  @media (max-width: 500px) {
    order: 1;

    padding-left: 0px;
  }
`;

const GrowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
