import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../containers/styles/styles";

const Button = ({
  text,
  textStyle,
  onClick,
  secondary,
  third,
  accent,
  mainColor,
  noFill,
  style,
  id,
  buttonClass,
  textClass,
  disabled,
  dark,
  className,
}) => (
  <ButtonWrapper
    disabled={disabled}
    className={`button_wrapper ${
      buttonClass !== undefined ? buttonClass : ""
    } ${className}`}
    style={style}
    noFill={noFill}
    secondary={secondary}
    third={third}
    accent={accent}
    id={id}
    dark={dark}
    mainColor={mainColor}
    onClick={disabled ? null : onClick}
  >
    <Text
      className={`button_text, ${textClass}`}
      style={textStyle}
      id={id}
      noFill={noFill}
      secondary={secondary}
      dark={dark}
    >
      {text}
    </Text>
  </ButtonWrapper>
);

/*
 * TODO: global styles for colors
 */
const ButtonWrapper = styled.div`
  width: auto;
  height: auto;
  padding: 9px 18px;
  background-color: ${(props) =>
    props.secondary
      ? "rgba(0,0,0,0)"
      : props.third
        ? "#75403E"
        : props.accent
          ? colors.accentColor
          : props.mainColor
            ? "#000"
            : "#334897"};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-style: normal;
  cursor: pointer;
  text-decoration: none !important;
  border: solid 1px
    ${(props) =>
      props.secondary
        ? "white"
        : props.third
          ? "#75403E"
          : props.accent
            ? colors.accentColor
            : props.mainColor
              ? "#000"
              : "#334897"};

  color: ${(props) =>
    props.noFill ? (props.secondary ? "black" : "black") : "white"};

  &:hover {
    background-color: white;
    border: solid 1px
      ${(props) =>
        props.secondary
          ? "white"
          : props.third
            ? "#75403E"
            : props.accent
              ? colors.accentColor
              : props.mainColor
                ? "#000"
                : "#334897"};
    color: ${(props) =>
      props.secondary
        ? "#4c4c4c"
        : props.third
          ? "#75403E"
          : props.accent
            ? colors.accentColor
            : props.mainColor
              ? "#000"
              : "#334897"};
  }

  ${(props) =>
    props.dark &&
    css`
      background: #000000;
      border: solid 1px #000000 !important;
      color: white;
      &:hover {
        background-color: #ffffff;
        border: solid 1px #000000 !important;
        color: black;
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: #dedfe0;
      color: white;
      border-color: #dedfe0;

      &:hover {
        background: #dedfe0;
        border: none;
        color: white;
        cursor: auto;
      }
    `} ${(props) =>
    props.noFill &&
    css`
      background: white;
      border: solid 1px ${(props) => (props.secondary ? "#f3a689" : "#334897")};
    `};
`;

const Text = styled.div`
  font-family: Platform-Medium;
  font-size: 14px;
  text-transform: uppercase;
  text-spacing: 0.5px;
`;

export default Button;
