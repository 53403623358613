import styled from "styled-components";
import { H5 } from "@team-yumi/ui";

const mobile = "900px";
const Text = styled.div`
  ${props => props.color && `color: ${props.color}`};
`;

// H1: Introduce the main goal for a page, always use color in headlines
export const H1 = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 36px;
    line-height: 39px;
  }
`;

// H2: Introduce a topic, always use color in headlines
export const H2 = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 30px;
    line-height: 33px;
  }
`;

// H3: Introduce a topic. Always use color in headlines
export const H3 = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 30px;
  line-height: 24px;
  letter-spacing: 1%;

  @media (max-width: ${mobile}) {
    font-size: 24px;
    line-height: 26px;
  }
`;

// H4: Tonal copy or value props. Always use color in headlines.

export const H4 = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 26px;
  line-height: 28.6px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 18px;
    line-height: 19.8px;
  }
`;

// ProductName: Tonal copy or value props. Informational copy, product name, dates.

export const ProductName = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 22px;
  line-height: 24.2px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 14px;
    line-height: 19.8px;
  }
`;

// Label: Header on informational sections.
export const Label = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 14px;
  line-height: 19.8px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    /* the same */
  }
`;

// TextButton: Header on informational sections
export const TextButton = styled(Text)`
  font-family: "Platform-Medium", sans-serif;
  font-size: 14px;
  line-height: 15.4px;
  letter-spacing: 1%;
  color: rgba(0, 0, 0, 0.38);
  margin: 16px 0 8px 0;
  @media (max-width: ${mobile}) {
    font-size: 11px;
    line-height: 12.1px;
  }
`;

// Body: Always used black text for text used with Freight
export const Body = styled(Text)`
  font-family: "Freight-Book";
  font-size: 22px;
  line-height: 36px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 18px;
    line-height: 27px;
  }
`;

// Body 2: same as Body 1
export const Body2 = styled(Text)`
  font-family: "Freight-Book";
  font-size: 24px;
  line-height: 31.2px;
  letter-spacing: 1%;
  @media(max-width${mobile}){
      /* the same */
  }
`;

// Detail: Ingredients. Always use Black for text used with Freight
export const Detail = styled(Text)`
  font-family: "Freight-Book";
  font-size: 16px;
  line-height: 24.2px;
  letter-spacing: 1%;
  @media (max-width: ${mobile}) {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const SettingsDetail = styled(H5)`
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px !important;
  line-height: 24.2px;
  @media (max-width: ${mobile}) {
    font-size: 14px;
    line-height: 21px;
  }
`;

// PullQuote: Press Quotes.
export const PullQuote = styled(Text)`
  font-family: "Freight-Book";
  font-size: 30px;
  line-height: 46.8px;
  @media (max-width: ${mobile}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Title = styled(Text)`
  text-align: center;
  font-family: "Platform-Medium", sans-serif;
  font-size: 24px;
  line-height: 51px;
  letter-spacing: 1%;
  color: #334897;
  @media (max-width: ${mobile}) {
    font-size: 24px;
    line-height: 39px;
  }
`;
