import React from "react";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import { connect } from "react-redux";
import logo from "../../../assets/icons/Yumi_logo.svg";
import menu from "../../../assets/icons/menu.svg";
import Button from "../Button";
import NavModal from "../NavModal";
import { logout } from "../../redux/actions/Auth/index";
import requireAuth from "../../utils/requireAuth";
import flare from "./styles/flare.svg";
import "styled-components/macro";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      modalOpen: false,
      fixed: false
    };
  }

  componentDidMount() {
    if (window.innerWidth > 500) {
      this.props.history.listen(location => {
        this.setState({
          opacity: location.pathname !== "/" ? 1 : window.scrollY / 100
        });
      });
    }

    document.addEventListener("scroll", () => {
      if (this.props.history.location.pathname === "/") {
        const opacity = window.scrollY / 100;
        this.setState({ opacity });
      }

      if (window.outerWidth < 946) {
        if (window.scrollY > 175) {
          this.setState({ fixed: true });
        } else {
          this.setState({ fixed: false });
        }
      } else if (window.outerWidth < 630) {
        if (window.scrollY > 120) {
          this.setState({ fixed: true });
        } else {
          this.setState({ fixed: false });
        }
      } else if (window.outerWidth < 405) {
        if (window.scrollY > 100) {
          this.setState({ fixed: true });
        } else {
          this.setState({ fixed: false });
        }
      } else if (window.scrollY > 200) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    });
  }

  buttonTextStyle = {
    fontFamily: "Platform-Medium",
    fontSize: "14px",
    letterSpacing: "1px"
  };

  buttonStyle = () => {
    let style;

    if (
      this.props.history.location.pathname === "/" ||
      this.props.history.location.pathname === "/plans"
    ) {
      style = "true";
      return style;
    }

    return style;
  };

  logoutUser = async () => {
    this.props.logout();
    this.setState({ modalOpen: false });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  authed = () => requireAuth.authenticated(this.props.user);

  render() {
    const { user } = this.props;

    return (
      <div>
        {1 === 0 && (
          <nav
            style={{
              position: "fixed",
              top: 0,
              bottom: "auto",
              zIndex: 1000,
              width: "100vw",
              textAlign: "center",
              fontSize: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Avenir, sans-serif"
            }}
            css="height: 52px;
                  background: #645394 url(
            'https://email.helloyumi.com/assets/starsBGDesktop.svg') no-repeat right top;
            background-size: cover;
            @media (max-width: 1500px) {
              background-url: url('https://email.helloyumi.com/assets/bannerStarsDesk.svg') }
            @media (max-width: 1100px) {
              background-url: url('https://email.helloyumi.com/assets/bannerStarsDesktop.svg') }
            @media (max-width: 800px) {
              background-url: url('https://email.helloyumi.com/assets/bannerStarsMobile.svg');
              height: 40px;
            background-size: contain;
            }"
          >
            <div
              css="font-family: Platform-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 52px;
                    color: white; letter-spacing: 0.28px; background-color: #645394;
                    @media (max-width: 800px) {
                      line-height: 40px;
                      font-size: 16px;
                    }
            "
            >
              New From Our Chef Series:{" "}
              <Link to="/julia-sherman" style={{ color: "white" }}>
                Salad For President
              </Link>
            </div>
          </nav>
        )}
        <div
          className="navigationBar_mainContainer fixed"
          style={{
            background: `rgba(255,255,255, ${this.state.modalOpen ? 1 : 1})`,
            display: this.props.hide ? "none" : "flex"
          }}
          css="top: 52px;
          @media (max-width: 800px) {
            top: 40px;
          }"
        >
          <div className="navigationBar_leftContainer">
            <ul className="desktop">
              <li className="link">
                <Link to="/menu">OUR MENU</Link>
              </li>

              <li className="link">
                <Link to={`/plans${window.location.search}`}>PLANS</Link>
              </li>

              <li className="link nohover" onClick={() => this.toggleModal()}>
                <img src={menu} alt="hamburger" />
              </li>
            </ul>
            <img
              onClick={() => this.toggleModal()}
              src={menu}
              className="navigationBar_leftContainer_menu hamburgey"
              alt="hamburger"
            />
          </div>
          <div className="navigationBar_mainContainer_logoContainer">
            <Link to="/">
              <img
                onClick={() => this.closeModal()}
                src={logo}
                className="navigationBar_mainContainer_logoContainer_logo"
                alt="hamburger"
              />
            </Link>
          </div>
          <div className="navigationBar_rightContainer">
            <ul>
              <li>
                <a
                  href="https://helloyumi.kustomer.help/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </li>

              {this.authed() && (
                <li>
                  {" "}
                  <Link to="/profile/">PROFILE</Link>
                </li>
              )}
              {this.authed() && <li onClick={this.logoutUser}>LOGOUT</li>}
              {!this.authed() && (
                <li>
                  <Link to="/login">LOGIN</Link>
                </li>
              )}
              {!this.authed() && (
                <li>
                  <Link to="/checkout">
                    <Button
                      third={this.buttonStyle()}
                      textStyle={this.buttonTextStylea}
                      onMouseEnter={{ backgroundColor: "red" }}
                      text="Get Started"
                    />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        {this.state.modalOpen ? (
          <NavModal
            handleClick={() => this.closeModal()}
            user={user}
            onClick={this.logoutUser}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar);
