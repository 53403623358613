import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import "styled-components/macro";
import {
  CukeBackground,
  InnerGridTemplate,
  EduardoChopping,
  GreenButton
} from "../assets";
import { yumiGreen, H6 } from "../../../styledcomponents";

import { H1, Body } from "../../../styledcomponents/typefaces";

export const Salare = () => (
  <SalareContainer>
    <InnerGridTemplate
      gridRows="600px"
      gridMobileRows="0px"
      gridDimensions="0.3fr 1fr 2fr 0.3fr"
      leftChildren={(
        <React.Fragment>
          <img
            src={EduardoChopping}
            alt="salare"
            css="width:698px;height:600px; object-fit:cover;
            @media(max-width:1200px){
            width:550px;
            height: 100%;
            }
            @media(max-width:1000px){
              width:100%;
              height: 100%;
            }
            "
          />
        </React.Fragment>
)}
      rightStyling={css`
        background: white;
        height: 100%;
        padding-left: 80px;
        display: flex;
        flex-direction: Column;
        justify-content: center;

        @media (max-width: 1000px) {
          padding-left: 25px;
          padding-bottom: 50px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <H6
            css={css`
              font-size: 14pt;
              padding-top: 30px;
              padding-bottom: 20px;
            `}
          >
            Introducing Our Latest Chef Collab:
          </H6>
          <H1
            css={css`
              font-size: 55pt;
              padding-bottom: 30px;

              @media (max-width: 1000px) {
                font-size: 50pt;
              }
            `}
          >
            Salare
          </H1>
          <Body css="width:87%; font-size:18px; line-height:1.5em; padding-bottom:30px;">
            We’re diving deep into ancient grains and experimental flavors with
            two time James Beard awarded chef, educator restauranteur, and
            father, Edouardo Jordan. <div css="padding-bottom:10px" /> Jordan,
            known for his use of unusual grains and eclectic take on southern
            cuisine, is introducing a host of new superfoods to your babe’s
            palette including, sumac, ginger and sorghum berry.
          </Body>
          <Link to="/checkout" css="text-decoration:none;">
            <GreenButton value="GET THE SALARE BOX" />
          </Link>
        </React.Fragment>
)}
    />
  </SalareContainer>
);

const SalareContainer = styled.div`
  width: 100%;

  @media (min-width: 500px) {
    height: 800px;

    background-repeat: repeat;
  }
  @media (min-width: 501px) {
    height: 1000px;

    background-repeat: repeat;
  }
  @media (min-width: 800px) {
    height: 700px;

    background-repeat: repeat;
  }

  display: flex;
  align-items: center;
  background-position: center;
`;
