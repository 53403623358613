import spacetime from "spacetime";
import Cookies from "js-cookie";

class RequireAuth {
  getCookie(name) {
    return Cookies.get(name);
  }

  setCookie(cookieKey, cookieValue) {
    Cookies.set(cookieKey, cookieValue);
  }

  deleteCookie(name) {
    Cookies.remove(name);
  }

  authenticated(user, location = null) {
    if (user.id && user.id > 0) {
      return true;
    }
    return false;
  }

  clearPayloadCookie() {
    document.cookie = "payload" + "=; expires=Thu, 01 Jan 2002 00:00:01 GMT;";
  }
}

export default new RequireAuth();
