import React from "react";
import "./styles/styles.css";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { restorePassword } from "../../usecases/users";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      token: "",
      password: "",
      confirmPass: "",
      error: "",
      isLoading: true,
      success: "",
      updated: false
    };
  }

  componentDidMount = () => {
    const url = this.props.location.search;
    const { email, token } = queryString.parse(url);
    if (email === undefined || token === undefined) {
      /*
       * Redirect to login if query parameters don't exist
       */
      this.props.history.push("/login");
    }

    this.setState(
      {
        token,
        email
      },
      () => this.setState({ isLoading: false })
    );
  };

  save = async () => {
    const body = {
      password: this.state.password,
      token: this.state.token,
      email: this.state.email
    };

    if (this.state.password !== this.state.confirmPass) {
      this.setState({ error: "Passwords don't match." });
      return;
    }

    if (this.state.password.length <= 3) {
      this.setState({ error: "Password should be greater than 3 characters." });
      return;
    }

    if (this.state.password.indexOf(" ") >= 0) {
      this.setState({ error: "Password cannot contain spaces" });
      return;
    }

    this.setState({ success: "Updating Password.." });
    this.setState({ error: "" });
    try {
      await restorePassword(body);
    } catch (error) {
      this.setState({ success: "" });
      this.setState({ error: "There was an error updating your password." });
      return;
    }
    this.setState({ success: "Updated Password!", updated: true });
  };

  onChangePass = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  render = () =>
    !this.state.isLoading && !this.state.updated ? (
      <div className="resetPassword_container">
        <Input
          type="password"
          onChange={e => this.onChangePass(e, "password")}
          text="New Password"
        />
        <Input
          type="password"
          onChange={e => this.onChangePass(e, "confirmPass")}
          text="Confirm Password"
        />

        <Button onClick={this.save} text="Reset Password" />
        <div>{this.state.success}</div>
        <div className="resetPassword_error">{this.state.error}</div>
      </div>
    ) : (
      <div className="resetPassword_container">
        <div className="resetPassword_success">{this.state.success}</div>
        <Link to="/login">
          <button className="link">Back to Login</button>
        </Link>
      </div>
    );
}

export default ResetPasswordPage;
