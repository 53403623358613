import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Stickyroll } from "@stickyroll/stickyroll";
import {
  H3,
  blackberry,
  Body1,
  H5,
  H6,
  blueberry
} from "../../../styledcomponents";
import {
  FutureAthlete,
  FutureExplorer,
  FutureAstronaut,
  FutureBuilder,
  Planet
} from "../assets";
import { useRequest, useWindowSize } from "../../../hooks";
import { getMilestoneOrders } from "../../../usecases";
import "styled-components/macro";

const getImageUrlFromMealId = id =>
  `https://email.helloyumi.com/assets/0${id}-Card.png`;

const milestones = [
  {
    image: FutureAthlete,
    month: "06",
    job: "Athlete",
    description:
      "At 6 months, your baby might be rolling over and attempting their first sit up. This month, we'll focus on strengthening their core with blends rich in plant-based protein, magnesium, calcium, and choline."
  },
  {
    image: FutureExplorer,
    month: "09",
    job: "Explorer",
    description:
      "At 9 months, your babe is exploring the world around them and may even be crawling. Since they are putting weight on their bones for the first time ever, we're supporting their muscle and bone development with blends rich in calcium, megnesium, and potassium."
  },
  {
    image: FutureAstronaut,
    month: 12,
    job: "Astronaut",
    description:
      "At 12 months, Happy first birthday! This is a huge milestone for your little one. This month, we'll focus on developing their muscles and bones to help support their upward motion, whether that's standing, cruising or walking."
  },
  {
    image: FutureBuilder,
    month: 14,
    job: "Builder",
    description:
      "At 14 months, your babe will move into the next stage of fine motor development. Your little builder will now explore stacking objects vertically. To support their hand-eye coordination we'll give them brain boosting foods."
  }
];

export const GazeIntoFuture = () => {
  const { width } = useWindowSize();
  const [pageInd, setPageInd] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [milestone_orders, error, Loading] = useRequest(getMilestoneOrders);

  if (Loading) return <h1>loading...</h1>;
  if (error || !milestone_orders) return <H3>Milestone orders not found.</H3>;

  return (
    <GazeIntoFutureContainer>
      <GazeTop>
        <img
          src={Planet}
          alt="planet"
          style={{
            width: "150px",
            marginTop: "100px"
          }}
          css={css`
            @media (max-width: 1100px) {
              width: 80px !important;
            }
          `}
        />
        <H3
          style={{
            fontSize: "65px",
            color: blackberry,
            textAlign: "center",
            marginTop: "30px"
          }}
          css={css`
            @media (max-width: 1100px) {
              font-size: 40px !important;
              margin-top: 10px !important;
            }
          `}
        >
          {" "}
          Let's build a healthier generation together
        </H3>
        <Body1
          style={{
            fontSize: "25px",
            textAlign: "center",
            marginTop: "25px"
          }}
          css={css`
            @media (max-width: 1100px) {
              font-size: 17px !important;
              margin-top: 10px !important;
            }
          `}
        >
          Each week, we'll share key info on your child's development and show
          how the meals in your box correlate to your baby's development.
        </Body1>
      </GazeTop>

      <Stickyroll
        pages={milestones}
        factor={2}
        throttle={100}
        style="max-width: 100vw"
      >
        {({ page, pageIndex, pages, progress }) => {
          const activeMilestone = clicked
            ? milestones[pageInd]
            : milestones[pageIndex];

          const pageValue = clicked ? pageInd : pageIndex;

          if (clicked) setClicked(false);

          return (
            <GazeSection width={width}>
              <GazeBottom>
                <FutureSelector>
                  <SelectorColumn>
                    {milestones.map((milestone, i) => (
                      <SelectorActionArea key={i}>
                        <H5
                          style={{
                            color: i === pageValue ? blackberry : "lightgray",
                            margin: "10px"
                          }}
                          css={css`
                            @media (max-width: 1100px) {
                              font-size: 12pt !important;
                            }
                            @media (max-width: 500px) {
                              font-size: 9pt !important;
                            }
                            &:hover {
                              cursor: pointer;
                            }
                          `}
                          onClick={() => {
                            setClicked(true);
                            setPageInd(i);
                          }}
                        >
                          {milestone.month}
                        </H5>
                        <SelectorArrow selected={i === pageValue} />
                        <SelectorDot selected={i === pageValue} />
                      </SelectorActionArea>
                    ))}
                  </SelectorColumn>
                  <img
                    src={activeMilestone.image}
                    alt="milestone"
                    style={{ width: "500px" }}
                    css={css`
                      @media (max-width: 1100px) {
                        width: 200px !important;
                        justify-self: center !important;
                        align-self: center !important;
                      }
                      @media (max-height: 600px) {
                        width: 250px !important;
                      }
                    `}
                  />
                </FutureSelector>
                <FutureInfo>
                  <Header>
                    <H3
                      style={{ color: blackberry }}
                      css={css`
                        @media (max-width: 1100px) {
                          font-size: 30px !important;
                          margin-top: 10px !important;
                          text-align: center !important;
                        }
                        @media (max-height: 600px) {
                          font-size: 15pt !important;
                        }
                      `}
                    >
                      {activeMilestone.month} Months: Future{" "}
                      {activeMilestone.job}
                    </H3>
                    <Body1
                      style={{
                        marginTop: "20px",
                        fontSize: "15pt"
                      }}
                      css={css`
                        @media (max-width: 1100px) {
                          text-align: center !important;
                          font-size: 11pt !important;
                        }
                        @media (max-width: 600px) {
                          font-size: 14px !important;
                          line-height: 1.15 !important;
                          margin: 10px !important;
                        }
                      `}
                    >
                      {activeMilestone.description}
                    </Body1>
                  </Header>
                  <MilestoneOrders>
                    <H6
                      style={{
                        color: blackberry,
                        fontSize: "18pt"
                      }}
                      css={css`
                        padding-top: 30px !important;
                        @media (max-width: 600px) {
                          padding: 0px !important;
                          margin-top: 10px !important;
                          font-size: 10pt !important;
                        }
                        @media (max-width: 1100px) {
                          font-size: 10pt !important;
                        }
                      `}
                    >
                      SAMPLE {activeMilestone.month} MONTH MENU
                    </H6>
                    <OrderRow
                      orders={milestone_orders[pageIndex]}
                      activeMilestone={activeMilestone.month}
                    />
                  </MilestoneOrders>
                </FutureInfo>
              </GazeBottom>
            </GazeSection>
          );
        }}
      </Stickyroll>
    </GazeIntoFutureContainer>
  );
};

const OrderRow = ({ orders, activeMilestone }) => (
  <div style={{ overflowY: "scroll", padding: "5px", borderRadius: "3px" }}>
    <MenuContainer>
      {orders.map(menuItem => (
        <MenuCardSmall
          key={menuItem.meal_id + activeMilestone + menuItem.quantity}
          menuItem={menuItem}
        />
      ))}
    </MenuContainer>
  </div>
);

const MenuCardSmall = ({ menuItem }) => (
  <MenuBox>
    <img
      src={getImageUrlFromMealId(menuItem.meal_id)}
      alt="meal"
      style={{ width: "190px", height: "125px" }}
      css={css`
        @media (max-width: 1000px) {
          width: 100px !important;
          height: 70px !important;
        }
        @media (max-height: 600px) {
          width: 80px !important;
          height: 50px !important;
        }
      `}
    />
    <div
      style={{
        alignSelf: "flex-start",
        margin: "20px",
        height: "50px"
      }}
    >
      <H6
        style={{ color: blueberry, fontSize: "11pt" }}
        css={css`
          @media (max-height: 600px) {
            font-size: 9pt !important;
          }
        `}
      >
        Stage {menuItem.stage}
      </H6>
      <H6
        style={{
          color: blackberry,
          fontSize: "11pt"
        }}
        css={css`
          @media (max-height: 600px) {
            font-size: 8pt !important;
            max-width: 40px;
          }
          @media (max-width: 1100px) {
            max-width: 40px;
          }
        `}
      >
        {menuItem.meal_name}
      </H6>
    </div>
  </MenuBox>
);

const GazeSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: ${({ width }) => (width < 1000 ? "calc(100% + 30px)" : "100%")};
`;
const MenuBox = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  box-shadow: 0px 0px 4px #eeeeee;
`;
const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-height: 600px) {
    margin-bottom: 50px;
  }
`;
const SelectorArrow = styled.div`
  border: 1px solid ${({ selected }) => (selected ? blueberry : "none")};
  width: ${({ selected }) => (selected ? "100%" : "0%")};
  margin-left: 20px;
  transition: width 0.5s;
  z-index: 5;
  @media (max-width: 1000px) {
    width: ${({ selected }) => (selected ? "100%" : "0%")};
    margin-left: 0;
    transition: width 0.5s;
  }
`;

const SelectorActionArea = styled.div`
  display: flex;
  align-items: center;
  width: 120%;

  @media (max-width: 1000px) {
    width: 139%;
  }
`;

const SelectorDot = styled.div`
  width: ${({ selected }) => (selected ? "20px" : `18px`)};
  z-index: 5;
  height: 12px;
  background: ${blueberry};
  border-radius: 50%;
  display: ${({ selected }) => (selected ? `inline-block` : `none`)};

  @media (max-width: 1100px) {
    height: 4px;
    align-self: center;
    width: 8px;
  }
`;

const SelectorColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  flex-wrap: wrap;
`;

const Header = styled.div`
  min-height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const MilestoneOrders = styled.div`
  overflow: hidden;
`;
const GazeIntoFutureContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1300px;
  justify-content: center;
`;
const GazeTop = styled.div`
  width: 60%;
  align-self: center;
  text-align: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-content: center;
    width: 50%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-content: center;
    width: 80%;
  }
`;

const GazeBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-end;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  @media (max-width: 800px) {
    max-width: 100vw;
  }
`;

const FutureSelector = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  @media (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 600px) {
    width: 80%;
    align-items: center;
    justify-content: flex-start;
  }
  @media (max-height: 600px) {
    align-items: center;
    justify-content: center;
  }
`;
const FutureInfo = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  @media (max-width: 1100px) {
    width: 55%;

    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 80%;

    justify-content: center;
  }
`;
