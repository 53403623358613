export const isValidJSON = string => {
  try {
    JSON.parse(string);
    return true;
  } catch (_) {
    return false;
  }
};

export default isValidJSON;
