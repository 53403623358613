import React from "react";
import "./styles/styles.css";

function ModalForm({ onChangeAddress }) {
  return (
    <div className="modal_form_container">
      <div className="modal_form_row">
        <div className="modal_form_input_container">
          <div className="modal_form_input_header">Address</div>
          <input
            onChange={e => onChangeAddress("street", e.target.value)}
            className="modal_form_input"
          />
        </div>
      </div>
      <div className="modal_form_row">
        <div className="modal_form_input_container">
          <div className="modal_form_input_header">Apt</div>
          <input
            onChange={e => onChangeAddress("street2", e.target.value)}
            className="modal_form_input"
          />
        </div>
        <div className="modal_form_input_container">
          <div className="modal_form_input_header">City</div>
          <input
            onChange={e => onChangeAddress("city", e.target.value)}
            className="modal_form_input"
          />
        </div>
      </div>
      <div className="modal_form_row">
        <div className="modal_form_input_container">
          <div className="modal_form_input_header">State</div>
          <input
            onChange={e => onChangeAddress("state", e.target.value)}
            className="modal_form_input"
          />
        </div>
        <div className="modal_form_input_container">
          <div className="modal_form_input_header">Zip</div>
          <input
            onChange={e => onChangeAddress("postcode", e.target.value)}
            className="modal_form_input"
          />
        </div>
      </div>
    </div>
  );
}

export default ModalForm;
