import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { Container, yumiOrange, H6 } from "../../../styledcomponents";
import { H2, Body } from "../../../styledcomponents/typefaces";
import { Balanced, Paired2Perf, Over100, RangeOfTextures } from "../assets";
import { LazyImage } from "../../../styledcomponents/LazyImage";

import "styled-components/macro";
import { BackArrow } from "../../GiftCard/assets";

const sections = [
  {
    image: Balanced,
    mobileHeight: "215px",
    name: "Balanced Fructose to Fiber",
    body:
      "Fiber helps slow down the absorption of sugar, which is why we balance our blends with fibrous veggies and proteins. In the short term, you'll notice fewer energy dips, which makes for a less fussy baby. In the long term, you're setting them up to reach for veggies over candy."
  },
  {
    image: Paired2Perf,

    mobileHeight: "190px",
    name: "Nutrients Paired to Absorption",
    body:
      "Your babe's tummy is like a tiny tank and it only has so much room to take in the nutrition it needs, so we aim to use the space efficiently. By pairing nutrients together we ensure maximum absorption."
  },
  {
    image: Over100,

    mobileHeight: "300px",
    name: "Over 100+ Ingredients",
    body:
      "Studies show that early exposure to a wide variety of texture, tastes and vegetables can reduce fussiness and instill a love of healthy food later in life. That's why we introduce your babe to over 100+ ingredients, including superfoods and spices from around the world."
  },
  {
    image: RangeOfTextures,

    mobileHeight: "250px",
    name: "Range of Textures",
    body:
      "We learned early on that it takes time to get used to solid foods. Babies go from super smooth liquids to chunky, grainy, silky to everything in between in a matter of weeks. That's why we stage our blends, to help you help your little one adjust."
  }
];

export const SolidsAndScience = ({ width }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [direction, setDirection] = useState(0);

  const scroller = useRef(null);

  const handleTabClick = () => {
    if (direction === 0 && activeIndex + 1 >= sections.length) {
      setDirection(1);
    }
    if (direction === 1 && activeIndex <= 0) {
      setDirection(0);
      setActiveIndex(old => old + 1);
      scroller.current.scroll({
        left: scrollLeft + 80,
        behavior: "smooth"
      });
    }
    if (direction === 0) {
      activeIndex + 1 < sections.length && setActiveIndex(old => old + 1);
      scroller.current.scroll({
        left: scrollLeft + 80,
        behavior: "smooth"
      });
    }
    if (direction === 1) {
      activeIndex - 1 >= 0 && setActiveIndex(old => old - 1);
      scroller.current.scroll({
        left: scrollLeft - 80,
        behavior: "smooth"
      });
    }
  };
  return (
    <SolidContainer>
      <H2
        css={css`
          color: ${yumiOrange};
          padding-bottom: 45px;
          margin-top: -20px;

          @media (max-width: 800px) {
            font-size: 30pt;
            text-align: center;
          }
        `}
      >
        We've got solids down to a science
      </H2>
      <ScrollFrame>
        <TabRow
          ref={scroller}
          onScroll={e =>
            width < 500 &&
            handleScroll(e, setActiveIndex, setScrollLeft, setDirection)
          }
        >
          {sections.map((section, i) => (
            <Tab
              onClick={() => {
                setActiveIndex(i);
              }}
              active={i === activeIndex}
            >
              <TabH6>{section.name}</TabH6>
            </Tab>
          ))}
          {width < 500 && (
            <div
              css={css`
                position: absolute;
                right: ${direction === 0 ? "10px" : "unset"};
                left: ${direction === 1 ? "10px" : "unset"};
                background: ${yumiOrange};
                opacity: 0.7;
                padding: 20px;
                border-radius: 5px;
                transition: all 0.1s ease-in;
                &:hover {
                  opacity: 1;
                  cursor: pointer;
                }
              `}
              onClick={() => handleTabClick()}
            >
              <img
                src={BackArrow}
                alt="arrow"
                css={css`
                  width: 10px;
                  transition: all 0.1s ease-in;

                  transform: ${direction === 0
                    ? "rotate(180deg)"
                    : "rotate(0deg)"};
                `}
              />
            </div>
          )}
        </TabRow>
      </ScrollFrame>
      <InnerGrid>
        <div />
        <Left>
          {width > 500 && (
            <H6
              css={css`
                color: #c47084;
                font-size: 23.5px;
                @media (max-width: 1200px) {
                  font-size: 23.5px;
                }
              `}
            >
              {sections[activeIndex].name}
            </H6>
          )}
          <Body
            css={css`
              padding-top: 10px;

              @media (max-width: 500px) {
                justify-self: center;
              }
            `}
          >
            {sections[activeIndex].body}
          </Body>
        </Left>
        <Right active={sections[activeIndex]}>
          <LazyImage
            src={sections[activeIndex].image}
            alt="img"
            css={css`
              width: 450px;

              @media (max-width: 500px) {
                width: 330px;
                height: ${sections[activeIndex].mobileHeight};
                padding-top: 5px;
              }
            `}
          />
        </Right>
        <div />
      </InnerGrid>
    </SolidContainer>
  );
};

const handleScroll = (e, setActiveIndex, setScrollLeft, setDirection) => {
  e.preventDefault();

  if (e.target.scrollLeft > 200) {
    setDirection(1);
  }
  if (e.target.scrollLeft < 60) {
    setDirection(0);
  }

  setScrollLeft(e.target.scrollLeft);
  const index = Number((e.target.scrollLeft / 75).toFixed(0));

  setScrollLeft(e.target.scrollLeft);
  if (index >= 0 && index < 4) {
    setActiveIndex(index);
  }
};

const ScrollFrame = styled.div`
  width: 100%;
  min-width: 100vw;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const InnerGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 0.4fr 0.7fr 1fr 0.4fr;
  grid-template-rows: 400px;
  align-items: center;
  padding-top: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: 0.2fr 0.8fr 1fr 0.2fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 0.2fr 0.8fr 1fr 0.2fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 99vw;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    margin-top: 30px;

    background: white;
    box-shadow: 1px 1px 4px gray;
    padding-top: 15px;
    border-radius: 4px;
  }
`;

const Right = styled.div`
  padding-left: 40px;
  @media (max-width: 500px) {
    height: ${({ active }) => active && active.mobileHeight};
    order: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-left: 0px;
  }
`;
const Left = styled.div`
  @media (max-width: 500px) {
    order: 2;
    width: 90%;
    display: flex;
    justify-content: center;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

const TabRow = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;

  @media (max-width: 500px) {
    justify-content: flex-start;
    overflow-x: none;
    width: 100%;
    border-bottom: 0px solid ${yumiOrange};
    -webkit-overflow-scrolling: touch;
    list-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: scroll;
  }
`;
const TabH6 = styled(H6)`
  color: ${yumiOrange};
  width: 80%;
  min-height: 70px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  text-align: center;

  @media (max-width: 500px) {
    font-size: 17px;
  }
`;
const Tab = styled.div`
  cusor: pointer;
  display: flex;
  justify-content: center;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  border-bottom: ${({ active }) =>
    active ? `3px solid ${yumiOrange}` : "none"};

  @media (max-width: 500px) {
    min-width: 40vw;
  }
`;

const SolidContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;

  justify-content: center;
  z-index: 501;

  @media (max-width: 500px) {
    padding-bottom: 70px;
  }
`;
