import React from "react";
import styled, { css } from "styled-components";
import { blackberry } from "../../../styledcomponents/colors";
import { Cloud } from "../assets";
import { BlueButton, InputField } from "../../../styledcomponents";
import "styled-components/macro";

import { H3 } from "../../../styledcomponents/typefaces";


export const FutureCollabs = () => (
  <FutureCollabsContainer>
    <CloudRow>
      <img
        src={Cloud}
        alt="cloud"
        style={{
          width: "60px",
          marginTop: "40px"
        }}
      />
      <H3
        style={{
          opacity: "none",
          padding: "20px",
          order: "0",
          color: blackberry
        }}
      >
        Get the Scoop on Future Collabs
      </H3>
      <img
        src={Cloud}
        alt="cloud"
        style={{
          width: "80px",
          marginTop: "-70px"
        }}
      />
    </CloudRow>
    <CloudForm>
      <iframe
        title="klaviyo email"
        src="https://email.helloyumi.com/assets/chef-series/julia-sherman/klaviyo.html"
        css="border: none; width:100%; padding: 0; margin-bottom: 0; height: 90px; z-index:10;"
      />
    </CloudForm>
  </FutureCollabsContainer>
);

const CloudForm = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    width: 300px !important;
  }
`;
const CloudRow = styled.div`
  display: flex;
  width: 800px;
  align-items: center;
  justify-content: center;
  height: 150px;
  @media (max-width: 1000px) {
    width: 400px !important;
  }
`;

const FutureCollabsContainer = styled.div`
  background: #8db9db;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    width: 400px !important;
  }
`;
