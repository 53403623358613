import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Box } from "./layout";
import { H3 } from "./text";

const Jar = `https://s3-us-west-1.amazonaws.com/email.helloyumi.com/assets/Jar.svg`;

export const Plan = ({
  color,
  jarCount,
  selected,
  style,
  select,
  margin,
  planHeader,
  smaller = false,
  ...props
}) => (
  <PlanBox
    color={color}
    style={{
      ...style
    }}
    margin={margin}
    selected={selected}
    onClick={select}
    {...props}
  >
    {planHeader}
    <JarContainer count={jarCount / 7}>
      {_.times(jarCount / 7, _.identity).map(val => (
        <JarImage
          smaller={smaller}
          key={jarCount + val}
          src={
            props.planId > 200
              ? "https://email.helloyumi.com/assets/SnackJar.svg"
              : Jar
          }
          alt="Single Jar"
        />
      ))}
    </JarContainer>
    <PlanBoxInner>
      <PlanText smaller={smaller}>{jarCount / 7}</PlanText>
      <MealPerDayText>
        {jarCount / 7 > 1 ? "meals per day" : "meal per day"}
      </MealPerDayText>
    </PlanBoxInner>
  </PlanBox>
);

const PlanBoxInner = styled(Box)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: ${({ smaller }) => (smaller ? "100px" : "120px")}
  display: flex;
  margin-left: 20px;

  @media (max-width: 1050px) {
    align-items: center;
    margin-left: unset;
  }
`;

const PlanBox = styled.button`
  cursor: pointer;
  display: flex;
  ${props => (props.nowrap ? "flex-wrap: nowrap;" : "flex-wrap: wrap;")}
  ${props => (props.row ? "justify-content: center;" : "align-items:center;")}
  background-color: ${props => props.color};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 240px;
  outline: ${props => (props.selected ? "3px solid #334897" : "unset")};
  box-sizing: "border-box";
  margin-right: ${props => `${props.margin}px`};
  @media (max-width: 1050px){
    margin-right: ${props => `${props.margin / 2}px`};
    height: 15em;
    width: 40vw;
    margin-bottom: 2em;
    flex-direction: row;
  }
  @media (max-width: 500px) {
    margin-right: 10px;
    margin-left: 10px;
    height: 15em;
    width: 80vw;
    margin-bottom: 1em;
    flex-direction: row;
  }
`;

const PlanText = styled(H3)`
  font-size: ${({ smaller }) => (smaller ? "20px" : "45px")};
  font-family: "Platform-Medium";
  @media (max-width: 1000px) {
    font-size: 32px;
  }
`;

const JarContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row-reverse wrap-reverse;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 1050px) {
    margin-right: 2px;
    width: 30%;
    margin-left: unset;
  }
  @media (max-width: 500px) {
    margin-top: 0;
  }
`;

const JarImage = styled.img`
  height: 56px;
  width: ${({ smaller }) => (smaller ? "20px" : "35px")};
  margin-right: 5px;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    height: 56px;
    width: 35px;
    margin-bottom: 0;
  }
`;

const MealPerDayText = styled(H3)`
  width: 81px;
  font-size: 20px;
  text-align: left;
  font-family: "Platform-Medium";
  @media (max-width: 1000px) {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
`;

export default Plan;
