import React from "react";
import styled, { css } from "styled-components";
import {
  H6,
  pepper,
  oat,
  blackberry,
  Body1,
  H4,
  BlueButton
} from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";

export const QuadSection = ({ name, text, quads }) => (
  <div
    style={{
      paddingTop: "40px",
      paddingBottom: "60px"
    }}
  >
    <div>
      <H4 style={{ color: blackberry }}>{name}</H4>
      <Body1 style={{ fontSize: "14pt", width: "65%", marginTop: "10px" }}>
        {text}
      </Body1>
    </div>
    <div style={{ display: "flex", marginTop: "20px" }}>
      {quads.map(quad => <QuadBox quad={quad} />)}
    </div>
  </div>
);

export const QuadBox = ({ quad }) => (
  <QuadBoxContainer>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Body>{quad.name}</Body>
      <H6 style={{ fontSize: "9pt", color: pepper }}>Learn more</H6>
      <AddButton>+</AddButton>
    </div>
  </QuadBoxContainer>
);

const AddButton = styled(BlueButton)`
  background: ${pepper};
  height: 70px;
  color: white;
  border-radius: 60px;
  box-shadow: 1px 1px 2px #eee;
  transition: all 0.2s;

  &:hover {
    box-shadow: 1px 2px 10px gray;
  }
`;

const QuadBoxContainer = styled.div`
  background: ${oat};
  padding: 50px;
  display: flex;
  justify-content: space-around;

  box-shadow: 1px 5px 5px #eee;
  margin-left: 10px;
  min-height: 400px;
  max-width: 500px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 1px 8px 8px #eee;
  }
`;
