import React from "react";
import _ from "lodash";
import { Sections, ReviewsCarousel, yumiOrange } from "../../styledcomponents";
import {
  useWindowSize,
  useMergeState,
  updateLocalStorage,
  getCouponFromQueryString
} from "../../hooks";
import { isValidJSON } from "../../utils/isValidJSON";
import {
  FutureIsFresh,
  FavoriteFoods,
  GrowingMinds,
  AlwaysFresh,
  SolidsAndScience,
  MealtimeSolved,
  SuperfoodForSuperbabies,
  WeHaveFingerFoods,
  GetTheScoop,
  CrowdPleaser
} from "./sections";
import useReload from "../../hooks/useReload";

const getFieldFromLocalStorage = (field, defaultValue) =>
  isValidJSON(window.localStorage.newCheckout2)
    ? _.get(JSON.parse(window.localStorage.newCheckout2), field, defaultValue)
    : defaultValue;

export const HomePageRefresh = ({ location }) => {
  const { width } = useWindowSize();
  useReload()
  const [couponState, setCouponState] = useMergeState(
    getFieldFromLocalStorage("couponState", {
      couponString: "",
      couponData: {}
    })
  );

  updateLocalStorage({
    couponState
  });

  getCouponFromQueryString({ couponState, location, setCouponState });

  return (
    <Sections>
      <FutureIsFresh width={width} />
      <FavoriteFoods width={width} />
      <GrowingMinds width={width} />
      <CrowdPleaser width={width} />
      <AlwaysFresh width={width} />
      <SolidsAndScience width={width} />
      <MealtimeSolved width={width} />
      <SuperfoodForSuperbabies width={width} />
      <WeHaveFingerFoods width={width} />
      <ReviewsCarousel color={yumiOrange} />
      <GetTheScoop width={width} />
    </Sections>
  );
};

//
