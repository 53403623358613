import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { InnerGridTemplate } from "../assets";
import { H3, Body } from "../../../styledcomponents/typefaces";
import { yumiGreen } from "../../../styledcomponents/colors";

export const RealFood = () => (
  <RealFoodContainer>
    <InnerGridTemplate
      gridRows="306px"
      gridMobileRows="0px"
      leftStyling={css`
        @media (max-width: 1000px) {
          padding-left: 20px;
          padding-top: 40px;
        }
      `}
      leftChildren={(
        <React.Fragment>
          <H3
            css={css`
              font-size: 40px;
              width: 92%;
              line-height: 1.1em;

              @media (max-width: 1300px) {
                font-size: 29px;
              }
            `}
          >
            Real food is the best food. <br /> Chefs know it, and we know it.
          </H3>
        </React.Fragment>
)}
      rightStyling={css`
        width: 100%;
        @media (max-width: 1000px) {
          padding-left: 20px;
          padding-top: 20px;
          padding-bottom: 40px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <Body
            css="font-size:27px; @media (max-width: 1300px) {
                font-size: 22px;
              }"
          >
            We want to show you that baby food should not only be real, but it
            should also be really delicious.
          </Body>
        </React.Fragment>
)}
    />
  </RealFoodContainer>
);

const RealFoodContainer = styled.div`
  width: 100%;
  background: #b5c7aa;
`;
