import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { EduardoLaugh, InnerGridTemplate } from "../assets";
import { Body } from "../../../styledcomponents/typefaces";

export const Quote = () => (
  <QuoteContainer>
    <InnerGridTemplate
      gridDimensions="0.3fr 1fr 1fr 0.3fr"
      gridRows="500px"
      gridMobileRows="1fr 400px"
      leftStyling={css`
        @media (max-width: 1000px) {
          display: flex;
          justify-content: center;
        }
      `}
      leftChildren={(
        <React.Fragment>
          <img
            src={EduardoLaugh}
            css="width:483px;max-height:350px; object-fit:cover; @media(max-width:500px){object-fit:contain;}"
            alt="bunch"
          />
        </React.Fragment>
)}
      rightStyling={css`
        width: 100%;
        @media (max-width: 1400px) {
          padding-left: 100px;
        }
        @media (max-width: 1200px) {
          padding-left: 50px;
        }
        @media (max-width: 1000px) {
          padding-top: 30px;
          padding-left: 30px;
          padding-bottom: 30px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <Body
            css="width:87%; font-size:21px; font-style:italic;
            @media (max-width: 1000px) {
          width:100%;
          font-size:18px;
          padding-bottom:15px;
            }
            @media (max-width: 500px) {
            width:90%;
            font-size:18px;
            padding-bottom:15px;
            }
          "
          >
            “As a Chef and a father, I understand the importance of providing
            children with a balanced, healthy diet from a young age. It's also
            important for me to teach my own son the foundations of food
            literacy and nutrition, so he can teach the next generation the
            importance of healthy living.”
          </Body>
          <Body
            css="font-size:18px; padding-top:10px;
            @media (max-width: 1000px) {
            
            padding-bottom:30px;
            }
          "
          >
            - Edouardo Jordan
          </Body>
        </React.Fragment>
)}
    />
  </QuoteContainer>
);

const QuoteContainer = styled.div`
  width: 100%;
  background: #b5c7aa;
`;
