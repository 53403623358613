import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ProfileIcon from "../../../assets/icons/Dashboard-icons/profile-icon.svg";
import OrdersIcon from "../../../assets/icons/Dashboard-icons/orders-icon.svg";
import YumiIcon from "../../../assets/icons/yumi-u-icon.svg";
import YumiLogo from "../../../assets/icons/Yumi_logo.svg";

const checkActive = (match) => (_, location) =>
  location.pathname.toLowerCase().includes(match);
export const MainNavBar = () => (
  <MainNav>
    <NavLink to="/">
      <Logo>
        <img src={YumiLogo} alt="The yumi logo" width="100px" />
      </Logo>
    </NavLink>
    <NavItems>
      <MainNavItem isActive={checkActive("not yet!")} to="">
        <Icon
          style={{ marginBottom: "6px", marginTop: "6px" }}
          src={YumiIcon}
          alt="home"
          height="12px"
        />{" "}
        Home
      </MainNavItem>
      <MainNavItem isActive={checkActive("orders")} to="/orders/">
        <Icon src={OrdersIcon} alt="orders" height="24px" />
        Orders
      </MainNavItem>
      <MainNavItem isActive={checkActive("profile")} to="/profile">
        <Icon src={ProfileIcon} alt="profile" height="24px" />
        Profile
      </MainNavItem>
    </NavItems>
  </MainNav>
);
const Logo = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;
const MainNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 50px;
  align-items: center;
  height: 54px;
  padding-bottom: 4px;
  width: 100%;
  @media (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    top: auto;
    padding-right: 0px;
    padding-left: 0px;
    background: white;
    width: 100%;
    justify-content: center;
    z-index: 100;
  }
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const MainNavItem = styled(NavLink)`
  font-family: "Platform-medium", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  margin-left: 16px;
  opacity: 0.4;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    opacity: 1;
  }

  @media (max-width: 1000px) {
    text-align: center;
    width: 33%;
    font-size: 12px;
    font-family: Avenir;
    text-transform: capitalize;
    margin-left: 0px;
  }
`;
const Icon = styled.img`
  display: block;
  margin: auto;
  @media (min-width: 1000px) {
    display: none;
  }
`;
export default MainNavBar;
