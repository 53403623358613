import React, { useReducer, useEffect } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  useSyncProgressWithLocalStorage,
  getProgressFromLocalStorage
} from "./effects";
import { Berry, PinkThing, PinkThingStem, BackArrow } from "./assets";
import { GiftCardFlowContext } from "./GiftCardFlowContext";
import { StepOne, StepTwo, StepThree, StepFour, StepFive } from "./steps";
import { H6, yumiLavender } from "../../styledcomponents";
import { useWindowSize } from "../../hooks";

export const createGiftReducer = (state, action) => {
  switch (action.type) {
    case "COMPLETE_STEP_ONE": {
      return {
        ...state,
        digital: action.digital,
        stage: 2
      };
    }
    case "COMPLETE_STEP_TWO": {
      return {
        ...state,
        amount: action.amount,
        coupon: action.coupon,
        stage: 3
      };
    }
    case "COMPLETE_STEP_THREE": {
      return {
        ...state,
        message: action.message,
        recepient_name: action.recepient_name,
        coupon: action.coupon,
        recepient_email: action.recepient_email,
        stage: 4
      };
    }
    case "COMPLETE_STEP_FOUR": {
      return {
        ...state,
        stage: 5,
        recepient_email: action.recepient_email,
        gift_code: action.gift_code
      };
    }
    default: {
      throw Error("Unknown action");
    }
  }
};

const getInitialReducerState = () => {
  const localStorageProgress = getProgressFromLocalStorage();

  return { ...localStorageProgress };
};

export const GiftCard = ({ match, history, location, user }) => {
  const { width } = useWindowSize();
  const [progress, dispatch] = useReducer(
    createGiftReducer,
    getInitialReducerState()
  );
  useSyncProgressWithLocalStorage(progress);

  useEffect(() => {
    window.location.replace("https://helloyumi.com/gift-card/1");
  }, [])

  return (
    <GiftCardContainer>
      <GiftCardFlowContext.Provider
        value={{ ...progress, dispatch, progress, history }}
      >

        <div css="@media(max-width:500px){display:grid; grid-template-columns: 120px 190px; justify-content:center; align-items:center; max-height:50px;}">
          {width < 500 &&
            (!location.pathname.includes("1") &&
            !location.pathname.includes("5") ? (
              <img
                css="cursor:pointer; "
                onClick={() => {
                  history.goBack();
                }}
                src={BackArrow}
                alt="arrow"
              />
            ) : (
              "​​"
            ))}
          {!location.pathname.includes("4") && (
            <SmallLavenderHeader>GIFT YUMI</SmallLavenderHeader>
          )}
        </div>
        <Switch>
          <Route
            path="/gift-card/1"
            render={() => <StepTwo location={location} />}
          />
          <Route path="/gift-card/2" render={() => <StepThree />} />
          <Route path="/gift-card/3" render={() => <StepFour user={user} />} />
          <Route path="/gift-card/4" render={() => <StepFive />} />

          <Route
            path="*"
            render={() =>
              location.search ? (
                <Redirect to={`/gift-card/1${location.search}`} />
              ) : (
                <Redirect to="/gift-card/1" />
              )
            }
          />
        </Switch>
      </GiftCardFlowContext.Provider>
    </GiftCardContainer>
  );
};

const SmallLavenderHeader = styled(H6)`
  color: ${yumiLavender};
  font-size: 18px;
  padding-top: 40px;
  @media (max-width: 500px) {
    padding-top: 0px;
  }
`;
const GiftCardContainer = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: Center;
  overflow: hidden;
  min-height: 99vh;
`;
