import React from "react";
import styled, { css } from "styled-components";
import { Container, yumiOrange } from "../../../styledcomponents";
import "styled-components/macro";

import {
  LittleCarrot,
  LittleTomato,
  Berry,
  Banana
} from "../../OneOffPurchase/assets";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { Avo, OrangeLinkButton, Berries, Blueberry } from "../assets";

export const AlwaysFresh = ({ width }) => (
  <FreshContainer>
    <H1
      css={css`
        @media (min-width: 1598px) {
          width: 75%;
        }
        font-size: 200px;
        color: ${yumiOrange};
        position: relative;
        line-height: 1em;
        text-align: center;

        @media (max-width: 1000px) {
          font-size: 90px;
          line-height: 1.2em;
        }
        @media (max-width: 500px) {
          font-size: 90px;
          line-height: 1.2em;
        }
      `}
    >
      Made fresh weekly
      <img
        src={LittleCarrot}
        alt="carrot"
        css={css`
          position: absolute;
          margin-top: -238px;
          margin-left: -873px;
          @media (max-width: 1000px) {
            margin-left: -800px;
            margin-top: -70px;
            width: 100px;
            transform: rotate(30deg);
          }
          @media (max-width: 720px) {
            margin-top: -140px;
            margin-left: -390px;
            width: 70px;
          }
          @media (max-width: 500px) {
            margin-left: -310px;
            width: 100px;
            margin-top: -200px;
            transform: rotate(0deg);
          }
        `}
      />
      <img
        src={LittleTomato}
        alt="tomate"
        css={css`
          position: absolute;
          width: 100px;
          margin-left: -504px;
          margin-top: -175px;
          @media (max-width: 1000px) {
            margin-left: -595px;
            width: 40px;
            margin-top: 25px;
          }
          @media (max-width: 720px) {
            margin-top: -85px;
            margin-left: -230px;
            width: 40px;
          }
          @media (max-width: 500px) {
            margin-left: -135px;
            margin-top: -195px;
            width: 45px;
          }
        `}
      />
      <img
        src={Berry}
        alt="berry"
        css={css`
          position: absolute;
          margin-top: -110px;
          margin-left: 170px;
          @media (max-width: 1000px) {
            margin-top: 30px;
            margin-left: 0px;
            width: 40px;
          }
          @media (max-width: 720px) {
            margin-top: 30px;
            margin-left: 0px;
            width: 40px;
          }
          @media (max-width: 500px) {
            margin-left: -10px;
            margin-top: 40px;
            width: 40px;
          }
        `}
      />
      <img
        src={Berries}
        alt="berry"
        css="position:absolute;display:none; margin-top:-110px;margin-left:170px;
        @media (max-width: 1000px) {
        margin-left:150px;
        width:50px;
  
        }
        @media (max-width: 500px) {
        left:0;
        position:absolute;
        margin-left:0px;
        margin-top: -150px;
        display:flex;
        }
        "
      />
      <img
        src={Blueberry}
        alt="berry"
        css="position:absolute; display:none;margin-top:-110px;margin-left:170px;
        @media (max-width: 1000px) {
        margin-left:150px;
       
        }
        @media (max-width: 500px) {
        margin-right:0px;
        margin-left:0px;
        margin-top:-250px;
        display:flex;
        right:10px;
        }
        @media (max-width: 400px) {
        margin-right:0px;
        margin-left:0px;
        margin-top:-210px;
        display:flex;
        right:10px;
        }
        "
      />
      <img
        src={Banana}
        alt="ban"
        css={css`
          position: absolute;
          margin-top: 80px;
          margin-left: -750px;
          @media (max-width: 1000px) {
            margin-left: -760px;
            width: 60px;
          }
          @media (max-width: 720px) {
            margin-top: -35px;
            margin-left: -380px;
            width: 60px;
          }
          @media (max-width: 500px) {
            margin-left: -300px;
            margin-top: 80px;
            width: 50px;
          }
        `}
      />
    </H1>
    <div css="display:flex;flex-direction:column;align-items:center;text-align:center; width:75%; ">
      <Body css="padding-bottom:30px;padding-top:80px;font-size:30px; @media(max-width:1000px){font-size:25px;}@media(max-width:800px){font-size:18px;}">
        Your baby’s tastebuds form in the first 2 years. The science is clear:
        the food choices you make now will help your child make better food
        choices later. That’s why we introduce them to over 100+ real, nutrient
        dense foods. It’s simple: real food is fresh food.
      </Body>
      <Body css="padding-bottom:60px;font-size:30px; @media(max-width:1000px){font-size:25px;}@media(max-width:800px){font-size:17px;}">
        No Synthetics. Never Fortified. No Additives. No Concentrates.
      </Body>

      <OrangeLinkButton to="/about" value="LEARN MORE" />
    </div>
  </FreshContainer>
);

const FreshContainer = styled.div`
  background: #faf8f6;
  height: 1100px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;

  width: 100%;

  @media (max-width: 1000px) {
    height: 705px;
  }
  @media (max-width: 500px) {
    height: 850px;
  }
`;
