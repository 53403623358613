import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Brain, Uma } from "../assets";
import { oatAdjusted } from "../../../styledcomponents/colors";
import { Body, H1 } from "../../../styledcomponents/typefaces";
import { H6, Body1 } from "../../../styledcomponents";

export const MedicalAdvisors = () => (
  <PageLayout>
    <img
      src={Brain}
      alt="brain"
      style={{ paddingTop: "120px", paddingBottom: "35px" }}
      css={css`
        @media (max-width: 1000px) {
          width: 50px !important;
          padding-top: 50px !important;
        }
      `}
    />
    <H1
      style={{ color: "#673E21", fontSize: "45pt" }}
      css={css`
        @media (max-width: 1000px) {
          font-size: 26pt !important;
        }
      `}
    >
      Medical Advisors
    </H1>
    <Body
      style={{
        fontSize: "20pt",
        width: "45%",
        textAlign: "center",
        paddingTop: "35px",
        paddingBottom: "56px"
      }}
      css={css`
        @media (max-width: 1000px) {
          width: 80% !important;
          font-size: 14pt !important;
        }
      `}
    >
      Our medical advisors, comprised of nutritionists and nutrition-obsessed
      pediatricians, guide our food philosophy, help us design your meals and
      keep us updated on the latest research.
    </Body>
    <MedicalAdvisorCard>
      <MedicalAdvisorImage src={Uma} alt="Uma" />
      <H6
        style={{
          color: "#673E21",
          paddingBottom: "13px",
          paddingTop: "24px",
          fontSize: "18pt"
        }}
        css={css`
          @media (max-width: 1000px) {
            font-size: 14pt !important;
          }
        `}
      >
        Dr. Uma Pisharody
      </H6>
      <Body1
        style={{ textAlign: "center", width: "80%", fontSize: "14pt" }}
        css={css`
          @media (max-width: 1000px) {
            font-size: 11pt !important;
          }
        `}
      >
        Dr. Pisharody is a pediatric gastroenterologist at the Swedish Medical
        Center in Seattle, and was the first doctor to successfully advocate for
        the removal of juice from her hospital's menu.
      </Body1>
    </MedicalAdvisorCard>
  </PageLayout>
);

const MedicalAdvisorImage = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 80px;
  margin-top: 45px;
  @media (max-width: 1000px) {
    width: 100px;
    height: 100px;
  }
`;
const MedicalAdvisorCard = styled.div`
  width: 800px;
  background: ${oatAdjusted};
  display: flex;
  height: 400px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  @media (max-width: 1000px) {
    width: 330px !important;
    height: 380px !important;
  }
`;
const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
  padding-bottom: 200px;

  @media (max-width: 1000px) {
    padding-bottom: 50px !important;
  }
`;
