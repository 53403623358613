import * as types from "../actions/types";

const authReducer = (
  state = {
    isLoginPending: false,
    isLoginSuccess: false,
    loginError: null,
    isRegisterPending: false,
    isRegisterError: null,
    isRegisterSuccess: false,
    sessionExpired: false
  },
  action
) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess
      };

    case types.LOGIN_PENDING:
      return {
        ...state,
        isLoginPending: action.isLoginPending
      };

    case types.SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.sessionExpired
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoginError: action.isLoginError
      };

    case types.REGISTER_PENDING:
      return {
        ...state,
        isRegisterPending: action.isRegisterPending
      };

    case types.REGISTER_ERROR:
      return {
        ...state,
        isRegisterError: action.isRegisterError
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterSuccess: action.isRegisterSuccess
      };

    default:
      return state;
  }
};

export default authReducer;
