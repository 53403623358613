import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Page } from "../components/styledComponents";
import Tiger from "../images/tiger.jpg";
import BitesInfo from "../images/bites_info_photo_1.jpg";
import HandPuff from "../images/hand_puff_bowl.jpg";
import BitesCarousel from "../components/BitesCarousel";

export const InfoPage = ({ user }) => (
  <Page style={{ marginBottom: "200px" }}>
    <Content>
      <PreviewImage src={BitesInfo} alt="cubano" />
      <TextContent>
        <Overline>INTRODUCING THE TOT BOX</Overline>
        <TitleSix>Nutrient dense bites for eager independent eaters.</TitleSix>
      </TextContent>
      <img src={Tiger} alt="tiger treat" width="100%" />
      <TextContent2>
        <Overline>MEALS</Overline>
        <InnerContent>
          <TitleFive>
            Food before one is not just for fun –– it's also <em>really</em>{" "}
            fun.
          </TitleFive>
          <BodyContent>
            We adapted a few recipes from our favorite blends into handheld
            bites for babes eager to start self-feeding and parents eager to
            keep providing nutrient dense foods. Serve them solo or as a meal
            with your favorite sides.
          </BodyContent>
        </InnerContent>
      </TextContent2>
      <img src={HandPuff} alt="hand puff" width="100%" />
      <TextContent2>
        <Overline>MUNCHIES</Overline>
        <InnerContent>
          <TitleFive>
            Just Beet It.{" "}
            <span
              style={{
                fontFamily: "'avenir-light', sans-serif",
                opacity: ".60"
              }}
            >
              Sorghum is the new rice.{" "}
            </span>
          </TitleFive>
          <BodyContent>
            We use beets because they're a great source of fiber, folate and
            potassium. And sorghum flour, a gluten-free ancient grain, because
            it has more protein than other grains such as rice (which is also
            high in arsenic) and it's also a great source of iron.
          </BodyContent>
        </InnerContent>
      </TextContent2>
      <BoosterPackContainer>
        <Overline>WHAT YOU GET</Overline>
        <BoosterPackTitle>The Tot Box</BoosterPackTitle>

        <BitesCarousel />
      </BoosterPackContainer>

      <FAQContainer>
        <FAQTitle>FAQ</FAQTitle>

        <FAQContent>
          <FAQ>
            <Question>How do I know if my baby is ready?</Question>

            <Answer>
              {" "}
              Every baby grows at their own pace, but here are a few ways to
              tell if your babe is ready to try finger foods. Does your baby
              have teeth? Are they showing interest in your food? Are they eager
              to self- feed? Has your baby tried any finger foods? If you
              responded yes to two or more of these questions, your babe might
              be ready to try our Tot Box. Since this box isn’t a weekly
              subscription you can add it to your order for $15, risk free!{" "}
            </Answer>
          </FAQ>
          <hr
            style={{ borderWidth: "1px", opacity: ".12", marginTop: "14px" }}
            width="100%"
          />

          <FAQ>
            <Question>Are your snacks safe?</Question>
            <Answer>
              Just like our blends, Yumi snacks are salt-free, gluten-free,
              allergen-free and plant-based. <br />
              Additionally, many snacks for babies use rice, which is high in
              arsenic. You can read more about heavy metals found in baby
              products here. We use sorghum flour instead of rice, to help our
              families have a safer and cleaner alternative. Our bites are made
              with fresh, organic produce, spices and grains.
            </Answer>
          </FAQ>
          <hr
            style={{ borderWidth: "1px", opacity: ".12", marginTop: "14px" }}
            width="100%"
          />
          <FAQ>
            <Question> How long do the bites stay good for?</Question>
            <Answer>
              Our bites come frozen and should be placed in the freezer upon
              arrival. They’ll stay fresh in the freezer for up to 4 months.
            </Answer>
          </FAQ>
          <hr
            style={{ borderWidth: "1px", opacity: ".12", marginTop: "14px" }}
            width="100%"
          />
          <FAQ>
            <Question> How often will I get a tot box? </Question>
            <Answer>
              We don’t offer subscriptions for the tot box — yet. We wanted to
              give you the chance to test out finger foods with your babe,
              before adding it to your subscription. You’ll only receive a Tot
              Box the weeks you add it to your order.
            </Answer>
          </FAQ>
          <hr
            style={{ borderWidth: "1px", opacity: ".12", marginTop: "14px" }}
            width="100%"
          />
        </FAQContent>
      </FAQContainer>
    </Content>

    <ButtonBar>
      <AddToOrder to="/bites/checkout">ADD TO ORDER</AddToOrder>
    </ButtonBar>
  </Page>
);

const Answer = styled.p`
  opacity: 0.6;
`;

const FAQTitle = styled.div`
  font-size: 33.57px;
  font-family: Avenir;
  font-weight: 500;
  text-align: center;
  width: 100%;

  @media (max-width: 1000px) {
    font-size: 22.29px;
    text-align: left;
    letter-spacing: 0.25px;
  }
`;

const FAQContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 200px;
  width: 500px;
  @media (max-width: 1000px) {
    margin-top: 70px;
    width: 100%;
    padding: 0px 32px;
  }
`;
const FAQ = styled.details`
  font-family: "Avenir", sans-serif;
  width: 100%;
  margin-top: 16px;
`;
const Question = styled.summary`
  width: 100%;
  font-weight: 500;
`;

const BoosterPackContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 1000px) {
  }
`;

const BoosterPackTitle = styled.span`
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  line-height: 1.15;
  text-align: center;
  font-size: 34px;
  @media (max-width: 1000px) {
    font-size: 22.29px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const BoosterPackSubTitle = styled.span`
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: "black";
  opacity: 0.6;
  margin-top: 20px;
  font-size: 22px;
`;
const PreviewImage = styled.img`
  width: 100%;
  margin-bottom: 100px;
  max-width: 900px;
  @media (max-width: 1000px) {
    margin-bottom: 56px;
  }
`;
const TextContent = styled.section`
  max-width: 900px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  @media (max-width: 1000px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const TextContent2 = styled.section`
  max-width: 900px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 170px auto 170px auto;
  @media (max-width: 1000px) {
    padding-left: 32px;
    padding-right: 32px;
    margin: 55px auto 74px auto;
  }
`;

const FAQContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Overline = styled.span`
  color: #334897;
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1;
`;

const TitleSix = styled.h6`
  color: black;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  font-size: 54px;
  line-height: 1.15;
  letter-spacing: 0.25px;
  margin: 0;
  margin-bottom: 100px;
  margin-top: 10px;
  @media (max-width: 1000px) {
    margin-bottom: 76px;
    font-size: 22px;
  }
`;

const TitleFive = styled.h6`
  color: black;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  font-size: 33.577px;
  line-height: 1.15;
  letter-spacing: 0.25px;
  margin: 0;
  margin-bottom: 100px;
  margin-top: 10px;
  width: 40%;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
    font-size: 22.29px;
    width: 100%;
  }
`;

const BodyContent = styled.div`
  color: black;
  font-family: "Avenir", sans-serif;
  font-weight: 400;
  font-size: 22.29px;
  line-height: 1.5;
  width: 50%;
  margin-left: 10%;
  @media (max-width: 1000px) {
    width: 100%;
    margin: 0;
    font-size: 15.8px;
  }
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const AddToOrder = styled(Link)`
  color: white;
  text-decoration: none;
  font-family: "Avenir", sans-serif;
  font-weight: 500;
  background: #334897;
  font-size: 16px;
  padding: 16px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
    background: #334598;
  }
  @media (max-width: 1000px) {
    padding: 16px;
  }
  letter-spacing: 2px;
`;

const Description = styled.div`
  font-family: "Avenir", sans-serif;
  font-size: 16px;
`;

const Content = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 54px;
  top: auto;
  z-index: 10000;
  padding: 8px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  @media (min-width: 1000px) {
    bottom: 0px;
    background: transparent;
  }
`;
