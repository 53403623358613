import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { CopyToClipboard } from "react-copy-to-clipboard";
import spacetime from "spacetime";
import { Button, Modal, H2, Image } from "@team-yumi/ui";

import {
  getReferralLink,
  getSurveySource,
  postSurveySource,
} from "../../usecases";
import { Body } from "../../styledcomponents/typefaces";
import { useRequest, useFormState } from "../../hooks";
import {
  Form,
  InputField,
  H6,
  BlueButton,
  BasicInput,
  HyperLink,
  Body2,
  H1,
  yumiOrange,
} from "../../styledcomponents";
import { confirmUser } from "../../usecases/checkout";
import { getUser } from "../../usecases/users";
import { getNextDeliveryDate } from "../../utils/dates";
import requireAuth from "../../utils/requireAuth";

const Email = "https://email.helloyumi.com/assets/_Referral/Referral_Email.svg";
const Message =
  "https://email.helloyumi.com/assets/_Referral/Referral_Message.svg";
const FaceBook = "https://email.helloyumi.com/assets/_Referral/Referral_FB.svg";
const Twitter =
  "https://email.helloyumi.com/assets/_Referral/Referral_Twitter.svg";
const MixedJar =
  "https://email.helloyumi.com/assets/_HomePageRefresh/MixedJar.svg";
const SwirlJar =
  "https://email.helloyumi.com/assets/_HomePageRefresh/SwirlJar.svg";
const PlainJar =
  "https://email.helloyumi.com/assets/_HomePageRefresh/PlainJar.svg";
const HalfKiwi =
  "https://email.helloyumi.com/assets/_HomePageRefresh/HalfKiwi.svg";
const Carrot = "https://email.helloyumi.com/assets/_HomePageRefresh/Carrot.svg";

const Edit = "https://email.helloyumi.com/assets/_HomePageRefresh/Edit.png";
const NewColors =
  "https://email.helloyumi.com/assets/_HomePageRefresh/Color_Boxes.gif";
const Jars = "https://email.helloyumi.com/assets/_HomePageRefresh/Jars.png";

export const Confirmation = ({ user, history }) => {
  const [form, setField] = useFormState({
    password: "",
    confirmPassword: "",
    referredBy: "",
    babyName: "",
  });

  const [surveyVisible, setSurveyVisible] = useState(true);

  const [source, setSource] = useState("");
  const [textareaSource, setTextareaSource] = useState("");
  const [surveyList, loadingSurvey] = useRequest(getSurveySource);

  const handleOptionChange = (e) => {
    setSource(e.target.value);
    setTextareaSource("");
  };

  const handleTextAreaChange = (e) => {
    setTextareaSource(e.target.value);
    setSource("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await postSurveySource(source || textareaSource).catch((sourceError) =>
      console.log("Error")
    );
    setSurveyVisible(false);
  };

  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
  const [error, setError] = useState("");
  const [stepOne, setStepOne] = useState(true);
  const [bodyContent, setBody] = useState("Thank you for your order!");

  const [copied, setCopied] = useState(false);
  const [textBox, setTextBox] = useState("");

  useEffect(() => {
    if (history.location.search === "?type=gift") {
      setShouldShowConfirmation(true);
      setBody("Thank you for your order!");
    }
  }, []);

  const [referralLink, loadingReferral] = useRequest(getReferralLink);
  const { link } = referralLink;

  const oneOffUser = history.location.state ? history.location.state.user : "";

  const [userData, loading, fetchError] = useRequest(() => getUser(user.id));
  if (loading || loadingReferral || loadingSurvey) return "loading...";
  if (!oneOffUser && fetchError)
    return "Something went wrong. Try to refresh the page and try again.";

  const selectedUserData = userData || oneOffUser;

  const plan = {
    "bento-five-weekly": "7 Jars + 1 Free",
    "bento-ten-weekly": "14 Jars + 2 Free",
    "bento-twenty-weekly": "21 jars + 3 Free",
  };

  const nextDate = getNextDeliveryDate();
  const formattedNext = spacetime(nextDate).format(`{day}, {month} {date-pad}`);

  const oneOffLabel =
    history.location &&
    history.location.state &&
    history.location.state.selectedPlan &&
    history.location.state.selectedPlan.label;

  const next = [
    {
      image: Jars,
      title: `${formattedNext}`,
      body:
        bodyContent ||
        (selectedUserData.subscription.stripe_plan
          ? `Your plan: ${plan[selectedUserData.subscription.stripe_plan]}`
          : `Your One-Off Purchase: ${oneOffLabel}`),
    },
    {
      image: NewColors,
      title: "New Week, New Color",
      body:
        "We make our food fresh every week. Our trays and jar labels change colors to help you identify and toss old jars in the fridge.",
    },
    {
      image: Edit,
      title: "Skip or edit future orders",
      body:
        "Just be sure to make any changes before our weekly cut-off time, Tuesdays at 11:59pm PT.",
    },
  ];

  const child_id = _.get(selectedUserData, "children[0].child_id", "");

  const preFormSubmit = () => {
    if (form.password !== form.confirmPassword)
      return setError("Your passwords have to be the same.");

    setStepOne(false);
  };
  const formSubmit = async () => {
    await confirmUser(
      history.location.state !== undefined ? oneOffUser.id : user.id,
      form.babyName,
      child_id,
      form.password,
      form.referredBy
    );
    history.location.state !== undefined
      ? history.push({
          pathname: "/one-off-confirmation",
          state: {
            user_id: oneOffUser.id,
            selectedPlan: history.location.state.selectedPlan,
            selectedQuantity: history.location.state.selectedQuantity,
          },
        })
      : history.push("/dashboard/orders/current");
    window.localStorage.newCheckout2 = "";
    window.localStorage.newCheckout3 = "";
  };

  requireAuth.deleteCookie("user_id");
  requireAuth.deleteCookie("userId");

  return (
    <ConfirmPage css="background: #fff9f0;">
      <img
        src={Carrot}
        alt="carrot"
        css={css`
          position: absolute;
          left: 0;

          @media (max-width: 1000px) {
            width: 200px;
          }
          @media (max-width: 500px) {
            width: 120px;
          }
        `}
      />
      <LastStepsSection>
        {stepOne ? (
          <React.Fragment>
            <Form onSubmit={preFormSubmit} css="padding-top:100px;">
              <H6
                css="font-size:40px; margin-bottom: 0.2em; color:#F1A68C;@media (max-width: 1000px) {
                font-size: 30px;
              }"
              >
                Welcome {selectedUserData.name}!
              </H6>
              <H1
                css={css`
                  font-size: 100px;
                  color: ${yumiOrange};

                  @media (max-width: 1000px) {
                    font-size: 60px;
                  }
                `}
              >
                One last step
              </H1>
              <TextGrid>
                <PasswordBox>
                  <InputField
                    type="password"
                    label="Password"
                    autocomplete="new-password"
                    required
                    width="350px"
                    orange
                    value={form.password}
                    id="securePass"
                    placeholder="Pick something secure."
                    onChange={setField("password")}
                    css={css`
                      @media (max-width: 500px) {
                        width: 220px;
                      }
                    `}
                  />
                  <div css="width:10px" />
                  <InputField
                    width="350px"
                    type="password"
                    autocomplete="new-password"
                    label="Confirm password"
                    required
                    orange
                    id="securePassRepeat"
                    value={form.confirmPassword}
                    onChange={setField("confirmPassword")}
                    placeholder="And type it again."
                    css={css`
                      @media (max-width: 500px) {
                        width: 220px;
                      }
                    `}
                  />
                </PasswordBox>
                <NameAndReferred>
                  <InputField
                    type="text"
                    orange
                    width="350px"
                    value={form.babyName}
                    label="Your baby's name"
                    id="babyName"
                    required
                    onChange={setField("babyName")}
                    css={css`
                      @media (max-width: 500px) {
                        width: 220px;
                      }
                    `}
                  />
                  <div css="width:10px" />
                  <InputField
                    type="text"
                    orange
                    width="350px"
                    id="referredBy"
                    value={form.referredBy}
                    label="Referred by"
                    placeholder=""
                    onChange={setField("referredBy")}
                    css={css`
                      @media (max-width: 500px) {
                        width: 220px;
                      }
                    `}
                  />
                </NameAndReferred>
                <Body2 css="color: red;">{error}</Body2>
              </TextGrid>

              <div
                css="
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px;
            overflow: hidden;
          "
              >
                <BlueButton
                  type="submit"
                  id="submitPass"
                  value="CREATE ACCOUNT"
                  css={css`
                    color: white;
                    padding: 20px;
                    width: 200px;
                    background: ${yumiOrange};
                  `}
                />
              </div>
            </Form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Form onSubmit={formSubmit} css="padding-top:100px;">
              <H6
                css="font-size:40px; margin-bottom: 0.2em; color:#F1A68C;@media (max-width: 1000px) {
                font-size: 30px;
              }"
              >
                Success!
              </H6>
              <H1
                css={css`
                  font-size: 60px;
                  color: ${yumiOrange};

                  @media (max-width: 1000px) {
                    font-size: 40px;
                  }
                `}
              >
                What's Next?
              </H1>
              <WhatsNextSection>
                {next.map((nextItem) => (
                  <NextCol
                    css="display:flex;flex-direction:column;align-items:center;"
                    key={nextItem.title}
                  >
                    <img src={nextItem.image} alt="img" css="width:200px;" />
                    <H6
                      css={css`
                        color: ${yumiOrange};
                        width: 70%;
                        padding-top: 20px;
                        text-align: center;
                      `}
                    >
                      {nextItem.title}
                    </H6>
                    <Body
                      css={css`
                        width: 70%;

                        padding-top: 20px;
                        text-align: center;
                        font-size: 17px;
                        line-height: 1.3em;
                      `}
                    >
                      {nextItem.body}
                    </Body>
                  </NextCol>
                ))}
              </WhatsNextSection>
              <BlueButton
                type="submit"
                id="submitPass"
                value="LOGIN"
                css={css`
                  color: white;
                  padding: 20px;
                  width: 200px;
                  background: ${yumiOrange};
                `}
              />
            </Form>
          </React.Fragment>
        )}
      </LastStepsSection>
      <img
        src={HalfKiwi}
        alt="kiwi"
        css={css`
          position: absolute;
          right: 0;
          transform: rotate(180deg);
          top: 30%;

          @media (max-width: 500px) {
            width: 50px !important;
          }
        `}
      />
      <ReferralSection>
        <JarRow>
          <img
            src={SwirlJar}
            alt="jar"
            css="width:55px; position:absolute;z-index:1;margin-left:-30px;"
          />

          <img
            src={MixedJar}
            alt="jar"
            css="width:65px; margin-top:13px;z-index:2;"
          />
          <img
            src={PlainJar}
            alt="jar"
            css="width:55px; margin-right:20px;z-index:1;position:absolute;margin-left:40px;"
          />
        </JarRow>
        <Section style={{ padding: "30px" }}>
          <H6
            css={css`
              color: ${yumiOrange};
              font-size: 40px;
              padding-bottom: 20px;
            `}
          >
            Gift 5 jars, get 3 jars
          </H6>
          <Body
            css={css`
              padding-bottom: 50px;
            `}
          >
            Earn 3 jar credits ($15 value) for referring a friend. Plus, they’ll
            get 5 jars, on us. Win win.
          </Body>
          <BasicInput
            value={link}
            style={{ padding: "25px" }}
            onChange={(e) => setTextBox(e.target.value)}
          />
          <div css="display:flex; width:360px; @media(max-width:600px){width:237px}">
            <CopyToClipboard text={link}>
              <BlueButton
                style={{
                  background: yumiOrange,
                  color: "white",
                  padding: "23px",
                  marginTop: "10px",
                  width: "60%",
                }}
                value="COPY LINK"
                onClick={() => setCopied(true)}
              />
            </CopyToClipboard>
            <HyperLink
              style={{
                background: yumiOrange,
                color: "white",
                padding: "23px",
                marginTop: "10px",
                width: "20%",
                marginLeft: "8px",
                display: "flex",
                justifyContent: "center",
              }}
              href={`sms: &body=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%207%20jars%20free%20when%20you%20use%20this%20link!%20${link}`}
            >
              <img src={Message} alt="msg" style={{ color: "white" }} />
            </HyperLink>
            <HyperLink
              style={{
                background: yumiOrange,
                color: "white",
                padding: "23px",
                marginTop: "10px",
                width: "20%",
                marginLeft: "8px",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
              }}
              href={`mailto: yourfriend@example.com?body=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%207%20jars%20free%20when%20you%20use%20this%20link!%20${link}`}
            >
              <img src={Email} alt="email" style={{ color: "white" }} />
            </HyperLink>
          </div>
          <Body style={{ padding: "15px", fontSize: "12pt" }}>
            <div css="display:flex; width:360px; justify-content:center; @media(max-width:600px){width:237px}">
              Or share via
              <HyperLink
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
              >
                <img
                  src={FaceBook}
                  alt="fb"
                  style={{ marginLeft: "12px", color: "white" }}
                />
              </HyperLink>
              <HyperLink
                href={`http://twitter.com/share?text=Fresh,%20organic%20and%20nutrient%20dense%20baby%20food%20delivered%20straight%20to%20your%20door%20every%20week.%20You’ll%20get%207%20jars%20free%20when%20you%20use%20this%20link!&url=${link}`}
              >
                <img
                  src={Twitter}
                  alt="twit"
                  style={{ marginLeft: "12px", color: "white" }}
                />
              </HyperLink>
            </div>
          </Body>
        </Section>
      </ReferralSection>
      <Modal visible={surveyVisible}>
        <Container onSubmit={handleSubmit}>
          <CloseButton
            src="close.svg"
            onClick={() => setSurveyVisible(false)}
          />

          <StyledSubtitle>How did you hear about Yumi</StyledSubtitle>

          <Options>
            {surveyList.sources.map((item) => (
              <div key={item}>
                <input
                  type="radio"
                  name="referral"
                  id={item}
                  value={item}
                  checked={source === item}
                  onChange={handleOptionChange}
                />
                <label style={{ marginLeft: "10px" }} htmlFor={item}>
                  {item}
                </label>
              </div>
            ))}
          </Options>

          <textarea
            placeholder="Other"
            value={textareaSource}
            onChange={handleTextAreaChange}
          />
          <Button
            mt="20px"
            type="submit"
            disabled={source === "" && textareaSource === ""}
          >
            SUBMIT
          </Button>
        </Container>
      </Modal>
    </ConfirmPage>
  );
};

const WhatsNextSection = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 400px;
  padding-top: 60px;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-template-rows: 410px 460px 460px;
  }
`;
const NextCol = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;
const JarRow = styled.div`
  display: flex;
  padding-top: 70px;
`;
const PasswordBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr 1fr;
  grid-template-rows: 100px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;

    grid-template-rows: 1fr 0.1fr 1fr;
  }
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

const NameAndReferred = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr 1fr;
  grid-template-rows: 100px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;

    grid-template-rows: 1fr 0.1fr 1fr;
  }
`;
const TextGrid = styled.div`
  width: 60%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-top: 50px;
`;
const ConfirmPage = styled.div`
  width: 100%;
  grid-template-rows: 1fr 0.5fr;
  grid-template-columns: 100%;

  @media (max-width: 500px) {
    grid-template-rows: 1fr 0.5fr;
    align-items: center;
    justify-content: center;
  }
`;
const LastStepsSection = styled.div`
  width: 100%;
  align-self: center;
  padding-bottom: 50px;

  @media (max-width: 500px) {
    padding-bottom: 40px;
  }
`;
const ReferralSection = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  align-items: center;
`;

const CloseButton = styled(Image)`
  position: absolute;
  right: 0;
  top: -38px;
  cursor: pointer;
`;

const Container = styled.form`
  width: 600px;
  overflow: visible;
  font-family: Freight-Book;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 70px;
  position: relative;

  @media (max-width: 600px) {
    width: 309px;
    padding: 40px 30px;
  }
  textarea {
    box-sizing: border-box;
    width: 360px;
    outline: none;
    resize: none;
    height: 80px;
    margin-top: 20px;
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    padding: 10px;
    @media (max-width: 600px) {
      width: 100%;
      height: 40px;
    }
  }
`;

const StyledSubtitle = styled(H2)`
  font-size: 21px;
  padding-bottom: 45px;
  margin: 0;
  text-align: center;
  font-family: Platform-Medium;
  @media (max-width: 600px) {
    padding-bottom: 20px;
  }
`;

const Options = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default Confirmation;
