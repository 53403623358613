// Login Types
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SESSION_EXPIRED = "SESSION_EXPIRED";

// Register Types
export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

// Route Types
export const ROUTE_TO_CHECKOUT = "ROUTE_TO_CHECKOUT";
export const ROUTE_TO_HOMEPAGE = "ROUTE_TO_HOMEPAGE";
export const ROUTE_TO_ACCOUNT = "ROUTE_TO_ACCOUNT";

// Cart Types
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SET_MEALS_PER_WEEK = "SET_MEALS_PER_WEEK";
export const SET_DELIVERY = "SET_DELIVERY";
export const EMPTY_CART = "EMPTY_CART";
export const SET_SPECIAL_MENU = "SET_SPECIAL_MENU";
export const REMOVE_SPECIAL_MENU = "REMOVE_SPECIAL_MENU";

// Checkout Types
export const UPDATE_CHECKOUT_INFO = "UPDATE_CHECKOUT_INFO";
export const CLEAR_CHECKOUT_INFO = "CLEAR_CHECKOUT_INFO";

// Get menus
export const GET_MENUS = "GET_MENUS";
export const RECEIVE_MENUS = "RECEIVE_MENUS";

// User Types
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CLEAR_USER = "CLEAR_USER";
