import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  texasOat,
  BlueButton,
  texasBrown,
  H6,
  Body1
} from "../../../styledcomponents";
import "styled-components/macro";
import { VarietyPack, StarterPack } from "../assets";
import { Body } from "../../../styledcomponents/typefaces";
import { useWindowSize } from "../../../hooks";

const Logo = `https://email.helloyumi.com/assets/Yumi_logo.svg`;

export const HoustonTop = () => {
  const { width } = useWindowSize();
  return (
    <TopContainer>
      <WeHaveBabyFood>
        {width > 500 && <StarterPackImage src={StarterPack} alt="starter" />}
        <HoustonIntro>
          <img
            src={Logo}
            css="width:130px; @media(max-width:500px){width:100px}"
            alt="logos"
          />
          <HoustonH1>Houston, we have baby food.</HoustonH1>
          <Body
            css="padding-top:20px; @media (max-width: 800px) {
    width: 85%;
  }"
          >
            Meet Yumi, a fresh, nutrient dense, organic alternative to
            store-bought babyfood.
          </Body>
          <Link css="margin-top: 20px;text-decoration:none" to="/checkout">
            <HoustonButton value="Join Now and Get $60 Off" />
          </Link>
        </HoustonIntro>
        <VarietyPackImage src={VarietyPack} alt="variety" />
      </WeHaveBabyFood>
      {width < 500 && (
        <YouKnowFood>
          <StarterPackImage src={StarterPack} alt="starter" />
          <ContentArea>
            <HoustonH1 css="font-size:20pt !important">
              You know good food and soon your babe will too.
            </HoustonH1>
            <Body1
              style={{ fontSize: "10pt", paddingTop: "10px", width: "100%" }}
            >
              We know Houston is ranked one of the top, most diverse food cities
              in the country. We also know that your babies taste buds form in
              the first 2 years, which is why we introduce your baby to over 100
              ingredients including superfoods and spices from around the world.
              Get ready to taste the world, baby.
            </Body1>
            <Link css="text-decoration:none; margin-top: 20px;" to="/menu">
              <HoustonButton value="Explore our Blends" />
            </Link>
          </ContentArea>
        </YouKnowFood>
      )}
    </TopContainer>
  );
};

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 230px;
  margin-left: -100px;
  padding-left: 15px;
  padding-top: 15px;
`;
const HoustonIntro = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  margin-left: -140px;
  padding-top: 240px;

  @media (max-width: 500px) {
    padding-left: 30px;
    padding-top: 100px;
    z-index: 90;
    margin-left: 0px;
    min-width: 250px;
  }
`;
const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  box-shadow: 0px 2px 4px gray;
  padding: 20px;
  font-size: 14pt;
  width: 300px;
  margin-top: 20px;
  @media (max-width: 500px) {
    font-size: 11pt;
    width: 200px;
    margin-top: 0px;

    padding: 15px;
  }
`;
const HoustonH1 = styled(H6)`
  font-size: 42pt;
  color: ${texasOrange};
  padding-top: 25px;

  @media (max-width: 800px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    font-size: 35pt;
    width: 100%;
    padding-top: 15px;
  }
`;

const WeHaveBabyFood = styled.div`
  display: flex;
  width: 100%;
`;

const YouKnowFood = styled.div`
  display: flex;
  z-index: 90;
  padding-top: 140px;
  padding-left: 20px;
  padding-bottom: 80px;
`;

const TopContainer = styled.div`
  width: 100%;
  background: ${texasOat};
`;

const StarterPackImage = styled.img`
  width: 700px;
  margin-top: -125px;
  margin-left: -170px;

  max-height: 700px;

  @media (max-width: 500px) {
    width: 330px;
    max-height: 330px;
    margin-top: -180px;
    margin-left: -140px;
  }
`;

const VarietyPackImage = styled.img`
  width: 700px;
  justify-self: flex-end;
  margin-left: 0px;
  @media (max-width: 500px) {
    width: 330px;
    margin-top: -70px;
    margin-left: -150px;
    max-height: 360px;
  }
`;
