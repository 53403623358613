import _ from "lodash";
import queryString from "query-string";

const defaultCoupon = { amount_off: 0, percent_off: 0 };
const defaultCouponState = { couponString: "", couponData: {} };
export const couponDiscount = (total, coupon = defaultCoupon) => {
  if (coupon.amount_off > 0) {
    return coupon.amount_off / 100;
  }
  if (coupon.percent_off) {
    return (coupon.percent_off / 100) * total;
  }
  return 0;
};

export const getCouponText = (coupon = defaultCoupon) =>
  coupon.percent_off
    ? `${coupon.percent_off}% off`
    : `$${coupon.amount_off / 100} off`;

export const referralDiscount = (total, referral) => {
  if (referral.amount_off > 0) {
    return referral.amount_off / 100;
  }
  if (referral.percent_off) {
    return (referral.percent_off / 100) * total;
  }
  return 0;
};

export const getReferralText = referral => `$${referral.amount_off / 100} off`;

export const setCouponFromUrl = location => {
  const couponStringSearch = _.get(
    queryString.parse(location.search),
    "coupon",
    _.get(defaultCouponState, "couponString", "")
  );

  const couponStringHash = _.get(
    queryString.parse(location.hash),
    "coupon",
    _.get(defaultCouponState, "couponString", "")
  );

  const couponString =
    couponStringSearch.length > 0 ? couponStringSearch : couponStringHash;
  const couponState = { couponString, couponData: {} };
  const oldState = () => {
    try {
      return JSON.parse(window.localStorage.newCheckout2);
    } catch (e) {
      return {};
    }
  };

  const newState = {
    ...oldState(),
    couponState
  };

  window.localStorage.newCheckout2 = JSON.stringify(newState);
};
