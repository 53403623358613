import React from "react";
import styled from "styled-components";
import {
  GrowBabyGrow,
  ParentOptions,
  GazeIntoFuture,
  FirstThousand,
  CheckOurSources,
  GrowTogether,
} from "./sections";

export const Milestones = () => (
  <MilestonePage>
    <GrowBabyGrow />
    <ParentOptions />
    <GazeIntoFuture />
    <FirstThousand />
    <CheckOurSources />
    <GrowTogether />
  </MilestonePage>
);

export const MilestonePage = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: visible;
`;
