import React from "react";
import styled, { css } from "styled-components";
import {
  BlueButton,
  yumiOrange,
  oatCreamy,
  blue,
  blueberry,
  yumiGreen
} from "../../styledcomponents";
import { Body } from "../../styledcomponents/typefaces";
import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_EduardoJordan/${name}${extension}`;

// images
export const CukeBackground = getImageURL("CukeBackground", ".png");
export const CarrotChop = getImageURL("EduardoCarrot", ".jpeg");
export const EduardoChopping = getImageURL("Salare", ".jpeg");
export const EduardoBunch = getImageURL("EduardoBunch", ".jpeg");
export const EduardoLaugh = getImageURL("EduardoCrackingUp", ".jpeg");
export const EduardoStack = getImageURL("EduardoStack", ".jpeg");

export const Sorghum = getImageURL("Sorgum", ".png");
export const Ginger = getImageURL("Ginger", ".png");
export const Sumac = getImageURL("Sumac", ".png");

export const InnerGridTemplate = ({
  leftChildren,
  rightChildren,
  rightStyling = "",
  leftStyling = "",
  gridDimensions = "",
  gridMidDimensions = "",
  gridRows = "",
  gridMobileRows = ""
}) => (
  <InnerGrid
    gridDimensions={gridDimensions}
    gridMidDimensions={gridMidDimensions}
    gridMobileRows={gridMobileRows}
    gridRows={gridRows}
  >
    <div
      css="@media (max-width: 1000px) {
        order:0;
      }"
    />
    <Left css={leftStyling}>{leftChildren}</Left>
    <Right css={rightStyling}>{rightChildren}</Right>
    <div
      css="@media (max-width: 1000px) {
        order:4;
      }"
    />
  </InnerGrid>
);

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ gridDimensions }) =>
    gridDimensions || "0.5fr 1fr 1fr 0.5fr"};
  grid-template-rows: ${({ gridRows }) => gridRows || "300px"};
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    grid-template-columns: ${({ gridMidDimensions, gridDimensions }) =>
      gridMidDimensions || gridDimensions};
  }

  @media (max-width: 1000px) {
    grid-template-columns: ${({ gridLowerDimensions }) =>
      gridLowerDimensions && gridLowerDimensions};
  }

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: ${({ gridMobileRows }) =>
      gridMobileRows && gridMobileRows};
  }
`;
const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 100%;
`;

export const GreenButton = styled(BlueButton)`
  background: black;
  color: white;
  padding: 15px;
  width: 160px;

  font-size: 11pt;
  box-shadow: 1px 1px 2px #eee;
`;
