import styled from "styled-components";
import React from "react";
import {theme} from '@team-yumi/ui'
import { useWindowSize } from "../hooks";

export const H1 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 94.77px;
  letter-spacing: -1.5px;
  line-height: 1.125;
  ${props => props.block && "display:block;"};
`;
export const H1_D = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 52px;
  letter-spacing: -1.5px;
  line-height: 1.125;
`;
export const H1_M = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 36px;
  letter-spacing: -1.5px;
  line-height: 1.125;
`;

export const H2 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 59.23px;
  letter-spacing: -0.5px;
  line-height: 1.125;
`;

export const H2_D = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 48px;
  letter-spacing: -1.5px;
  line-height: 1.125;
`;

export const H2_M = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 33px;
  letter-spacing: -1.5px;
  line-height: 1.125;
`;

export const H3 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 47.39px;
  letter-spacing: 0px;
  line-height: 1.125;
`;

export const H3_D = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 40px;
  letter-spacing: -0.5px;
  line-height: 1.125;
`;

export const H3_M = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 1.125;
`;
export const H4 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 33.57px;
  letter-spacing: 0.25px;
  line-height: 1.125;
  ${props => props.block && "display:block;"};
`;

export const H4_D = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 28.6px;
  letter-spacing: -0.5px;
  line-height: 1.125;
`;

export const H4_M = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 1.125;
`;

export const H5 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 23.69px;
  letter-spacing: 0px;
  line-height: 1.125;
`;
export const H6 = styled.div`
  font-family: "Ginger", sans-serif;
  font-size: 19.74px;
  letter-spacing: 0.25px;
  line-height: 1.125;
`;

export const Body1 = styled.div`
  font-family: "Ginger", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  ${theme.tablet} {
    font-size: 15px;
    line-height: 21px;
  }
`;

export const Body1_D = styled.div`
  font-family: "Freight-Medium", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 400;
`;

export const Body1_M = styled.div`
  font-family: "Freight-Medium", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-weight: 400;
`;

export const Body2 = styled.div`
  font-family: "Ginger", avenir, sans-serif;
  font-size: 13px;
  letter-spacing: 0.25px;
  line-height: 1.5;
  font-weight: 400;
`;

export const Subtitle1 = styled.div`
  font-family: "Avenir-Regular", avenir, sans-serif;
  font-size: 18.06px;
  letter-spacing: 0.15px;
  line-height: 1;
`;

export const Subtitle2 = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 13.82px;
  letter-spacing: 1.25px;
  line-height: 1;
`;

export const Caption = styled.div`
  font-family: "Avenir-Regular", avenir, sans-serif;
  font-size: 13.55px;
  letter-spacing: 0.4px;
  line-height: 1.5;
`;

export const Quote_D = styled.div`
  font-family: "Freight-Medium", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 1.3;
`;

export const Quote_M = styled.div`
  font-family: "Freight-Medium", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 1.3;
`;

export const Overline = styled.div`
  font-family: "Platform-Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2;
`;

export const Overline2 = styled.div`
  font-family: "Ginger", sans-serif;
  letter-spacing: 1px;
  line-height: 1;
  font-size: 0.7406em;
  text-transform: uppercase;
`;

export const HyperLink = ({ href, children, ...props }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
    css="display: block;"
  >
    {children}
  </a>
);

export const Text = ({ desktopText, mobileText, d, m, ...props }) => {
  const { width } = useWindowSize();
  return React.createElement(
    width >= 500 ? desktopText || d : mobileText || m,
    props
  );
};
