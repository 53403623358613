import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Link } from "react-router-dom";
import { EduardoBunch, InnerGridTemplate } from "../assets";
import { H3, yumiGreen } from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";

export const HowToGet = () => (
  <HowContainer>
    <InnerGridTemplate
      gridRows="700px"
      leftChildren={(
        <React.Fragment>
          <H3
            css={css`
              font-size: 40px;
              width: 92%;
              padding-bottom: 20px;

              @media (max-width: 1000px) {
                font-size: 24px;
              }
            `}
          >
            How to get the box
          </H3>
          <Body
            css="width:75%; font-size:18px; line-height:1.5em;
            @media (max-width: 1000px) {
           width:93%;
           font-size:14px;
           line-height:1.4em;
 
            }
          "
          >
            Our Salare Chef Box will be available for a limited time starting on
            October 30th. If you’re new to the Yumi family, you can find the
            Salare Chef Box at{" "}
            <Link
              css={css`
                color: ${yumiGreen};
              `}
              to="/checkout"
            >
              checkout.
            </Link>{" "}
            If you’re currently a Yumi parent, you can add it to your next order
            by{" "}
            <Link
              to="/login"
              css={css`
                color: ${yumiGreen};
              `}
            >
              logging into
            </Link>{" "}
            your account.
          </Body>
        </React.Fragment>
)}
      leftStyling={css`
        @media (max-width: 1000px) {
          order: 2;
          width: 100%;
          padding-top: 30px;
          padding-left: 30px;
          padding-bottom: 30px;
        }
      `}
      rightStyling={css`
        width: 100%;

        @media (max-width: 1000px) {
          order: 1;
          display: flex;
          justify-content: center;
        }
      `}
      gridMobileRows="1fr 500px"
      rightChildren={(
        <React.Fragment>
          <img
            src={EduardoBunch}
            css="width:415px;max-height:600px;@media(max-width:500px){height:500px;width:100%;object-fit:contain;user-select:none;}"
            alt="bunch"
          />
        </React.Fragment>
)}
    />
  </HowContainer>
);

const HowContainer = styled.div`
  width: 100%;
  background: white;
`;
