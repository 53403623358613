import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { oat, blackberry, Body1, BlueButton } from "../../styledcomponents";

import { H2 } from "../../styledcomponents/typefaces";
import "styled-components/macro";

const ReviewsBlend =
  "https://email.helloyumi.com/assets/_GiftAFriend/GiftAFriend_Hero.png";

export const Reviews = () => (
  <ReviewsPage>
    <ReviewsTop>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <H2
          style={{ color: blackberry, fontSize: "44pt" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 25pt !important;
            }
          `}
        >
          From the Yumi Family
        </H2>
        <Body1
          style={{
            fontSize: "17pt",
            padding: "11px",
            textAlign: "center"
          }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
              width: 70% !important;
            }
          `}
        >
          The reviews are in! Parents and babes are loving Yumi.
        </Body1>
      </div>
      <img
        src={ReviewsBlend}
        style={{ width: "520px", padding: "12px", marginTop: "15px" }}
        css={css`
          @media (max-width: 1000px) {
            width: 330px !important;
            padding: none !important;
            height: 120px !important;
          }
        `}
        alt="reviews"
      />
      <Link to="/checkout" css="text-decoration:none">
        <BlueButton
          css={css`
            background: ${blackberry};
            padding: 20px;
            color: white;
            margin-top: 30px;
            justify-self: center;
            width: 200px;
          `}
          value="GET STARTED"
        />
      </Link>
    </ReviewsTop>
    <div
      style={{ width: "70%" }}
      css={css`
        @media (max-width: 1000px) {
          width: 100% !important;
        }
      `}
    >
      <div
        className="yotpo yotpo-main-widget"
        data-product-id="yotpo_site_reviews"
      />
    </div>
    {/* <ReviewsCarousel /> */}
  </ReviewsPage>
);

const ReviewsTop = styled.div`
  background: ${oat};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 380px;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 50px;
`;

export const ReviewsPage = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
