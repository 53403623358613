import React from "react";
import styled from "styled-components";
import { oatAdjusted, Body1, H6, blueberry } from "../../styledcomponents";

export const ProductCard = ({ product, index }) => (
  <ProductCardContainer index={index}>
    <img
      src={product.image}
      alt={product.image}
      style={{
        width: "200px",
        alignSelf: "center",
        borderRadius: "50%",
        marginTop: "10px"
      }}
    />
    <BlendInfo>
      <InfoBox>
        <SmallText gray>STAGE {product.stage} </SmallText>
        <StaticTitle>{product.title}</StaticTitle>
        <BlendText>{product.description}</BlendText>
      </InfoBox>
      <IngredientBox>
        <SmallText>INGREDIENTS</SmallText>
        <BlendText>{product.ingredients}</BlendText>
      </IngredientBox>
    </BlendInfo>
  </ProductCardContainer>
);

const InfoBox = styled.div``;
const IngredientBox = styled.div`
  align-self: flex-end;
`;
const BlendInfo = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;
const ProductCardContainer = styled.div`
  background: ${oatAdjusted};
  margin-left: 20px;
  height: 480px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  box-shadow: 0px 1px 3px lightgray;
`;

const BlendText = styled(Body1)`
  font-size: 11pt;
`;
const SmallText = styled(Body1)`
  font-family: Platform-Medium;
  font-size: 9pt;
  color: ${({ gray }) => (gray ? "gray" : "black")};
`;
const StaticTitle = styled(H6)`
  padding-bottom: 10px;
  width: 80%;
  color: ${blueberry};
`;
