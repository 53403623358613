import { types } from "./actions";
import trackImg from "../../../../../assets/orders/truck.svg";
import totsImg from "../../../../../assets/orders/tots.svg";
import houseImg from "../../../../../assets/orders/house.svg";
// import receiptImg from '../../../../../assets/orders/receipt.svg'

export const modalReducer = (state, action) => {
  switch (action.type) {
    case types.SKIP_ORDER: {
      return { ...SKIP_ORDER_MODAL_DATA, orderId: action.id };
    }
    case types.GET_DETAILS: {
      const order = action.order;
      if (order.shipments.length > 0) {
        return {
          ...GET_DETAILS_MODAL_DATA,
          orderId: action.id,
          date: action.date,
          planId: action.planId,
          shipments: order.shipments
        };
      }
      const noShipmentsOption = GET_DETAILS_MODAL_DATA;
      delete noShipmentsOption.buttons[1];
      return {
        ...noShipmentsOption,
        orderId: action.id,
        date: action.date,
        planId: action.planId
      };
    }
    case types.UNSKIP_ORDER: {
      return { ...UNSKIP_ORDER_MODAL_DATA, orderId: action.id };
    }
    case types.OPEN_ADD_ADDRESS_MODAL: {
      return { ...ADD_ADDRESS_MODAL_DATA, orderId: action.id };
    }
    case types.OPEN_CHANGE_ADDRESS_MODAL: {
      return {
        ...CHANGE_ADDRESS_MODAL_DATA,
        orderId: action.id,
        date: action.date
      };
    }
    case types.OPEN_SNACKS: {
      return { action: OPEN_SNACKS };
    }
    // case types.SHOW_RECEIPT_MODAL: {
    //   return { ...RECEIPT_MODAL_DATA, orderId: action.id, date: action.date, planId: action.planId }
    // }
    case types.CLOSE: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const FILLED = "FILLED";
const NOT_FILLED = "NOT_FILLED";
const WITH_ICON = "WITH_ICON";

const SKIP = "SKIP";
const EDIT = "EDIT";
const UNSKIP = "UNSKIP";
const RATE_UP = "RATE_UP";
const RATE_DOWN = "RATE_DOWN";
const CLOSE_MODAL = "CLOSE_MODAL";
const SHOW_RECEIPT = "SHOW_RECEIPT";
const TRACK_ORDER = "TRACK_ORDER";
const OPEN_CHANGE_ADDRESS = "OPEN_CHANGE_ADDRESS";
const CHANGE_ADDRESS = "CHANGE_ADDRESS";
const OPEN_ADD_ADDRESS = "OPEN_ADD_ADDRESS";
const ADD_ADDRESS = "ADD_ADDRESS";
const OPEN_SNACKS = "OPEN_SNACKS";

/*
 *  ACTIONS FLOW:
 *  - Unskip Modal:
 *  - - UNSKIP
 *  - Skip Modal:
 *  - - SKIP
 *  - Details Modal:
 *  - - SHOW_RECEIPT
 *  - - OPEN_CHANGE_ADDRESS => Change Address Modal
 *  -                           - CHANGE_ADDRESS
 *  - -                         - OPEN_ADD_ADDRESS => Add Address Modal
 *  - - TRACK_ORDER
 */
export const MODAL_BUTTON_ACTIONS = {
  SKIP,
  EDIT,
  UNSKIP,
  RATE_UP,
  RATE_DOWN,
  CLOSE_MODAL,
  SHOW_RECEIPT,
  TRACK_ORDER,
  CHANGE_ADDRESS,
  OPEN_CHANGE_ADDRESS,
  OPEN_ADD_ADDRESS,
  OPEN_SNACKS,
  ADD_ADDRESS
};

const SKIP_ORDER_MODAL_DATA = {
  title: "Skip delivery",
  details: "Are you sure you would like to skip this week's delivery?",
  buttons: [
    {
      text: "Cancel",
      action: CLOSE_MODAL,
      style: NOT_FILLED,
      img: null
    },
    {
      text: "Save",
      action: SKIP,
      style: FILLED,
      img: null
    }
  ]
};

const UNSKIP_ORDER_MODAL_DATA = {
  title: "Unskip delivery",
  details: "Are you sure you would like to unskip this week's delivery?",
  buttons: [
    {
      text: "Cancel",
      action: CLOSE_MODAL,
      style: NOT_FILLED,
      img: null
    },
    {
      text: "Save",
      action: UNSKIP,
      style: FILLED,
      img: null
    }
  ]
};

const GET_DETAILS_MODAL_DATA = {
  title: "More",
  details: "Manage more settings for the delivery of",
  buttons: [
    {
      text: "Change Address",
      action: OPEN_CHANGE_ADDRESS,
      style: WITH_ICON,
      img: houseImg
    },
    {
      text: "Track Order",
      action: TRACK_ORDER,
      style: WITH_ICON,
      img: trackImg
    },
    {
      text: "Add Tots",
      action: OPEN_SNACKS,
      style: WITH_ICON,
      img: totsImg
    }
    // {
    //   text: 'Show Receipt',
    //   action: SHOW_RECEIPT,
    //   style: WITH_ICON,
    //   img: receiptImg
    // }
  ]
};

const CHANGE_ADDRESS_MODAL_DATA = {
  title: "Change address",
  details: "Change the delivery address for the week of",
  buttons: [
    {
      text: "Add Address",
      action: OPEN_ADD_ADDRESS,
      style: NOT_FILLED,
      img: null
    }
  ]
};

const ADD_ADDRESS_MODAL_DATA = {
  title: "Add address",
  details: "",
  buttons: [
    {
      text: "Cancel",
      action: CLOSE_MODAL,
      style: NOT_FILLED,
      img: null
    },
    {
      text: "Save",
      action: ADD_ADDRESS,
      style: FILLED,
      img: null
    }
  ]
};

// const RECEIPT_MODAL_DATA = {
//   title: 'Receipt',
//   details: '',
//   buttons: [
//   ]
// }
