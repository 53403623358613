import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_About/${name}${extension}`;

export const HalfPineapple = getImageURL("About_HalfPineapple", ".svg");

export const AboutHero = getImageURL("About_Hero", ".jpg");

export const AboutFounders = getImageURL("About_Founders", ".jpg");

export const TopWave = getImageURL("About_TopWave", ".svg");

export const WePlan = getImageURL("About_WePlan", ".svg");

export const LimitThe = getImageURL("About_LimitThe", ".svg");

export const DesignedTo = getImageURL("About_DesignedTo", ".svg");

export const ScienceBased = getImageURL("About_ScienceBased", ".svg");

export const Brain = getImageURL("About_Brain", ".svg");

export const Uma = getImageURL("uma-pisharody", ".jpg");
