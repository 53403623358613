import { updateOrderAttributes } from "../../usecases/orders";

/**
 *
 * @param {Number} orderId
 * @param {Array} newItems
 */

const updateOrderService = async (orderId, cart) => {
  const attributes = cart.itemsById.reduce((a, mealId) => {
    const item = getItemFromAttributes(
      mealId,
      cart.items[mealId],
      cart.mealIdsToMenuIds[mealId]
    );
    if (item.quantity > 0) {
      a.push(item);
    }

    return a;
  }, []);

  // eslint-disable-next-line camelcase
  const special_menu_id = null;

  try {
    const update = await updateOrderAttributes(orderId, {
      attributes,
      special_menu_id
    });
    return update;
  } catch (err) {
    throw Error(`There was an issue updating order ${orderId}`, err);
  }
};

const getItemFromAttributes = (mealId, quantity, menuId) => ({
    menu_id: menuId,
    quantity,
    meal_id: mealId
  });

export default updateOrderService;
