import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { InnerGridTemplate } from "../../../styledcomponents/LandingPageSliceTemplate";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { NinetyFivePercent, YumiLogo, Carrot } from "../assets";
import { texasOrange, yumiOrange } from "../../../styledcomponents";

export const KeepThemOut = () => (
  <KeepContainer>
    <InnerGridTemplate
      gridDimensions="0.4fr 1.39fr 1fr 0.3fr"
      gridMobileRows="250px 100px"
      leftStyling={css`
        @media (max-width: 1000px) {
          order: 2;
          padding-left: 35px;
        }
      `}
      rightStyling={css`
        @media (max-width: 1000px) {
          order: 1;
        }
      `}
      leftChildren={(
        <React.Fragment>
          <img
            src={Carrot}
            alt="carrot"
            css="width:390px; position:absolute; left:0; top:30px; @media(max-width:1000px){display:none;}"
          />
          <img
            src={YumiLogo}
            alt="yumi"
            css="width:240px; padding-bottom:50px;padding-top:170px;@media(max-width:1000px){display:none;}"
          />
          <H1
            css={css`
              color: ${texasOrange};
              padding-bottom: 30px;
              font-size: 66px;
              line-height: 1em;

              @media (max-width: 1200px) {
                font-size: 45px;
                width: 75%;
              }
              @media (max-width: 1000px) {
                padding-bottom: 10px;
                font-size: 30px;
              }
            `}
          >
            The Heavy on Heavy Metals
          </H1>
          <Body
            css="line-height:1.3em; font-size:26px;@media (max-width: 1200px) {
                font-size: 25px;
              } @media(max-width:1000px){width:85%;font-size: 14px;}"
          >
            We’ve been deeply disturbed by the{" "}
            <a
              css={css`
                color: ${yumiOrange};
              `}
              href="https://www.nytimes.com/2021/02/04/health/baby-food-metals-arsenic.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              latest headlines
            </a>{" "}
            revealing concerning levels of heavy metals in popular, store-bought
            baby food brands. A{" "}
            <a
              css={css`
                color: ${yumiOrange};
              `}
              href="https://oversight.house.gov/sites/democrats.oversight.house.gov/files/2021-02-04%20ECP%20Baby%20Food%20Staff%20Report.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              2021 report
            </a>{" "}
            found that, "commercial baby foods are tainted with significant
            levels of toxic heavy metals, including arsenic, lead, cadmium, and
            mercury."
          </Body>
        </React.Fragment>
      )}
      rightChildren={(
        <React.Fragment>
          <H1
            css={css`
              font-size: 100px;
              position: absolute;
              z-index: 100;
              right: 190px;
              color: ${yumiOrange};

              @media (max-width: 1000px) {
                font-size: 65px;
                right: 100px;
                top: 270px;
              }
            `}
          >
            95%
          </H1>

          <BodySection>
            <Body
              css="font-size:35px; position:absolute; z-index:100; @media (max-width: 1000px) {
position: relative;
                font-size:15px;
                padding: 0;
    line-height: 20px;

  }"
            >
              of babyfood in the US
            </Body>
            <Body
              css="font-size:35px; position:absolute; z-index:100; padding-top:40px; @media (max-width: 1000px) {
    padding-top: 20px;
                      font-size:15px;
                      position: relative;
                      padding: 0;
    line-height: 20px;
  }"
            >
              contains heavy metals
              <span
                css={css`
                  color: ${yumiOrange};
                  text-decoration: none;
                `}
              >
                *
              </span>
            </Body>
            <Body>
              <a
                css={css`
                  color: ${yumiOrange};
                  text-decoration: none;
                  z-index: 100;
                  position: absolute;
                  font-size: 10px;
                  width: 300px;
                  margin-top: 150px;
                  @media (max-width: 1000px) {
                    position: relative;
                    padding: 0;
                    line-height: 20px;
                  }
                  @media (max-width: 500px) {
                    margin-top: 55px;
                  }
                `}
                href="https://www.healthybabyfood.org/sites/healthybabyfoods.org/files/2019-10/BabyFoodReport_FULLREPORT_ENGLISH_R5b.pdf"
              >
                *Healthy Babies Bright Futures 2019
              </a>
            </Body>
          </BodySection>

          <img
            src={NinetyFivePercent}
            alt="95"
            css="width:600px; position:absolute; right:0; padding-top: 120px; z-index:90;

            @media(max-width:1000px){width:358px;top:-30px;}
            "
          />
        </React.Fragment>
      )}
    />
  </KeepContainer>
);

const BodySection = styled.div`
  position: absolute;
  right: 60px;
  width: 300px;
  padding-top: 120px;

  @media (max-width: 1000px) {
    top: 210px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
`;
const KeepContainer = styled.div`
  width: 100%;
  outline: 2px solid red;
  height: 710px;
  overflow: hidden;
  @media (max-width: 1200px) {
    height: 650px;
  }
`;
