import styled from "styled-components";
import { BlueButton, yumiPurple } from "../../styledcomponents";
import { H2 } from "../../styledcomponents/typefaces";
import "styled-components/macro";

const getImageURL = (folder, name, extension = ".png") =>
  `https://email.helloyumi.com/assets/${folder}/${name}${extension}`;

// all pages
export const Berry = getImageURL("_OneOffPurchase", "Berry", ".svg");
export const PinkThing = getImageURL("_OneOffPurchase", "HugeThing", ".svg");
export const BackArrow = getImageURL("_GiftCard", "BackArrow", ".svg");

// page 1
export const PurpleBackground = getImageURL(
  "_GiftCard",
  "PurpleBackground",
  ".png"
);
export const PhysicalCard = getImageURL("_GiftCard", "YumiGiftCard", ".jpeg");
export const PhysicalCard2 = getImageURL("_GiftCard", "Frame", ".png");
export const PhysicalCard3 = getImageURL("_GiftCard", "PhysicalCard3", ".png");

// page 2
export const BellPepperOne = getImageURL("_GiftCard", "BellPepperOne", ".jpeg");
export const BellPepperTwo = getImageURL("_GiftCard", "BellPepperTwo", ".jpeg");
export const BellPepperThree = getImageURL(
  "_GiftCard",
  "BellPepperThree",
  ".jpeg"
);
export const BellPepperThreeAlt = getImageURL(
  "_GiftCard",
  "OtherBell1",
  ".png"
);

// page 3
export const PineappleCorner = getImageURL(
  "_GiftCard",
  "PineappleCorner",
  ".svg"
);
export const KiwiCorner = getImageURL("_GiftCard", "KiwiCorner", ".svg");
export const PinkThingStem = getImageURL("_OneOffEmail", "stem1", ".svg");
export const KiwiAndPine = getImageURL("_GiftCard", "KiwiAndPine", ".svg");

// page 4
export const Calendar = getImageURL("_OneOffPurchase", "Intro-min", ".png");
export const Hazmat = getImageURL("_OneOffPurchase", "HazmatFunny", ".jpeg");
export const Nutrition = getImageURL("_OneOffPurchase", "EmailBoi", ".png");
// bell pepper one

export const PurpleButton = styled(BlueButton)`
  color: white;
  background: ${yumiPurple};
  width: 200px;
  padding: 15px;

  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
`;

export const LargePurpleHeader = styled(H2)`
  color: ${yumiPurple};
  padding-bottom: 50px;

  @media (max-width: 500px) {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 34px;
    font-size: 22pt;
  }
`;
