import React from "react";
import styled from "styled-components";
import {
  Finally,
  BabyInCharge,
  HowItWorks,
  OnlyTheGoodStuff,
  HowDoIKnow,
  TalkOfTheTown,
  FruitBanner
} from "./sections";
import "styled-components/macro";

export const BLWpage = () => (
  <Sections>
    <Finally />
    <BabyInCharge />
    <HowItWorks />
    <OnlyTheGoodStuff />
    <HowDoIKnow />
    <TalkOfTheTown />
    <FruitBanner />
  </Sections>
);

export const Sections = styled.section`
  display: grid;
  grid-template-rows: repeat(5, 1fr) 0.5fr 0.25fr;
  grid-template-columns: 100%;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1200px) {
    grid-template-rows: 700px 700px 1300px 700px 600px 550px 200px;
  }
  @media (max-width: 1000px) {
    grid-template-rows: 400px 300px 1200px 700px 800px 800px 100px;
  }
  @media (max-width: 700px) {
    grid-template-rows: 450px 350px 1250px 700px 800px 800px 200px;
  }
  @media (max-width: 500px) {
    grid-template-rows: 520px 450px 1200px 1000px 900px 700px 90px;
  }
`;
