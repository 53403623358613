import React from "react";
import { css } from "styled-components";
import "styled-components/macro";
import {
  Page,
  Box,
  H3,
  H4,
  H5,
  H6,
  Overline,
  Subtitle2,
  Body1,
  lightPink,
  blackberry,
  HR,
  Text,
  HyperLink
} from "../../../styledcomponents";

import { useWindowSize } from "../../../hooks";
import {
  VeggieGroupMobile,
  VeggieGroupDesktop,
  Julia2,
  JuliaCutting,
  Julia6,
  Mashable,
  NYT,
  Goop,
  Cloud
} from "./assets";

const ms = style => `@media(max-width: 500px){${style}}`;

export const JuliaSherman = () => {
  const { width } = useWindowSize();
  const VeggieGroup = width > 500 ? VeggieGroupDesktop : VeggieGroupMobile;
  return (
    <Page css="max-width: 100vw;">
      <Box
        full
        name="first main div"
        css={`
          width: 100%;
          background-color: ${lightPink};
          padding: 2.5em 1.5em;
          color: ${blackberry};
        `}
      >
        <Overline
          css={`
            font-size: 15px;
            margin-bottom: 24px;
            line-height: 1;
            ${ms("display: none;")};
          `}
        >
          INTRODUCING CHEF'S TABLE: YUMI EDITITION
        </Overline>
        <H3
          css={`
            ${ms("font-size:40px")};
          `}
        >
          Baby Food
        </H3>

        <Box
          row
          name="text wrapped around the image"
          css="align-items:center;"
          nowrap
        >
          <H3
            css={`
              margin-right: 12px;
              ${ms("font-size:40px")};
            `}
          >
            for
          </H3>
          <div
            css={`
              ${ms("width: 80%;")} width:500px;
              flex: 1 2 500px;
            `}
          >
            <img src={VeggieGroup} width="100%" alt="veggie group" />
          </div>
          <Subtitle2 css="flex: 0 1 130px;">
            Designed by Salad for President's Julia Sherman
          </Subtitle2>
        </Box>
        <H3
          css={`
            ${ms("font-size:40px")};
          `}
        >
          President
        </H3>
      </Box>
      <Box
        css="align-self:center; width: 700px; max-width: 100%; margin-top:100px; align-items:flex-start;
       @media(max-width: 500px){width: 100%; padding: 1.5em; margin-top 3.75em;}"
        name="area with all the copy etc"
      >
        <H5 style={{ color: blackberry, marginBottom: "1em" }}>
          Why We Partnered with Salad for President
        </H5>
        <Body1>
          We have a major crush on Julia Sherman, the artist and mastermind
          behind Salad for President. Through her vivid site and her book by the
          same name, Julia has merged the world of art with food and
          single-handedly changed the reputation of the oft-overlooked salad. As
          people who are pretty obsessed with the beauty of real food, we love
          how Julia sees each meal as a moment to celebrate “edible confetti.”
          <br /> <br />
          She spent months tinkering with adventurous superfoods, to bring you a
          dish that is both nourishing and full of flavor and color. It’s no
          salad, but it certainly has our vote.
        </Body1>
        <div
          name="julia 2 box"
          css={css`
            ${ms("width: 100%;")} width: 700px;
            margin-top: 40px;
            margin-bottom: 40px;
          `}
        >
          <img
            src={Julia2}
            width="100%"
            alt="julia sherman in a magenta sweater"
          />
        </div>
        <Body1 css="margin-bottom: 32px">
          Julia Sherman is a photographer, writer and cook. Her blog and book by
          the same name, Salad for President celebrates delicious and beautiful
          food. The perfect blend of nutritional and artistic appeal.
        </Body1>

        <div style={{ borderLeft: `5px solid ${blackberry}` }}>
          <Body1 css="margin-left:30px; font-style: italic;">
            “As a new mother, it's so rewarding to contribute to the making of
            the next generation of passionate eaters. Yumi is a wholesome and
            thoughtful way for fellow busy moms to instill a sense of wonder
            around meal time, rest assured they are feeding their kids only the
            very best ingredients.”
          </Body1>
        </div>
        <HR css="margin-bottom: 40px; margin-top: 40px;" />
        <img
          src={JuliaCutting}
          alt="Julia Sherman cutting some fruit"
          width="100%"
        />
        <H5
          style={{ color: blackberry, marginBottom: "1em", marginTop: "40px" }}
        >
          Coconut Squash Porridge
        </H5>
        <Body1 css="margin-bottom: 32px;">
          Our "Coconut Squash Porridge" is inspired by the wholesome, fresh,
          innovative cooking style Julia is known for. The recipe is a creamy,
          comforting blend of roasted squash with a coconut + amaranth porridge
          base. As with all Yumi blends, it's nutrient-dense, low in sugar, and
          incredibly delicious -- perfect for the youngest palates in the
          family. For older kids, you could amp up the texture by tossing in
          some chopped nuts, chia seeds, shredded coconut or raspberries.
        </Body1>

        <div style={{ borderLeft: `5px solid ${blackberry}` }}>
          <Body1 css="margin-left:30px; font-style: italic;">
            “The best part of developing a Salad for President flavor purée for
            Yumi, was taste-testing the recipes myself. As a new mom, I love the
            idea of feeding my child something so delicious, they have to fight
            me for the last bite.”
          </Body1>
        </div>

        <Box
          name="Coconut squash porridge box"
          full
          css={`
            margin-top: 40px;
            background: #f5e0e2;
            padding-left: 44px;
            padding-right: 30px;
            padding-top: 56px;
            padding-bottom: 62px;
            align-items: flex-start;
            margin-bottom: 40px;
            position: relative;

            ${ms(
              "padding-top:20px; padding-bottom:37px; padding-left: 20px; padding-right: 20px;"
            )};
          `}
        >
          <img
            src={Cloud}
            alt="cloud"
            height="50px"
            css="position:absolute; z-index:1; right: 20px; top:10%; bottom:auto; left: auto;"
          />
          <img
            src={Cloud}
            alt="cloud"
            height="50px"
            css="position:absolute; z-index:0; left: 20px; top:auto; right: auto;"
          />
          <img
            src={Cloud}
            alt="cloud"
            height="50px"
            css="position:absolute; z-index:0; left: auto; bottom:10px; top:auto; right: 30%;"
          />
          <H5
            style={{
              color: blackberry,
              marginBottom: "40px",
              zIndex: "1"
            }}
          >
            Coconut Squash Porridge
          </H5>

          <Box
            name="nutritional highlights / ingredients"
            full
            row
            css="justify-content: space-between;"
            wrap
          >
            <Box
              name="highlights"
              css={`
                flex: 1 0 300px;
                margin-right: 25px;
                align-items: flex-start;

                ${ms("margin-bottom: 32px; margin-right: 0px;")};
              `}
            >
              <Subtitle2
                css={`
                  color: ${blackberry};
                  margin-bottom: 0.5em;
                  ${ms("text-transform: uppercase;")};
                `}
              >
                Nutritional highlights
              </Subtitle2>
              <Body1 css="line-height:1.125;">
                Rich in micronutrients like iron, magnesium and potassium, and
                vitamins C, E, and B6
              </Body1>
            </Box>
            <Box
              name="ingredients"
              css="flex: 1 0 300px; min-width: 72px; align-items: flex-start; z-index:10;"
            >
              <Subtitle2
                css={`
                  color: ${blackberry};
                  margin-bottom: 0.5em;
                  ${ms("text-transform: uppercase;")};
                `}
              >
                Ingredients
              </Subtitle2>
              <Body1 css="line-height:1.125;">
                Kabocha squash, sweet potato, apple amaranth, coconut milk,
                vanilla extract, moringa powder
              </Body1>
            </Box>
          </Box>
        </Box>
        <img src={Julia6} width="100%" alt="jar of coconut baby food" />
        <Box name="Talk of the town stuff" full>
          <Subtitle2 css="letter-spacing: 0.02em; margin-bottom:42px; margin-top: 56px; color: #334897; font-size: 20px;">
            {" "}
            TALK OF THE TOWN
          </Subtitle2>
          <HyperLink
            css="text-align: center;"
            href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html"
          >
            <img src={NYT} alt="New york times logo" height="25px" />
          </HyperLink>
          <HyperLink
            css="text-align: center; margin-top:1em; margin-bottom:1em;"
            href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/"
          >
            <img src={Goop} alt="Goop logo" height="25px" />
          </HyperLink>
          <HyperLink
            css="text-align: center;"
            href="https://mashable.com/article/yumi-baby-food-subscription/"
          >
            <img src={Mashable} height="25px" alt="mashable logo" />
          </HyperLink>
        </Box>
        <Box
          full
          css={`
            background: #8db9db;
            position: relative;
            padding: 60px 6em 0 6em;
            margin-top: 4em;
            ${ms("padding: 2em 1em 0em 1em;")};
          `}
        >
          <img
            src={Cloud}
            alt="cloud"
            height="50px"
            css={`
              position: absolute;
              z-index: 1;
              right: 20px;
              top: 10%;
              bottom: auto;
              left: auto;
              ${ms("display: none;")};
            `}
          />
          <img
            src={Cloud}
            alt="cloud"
            height="50px"
            css={`
              position: absolute;
              z-index: 1;
              right: auto;
              bottom: 10%;
              top: auto;
              left: 10px;
              ${ms("display: none;")};
            `}
          />
          <Text
            d={H4}
            m={Subtitle2}
            style={{
              color: blackberry,
              marginLeft: "25px",
              marginRight: "25px",
              textAlign: "center"
            }}
          >
            Get the Scoop on Future Collabs
          </Text>
          <iframe
            title="klaviyo email"
            src="https://email.helloyumi.com/assets/chef-series/julia-sherman/klaviyo.html"
            css="border: none; width:100%; padding: 0; margin-bottom: 0; height: 90px; z-index:10;"
          />
        </Box>
      </Box>
    </Page>
  );
};
