import React, { useState, useEffect } from "react";
import _ from "lodash";
import qs from "query-string";
import styled from "styled-components";

import "styled-components/macro";

import AnimateHeight from "react-animate-height";
import { getAddOnMenu } from "../../usecases";

import { useRequest } from "../../hooks";

import { getRecentOrders } from "../../usecases/users";
import requireAuth from "../../utils/requireAuth";

import Notification from "./components/Notification";
import { Survey } from "./components/Survey";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

const logo = "https://email.helloyumi.com/assets/yumi-logo-black.svg";

export const AddOn = ({ user, history }) => {
  // console.log(" cookie here", Cookies.get("username"));
  requireAuth.deleteCookie("user_id");
  requireAuth.deleteCookie("userId");
  const [addOnData, loading, req_err] = useRequest(() => getAddOnMenu());
  const [orderData, orderLoading, order_req_err] = useRequest(() =>
    getRecentOrders(user.id)
  );
  const [step, setStep] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationHeight, setNotificationHeight] = useState(0);

  const [helpHeight, setHelpHeight] = useState(0);
  const [helpNotification, setHelpNotification] = useState(false);

  const [surveyHeight, setSurveyHeight] = useState("0%");
  const qsSearch = qs.parse(window.location.search);

  const mealPlanId = _.get(orderData, "meal_plan_id");

  useEffect(
    () => {
      mealPlanId === 161 && setStep(2);
    },
    [orderData]
  );

  useEffect(
    () => {
      async function triggerNotification() {
        setHelpHeight(60);
        setTimeout(() => {
          setHelpHeight(0);
          setHelpNotification(false);
        }, 8000);
      }

      if (helpNotification && step === 3) {
        triggerNotification();
      }
    },
    [helpNotification, step]
  );

  useEffect(
    () => {
      async function triggerNotification() {
        setNotificationHeight(60);
        setTimeout(() => {
          setNotificationHeight(0);
          setShowNotification(false);
        }, 8000);
      }

      if (showNotification && step === 2) {
        triggerNotification();
      }
    },
    [showNotification, step]
  );

  useEffect(
    () => {
      async function triggerNotification() {
        setTimeout(() => {
          setSurveyHeight("100%");
        }, 3000);
      }

      if (step === 3) {
        triggerNotification();
      }
    },
    [step]
  );

  const notificationText = "Add On Purchase Successful";

  if (loading || orderLoading) {
    return "loading...";
  }

  return (
    <ConfirmContainer>
      <NavBar>
        <NavBarImage src={logo} />

        <CircleRow>
          <Circle active />
          <Circle active />
          <Circle active />
          {qsSearch && qsSearch.bundle && qsSearch.bundle === "1" ? (
            <Circle active />
          ) : null}

          {_.times(2, (index) => (
            <Circle
              key={`circ${Math.random()}`}
              onClick={() => {
                if (step + 1 === index + 1 || step + 1 >= index + 1) {
                  return setStep(index + 1);
                }
                return null;
              }}
              active={step >= index + 1 || index === 2}
            />
          ))}
        </CircleRow>
      </NavBar>
      <AnimateHeight
        duration={500}
        height={notificationHeight}
        style={{ position: "absolute", width: "100%" }}
      >
        <Notification text={notificationText} />
      </AnimateHeight>

      <AnimateHeight
        duration={500}
        height={helpHeight}
        style={{ position: "absolute", width: "100%" }}
      >
        <Notification text="Thanks for your help!" />
      </AnimateHeight>

      <AnimateHeight
        duration={500}
        height={surveyHeight}
        style={{ position: "absolute", width: "100%" }}
      >
        <Survey
          setClose={() => {
            setSurveyHeight("0%");
            setHelpNotification(true);
            setTimeout(() => {
              history.push("/dashboard/orders/current");
            }, 10000);
          }}
        />
      </AnimateHeight>
      <ConfirmBody>
        {step === 1 && (
          <Step1
            addOnData={addOnData}
            orderData={orderData}
            setStep={setStep}
            setShowNotification={setShowNotification}
            userId={user.id}
          />
        )}
        {step === 2 && (
          <Step2 userId={user.id} setStep={setStep} orderData={orderData} />
        )}
        {step === 3 && <Step3 setStep={setStep} orderData={orderData} />}
      </ConfirmBody>
    </ConfirmContainer>
  );
};

const ConfirmContainer = styled.div`
  background-color: #faf8f6;
  min-height: 100vh;
`;

const CircleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  flex-direction: row;
`;

const Circle = styled.div`
  border-radius: 50%;
  background: #ef6f35;
  width: 10px;
  height: 10px;
  border: 1.5px solid #ef6f35;
  box-sizing: border-box;
  transform: matrix(1, 0, 0, -1, 0, 0);
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#ef6f35" : "unset")};
`;

const ConfirmBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavBarImage = styled.img`
  height: 17px;
`;

const NavBar = styled.div`
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  left: 0px;
  top: 95px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

export default AddOn;
