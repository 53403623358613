import React, { useState } from "react";
import styled, { css } from "styled-components";
import { H4, Body } from "../../../styledcomponents/typefaces";
import { AddToCartButton, OrangeAddIcon } from "../assets";
import {
  oatCreamy,
  BlueButton,
  yumiOrange,
  oatAdjusted,
  blackberry
} from "../../../styledcomponents";
import "styled-components/macro";

const questions = [
  {
    question: "Can I freeze my blends?",
    answer:
      "Yes, you can freeze our blends for up to 2 months! Be sure to write the date you froze the jar on the lid and once you’re ready to serve, let the jar defrost in the fridge and be sure to serve within 24 hours of unfreezing."
  },
  {
    question: "Am I subscribing by getting the box?",
    answer:
      "Nope, you are making a 1 time purchase with Yumi. You are not subscribing to the weekly meal delivery service they offer on their site."
  },
  {
    question: `My baby is just starting to transition to solids. How can I work
        in introducing solids while I am still
              breastfeeding/bottle-feeding them`,
    answer:
      "We find that the best time to give a baby food is about 30-45 minutes before they would normally breastfeed/take a bottle. This way they are hungry, but they’re not quite at the point where they are crying because they are so hungry 🙂. Note: Try to avoid introducing your baby to foods after they’ve just breastfed/had a bottle. Since they just ate, they will likely be uninterested in trying solid foods."
  }
];

export const FAQ = ({ AddToCartButton, width }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleAnswer = () => setIsOpen(old => !old);
  return (
    <FAQContainer>
      <InnerContainer>
        <div />
        <Left>
          <H4 css="padding-bottom:35px">FREQUENTLY ASKED QUESTIONS</H4>
          {width > 800 && AddToCartButton}
        </Left>
        <Right>
          <QuestionColumn>
            {questions.map((item, i) => {
              const currentIndex = i + 1;
              return (
                <QuestionAndAnswer>
                  <QuestionRow
                    css={css`
                      padding-top: ${currentIndex > 1 && "40px"};
                    `}
                  >
                    <Question isOpen={isOpen && currentIndex === activeIndex}>
                      {item.question}
                    </Question>

                    <OrangeAddButton
                      index={currentIndex}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      toggleAnswer={toggleAnswer}
                      isOpen={isOpen}
                    />
                  </QuestionRow>
                  <Answer visible={isOpen && currentIndex === activeIndex}>
                    <Body
                      css={css`
                        width: 75%;
                        border-radius: 2px;
                        border-bottom: 1px solid ${yumiOrange};
                        transition: 0.3s all ease-in;
                        font-size: 13pt;
                        position: absolute;
                        line-height: 1.5em;
                        background: ${oatCreamy};
                        padding: 10px;

                        box-shadow: 0px 1px 2px gray;

                        @media (max-width: 800px) {
                          font-size: 11pt;
                          line-height: 1.2em;
                          width: 90%;
                        }
                      `}
                    >
                      {item.answer}
                    </Body>
                  </Answer>
                </QuestionAndAnswer>
              );
            })}
          </QuestionColumn>
        </Right>
        <div />
      </InnerContainer>
    </FAQContainer>
  );
};

const OrangeAddButton = ({
  index,
  activeIndex,
  setActiveIndex,
  isOpen,
  toggleAnswer
}) => (
  <BlueButton
    css="outline:none"
    onClick={() => {
      setActiveIndex(index);
      if (index === activeIndex || !isOpen) toggleAnswer();
    }}
  >
    <img
      src={OrangeAddIcon}
      alt="img"
      css={css`
        transform: ${isOpen && activeIndex === index && "rotate(360deg);"};
        box-shadow: ${isOpen && activeIndex === index && `0px 1px 4px gray`};
        border-radius: 50%;
        padding: 5px;
        background: ${isOpen && activeIndex === index && `${oatAdjusted}`};
        transition-timing: ease-in-out;
        transition: all 0.1s ease-in;
      `}
    />
  </BlueButton>
);

const QuestionAndAnswer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Answer = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none")};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  position: relative;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 10px;
  border-radius: 5px;
  -webkit-animation: fadein 0.1s ease-in;
  -moz-animation: fadein 0.1s ease-in;
  -ms-animation: fadein 0.1s ease-in;
  -o-animation: fadein 0.1s ease-in;
  animation: fadein 0.1s ease-in;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media (max-width: 800px) {
    width: 80%;
  }
`;

const QuestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Question = styled(Body)`
  color: ${({ isOpen }) => (isOpen ? yumiOrange : "black")};
  transition: 0.2s all ease-in;

  @media (max-width: 800px) {
    font-size: 12pt;
  }
`;

const QuestionColumn = styled.div``;
const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 0.15fr 0.8fr 1.5fr 0.15fr;

  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;
const Left = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
  }
`;
const Right = styled.div`
  width: 100%;
  padding-left: 10px;
  height: 330px;

  @media (max-width: 800px) {
    padding-left: 20px;
  }
`;
const FAQContainer = styled.div`
  width: 100%;
  height: 350px;
  doverflow: hidden;
  align-items: center;
  display: flex;
  padding-top: 60px;
  background: ${oatCreamy};

  @media (max-width: 800px) {
    padding-top: 30px;
    height: 400px;
  }
`;
