import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { blackberry, blueberry } from "../../../styledcomponents/colors";
import { H2, Body } from "../../../styledcomponents/typefaces";
import { H6, BlueButton } from "../../../styledcomponents";
import { SuperSmash, VIBBurg, SmallJar, MixedJar, Star } from "../assets";
import "styled-components/macro";

export const ByChloeBlends = () => (
  <div style={{ display: "flex" }}>
    <div
      style={{ width: "40%" }}
      css={css`
        @media (max-width: 1000px) {
          width: 0% !important;
        }
      `}
    />
    <ByChloeContainer>
      <BlendContainer>
        <BlendImage
          src={SuperSmash}
          alt="circle"
          css={css`
            @media (max-width: 1000px) {
              margin-left: 50px !important;
              margin-top: 120px !important;
            }
          `}
        />

        <img
          src={SmallJar}
          alt="mix"
          style={{
            width: "35px",
            justifyContent: "flex-end",
            display: "none"
          }}
          css={css`
            @media (max-width: 1000px) {
              display: flex !important;
              align-self: flex-end;
              paddingleft: 10px;
            }
          `}
        />
        <BlendInfo>
          <SmallGrayLabel>STAGE 3</SmallGrayLabel>
          <BlendName>The Super Smash Burger Blend</BlendName>
          <BlendDescription>
            Our Super Smash Blend brings together the hearty and warm flavors
            from by CHLOE.'s best-seller, the Guac Burger. Perfect for even the
            smallest palate, this blend is a great source of protein, potassium
            and vitamin C for babes 9+ months.
          </BlendDescription>
          <SmallGrayLabel>ORGANIC INGREDIENTS</SmallGrayLabel>
          <IngredientsList>
            Sweet Potato, Black Beans, White Quinoa, Corn, Pear
          </IngredientsList>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/checkout"
          >
            <ChefButton>GET THE BOX</ChefButton>
          </Link>
        </BlendInfo>
      </BlendContainer>
      <BlendContainer
        style={{
          paddingTop: "100px",
          alignItems: "center"
        }}
        css={css`
          @media (max-width: 1000px) {
            padding-top: 60px !important;
          }
        `}
      >
        <BlendImage
          src={VIBBurg}
          alt="circle"
          css={css`
            align-self: flex-start;
            @media (max-width: 1000px) {
              margin-left: 50px !important;
              width: 120px !important;
            }
          `}
        />
        <img
          src={MixedJar}
          alt="mix"
          style={{ width: "45px", display: "none" }}
          css={css`
            @media (max-width: 1000px) {
              display: flex !important;
              align-self: flex-end;
              padding-left: 10px;
            }
          `}
        />
        <BlendInfo>
          <SmallGrayLabel>STAGE 4</SmallGrayLabel>
          <BlendName>The Baby Burger Bite</BlendName>
          <BlendDescription>
            Inspired by by CHLOE.'s bestseller, the Guac Burger, our Baby Burger
            Bite is the perfect meal to help babes 10+ months perfect that
            pincer grasp. This bite is rich in iron, protein, and potassium.
            Serve it warm or whip up your own guac using our by CHLOE. recipe.
          </BlendDescription>
          <SmallGrayLabel>ORGANIC INGREDIENTS</SmallGrayLabel>
          <IngredientsList>
            Black Beans, Sweet Potato, White Quinoa, Potato Flour, Corn,
            Sunflower Oil
          </IngredientsList>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/checkout"
          >
            <ChefButton>GET THE BOX</ChefButton>{" "}
          </Link>
        </BlendInfo>
      </BlendContainer>
    </ByChloeContainer>
  </div>
);

const BlendContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  width: 100%;
  @media (max-width: 1000px) {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }
`;

const ChefButton = styled(BlueButton)`
  width: 150px;
  background: ${blackberry};
  color: white;
  padding: 15px;
  font-size: 11pt;
  margin-top: 25px;

  @media (max-width: 1000px) {
    display: none;
  }
`;
const BlendInfo = styled.div`
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BlendImage = styled.img`
  width: 160px !important;
  background: seagreen;
  border-radius: 50%;
  @media (max-width: 1000px) {
    width: 120px !important;
  }
`;

const ByChloeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const IngredientsList = styled(Body)`
  font-size: 13pt;
  color: black;
  width: 400px;

  @media (max-width: 1000px) {
    font-size: 10pt !important;
    width: 80% !important;
  }
`;
const BlendName = styled(H2)`
  color: black;
  color: ${blueberry};
  padding-top: 20px;

  @media (max-width: 1000px) {
    font-size: 15pt !important;
    padding-top: 0px !important;
  }
`;
const BlendDescription = styled(Body)`
  color: black;
  font-size: 16pt;
  padding-top: 20px;
  width: 75%;

  @media (max-width: 1000px) {
    font-size: 11pt !important;
    width: 90% !important;
    padding-top: 0px !important;
  }
`;
const SmallGrayLabel = styled(H6)`
  font-size: 12pt;
  color: black;
  opacity: 0.4;
  padding-top: 20px;

  @media (max-width: 1000px) {
    padding-top: 20px;
    font-size: 8pt !important;
  }
`;
