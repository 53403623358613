import styled from "styled-components";

export const Page = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: visible;
`;

export const Sections = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
`;

export const DashboardCard = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  cursor: pointer;
  align-items: center;
  text-align: center;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  width: ${({ width }) => width || "276px"};
  height: ${({ height }) => height || "51px"};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  color: ${({ color }) => color || "#ffffff"};
  line-height: 15px;
  letter-spacing: 0.03em;

  @media (max-width: 1000px) {
    width: ${({ mobileWidth }) => mobileWidth || "110%"};
  }
`;

export const GridBox = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-left: unset !important;

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 80px);
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: ${props => (props.row ? "row" : "column")};
  ${props => (props.nowrap ? "flex-wrap: nowrap;" : "flex-wrap: wrap;")}
  ${props => (props.row ? "justify-content: center;" : "align-items:center;")}
  background-color: ${props =>
    props.color || props.background || props.bg || "initial"};
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.full && "width: 100%;"}
  
  @media(max-width: ${props => props.mobile || "1000px"}){
    ${props =>
      props.mobileStyle &&
      `${props.mobileStyle.split(";").join(" !important;")}`}
  }
  
  @media(max-width: 1000px){
    ${props => props.ms}
  }
  ${props => props.jc && `justify-content: ${props.jc};`}
  ${props => props.ai && `align-items: ${props.ai};`}
`;

export const FullHR = styled.hr`
  width: 100vw;
  position: relative;
  overflow: visible;
  border: 0 none;
  background: rgba(0, 0, 0, 0.12);
  height: 1px;
  margin: 0;
`;

export const HR = styled.hr`
  width: 100%;
  height: 1px;
  border: 0 none;
  background: rgba(0, 0, 0, 0.12);
  margin: 0;
`;

export const RewardContainerGrid = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : "repeat(3, 1fr)"};
  overflow-y: hidden;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  padding: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
