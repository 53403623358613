import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  texasOrange,
  Body1,
  texasLightOrange,
} from "../../../styledcomponents";
import "styled-components/macro";
import { H2, H4 } from "../../../styledcomponents/typefaces";

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";
const BowlOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BowlOnOat.png";
const BabyOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BabyOnOat.png";

export const HowItWorks = () => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [maxScroll, setMaxScroll] = useState(640);
  const [progress, setProgress] = useState(1);
  return (
    <React.Fragment>
      <H2
        css={`
          justify-content: center;
          color: ${texasOrange};
          padding-top: 50px;
          width: 100%;
          display: flex;
        `}
      >
        Mealtime Solved.
      </H2>
      <HowContainer
        onScroll={(e) =>
          handleScroll(e, setProgress, setScrollLeft, setMaxScroll)
        }
      >
        <TexasInfo
          css={css`@media(max-width:500px){opacity: ${progress > 1 && `0.7`}}`}
        >
          <img
            src={BabyOnOat}
            alt="made-for"
            css="width:170px; @media(max-width:500px){width:100px;}"
          />
          <H4
            css={css`
              color: ${texasOrange};
            `}
          >
            Made for your babe.
          </H4>
          <Body1
            css="width:280px;padding-top:20px; @media (max-width: 800px) {
    width:230px;
  }@media(max-width:500px){padding-top:0px;}"
          >
            Our nutritionist will recommend the perfect plan to suit your babe's
            developmental needs.
          </Body1>
        </TexasInfo>
        <TexasInfo
          css={css`@media(max-width:500px){padding:20px; opacity: ${progress <
            2 &&
            progress < 4 &&
            `0.7`}}`}
        >
          <img
            src={BowlOnOat}
            alt="made-for"
            css="width:170px;@media(max-width:500px){width:100px;}"
          />
          <H4
            css={css`
              color: ${texasOrange};
            `}
          >
            You pick, we cook.
          </H4>
          <Body1
            css="width:280px; padding-top:20px; @media (max-width: 800px) {
    width: 160px;
  }@media(max-width:500px){padding-top:0px;width:220px;}"
          >
            Let us know how many times per day you'd like to serve Yumi. Sit
            back while we cook.
          </Body1>
        </TexasInfo>
        <TexasInfo
          css={css`@media(max-width:500px){padding:40px; opacity: ${progress <
            5 && `0.7`}}`}
        >
          <img
            src={PricingFast}
            alt="made-for"
            css="width:170px; @media(max-width:500px){width:100px;}"
          />
          <H4
            css={css`
              color: ${texasOrange};
            `}
          >
            {" "}
            Delivered fresh weekly.
          </H4>
          <Body1
            css="width:240px; padding-top:20px;@media (max-width: 800px) {
    width: 190px;
  }@media(max-width:500px){padding-top:0px;width:220px;}"
          >
            Starting at $5 per day. You can edit or skip anytime!
          </Body1>
        </TexasInfo>
      </HowContainer>
      <ProgressDots>
        {Array(3)
          .fill()
          .map((el, i) => (
            <div
              key={i}
              style={{
                background: progress >= i * 2 ? texasOrange : texasLightOrange,
                padding: "5px",
                marginLeft: "5px",
                borderRadius: "30px",
              }}
            />
          ))}
      </ProgressDots>
    </React.Fragment>
  );
};

const handleScroll = (e, setProgress, setScrollLeft, setMaxScroll) => {
  if (!e.target.scrollTop && e.target.scrollLeft) {
    setMaxScroll(e.target.scrollWidth);
    setScrollLeft(e.target.scrollLeft);
    setProgress(
      Number((e.target.scrollLeft / e.target.scrollWidth) * 10).toFixed(0)
    );
  }
};

const TexasInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProgressDots = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    padding-top: 20px;
    width: 100%;
    justify-content: center;
  }
`;

const HowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 60px;
  margin-bottom: 60px;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;
    min-width: 99vw;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
