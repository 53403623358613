import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import spacetime from "spacetime";
import "styled-components/macro";
import { H2, H4, H5, Button, H3, Image } from "@team-yumi/ui";
import { getSpecialMenus } from "../../../usecases/checkout";
import { useRequest, useRedirect, useWindowSize } from "../../../hooks";
import { formatDate, getNextDeliveryDate } from "../../../utils/dates";
import {
  setInitialStateAction,
  fillSpecialMenuAction,
  updateMenuAction,
} from "../editMenuReducer";
import { getNewImageUrlFromMealId } from "../../../utils/images";

import { Box, oatAdjusted, BlueButton } from "../../../styledcomponents";

import { fetchMenu, updateCartMenuAfterFetch } from "../hooks";

export const ByChloeBabe =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeBabe.png";

export const StepThree = ({
  birthday,
  plans,
  planState,
  cart,
  dispatch,
  setMaxStep,
  trackEvent,
}) => {
  const selectedPlan = plans[planState.selectedPlanIndex];

  useWindowSize();

  const [selectedSpecialMenuID, setSelectedSpecialMenuID] = useState(0);
  // fetch special menus.
  const [specialMenus, specialMenuLoading, specialMenuError] = useRequest(
    () =>
      getSpecialMenus(
        formatDate(getNextDeliveryDate()),
        spacetime(birthday).format("yyyy-mm-dd"),
        selectedPlan.planId
      ),
    [birthday, selectedPlan.planId]
  );
  // This here, makes sure the menu in the cart is always equal to the menu fetched
  const { menu, menuLoading } = fetchMenu();
  updateCartMenuAfterFetch({
    dispatch,
    updateMenuAction,
    menu,
  });

  const continueStep = () => {
    setMaxStep(4);
    trackEvent("CheckoutStepThreeCompleted");

    setShouldRedirect(true);
  };

  // custom hook that returns a <Redirect/> element from react router, to be tossed at the bottom of the component tree.
  const [routeToStep4, setShouldRedirect] = useRedirect("/checkout/4");

  useEffect(() => {
    trackEvent("CheckoutStepThreeLoaded", { step: "box" });
  }, []);

  if (menuLoading || specialMenuLoading) return "loading...";
  if (specialMenuError) return "An error occurred.";
  if (!cart.initialStateSet)
    dispatch(
      setInitialStateAction({
        items: [],
        menu,
        meal_plan: selectedPlan.planId,
      })
    );

  if (selectedSpecialMenuID === 0) {
    setSelectedSpecialMenuID(
      _.get(specialMenus, (sm) => sm.age > 0, specialMenus[0]).id
    );
  }

  const filteredMenu = specialMenus.filter(
    (m) => m.id === selectedSpecialMenuID
  )[0];

  const specialMenuDescription = _.get(
    specialMenus.find((sm) => sm.id === selectedSpecialMenuID),
    "description",
    "Great for babes who want to taste the rainbow."
  );

  const specialMenuAge = _.get(
    specialMenus.find((sm) => sm.id === selectedSpecialMenuID),
    "age",
    0
  );

  const getImageUrl = () => {
    const selectedSpecialMenu = specialMenus.find(
      (sm) => sm.id === selectedSpecialMenuID
    );

    if (!selectedSpecialMenu) {
      return "";
    }

    if (specialMenuAge !== 0) {
      return `https://email.helloyumi.com/assets/_special_menus/sm-${
        selectedSpecialMenu.name
      }.gif`;
    }

    return `https://email.helloyumi.com/assets/_special_menus/sm-${
      selectedSpecialMenu.name
    }.png`;
  };

  const buttonText = filteredMenu ? `SELECT BOX` : "SELECT BOX";

  return (
    <div
      style={{
        fontFamily: "Freight-Book",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "calc(100% - 116px)",
        marginTop: "58px",
        marginBottom: "58px",
      }}
      css={css`
        @media (max-width: 1000px) {
          width: 100% !important;
          margin-top: 0px !important;
        }
      `}
    >
      <Box>
        <H2>Choose your baby's meal plan</H2>
        <MenuBar
          row
          css={css`
            max-width: 100vw;
            @media (min-width: 1001px) {
              position: relative;
              z-index: 1000;
            }
          `}
        >
          <MenuBarContents row ai="center" jc="center">
            {specialMenus.map((specialMenu, index) => (
              <Box
                key={specialMenu.meal_id}
                full
                ai="center"
                jc="center"
                css={css`
                  width: 100%;
                  height: 30px;
                  background-color: white;
                  border-left: ${index ? "1px" : "0px"} solid #f2f2f2;
                  color: ${specialMenu.id === selectedSpecialMenuID
                    ? "#334897"
                    : "#C4C4C4"};
                `}
              >
                <MenuBarOption
                  isSelected={specialMenu.id === selectedSpecialMenuID}
                  onClick={() => setSelectedSpecialMenuID(specialMenu.id)}
                  age={specialMenu.age > 0}
                >
                  {specialMenu.type === "collab"
                    ? "Bites and Blends"
                    : specialMenu.name}
                </MenuBarOption>
              </Box>
            ))}
          </MenuBarContents>
        </MenuBar>

        <SummaryContainer>
          <SummaryImage src={getImageUrl()} />

          <SummaryRight>
            <SummaryContent>
              <H4>{_.get(filteredMenu, "name")}</H4>

              <H5>{specialMenuDescription}</H5>
            </SummaryContent>

            <Button
              type="tertiary"
              onClick={() => {
                dispatch(fillSpecialMenuAction(filteredMenu && filteredMenu));
                continueStep();
              }}
            >
              {buttonText}
            </Button>
          </SummaryRight>
        </SummaryContainer>
      </Box>
      {routeToStep4()}

      <div style={{ padding: "15px", width: "700px", maxWidth: "100%" }}>
        <H3>What's included?</H3>

        <MenuCardContainer>
          {filteredMenu &&
            filteredMenu.menus.map((menu) => (
              <MenuCard key={menu.meal_id}>
                <MenuCardImg>
                  <Image
                    w={180}
                    src={getNewImageUrlFromMealId(menu.meal_id)}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = `https://email.helloyumi.com/assets/0${
                        menu.meal_id
                      }.png`;
                    }}
                  />
                </MenuCardImg>
                <MenuCardRight>
                  <H4 mt="0px" mb="5px">
                    {" "}
                    {menu.meal_name} - x {menu.quantity}
                  </H4>
                  <H5 m="0px">{menu.ingredients}</H5>
                </MenuCardRight>
              </MenuCard>
            ))}
        </MenuCardContainer>
      </div>
    </div>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 45px;
  width: 100%;
  max-width: 700px;
  flex-wrap: nowrap;
  @media (max-width: 740px) {
    padding-left: 20px;
  }
`;

const SummaryImage = styled.img`
  width: 320px;
  height: 320px;
  background: ${oatAdjusted};
  flex-shrink: 0;
  @media (max-width: 600px) {
    width: 160px;
    height: 160px;
  }
`;

const SummaryRight = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;
const SummaryContent = styled.div`
  flex: 1;
  padding: 20px;
  padding-left: 0;
`;

const SummaryContentTitle = styled.header`
  font-weight: 500;
  font-size: 24px;
  color: #658fca;
  margin-bottom: 12px;
  font-family: "Platform-Medium";
`;

const MenuBar = styled(Box)`
  width: 100%;
  display: flex;

  @media (max-width: 1000px) {
    min-height: 65px;
  }
`;

const MenuBarContents = styled(Box)`
  width: 100%;
  text-align: center;
  display: flex;
  z-index: 2;
  flex-wrap: nowrap;
  margin: 0 auto;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: 32px;
  margin-right: 32px;
  max-width: 1174px;
  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start !important;

    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    margin: 0;
    padding: 0;
  }
`;

const MenuBarOption = styled(BlueButton)`
  font-size: 12pt;
  min-height: 70px;
  outline: none;

  @media (max-width: 500px) {
    font-size: 14px;
    margin: 0 40px 0 0;
    width: 100%;
  }
`;

const MenuTitle = styled.p`
  font-family: "Platform-Medium";
  font-weight: 500;
  font-size: 28px;
`;
const MenuCardContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;
const MenuCard = styled.div`
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const MenuCardImg = styled.div`
  width: 70px;
  min-height: 109px;
  max-height: 133px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: 70%;
    height: 50%;
  }
`;

const MenuCardRight = styled.div`
  flex: 1;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MenuCardTitle = styled.p`
  font-family: "Platform-Medium";
  font-size: 16px;
  margin-bottom: 6px;
`;
