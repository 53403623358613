import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  oatCreamy,
  yumiOrange,
  H1,
  H5,
  BlueButton
} from "../../../styledcomponents";
import {
  Receipt,
  BigCarrot,
  Kiwi,
  BabySmall,
  Broccoli,
  YellowQuad,
  LittleCarrot,
  LittleTomato,
  Banana,
  Berry,
  SilverBend
} from "../assets";
import "styled-components/macro";

import { H2, Body } from "../../../styledcomponents/typefaces";
import { useWindowSize, useRequest } from "../../../hooks";
import { getUser } from "../../../usecases";

const imageColumns = [
  {
    image: BabySmall,
    title: "Made for your babe",
    description:
      "Our nutritionist will recommend the perfect plan to suit your babe's developmental needs."
  },
  {
    image: Broccoli,
    title: "You pick, we cook",
    description:
      " Let us know how many times per day you'd like to serve Yumi. Sit back while we cook."
  },
  {
    image: YellowQuad,
    title: "Delivered fresh weekly",
    description: "Starting at $5 per day. You can edit or skip anytime!"
  }
];
export const OneOffPurchaseConfirmation = ({
  history,
  selectedPlan,
  selectedQuantity
}) => {
  const { width } = useWindowSize();

  return (
    <MainWrapper>
      <img
        src={BigCarrot}
        alt="care"
        css="position:absolute; left:0; @media(max-width:800px){display:none;}@media(max-width:500px){display:block;width:130px; margin-top:404px; z-index:5}"
      />
      <img
        src={Kiwi}
        alt="kee"
        css="position:absolute; right:0; top:800px; width:130px; @media(max-width:800px){width:80px}@media(max-width:500px){top:650px;width:55px}
    "
      />
      <img
        src={LittleCarrot}
        alt="carrot"
        css="position:absolute; margin-top:239px;margin-left:350px;@media(max-width:800px){transform:rotate(45deg);margin-top:200px;margin-left:300px;}@media(max-width:500px){transform:rotate(0deg);width:120px;margin-top:30px;margin-left:150px;}"
      />
      <img
        src={LittleTomato}
        alt="tomate"
        css="position:absolute; z-index: 100; margin-left:-2px;margin-top:340px; @media(max-width:1330px){margin-left:20px}@media(max-width:800px){margin-top:250px;}@media(max-width:500px){margin-left:7px;margin-top:115px;width:30px}"
      />
      <img
        src={Berry}
        alt="berry"
        css="position:absolute; margin-top:100px;left:500px;@media(max-width:800px){margin-left:150px;margin-top:0px;width:40px;}@media(max-width:500px){width:25px;margin-top:15px;left:40px;}"
      />
      <img
        src={Banana}
        alt="ban"
        css="position:absolute; margin-top:400px; left:260px;@media(max-width:1250px){left:200px}@media(max-width:1150px){left:150px}@media(max-width:800px){width:100px; margin-top:280px;left:50px;}@media(max-width:500px){width:60px; margin-top:130px; left:30px;}"
      />
      {width > 800 ? (
        <CentralBox>
          <H1
            css={css`
              font-size: 150px;
              color: ${yumiOrange};
              position: relative;
              line-height: 1em;
              justify-self: center;
              align-self: center;
              height: 380px;
              text-align: center;
            `}
          >
            Thanks for your order
          </H1>
          <div
            css="
  border-top: 1px solid gray; height:250px;display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;"
          >
            <Receipt
              big
              selectedPlan={selectedPlan}
              selectedQuantity={selectedQuantity}
              price={35}
            />
            <OrangeButton
              css="width:200px; padding:12px; margin-top:20px;font-size:10pt;"
              onClick={() => history.push("/dashboard/orders/current")}
              value="GO TO DASHBOARD"
            />
          </div>
          <div />
        </CentralBox>
      ) : (
        <TopBox>
          <Oatbox>
            <H1
              css={css`
                font-size: 60px;
                color: ${yumiOrange};
                position: relative;
                line-height: 1em;
                justify-self: center;
                align-self: center;
                text-align: center;
              `}
            >
              Thanks for your order
            </H1>
            <div css="padding-top:20px" />
            <Receipt
              noBorder
              selectedPlan={selectedPlan}
              selectedQuantity={selectedQuantity}
              price={35}
            />
            <Link css="text-decoration:none" to="/checkout">
              <OrangeButton
                css="width:200px; padding:12px; margin-top:20px;font-size:10pt;"
                onClick={() => history.push("/dashboard/orders/current")}
                value="GO TO DASHBOARD"
              />
            </Link>
          </Oatbox>
          <Get50Sliver>
            <div />{" "}
            <Left>
              <H5
                css={css`
                  color: ${yumiOrange};
                  padding-bottom: 20px;
                  width: 90%;
                  @media (max-width: 800px) {
                    width: 100%;
                    width: 99%;
                  }
                `}
              >
                Get 50% percent off your first month
              </H5>
            </Left>
            <Right>
              <Body css="padding-bottom:20px;width:90%; font-size:10pt !important; line-height:1.5em !important">
                We don’t know each other yet, but we think this is the start of
                something great. We’re offering you the chance to get 50% off 1
                month of Yumi.
              </Body>
              <OrangeButton value="GET OFFER" />
            </Right>
          </Get50Sliver>
          <img
            src={SilverBend}
            css="width:100%; margin-top:-170px;right:0;position:absolute; z-index:1;"
            alt="Silvy"
          />
        </TopBox>
      )}
      <HowItWorks css="z-index:2;">
        <H2
          css={css`
            color: ${yumiOrange};
            padding-bottom: 45px;
            margin-top: -20px;

            @media (max-width: 800px) {
              font-size: 30pt;
            }
          `}
        >
          How It Works
        </H2>
        <HowItWorksRow>
          {imageColumns.map(column => (
            <HowColumn>
              <img
                src={column.image}
                alt="col"
                css="padding-bottom:20px; max-height:320px"
              />
              <H5
                css={css`
                  color: ${yumiOrange};
                  padding-bottom: 20px;
                `}
              >
                {column.title}
              </H5>

              <Body css="font-size:14pt; width:85%; line-height:1.5em; @media(max-width:500px){width:100%;}">
                {column.description}
              </Body>
            </HowColumn>
          ))}
        </HowItWorksRow>
      </HowItWorks>
    </MainWrapper>
  );
};

const Oatbox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  background: ${oatCreamy};
  width: 100%;
  z-index: 2;
`;

const Get50Sliver = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: 15px 178px 160px 14px;
  grid-template-rows: 200px;
  justify-content: center;
  margin-top: 50px;
`;
const TopBox = styled.div`
  grid-template-rows: 2fr 1fr;
`;

const OrangeButton = styled(BlueButton)`
  color: white;
  font-size: 16px;
  padding: 22px;
  background: ${yumiOrange};
  width: 300px;
  padding-left: 30px;
  box-shadow: 0px 1px 3px gray;

  @media (max-width: 800px) {
    width: 140px;
  }
`;

const HowColumn = styled.div`
  display: flex;
  flex-direction: Column;
  width: 230px;
  align-items: center;

  text-align: center;
  width: 100%;

  @media (max-width: 800px) {
    margin-bottom: 40px;
  }
`;
const HowItWorksRow = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 300px 300px 300px;

  @media (max-width: 800px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;
const Get50 = styled.div`
  display: grid;
  grid-template-columns: 360px 300px;
  padding-top: 40px;
  justify-content: center;
  border-top: 1px solid gray;
  align-items: center;
`;

const Left = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: Center;
    padding-left: 25px;
  }
`;

const Right = styled.div`
  @media (max-width: 800px) {
    padding-left: 10px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;
const CentralBox = styled.div`
  background: ${oatCreamy};
  display: grid;
  padding: 60px;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  width: 75%;
  grid-template-rows: 470px 280px;

  @media (max-width: 800px) {
    grid-template-rows: 300px 200px 200px;
  }
`;
const HowItWorks = styled.div`
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    margin-top: 250px;
    background: #faf8f6;
  }
`;
