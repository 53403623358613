import React from "react";
import styled, { css } from "styled-components";
import { Body1, H3, blackberry, BlueButton } from "../../../styledcomponents";
import { MilestoneText, CoachingSessions, AgeAndDev } from "../assets";
import "styled-components/macro";

export const ParentOptions = () => (
  <ParentOptionsContainer>
    <ParentSectionTop>
      <ParentOption>
        <img
          src={AgeAndDev}
          alt="coaching"
          css={css`
            @media (max-width: 1000px) {
              width: 32px !important;
            }
          `}
        />

        <Body1
          style={{ fontSize: "24px", margin: "50px" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
              margin: 10px !important;
            }
          `}
        >
          {" "}
          Meals selected for your baby's age and development.
        </Body1>
      </ParentOption>
      <ParentOption>
        <img
          src={CoachingSessions}
          alt="coaching"
          css={css`
            @media (max-width: 1000px) {
              width: 32px !important;
            }
          `}
        />
        <Body1
          style={{ fontSize: "24px", margin: "50px" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
              margin: 10px !important;
            }
          `}
        >
          5 Free Nutritional Coaching Sessions
        </Body1>
      </ParentOption>
    </ParentSectionTop>
    <ParentSectionBottom>
      <IMessageImage>
        <img
          src={MilestoneText}
          alt="text"
          style={{ width: "400px" }}
          css={css`
            box-shadow: 1px 2px 20px 0px rgba(209, 209, 209, 0.75);
            @media (max-width: 1000px) {
              width: 250px !important;
            }
          `}
        />
      </IMessageImage>
      <ParentingManualSection>
        <H3
          style={{ fontSize: "35pt", color: blackberry }}
          css={css`
            @media (max-width: 1000px) {
              justify-content: center !important;
              font-size: 15pt !important;
              text-align: center !important;
              margin-top: 50px !important;
            }
          `}
        >
          {" "}
          Parenting doesn't come with a manual, but we give you a coach.
        </H3>
        <Body1
          style={{ fontSize: "18pt", marginTop: "20px" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
            }
          `}
        >
          We'll pair you with a dietitian who will be there every step of the
          way as you introduce solids.
        </Body1>

        <a
          style={{ textDecoration: "none", color: "white" }}
          href="https://calendly.com/yumi-guide-team/yumi-guide-call"
        >
          {" "}
          <BlueButton
            style={{
              background: blackberry,
              color: "white",
              padding: "2.25vh",
              fontSize: "15pt",
              width: "60%",
              marginTop: "4vh",
            }}
            css={css`
            @media(max-width:1000px){
              font-size: 9pt !important;
              width: 100% !important;
            }
          }}`}
          >
            SCHEDULE A FREE CONSULTATION TODAY
          </BlueButton>{" "}
        </a>
      </ParentingManualSection>
    </ParentSectionBottom>
  </ParentOptionsContainer>
);

const ParentOption = styled(Body1)`
  font-size: 2.25vh;
  display: flex;
  align-items: center;
`;
const IMessageImage = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
`;
const ParentingManualSection = styled.div`
  align-self: center;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    align-items: center;
    width: 100%;
  }
`;
const ParentSectionTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 100px;
`;

const ParentSectionBottom = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    justify-content: center;
    text-align: center;
  }
`;

const ParentOptionsContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;

  padding: 30px;
  display: flex;
  flex-direction: column;
`;
