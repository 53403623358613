import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { TotBoxGrab, ColorfulJar } from "../assets";
import { H1, Body1, BlueButton } from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";
import {
  oatCreamy,
  blackberry,
  oat,
  oatDark
} from "../../../styledcomponents/colors";
import "styled-components/macro";

export const BowlOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BowlOnOat.png";

export const BabyOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BabyOnOat.png";

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";

export const HowItWorks = () => (
  <PageLayout>
    <TotImageSection>
      <img
        src={TotBoxGrab}
        css={css`
          width: 550px;

          @media (max-width: 1000px) {
            width: 100%;
            max-height: 480px;
            object-fit: cover;
          }
          @media (max-width: 500px) {
            width: 550px;
          }
        `}
        alt="tot-box-grab"
      />
    </TotImageSection>
    <TotTextSection>
      <div
        css={css`
          display: flex;
          @media (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
            padding-top: 80px;
          }
        `}
      >
        <img
          src={ColorfulJar}
          alt="jar"
          css="width:100px; @media(max-width:1000px){width:90px; }"
        />
        <H1
          css={css`
            color: ${blackberry};
            width: 80%;
            font-size: 35pt;
            padding-left: 20px;
            @media (max-width: 1000px) {
              width: 100%;
              padding-left: 0px;
              font-size: 20pt;
            }
            @media (max-width: 1000px) {
              padding-left: 0px;
              font-size: 30pt;
              display: flex;
              padding-top: 32px;
              justify-content: center;
            }
          `}
        >
          {" "}
          How It Works{" "}
        </H1>
      </div>
      <InChargeSection>
        <ImageAndBody
          css="@media(max-width:1000px){padding-top:30px;}
        "
        >
          <img
            src={PricingFast}
            alt="price"
            css={css`
              width: 60px;
              background: ${oat};

              min-height: 60px;
              border-radius: 50%;
            `}
          />
          <InChargeBody>
            Decide how often you’d like to serve Yumi.
          </InChargeBody>
        </ImageAndBody>
        <ImageAndBody css="padding-top:48px; @media(min-width:1000px){padding-top:0px;}">
          <img
            src={BowlOnOat}
            alt="bowl"
            css={css`
              width: 60px;
              background: ${oat};
              min-height: 60px;
              border-radius: 50%;
            `}
          />
          <InChargeBody>
            Warm up bites and place on high chair table.
          </InChargeBody>
        </ImageAndBody>
        <ImageAndBody css="padding-top:48px; @media(min-width:1000px){padding-top:0px;}">
          <img
            src={BabyOnOat}
            alt="babe"
            css={css`
              width: 60px;
              background: ${oat};

              min-height: 60px;
              border-radius: 50%;
            `}
          />
          <InChargeBody>
            Let your baby self feed. Your baby will control how much they eat
            and will become attuned to their own hunger response cues.
          </InChargeBody>
        </ImageAndBody>
      </InChargeSection>
      <div css="width:20%; display:flex; justify-content:center; @media (max-width: 1000px) {width:100%}">
        <Link
          css={css`
            padding: 20px;
            width: 150px;
            margin-top: 0px;
            text-decoration: none;

            @media (max-width: 1000px) {
              align-self: center;
              justify-self: center;
              padding: 0px;
              margin-top: 0px;
            }
          `}
          to="/checkout"
        >
          <BlueButton
            css={css`
              background: ${blackberry};
              color: white;
              padding: 20px;
              width: 150px;
              margin-top: 55px;

              @media (max-width: 1000px) {
                padding: 12px;
              }
            `}
            value="GET STARTED"
          />
        </Link>
      </div>
    </TotTextSection>
  </PageLayout>
);

const ImageAndBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  @media (max-width: 1000px) {
    flex-direction: column;
    min-height: 100px;
    margin-top: 0px;
  }
`;
const InChargeSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 0px;
  }
`;
const InChargeBody = styled(Body)`
  font-size: 13pt;
  line-height: 1.5em;
  padding-left: 20px;

  @media (max-width: 1000px) {
    font-size: 13pt;
    width: 90%;
    text-align: center;
    padding-top: 7px;
  }
`;

const TotImageSection = styled.div`
  width: 50%:

  @media(max-width:1000px) {
    width: 100%;
    object-fit: cover;
    justify-self: center;
  }
  @media (max-width: 500px) {
    object-fit: cover;
  }
`;
const TotTextSection = styled.div`
  width: 50%;
  padding-left: 120px;
  max-height: 500px;
  @media (max-width: 1200px) {
    width: 100%;
    padding-top: 0px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    justify-self: center;
    align-self: center;
    text-align: center;
    padding-left: 0px;
    padding-top: 50px;
  }

  @media (max-width: 500px) {
    padding-top: 0px;
  }
`;
const PageLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background: #fefefe;
  overflow: hidden;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 300px 600px;
    justify-content: center;
    align-items: center;
  }
`;
