import React from "react";
import LazyLoad from "react-lazyload";
import Transition from "react-transition-group";

export const LazyImage = ({
  src,
  alt,
  height,
  style,
  offset = 100,
  ...props
}) => (
  <LazyLoad height={height} offset={offset}>
    <img src={src} alt={alt} height={height} {...props} style={style} />
  </LazyLoad>
);
