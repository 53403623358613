import React from "react";
import styled from "styled-components";

import { blackberry, H5, H6, BlueButton } from "../../styledcomponents";
import { QuadSection } from "./components/QuadContainers";

const first_quads = [{ name: "Fruit Variety Box" }, { name: "Veggie Variety" }];
export const QuadsCheckout = () => (
  <QuadsPage>
    <ProgressHeader />
    <QuadMenuSection>
      <QuadSection
        name="Starters"
        text="Great for babes just starting solids. Introduce 1 ingredient at a time or mix a few together."
        quads={first_quads}
      />{" "}
      <QuadSection
        name="Transition Boxes"
        text="Great for babes who are ready to try a few multi-ingredient blends. Get your favorite single ingredients and challenge your babe withe a few stage 2, smooth blends."
        quads={first_quads}
      />
    </QuadMenuSection>
  </QuadsPage>
);

const QuadMenuSection = styled.div`
  width: 90%;
`;

const ProgressHeader = () => (
  <ProgressHeaderContainer>
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <H5 style={{ color: "white", fontSize: "22pt" }}>(0/7)</H5>
      <H6 style={{ color: "white", opacity: "0.3" }}>JARS</H6>
    </div>
    <div
      style={{
        display: "flex",
        justifySelf: "flex-end"
      }}
    >
      <QuadButton value="CANCEL" />
      <QuadButton value="SAVE" />
    </div>
  </ProgressHeaderContainer>
);

const QuadButton = styled(BlueButton)`
  color: white;
  border: 1px solid white;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 10pt;
  margin-left: 5px;
`;
const ProgressHeaderContainer = styled.div`
  background: ${blackberry};
  width: 100%;
  min-height: 100px;
  display: flex;
  outline: 1px solid red;
  justify-content: space-between;
  padding-left: 90px;
  padding-right: 90px;
  align-items: center;

  @media (max-width: 1000px) {
    position: fixed;
    left: 0;
    bottom: 0;
  }
`;

export const QuadsPage = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: hidden;
`;
