import React from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import "styled-components/macro";
import { ProductName } from "../../styledcomponents/typefaces";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_homepage/${name}${extension}`;

export const BlockTwo = getImageURL("BlockTwo");
export const Brains = getImageURL("Brains");
export const HeaderDesktop = getImageURL("HeaderDesktop");
export const HeaderMobile = getImageURL("mobile-hero-min", ".png");
export const NewHeaderDesktop = getImageURL("NewHeaderDesktop", ".png");
export const Rosie = getImageURL("Rosie", ".gif");
export const TotImage = getImageURL("pizza-bites-and-hands", ".jpg");
export const Vogue = getImageURL("Vogue", ".svg");
export const NYT = getImageURL("NYT", ".svg");
export const Goop = getImageURL("Goop", ".svg");
export const FoodAndWine = getImageURL("FoodAndWine", ".svg");
export const BigBrain = getImageURL("Brain", ".svg");
export const Baby = getImageURL("Baby", ".svg");
export const Bowl = getImageURL("Bowl", ".svg");
export const Mail = getImageURL("Mail", ".svg");
export const TopSolidsWave = getImageURL("TopSolidsWave", ".svg");
export const BottomSolidsWave = getImageURL("wave3", ".svg");
export const AllieRizzo = getImageURL("allie_rizzo1", ".png");
export const AllieRizzoAvi = getImageURL("allie_rizzo_avi_circle", ".png");
export const JV = getImageURL("social3", ".jpg");
export const JVLogo = getImageURL("jv_logo", ".jpg");
export const AJ = getImageURL("aj2", ".jpg");
export const Papaya = getImageURL("social2", ".jpg");
export const Klo = getImageURL("klo", ".jpeg");
export const KloAvi = getImageURL("klo_avi_circle", ".png");
export const Raina = getImageURL("raina", ".jpeg");
export const RainaAvi = getImageURL("raina_avi_circle", ".png");
export const Alice = getImageURL("alice", ".jpeg");
export const AliceAvi = getImageURL("alice_avi_circle", ".png");
export const Monica = getImageURL("monica", ".jpeg");
export const MonicaAvi = getImageURL("monica_avi_circle", ".png");
export const YumiLogo = getImageURL("yumi_circle", ".png");
export const Peach = getImageURL("Peach", ".svg");
export const Eggplant = getImageURL("Eggplant", ".svg");
export const Kiwi = getImageURL("Kiwi", ".svg");
export const Star = getImageURL("Star", ".svg");
export const PapayaLeft = getImageURL("PapayaLeft");
export const BlueberryRight = getImageURL("BlueberryRight");
export const KiwiJar = getImageURL("KiwiJar-min", ".png");

export const DarkBlue = "#324599";
export const BabyBlue = "#668FCA";

export const LinkButton = ({
  to = "/checkout",
  value = "GET STARTED",
  background = BabyBlue,
  color = "white"
}) => (
  <Link
    to={to}
    css={css`
      text-decoration: none;
      @media (max-width: 500px) {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        padding: 10px;
        z-index: 899;
        box-shadow: 0 2px 5px 0 #e9e9e9;
        background: #f6da71
          url(https://email.helloyumi.com/assets/bannerStarsDesktop.svg)
          no-repeat;
        background-size: contain;
        left: 0px;
      }
    `}
  >
    <button
      type="button"
      value={value}
      css={`
        background: ${background};
        color: ${color};
        padding: 1em 3em 1em 3em;
        font-family: "Platform-Medium";
        cursor: pointer;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <ProductName>{value}</ProductName>
    </button>
  </Link>
);

export const TotLinkButton = ({
  value = "GET STARTED",
  background = BabyBlue,
  color = "white"
}) => (
  <a
    href="/menu"
    css={css`
      text-decoration: none;
    `}
  >
    <button
      type="button"
      value={value}
      css={`
        background: ${background};
        color: ${color};
        padding: 1em 3em 1em 3em;
        font-family: "Platform-Medium";
        cursor: pointer;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <ProductName>{value}</ProductName>
    </button>
  </a>
);
