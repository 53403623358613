import React from "react";
import styled from "styled-components";
import { Box, Modal, Row } from "@team-yumi/ui";

export const CustomModal = ({
  visible,
  modalBody,
  image,
  imageWidth,
  imageHeight,
}) => (
  <Modal visible={visible}>
    {" "}
    <Row width="100vw">
      <Box
        center
        bg="white"
        xl={6}
        md={4}
        sm={2}
        start={{ xl: 4, md: 2, sm: 1 }}
      >
        <Box center p="30px">
          <Image
            height={imageHeight}
            width={imageWidth}
            src={`https://email.helloyumi.com/assets/${image}`}
          />
          {modalBody()}
        </Box>
      </Box>
    </Row>
  </Modal>
);

const Image = styled.img``;
