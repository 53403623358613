import React from "react";
import MaskedInput from "react-text-mask";
import { TextInput } from "../forms";

const dateMask = input => {
  // Determine mask based on month-
  if (input.length === 1 && Number(input) > 1)
    return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  if (input.length === 2 && input[1] === "/")
    return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  if (input.length === 5 && input[4] === "/")
    return ["0", /\d/, "/", "0", /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  // Determine mask based on day
  if (input.length === 4 && Number(input[3]) > 3)
    return [/\d/, /\d/, "/", "0", /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  // Determine mask based on day
  if (input.length === 9 && Number(input[0]) < 10 && Number(input[0]) !== 1)
    return ["0", /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  // Determine mask based on day
  if (input.length === 8)
    return [/\d/, /\d/, "/", /\d/, /\d/, "/", "2", "0", /\d/, /\d/];
  // Default
  return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
};

export const DateInput = ({ onChange, value, required = false, ...rest }) => (
  <MaskedInput
    mask={dateMask}
    guide={false}
    onChange={onChange}
    placeholder="mm/dd/yyyy"
    value={value}
    id="checkoutBirthdayField"
    required={required}
    style={{ maxWidth: "100%", fontFamily: "Freight-Book" }}
    type="text"
    render={(ref, p) => <TextInput basicRef={ref} {...p} />}
    {...rest}
  />
);
