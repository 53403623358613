import React, { useState } from "react";
import styled, { css } from "styled-components";
import { oat, blackberry, H2, Body1, H6 } from "../../../styledcomponents";
import { Tummy, Brain, Bones } from "../../Blends/assets";
import { Baby } from "../assets";
import { useWindowSize } from "../../../hooks";
import "styled-components/macro";

const DownArrow =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_mini-chevron-right-solid.svg";

const healthItems = [
  {
    name: "Taste Preferences",
    icon: Baby,
    info:
      "Studies show that early exposure to a wide variety of texture, tastes and vegetables can reduce fussiness and instill a love of health foods later in life."
  },
  {
    name: "Neural Development",
    icon: Brain,
    info:
      "By age 2, about 80% of the adult brain is formed. During this period more than half of all energy consumed is going straight to the brain."
  },
  {
    name: "Metabolic Issues",
    icon: Tummy,
    info:
      "This period as also been regarded as an important one for fat cell development. Eating too much sugar, especially during this period, puts your child at greater risk of diabetes and obesity."
  },
  {
    name: "Bone Development",
    icon: Bones,
    info:
      "Babies are born with over 300 bones, and as they grow their bones will eventually fuse together to form the 206 bones that adults have."
  }
];

export const FirstThousand = () => {
  const { width } = useWindowSize();

  const [selectedInfoIndex, setSelectedInfoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggleInfo = () => setIsOpen(old => !old);

  return (
    <FirstThousandContainer>
      <FirstThousandTop>
        <H2
          style={{
            textAlign: "center",
            color: blackberry,
            marginTop: "180px"
          }}
          css={`
            @media (max-width: 900px) {
              font-size: 40px !important;
              margin-top: 80px !important;
            }
          `}
        >
          Why Do the First 1000 Days Matter?
        </H2>
        <Body1
          style={{
            opacity: "0.7",
            width: "58%",
            marginTop: "3vh",
            fontSize: "17pt",
            textAlign: "center"
          }}
          css={`
            @media (max-width: 900px) {
              font-size: 14pt !important;
              width: 90% !important;
            }
          `}
        >
          What you feed your baby now, during the first 1,000 days of life,
          impacts your child's everything. Together, we're nourishing your baby
          with the right foods to help them reach their full potential.
        </Body1>
      </FirstThousandTop>
      <FirstThousandBottom>
        <IconRow>
          {healthItems.map((healthItem, i) => (
            <React.Fragment key={healthItem.icon}>
              <HealthCircle
                healthItem={healthItem}
                index={i}
                selectedInfoIndex={selectedInfoIndex}
                setSelectedInfoIndex={setSelectedInfoIndex}
                isOpen={isOpen}
                toggleInfo={toggleInfo}
              />
              {width < 630 &&
                selectedInfoIndex === i && (
                  <InfoBox isOpen={isOpen}>
                    <Body1
                      style={{ fontSize: "20pt" }}
                      css={css`
                        @media (max-width: 1000px) {
                          font-size: 12pt !important;
                        }
                      `}
                    >
                      {healthItems[selectedInfoIndex].info}
                    </Body1>
                  </InfoBox>
                )}
            </React.Fragment>
          ))}
        </IconRow>
        <InfoBox isOpen={isOpen && width > 630}>
          <Body1 style={{ fontSize: "20px" }}>
            {healthItems[selectedInfoIndex].info}
          </Body1>
        </InfoBox>
      </FirstThousandBottom>
    </FirstThousandContainer>
  );
};
const HealthCircle = ({
  healthItem,
  index,
  selectedInfoIndex,
  setSelectedInfoIndex,
  isOpen,
  toggleInfo
}) => (
  <HealthItem>
    <img
      src={healthItem.icon}
      alt="health item"
      style={{ width: "180px" }}
      css={css`
        @media (max-width: 1000px) {
          width: 160px !important;
        }
      `}
    />
    <H6 style={{ marginTop: "24px", color: blackberry, fontSize: "25px" }}>
      {healthItem.name}
    </H6>
    <ShowInfoIcon
      src={DownArrow}
      alt="down arrow"
      onClick={() => {
        setSelectedInfoIndex(index);
        if (index === selectedInfoIndex || !isOpen) toggleInfo();
      }}
      isOpen={isOpen && index === selectedInfoIndex}
    />
  </HealthItem>
);

const ShowInfoIcon = styled.img`
  border: 2px solid ${blackberry};
  border-radius: 40px;
  padding: 5px;
  margin-top: 15px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "none")};
  transition: all 0.1s;
  &:hover {
    cursor: pointer;
  }
`;
const InfoBox = styled.div`
  background: white;
  position: relative;
  text-align: center;
  width: 75%;
  padding: 62px;
  display: ${({ isOpen }) => (isOpen ? "inline-black" : "none")};

  @media (max-width: 1000px) {
    padding: 20px;
  }
`;
const HealthItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 30px;
  margin-top: 25px;
`;
const FirstThousandTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  @media (max-height: 600px) {
    margin-top: 100px;
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1500px;
  justify-content: center;
  margin-top: 80px;
  padding-bottom: none;
`;

const FirstThousandBottom = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 80px;
`;

const FirstThousandContainer = styled.div`
  background: ${oat};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
