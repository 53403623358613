import React from "react";
import { Link } from "react-router-dom";

import "./styles/styles.css";
import StickyFooter from "../StickyFooter";
import requireAuth from "../../utils/requireAuth";
import flare from "../NavigationBar/styles/flare.svg";

class NavModal extends React.Component {
  authed = () => requireAuth.authenticated(this.props.user);

  hideMe() {
    return window.location.pathname === "/jon-&-vinnys";
  }

  render() {
    return this.hideMe() ? (
      ""
    ) : (
      <div>
        <div className="navModal_container">
          <ul>
            <li>
              <Link
                to="/menu"
                onClick={this.props.handleClick}
                className="navModal_link"
              >
                OUR MENU
              </Link>
            </li>
            <li>
              <a href="/menu">
                FINGER FOODS{" "}
                <img
                  src={flare}
                  alt="baby flare"
                  style={{ position: "relative", top: "auto", bottom: "15px" }}
                />
              </a>
            </li>
            <li>
              <Link
                to="/plans"
                onClick={this.props.handleClick}
                className="navModal_link"
              >
                PLANS
              </Link>
            </li>
            {!this.authed() && (
              <li>
                <a
                  href="https://helloyumi.kustomer.help/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </li>
            )}
            {this.authed() && (
              <li>
                <Link to="/orders" onClick={this.props.handleClick}>
                  ORDERS
                </Link>
              </li>
            )}
            {this.authed() && (
              <li>
                <Link to="/dashboard/profile" onClick={this.props.handleClick}>
                  SETTINGS
                </Link>
              </li>
            )}
            {this.authed() && <li onClick={this.props.onClick}>LOGOUT</li>}
            {!this.authed() && (
              <li>
                <Link to="/login" onClick={this.props.handleClick}>
                  LOGIN
                </Link>
              </li>
            )}
            {!this.authed() && (
              <li>
                <Link to="/checkout" onClick={this.props.handleClick}>
                  GET STARTED
                </Link>
              </li>
            )}
          </ul>
        </div>
        <StickyFooter />
      </div>
    );
  }
}

export default NavModal;
