import React, { useContext } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { GiftCardFlowContext } from "../GiftCardFlowContext";
import "styled-components/macro";
import { PhysicalCard3 } from "../assets";
import { yumiPurple } from "../../../styledcomponents";
import { H3, H1, Body } from "../../../styledcomponents/typefaces";
import { Hazmat, Nutrition, Calendar } from "../../OneOffPurchase/assets";

export const StepFive = () => {
  const { progress } = useContext(GiftCardFlowContext);

  const amount = (progress.amount / 100).toFixed(2);
  return (
    <div css="width:100%; display:flex;flex-direction:column;align-items:center;">
      <StepContainer>
        <img
          src={PhysicalCard3}
          alt="physical-card"
          css={css`
            width: 400px;
            @media (max-width: 500px) {
              width: 200px;
            }
          `}
        />
        <H1
          css={css`
            padding-top: 25px;
            width: 40%;
            text-align: center;
            color: ${yumiPurple};

            @media (max-width: 500px) {
              width: 75%;
              font-size: 30px;
            }
          `}
        >
          Thanks for your purchase!
        </H1>
        <H3
          css={css`
            padding-top: 25px;
            text-align: center;

            @media (max-width: 500px) {
              font-size: 20px;
            }
          `}
        >
          Summary:
        </H3>
        <Body>
          Gift card number:{" "}
          <span
            css={css`
              color: ${yumiPurple};
            `}
          >
            {progress.gift_code}{" "}
          </span>
        </Body>
        <Body>
          Amount:{" "}
          <span
            css={css`
              color: ${yumiPurple};
            `}
          >
            ${amount}
          </span>
        </Body>
        <H3
          css={css`
            padding-top: 25px;
            text-align: center;
            @media (max-width: 500px) {
              font-size: 20px;
            }
          `}
        >
          Sent to:
        </H3>
        <Body>{progress.recepient_name}</Body>
        <Body>{progress.recepient_email}</Body>
      </StepContainer>
      <ExtrasContainer>
        <H3
          css={css`
            padding-top: 25px;
            text-align: center;
            color: ${yumiPurple};

            @media (max-width: 500px) {
              font-size: 25px;
            }
          `}
        >
          Your gift also includes these goodies:
        </H3>
        <Bonus>
          <ImageAndText>
            <img src={Hazmat} alt="haz" css="width:200px" />
            <Body css="font-size:17px;">Baby Hazmat Suit - x1</Body>
          </ImageAndText>
          <ImageAndText>
            <img src={Nutrition} alt="nutri" css="width:200px" />
            <Body css="font-size:17px;">Nutritional Coaching Session - x1</Body>
          </ImageAndText>
          <ImageAndText>
            <img src={Calendar} alt="cal" css="width:200px" />
            <Body css="font-size:17px;">Intro to Solids Guide - x1</Body>
          </ImageAndText>
        </Bonus>
      </ExtrasContainer>
    </div>
  );
};

const ImageAndText = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const Bonus = styled.div`
  display: flex;
  width: 80%;
  padding-top: 50px;
  padding-bottom: 80px;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    align-self: Center;
    width: 50%;
  }
`;
const ExtrasContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;

  justify-content: center;
`;
const StepContainer = styled.div`
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  sjustify-content: center;
  width: 100%;
  padding-bottom: 50px;
  background: #fdebe0;
  overflow: hidden;
`;
