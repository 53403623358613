import * as types from "../actions/types";

const checkoutReducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_CHECKOUT_INFO: {
      const { payload } = action
      return {
        ...state,
        ...payload
      };
    }

    case types.CLEAR_CHECKOUT_INFO: {
      return {}
    }

    default:
      return state;
  }
};

export default checkoutReducer;
