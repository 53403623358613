import React from "react";
import styled, { css } from "styled-components";
import { H3, blackberry, Body1, yumiOrange } from "../../../styledcomponents";
import { PageSource, LightBulb } from "../assets";
import "styled-components/macro";

const articles = [
  {
    link:
      "https://www.amazon.com/Whats-Going-There-Brain-Develop/dp/0553378252",
    citation:
      "Eliot, Lise. What’s Going On In There? How the Brain and Mind Develop in the First Five Years of Life. Bantam Books, 2000."
  },
  {
    link:
      "https://www.amazon.com/Whats-Going-There-Brain-Develop/dp/0553378252",
    citation:
      "Libertus, Klaus & Violi, Dominic. (2016). Sit to Talk: Relation between Motor Skills and Language Development in Infancy. Frontiers in Psychology. 7. . 10.3389/fpsyg.2016.00475."
  },
  {
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4991921/",
    citation:
      "“Office of Dietary Supplements - Calcium.“ NIH Office of Dietary Supplements, U.S. Department of Health and Human Services, 17 Nov. 2016, ods.od.nih.gov/factsheets/Calcium-Consumer/."
  },
  {
    link:
      "https://www.thelancet.com/pb/assets/raw/Lancet/stories/series/nutrition-eng.pdf",
    citation:
      "“Thelancet.com.“ (2019). Maternal and Child Nutrition. [online] Available at: https://www.thelancet.com/pb/assets/raw/Lancet/stories/series/nutrition-eng.pdf."
  }
];

export const CheckOurSources = ({ alt, sources = articles }) => (
  <CheckSourcesContainer>
    <HeaderContainer>
      <img src={LightBulb} alt="bulbous" style={{ padding: "30px" }} />
      <H3
        style={{ color: alt ? yumiOrange : blackberry }}
        css={css`
          @media (max-width: 1000px) {
            font-size: 20pt !important;
          }
        `}
      >
        Check our sources
      </H3>
      <Body1
        style={{ fontSize: "20pt", marginTop: "20px" }}
        css={css`
          @media (max-width: 1000px) {
            font-size: 12pt !important;
          }
        `}
      >
        We nerd out so you don't have to. Our research is backed by thousands of
        clinical studies. Below are a few standouts.
      </Body1>
    </HeaderContainer>
    <ArticleList>
      {sources.map(article => (
        <a href={article.link} key={article.citation}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "35px",
              borderTop: "1px solid #eee",

              borderBottom: "1px solid #eee"
            }}
          >
            <img src={PageSource} alt="page" style={{ width: "50px" }} />
            <Body1
              style={{
                marginLeft: "10px",
                fontSize: "14pt",
                overflow: "scroll"
              }}
              css={css`
                @media (max-width: 1000px) {
                  font-size: 11pt !important;
                  max-width: 200px !important;
                }
              `}
            >
              {article.citation}
            </Body1>
          </div>
        </a>
      ))}
    </ArticleList>
  </CheckSourcesContainer>
);

const ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  padding: 40px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 1000px) {
    width: 70%;
  }
`;
const CheckSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
