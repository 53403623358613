import * as types from "../actions/types";

const screensReducer = (state = "homepage", action) => {
  switch (action.type) {
    case types.ROUTE_TO_CHECKOUT:
      return "checkout";
    case types.ROUTE_TO_HOMEPAGE:
      return "homepage";
    case types.ROUTE_TO_ACCOUNT:
      return "account";
    default:
      return state;
  }
};

export default screensReducer;
