import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { PrivateRoute } from "../../containers/App/PrivateRoute";
import Account from "./containers/Account";
import {
  Settings,
  Milestones,
  Progress,
  CancelSubscription,
  BillingHistory,
  Resubscribe
} from "./Pages";
import { logout as logoutAction } from "../../redux/actions/Auth";

const SettingsRoutes = ({ user, isAuthenticated, logout }) => (
  <React.Fragment>
    <PrivateRoute
      path="/profile"
      component={() => <Account user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <Route
      exact
      path="/profile/"
      render={() => <Redirect to="/profile/settings" />}
    />
    <PrivateRoute
      path="/profile/progress"
      component={() => <Progress user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      path="/profile/milestones"
      component={() => <Milestones user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      exact
      path="/profile/settings"
      component={() => <Settings user={user} logout={logout} />}
      isAuthenticated={isAuthenticated}
    />

    <PrivateRoute
      path="/profile/settings/resubscribe"
      component={() => <Resubscribe user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      path="/profile/settings/cancel"
      component={() => <CancelSubscription user={user} />}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute
      path="/profile/billing/"
      component={() => <BillingHistory user={user} />}
      isAuthenticated={isAuthenticated}
    />
  </React.Fragment>
);

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction())
});

export default connect(
  null,
  mapDispatchToProps
)(SettingsRoutes);
