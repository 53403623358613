import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import Autocomplete from "react-google-autocomplete";
import {
  InputField,
  ModalButton,
  FormContainer,
  Card,
  ButtonContainer,
  Label,
  EditHeader,
  CardTitle
} from "../components/styledComponents";
import Modal from "../components/Modal";
import {
  removeShippingAddress,
  updateShippingAddress
} from "../../../usecases/users";
import DeliveryIcon from "../../../../assets/icons/Dashboard-icons/truck-icon.svg";

export const DeliveryAddress = ({
  activeModal,
  openModal,
  openAddModal,
  close,
  user,
  success
}) => {
  const { addresses, name } = user;
  const initialState = {
    street: "",
    street2: "",
    city: "",
    state: "",
    postcode: ""
  };
  const [form, updateForm] = useState(initialState);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const update = field => e => updateForm({ ...form, [field]: e.target.value });

  const mapPlaceToState = place => {
    const address = place.address_components;
    const getSection = type =>
      address.find(({ types }) => types.includes(type));

    const street = `${_.get(getSection("street_number"), "long_name", "")} ${
      getSection("route").long_name
    }`;
    const postcode = getSection("postal_code").long_name;
    const state = getSection("administrative_area_level_1").short_name;
    const city =
      getSection("locality") !== undefined
        ? getSection("locality").long_name
        : getSection("sublocality").long_name;

    updateForm({ ...form, street, state, city, postcode });
  };

  return (
    <React.Fragment>
      <Card icon={DeliveryIcon} onClick={openModal}>
        <CardTitle>Addresses</CardTitle>
      </Card>
      <Modal isOpen={activeModal === "DeliveryAddress"} close={close}>
        <EditHeader close={close}>Delivery addresses</EditHeader>
        <DeliveryAddressForm
          name={name}
          close={close}
          addresses={addresses}
          openAddModal={openAddModal}
          activeModal={activeModal}
          user={user}
          openModal={openModal}
        />
      </Modal>
      <Modal isOpen={activeModal === "AddAddress"} close={close}>
        <EditHeader close={close}>Add address</EditHeader>
        <FormContainer
          onSubmit={async e => {
            e.preventDefault();
            const body = {
              ...form,
              id: null,
              name,
              label: `${name} address`,
              default: 1
            };
            try {
              setProcessing(true);
              await updateShippingAddress(user.id, body);
              updateForm(initialState);
              setProcessing(false);
              close();
              success();
            } catch (_e) {
              setProcessing(false);
              setError(
                "Something went wrong. Please check your form and try again."
              );
            }
          }}
        >
          <Label htmlFor="street">Address</Label>
          <Autocomplete
            types={["address"]}
            componentRestrictions={{ country: "us" }}
            style={autoCompleteStyle}
            onPlaceSelected={mapPlaceToState}
            value={form.street}
            onChange={update("street")}
          />

          <Label htmlFor="street2">Apt. number</Label>
          <InputField
            onChange={update("street2")}
            value={form.street2}
            type="text"
            name="street2"
            id="street2"
          />

          <Label htmlFor="city">City</Label>
          <InputField
            onChange={update("city")}
            value={form.city}
            type="text"
            name="city"
            id="city"
          />
          <Label htmlFor="state">State</Label>
          <select
            value={form.state}
            onChange={update("state")}
            id="state"
            style={{ width: "20%" }}
          >
            {stateAbbreviations.map((state, ind) => (
              <option value={state} key={state + ind}>
                {state}
              </option>
            ))}
          </select>
          <Label htmlFor="postcode">Zip</Label>
          <InputField
            onChange={update("postcode")}
            value={form.postcode}
            type="number"
            name="postcode"
            id="postcode"
          />

          <ButtonContainer>
            <ModalButton cancel type="button" value="Cancel" onClick={close} />
            <ModalButton type="submit" value="Save" disabled={processing} />
          </ButtonContainer>
        </FormContainer>
        <p style={{ color: "red" }}>{error}</p>
      </Modal>
    </React.Fragment>
  );
};

const stateAbbreviations = [
  "CA",
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

const Address = ({ address, user, refresh, len, name }) => {
  const isDefaultAddress = (len && len === 1) || address.default;
  const isOnlyAddress = len && len > 1;
  const { street, street2, city, state, postcode, address_id } = address;
  return (
    <AddressCard>
      <AddressBody>
        <p>{street}</p>
        {street2 && <p>Apartment {street2}</p>}
        <p>
          {city}, {state} {postcode}
        </p>
      </AddressBody>
      <AddressButtons>
        {isOnlyAddress && !isDefaultAddress ? (
          <RemoveButton
            onClick={e => {
              e.stopPropagation();
              removeShippingAddress(user.id, { address_id }).then(refresh);
            }}
          >
            Remove
          </RemoveButton>
        ) : null}

        {isDefaultAddress ? (
          <p style={{ color: "#2fab4f", fontFamily: "Platform-Medium" }}>
            Default
          </p>
        ) : (
          <RemoveButton
            onClick={() => {
              updateShippingAddress(user.id, {
                id: address_id,
                street,
                street2,
                city,
                name,
                label: `${name} address`,
                state,
                postcode,
                default: 1,
                userId: user.id
              }).then(refresh);
            }}
          >
            Make default
          </RemoveButton>
        )}
      </AddressButtons>
    </AddressCard>
  );
};

const DeliveryAddressForm = ({
  addresses,
  openAddModal,
  user,
  openModal,
  name
}) => {
  const refresh = () => {
    openAddModal();
    openModal();
  };
  return (
    <React.Fragment>
      {addresses.map(address => (
        <Address
          key={address.address_id}
          name={name}
          address={address}
          user={user}
          refresh={refresh}
          len={addresses.length}
        />
      ))}
      <AddAddress onClick={openAddModal}>+Add Address</AddAddress>
    </React.Fragment>
  );
};
const autoCompleteStyle = {
  marginBottom: "32px",
  lineHeight: "1",
  borderBottom: "1px solid rgba(0,0,0, .07)",
  padding: "5px 5px",
  width: "100%"
};

const AddressCard = styled.div`
  font-family: "Avenir", sans-serif;
  display: flex;
  flex-direction: row;
  border-bottom: 32px;
  border-radius: 10px;
  justify-content: space-between;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 18px;
  padding-bottom: 18px;

  border: 1px solid rgba(0, 0, 0, 0.07);

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const AddressBody = styled.div`
  > p {
    line-height: 1.5;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  font-size: 15px;
`;
const RemoveButton = styled.div`
  margin-right: 5px;
  cursor: pointer;
  align-self: center;
  font-family: "Platform-medium", sans-serif;
  font-size: 15px;
`;

const AddAddress = styled.div`
  align-self: flex-start;
  color: black;
  font-family: "Platform-Medium", sans-serif;
  border-bottom: 32px;
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const AddressButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  }
`;
