import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { HyperLink, yumiGreen } from "../../../styledcomponents";
import { H3 } from "../../../styledcomponents/typefaces";

export const Mashable =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/Mashable.svg";

export const NYT =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/nyt.svg";

export const goop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/goop.svg";

export const TalkOfTheTown = () => (
  <OuterContainer>
    <TalkOfTheTownContainer>
      <H3
        style={{
          color: yumiGreen,
          fontSize: "27pt"
        }}
        css={css`
          @media (max-width: 1000px) {
            padding-bottom: 40px;
          }
        `}
      >
        Talk of the Town
      </H3>
      <div css="display:flex">
        <div
          style={{
            display: "flex",
            padding: "50px",
            flexWrap: "wrap"
          }}
          css={css`
            @media (max-width: 1000px) {
              justify-content: flex-start;
              padding: 0px !important;
            }
          `}
        >
          <HyperLink
            css="text-align: center;"
            href="https://mashable.com/article/yumi-baby-food-subscription/"
          >
            {" "}
            <img
              style={{ width: "140px" }}
              src={Mashable}
              alt="mash"
              css={css`
                @media (max-width: 1000px) {
                  width: 140px !important;
                  margin-left: 30px !important;
                }
              `}
            />
          </HyperLink>
          <HyperLink
            css="text-align: center;"
            href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html"
          >
            {" "}
            <img
              style={{ width: "190px", marginLeft: "30px" }}
              css={css`
                @media (max-width: 1000px) {
                  padding-top: 33px !important;
                }
              `}
              src={NYT}
              alt="nyt"
            />
          </HyperLink>
          <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
            <img
              style={{
                width: "70px",
                marginLeft: "30px",
                position: "relative"
              }}
              css={css`
                @media (max-width: 1000px) {
                  padding-top: 30px !important;
                }
              `}
              src={goop}
              alt="goop"
            />
          </HyperLink>
        </div>
      </div>
    </TalkOfTheTownContainer>
  </OuterContainer>
);

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const TalkOfTheTownContainer = styled.div`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding-top: 90px;
  padding-bottom: 50px;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    margin-top: 30px !important;

    margin-bottom: 20px;
  }
`;
