import React, { useContext, useState } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { GiftCardFlowContext } from "../GiftCardFlowContext";
import "styled-components/macro";
import { PurpleButton, LargePurpleHeader, KiwiAndPine } from "../assets";
import { yumiPurple, InputField } from "../../../styledcomponents";
import { H3 } from "../../../styledcomponents/typefaces";
import { useFormState } from "../../../hooks";

export const StepThree = () => {
  const { progress, dispatch, history } = useContext(GiftCardFlowContext);

  const [form, setField] = useFormState({
    message: "",
    recepient_name: "",
    recepient_email: "",
  });

  const [error, setError] = useState("");

  return (
    <StepContainer>
      <LargePurpleHeader css="text-align:center;">
        Great choice! <br /> Just a few more details.
      </LargePurpleHeader>
      <MessageInput
        id="giftMessage"
        value={form.messsage}
        onChange={setField("message")}
        placeholder="Write your message here..."
      />
      <WhoForm>
        <H3
          css={css`
            color: ${yumiPurple};
            padding-bottom: 40px;

            @media (max-width: 800px) {
              text-align: center;
            }
          `}
        >
          Who should we deliver this gift to?
        </H3>
        <InputField
          value={form.recepient_name}
          onChange={setField("recepient_name")}
          placeholder="Recipient Name"
          id="recipientName"
        />
        <InputField
          value={form.recepient_email}
          onChange={setField("recepient_email")}
          css="margin-top:15px;"
          type="email"
          placeholder="Recipient Email Address"
          id="recipientEmail"
        />
      </WhoForm>
      {error && error.length > 0 ? (
        <span
          style={{
            color: "red",
            marginBottom: "10px",
            background: "white",
            zIndex: 10,
          }}
        >
          {error}
        </span>
      ) : null}
      <PurpleButton
        value="NEXT"
        css="margin-bottom:30px;"
        onClick={() => {
          if (
            !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              form.recepient_email
            )
          ) {
            setError(
              "Please enter a different recepient email or check that the one you entered is correct."
            );
            return;
          }

          dispatch({
            type: "COMPLETE_STEP_THREE",
            digital: progress.digital,
            coupon: progress.coupon,
            recepient_email: form.recepient_email,
            recepient_name: form.recepient_name,
            message: form.message,
          });
          history.push("/gift-card/3");
        }}
      />
    </StepContainer>
  );
};

const WhoForm = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: Center;
  padding-bottom: 40px;
`;

const MessageInput = styled.textarea`
  padding: 90px;
  width: 469px;
  height: 320px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  font-family: "Freight-Book";
  font-size: 16px;
  outline: none;
  background-image: url(${KiwiAndPine});
  resize: none;
  border-radius: 1px;
  transition: all 0.2s ease-out;

  &:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease-in;
  }

  @media (max-width: 800px) {
    width: 336px;
    height: 202px;
    padding: 65px;
    font-size: 12px;
    background-size: 77px;
  }
`;
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 15px;
  background: #fefefe;
  overflow: hidden;
`;
