import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  oat,
  H2,
  blackberry,
  Body1,
  BlueButton,
  H6
} from "../../../styledcomponents";
import { MilestoneHero } from "../assets";
import "styled-components/macro";

export const GrowBabyGrow = () => (
  <GrowBabyContainer>
    <GrowOptions>
      <H2
        style={{ color: blackberry, fontSize: "70px" }}
        css={`@media(max-width:1000px){
        font-size:45px !important; text-align:center !important;
      } }}`}
      >
        Grow baby grow
      </H2>
      <Body1
        style={{ marginTop: "2.5vh", width: "80%", fontSize: "22px" }}
        css={`@media(max-width:1000px){
        font-size:15px !important; text-align:center !important;
      } }}`}
      >
        Our milestone plan was designed to keep pace with your ever evolving
        babe. Let our dietitian select jars based on your baby's age and
        developmental needs.
      </Body1>
      <Link style={{ textDecoration: "none" }} to="/checkout">
        <BlueButton
          style={{
            background: blackberry,
            color: "white",
            padding: "20px",
            fontSize: "15pt",
            marginTop: "6vh"
          }}
          css={css`
            @media(max-width:1000px){
              font-size:11pt !important;
            }
          }}`}
        >
          Starting at $35 per week
        </BlueButton>
      </Link>
      <H6
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "15pt",
          color: blackberry,
          marginTop: "15pt"
        }}
        css={css`
          @media(max-width:1000px){
            font-size: 10pt !important;
          }
        }}`}
      >
        Free Shipping{" "}
        <p style={{ margin: "0.5rem", color: "lightgray" }}>&#8226;</p> Skip or
        cancel anytime
      </H6>
    </GrowOptions>
    <GrowImage>
      <img
        src={MilestoneHero}
        alt="baby"
        style={{ width: "500px" }}
        css=" @media(max-width:1000px){width:300px !important;}"
      />
    </GrowImage>
  </GrowBabyContainer>
);

const GrowImage = styled.div`
  align-self: flex-end;
  margin-right: 100px;
  @media (max-width: 1000px) {
    align-self: center;
  }
`;
const GrowOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: 1000px) {
    width: 70%;
    align-items: center;
  }
`;
const GrowBabyContainer = styled.div`
  background: ${oat};
  display: flex;
  width: 100%;
  padding-left: 130px;
  padding-top: 100px;
  @media (max-width: 1000px) {
    padding: 0px;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
  }
`;

/* const SendGiftArea = styled.div`
  display: flex;
  flex-direction: row;
  background: ${oat};
  justify-content: center;
  width: 100%;
  padding-left: 9vh;
  padding-right: 9vh;
  padding-top: 8vh;
  padding-bottom: 15vh;

  @media (max-width: 1000px) {
    padding-top: 0px;
  }
`; */
