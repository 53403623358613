import React, { useEffect, useState } from "react";
import "./styles/styles.css";
import styled, { css } from "styled-components";
import "styled-components/macro";

import spacetime from "spacetime";
import { formatDateWithDayOfWeek } from "../../utils/dates";
import * as buttonGroups from "./types";
import ActionsButtonGroup from "./components/ActionsButtonGroup";

function Order({ details, order, previous, dispatch }) {
  const [buttonGroupType, setGroup] = useState(null);

  const getColor = menuId => {
    switch (menuId) {
      case 8:
        return "#000000";
      case 9:
        return "#8db9db";
      case 12:
        return "#F1A68C";
      case 11:
        return "#f6da71";
      case 10:
        return "#7dbb54";
      default:
        return "#7dbb54";
    }
  };

  const getTotalJars = orderData =>
    orderData.reduce(
      (sum, { quantity }) => sum + Number.parseInt(quantity, 10),
      0
    );

  const getShippingStatus = () => {
    if (order.shipments.length > 0 && order.shipments[0].delivered) {
      return "Delivered";
    }
    if (order.shipments.length > 0) {
      return (
        <React.Fragment>
          <a
            href={`https://yumi.aftership.com/${
              order.shipments[0].tracking_number
            }`}
            css={css`
              color: black;
            `}
            target="_blank"
          >
            Track Your Shipment
          </a>
        </React.Fragment>
      );
    }
    if (order.paused) {
      return "Skipped";
    }
    return "Estimated Delivery";
  };

  const getOrderDetailsString = orderData => {
    const orderDetails = [];
    orderData.forEach(item => {
      orderDetails.push(`${`(x${item.quantity})` + " "}${item.meal_name}`);
    });

    return `${getTotalJars(orderData)} Jars: ${orderDetails.join(", ")}`;
  };

  const getChangeOrderCutoffDate = deliveryDate => {
    const orderDate = spacetime(deliveryDate).goto("America/Los_Angeles");
    const oneWeekBeforeOrderDate = orderDate
      .subtract(6, "days")
      .hour(23)
      .minutes(59);
    return oneWeekBeforeOrderDate;
  };
  const isOrderEditable = _order =>
    getChangeOrderCutoffDate(_order.delivery_date).isBefore(
      spacetime.now().goto("America/Los_Angeles")
    );

  useEffect(() => {
    if (previous) {
      /*
       * Uncomment following line when ratings are added to backend
       */
      // setGroup(buttonGroups.RATE_BUTTON_GROUP)
      setGroup(null);
    } else if (!isOrderEditable(order) && order.paused) {
      setGroup(buttonGroups.SKIPPED_BUTTON_GROUP);
    } else if (previous) {
      // setGroup(buttonGroups.RATE_BUTTON_GROUP)
    } else if (isOrderEditable(order)) {
      setGroup(buttonGroups.UNEDITABLE_BUTTON_GROUP);
    } else {
      setGroup(buttonGroups.EDITABLE_BUTTON_GROUP);
    }
  });

  return (
    <OrderContainer
      className="order_container"
      color={getColor(order.color_id)}
      skipped={order.paused}
    >
      <div className="order_information">
        <div
          className={`order_information_shipping${
            order.paused ? " skipped" : ""
          }`}
        >
          {getShippingStatus()}
        </div>
        <div className="order_information_header">
          {formatDateWithDayOfWeek(order.delivery_date)}
        </div>
        <div
          className={`order_information_details${
            order.paused ? " skipped" : ""
          }`}
        >
          {getOrderDetailsString(details)}
        </div>
      </div>
      <div className="order_actions_container">
        <ActionsButtonGroup
          dispatch={dispatch}
          order={order}
          group={buttonGroupType}
          color={getColor(order.color_id)}
        />
      </div>
    </OrderContainer>
  );
}

const OrderContainer = styled.div`
  display: flex;
  background-color: ${props => props.color};
  border-radius: 8px;

  ${props =>
    props.skipped &&
    css`
      background-color: white;
      border-radius: 8px;

      @media (max-width: 1000px) {
        margin-top: 60px;
      }
    `};
`;

export default Order;
