import React, { useState } from "react";
import _ from "lodash";
import {
  H6,
  BigButton,
  Box,
  Body1,
  Form,
  RadioInput,
  HR,
  Body2,
  SnackReceipt
} from "../index";
import { useRequest } from "../../hooks/useFetch";
import { getUserSnacks } from "../../usecases/users";
import { placeSnackAddOnOrder } from "../../usecases/orders";
import Puffs from "../../pages/BitesPage/images/puffs.png";
import { getNextDeliveryDate, formatDatePretty } from "../../utils/dates";

export const SnacksAddOn = ({ user, style, showConfirmation = () => null }) => {
  const [snacksData, loading, req_err] = useRequest(() =>
    getUserSnacks(_.get(user, "id", "2"))
  );

  const [planIndex, setPlanIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isConfirm, setConfirm] = useState(false);

  if (req_err) {
    showConfirmation();
    return "";
  }
  if (loading) return "loading...";

  const { plans } = snacksData;
  const selectedPlan = plans[planIndex];

  const snackOrders = [
    { quantity: 4, tax: 1.43 },
    { quantity: 8, tax: 2.66 },
    { quantity: 16, tax: 5.7 },
    { quantity: 24, tax: 8.56 }
  ];

  const getTax = quantity => _.find(snackOrders, { quantity });

  const submitAddOnOrder = async () => {
    placeSnackAddOnOrder({
      snacks: selectedPlan.snacks.map(snack => ({
        menu_id: snack.menu_id,
        quantity: snack.quantity,
        meal_id: snack.meal_id
      }))
    })
      // TODO: Add some proper error handling / displaying here, to come on the updated orders page.
      .then(() => true)
      .catch(() => false);
  };
  if (!isVisible)
    return (
      <Box style={{ marginTop: "32px" }}>
        <div>
          <Body1>Thanks. Your order has been placed</Body1>
        </div>
      </Box>
    );
  return (
    <Box
      style={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "32px",
        borderRadius: "8px",
        background: "white",
        ...style
      }}
    >
      <div>
        <H6>Don{"'"}t Forget Snacks!</H6>
      </div>
      <Box
        row
        style={{
          background: "#B2A8E7",
          borderRadius: "8px",
          width: "164px",
          height: "164px",
          alignItems: "center"
        }}
      >
        <img src={Puffs} height="100px" alt="beet puffs jar" />
      </Box>
      <div>
        <Body2>The Tot Box</Body2>
      </div>
      <div>
        <Body1>${selectedPlan.withDiscount}</Body1>
      </div>
      <div style={{ textAlign: "center" }}>
        <Body2>
          <a href="/menu" target="_blank" rel="noopener noreferrer">
            Read more
          </a>
        </Body2>
      </div>
      <Form
        style={{ marginTop: "32px" }}
        onSubmit={async () => {
          await submitAddOnOrder();
          showConfirmation();
          setIsVisible(false);
        }}
      >
        <HR /> <br />
        <Body1>Select your size</Body1>
        <Box style={{ width: "80%" }}>
          {plans.map(({ plan_boxes, plan_size }, index) => (
            <RadioInput
              id={`${plan_boxes}box`}
              key={`${plan_boxes}box`}
              value={plan_size}
              label={`${plan_boxes} ${plan_boxes === 1 ? "box" : "boxes"}`}
              checked={selectedPlan.plan_size === plan_size}
              onChange={() => setPlanIndex(index)}
            />
          ))}
        </Box>
        <HR />
        {!isConfirm ? (
          <React.Fragment>
            <BigButton value="Add Tot Box" onClick={() => setConfirm(true)} />{" "}
            <br />
            <BigButton
              value="No thanks"
              type="button"
              nofill
              onClick={() => {
                setIsVisible(false);
                showConfirmation();
              }}
            />
          </React.Fragment>
        ) : null}
        {isConfirm ? (
          <React.Fragment>
            <SnackReceipt
              date={formatDatePretty(getNextDeliveryDate())}
              tax={getTax(selectedPlan.plan_size).tax}
              numSnacks={selectedPlan.plan_size}
              total={
                parseFloat(selectedPlan.withDiscount) +
                getTax(selectedPlan.plan_size).tax
              }
            />
            <BigButton value="Add Tot Box" type="submit" /> <br />
            <BigButton
              value="No thanks"
              type="button"
              nofill
              onClick={() => {
                setIsVisible(false);
                showConfirmation();
              }}
            />
          </React.Fragment>
        ) : null}
      </Form>
    </Box>
  );
};

export default SnacksAddOn;
