import { useEffect, useState } from "react";
import _ from "lodash";

export const useRequest = (req, memo = []) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(0);
  const forceFetch = () => setUpdateIndex((i) => i + 1);

  let isMounted;
  const fetchUrl = async () => {
    if (isMounted && req() !== void 0) {
      const response = await req().catch(() => setError(true));
      if (isMounted) {
        setData(response);
        setLoading(false);
      }
    }
  };

  useEffect(
    () => {
      isMounted = true;

      fetchUrl();

      return () => {
        isMounted = false;
      };
    },
    [updateIndex, ..._.flatten(memo)]
  );

  return [data, loading, error, forceFetch];
};

export default useRequest;
