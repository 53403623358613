import React from "react";
import styled, { css } from "styled-components";
import { Container, yumiOrange } from "../../../styledcomponents";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { LazyImage } from "../../../styledcomponents/LazyImage";

import { OrangeLinkButton, SecondGif } from "../assets";
import "styled-components/macro";

export const SuperfoodForSuperbabies = ({ width }) => (
  <Container css="background:white;z-index:100;">
    <InnerGrid>
      <div />
      <Left>
        <LazyImage
          src={SecondGif}
          alt="babygif"
          css=" width:600px; @media(max-width:500px){

          width:100%;
        }"
        />
      </Left>
      <Right>
        <H1
          css={css`
            color: ${yumiOrange};
            padding-bottom: 30px;
          `}
        >
          Superfoods for Superbabies
        </H1>
        <Body css=" padding-bottom:30px; @media(max-width:500px){width:85%;}">
          Our menu changes weekly and rotates between 70 flavors featuring 100+
          fruits, veggies, superfoods and spices from around the world.
        </Body>
        <OrangeLinkButton
          css={css`
            width: 350px;
          `}
          value="EXPLORE OUR MENU"
          to="/menu"
        />
      </Right>
      <div />
    </InnerGrid>
  </Container>
);

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr 1.4fr 0.5fr;
  grid-template-rows: 700px;
  padding-top: 100px;

  @media (max-width: 500px) {
    grid-template-columns: 100%;
    grid-template-rows: 0px 1fr 1fr 0px;
    padding-top: 0px;
  }
`;

const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 100%;
  padding-left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 500px) {
    padding-left: 30px;
    padding-top: 0px;
  }
`;
