import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { TopWave, WePlan, LimitThe, DesignedTo, ScienceBased } from "../assets";
import { oat, oatAdjusted, H6, Body1 } from "../../../styledcomponents";
import { H1 } from "../../../styledcomponents/typefaces";

const OurMissionSteps = [
  {
    icon: LimitThe,
    title: "Limit The Sweet Stuff",
    description:
      "Unlike store-bought brands, we keep the total amount of fructose as low as possible. We also balance our blends with fiber, because fiber helps slow down the absorption of sugar. In the long term, you're setting them up to reach for veggies over candy."
  },
  {
    icon: DesignedTo,
    title: "Designed To Meet Daily Recommended Values",
    description:
      "Each blend has at least 20% of the recommended daily amount in 5 key nutrients or more. It's like a multivitamin, but with real food."
  },
  {
    icon: ScienceBased,
    title: "Science-based, Evidence-led",
    description:
      "Every ingredient has a purpose, your baby's meals and content are mapped against thousands of clinical studies and reviewed by our experts. We nerd out so you don't have to."
  },
  {
    icon: WePlan,
    title: "We Plan For Developmental Milestones",
    description:
      "In the first 1000 days, your baby grows at an accelerated rate. Our meals are loaded with essential vitamins and minerals to make sure your baby gets all the nutrients they need at every key milestone."
  }
];
const GiftAFriendBlend =
  "https://email.helloyumi.com/assets/_GiftAFriend/GiftAFriend_Hero.png";

export const FoodsThatNourish = () => (
  <PageLayout>
    <TopWaveArea src={TopWave} alt="wave" />
    <MissionArea>
      <img
        src={GiftAFriendBlend}
        alt="peach puddin"
        style={{ width: "700px" }}
        css={css`
          @media (max-width: 1000px) {
            width: 320px !important;
          }
        `}
      />
      <H1
        style={{
          color: "#673E21",
          paddingTop: "56px",
          paddingBottom: "56px",
          width: "35%",
          textAlign: "center"
        }}
        css={css`
          @media (max-width: 1000px) {
            font-size: 24pt !important;
            width: 90% !important;
            padding-bottom: 15px !important;
          }
        `}
      >
        We Choose Foods That Nourish
      </H1>

      <FourMissionSteps>
        {OurMissionSteps.map(missionStep => (
          <MissionCard
            icon={missionStep.icon}
            title={missionStep.title}
            description={missionStep.description}
          />
        ))}
      </FourMissionSteps>
    </MissionArea>
  </PageLayout>
);

const MissionCard = ({ icon, title, description }) => (
  <MissionCardContainer>
    <div>
      <img src={icon} alt="step-icon" style={{ paddingBottom: "20px" }} />
      <H6 style={{ color: "#673E21", fontSize: "15pt" }}>{title}</H6>
    </div>

    <Body1
      style={{
          fontSize: "12pt",
          paddingTop: "15px",
          width: "320px"
        }}
    >
      {description}
    </Body1>
  </MissionCardContainer>
  );

const MissionCardContainer = styled.div`
  background: white;
  width: 360px;
  height: 326px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 45px;

  @media (max-width: 1000px) {
    width: 350px;
    height: 320px;
    margin-top: 15px;
  }
`;
const FourMissionSteps = styled.div`
  display: grid;
  grid-template-rows: 340px 340px;
  grid-template-columns: 376px 376px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    grid-template-columns: 350px;
    grid-template-rows: 340px 340px 340px 340px;
  }
`;
const MissionArea = styled.div`
  background: ${oatAdjusted};
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TopWaveArea = styled.img`
  width: 100%;
  padding-bottom: none;
`;
const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
