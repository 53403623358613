import { useEffect } from "react";

export default () => {
  useEffect(() => {
    if (window.location.hostname.includes('helloyumi.com')) {
      document.body.style.display = "none";
      window.location.reload();
    }
  }, []);
};
