import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TryBites = () => (
  <Container>
    <LeftBox>
      <span>New</span>
      <span>Yumi Tots</span>
    </LeftBox>

    <RightBox>
      <LearnMore to="/bites/info">Learn more</LearnMore>
    </RightBox>
  </Container>
);

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #f97f47;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (max-width: 1000px) {
    position: fixed;
    bottom: 53px;
    top: auto;
    width: 100%;
    height: 67px;
    justify-content: space-between;
  }
  z-index: 899;
`;

const LeftBox = styled.div`
  color: white;
  font-family: "Avenir", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 32px;
`;
const RightBox = styled.div`
  color: white;
  font-family: "Avenir", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 32px;
`;

const LearnMore = styled(Link)`
  font-family: "Avenir", sans-serif;
  color: white;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border: solid 1px #fff;
  padding: 10px;
  border-radius: 32px;

  @media (min-width: 1000px) {
    margin-left: 64px;
  }

  &:hover {
    background: white;
    color: #f97f47;
  }
`;

export default TryBites;
