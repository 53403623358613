import { request } from "../utils/request";

export const getQuad = async (quad_id, delivery_date) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}quads?id=${quad_id}&date=${delivery_date}`
  );

// export const getQuad = async (quad_id, delivery_date) => ({
//   id: 1,
//   name: "Breakfast of Champions Box",
//   description:
//     "Inspired by the best selling by CHLOE. Guac Burger, we're serving up our own version to help babes 9+ months perfect that pincer grasp. This bite is rich in iron, protein and potassium. Serve it warm or whip up your own guac using our by CHLOE. recipe.",
//   category: "Blends",
//   meals: [
//     {
//       meal_id: 478,
//       meal_name: "Peaches & Blackberry Chia Pudding",
//       menu_id: 4,
//       stage: 3,
//       quantity: 1,
//       ingredients: "Peaches, Sweet Potato, Blackberries, Coconut Milk"
//     },
//     {
//       meal_id: 478,
//       meal_name: "Peaches & Blackberry Chia Pudding",
//       menu_id: 4,
//       stage: 3,
//       quantity: 1,
//       ingredients: "Peaches, Sweet Potato, Blackberries, Coconut Milk"
//     }
//   ]
// });
