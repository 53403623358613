import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { Box, Subtitle2 } from "../../../styledcomponents";
import YumiU from "../../../assets/checkout/yumi_U.svg";
import YumiLogo from "../../../../assets/icons/Yumi_logo.svg";
import { useWindowSize } from "../../../hooks";

export const Stepper = ({ step, maxStep }) => {
  const { width } = useWindowSize();
  const logo = width > 1000 ? YumiLogo : YumiU;
  return (
    <Box
      row
      nowrap
      style={{ width: "100%", background: "white" }}
      name="outer box"
    >
      <Link to="/">
        <Logo src={logo} height="18px" />
      </Link>
      <Box
        style={{
          width: "75%",
          padding: "1em",
          textAlign: "center",
          background: "white",
          justifyContent: "space-between",
          maxWidth: "900px"
        }}
        mobileStyle="height: 50px; padding: 10px;"
        name="stepper bar"
        row
      >
        {["Welcome", "Claim Your Box", "Gift Summary"].map((page, i) => {
          const index = i + 1;
          const isCurrent = Number(step) === index;
          if (index <= maxStep) {
            return (
              <LinkBox row key={page} current={isCurrent}>
                <AvailableLink to={`/gift-checkout/${index}`}>
                  <IndexCircle current={isCurrent}>{index}</IndexCircle>
                  <PageLabel>{page}</PageLabel>
                </AvailableLink>
              </LinkBox>
            );
          }
          return (
            <LinkBox row key={page} style={{ opacity: 0.5 }}>
              <AvailableLink to={`/gift-checkout/${maxStep}`}>
                <IndexCircle>{index}</IndexCircle>
                <PageLabel>{page}</PageLabel>
              </AvailableLink>
            </LinkBox>
          );
        })}
      </Box>
    </Box>
  );
};

const Logo = styled.img`
  position: absolute;
  left: 3em;
  top: 1em;
  bottom: auto;
  right: auto;
  @media (max-width: 1000px) {
    left: 10px;
    top: 1em;
  }
`;
const AvailableLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const IndexCircle = styled.div`
  border-style: solid;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  margin-right: 8px;
  border: 1.5px solid #334897;
  font-family: "Platform-Regular", sans-serif;
  color: #334897;
  ${props => props.current && "background: #334897; color: white;"};

  @media (max-width: 1000px) {
    margin-right: 0px;
    margin-bottom: 5px;
  }
`;

const PageLabel = styled(Subtitle2)`
  text-transform: uppercase;
  color: #334897;
  @media (max-width: 1000px) {
    font-size: 9px;
    width: 100%;
    font-family: Avenir, sans-serif;
    text-transform: unset;
  }
`;

const LinkBox = styled(Box)`
  border-bottom: 1px solid transparent;
  flex: 0 0 25%;
  justify-content: center;
  align-items: baseline;

  &:hover {
  }
`;
