import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { H1, H2, Body } from "../../../styledcomponents/typefaces";
import { yumiOrange } from "../../../styledcomponents";
import { OrangeLinkButton, Soil, Grill } from "../assets";

export const HowTheyGetIn = ({ width }) => (
  <KeepContainer>
    {width > 1000 && <div />}
    <div
      css="@media(max-width:1000px){
        display:grid; 
        grid-template-rows: 170px 330px 330px 100px;
        grid-template-columns: 380px;
        margin-left:35px;
    }"
    >
      <H1
        css={css`
          color: ${yumiOrange};
          padding-bottom: 100px;
          width: 60%;
          line-height: 1em;
          padding-top: 70px;
          font-size: 50px;

          @media (max-width: 1000px) {
            width: 90%;
            font-size: 40px;
            padding-top: 0px;
            padding-left: 15px;
          }
        `}
      >
        How do heavy metals get into your baby's food?{" "}
      </H1>
      <SmallInnerGrid>
        <img
          src={Soil}
          alt="soil"
          css="width:188px;@media(max-width:1000px){width:75px; }"
        />
        <BodySection>
          <H2
            css={css`
              color: ${yumiOrange};
              @media (max-width: 1000px) {
                font-size: 20px;
                padding-top: 20px;
              }
            `}
          >
            Contaminated Soil
          </H2>
          <Body
            css="width:75%; width:75%;@media(max-width: 1000px) {
                width:100%;

                        font-size: 20px;
                        };@media(max-width: 800px) {
                        width:85%;
                        }"
          >
            Just because a product is organic, doesn't mean it's necessarily
            good for you or even free of heavy metals. Many heavy metals still
            make their way to plants even in organic soil. That's because
            contaminated water run-off, not pesticides, are the common cause of
            metal in soil.
          </Body>
        </BodySection>
      </SmallInnerGrid>
      <SmallInnerGrid css="padding-top: 40px; padding-bottom:40px;">
        <img
          src={Grill}
          alt="grill"
          css="width:134px; @media(max-width:1000px){width:53px;}"
        />
        <BodySection>
          <H2
            css={css`
              color: ${yumiOrange};
              @media (max-width: 1000px) {
                font-size: 20px;

                padding-top: 20px;
              }
            `}
          >
            Cooking & Handling Processes
          </H2>
          <Body
            css="width:75%;@media(max-width: 1000px) {
                width:100%;

                        font-size: 20px;
                        };@media(max-width: 800px) {
                        width:85%; 
                        }"
          >
            Contaminants, on the other hand, are caused by contaminated cooking
            water, metals used in the food processing equipment, over-processing
            and overheating certain foods.
          </Body>
        </BodySection>
      </SmallInnerGrid>
      <div css="padding-left:15px;padding-top:35px; @media(max-width:1200px){padding-top:10px}; @media(max-width:1000px){padding-top:35px;}">
        <OrangeLinkButton left="20px" value="LEARN ABOUT US" to="/about" />
      </div>
    </div>
  </KeepContainer>
);

const SmallInnerGrid = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1.5fr;
  justify-content: center;
  padding-bottom: 40px;

  @media (max-width: 1000px) {
    grid-template-columns: 500px;
    padding-left: 140px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 350px;
    padding-left: 0px;
  }
`;
const BodySection = styled.div`
  display: flex;
  flex-direction: column;
`;
const KeepContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-template-rows: 1100px;
  justify-content: center;
  align-items: Center;

  @media (max-width: 1200px) {
    grid-template-rows: 1100px;
  }
  @media (max-width: 1000px) {
    grid-template-rows: 1100px;
  }
`;
