/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-for */
import _ from "lodash";
import React, { useState } from "react";
import { H5 } from "@team-yumi/ui";
import styled, { css } from "styled-components";
import "styled-components/macro";
import "./styles.css";

export const Survey = ({ setHigherCheckState }) => {
  const [checkState, setCheckState] = useState([
    {
      item: "fb_ad",
      value: false,
      fancy: "Facebook or Instagram Ad",
    },
    {
      item: "google_search",
      value: false,
      fancy: "Google Search",
    },
    {
      item: "yumi_mail",
      value: false,
      fancy: "Mailer",
    },
    {
      item: "yumi_billboard",
      value: false,
      fancy: "Billboard",
    },
    {
      item: "yumi_podcast_radio",
      value: false,
      fancy: "Podcast / Radio",
    },
    {
      item: "yumi_baby",
      value: false,
      fancy: "Baby Registry",
    },
    {
      item: "yumi_event",
      value: false,
      fancy: "Event",
    },
    {
      item: "yumi_nwp",
      value: false,
      fancy: "Press",
    },
    {
      item: "yumi_ba",
      value: false,
      fancy: "Brand Ambassador",
    },
    {
      item: "yumi_iig",
      value: false,
      fancy: "Yumi’s Instagram, Facebook or Pinterest",
    },
    {
      item: "yumi_friend",
      value: false,
      fancy: "Friend or Family Member",
    },
  ]);

  return (
    <SurveyContainer>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr 1fr;

          @media (max-width: 500px) {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        {checkState.map((i, index) => (
          <label
            css={css`
              padding-bottom: 20px;
              @media (max-width: 700px) {
                padding-bottom: 0px;
              }
            `}
            className="CheckboxContainer"
            key={`check${Math.random()}`}
          >
            <H5 m="0px">{i.fancy}</H5>
            <input
              type="checkbox"
              checked={checkState[index].value}
              onChange={() => {
                const newCheckState = checkState.map((c) => ({
                  ...c,
                  value: false,
                }));
                newCheckState[index].value = true;
                setCheckState(newCheckState);
                setHigherCheckState(newCheckState);
              }}
            />
            <span
              id={index === 0 && "option1"}
              css={css`
                background: white;
                color: white;
                border: 1px solid #dedede;
              `}
              className="CheckBoxcheckmark"
            />
          </label>
        ))}
      </div>
    </SurveyContainer>
  );
};
const SurveyContainer = styled.div`
  z-index: 99999;
  padding: 2px;
  justify-content: flex-start;
  display: flex;

  align-items: center;
  flex-direction: column;
  width: 450px;

  @media (max-width: 500px) {
    height: 390px;
    width: 250px;
  }
`;
