import React from "react";
import injectSheet from "react-jss";

export const styles = {
  root: {},
  table: {
    width: "100%",
    fontStyle: 12,
    borderCollapse: "collapse",
    "& tr": {
      borderBottom: "1px solid black"
    },
    "& tr > td": {
      textAlign: "center",
      "&:first-of-type": {
        textAlign: "left"
      },
      "&:last-of-type": {
        textAlign: "right"
      }
    }
  },
  footNotes: {
    fontSize: 11,
    textAlign: "left",
    marginTop: 3
  }
};

const Vitamins = ({ classes, collection, ingredients }) => (
  <div className={classes.root}>
    <table className={classes.table}>
      <tbody>
        {collection.filter(x => x && x.value !== "0%").map((column, idx) => (
          <tr key={idx.toString()}>
            <React.Fragment key={idx.toString()}>
              <td style={{ fontSize: 11 }}>{column.name}</td>
              <td style={{ fontSize: 11 }}>{column.value}</td>
            </React.Fragment>
          </tr>
        ))}
      </tbody>
    </table>
    <div className={classes.footNotes}>
      Ingredients:{" "}
      {ingredients
        .split(",")
        .map(i => (i.trim() !== "Water" ? `${i}*` : `${i}`))
        .join(",")}
    </div>
    <br />
    <div
      className={classes.footNotes}
      style={{ marginBottom: "-16px", marginTop: "-8px" }}
    >
      *Organic
    </div>
  </div>
);

Vitamins.defaultProps = {
  collection: []
};

export default injectSheet(styles)(Vitamins);
