import setDefaultAddressService from "./setDefaultAddress";
import addAddressService from "./addAddress";
import changeOrderAddressByIdService from "./changeOrderAddressById";
import getAddressesService from "./getAddresses";

export {
  changeOrderAddressByIdService,
  addAddressService,
  setDefaultAddressService,
  getAddressesService
};
