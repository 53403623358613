import { request, put, post } from "../utils/request";

export const applyGift = async body => post(`gift`, body);

export const getGiftCard = async gift_code =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}gift/${gift_code}`
  );

export const redeemGiftCard = async body => post(`gift/redeem`, body);
