import React from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import "styled-components/macro";
import { Label } from "../../styledcomponents/typefaces";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_blends/${name}${extension}`;

export const Hero = getImageURL("Blends-Header", ".png");
export const Tummy = getImageURL("Tummy", ".svg");
export const Sleep = getImageURL("Sleep", ".svg");
export const Skin = getImageURL("Skin", ".svg");
export const Muscles = getImageURL("Muscles", ".svg");
export const Immunity = getImageURL("Immunity", ".svg");
export const Heart = getImageURL("Heart", ".svg");
export const Eyes = getImageURL("Eyes", ".svg");
export const Brain = getImageURL("Brain", ".svg");
export const Bones = getImageURL("Bones", ".svg");
export const Cali = getImageURL("CaliAsset", ".png");

export const SquashAndGreens = getImageURL("SquashAndGreens", ".png");
export const CranSquash = getImageURL("CranSquash", ".png");
export const BellPepperBowl = getImageURL("BellPepperBowl", ".png");

export const DarkBlue = "#324599";
export const BabyBlue = "#668FCA";

export const LinkButton = ({
  to = "/checkout",
  value = "GET STARTED",
  background = BabyBlue,
  color = "white"
}) => (
  <Link
    to={to}
    css={css`
      text-decoration: none;
      margin-top: 30px;
      @media (max-width: 500px) {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        padding: 10px;
        z-index: 899;
        box-shadow: 0 2px 5px 0 #e9e9e9;
        background: #f6da71
          url(https://email.helloyumi.com/assets/bannerStarsDesktop.svg)
          no-repeat;
        background-size: contain;
        left: 0px;
      }
    `}
  >
    <button
      type="button"
      value={value}
      css={`
        background: ${background};
        color: ${color};
        padding: 1.4em 3em 1.4em 3em;
        font-family: "Platform";
        font-size: 13px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <Label>{value}</Label>
    </button>
  </Link>
);
