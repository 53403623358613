/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Link } from "react-router-dom";
import { H2, Box, H3, H6, Image, theme, Input, Button } from "@team-yumi/ui";
import { setPassword } from "../../usecases/checkout";

import { earnPoints } from "../../usecases/reward";
import { CustomModal } from "../Dashboard/views/components/CustomModal";
import { Survey } from "./components/Survey";
import { updateChild, addSource, getUser } from "../../usecases";
import { useRequest } from "../../hooks";

const static_rewards = [
  {
    image: "_RewardCenter/Star.svg",
    points: 100,
    title: "Create an Account",
    body:
      "Earn your first 100 points by setting up your full profile! You'll need to set a password to log into your account.",
  },
  {
    image: "_RewardCenter/Survey.svg",
    points: 100,
    title: "Fill Out Our 1 Question Survey",
    body: "Earn an additonal 100 points.",
  },
  {
    image: "_RewardCenter/Diamond.svg",
    points: 0,
    title: "Explore Your Account and Rewards",
    body:
      "1000 points earns you 10% off a future order and 2,500 points earns you a copy of our Future Baby Board Book! Plus so many more!",
  },
];

export const Step2 = ({ setStep, userId, orderData }) => {
  const [pastModal, setPastModal] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [passwordState, setPasswordState] = useState("");
  const [babyState, setBabyState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [higherCheckState, setHigherCheckState] = useState("");

  const [fullUser, loading] = useRequest(() => getUser(userId));

  if (loading) return <Box>Loading...</Box>;

  const fullChild = _.get(fullUser, "children")[0];

  const handleAddSource = async () => {
    const [source] = higherCheckState
      .filter((item) => !!item.value)
      .map((i) => i.fancy);
    await addSource({
      source,
    });
  };

  const runConfirmUser = async () => {
    if (passwordState.length === 0) {
      setErrorMessage("Your password cannot be blank");
      return;
    }
    if (babyState.length === 0) {
      setErrorMessage("Please enter a name for your baby!");
      return;
    }

    if (passwordState.indexOf(" ") !== -1) {
      setErrorMessage("Please make sure your password does not contain spaces");
      return;
    }

    if (passwordState.length < 5) {
      setErrorMessage(
        "Please make sure your password is over 5 characters long"
      );
      return;
    }

    try {
      await setPassword(userId, passwordState);
    } catch (error) {
      setErrorMessage("Please check your password field");
      return;
    }
    try {
      await updateChild(userId, {
        child_id: _.get(fullChild, "child_id"),
        birthday: _.get(fullChild, "birthday"),
        name: babyState,
      });
    } catch (error) {
      setErrorMessage("Please check your baby field");
      return;
    }

    try {
      await earnPoints({ earn_id: 1 });
    } catch (error) {
      setErrorMessage("Could not add points.");
      return;
    }

    setStepOne(false);
  };

  const advanceToRewards = async () => {
    try {
      await earnPoints({ earn_id: 22 });
    } catch (error) {
      throw error;
    }
    return handleAddSource();
  };

  return (
    <ConfirmPageContainer center>
      <LeftBox
        center
        height={{
          xl: stepOne ? "90vh" : "100vh",
          lg: stepOne ? "90vh" : "100vh",
          md: stepOne ? "90vh" : "100vh",
          sm: "84vh",
        }}
      >
        <Box width={{ xl: "482px", lg: "482px", md: "370px", sm: "335px" }}>
          <H3 mb="5px">Eat to Earn</H3>
          <H6 mt="5px">
            As a Yumi Member, you’ll collect points on almost everything you buy
            and those points add up to (some really yummy) rewards— in as few as
            2–3 orders.
          </H6>
          <Box>
            {static_rewards.map((reward) => (
              <Box
                css={css`
                  display: flex;
                `}
                fd="row"
                mt="20px"
              >
                <Image
                  src={_.get(reward, "image")}
                  alt="image"
                  css={css`
                    width: 80px !important;
                    @media (max-width: 500px) {
                      width: 50px;
                    }
                  `}
                  width={{ xl: "80px", lg: "80px", sm: "50px" }}
                  height={{ xl: "80px", lg: "80px", sm: "50px" }}
                />
                <Box ml="25px">
                  {reward.points > 0 && (
                    <H6 m="0px" fs="18px" color="#4993B7;">
                      +{reward.points} Points
                    </H6>
                  )}
                  <H6 fs="18px" m="0px">
                    {reward.title}
                  </H6>
                  <H6 mt="10px" fs="16px">
                    {reward.body}
                  </H6>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </LeftBox>
      <RightBox
        vCenter
        ml={stepOne ? "0px" : "-30px"}
        mb="0px"
        height={{
          xl: stepOne ? "90vh" : "100vh",
          lg: stepOne ? "90vh" : "100vh",
          md: stepOne ? "90vh" : "100vh",
          sm: stepOne ? "60vh" : "92vh",
        }}
      >
        <Box
          p={{ xl: "0px", lg: "0px", md: "0px" }}
          width={{ xl: "505px", lg: "505px", md: "380px", sm: "335px" }}
        >
          <Image src="_RewardCenter/Bodge.svg" alt="badge" />{" "}
          <H6>+100 points</H6>
          {stepOne ? (
            <React.Fragment>
              <H2
                css={css`
                  font-size: 26px;
                  ${theme.mobile} {
                    font-size: 20px;
                  }
                `}
              >
                Complete your profile
              </H2>
              <Input
                type="password"
                placeholder="Password"
                hint="Password"
                id="#new-password"
                value={passwordState}
                onChange={(e) => {
                  setPasswordState(e.target.value);
                }}
              />
              <Input
                placeholder="Baby Name"
                hint="Baby Name"
                id="#new-babyname"
                value={babyState}
                onChange={(e) => setBabyState(e.target.value)}
              />
              {errorMessage && <H6 m="2px">{errorMessage}</H6>}
              <Button
                type="secondary"
                id="complete"
                mt={{ lg: "20px" }}
                css={css`
                  background: #4993b7;
                `}
                onClick={() => runConfirmUser()}
              >
                {" "}
                Continue
              </Button>{" "}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <H2
                css={css`
                  font-size: 26px;

                  ${theme.mobile} {
                    font-size: 20px;
                  }
                `}
              >
                How did you first hear about us?
              </H2>{" "}
              <Survey
                higherCheckState={higherCheckState}
                setHigherCheckState={setHigherCheckState}
              />
              <Link
                to={{
                  pathname: "/dashboard/rewards",
                  state: { newUser: true },
                }}
              >
                <Button
                  id="complete"
                  type="secondary"
                  mt={{ lg: "20px", sm: "25px" }}
                  css={css`
                    background: #4993b7;
                  `}
                  onClick={() => advanceToRewards()}
                >
                  Continue
                </Button>
              </Link>
            </React.Fragment>
          )}
        </Box>
      </RightBox>
      <CustomModal
        visible={!pastModal}
        modalBody={() => (
          <Box center>
            {" "}
            <H2
              css={css`
                font-size: 26px;

                ${theme.mobile} {
                  font-size: 20px;
                }
              `}
              mt="10px"
              mb="11px"
            >
              Thank you for your order
            </H2>
            <H6 mt="0px" mb="10px">
              {" "}
              You'll recieve an email confirmation in a few.
            </H6>
            <H6 mt="10px" ta="center">
              {" "}
              In the meantime, let's setup your account so you can manage your
              subscription and earn reward points.
            </H6>
            <Button
              css={css`
                background: #4993b7;
              `}
              onClick={() => setPastModal(true)}
            >
              Set Up Account
            </Button>
          </Box>
        )}
        image="_RewardCenter/Juggler.png"
        imageWidth={{ xl: "213px", lg: "213px" }}
        imageHeight={{ xl: "199px", md: "199px", sm: "199px" }}
      />
    </ConfirmPageContainer>
  );
};

const LeftBox = styled(Box)`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
    order: 2;
  }
`;
const RightBox = styled(Box)`
  width: 50%;
  background: #fff9f0;
  padding: 75px;

  @media (max-width: 800px) {
    width: 100%;
    order: 1;
  }
`;
const ConfirmPageContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 0px !important;
  margin-bottom: 0px !important;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
