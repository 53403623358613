import React, { useReducer } from "react";
import qs from "query-string";
import { Route, Redirect, Switch } from "react-router-dom";
import { Page, oat } from "../../../styledcomponents";
import { GiftCheckoutContext } from "./GiftCheckoutContext";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import {
  useSyncProgressWithLocalStorage,
  getProgressFromLocalStorage
} from "../effects";
import { Stepper } from "./stepper";
import { isValidReferral, getReferralMenu } from "../../../usecases";
import { useRequest } from "../../../hooks";

export const createGiftReducer = (state, action) => {
  switch (action.type) {
    case "COMPLETE_STEP_ONE": {
      return {
        ...state,
        stage: 2,
        milestoneMenu: action.milestoneMenu,
        zipcode: action.zipcode,
        zone: action.zone,
        birthday: action.birthday,
        deliveryDate: ""
      };
    }
    case "SUBMIT_PAYMENT_INFO": {
      return {
        ...state,
        stage: 3,
        deliveryDate: action.deliveryDate
      };
    }
    case "SUBMIT_WITHOUT_PAYMENT": {
      return {
        ...state,
        stage: 3
      };
    }
    case "COMPLETE_STEP_THREE": {
      return { ...state, stage: 4, gift: action.gift };
    }
    default: {
      throw Error("Unknown action");
    }
  }
};
const getInitialReducerState = ({ location }) => {
  const localStorageProgress = getProgressFromLocalStorage();
  const { referral_code = localStorageProgress.referral_code } = qs.parse(
    location.search
  );
  return { ...localStorageProgress, referral_code };
};
export const GiftCheckout = ({ match, history, location }) => {
  const [progress, dispatch] = useReducer(
    createGiftReducer,
    getInitialReducerState({ location })
  );
  useSyncProgressWithLocalStorage(progress);

  if (progress.stage < Number(match.params.stage))
    return <Redirect to={`/gift-checkout/${progress.stage}`} />;

  return (
    <React.Fragment>
      <Stepper step={match.params.stage} maxStep={3} />
      <Page
        style={{
          background: oat,
          overflow: "hidden"
        }}
      >
        <GiftCheckoutContext.Provider
          value={{
            ...progress,
            dispatch,
            progress,
            history
          }}
        >
          <Switch>
            <Route path="/gift-checkout/1" render={() => <StepOne />} />
            <Route path="/gift-checkout/2" render={() => <StepTwo />} />
            <Route path="/gift-checkout/3" render={() => <StepThree />} />
            <Route
              path="/gift-checkout/3/payment"
              render={() => <StepThree />}
            />
            <Route path="*" render={() => <Redirect to="/gift-checkout/1" />} />
          </Switch>
        </GiftCheckoutContext.Provider>
      </Page>
    </React.Fragment>
  );
};
