import React from "react";

import styled, { css } from "styled-components";
import { Box, H3, Label, yumiOrange, oatNew } from "../../../styledcomponents";
import {
  Hero,
  LinkButton,
  CranSquash,
  SquashAndGreens,
  BellPepperBowl
} from "../assets";

import "styled-components/macro";
import { useWindowSize } from "../../../hooks";

const Header = () => {
  const { width } = useWindowSize();
  return (
    <HeaderContainer>
      <Box
        css={css`
          width: 34%;
          align-items: flex-start;
          justify-content: space-around;

          @media (max-width: 1200px) {
            min-width: 60% !important;
            width: 60% !important;
          }
          @media (max-width: 500px) {
            min-width: 52% !important;
            width: 52% !important;
          }
        `}
      >
        {" "}
        <H3
          style={{ width: "100%" }}
          css={css`
            font-size: 60px;
            text-align: center;
            line-height: 58px;
            letter-spacing: 0.01em;
            color: ${yumiOrange};
            margin-bottom: 20px;
            margin-top: 0.5em;
            text-align: left;
            @media (max-width: 1000px) {
              font-size: 44px;
              line-height: 40px;
            }
            @media (max-width: 800px) {
              font-size: 39px;
            }
            @media (max-width: 800px) {
              font-size: 28px;
              line-height: 26px;
            }
          `}
        >
          Future Foodies Start Here
        </H3>
        <Label
          color="#000000"
          css={css`
            font-family: "Freight-Book";
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            max-width: 500px;
            width: 95%;
            margin-top: 20px;

            @media (max-width: 1000px) {
              font-size: 22px;
              line-height: 27px;
              width: 100%;
            }
            @media (max-width: 800px) {
              font-size: 14px;
              line-height: 18px;
              margin-top: 0px;
              z-index: 103;
            }
          `}
        >
          Our menus change every week. <br /> Explore our over 70 flavors here.
        </Label>
        <LinkButton
          background={yumiOrange}
          to="/checkout"
          value="GET STARTED"
        />
      </Box>
      <Box
        css=" display:flex; flex-direction:row; position:relative; width:800px; height: 500px;margin-top:90px; @media (max-width: 1200px) {
          margin-top:100px;
        }@media (max-width: 800px) {
        margin-top:70px;
        max-height:250px;

        }@media (max-width: 500px) {
        margin-top:0px;

        }"
      >
        <JarImage
          src={CranSquash}
          alt="cran"
          css="z-index:100;
          @media (max-width: 1200px) {
          margin-top:20px;
          margin-left:-70px;
          } 

          @media (max-width: 800px) {
          margin-top:0px;
          margin-left:0px;
          }
          @media (max-width: 500px) {
          margin-top:50px;
          margin-left:-20px;
          z-index:99;
          }"
        />
        <JarImage
          src={SquashAndGreens}
          css="width:340px; margin-left:280px;margin-top:-100px; z-index:99; 
          @media (max-width: 1200px) {
          margin-left:100px;
          margin-top:-150px;
          width: 210px !important;
          z-index:99;
          }
          @media (max-width: 800px) {
          width: 160px !important;
          margin-top:-130px;
          z-index:100;
          }
          @media (max-width: 500px) {
            width: 110px !important;
          margin-left:50px;
          margin-top:-40px;
          }"
          alt="cran"
        />
        <JarImage
          src={BellPepperBowl}
          alt="cran"
          css="z-index:800; margin-left:460px; margin-top:80px; 
          @media (max-width: 1200px) {
             margin-left:200px;
            margin-top:-20px;
            }
          @media (max-width: 500px) {
             margin-left:70px;
            margin-top:30px;
         }"
        />
      </Box>
    </HeaderContainer>
  );
};

const JarImage = styled.img`
  width: 300px;
  position: absolute;

  @media (max-width: 1200px) {
    width: 200px !important;
  }
  @media (max-width: 800px) {
    width: 150px !important;
  }
  @media (max-width: 500px) {
    width: 100px !important;
  }
`;
const HeaderContainer = styled.div`
  height: 570px;
  width: 100%;
  background: ${oatNew};
  background-size: 120vw;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: -90px;
  padding-left: 6%;
  margin-top: -64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  g .header1_text {
    font-size: 24px;
  }

  @media (max-width: 1050px) {
    background-position-y: -120px;
    height: 500px;
    background-size: 132vw;
  }

  @media (max-width: 800px) {
    height: 385px;
    padding-top: calc(1.5em + 32px);
    background-size: 140vw;
    background-position-y: -60px;

    img {
      width: 200px;
    }

    .header1_text {
      font-size: 20px;
    }
  }

  @media (max-width: 500px) {
    height: 300px;
    background-size: 140vw;
    background-position-y: -60px;
    background-position-x: -40px;
  }

  @media (max-width: 400px) {
    background-size: 140vw;
    background-position-y: 10px;
  }

  .button_wrapper {
    width: 128px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export default Header;
