import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import "styled-components/macro";
import { Button, H2, H4, H3, Image, Box, toast, loading } from "@team-yumi/ui";

import { updateOrderAddOn } from "../../usecases";
import { CSA } from "./components/CSA";
import { Frozen } from "./components/Frozen";
import ButtonContainer from "./components/ButtonContainer";

export const Step1 = ({
  addOnData,
  setStep,
  setShowNotification,
  orderData,
  userId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isCSA = false;
  const isFrozen = window.localStorage.frozenCheckout === "true" || false;

  if (isCSA) {
    addOnData.quads = [{ quad_id: 134, quantity: 1, name: "Produce Box" }];
  }
  const addQuadOn = async () => {
    if (orderData && orderData.order_id) {
      setIsLoading(true);
      loading.show(
        "We're processing your purchase. Please don't close this page."
      );
      try {
        await updateOrderAddOn(orderData.order_id, addOnData);
      } catch (err) {
        loading.hide();
        toast.error("Error Purchasing Add On");
        return;
      }
      setIsLoading(false);
      loading.hide();
      setStep(2);
      setShowNotification(true);
    } else {
      setStep(2);
      setShowNotification(false);
    }
  };

  return (
    <React.Fragment>
      {!isCSA &&
        !isFrozen && (
          <Step1Container>
            <H2 mb="10px" color="#333333">
              Get our Pi(e) Day Box
            </H2>
            <IntroP color="#333333">
              Our pies are sneaky delicious -- they help balance your babe's
              blood sugar by mixing it with fiber packed ingredients like white
              bean and sweet potato. Only for a limited time.
            </IntroP>
            <AddOnContainer>
              <AddOnImage src={addOnData.img} />
              <H3 style={{ marginTop: "20px" }}>{addOnData.quads[0].name}</H3>
              <H4 style={{ marginBottom: "15px" }} mt="0px">
                $16.33 - 4 Jars
              </H4>
              {addOnData.attributes.map(({ name, quantity }) => (
                <AddOnBox key={`addon${Math.random()}`}>
                  {name} - x{quantity}
                </AddOnBox>
              ))}
            </AddOnContainer>
            <ButtonContainer>
              <Box
                center
                width={{ xl: "100%", lg: "50%" }}
                mr={{ xl: "30px", lg: 0 }}
              >
                <Button
                  disabled={isLoading}
                  onClick={() => addQuadOn()}
                  type="primary"
                  backgroundColor="#61AACE"
                  style={{ backgroundColor: "#61AACE" }}
                >
                  Add for $16.33
                </Button>
              </Box>
              <Box center width={{ xl: "100%", lg: "50%" }}>
                <Button
                  type="link-tertiary"
                  color="#61AACE"
                  id="no-thanks"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  No, Thanks
                </Button>
              </Box>
            </ButtonContainer>
          </Step1Container>
        )}
      {isCSA && (
        <CSA
          addQuadOn={addQuadOn}
          isLoading={isLoading}
          addOnData={addOnData}
          setStep={setStep}
        />
      )}
      {isFrozen && (
        <Frozen
          isLoading={isLoading}
          setStep={setStep}
          setIsLoading={setIsLoading}
          setShowNotification={setShowNotification}
          userId={userId}
        />
      )}
    </React.Fragment>
  );
};

const IntroP = styled.p`
  font-family: "Ginger";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  align-self: center;
  margin: 0px 10px 40px 0px;
  max-width: 335px;
  color: #4a4a4a;
`;

const Step1Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 50px;
  margin-bottom: 50px;
`;

const AddOnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
  max-width: 260px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
`;

const AddOnImage = styled(Image)`
  width: 100%;
`;

const AddOnBox = styled.span`
  font-family: "Ginger", sans-serif;
  font-size: 11.5px;
  line-height: 115%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #777777;
  padding: 2px;
`;
