import React, { useState, useRef } from "react";
import "styled-components/macro";
import styled, { css } from "styled-components";
import {
  Box,
  HyperLink,
  LazyImage,
  yumiOrange,
  BlueButton
} from "../../../styledcomponents";

import { H3, Body, Label } from "../../../styledcomponents/typefaces";
import {
  Kiwi,
  AllieRizzo,
  AllieRizzoAvi,
  Raina,
  RainaAvi,
  Alice,
  AliceAvi,
  Klo,
  KloAvi,
  Monica,
  MonicaAvi,
  YumiLogo,
  AJ
} from "../../HomePage/assets";

const Arrow = "https://email.helloyumi.com/assets/_Reviews/back-arrow.png";

const JonNVin =
  "https://email.helloyumi.com/assets/_HomePageRefresh/JonNVin1.png";
const JonNVinAvi = "https://email.helloyumi.com/assets/_HomePageRefresh/so.svg";

export const GetTheScoop = ({ width }) => {
  const scroller = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [direction, setDirection] = useState(0);

  const handleScroll = () => {
    setScrollLeft(scroller.current.scrollLeft);

    if (scrollLeft > 1500 && direction === 0) {
      setDirection(1);
    }
    if (scrollLeft < 300 && direction === 1) {
      setDirection(0);
    }
  };

  const onRightClick = () => {
    if (scrollLeft > 1500 && direction === 0) {
      setDirection(1);
    }
    if (scrollLeft < 300 && direction === 1) {
      setDirection(0);
    }

    direction === 1
      ? scroller.current.scroll({
          left: scroller.current.scrollLeft - 500,
          behavior: "smooth"
        })
      : scroller.current.scroll({
          left: scroller.current.scrollLeft + 500,
          behavior: "smooth"
        });
  };
  return (
    <Box
      row
      full
      css={css`
        background: #faf8f6;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-left: 120px;
        padding-right: 120px;
        @media (min-width: 1200px) {
          flex-direction: row;
          flex-wrap: nowrap;
          padding: 115px 0;
        }
        @media (max-width: 800px) {
          padding-top: 0;
          padding-left: 0px;
          padding-right: 0px;
        }
      `}
    >
      <LazyImage
        src={Kiwi}
        css="position: absolute; left:0; right: auto; top: 10px; bottom: auto; @media(max-width: 800px){
        display: none;
      }"
        alt="cartoon kiwi"
      />
      <div
        css="width:60%; display:Flex;justify-content:center; @media(max-width:1200px){
            padding-top:80px;
              width:100%;
          }"
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 530px;
            padding-left: 50px;

            @media (max-width: 1200px) {
              align-items: center;
              width: 100%;
              padding-left: 0px;
            }
          `}
        >
          <H3
            css={css`
              font-size: 48px;
              padding-bottom: 20px;

              @media (max-width: 500px) {
                font-size: 30px;
              }
            `}
            color={yumiOrange}
          >
            Get the Scoop{" "}
          </H3>
          <Body
            css="margin-top: 5px; font-size:24px;
            padding-bottom:0px; @media(max-width:1200px){
                text-align:center;
              width:80%;
              padding-bottom:0px;
          }"
          >
            {" "}
            We're always cooking up something fresh. Sign up to get the scoop on
            our latest chef collabs and more.{" "}
          </Body>

          <CloudForm>
            <iframe
              title="klaviyo email"
              src="https://email.helloyumi.com/assets/_HomePageRefresh/klaviyohome.html"
              css="border: none; margin-left:-20px;width:400px; padding: 0; margin-bottom: 0; height: 90px; z-index:10; @media(max-width:1000px){margin-left:0px;}"
            />
          </CloudForm>
        </div>
      </div>

      <CarouselFrame name="a bunch of pictures on the right">
        <Carousel>
          <CarouselButton onClick={() => onRightClick()}>
            <img
              src={Arrow}
              alt="right"
              css={css`
                transform: ${direction === 1
                  ? `rotate(0deg)`
                  : `rotate(180deg)`};
                transition: all 0.1s ease-in;

                top: 0;
              `}
            />
          </CarouselButton>
          <Scroll ref={scroller} onScroll={() => handleScroll()}>
            <InstaBox
              image={AllieRizzo}
              avi={AllieRizzoAvi}
              name="Allie R."
              username="allierizzo"
              width={width}
            />
            <InstaBox
              image={JonNVin}
              avi={JonNVinAvi}
              name="Jon & Vinny's."
              username="jonandvinnydelivery"
              width={width}
            />
            <InstaBox
              image={Klo}
              avi={KloAvi}
              name="Kathy L."
              username="kathylo15"
              width={width}
            />
            <InstaBox
              image={Raina}
              avi={RainaAvi}
              name="Raina E."
              username="rainmonsterr"
              width={width}
            />
            <InstaBox
              image={Alice}
              avi={AliceAvi}
              name="Alice H."
              username="rudegirrl"
              width={width}
            />
            <InstaBox
              image={Monica}
              avi={MonicaAvi}
              name="Monica F."
              username="fonsecamonica"
              width={width}
            />
            <InstaBox
              image={AJ}
              avi={YumiLogo}
              name="Yumi"
              username="yumi"
              width={width}
            />
          </Scroll>
        </Carousel>
      </CarouselFrame>
    </Box>
  );
};

const CloudForm = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    width: 300px !important;
  }
`;

const InstaBox = ({ image, avi, name, username, width }) => (
  <ScrollItemOuter>
    <ScrollItem>
      <InstaContainer css="align-items: center; background: white; box-shadow:0px 1px 5px lightgray;">
        {width < 800 ? (
          <div css="width: 250px; height: 260px; overflow: hidden;">
            <LazyImage
              src={image}
              alt="instagram content"
              css="height: 100%;"
            />
          </div>
        ) : (
          <div css="width: 300px; height: 300px; overflow: hidden;">
            <LazyImage
              src={image}
              alt="instagram content"
              css="height: 100%;"
            />
          </div>
        )}
        <Box
          row
          full
          css="justify-content: flex-start; align-items: center; flex: 1 0 50px; text-align: left; @media (max-width: 800px) {padding: 7px 0;}"
        >
          <LazyImage
            src={avi}
            alt="instagram avatar"
            css="clip-path: circle(25px at center); width: 75px; height: 75px;"
          />

          <Label>
            {name} <br />
            <HyperLink
              css="color: rgba(0,0,0,.33);"
              href={`https://instagram.com/${username}`}
            >
              {" "}
              <small>@{username}</small>
            </HyperLink>
          </Label>
        </Box>
      </InstaContainer>
    </ScrollItem>
  </ScrollItemOuter>
);

const CarouselButton = styled(BlueButton)`
  border-radius: 40px;
  position: absolute;
  width: 50px;
  display: flex;
  z-index: 1000;
  opacity: 0.9;
  right: 10px;
  top: 300px;
  height: 65px;
  outline: none;
  background: ${yumiOrange};

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1200px) {
    top: 500px;
  }
`;

const CarouselFrame = styled.div`
  width: 55%;
  margin: 0 auto;

  display: flex;
  justify-content: flex-end;

  padding: 24px 0;
  height: 100%;
  background-color: #faf8f6;

  @media (min-width: 1200px) {
    overflow: hidden;
  }

  @media (max-width: 1250px) {
    background-color: #faf8f6;
    width: 90%;
  }

  @media (max-width: 800px) {
    padding-top: 0;
    margin-left: 15px;
    overflow: hidden;
    width: calc(100% - 15px);
  }
`;

const Carousel = styled.div`
  width: 90%;

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const Scroll = styled.ul`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  box-sizing: content-box;
  margin-block-start: 0px;
  padding-inline-start: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollItemOuter = styled.li`
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  list-style-type: none;
`;

const ScrollItem = styled.div`
  width: 300px;
  height: 100%;
  padding-right: 50px;
  text-align: center;
  @media (max-width: 800px) {
    padding-right: 1em;
    width: 250px;
  }
`;

const InstaContainer = styled(Box)`
  align-items: center;
  background: green;
  flex: 0 1 300px;
`;
