import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { oat, blackberry, Body1, BlueButton } from "../../../styledcomponents";
import { H2 } from "../../../styledcomponents/typefaces";
import { Builder } from "../assets";
import "styled-components/macro";

export const GrowTogether = () => (
  <GrowTogetherContainer>
    <img
      src={Builder}
      alt="keeperofkeys"
      style={{ width: "450px" }}
      css={css`
        @media (max-width: 1000px) {
          width: 200px !important;
        }
      `}
    />
    <H2 style={{ color: blackberry, marginTop: "15px", textAlign: "center" }}>
      Let's grow together.
    </H2>
    <Body1
      style={{
        marginTop: "20px",
        width: "60%",
        fontSize: "20pt",
        textAlign: "center"
      }}
      css={css`
        @media (max-width: 900px) {
          font-size: 14pt !important;
          width: 100% !important;
        }
      `}
    >
      At this moment, your baby's brain is growing faster than it ever will. By
      the first year, the brain doubles in size. Because food fuels that growth,
      we make sure each blend is dense in at least 5 key micronutrients.
    </Body1>
    <Link to="/checkout">
      <BlueButton
        value="GET STARTED"
        style={{
          padding: "20px",
          color: "white",
          background: blackberry,
          marginTop: "35px"
        }}
      />
    </Link>
  </GrowTogetherContainer>
);

const GrowTogetherContainer = styled.div`
  background: ${oat};
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 9vh;
  padding-right: 9vh;
  padding-top: 8vh;
  padding-bottom: 15vh;
`;
