import React from "react";
import styled from "styled-components";
import useTimeout from "../hooks/useTimeout";

export const SuccessIndicator = ({ updatedField, close }) => {
  if (!updatedField) return "";
  return <Success close={close} updatedField={updatedField} />;
};

const Success = ({ updatedField, close }) => {
  const hidden = useTimeout(close, 5900);
  if (hidden) return "";
  return (
    <SuccessBox>
      <div> Successfully updated {updatedField} </div>{" "}
      <XButton onClick={close}>X</XButton>
    </SuccessBox>
  );
};

const SuccessBox = styled.div`
  background: #2fab4f;
  color: white;
  font-family: "Avenir", sans-serif;
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  animation: fadeaway-transition 6s;
  @keyframes fadeaway-transition {
    0% {
      opacity: 1;
      visibility: visible;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }
  @media (max-width: 1000px) {
    position: fixed;
    top: 0;
    bottom: auto;
  }
`;

const XButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 10%;
`;
