import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { TrayOfBites } from "../assets";
import { oatCreamy, blackberry } from "../../../styledcomponents/colors";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { BlueButton } from "../../../styledcomponents";
import "styled-components/macro";

export const Finally = () => (
  <PageLayout>
    <LeftSide>
      <TopBar>
        <img
          css="width:500px; transform:rotate(-17.5deg); margin-top:-146px; margin-left:-70px; @media(max-width:1000px){width:300px}"
          src={TrayOfBites}
          alt="tray"
        />
      </TopBar>
      <BottomBar>
        <H1
          css={css`
            color: ${blackberry};
            width: 80%;
            align-self: flex-end;
            @media (max-width: 1000px) {
              width: 100%;
            }
          `}
        >
          {" "}
          Finally, a baby led weaning program focused on your babe’s nutrition.
        </H1>
        <BodySection>
          <Body css="@media(max-width:1000px){font-size:17px;}">
            - Finger foods to perfect that pincer grasp{" "}
          </Body>{" "}
          <Body css="@media(max-width:1000px){font-size:17px;}">
            {" "}
            - Formulated for baby (salt and rice free)
          </Body>{" "}
          <Body css="@media(max-width:1000px){font-size:17px;}">
            {" "}
            - High in iron and fiber
          </Body>
          <Link
            css={css`
              padding: 20px;
              width: 150px;
              margin-top: 0px;
              text-decoration: none;

              @media (max-width: 1000px) {
                align-self: center;
                justify-self: center;
                padding: 0px;
                margin-top: 0px;
              }
            `}
            to="/checkout"
          >
            <BlueButton
              css={css`
                background: ${blackberry};
                color: white;
                padding: 20px;
                width: 150px;
                margin-top: 55px;

                @media (max-width: 1000px) {
                  padding: 12px;
                }
              `}
              value="GET STARTED"
            />
          </Link>
        </BodySection>
      </BottomBar>
    </LeftSide>
    <RightSide>
      <img
        css="width:500px;transform:rotate(-59.58deg);margin-top:50px;"
        src={TrayOfBites}
        alt="white"
      />
    </RightSide>
  </PageLayout>
);

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  width: 60%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    font-size: 10pt;
  }
`;
const LeftSide = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1500px) {
    margin-top: 0px;
    width: 74%;
  }
`;

const RightSide = styled.div`
  width: 22%;
  margin-top: -50px;

  @media (max-width: 1500px) {
    margin-top: -16px;
    width: 26%;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const TopBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
`;
const BottomBar = styled.div`
  width: 100%;
  display: flex;

  margin-top: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const PageLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
  background: ${oatCreamy};
`;
