/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/styles.css";
import Address from "./components/Address";
import Button from "../../../../../../components/Order/components/Button";
import { MODAL_BUTTON_ACTIONS } from "../../modalAPI/reducer";
import {
  pauseOrderService,
  unpauseOrderService,
  getOrderByIdService
} from "../../../../../../services/orders";
import {
  addAddressService,
  changeOrderAddressByIdService,
  getAddressesService
} from "../../../../../../services/address";
import {
  openAddAddressModalAction,
  openChangeAddressModalAction
} from "../../modalAPI/actions";

import closeButton from "../../../../../../assets/orders/x.svg";
import ModalForm from "../ModalForm";
import ReceiptBody from "./components/ReceiptBody";

import useWindowDimensions from "./useWindowDimensions";

function Modal({
  title,
  details,
  options,
  handleClose,
  order,
  yPos,
  date,
  dispatch,
  shipments,
  planId,
  user
}) {
  const [showForm, setForm] = useState(false);
  const [showAddresses, setAddresses] = useState(null);
  const [newAddress, editNewAddress] = useState({
    street: null,
    street2: null,
    city: null,
    state: null,
    postcode: null,
    order_id: order
  });
  const [showReceipt, setReceipt] = useState(null);
  const { width, height } = useWindowDimensions();

  // TODO: move this switch statement to its own file
  const getButtonAction = async (action, args) => {
    switch (action) {
      case MODAL_BUTTON_ACTIONS.CLOSE_MODAL:
        document.body.classList.remove("modal-open");
        handleClose();
        break;
      case MODAL_BUTTON_ACTIONS.SKIP:
        try {
          await pauseOrderService(order);
          document.body.classList.remove("modal-open");
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
        break;
      case MODAL_BUTTON_ACTIONS.UNSKIP:
        try {
          await unpauseOrderService(order);
          document.body.classList.remove("modal-open");
          window.location.reload();
        } catch (err) {
          console.log(err);
        }

        break;
      case MODAL_BUTTON_ACTIONS.OPEN_CHANGE_ADDRESS: {
        try {
          const addresses = await getAddresses();
          setAddresses(addresses);
          dispatch(openChangeAddressModalAction(order, date));
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case MODAL_BUTTON_ACTIONS.OPEN_ADD_ADDRESS: {
        setForm(true);
        setAddresses(null);
        dispatch(openAddAddressModalAction(order));
        break;
      }
      case MODAL_BUTTON_ACTIONS.ADD_ADDRESS: {
        try {
          await addAddressService(order, newAddress);
          window.location.reload();
          document.body.classList.remove("modal-open");
          handleClose();
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case MODAL_BUTTON_ACTIONS.CHANGE_ADDRESS: {
        try {
          await changeOrderAddressByIdService(order, args);
          document.body.classList.remove("modal-open");
          handleClose();
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case MODAL_BUTTON_ACTIONS.SHOW_RECEIPT:
        setReceipt({ order, date });
        // dispatch(openReceiptAction(order, date, planId));
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, []);

  const onClickTracking = e => {
    e.preventDefault();
    window.open(`https://yumi.aftership.com/${shipments[0].tracking_number}`);
  };

  /*
   * Maps over to get our currently selected address for that order
   */
  const getAddresses = async () => {
    /*
     * Get address for that order
     */
    const orderJSON = await getOrderByIdService(order);
    const currAddress = orderJSON.address;
    /*
     * Get all of user's addresses and map to selected field
     */
    const allAddresses = await getAddressesService(user);
    const mapWithSelectedField = allAddresses.addresses.map(address => {
      address.selected = address.address_id === currAddress.address_id;
      return address;
    });

    return [...mapWithSelectedField];
  };

  const selectAddress = async address => {
    delete address.selected;
    getButtonAction(MODAL_BUTTON_ACTIONS.CHANGE_ADDRESS, address);
  };

  const onChangeAddress = (key, value) => {
    const state = newAddress;
    state[key] = value;
    editNewAddress(state);
  };

  const moreOptionsButtonStyle = {
    width: "100%",
    border: "solid 1px rgba(0, 0, 0, 0.07)",
    "border-radius": "8px",
    height: "100px",
    "justify-content": "flex-start",
    "font-size": "14px"
  };

  const linkButtonStyle = {
    width: "100%",
    border: "solid 1px rgba(0, 0, 0, 0.07)",
    "border-radius": "8px",
    height: "100px",
    "font-size": "14px"
  };

  const regularButtonStyle = {
    width: "100%",
    border: "solid 1px rgba(0, 0, 0, 0.07)"
  };

  const getDetailsText = () => {
    let detailsString = details;
    detailsString += date ? ` ${date}.` : "";

    if (title === "Receipt") return "";
    return detailsString;
  };

  const getPos = (width, height) => {
    if (width > 750) {
      return yPos + 60;
    }
    if (width < 750 && width > 480) {
      return yPos + 50;
    }
    if (height > 700) {
      if (width > 413) {
        // iphone 678 plus
        return yPos + 72;
      }
      // iphone x
      return yPos + 110;
    }
    // iphone 678
    return yPos + 70;
  };

  const getHeight = (width, height) => {
    if (width < 480) {
      if (height > 736) {
        return 655;
      }
      if (height > 667) {
        return 640;
      }
      if (height > 568) {
        return 560;
      }
      return 460;
    }
  };

  return (
    <div
      style={{ top: getPos(width, height), height: getHeight(width, height) }}
      className="orders_modal_container"
    >
      <img
        src={closeButton}
        onClick={() => getButtonAction(MODAL_BUTTON_ACTIONS.CLOSE_MODAL, null)}
        className="orders_modal_close"
      />
      <div
        className={`orders_modal_text${
          title === "More" ||
          title === "Change address" ||
          title === "Add address"
            ? " divider"
            : ""
        }${title === "Receipt" ? " receipt" : ""}`}
      >
        <div className="orders_modal_text_header">{title}</div>
        <div className="orders_modal_text_details">{getDetailsText()}</div>
      </div>
      <div className={`orders_modal_body${showAddresses ? "" : " hide"}`}>
        {showAddresses
          ? showAddresses.map(address => (
            <Address handleClick={selectAddress} address={address} />
            ))
          : null}
      </div>
      {showReceipt ? (
        <ReceiptBody id={order} date={date} planId={planId} />
      ) : null}
      {showForm ? <ModalForm onChangeAddress={onChangeAddress} /> : null}
      {
        <div
          className={`orders_modal_options${
            options[0].action === "OPEN_CHANGE_ADDRESS" ? " more" : ""
          } `}
        >
          {options.map(
            (option =>
              option.action !== MODAL_BUTTON_ACTIONS.TRACK_ORDER &&
              option.action !== MODAL_BUTTON_ACTIONS.OPEN_SNACKS ? (
                <Button
                  img={option.img}
                  style={
                    options[0].action === "OPEN_CHANGE_ADDRESS"
                      ? moreOptionsButtonStyle
                      : regularButtonStyle
                  }
                  onClick={() => getButtonAction(option.action, null)}
                  solid={option.style === "FILLED"}
                  text={option.text}
                  color="white"
                />
              ) : option.action === MODAL_BUTTON_ACTIONS.OPEN_SNACKS ? (
                <Link
                  style={linkButtonStyle}
                  to="/bites/checkout"
                  onClick={() =>
                    getButtonAction(MODAL_BUTTON_ACTIONS.CLOSE_MODAL, null)
                  }
                  className="orders_modal_button_link"
                >
                  <Button
                    img={option.img}
                    style={{
                      border: "0px",
                      width: "100%",
                      height: "100%",
                      margin: "0px",
                      fontSize: "14px",
                      justifyContent: "flex-start"
                    }}
                    solid={option.style === "FILLED"}
                    text={option.text}
                    color="white"
                  />
                </Link>
              ) : (
                <Link
                  style={linkButtonStyle}
                  to={`https://yumi.aftership.com/${order}`}
                  target="_blank"
                  className="orders_modal_button_link"
                  onClick={e => onClickTracking(e)}
                >
                  <Button
                    img={option.img}
                    style={{
                      border: "0px",
                      width: "100%",
                      height: "100%",
                      margin: "0px",
                      fontSize: "14px",
                      justifyContent: "flex-start"
                    }}
                    onClick={() => getButtonAction(option.action, null)}
                    solid={option.style === "FILLED"}
                    text={option.text}
                    color="white"
                  />
                </Link>
              ): null)
          )}
        </div>
      }
    </div>
  );
}

export default Modal;
