import React from "react";
import styled, { css } from "styled-components";
import { Banana, GreenCheck } from "../assets";
import { H1, blackberry } from "../../../styledcomponents";
import { Body } from "../../../styledcomponents/typefaces";

import "styled-components/macro";
import { useWindowSize } from "../../../hooks";

export const HowDoIKnow = () => {
  const { width } = useWindowSize();
  return (
    <PageLayout>
      <img
        src={Banana}
        alt="nanna"
        css={css`
          width: 60px;
          margin-top: 120px;
          @media (max-width: 1000px) {
            margin-top: 90px;
            width: 40px;
          }
        `}
      />
      <H1
        css={css`
          color: ${blackberry};
          font-size: 35pt;
          padding-top: 32px;

          @media (max-width: 1000px) {
            width: 100%;
            font-size: 28pt;
            text-align: center;
            width: 80%;
          }
        `}
      >
        {" "}
        How do I know if my baby is ready?{" "}
      </H1>
      <BoxRow>
        <Box>
          <img src={GreenCheck} alt="check" css="width:20px" />
          <BoxBody>Is at least 6 months old</BoxBody>
        </Box>
        <Box>
          <img src={GreenCheck} alt="check" css="width:20px" />

          <BoxBody>Can sit without support</BoxBody>
        </Box>
        <Box>
          <img src={GreenCheck} alt="check" css="width:20px" />

          <BoxBody>Can grasp objects and bring them to their mouth</BoxBody>
        </Box>
        <Box>
          <img src={GreenCheck} alt="check" css="width:20px" />

          <BoxBody>Has lost their tongue-thrust reflex</BoxBody>
        </Box>
        <Box>
          <img src={GreenCheck} alt="check" css="width:20px" />

          <BoxBody>Can tell you they are full by pushing a bottle away</BoxBody>
        </Box>
        {width < 1000 && (
          <Box>
            <BoxBody />
          </Box>
        )}
      </BoxRow>
    </PageLayout>
  );
};

const BoxBody = styled(Body)`
  font-size: 12pt;
  line-height: 1.5em;

  @media (max-width: 1000px) {
    text-align: flex-start;
    font-size: 12pt;
    line-height: 1.3em;
  }
`;
const BoxRow = styled.div`
  display: grid;
  padding-top: 64px;
  grid-template-columns: 220px 220px 200px 220px 220px;
  grid-template-rows: 170px;
  @media (max-width: 1000px) {
    grid-template-rows: 170px 170px 170px 170px 170px;
    grid-template-columns: 150px 150px;
  }
`;

const Box = styled.div`
  border: 3px solid ${blackberry};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  margin-right: -3px;
  @media (max-width: 1000px) {
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
