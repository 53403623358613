import React from "react";
import styled from "styled-components";
import { ProfileNavBar } from "./NavBar";

const AccountPage = () => (
  <Account>
    <ProfileNavBar />
  </Account>
  );

const Account = styled.section`
  background: white;
`;

export default AccountPage;
