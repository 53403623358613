import React from "react";
import styled from "styled-components";
import { H5 } from "@team-yumi/ui";

const check = "https://email.helloyumi.com/assets/white-checkbox.svg";

const Notification = ({ text }) => (
  <NotificationContainer>
    <H5 color="#ffffff" style={{ paddingRight: "5px" }}>
      {text}
    </H5>
    <img src={check} />
  </NotificationContainer>
);

const NotificationContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efaf3d;
  color: #ffffff;
  flex-direction: row;
`;

export default Notification;
