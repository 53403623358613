import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  BlueButton,
  texasBrown,
  H6
} from "../../../styledcomponents";
import "styled-components/macro";
import { Peach, HoustonBabe, Baby } from "../assets";
import { Body, H2 } from "../../../styledcomponents/typefaces";
import { Tummy, Brain, Bones } from "../../Blends/assets";

export const WhyItMatters = ({ width }) => (
  <PageLayout>
    <img
      src={Peach}
      alt="peach"
      css="display:none;@media(max-width:500px){width:140%; margin-left:-80px;height:220px; object-fit:cover; }"
    />
    <H2
      css={css`
        color: ${texasOrange};
        padding-top: 60px;
        width: 20%;
        padding-bottom: 10px;
        font-size: 33pt;

        align-self: flex-start;
        @media (max-width: 1200px) {
          width: 70%;
          margin-left: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
        }
        @media (max-width: 1000px) {
          width: 100%;
          margin-left: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
        }
        @media (max-width: 500px) {
          width: 48%;
          margin-left: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
        }
      `}
    >
      Why food matters now.
    </H2>
    <OuterGrid>
      <div />
      <InfoGrid>
        <InfoItem>
          <img
            src={Baby}
            alt="blackberry"
            css="width:100px;max-height:100px;align-self:flex-start;@media(max-width:500px){line-height: 1.4em;width:60px;max-height:60px;}"
          />
          <TextSection>
            <H6
              css={css`
                color: ${texasOrange};
                @media (min-width: 500px) {
                  font-size: 25pt;
                }
              `}
            >
              Taste Preferences
            </H6>
            <Body css="font-size:16pt;padding-top:10px; @media(max-width:500px){width:85%;line-height: 1.4em; font-size:10pt;  }">
              Studies show that early exposure to a wide variety of texture,
              tastes and vegetables can reduce fussiness and instill a love of
              healthy foods later in life.
            </Body>
          </TextSection>
        </InfoItem>
        <InfoItem>
          <img
            src={Brain}
            alt="blackberry"
            css="width:100px;padding-top:10px;align-self:flex-start;@media(max-width:500px){line-height: 1.4em;width:60px;}"
          />
          <TextSection>
            <H6
              css={css`
                color: ${texasOrange};
                @media (min-width: 500px) {
                  font-size: 25pt;
                }
              `}
            >
              Neural Development
            </H6>
            <Body css="font-size:16pt;padding-top:10px; @media(max-width:500px){width:85%;font-size:10pt;line-height: 1.5em;  padding-top:10px;}">
              By age 3, about 80% of the adult brain is formed. During this
              period more than half of all energy consumed is going to straight
              to brain development.
            </Body>
          </TextSection>
        </InfoItem>
        <InfoItem>
          <img
            src={Tummy}
            alt="blackberry"
            css="width:100px;padding-top:10px; align-self:flex-start;@media(max-width:500px){line-height: 1.5em; width:60px;}"
          />
          <TextSection>
            <H6
              css={css`
                color: ${texasOrange};
                @media (min-width: 500px) {
                  font-size: 25pt;
                }
              `}
            >
              Metabolic Issues
            </H6>
            <Body css="font-size:16pt; @media(max-width:500px){width:85%;font-size:10pt; line-height: 1.4em; padding-top:10px;}">
              This period has also been regarded as an important one for fat
              cell development. Eating too much sugar, especially during this
              period, puts your child at greater risk of diabetes and obesity.
            </Body>
          </TextSection>
        </InfoItem>
        <InfoItem>
          <img
            src={Bones}
            alt="blackberry"
            css="width:100px;align-self:flex-start;max-height:100px;@media(max-width:500px){line-height: 1.4em;width:60px;}"
          />
          <TextSection>
            <H6
              css={css`
                color: ${texasOrange};
                @media (min-width: 500px) {
                  font-size: 25pt;
                }
              `}
            >
              Bone Development
            </H6>
            <Body css="font-size:16pt;@media(max-width:500px){font-size:10pt; width:85%; line-height: 1.7em;padding-top:10px;}">
              {" "}
              Babies are born with over 300 bones, as they grow their bones will
              eventually fuse together to form the 206 bones that adults have.
            </Body>
          </TextSection>
        </InfoItem>
        {width > 500 && (
          <Link
            css="width: 100%;align-self: flex-start;text-decoration:none"
            to="/checkout"
          >
            <HoustonButton value="Join Now and Get $60 Off" />
          </Link>
        )}
      </InfoGrid>
      {width > 1200 && (
        <img
          src={HoustonBabe}
          alt="big one"
          css="width:800px;align-self:flex-end;
            @media(min-width:1200px){margin-left:0px} @media(min-width:1300px){margin-left:150px}@media(min-width:1600px){margin-left:300px}@media(min-width:1700px){margin-left:500px}"
        />
      )}
      <div />
    </OuterGrid>
    {width < 500 && (
      <Link
        css="width: 70%;align-self: flex-end;text-decoration:none"
        to="/checkout"
      >
        <HoustonButton value="Join Now and Get $60 Off" />
      </Link>
    )}
  </PageLayout>
);

const OuterGrid = styled.div`
  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 500px 5fr 1fr;
  }
  @media (min-width: 1600px) {
    grid-template-columns: 1fr 500px 5fr 1fr;
  }
`;
const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  @media (max-width: 500px) {
    padding-left: 15px;
  }
`;
const InfoGrid = styled.div`
  display: grid;
  padding-top: 40px;
  grid-template-rows: 250px 250px 250px 250px 100px;

  @media (max-width: 500px) {
    grid-template-rows: 150px 150px 150px 150px;
    grid-template-columns: 330px;
  }
`;

const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  font-size: 14pt;
  padding: 20px;
  width: 300px;
  margin-left: 130px;
  box-shadow: 0px 2px 4px gray;

  @media (max-width: 500px) {
    width: 220px;
    font-size: 11pt;
    margin-left: 0px;
  }
`;

const InfoItem = styled.div`
  width: 100%;
  display: flex;
`;
const PageLayout = styled.div`
  display: flex;
  padding-top: 30px;
  padding-left: 200px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    padding-left: 30px;
    padding-bottom: 40px;
  }
`;
