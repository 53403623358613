import React from "react";
import ReactModal from "react-modal";
import { Children } from "./styledComponents";
import useWindowSize from "../hooks/useWindowSize";

ReactModal.setAppElement("body");
const defaultStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "50%",
    marginTop: "10px",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "560px",
    paddingBottom: "0px",
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingTop: "32px"
  }
};
const mobileStyle = {
  content: {
    bottom: 0,
    top: "auto",
    left: 0,
    right: 0,
    marginRight: "50%",
    marginTop: "10px",
    width: "100%",
    maxWidth: "560px",
    borderRadius: "10px"
  },
  overlay: {
    zIndex: "3000"
  }
};

const Modal = ({ isOpen, children, close }) => {
  const windowSize = useWindowSize();
  const styles = windowSize.width < 500 ? mobileStyle : defaultStyle;
  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={e => {
        e.stopPropagation();
        close();
      }}
    >
      <Children>{children}</Children>
    </ReactModal>
  );
};

export default Modal;
