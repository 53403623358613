import React, { useContext } from "react";
import { css } from "styled-components";
import spacetime from "spacetime";
import { getNextDeliveryDate } from "../../../utils/dates";
import {
  H2,
  blackberry,
  Box,
  Body2,
  Overline2,
  H6,
  BigButton
} from "../../../styledcomponents";
import { useRedirect } from "../../../hooks";

import { GiftCheckoutContext } from "./GiftCheckoutContext";

const getImageUrlFromMealId = id =>
  `https://email.helloyumi.com/assets/0${id}.png`;

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";

export const StepThree = () => {
  const { milestoneMenu, dispatch, progress, history } = useContext(
    GiftCheckoutContext
  );
  const [routeToConfirmation, shouldRedirect] = useRedirect(
    "/confirmation?type=gift"
  );

  const { order_quads } = milestoneMenu;
  const order_attributes = order_quads.reduce(
    (acc, e) => acc.concat(e.meals),
    []
  );
  const formatted_delivery_date = `${spacetime(getNextDeliveryDate()).format(
    "day"
  )}, ${spacetime(getNextDeliveryDate()).format("month-short")}. ${spacetime(
    getNextDeliveryDate()
  ).format("date")}`;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img src={PricingFast} style={{ width: "130px" }} alt="quick" />
      <H2 style={{ color: blackberry }}>Congratulations!</H2>

      <Box
        nowrap
        style={{
          background: "white",
          alignItems: "flex-start",
          flex: "0 2 450px",
          padding: "2.5em 3.5em",
          marginBottom: "1em",
          maxWidth: "100%",
          marginTop: "3em"
        }}
        mobileStyle="padding: 2.5em 1.5em;"
        css={css`
          margin-left: 15px;
          margin-right: 15px;
          @media (max-width: 500px) {
            margin-left: 0px;
            margin-right: 0px;
          }
        `}
      >
        <H6
          style={{
            textAlign: "left",
            marginBottom: "2em"
          }}
        >
          {" "}
          Order Summary{" "}
        </H6>{" "}
        <Box
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%"
          }}
        >
          <Overline2 style={{ fontSize: "15px" }}>
            {" "}
            FIRST DELIVERY DATE{" "}
          </Overline2>{" "}
          <Body2
            style={{
              marginTop: ".31em",
              marginBottom: "2em",
              fontSize: "20px"
            }}
          >
            {" "}
            {formatted_delivery_date}
          </Body2>{" "}
        </Box>{" "}
        <Box
          full
          name="list of jars from your first box"
          css="margin-bottom:1em; align-items: flex-start;"
        >
          <Overline2 css="margin-bottom:1em;"> FIRST BOX </Overline2>{" "}
          {order_attributes.map(meal => (
            <Box
              row
              full
              css="justify-content:space-between; height: 48px;"
              key={meal.meal_id}
            >
              <Body2>
                <img
                  src={getImageUrlFromMealId(meal.meal_id)}
                  height="32px"
                  width="auto"
                  alt="baby food mini jar"
                  css="margin-right:.75em;"
                />
                {meal.meal_name}{" "}
              </Body2>{" "}
              <Body2> x {meal.quantity} </Body2>{" "}
            </Box>
          ))}{" "}
        </Box>{" "}
      </Box>
      <BigButton
        value="Continue"
        className="checkout-step-three"
        onClick={() => {
          shouldRedirect(true);
        }}
        css="background-color: #334897; color: white; &:hover {background-color: white; color: #334897; border-color: #334897; }"
      />
      {routeToConfirmation()}
    </div>
  );
};
