import { updateOrderAddress } from "../../usecases/orders";

/**
 *
 * @param {Number} orderId
 * @param {Array} newItems
 */

const updateOrderAddressService = async (orderId, address) => {
  try {
    const update = await updateOrderAddress(orderId, address);
    return update;
  } catch (err) {
    throw Error(`There was an issue updating order ${orderId}`, err);
  }
};

export default updateOrderAddressService;
