import React, { useState } from "react";
import {
  Card,
  CardTitle,
  Label,
  InputField,
  EditHeader,
  FormContainer,
  ButtonContainer,
  ModalButton,
  CardSubtitle
} from "../components/styledComponents";
import Modal from "../components/Modal";
import UserIcon from "../../../../assets/icons/Dashboard-icons/person-icon.svg";
import { updateUser } from "../../../usecases/users";

export const YourProfile = ({
  activeModal,
  close,
  openModal,
  success,
  user
}) => {
  const { name, email, id } = user;
  const [form, updateForm] = useState({ name, email });
  const [error, setError] = useState("");
  return (
    <Card onClick={openModal} icon={UserIcon}>
      <CardTitle>Profile</CardTitle>
      <CardSubtitle>Name, email</CardSubtitle>
      <Modal isOpen={activeModal === "Your Profile"} close={close}>
        <EditHeader close={close}>Edit your profile</EditHeader>
        <FormContainer
          onSubmit={async e => {
            e.preventDefault();
            try {
              await updateUser(id, form);
              success();
              close();
            } catch (e) {
              setError(
                "Something went wrong. Please refresh the page and try again."
              );
            }
          }}
        >
          <Label htmlFor="profileName">Name</Label>
          <InputField
            onChange={e => updateForm({ ...form, name: e.target.value })}
            id="profileName"
            value={form.name}
            type="text"
            name="name"
          />

          <Label htmlFor="userEmail">Email</Label>
          <InputField
            onChange={e => updateForm({ ...form, email: e.target.value })}
            id="userEmail"
            value={form.email}
            type="text"
            name="email"
          />
          <ButtonContainer>
            <ModalButton cancel type="button" value="Cancel" onClick={close} />
            <ModalButton type="submit" value="Save" />
          </ButtonContainer>
        </FormContainer>
        <p style={{ color: "red" }}>{error}</p>
      </Modal>
    </Card>
  );
};
