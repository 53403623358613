import styled from "styled-components";
import { Box, theme } from "@team-yumi/ui";

export default styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${theme.smallDesktop} {
    border-top: 1px solid ${theme.rule};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    padding: 20px;
    ${({ bg }) => !bg && "background:white"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;
