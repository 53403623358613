import React, { useState } from "react";
import _ from "lodash";
import "styled-components/macro";
import { css } from "styled-components";

import {
  Box,
  Quote_D,
  Quote_M,
  Text,
  H2,
  H2_M,
  blackberry
} from "../../../styledcomponents";
import { Vogue, NYT, Goop, FoodAndWine, Eating } from "../assets";

const pullContent = [
  {
    image: NYT,

    copy: "Make room, Gerber Babies, for the Goo-Goo Gourmets."
  },
  {
    image: Goop,
    copy:
      "Yumi, an adorable new pediatrician/nutritionist-approved baby-food delivery company, makes the whole experience a pleasure."
  },
  {
    image: Vogue,
    copy:
      "Rooted in the research-backed idea that a child’s first 1,000 days, starting with conception, are crucial to well-being, the plan takes aim at the sugar-loaded squeeze packs and jars found at the supermarket."
  },
  {
    image: FoodAndWine,
    copy:
      "Admit it: If you order these meals, your baby will eat more adventurously than you do."
  }
];

const ms = style => `@media(max-width: 800px){${style}}`;

export const PressTestimonials = ({ width }) => {
  const [index, setIndex] = useState(0);
  const pulls = width > 900 ? pullContent : pullContent.slice(0, 3);
  return (
    <Box
      full
      name="testimonial box"
      css={`
        margin: 120px auto;
        max-width: 1238px;
        width: 100%;
        padding: 0 32px;
        /align-items: flex-start;
        padding-left: 124px;
        padding-right: 124px;
        text-align: center;
        ${ms("padding: 0; margin: 80px 0px; padding: 0px 24px; width: auto;")};
      `}
    >
      <img
        src={Eating}
        css={css`
          margin: 0px auto 24px;
          max-width: 250px;
          max-height: 250px;
          width: 100%;
          ${ms("width: 50%; margin: 0px auto 16px;")};
        `}
      />
      <Text
        d={H2}
        m={H2_M}
        css={css`
          margin: 0 auto 16px;
          color: ${blackberry};
        `}
      >
        Talk Of The Town
      </Text>
      <Text
        d={Quote_D}
        m={Quote_M}
        css={`
          ${ms("height: auto;")};
          @media (max-width: 900px) {
          }
        `}
      >
        "{pullContent[index].copy}"
      </Text>
      <Box
        row
        full
        name="logos"
        css={css`
          justify-content: space-between;
          width: 100%;
          margin-top: 56px;
          max-width: 800px;
          ${ms("justify-content: center; margin-top: 0; order: 1;")};
        `}
      >
        {pulls.map(({ image, copy }, ind) => (
          <button
            type="button"
            css={`
              cursor: pointer;
              outline: none;
              height: 30px;
              padding: 0;
              @media (max-width: 900px) {
                margin: 1em 20px 1em 0;
              }
            `}
            key={copy}
            onClick={() => setIndex(ind)}
          >
            <img
              src={image}
              alt="logo for this company"
              height="100%"
              css={`
                max-width: 100%;

                opacity: ${ind === index ? "1" : ".15"};
                @media (max-width: 900px) {
                  max-height: 20px;
                }
              `}
            />
          </button>
        ))}
      </Box>
    </Box>
  );
};
