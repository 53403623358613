import React from "react";
import { H1, H4, H5 } from "@team-yumi/ui";
import styled from "styled-components";

const PlanItem = ({ image, count, select, selected }) => (
  <PlanItemContainer onClick={select}>
    <Image src={image} />
    <PlanText selected={selected}>
      <PlanTextTop>
        <H1 mr="10px">{count}</H1>
        <Meals>{`meal${count > 1 ? "s" : ""} per day`}</Meals>
      </PlanTextTop>
      <PlanTextBottom>
        recommended for babes {count === 1 ? "5-7" : count === 2 ? "7-9" : "9+"}{" "}
        months
      </PlanTextBottom>
    </PlanText>
  </PlanItemContainer>
);

const PlanItemContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-family: "Platform-Medium";
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  @media (min-width: 650px) {
    flex-direction: column;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  h1,
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  width: 160px;
  height: 136px;
  object-fit: cover;
  object-position: 50% 80%;
  @media (min-width: 650px) {
    width: 206px;
    height: 170px;
  }
`;

const PlanText = styled.div`
  height: 136px;
  display: flex;
  flex-direction: column;
  background-color: ${({ selected }) => (selected ? "#658FCA" : "white")};
  color: ${({ selected }) => (selected ? "white" : "black")};
  flex: 1;
  align-items: center;
  padding: 42px 40px;
  padding-right: 10px;
  @media (min-width: 650px) {
    width: 206px;
    height: 150px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const PlanTextTop = styled.div`
  width: 100%;
  display: flex;
  line-height: 1;
  align-items: center;
`;

const PlanTextBottom = styled(H5)`
  display: none;
  @media (min-width: 650px) {
    padding-top: 22px;
    display: block;
  }
`;

const Meals = styled(H4)`
  flex: 1;
  width: 90px;
  @media (min-width: 650px) {
    padding-bottom: 6px;
  }
`;

export default PlanItem;
