import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { slide as Menu } from "react-burger-menu";
import spacetime from "spacetime";
import _ from "lodash";
import { connect } from "react-redux";
import {
  Sections,
  ReviewsCarousel,
  BlueButton,
  H6,
  Box,
  InputField
} from "../../styledcomponents";
import "styled-components/macro";

import { placeOneOffOrder } from "../../usecases/checkout";
import StripeBillingForm from "../../styledcomponents/containers/StripeBillingForm";

import { AddressForm } from "../../styledcomponents/AddressForm";
import { yumiOrange, blackberry } from "../../styledcomponents/colors";
import {
  FirstFoods,
  WhatsIncluded,
  WhyParentsLove,
  CrowdPleaser,
  FreshMadeWeekly,
  FAQ,
  LetsStart
} from "./sections";
import { Body } from "../../styledcomponents/typefaces";
import { useWindowSize, useFormState } from "../../hooks";
import {
  menuStyles,
  YumiLogoSmall,
  FirstFoodsMini,
  Hazmat,
  Nutrition,
  Calendar,
  Receipt
} from "./assets";
import { updateUserId } from "../../redux/actions/Checkout";

const mapDispatchToProps = dispatch => ({
  addUserToGlobalStore: payload => dispatch(updateUserId(payload))
});

const MenuCart = [
  { image: Hazmat, name: "Baby Hazmat Suit", price: 0 },
  {
    image: Nutrition,
    name: "Nutritional Coaching Session",
    subtitle: "30 Minute Session With Our Dietician",
    price: 0
  },
  { image: Calendar, name: "Intro to Solids Guide", price: 0 }
];

const shipping = 0;

const MenuContext = React.createContext();

const MenuProvider = ({ children, history, addUserToGlobalStore }) => {
  const [isShelfOpen, setIsShelfOpen] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    value: "weekly",
    label: "The First Food Pack - 7 Jars - x1 Week - $35",
    subtotal: 35
  });
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const planOptions = [
    {
      value: "weekly",
      label: "The First Food Pack - 7 Jars - x1 Week - $35",
      subtotal: 35
    },
    {
      value: "monthly",
      label: "The First Food Pack - 7 Jars - x1 Month - $140",
      subtotal: 140
    }
  ];
  return (
    <MenuContext.Provider
      value={{
        isMenuOpen: isShelfOpen,
        toggleShelf: () => setIsShelfOpen(old => !old),
        stateChangeHandler: newState => setIsShelfOpen(newState.isOpen),
        toggleCheckout: () => setIsCheckout(old => !old),
        changePlan: plan => setSelectedPlan(plan),
        selectedQuantity,
        setSelectedQuantity,
        planOptions,
        selectedPlan,
        isCheckout,
        history
      }}
    >
      <ShelfCheckout addUserToGlobalStore={addUserToGlobalStore} />
      {children}
    </MenuContext.Provider>
  );
};

export const AddToCartButton = ({
  screenWidth,
  width,
  padding,
  fontSize,
  fixed = false
}) => {
  const shelfContext = useContext(MenuContext);

  return screenWidth < 500 && shelfContext.isMenuOpen ? (
    <p>Checkout open </p>
  ) : (
    <OrangeButton
      width={width}
      padding={padding}
      fontSize={fontSize}
      value="ADD TO CART"
      onClick={shelfContext.toggleShelf}
      fixed={fixed}
    />
  );
};

const ShelfCheckout = ({ addUserToGlobalStore }) => {
  const {
    isMenuOpen,
    toggleShelf,
    isCheckout,
    toggleCheckout,
    selectedPlan,
    selectedQuantity,
    setSelectedQuantity,
    history
  } = useContext(MenuContext);

  return (
    <Menu
      disableAutoFocus
      disableOverlayClick={toggleShelf}
      styles={menuStyles}
      css={css`
        .bm-menu-wrap {
          position: fixed;
          top: 0;
          min-width: 380px;
          max-width: 380px;

          @media (max-width: 800px) {
            overflow: hidden !important;
          }
        }
        .bm-menu {
          padding: 0em 0em 0;
          font-size: 1.15em;
          overflow: hidden;

          @media (max-width: 800px) {
            overflow-x: hidden !important;
            overflow-y: auto !important;
            min-height: 50vh !important;
            outline: 3px solid red !important;
          }
        }
        .bm-morph-shape {
          fill: #373a47;
        }
        .bm-item-list {
          color: "#b8b7ad";
        }
        bm-item {
          display: inline-block;
        }
        bm-overlay {
          top: 0;
          background: rgba(0, 0, 0, 0.3);
        }
      `}
      right
      isOpen={isMenuOpen}
    >
      <CheckoutColumn>
        {isCheckout ? (
          <Checkout
            selectedPlan={selectedPlan}
            selectedQuantity={selectedQuantity}
            toggleCheckout={toggleCheckout}
            toggleShelf={toggleShelf}
            history={history}
            addUserToGlobalStore={addUserToGlobalStore}
          />
        ) : (
          <Cart
            selectedPlan={selectedPlan}
            toggleCheckout={toggleCheckout}
            toggleShelf={toggleShelf}
            selectedQuantity={selectedQuantity}
            setSelectedQuantity={setSelectedQuantity}
          />
        )}
      </CheckoutColumn>
    </Menu>
  );
};

const Checkout = ({
  toggleCheckout,
  toggleShelf,
  selectedPlan,
  selectedQuantity,
  history,
  addUserToGlobalStore
}) => {
  const [shippingFormState, setShippingField, setShippingForm] = useFormState({
    street: "",
    street2: "",
    city: "",
    state: "",
    postcode: ""
  });
  const [userFormState, setUserField] = useFormState({
    email: "",
    phone: ""
  });

  const [formError, setFormError] = useState("");
  const [cardholder, setCardholder] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const [getStripeData, setStripeSubmit] = useState(null);

  const isFormFull = _.every([
    userFormState.email,
    cardholder,
    userFormState.phone,
    ..._.values({
      ..._.omit(shippingFormState, "street2")
    })
  ]);

  const yesterday = spacetime
    .yesterday()
    .add(1, "month")
    .subtract(5, "days")
    .format("{year}-{month-pad}-{date-pad}");

  const submitOneOffOrder = async stripeData => {
    if (!isFormFull)
      return setFormError(
        "There are required form fields missing. Double check your form and try again"
      );

    setIsButtonClicked(true);

    try {
      setFormError("");
      setIsPlacingOrder(true);
      const user = await placeOneOffOrder({
        email: userFormState.email,
        name: cardholder,
        phone: userFormState.phone,
        address: shippingFormState,
        stripe_card_token: stripeData.token.id,
        billing_plan: "bento-five",
        timeline: "weekly",
        special_menu_id: 39,
        segment_user_id: window.analytics
          ? window.analytics.user().anonymousId()
          : "not-found",
        count,
        child_birthday: yesterday
      });

      if (!_.get(user, "id", null)) throw new Error(user.details);

      addUserToGlobalStore(user);

      history.push({
        pathname: "/confirmation",
        state: { onetime: true, user, selectedPlan, selectedQuantity }
      });
    } catch (userError) {
      setIsButtonClicked(false);
      setIsPlacingOrder(false);
      setFormError(userError.message);
    }
  };

  const count = selectedPlan.value === "monthly" ? 4 : 1;

  const price = 35 * count;

  const tax = (price * 0.09505).toFixed(2);

  const subtotal = Number(price).toFixed(2);

  const total = Number(subtotal) + Number(tax);

  return (
    <React.Fragment>
      <TempForm css="@media(max-width:500px){height:160vh; align-items:center;}">
        <TopRow>
          <H6 css="justify-self:flex-start; font-size: 10pt; display:flex;align-items:baseline;">
            {" "}
            <img
              src={YumiLogoSmall}
              alt="yumi-logo-small"
              css="width:60px"
            />{" "}
            <span css="padding-left:10px">CHECKOUT</span>
          </H6>
          <div css="display:flex">
            <BackButton
              type="button"
              value="Return To Cart"
              onClick={toggleCheckout}
            />
            <CloseButton type="button" value="Close" onClick={toggleShelf} />
          </div>
        </TopRow>
        <hr css="width:100%" />

        <Box css="background:white; width:90%; padding:20px;justify-self:center; margin-top:10px; ">
          <H6
            css={css`
              margin-top: 1em;
              flex-shrink: 0;
              font-size: 12px;
              margin-bottom: 1em;
              color: ${yumiOrange};
            `}
          >
            {" "}
            SHIPPING ADDRESS{" "}
          </H6>{" "}
          <InputField
            type="email"
            shelf
            onChange={setUserField("email")}
            errorMessage="Please enter a valid email"
            error={emailError}
            css="color:black"
            placeholder="Email"
            value={userFormState.email}
            id="checkoutEmailField"
            label="Email"
            style={{ width: "300px", maxWidth: "100%", marginBottom: "5px" }}
          />
          <InputField
            type="phone"
            shelf
            onChange={setUserField("phone")}
            errorMessage="Please enter a phone number"
            value={userFormState.phone}
            css="color:black"
            placeholder="Phone"
            id="checkoutPhone"
            label="Phone"
            style={{ width: "300px", maxWidth: "100%", marginBottom: "5px" }}
          />
          <AddressForm
            shelf
            formState={shippingFormState}
            setField={setShippingField}
            setForm={setShippingForm}
          />{" "}
        </Box>
        <Box css="background:white;width:90%; padding:20px; justify-self:center; margin-top:10px;">
          <H6
            css={css`
              margin-top: 1em;
              flex-shrink: 0;
              font-size: 12px;

              margin-bottom: 1em;
              color: ${yumiOrange};
            `}
          >
            PAYMENT INFORMATION{" "}
          </H6>{" "}
          <StripeBillingForm
            shelf
            hideAddress
            setStripeSubmit={setStripeSubmit}
            applePayMessage="Payment processed successfully. Click PLACE ORDER to finish signing up."
            amount={total}
          />{" "}
          <Box row nowrap css="width: 100%; margin-top: 0.5em; flex-shrink: 0;">
            <InputField
              type="text"
              label="Name on card"
              shelf
              id="cardHolder"
              placeholder="Name on card "
              onChange={e => setCardholder(e.target.value)}
              value={cardholder}
            />{" "}
          </Box>{" "}
        </Box>
        <Body css="font-size:12pt; line-height:1.5em; color: red;">
          {" "}
          {formError}{" "}
        </Body>

        <Receipt
          selectedPlan={selectedPlan}
          price={price}
          selectedQuantity={selectedQuantity}
        />
        <PriceSection
          subtotal={price}
          selectedPlan={selectedPlan}
          total={total}
          tax={tax}
        />
      </TempForm>
      <CheckoutButton
        purchase
        onClick={() =>
          getStripeData !== null &&
          getStripeData().then(stripeData => submitOneOffOrder(stripeData))
        }
        value="PURCHASE"
      />
    </React.Fragment>
  );
};

const Cart = ({
  toggleShelf,
  toggleCheckout,
  selectedPlan,
  selectedQuantity
}) => {
  const count = selectedPlan.value === "monthly" ? 4 : 1;
  const price = 35 * count;

  return (
    <React.Fragment>
      <TempForm>
        <TopRow>
          <H6 css="justify-self:flex-start; font-size: 10pt; display:flex; flex-direction:column;align-items:baseline;">
            {" "}
            <span css="padding-left:10px">YOUR CART</span>
            <span css="padding-left:10px;padding-top:5px;">
              {selectedPlan && selectedPlan.label}
            </span>
          </H6>
          <CloseButton type="button" value="Close" onClick={toggleShelf} />
        </TopRow>
        <hr css="width:100%" />
        <CartCol>
          <CartRow>
            <img
              css=" width:85px; @media(max-width:800px){width:75px;}"
              src={FirstFoodsMini}
              alt="img"
            />
            <div css="display:flex;flex-direction:column;">
              <CartBody>First Foods Box</CartBody>
              <CartBody css="color:gray; font-size:10pt; padding-top:10px;">
                Variety Pack - 8 jars
              </CartBody>
              {/* <ButtonRow>
              {" "}
              <WhiteMinusButton
                type="button"
                onClick={() => {
                  selectedQuantity > 1 && setSelectedQuantity(old => old - 1);
                }}
                value="-"
              />
              <CartBody css="padding-left:10px; padding-right:10px;font-size:12pt; @media(max-width:800px){padding-top:2px;}">
                {selectedQuantity}
              </CartBody>
              <WhitePlusButton 
                type="button"
                onClick={() => {
                  selectedQuantity < 3 && setSelectedQuantity(old => old + 1);
                }}
                value="+"
              />
              </ButtonRow> */}
            </div>
            <CartBody>
              ${price}
              {selectedPlan.value === "monthly" && "/month"}
            </CartBody>
          </CartRow>
          {MenuCart.map(cartItem => (
            <CartRow>
              <img
                css="width:85px; @media(max-width:800px){width:75px;}"
                src={cartItem.image}
                alt="img"
              />
              <div css="display:flex;flex-direction:column;">
                <CartBody>{cartItem.name}</CartBody>
                {cartItem.subtitle && (
                  <CartBody css="color:gray; font-size:10pt; padding-top:10px; @media(max-width:800px){padding-top:2px;}">
                    {cartItem.subtitle}
                  </CartBody>
                )}
              </div>
              <CartBody>
                {cartItem.price > 0 ? `$${cartItem.price}` : "Free"}
              </CartBody>
            </CartRow>
          ))}
          <Receipt
            selectedPlan={selectedPlan}
            price={price}
            selectedQuantity={selectedQuantity}
          />
          <PriceSection subtotal={price} selectedPlan={selectedPlan} />
        </CartCol>
      </TempForm>
      <CheckoutButton value="CHECKOUT" onClick={toggleCheckout} />
    </React.Fragment>
  );
};

const PriceSection = ({ subtotal, selectedPlan, tax = 0, total = 0 }) => (
  <PriceArea>
    <PriceItem>
      <CartBody>Subtotal</CartBody>
      <CartBody>
        ${subtotal}
        {selectedPlan.value === "monthly" && "/month"}{" "}
      </CartBody>
    </PriceItem>
    <PriceItem>
      <CartBody>Shipping</CartBody>
      <CartBody>{shipping > 0 ? shipping : "Free"}</CartBody>
    </PriceItem>
    {tax > 0 && (
      <PriceItem>
        <CartBody>Tax</CartBody>
        <CartBody>${tax}</CartBody>
      </PriceItem>
    )}
    <PriceItem>
      <CartBody>Estimated Total </CartBody>
      <CartBody>${total > 0 ? total : subtotal}</CartBody>
    </PriceItem>
  </PriceArea>
);

export const OneOffPurchase = connect(
  () => ({}),
  mapDispatchToProps
)(({ history, addUserToGlobalStore }) => {
  const { width } = useWindowSize();
  return (
    <MenuProvider history={history} addUserToGlobalStore={addUserToGlobalStore}>
      {width < 800 && (
        <AddToCartButton
          screenWidth={width}
          fontSize="20px"
          width="100%"
          padding="15px"
          fixed
        />
      )}
      <Sections>
        <FirstFoods
          MenuContext={MenuContext}
          AddToCartButton={(
            <AddToCartButton
              className="add-button"
              id="addy-button"
              fontSize="20px"
              padding="20px"
              width="100%"
            />
)}
          width={width}
        />
        <WhatsIncluded width={width} MenuContext={MenuContext} />
        <WhyParentsLove
          AddToCartButton={<AddToCartButton fontSize="14pt" />}
          width={width}
        />
        <CrowdPleaser width={width} />
        <FreshMadeWeekly width={width} />
        <ReviewsCarousel color={yumiOrange} />
        <FAQ
          width={width}
          AddToCartButton={
            <AddToCartButton width="270px" padding="15px" fontSize="20px" />
          }
        />
        <LetsStart
          AddToCartButton={
            <AddToCartButton fontSize="20px" width="450px" padding="18px" />
          }
          width={width}
        />
      </Sections>
    </MenuProvider>
  );
});

const PriceArea = styled.div`
  display: grid;
  border-top: 1px solid gray;
  grid-template-rows: 20px 20px 20px;
  padding-top: 10px;
  margin-top: 30px;
  padding-bottom: 15px;
`;
const PriceItem = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
  @media (max-width: 500px) {
    grid-template-columns: 165px 165px;
  }
`;
const CartCol = styled.div`
  display: grid;
  grid-template-rows: 95px 95px 95px 95px 100px 100px;

  @media (max-width: 800px) {
    grid-template-rows: 90px 90px 90px 90px 100px 100px;
  }
`;
const CartBody = styled(Body)`
  font-size: 10pt;
  line-height: 1.2em;
  color: black;

  @media (max-width: 800px) {
    font-size: 9pt;
    line-height: 1em;
  }
`;
const CartRow = styled.div`
  display: grid;
  grid-template-columns: 120px 160px 50px;
  align-items: flex-start;
`;
const CloseButton = styled(BlueButton)`
  color: black;
  justify-self: flex-end;
  border: 1px solid red;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12pt;
  padding: 10px;
  color: ${yumiOrange};
`;

const BackButton = styled(BlueButton)`
  color: black;
  justify-self: flex-end;
  font-size: 12pt;
  padding: 10px;
  border: 1px solid ${blackberry};
  border-radius: 4px;
  margin-right: 5px;
  color: ${blackberry};
  cursor: pointer;
`;
const CheckoutButton = styled(BlueButton)`
  color: white;
  font-size: 15px;
  padding: 10px;
  width: 380px;

  background: ${({ purchase }) => (purchase ? "gray" : yumiOrange)};
  bottom: 0;
  position: fixed;
  min-height: 80px;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const OrangeButton = styled(BlueButton)`
  color: white;
  font-size: ${({ fontSize }) => fontSize || "12px"};
  padding: ${({ padding }) => padding || "10px"};
  background: ${yumiOrange};
  width: ${({ width }) => width || "200px"};
  box-shadow: 0px 1px 3px gray;
  bottom: ${({ fixed }) => fixed && "0;"};
  position: ${({ fixed }) => fixed && "fixed;"};
  margin: ${({ fixed }) => fixed && "0 auto;"};
  z-index: ${({ fixed }) => fixed && "1000;"};
  height: ${({ fixed }) => fixed && "80px;"};
`;

const CheckoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TempForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 800px) {
    overflow-y: scroll;
    min-height: 90vh;
    max-height: 90vh;
    height: 90vh;
  }

  @media (max-width: 500px) {
    height: 120vh;
  }

  padding: 0.8em;
`;
