import * as types from "../actions/types";
import { getCurrentDeliveryCycle, getMonthAndDay } from "../../utils/dates";

const initialState = {
  allIds: [],
  quantityById: {},
  nameById: {},
  totalInCart: 0,
  mealLimit: 12,
  specialMenuId: null,
  deliveryCycle: {
    start: null,
    end: null
  }
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TO_CART: {
      const { id, mealName, quantity, menu_id } = action.payload;
      let allIds = [...state.allIds];
      if (allIds.find(item => item.meal_id === id) === undefined) {
        allIds = [...allIds, { meal_id: id, menu_id }];
      }
      const quantityById = {
        ...state.quantityById,
        [menu_id]: (state.quantityById[menu_id] || 0) + quantity
      };
      const nameById = {
        ...state.nameById,
        [id]: state.nameById[id] || mealName
      };
      const totalInCart = state.totalInCart + quantity;
      // I'm not really proud of this fix. but i mean. it's all we have for now
      if (totalInCart > state.mealLimit * 2)
        return {
          ...state,
          allIds: [],
          nameById: {},
          totalInCart: 0,
          quantityById: {}
        };
      return { ...state, allIds, quantityById, nameById, totalInCart };
    }

    case types.REMOVE_FROM_CART: {
      const { id, quantity } = action.payload;
      let allIds = [...state.allIds];
      let quantityById = { ...state.quantityById };
      const nameById = { ...state.nameById };

      if (state.quantityById[id] - quantity === 0) {
        delete quantityById[id];
        delete nameById[id];
        allIds = allIds.filter(item => item.menu_id !== id);
      } else {
        quantityById = {
          ...state.quantityById,
          [id]: state.quantityById[id] - quantity
        };
      }
      const totalInCart = state.totalInCart - quantity;

      return { ...state, allIds, quantityById, nameById, totalInCart };
    }

    case types.EMPTY_CART: {
      return {
        ...state,
        allIds: [],
        nameById: {},
        totalInCart: 0,
        quantityById: {}
      };
    }

    case types.SET_MEALS_PER_WEEK: {
      const { numMeals } = action;
      return { ...state, numMeals };
    }

    case types.SET_SPECIAL_MENU: {
      const { id } = action.payload;

      return { ...state, specialMenuId: id };
    }

    case types.REMOVE_SPECIAL_MENU: {
      return {
        ...state,
        specialMenuId: null
      };
    }

    case types.SET_DELIVERY: {
      const { start, end } = getCurrentDeliveryCycle();
      const deliveryCycle = {
        start: { date: start, string: getMonthAndDay(start) },
        end: { date: end, string: getMonthAndDay(end) }
      };

      return { ...state, deliveryCycle };
    }

    default:
      return state;
  }
};

export default cartReducer;
