import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  BlueButton,
  texasBrown,
  H6,
  Body1
} from "../../../styledcomponents";
import "styled-components/macro";
import { Rizzo } from "../assets";

export const YouKnowFoodSlice = () => (
  <YouKnowFood>
    <RizzoImage src={Rizzo} alt="starter" />
    <ContentArea>
      <HoustonH1>You know good food and soon your babe will too.</HoustonH1>
      <Body1
        css="font-size: 16pt; padding-top: 20px; width: 100%; @media (max-width: 900px) {
    font-size: 13pt;
    width: 85%;
  }"
      >
        We know Houston is ranked one of the top, most diverse food cities in
        the country. We also know that your babies taste buds form in the first
        2 years, which is why we introduce your baby to over 100 ingredients
        including superfoods and spices from around the world. Get ready to
        taste the world, baby.
      </Body1>
      <Link
        css="text-decoration:none; 
  margin-top: 20px;"
        to="/menu"
      >
        <HoustonButton value="Explore our Blends" />
      </Link>
    </ContentArea>
  </YouKnowFood>
);

const RizzoImage = styled.img`
  width: 450px;

  @media (max-width: 900px) {
    width: 400px;
    max-height: 490px;
  }
`;
const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  box-shadow: 0px 2px 4px gray;
  padding: 20px;
  font-size: 14pt;
  width: 300px;
  margin-top: 20px;
`;
const HoustonH1 = styled(H6)`
  font-size: 34pt;
  color: ${texasOrange};
  padding-top: 25px;
  @media (max-width: 900px) {
    font-size: 28pt;
  }
  @media (max-width: 700px) {
    font-size: 23pt;
    width: 75%;
  }
`;

const YouKnowFood = styled.div`
  display: flex;
  padding-top: 80px;
  justify-content: center;
  width: 100%;
`;

const ContentArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 420px;
  padding-left: 40px;
`;
