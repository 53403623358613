import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import Select from "react-select";
import {
  InnerGridTemplate,
  LittleCarrot,
  Kiwi,
  StarterPack,
  YumiBaby,
  Calendar,
  AllJars,
  DownArrow
} from "../assets";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { yumiOrange, H5, H6, BlueButton } from "../../../styledcomponents";

const images = [StarterPack, YumiBaby, Calendar, AllJars];

export const FirstFoods = ({ AddToCartButton, width, MenuContext }) => {
  const [activeImage, setActiveImage] = useState(StarterPack);

  const dropDownContext = useContext(MenuContext);

  return (
    <FirstContainer>
      <img
        src={LittleCarrot}
        alt="cart"
        css={css`
          left: -200px;
          margin-top: 100px;
          width: 450px;
          position: absolute;
          @media (max-width: 1350px) {
            width: 370px;
            left: -150px;
            margin-top: 60px;
          }
          @media (max-width: 800px) {
            width: 220px;
            left: -105px;
            margin-top: -145px;
          }
        `}
      />
      <img
        src={Kiwi}
        alt="da-kiwi"
        css={css`
          right: 0;
          top: 280px;
          position: absolute;

          @media (max-width: 800px) {
            width: 70px;
            top: 130px;
          }
        `}
      />
      <InnerGridTemplate
        gridDimensions="0.3fr 1fr 1.2fr 0.3fr"
        leftStyling={css`
          @media (max-width: 800px) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
        leftChildren={(
          <PhotoGallery
            css={css`
              padding-top: 30px;
            `}
          >
            <img
              src={activeImage}
              css={css`
                height: 606px;
                object-fit: cover;
                width: 606px;
                @media (max-width: 1200px) {
                  height: 504px;
                  width: 504px;
                }
                @media (max-width: 1100px) {
                  height: 404px;
                  width: 404px;
                }
                @media (max-width: 800px) {
                  height: 304px;
                  width: 304px;
                }
              `}
              alt="active"
            />
            <TinyRow
              css={css`
                display: flex;
                width: 100%;
                justify-content: center;
                padding-top: 40px;
                padding-bottom: 40px;

                @media (max-width: 800px) {
                  padding-top: 10px;
                }
              `}
            >
              <Inner
                css={css`
                  display: flex;
                  justify-content: space-evenly;
                  width: 440px;

                  @media (max-width: 800px) {
                    width: 200px;
                  }
                `}
              >
                {images.filter(img => img !== activeImage).map(img => (
                  <TinyImage
                    onClick={() => setActiveImage(img)}
                    src={img}
                    alt="tiny"
                    css={css`
                      width: 103px;
                      height: 93px;

                      @media (max-width: 800px) {
                        width: 45px;
                        height: 40px;
                      }
                    `}
                  />
                ))}
              </Inner>
            </TinyRow>
          </PhotoGallery>
)}
        rightStyling={css`
          width: 100%;
          padding-left: 60px;
          justify-content: center;
          align-items: center;
          margin-top: -25px;
        `}
        rightChildren={(
          <React.Fragment>
            <CopyArea>
              <H5
                css={css`
                  font-size: 30px !important;
                  padding-bottom: 20px;
                  @media (max-width: 1350px) {
                    font-size: 21px;
                  }
                  @media (max-width: 1100px) {
                    font-size: 12px;
                  }
                  @media (max-width: 800px) {
                    font-size: 15px !important;
                    padding-bottom: 0px;
                    padding-top: 30px;
                  }
                `}
              >
                LIMITED EDITION
              </H5>
              <H1
                css={css`
                  padding-top: 12px;
                  font-size: 60px;
                  color: ${yumiOrange};

                  @media (max-width: 1200px) {
                    font-size: 45px;
                  }

                  @media (max-width: 1000px) {
                    font-size: 30px;
                  }
                `}
              >
                The First Foods Pack
              </H1>
              <Body
                css={css`
                  padding-top: 20px;

                  @media (max-width: 1200px) {
                    font-size: 19px;
                  }
                  @media (max-width: 1100px) {
                    font-size: 15px;
                    line-height: 1.5em;
                  }
                `}
              >
                It's time. Your baby has been eyeing your plate, and you're
                tempted to give them a bite. But where do you begin? One
                ingredient at a time? Allergens? Salt? We know, it can get a
                little daunting. Introducing your answer to home made baby food,
                but better.
              </Body>
            </CopyArea>
            <HTMLSelect>
              <Select
                options={dropDownContext.planOptions}
                css="width:100%;"
                id="dropdownOrder"
                onChange={newState => {
                  dropDownContext.changePlan(newState);
                }}
                value={dropDownContext.selectedPlan}
              />
            </HTMLSelect>
            {/* } <HTMLSelect>
              <FirstFoodPackSelector css={css``}>
                The First Foods Pack - 7 Jars
              </FirstFoodPackSelector>
              <RightSide>
                <H6
                  css={css`
                    color: ${yumiOrange};
                    outline: none;
                  `}
                >
                  ${35 * dropDownContext.selectedPlan}
                </H6>
                }{" "}
                <DropDownButton>
                  <img src={DownArrow} alt="arrow" css="width:15px" />
                </DropDownButton>
              </RightSide>
              </HTMLSelect> */}
            {width > 800 && AddToCartButton}
          </React.Fragment>
)}
      />
    </FirstContainer>
  );
};

const RightSide = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const DropDownButton = styled(BlueButton)`
  color: ${yumiOrange};
  outline: none;
`;
const HTMLSelect = styled.div`
  display: flex;
  border-bottom: 3px solid ${yumiOrange};
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 30px;
  padding-bottom: 8px;
`;
const FirstFoodPackSelector = styled(H6)`
  color: ${yumiOrange};

  border-radius: 3px;
`;
const CopyArea = styled.div`
  display: flex;
  flex-direction: column;
`;
const PhotoGallery = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstContainer = styled.div`
  width: 100%;
  height: 865px;
  overflow: hidden;
  align-items: center;
  display: flex;

  @media (max-width: 800px) {
    height: 970px;
  }
`;

const TinyImage = styled.img`
  width: 103px;
  height: 93px;
  object-fit: cover;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 3px gray;
    transition: all 0.1s ease-in;
  }
`;

const TinyRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 40px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 440px;
`;
