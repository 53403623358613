import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BillingHistoryIcon from "../../../../assets/icons/Dashboard-icons/receipt-icon.svg";
import CancelIcon from "../../../../assets/icons/Dashboard-icons/cancel-icon.svg";
import PlanIcon from "../../../../assets/icons/Dashboard-icons/jar-icon.svg";
import { CardTitle, CardSubtitle } from "../components/styledComponents";

const LinkCard = ({ to, title, subtitle, icon, cancelCard }) => (
  <ButtonLink to={to}>
    <div style={{ display: "flex", marginLeft: "32px", marginRight: "32px" }}>
      <img
        style={{ opacity: cancelCard ? "0.5" : "1" }}
        src={icon}
        alt={icon}
        width="15px"
        height="15px"
      />
    </div>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
    </div>
  </ButtonLink>
);

export const PlanCard = () => (
  <LinkCard
    icon={PlanIcon}
    to="/profile/settings/plan"
    title="Plan Size"
    subtitle=""
  />
);
export const BillingCard = () => (
  <LinkCard
    to="/profile/billing"
    title="Billing History"
    icon={BillingHistoryIcon}
  />
);
export const CancelCard = () => (
  <LinkCard
    style={{ color: "rgba(0, 0, 0, 0.5)", maxWidth: "300px" }}
    cancelCard
    to="/profile/settings/cancel"
    title="Cancel Subscription"
    subtitle=""
    icon={CancelIcon}
  />
);

const ButtonLink = styled(Link)`
  border-style: solid;
  border-color: grey;
  background: white;
  margin-left: 7.5px;
  margin-right: 7.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-basis: auto;
  cursor: pointer;
  font-family: "Avenir", sans-serif;
  border: 1px solid #e5e8ed;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 346px;
  @media (max-width: 1000px) {
    max-width: 300px;
  }
  &:hover {
    border-color: blue;
  }
  flex-grow: 1;
  padding-top: 32px;
  padding-bottom: 32px;
  text-decoration: none;
  color: inherit;
  padding-left: 7px;
  padding-right: 7px;
`;
