import React from "react";
import "./styles/styles.css";

const receiptData = {
  121: {
    numMeals: 6,
    weeklyTotal: 35.0,
    perMeal: 5.83,
    planId: 121,
    plan: "bento-five",
    timeline: "weekly",
    tax: 3.33
  },
  141: {
    numMeals: 12,
    weeklyTotal: 65.0,
    perMeal: 5.41,
    planId: 141,
    plan: "bento-ten",
    timeline: "weekly",
    tax: 6.18
  },
  161: {
    numMeals: 24,
    weeklyTotal: 90.0,
    perMeal: 3.75,
    planId: 161,
    plan: "bento-twenty",
    timeline: "weekly",
    tax: 8.55
  }
};

function ReceiptBody({ id, date, planId }) {
  return (
    <div className="receipt_body">
      <div className="receipt_body_row">
        <div className="receipt_body_field">{`Order ID: 10000000${id}`}</div>
        <div className="receipt_body_field">{`Date: ${date}`}</div>
      </div>
      <div className="receipt_body_row">
        <div className="receipt_body_field">{`${
          receiptData[planId].numMeals
        } Jars`}
        </div>
        <div className="receipt_body_field">{`$${
          receiptData[planId].weeklyTotal
        }`}
        </div>
      </div>
      <div className="receipt_body_row">
        <div className="receipt_body_field">Tax</div>
        <div className="receipt_body_field">{`$${
          receiptData[planId].tax
        }`}
        </div>
      </div>
      <div className="receipt_body_row">
        <div className="receipt_body_field">Shipping</div>
        <div className="receipt_body_field">Free</div>
      </div>
      <div className="receipt_body_row total">
        <div className="receipt_body_field">Total</div>
        <div className="receipt_body_field">{`$${receiptData[planId].tax +
          receiptData[planId].weeklyTotal}`}
        </div>
      </div>
    </div>
  );
}

export default ReceiptBody;
