import { updateOrderPause } from "../../usecases/orders";

const pauseOrderService = async id => {
  let pause;
  try {
    pause = await updateOrderPause(id, { paused: true });
    return pause;
  } catch (err) {
    return Error("There was an error pausing your order.", err);
  }
};
export default pauseOrderService;
