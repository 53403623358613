import React from "react";
import { Link } from "react-router-dom";
import "styled-components/macro";
import styled from "styled-components";
import "./styles/styles.css";

const computeTotalItems = state =>
  state.items.reduce((total, item) => total + item.quantity, 0);

function Footer({ updateOrder, orderId, cart, fixed }) {
  const { wasEdited } = cart;
  const canSave = cart.capacity === computeTotalItems(cart);

  return (
    <div className={`orders_footer_container${fixed ? " fixed" : ""}`}>
      <Link className="orders_footer_link" css="flex: 1 0 30%;" to="/orders">
        <Button css="background: #F6F6F6; color:black; width: 100%; opacity: .8;">
          Cancel
        </Button>
      </Link>
      <Button
        disabled={!canSave || !wasEdited ? "disabled" : ""}
        save
        onClick={() => updateOrder(orderId, cart)}
        css="background-color:black !important; color: white;"
      >
        Save
      </Button>
    </div>
  );
}

const Button = styled.button`
  border: solid 1px rgba(0, 0, 0, 0.07);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: white;
  font-family: Platform-Regular;
  font-size: 16px;
  cursor: pointer;
  background: black;
  border-radius: 20px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    border: solid 1px #000000;
    background-color: #000000;
    color: white;
  }
`;

export default Footer;
