import { combineReducers } from "redux";

import authReducer from "./auth";
import screensReducer from "./screens";
import cartReducer from "./cart";
import userReducer from "./user";
import checkoutReducer from "./checkout";

const rootReducer = combineReducers({
  auth: authReducer,
  checkout: checkoutReducer,
  screen: screensReducer,
  cart: cartReducer,
  user: userReducer
});

export default rootReducer;
