import { useEffect } from "react";

export const useSyncProgressWithLocalStorage = progress => {
  useEffect(
    () => {
      localStorage.setItem("giftCheckoutProgress", JSON.stringify(progress));
    },
    [progress]
  );
};

export const getProgressFromLocalStorage = () => {
  try {
    const progress = JSON.parse(localStorage.getItem("giftCheckoutProgress"));
    if (progress === null) throw Error("oy");
    return progress;
  } catch (_) {
    return { stage: 1 };
  }
};
