import React from "react";
import styled from "styled-components";
import spacetime from "spacetime";
import { getUserBillingHistory } from "../../../usecases/users";
import { useRequest } from "../hooks/useFetch";
import { Page } from "../components/styledComponents";

const Billing = ({ billing }) => (
  <BillCard>
    <BillText>Amount: ${billing.amount / 100} </BillText>
    <BillText>
        Date: {spacetime(billing.charge_date).format("MM/DD/YYYY")}{" "}
    </BillText>
    <BillText>
        Card: {billing.card_brand} **** **** **** {billing.card_last_four}
    </BillText>
  </BillCard>
  );
export const BillingHistory = ({ user }) => {
  const [billings, isLoading, isError] = useRequest(() =>
    getUserBillingHistory(user.id)
  );
  if (isLoading) return "Loading...";
  if (isError)
    return "An error occurred. Please refresh the page and try again.";
  return (
    <Page>
      <HeaderText>Billing History</HeaderText>
      <BillContainer>
        {billings.charges.map(billing => (
          <Billing key={JSON.stringify(billing)} billing={billing} />
        ))}
      </BillContainer>
    </Page>
  );
};

const BillCard = styled.div`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  background: white;
  width: 630px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (max-width: 1000px) {
    width: 80%;
  }
`;
const HeaderText = styled.h1`
  font-family: "Platform-medium", sans-serif;
  font-size: 48px;
  line-height: 1;
  margin-top: 100px;
  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;
const BillText = styled.p`
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Avenir", sans-serif;
  font-size: 15px;
`;
const BillContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;
  align-items: center;
  align-self: center;
  margin-bottom: 80px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
