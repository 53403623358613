import styled from "styled-components";

export const Page = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fefefe;
  min-height: 99vh;
  overflow: hidden;
`;
