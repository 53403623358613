import React from "react";
import styled, { css } from "styled-components";
import { Container, yumiOrange, H5 } from "../../../styledcomponents";
import {
  BabySmall,
  Broccoli,
  YellowQuad,
  SilverBend
} from "../../OneOffPurchase/assets";
import { Body, H2 } from "../../../styledcomponents/typefaces";
import { BackgroundWave } from "../assets";
import { LazyImage } from "../../../styledcomponents/LazyImage";

import "styled-components/macro";

const Wave = "https://email.helloyumi.com/assets/_HomePageRefresh/Wave.svg";
const BabyGif =
  "https://email.helloyumi.com/assets/_HomePageRefresh/BabyGif.gif";
const QuadGif =
  "https://email.helloyumi.com/assets/_HomePageRefresh/QuadGif.gif";

const imageColumns = [
  {
    image: BabyGif,
    title: "Made for your babe",
    description:
      "Our nutritionist will recommend the perfect plan to suit your babe's developmental needs."
  },
  {
    image: Broccoli,
    title: "You pick, we cook",
    description:
      " Let us know how many times per day you'd like to serve Yumi. Sit back while we cook."
  },
  {
    image: QuadGif,
    title: "Delivered fresh weekly",
    description: "Starting at $5 per day. You can edit or skip anytime!"
  }
];
export const MealtimeSolved = ({ width }) => (
  <React.Fragment>
    {" "}
    {width > 500 && (
      <LazyImage
        src={Wave}
        css="width:100%; z-index:99;right:0;margin-top:550px;position:absolute; z-index:500;

        @media(min-width:1450px){
          height:1000px;
        }
        @media(max-width:1200px){
        margin-top:500px;
        }
        @media(max-width:1000px){
        margin-top:400px;
        }
        @media(max-width:900px){
          margin-top:300px;
        }"
        alt="Silvy"
      />
    )}
    {width < 500 && (
      <LazyImage
        src={SilverBend}
        css="width:100%; margin-top:-255px;right:0;position:absolute; z-index:500;"
        alt="Silvy"
      />
    )}
    <MealTimeSolvedContainer>
      <H2
        css={css`
          color: ${yumiOrange};
          padding-bottom: 45px;
          margin-top: -20px;

          @media (max-width: 800px) {
            font-size: 30pt;
          }
        `}
      >
        Smarter Baby Food
      </H2>
      <HowItWorksRow>
        {imageColumns.map(column => (
          <HowColumn>
            <LazyImage
              src={column.image}
              alt="col"
              css="padding-bottom:20px; max-height:320px"
            />
            <H5
              css={css`
                color: ${yumiOrange};
                padding-bottom: 20px;
              `}
            >
              {column.title}
            </H5>

            <Body css="font-size:14pt; width:85%; line-height:1.5em; @media(max-width:500px){width:85%;}">
              {column.description}
            </Body>
          </HowColumn>
        ))}
      </HowItWorksRow>
    </MealTimeSolvedContainer>
  </React.Fragment>
);

const HowColumn = styled.div`
  display: flex;
  flex-direction: Column;
  width: 230px;
  align-items: center;

  text-align: center;
  width: 100%;

  @media (max-width: 800px) {
    margin-bottom: 40px;
  }
`;
const HowItWorksRow = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 350px 350px 350px;

  @media (max-width: 800px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

const MealTimeSolvedContainer = styled.div`
  @media (min-height: 1450px) {
    height: 1500px;
  }

  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 501;
  height: 1170px;
  padding-top: 100px;
  background-image: url(${BackgroundWave});

  @media (max-width: 900px) {
    height: 900px;

    margin-top: 80px;
    background: #faf8f6;
    padding-top: 0px;
  }
  @media (max-width: 800px) {
    height: 1500px;

    margin-top: 80px;
    background: #faf8f6;
    padding-top: 0px;
  }
`;
