/*
 * Date utility functions
 */
const spacetime = require("spacetime");
/*
 * 2: Represents Wednesdays
 */
const DELIVERY_DAY_OF_WEEK = 2;

/**
 * Gets upcoming delivery date based on DELIVERY_DAY_OF_WEEK.
 *
 * @return {Date} next delivery date
 */
export const getNextDeliveryDate = () => {
  let now = new Date(spacetime.now("America/Los_Angeles").format("iso-utc"));
  now = new Date(now.setHours(0, 0, 0, 0));
  let nextDelivery = now;
  nextDelivery.setDate(
    now.getDate() + ((14 + DELIVERY_DAY_OF_WEEK - now.getDay()) % 7) + 7
  );

  if (formatDate(nextDelivery) === "2019-12-24") {
    nextDelivery = new Date(
      spacetime("2019-12-23 09:00:00", "America/Los_Angeles").format("iso")
    );
  } else if (formatDate(nextDelivery) === "2019-12-31") {
    nextDelivery = new Date(
      spacetime("2019-12-30 09:00:00", "America/Los_Angeles").format("iso")
    );
  }

  return nextDelivery;
};

/**
 * Gets start and end date for current delivery cycle.
 *
 * @return {Date} start - Start date
 * @return {Date} end   - End date
 */
export const getCurrentDeliveryCycle = () => {
  const startDate = new Date(getNextDeliveryDate());

  const start = startDate.setDate(startDate.getDate());
  const end = startDate.setDate(startDate.getDate() + 6);
  return { start, end };
};

/**
 * Gets month and day from date.
 *
 * @param  {date}   date  - Date object
 * @return {string} Month + day
 */
export const getMonthAndDay = date => {
  date = new Date(date);
  const day = date.getDate().toString();
  const month = date.toLocaleString("en-us", { month: "long" });
  return `${month} ${day}`;
};

/**
 * Returns baby's age in months based off his/her birthday and next delivery date.
 *
 * @param {string} birthday - Baby's birthday in mm/dd/yyyy format
 *
 * @return {number} age in months
 */
export const getBabyAge = birthday => {
  let months;
  birthday = new Date(birthday);
  const nextDelivery = getNextDeliveryDate();

  months = (nextDelivery.getFullYear() - birthday.getFullYear()) * 12;
  months -= birthday.getMonth() + 1;
  months += nextDelivery.getMonth() + 1;

  return months <= 0 ? 0 : months;
};

/**
 * Formats date in yyyy-mm-dd format.
 *
 * @param {string} date - Date as mm/dd/yyyy.
 *
 */
export const formatDate = date => {
  date = new Date(date);
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  let day = date.getDate();

  month = month < 9 ? `0${month}` : `${month}`;
  day = day < 9 ? `0${day}` : `${day}`;

  return `${year}-${month}-${day}`;
};

export const formatDateStandard = (date, withYear = true) => {
  date = new Date(date);
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  let day = date.getDate();

  month = month < 9 ? `0${month}` : `${month}`;
  day = day < 9 ? `0${day}` : `${day}`;

  if (withYear) return `${month}/${day}/${year}`;

  return `${month}/${day}`;
};

export const formatDatePretty = dateObj => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  let date = new Date(dateObj);
  date = new Date(date.setDate(date.getDate() + 1));
  const dayOfWeek = date.getUTCDay();
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();

  return `${weekday[dayOfWeek]}, ${monthNames[monthIndex]}. ${day}`;
};

export const getDayDifference = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  return Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)
  );
};

/**
 * @function formatDateWithDayOfWeek - Returns date with day of week.
 *                                     Example: Tuesday, Feb. 24
 * @param   {Date}    dateInfo
 * @returns {String}
 */
export const formatDateWithDayOfWeek = dateInfo => {
  let date;

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  date = new Date(dateInfo);
  date = new Date(date.setDate(date.getDate() + 1));

  // Extract day of week, day, and month
  const dayOfWeek = days[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];

  // Return values
  return `${dayOfWeek}, ${month}. ${day}`;
};

export const ordinal_suffix_of = i => {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};
