import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { EduardoStack, InnerGridTemplate } from "../assets";
import { yumiGreen, H3 } from "../../../styledcomponents";

import { H1, Body } from "../../../styledcomponents/typefaces";

export const InsideTheBox = () => (
  <SalareContainer>
    <InnerGridTemplate
      gridRows="1000px"
      gridMobileRows="1fr"
      gridDimensions="0.3fr 1fr 1.2fr 0.3fr"
      leftStyling={css`
        display: flex;
        justify-content: Center;
      `}
      leftChildren={(
        <React.Fragment>
          <img
            src={EduardoStack}
            alt="salare"
            css="width:555px;max-height:700px; object-fit:cover;  @media (max-width: 500px) {
                display:none;
              }"
          />
        </React.Fragment>
)}
      rightStyling={css`
        height: 100%;
        padding-left: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 1000px) {
          padding-left: 30px;
          padding-top: 25px;
        }
      `}
      rightChildren={(
        <React.Fragment>
          <H3
            css={css`
              font-size: 40px;
              width: 92%;
              padding-bottom: 50px;
              @media (max-width: 1000px) {
                font-size: 24px;
                padding-top: 10px;
                padding-bottom: 35px;
              }
            `}
          >
            Inside the Salare Chef Box
          </H3>
          <MealBox css="padding-bottom:30px;">
            <StageHeader>Stage 3 </StageHeader>
            <TitleHeader>Carrot Ginger Porridge</TitleHeader>
            <IngredientBody>
              Ingredients: Organic carrot, apple, sorghum, onion, ginger
            </IngredientBody>
            <IngredientBorder />

            <DescriptionBody>
              Our Carrot Ginger Porridge is a unique and nutrient dense take on
              a fall favorite. It features sorghum berries, an ancient grain,
              rich in protein, fiber, niacin and thiamin. These two B-vitamins
              aid in nutrient absorption and truly make the blend a super food.
            </DescriptionBody>
          </MealBox>
          <MealBox
            css="@media (max-width: 1000px) {
          padding-bottom: 30px;
        }"
          >
            <StageHeader>Stage 4 </StageHeader>
            <TitleHeader>Chickpea Panisse</TitleHeader>
            <IngredientBody>
              Ingredients: Organic chickpea flour, sumac, lemon oil
            </IngredientBody>
            <IngredientBorder />
            <DescriptionBody>
              Our Chickpea Pannise is full of Mediterranean flavors and is the
              perfect bite to help perfect that pincer grasp. Chickpeas (and
              yes, they are also known as garbanzo beans) are an excellent
              source of protein and fiber.
            </DescriptionBody>
          </MealBox>
        </React.Fragment>
)}
    />
  </SalareContainer>
);

const IngredientBorder = styled.div`
  width: 20%;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
`;

const TitleHeader = styled(H3)`
  font-size: 30px;
  width: 92 %;
  padding-bottom: 15px;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;
const DescriptionBody = styled(Body)`
  width: 87%;
  padding-bottom: 30px;
  width: 75%;
  line-height: 1.5em;
  font-size: 14pt;
  @media (max-width: 1300px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 92%;
    font-size: 14px;
    padding-bottom: 15px;
    line-height: 1.4em;
  }
`;
const IngredientBody = styled(Body)`
  padding-bottom: 20px;
  font-size: 14pt;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 1.4em;
    padding-bottom: 10px;
  }
`;
const StageHeader = styled(H3)`
  font-size: 18px;
  width: 92%;
  padding-bottom: 15px;

  @media (max-width: 1000px) {
    font-size: 13.86px;
    padding-bottom: 8px;
  }
`;
const MealBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SalareContainer = styled.div`
  width: 100%;
  background: #b5c7aa;
`;
