import { useEffect, useState } from "react";

export const useRequest = (req, memo) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  let isMounted;
  const fetchUrl = async () => {
    if (isMounted) {
      const response = await req().catch(() => setError(true));
      if (isMounted) {
        setData(response);
        setLoading(false);
      }
    }
  };

  useEffect(
    () => {
      isMounted = true;

      fetchUrl();

      return () => {
        isMounted = false;
      };
    },
    [memo]
  );

  return [data, loading, error];
};
