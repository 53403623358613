import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { blackberry, blueberry } from "../../styledcomponents/colors";
import { H1, H3, Body } from "../../styledcomponents/typefaces";
import { H6, BlueButton } from "../../styledcomponents";
import { useWindowSize } from "../../hooks";
import {
  ByChloeHero,
  ByChloeBabe,
  BurgerMat,
  FeedingBaby,
  ByChloeBannerDesktop,
  ByChloeBannerMobile
} from "./assets";
import { FutureCollabs, TalkOfTheTown, HowTo, ByChloeBlends } from "./sections";
import "styled-components/macro";

export const ByChloe = () => {
  const { width } = useWindowSize();
  return (
    <Sections>
      <ByChloeBanner width={width} />
      <div
        style={{
          width: "100%",
          paddingTop: "10px",
          maxWidth: "1200px"
        }}
      >
        <img
          src={ByChloeHero}
          alt="big img"
          style={{
            width: "100%",
            maxWidth: "2000px"
          }}
          css={css`
            margin: 0 auto;
          `}
        />
        <div
          style={{ position: "relative", bottom: "200px", left: "35px" }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt;
              width: 62%;
              display: none;
            }
          `}
        >
          <H6
            style={{
              fontSize: "20pt",
              color: "white",
              paddingLeft: "20px",
              paddingTop: "30px"
            }}
          >
            Introducing Our Latest Chef Collab:
          </H6>
          <H1
            style={{
              color: "white",
              paddingLeft: "20px",
              fontSize: "57pt",
              paddingTop: "10px"
            }}
          >
            by CHLOE.
          </H1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "64%"
        }}
        css={css`
          @media (max-width: 1000px) {
            width: 100% !important;
            align-items: flex-start !important;
          }
        `}
      >
        <H1
          style={{
            color: blackberry,
            width: "70%"
          }}
          css={css`
            @media (max-width: 1000px) {
              width: 100% !important;
              text-align: right !important;
              display: none;
              padding-top: 50px !important;
            }
          `}
        >
          Why we make the perfect pear
        </H1>
        <H6
          style={{
            fontSize: "11pt",
            color: blackberry,
            display: width > 1000 ? "none" : "flex",
            paddingLeft: "20px",
            paddingTop: "30px"
          }}
        >
          Introducing Our Latest Chef Collab:
        </H6>
        <H1
          style={{
            color: blackberry,
            display: width > 1000 ? "none" : "flex",
            paddingLeft: "20px"
          }}
        >
          by CHLOE.
        </H1>
        <Body
          style={{
            paddingTop: "30px",
            width: "80%"
          }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 14pt !important;
              width: 94% !important;
              padding-left: 20px;
            }
          `}
        >
          {`By CHLOE. started a food revolution by turning fast-casual favorites into nutritious, tasty plant-based alternatives. Since opening in 2015, they've reached cult-like status in New York, Los Angeles, Boston and even across the pond in London and Canada (coming soon!) We've partnered with these plant-based trailblazers to create a baby burger bite and blend that's sneaky delicious. The mastermind behind the collab is by CHLOE. founder Samantha Wasser, who is also the mother to James and two furbabies, Jett and Bowie Wasser (pictured above).`}
        </Body>
        <img
          src={FeedingBaby}
          alt="baby-mobile"
          style={{
            display: width > 1000 ? "none" : "flex",
            order: 2,
            height: "220px",
            objectFit: "cover",
            width: width < 400 ? "100%" : "500px",
            alignSelf: "center",
            marginTop: "70px"
          }}
        />
        <Body
          style={{
            width: "80%",
            paddingLeft: "40px",
            paddingTop: "none",
            paddingRight: "20px",
            marginTop: "35px",
            borderLeft: `12px solid ${blackberry}`,
            fontStyle: "italic",
            order: 2
          }}
          css={css`
            @media (max-width: 1000px) {
              font-size: 12pt !important;
              width: 100% !important;
              order: ${3};
            }
          `}
        >
          {`
        “Being a new mama, Yumi fan and customer, this collaboration is so special to me and quite possibly my favorite partnership to date! Yumi offers already-made, nutrient rich dishes that are a huge lifesaver for busy working parents like myself. I am so proud of the delicious bites we created with the Yumi team perfect for the Very Important Babes in our lives. The Yumi baby bites are inspired by our best-seller, The Guac Burger, and is made with fresh, whole ingredients and a lotta love for your babe to enjoy!”`}
          <br /> {`- Samantha Wassar`}
        </Body>
        <Link to="/checkout" style={{ textDecoration: "none", color: "white" }}>
          <ChefButton
            style={{ background: blackberry, order: 5, marginTop: "50px" }}
            css={css`
              @media (max-width: 1000px) {
                order: ${1} !important;
                margin-left: 20px;
                background: ${blueberry} !important;
                margin-top: 25px !important;
                width: 200px !important;
                padding: 12px !important;
                font-size: 9pt !important;
              }
            `}
          >
            GET THE BOX
          </ChefButton>{" "}
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          height: "550px",
          paddingTop: "150px"
        }}
        css={css`
          @media (max-width: 1000px) {
            flex-direction: column;
            width: 100% !important;
            padding-top: 50px !important;
          }
        `}
      >
        <img
          src={ByChloeBabe}
          alt="cutting bord"
          style={{
            width: "700px",
            height: "400px",
            paddingLeft: "50px",
            position: "relative"
          }}
          css={css`
            @media (max-width: 1000px) {
              padding-top: 40px !important;
              width: 400px !important;
              align-self: center;
              height: 300px !important;
              padding-left: 0px !important;
            }
            @media (max-width: 400px) {
              width: 100% !important;
            }
          `}
        />
        <div
          style={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignSelf: "flex-end",
            paddingRight: "100px"
          }}
          css={css`
            @media (max-width: 1000px) {
              width: 100% !important;
              padding-left: 30px !important;
            }
          `}
        >
          <H1
            style={{ color: blackberry }}
            css={css`
              @media (max-width: 1000px) {
                font-size: 18pt;
                padding-top: 20px !important;
                width: 100% !important;
              }
            `}
          >
            The by CHLOE. Chef Box
          </H1>
          <Body
            style={{
              fontSize: "17pt"
            }}
            css={css`
              @media (max-width: 900px) {
                font-size: 14pt !important;
                width: 300px !important;
                padding-top: 12px;
              }
            `}
          >
            Your VIB (Very Important Babe) is in for a treat. These delicious
            recipes, custom designed by Samantha and the by CHLOE. team, are
            brimming with plant-based goodness. Packed with protein-rich black
            beans, creamy sweet potatoes for a boost of potassium and vitamin C,
            and quinoa for texture and fiber, your babe will enjoy every last
            bite.
          </Body>
        </div>
      </div>

      <ByChloeBlends />
      <VIBImage src={BurgerMat} alt="VIB" />
      <SurpriseGiftBox>
        <H3
          style={{
            padding: "10px",
            fontFamily: "SignPainter",
            fontSize: "14pt"
          }}
        >
          {" "}
          (PSST... SURPRISE GIFT WITH EVERY BOX)
        </H3>
      </SurpriseGiftBox>
      <HowTo />
      <TalkOfTheTown />
      <FutureCollabs />
    </Sections>
  );
};

const ByChloeBanner = ({ width }) => (
  <BannerContainer>
    {width > 1000 ? (
      <img src={ByChloeBannerDesktop} alt="banner" style={{ width: "94%" }} />
    ) : (
      <img src={ByChloeBannerMobile} alt="banner" style={{ width: "350px" }} />
    )}
  </BannerContainer>
);

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 96%;
  height: 360px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;

  @media (max-width: 1000px) {
    height: 170px;
    width: 90%;
  }
`;
const SurpriseGiftBox = styled.div`
  border: 2px solid black;
  display: none;
  width: 90%;
  margin-top: 70px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;
const VIBImage = styled.img`
  width: 90%;
  object-fit: cover;
  margin-top: 120px;
  height: 320px;

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

const SurpriseImage = styled.img`
  width: 20%;
  outline: 1px solid red;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Sections = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: hidden;
`;

const ChefButton = styled(BlueButton)`
  width: 150px;
  background: ${blackberry};
  color: white;
  padding: 15px;
  font-size: 11pt;
  margin-top: 25px;
`;
