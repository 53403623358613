import React from "react";
import injectSheet from "react-jss";

export const styles = {
  root: {
    borderBottomStyle: "solid",
    borderBottomColor: "black",
    borderBottomWidth: props => props.borderWidth,
    textAlign: "left"
  },
  headline: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    fontSize: props => props.headlineSize,
    fontWeight: 900,
    lineHeight: 1,
    marginTop: "4px",
    borderBottom: "1px solid black",
    "& > span:first-of-type": {
      marginRight: 8
    }
  },
  servingSummary: {
    fontSize: props => props.servingSummarySize,
    margin: "4px 0"
  }
};

const Header = ({ classes, servingSize, serving_size, name }) => (
  <div className={classes.root}>
    <div className={classes.headline}>
      <span>Nutrition</span>
      <span>Facts</span>
    </div>
    <div className={classes.servingSummary}>
      {typeof servingSize !== "undefined" && (
        <div>{`Serving Size ${servingSize}`}</div>
      )}
      {/* {typeof serving_size !== "undefined" && (
        <div>{`Serving Size ${serving_size}g`}</div>
      )} */}
    </div>
  </div>
);

Header.defaultProps = {
  headlineSize: 28,
  servingSummarySize: 12,
  borderWidth: 8
};

export default injectSheet(styles)(Header);
