import React from "react";
import useReload from "../../hooks/useReload";

const Menu = () => {
  useReload()
  return (
    <div>

    </div>
  );
};

export default Menu;
