import React, { useState, useContext } from "react";
import _ from "lodash";
import spacetime from "spacetime";
import styled from "styled-components";
import "styled-components/macro";
import { Body1, H2, blackberry } from "../../../styledcomponents";
import { Form, TextInput, BlueButton } from "../../../styledcomponents/forms";
import { DateInput } from "../../../styledcomponents/containers/DateInput";
import { GiftCheckoutContext } from "./GiftCheckoutContext";
import { getReferralMenu, getZone, isValidReferral } from "../../../usecases";
import { useMergeState } from "../../../hooks";

const PricingFast =
  "https://email.helloyumi.com/assets/_Pricing/Pricing_Fast.svg";

const GiftAFriendBlend =
  "https://email.helloyumi.com/assets/_GiftAFriend/GiftAFriend_Hero.png";

export const StepOne = () => {
  const { progress, dispatch, history } = useContext(GiftCheckoutContext);

  const [form, setForm] = useState({
    zipcode: "",
    birthday: "Child's birthday"
  });

  const [errors, setErrors] = useMergeState({
    birthday: false,
    zipcode: false,
    referral: false
  });

  const formattedBirthday = spacetime(form.birthday).format("iso-short");

  const getMenuAndZoneAndValidRef = () =>
    Promise.all([
      getReferralMenu(formattedBirthday).catch(
        () => void setErrors({ birthday: true })
      ),
      getZone(form.zipcode).catch(() => void setErrors({ zipcode: true })),
      isValidReferral(progress.referral_code)
    ]);

  return (
    <React.Fragment>
      <React.Fragment>
        {!errors.zipcode && (
          <React.Fragment>
            <H2
              style={{
                paddingTop: "50px",
                color: blackberry,
                fontSize: "50px"
              }}
            >
              Your Free Box
            </H2>
            <Body1
              style={{ fontSize: "20px", margin: "2vh", textAlign: "center" }}
            >
              Let's build the perfect box for your baby's age.{" "}
            </Body1>
            <img
              src={GiftAFriendBlend}
              alt="blend"
              style={{ maxWidth: "340px", margin: "2vh" }}
            />{" "}
          </React.Fragment>
        )}
        {!errors.zipcode && (
          <GiftForm
            onSubmit={async () => {
              setErrors({ birthday: false, zipcode: false });
              const [
                milestoneMenu,
                zone,
                isValid
              ] = await getMenuAndZoneAndValidRef();
              if (_.isEmpty(milestoneMenu))
                return setErrors({ birthday: true });
              if (_.isEmpty(zone)) return setErrors({ zipcode: true });
              if (!isValid.referral_code_valid) {
                return setErrors({ referral: true });
              }
              dispatch({
                type: "COMPLETE_STEP_ONE",
                zone,
                milestoneMenu,
                birthday: formattedBirthday,
                zipcode: form.zipcode
              });
              history.push("/gift-checkout/2");
            }}
          >
            <GiftTextInput
              value={form.zipcode}
              placeholder="Zipcode"
              label="Your zipcode:"
              onChange={e => setForm({ ...form, zipcode: e.target.value })}
            />
            <DateInput
              value={form.birthday}
              onChange={e => setForm({ ...form, birthday: e.target.value })}
              style={{ border: "none" }}
              label="Your child's birthday:"
            />
            <Body1 style={{ marginTop: "2vh", textAlign: "center" }}>
              By clicking above, you agree to our Terms of Use and consent to
              our Privacy Policy.{" "}
            </Body1>
            <BlueButton
              style={{
                background: blackberry,
                color: "white",
                height: "45px",
                fontSize: "18px",
                width: "100%",
                marginTop: "2.5vh"
              }}
              type="submit"
            >
              PROCEED TO CHECKOUT
            </BlueButton>
          </GiftForm>
        )}
      </React.Fragment>

      {errors.zipcode && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={PricingFast} style={{ width: "130px" }} alt="quick" />
          <H2 style={{ color: blackberry }}>Coming Soon</H2>
          <Body1 style={{ fontSize: "20px" }}>
            Sorry, we're currently not shipping to your location. We hope to
            deliver to you soon.
          </Body1>
          <BlueButton
            style={{ color: blackberry, marginTop: "20px" }}
            onClick={() => setErrors({ zipcode: false })}
          >
            INPUT DIFFERENT ZIPCODE
          </BlueButton>
        </div>
      )}
      {errors.birthday && (
        <ErrorText>
          It looks like there's something wrong with the birthday you entered.
        </ErrorText>
      )}
      {errors.referral && (
        <ErrorText>It looks like you have an invalid referral link.</ErrorText>
      )}
    </React.Fragment>
  );
};

const GiftForm = styled(Form)`
  background: white;
  padding: 15px;
  max-width: 400px;
`;

const ErrorText = styled.span`
  color: red;
`;
const GiftTextInput = styled(TextInput)``;
