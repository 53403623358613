import React, { useState } from "react";
import { Redirect } from "react-router-dom";

export const useRedirect = path => {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  return [
    () => shouldNavigate && (() => <Redirect to={path} />)(),
    setShouldNavigate
  ];
};

export default useRedirect;
