import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  BlueButton,
  texasBrown,
  HyperLink,
} from "../../../styledcomponents";
import "styled-components/macro";
import { Cactus } from "../assets";
import { H2 } from "../../../styledcomponents/typefaces";

export const Mashable =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/Mashable.svg";

export const NYT =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/nyt.svg";

export const goop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/goop.svg";

export const TalkOfTheTown = ({ width }) => (
  <TalkContainer>
    <TalkBox>
      <LeftSide>
        <H2
          css={css`
            color: ${texasOrange};

            @media (min-width: 500px) {
              font-size: 35pt;
            }
          `}
        >
          Talk of the Town
        </H2>
        <Links>
          <HyperLink href="https://mashable.com/article/yumi-baby-food-subscription/">
            <img
              src={Mashable}
              alt="mash"
              css="width:140px; @media(max-width:500px){ padding-top:25px;}"
            />
          </HyperLink>
          <HyperLink href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html">
            <img
              src={NYT}
              alt="nyt"
              css="width:220px;padding-left:40px;@media(max-width:500px){width: 130px;padding-left:0px;padding-top:25px;}"
            />
          </HyperLink>
          <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
            <img
              src={goop}
              alt="goop"
              css="width: 110px;padding-left:40px;@media(max-width:500px){width: 50px;padding-left:0px;padding-top:25px;}"
            />
          </HyperLink>
        </Links>

        {width > 500 && (
          <Link css="text-decoration:none" to="/checkout">
            <HoustonButton value="Get Started" />
          </Link>
        )}
      </LeftSide>
      <RightSide>
        <img
          src={Cactus}
          css="width:200px; margin-left:60px; @media(max-width:500px){width:100px; margin-left:0px; margin-top:40px}"
          alt="cact"
        />
      </RightSide>
    </TalkBox>
  </TalkContainer>
);

const LeftSide = styled.div`
  justify-self: center;
`;

const RightSide = styled.div``;

const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  font-size: 14pt;
  padding: 20px;
  width: 300px;
  margin-top: 100px;
  box-shadow: 0px 2px 4px gray;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) {
    flex-direction: row;
    padding-top: 60px;
  }
`;

const TalkBox = styled.div`
  @media (max-width: 500px) {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  width: 100%;
  @media (min-width: 500px) {
    align-items: center;
    justify-content: center;
    padding: 100px;
  }
`;
const TalkContainer = styled.div`
  padding: 30px;
  width: 100%;
  background: white;
  z-index: 80;
`;
