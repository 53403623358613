import { updateOrderPause } from "../../usecases/orders";

const unpauseOrderService = async id => {
  let unpause;
  try {
    unpause = await updateOrderPause(id, { paused: false });
    return unpause;
  } catch (err) {
    return Error("There was an error unpausing your order.", err);
  }
};

export default unpauseOrderService;
