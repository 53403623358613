import React, { useRef } from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import { blackberry } from "./colors";
import { BlueButton } from "./forms";
import "styled-components/macro";

const Arrow = "https://email.helloyumi.com/assets/_Reviews/back-arrow.png";

export const Swiper = ({
  render,
  onScroll = () => _.noop,
  scrollLeft,
  maxScroll,
  windowWidth,
  color = blackberry
}) => {
  const scroller = useRef(null);
  const onLeftClick = () => {
    scroller.current.scroll({
      left: scroller.current.scrollLeft - 2000,
      behavior: "smooth"
    });
  };

  const onRightClick = () => {
    scroller.current.scroll({
      left: scroller.current.scrollLeft + 2000,
      behavior: "smooth"
    });
  };
  return (
    <CarouselFrame>
      <Carousel>
        {windowWidth > 1000 &&
          scrollLeft > 700 && (
            <CarouselButton color={color} onClick={() => onLeftClick()}>
              <img src={Arrow} alt="left" />
            </CarouselButton>
          )}
        <Scroll ref={scroller} onScroll={onScroll}>
          {render()}
        </Scroll>
        {windowWidth > 1000 &&
          scrollLeft < maxScroll - 2000 && (
            <CarouselButton color={color} onClick={() => onRightClick()}>
              <img
                src={Arrow}
                alt="right"
                css={css`
                  transform: rotate(180deg);
                `}
              />
            </CarouselButton>
          )}
      </Carousel>
    </CarouselFrame>
  );
};

const CarouselButton = styled(BlueButton)`
  border-radius: 40px;
  position: relative;
  width: 1px;
  display: flex;
  opacity: 0.5;
  height: 71px;
  outline: none;
  background: ${({ color }) => color || blackberry};
`;
const CarouselFrame = styled.div`
  width: 100%;
  height: 100%;
  min-width: 99vw;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Carousel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Scroll = styled.ul`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 500px;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  box-sizing: content-box;
  margin-block-start: 0px;
  padding-inline-start: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1000px) {
    height: 440px;
  }
`;

export const ScrollItemOuter = styled.li`
  width: 100%;
  height: 100%;
  box-sizing: content-box;
`;

export const ScrollItem = styled.div`
  height: 100%;
  padding-right: 25px;
  text-align: left;

  @media (max-width: 480px) {
    padding-right: 0;
    width: 80vw;
  }
`;
