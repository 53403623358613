import React from "react";
import "./styles/styles.css";

const Input = ({ text, type = "text", onChange, id = "generic" }) => (
  <div className="input_container">
    <input id={id} onChange={onChange} type={type} name={type} />
    <div className="input_header">{text}</div>
  </div>
);

export default Input;
