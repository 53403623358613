import React from "react";
import styled from "styled-components";
import { TotBox } from "../HomePage/sections";
import { Testimonials, PricingOptions, CommonQuestions } from "./sections";
import { oat } from "../../styledcomponents/colors";
import { setCouponFromUrl } from "../../utils/coupon";
import useReload from "../../hooks/useReload";

export const Pricing = () => {
  useReload()
  setCouponFromUrl(window.location);
  return (
    <Sections>
      <PricingOptions />
      <Testimonials />
      <BottomSection>
        <CommonQuestions />

        <div style={{ marginTop: "40px", width: "97%" }}>
          <TotBox />
        </div>
      </BottomSection>
    </Sections>
  );
};

const BottomSection = styled.div`
  background: ${oat};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8vh;
  margin-bottom: 0;
`;

export const Sections = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background: #fefefe;
  min-height: 100vh;
  overflow: hidden;
`;
