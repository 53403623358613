import React from "react";
import ReactSwipe from "react-swipe";
import styled from "styled-components";
import cubano from "../images/cubano_patty.png";
import carrot from "../images/carrot_patty.png";
import lentil from "../images/lentil_meatball_patty.png";
import puff from "../images/puffs.png";

const Carousel = () => {
  let swipeEl;

  return (
    <CarouselContainer>
      <ArrowButton onClick={() => swipeEl.prev()} type="button">
        {"<"}
      </ArrowButton>
      <ReactSwipe
        swipeOptions={{ continuous: true }}
        ref={el => {
          swipeEl = el;
        }}
      >
        <Child>
          <SwipeItem>
            <SnackImageContainer>
              <SnackImage src={cubano} alt="cubano patty" />
            </SnackImageContainer>
            <ImageInfo>
              <ImageDescription> CUBANO BITE</ImageDescription>
              <InfoLine>Size: 1 Jar of 5 Bites</InfoLine>
              <InfoLine>
                Ingredients: Organic Bananas, Organic Spinach, Organic Black
                Beans, Organic Garlic, Organic Green Banana Flour, Organic Sweet
                Potatoes, Organic Olive Oil, Organic Sunflower Oil{" "}
              </InfoLine>
              <InfoLine>Stage 4: 10 months+</InfoLine>
            </ImageInfo>
          </SwipeItem>
        </Child>

        <Child>
          <SwipeItem>
            <SnackImageContainer>
              <SnackImage src={carrot} alt="carrot patty" />
            </SnackImageContainer>
            <ImageInfo>
              <ImageDescription>CARROT SPINACH MILLET BITE</ImageDescription>
              <InfoLine>Size: 1 Jar of 5 Bites</InfoLine>
              <InfoLine>
                Ingredients: Organic Millet, Organic Shallot, Organic Spinach,
                Organic Cilantro, Organic Garlic, Organic Oats, Organic Curry
                Powder, Organic Ground Flax, Organic Carrots, Organic Olive Oil,
                Organic Sunflower Oil
              </InfoLine>
              <InfoLine>Stage 4: 10 months+</InfoLine>
            </ImageInfo>
          </SwipeItem>
        </Child>

        <Child>
          <SwipeItem>
            <SnackImageContainer>
              <SnackImage src={lentil} alt="lentil patty" />
            </SnackImageContainer>
            <ImageInfo>
              {" "}
              <ImageDescription>PIZZA BITE</ImageDescription>{" "}
              <InfoLine>Size: 1 Jar of 5 Bites </InfoLine>
              <InfoLine>
                {" "}
                Ingredients: Organic Mushrooms (WHITE), Organic Onions, Organic
                Lentils, Organic Thyme, Organic Gluten-Free Oats, Organic
                Garlic, Organic Tomato Paste, Organic Tomato Powder, Organic
                Olive Oil, Organic Ground Flax, Organic Sunflower Oil
              </InfoLine>
              <InfoLine>Stage 4: 10 months+</InfoLine>
            </ImageInfo>
          </SwipeItem>
        </Child>

        <Child>
          <SwipeItem>
            <SnackImageContainer>
              <SnackImage src={puff} alt="jar of puffs" />
            </SnackImageContainer>
            <ImageInfo>
              <ImageDescription>BEET PUFFS</ImageDescription>
              <InfoLine>Size: 1 Jar / 18 grams</InfoLine>
              <InfoLine>
                Ingredients: Organic Sorghum Flour, organic Coconut oil, organic
                beet powder
              </InfoLine>
              <InfoLine>Stage 4: 10 months+ </InfoLine>
            </ImageInfo>
          </SwipeItem>
        </Child>
      </ReactSwipe>
      <ArrowButton onClick={() => swipeEl.next()} type="button">
        {">"}
      </ArrowButton>
    </CarouselContainer>
  );
};

const InfoLine = styled.div`
  font-family: "Avenir", sans-serif;
  font-size: 15.8px;
  font-weight: 400;
  opacity: 0.6;
  line-height: 1.5;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;
const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;

  @media (max-width: 1000px) {
    text-align: center;
    width: 100%;
    align-items: center;
  }
`;
const ImageDescription = styled.span`
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 22.29px;
  @media (max-width: 1000px) {
    font-size: 13.2px;
    text-align: center;
  }
  margin-bottom: 16px;
`;
const SnackImage = styled.img`
  max-width: 200px;
  margin-bottom: 10px;
`;
const SnackImageContainer = styled.div`
  width: 40%;
`;
const Child = styled.div`
  display: inherit;
  height: 500px;
  @media (max-width: 1000px) {
    height: 700px !important;
  }
`;
const SwipeItem = styled.div`
  font-weight: 700;
  height: 500px;
  font-family: "Avenir", sans-serif;
  color: black;
  font-size: 20px;
  text-align: center;
  margin: 10px;
  padding: 80px 0 20px 0px;
  box-shadow: 0 1px #ebebeb;
  background: #cdc3fc;
  border-radius: 5px;
  border: 1px solid;
  border-color: #e5e5e5 #d3d3d3 #b9c1c6;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: 700px !important;
  }
`;

const ArrowButton = styled.button`
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const CarouselContainer = styled.section`
  width: 900px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 24px;

  height: 500px;
  @media (max-width: 1000px) {
    width: 100%;
    height: 700px !important;
  }
`;
export default Carousel;
