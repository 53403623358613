import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Image } from "@team-yumi/ui";
import "styled-components/macro";
import { Box, FullHR } from "../../styledcomponents";
import { Label, H3 } from "../../styledcomponents/typefaces";
import { useDisableScroll, useWindowSize } from "../../hooks";

const Logo = `https://email.helloyumi.com/assets/Yumi_logo.svg`;
const BoxImage = `https://email.helloyumi.com/assets/_homepage/box.svg`;
const Hamburger = `https://email.helloyumi.com/assets/Hamburger.svg`;

const SideBarContainer = styled.nav`
  position: fixed;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition-duration: 0.5s;
  align-items: center;
  background: white;
  z-index: 2;
  ${(props) =>
    props.isActive
      ? css`
          left: 0px;
          right: auto;
          top: 0px;
          bottom: auto;
        `
      : css`
          left: -150vw;
          right: auto;
          top: 0;
          bottom: auto;
        `};

  @media (min-width: 900px) {
    display: none;
  }
`;

const SideBarRow = ({ to = "/", children, ...props }) => (
  <NavLink
    to={to}
    css="text-decoration: none;  width: 100%; text-align: left;"
    {...props}
  >
    <H3 css="width: 100%; background: white; color: #324599; padding: .5em 0; padding-left: 24px;">
      {children}
    </H3>
  </NavLink>
);

const SideBarRowOther = ({ to = "/", children, ...props }) => (
  <a
    href={to}
    css="text-decoration: none;  width: 100%; text-align: left;"
    {...props}
  >
    <H3 css="width: 100%; background: white; color: #324599; padding: .5em 0; padding-left: 24px;">
      {children}
    </H3>
  </a>
);

const SideBar = ({ isActive = false, close }) => (
  <SideBarContainer isActive={isActive}>
    <Box
      full
      row
      css="align-items: center; padding: 1em; justify-content: space-between;"
    >
      <button
        type="button"
        css="@media(min-width: 901px){display:none;} cursor: pointer;"
        onClick={close}
      >
        <img src={Hamburger} alt="burger" />
      </button>
      <button type="button" css="cursor: pointer; " onClick={close}>
        <img src={Logo} alt="YUMI" height="15px" />
      </button>
    </Box>
    <Box
      full
      css="background: white;
      z-index: 100;
      height: 100%;
      align-items:center;
      "
    >
      <FullHR />
      <SideBarRow to="/login">LOGIN</SideBarRow>
      <SideBarRowOther to="/reviews">REVIEWS</SideBarRowOther>
      <SideBarRowOther to="/gifts">GIFTS</SideBarRowOther>

      <SideBarRow to="/menu">OUR MENU</SideBarRow>

      <SideBarRow to="/milestones">MILESTONES</SideBarRow>
      <SideBarRow to="/pricing">PRICING</SideBarRow>
      <SideBarRow to="/about">ABOUT</SideBarRow>

      <SideBarRow to="/checkout">GET STARTED</SideBarRow>
      <FullHR />
    </Box>
  </SideBarContainer>
);

export const config = {
  height: { xl: "3vw", lg: "4vw", md: "17vw", sm: "25vw" },
  src: {
    xl: "_GiftCard/mother-day-banner-xl.png",
    lg: "_GiftCard/mother-day-banner-lg.png",
    md: "_GiftCard/mother-day-banner-md.png",
    sm: "_GiftCard/mother-day-banner-sm.png",
  },
  alt: "mother day Banner",
};

export const NavBar = ({ history }) => {
  const [isSideBarOpen, setSideBar] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const toggleSideBar = () => setSideBar((old) => !old);
  const { width } = useWindowSize();
  const [showBanner, setShowBanner] = useState(true);

  useDisableScroll(isSideBarOpen && width < 900);
  useEffect(
    () => {
      setSideBar(false);
    },
    [history.location]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleScroll() {
    window.pageYOffset > 54 ? setBgColor("white") : setBgColor("none");
  }

  return (
    <React.Fragment>
      <TopContainer>
        <SideBar isActive={isSideBarOpen} close={toggleSideBar} />

        <NavContainer full row bgColor={bgColor}>
          <button
            type="button"
            css="@media(min-width: 901px){display:none;} cursor: pointer;"
            onClick={toggleSideBar}
          >
            <img src={Hamburger} alt="burger" />
          </button>

          {width < 900 && (
            <NavLink
              to="/"
              css="position: absolute; margin: 0 auto; left: 50%; z-index: 1; transform: translateX(-50%);"
            >
              <img src={Logo} alt="YUMI" height="15px" css="cursor: pointer;" />
            </NavLink>
          )}

          {width > 900 && (
            <Box
              row
              css="
        flex: 1 0 200px;
        justify-content: space-between;
        padding-left: 16px;
        align-items: center;
        max-width: 600px;
        @media(max-width: 900px){
            display: none;
        }
      "
            >
              <NavLink to="/">
                <img
                  src={Logo}
                  alt="YUMI"
                  height="15px"
                  css="cursor: pointer;"
                  onClick={() => setSideBar(true)}
                />
              </NavLink>
              <Field to="/menu">OUR MENU</Field>

              <Field to="/milestones">MILESTONES</Field>
              <Field to="/pricing">PRICING</Field>
            </Box>
          )}

          <Box
            row
            nowrap
            css="
        flex: 1 0 200px;
        justify-content: flex-end;
        align-items: center;
        @media(max-width: 900px){
          flex: 0 0;
          justify-content: flex-start;
        }
      "
          >
            <FieldOther
              to="/gifts"
              css="margin-right: 50px; @media(max-width: 900px){
          display: none;
        }"
            >
              GIFTS
            </FieldOther>
            <FieldOther
              to="/reviews"
              css="margin-right: 50px; @media(max-width: 900px){
          display: none;
        }"
            >
              REVIEWS
            </FieldOther>
            <Field
              to="/login"
              css="margin-right: 50px; @media(max-width: 900px){
          display: none;
        }"
            >
              LOGIN
            </Field>
            <Field to="/checkout">
              <img src={BoxImage} alt="Box icon" height="30px" />
            </Field>
          </Box>
        </NavContainer>
      </TopContainer>
    </React.Fragment>
  );
};

const TopContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 900;
  @media (max-width: 900px) {
    top: 0;
  }
`;
const NavContainer = styled(Box)`
  background: transparent;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.bgColor};
  padding: 16px;
  z-index: 2;
  @media (max-width: 900px) {
    top: 0px;
    bottom: auto;
    padding: 16px;
  }
`;

const Field = ({ to = "/", children, ...props }) => (
  <NavLink to={to} css="text-decoration: none;" {...props}>
    <Label color="#324599">{children}</Label>
  </NavLink>
);

const FieldOther = ({ to = "/", children, ...props }) => (
  <a href={to} css="text-decoration: none;" {...props}>
    <Label color="#324599">{children}</Label>
  </a>
);

export default NavBar;
