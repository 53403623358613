export const colors = {
  bgWhite: "#f8f8f6",
  bgIvory: "#fffdf7",
  textBlack: "#000000",
  textWhite: "#fff",
  textGrey: "#4c4c4c",
  textDarkGreen: "#065a45",
  accentRed: "#e66135",
  hoverOrange: "#f3a689",
  hoverYellow: "#fbda5c",
  hoverBlue: "#6679bf",
  textOat: "#75403E",
  mainColor: "#000",
  accentColor: "#0A6D43"
};
