import React from "react";
import styled, { css } from "styled-components";
import { oatCreamy, blackberry } from "../../../styledcomponents/colors";
import { BabyHand, WhitePuffs } from "../assets";
import { H1, Body } from "../../../styledcomponents/typefaces";

import "styled-components/macro";

export const BabyInCharge = () => (
  <PageLayout>
    <img
      css={css`
        width: 280px;
        height: 270px;
        left: 0;
        top: 900px;
        position: absolute;

        @media (max-width: 1000px) {
          display: none;
          width: 0px;
          height: 0px;
          margin-left: none;
          margin-top: none;
        }
      `}
      src={WhitePuffs}
      alt="white-puffs"
    />
    <MiddleContent>
      <H1
        css={css`
          color: ${blackberry};
          width: 80%;
          font-size: 42pt;
          align-self: flex-end;
          @media (max-width: 1000px) {
            width: 70%;
            align-self: center;
          }
          @media (max-width: 500px) {
            width: 100%;
            align-self: center;
          }
        `}
      >
        {" "}
        Baby's in charge{" "}
      </H1>
      <InChargeSection>
        <InChargeBody>
          We love supporting independent eaters. But as parents, we wanted
          finger foods that were actually nutritious.
        </InChargeBody>
        <InChargeBody>
          That’s why we developed the Tot box. Our box is filled with organic
          and nutrient dense finger food meals for babes looking to achieve
          pincer perfection.
        </InChargeBody>
      </InChargeSection>
    </MiddleContent>
    <img
      src={BabyHand}
      css={css`
        width: 500px;
        height: 400px;
        transform: rotate(17deg);
        right: 0;

        position: absolute;
        @media (max-width: 1000px) {
          margin-left: 0px;
          display: none;
          transform: rotate(0deg);
        }
        @media (max-width: 500px) {
          order: 0;
          display: block;
          transform: scaleX(-1);
          width: 170px;
          height: 130px;
          position: absolute;
          left: -10px;
          margin-top: -200px;
        }
      `}
      alt="baby-hand"
    />
  </PageLayout>
);

const InChargeSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const InChargeBody = styled(Body)`
  font-size: 14pt;
  padding-top: 25px;
  line-height: 1.5em;

  @media (max-width: 1000px) {
    font-size: 13pt;
    width: 85%;
    line-height: 1.6em;
  }
`;
const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  align-items: center;
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    order: 1;
  }
`;
const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
  background: ${oatCreamy};
  overflow: hidden;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
