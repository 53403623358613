import React from "react";
import "styled-components/macro";
import styled, { css } from "styled-components";

import { H2, Body, H3 } from "../../../styledcomponents/typefaces";
import { Box, LazyImage } from "../../../styledcomponents";
import {
  DarkBlue,
  BlockTwo,
  LinkButton,
  TopSolidsWave,
  BottomSolidsWave,
  Peach,
  JV,
  Papaya,
  Eggplant
} from "../assets";

const ms = style => `@media(max-width: 800px){${style}}`;

export const SolidsAndScience = () => (
  <React.Fragment>
    <div css="max-width: 100vw; align-self: flex-end; width: 100%; margin-bottom:-5px; ">
      <LazyImage src={TopSolidsWave} alt="wave" width="100%" />
    </div>

    <Box
      full
      row
      name="SolidsAndScience"
      css={`
        background: #ffd86d;
        justify-content: space-between;
        padding-left: 94px;
        padding-right: 170px;

        ${ms("padding-left: 1em; padding-right: 1em;")};
        @media (max-width: 800px) {
          padding-left: 2em;
          padding-right: 2em;
        }
      `}
    >
      <LazyImage
        src={Eggplant}
        alt="cartoon eggplant"
        css={css`
          position: absolute;
          left: 0px;
          z-index: 10;
          bottom: auto;
          margin-top: -50px;
          @media (min-width: 801px) {
            margin-top: -125px;
          }
          @media (max-width: 800px) {
            width: 50px;
            left: 0%;
            margin-top: -50px;
          }
        `}
      />

      <Box
        name="image left half"
        css={`
          flex: 0 1 600px;
          order: 1;

          margin-right: 50px;
          align-self: center;
          ${ms("display: none; margin-top: 0em; margin-right: 0px;")};
          @media (min-width: 800px) {
            z-index: 1;
          }
        `}
      >
        <LazyImage
          alt="faceless child with baby food and healthy items at a table"
          src={BlockTwo}
          width="100%"
          css="max-width: 99%;"
        />
      </Box>

      <Box
        name="text right half"
        css={`
          flex: 1 1 200px;
          align-items: flex-start;
          order: 2;
          z-index: 1;
          ${ms("order: 1;")};
          @media (min-width: 1150px) {
            margin-top: -100px;
          }
        `}
      >
        <H2
          css={`
            color: ${DarkBlue};
            ${ms("margin-top: 80px;")};
            @media (min-width: 850px) {
              margin-top: 20px;
            }
          `}
        >
          We've Got Solids <br /> Down to a Science
        </H2>
        <Body
          css={`
            margin-top: 32px;
            margin-bottom: 1em;
            ${ms("margin-top: 30px;")};
            @media (min-width: 1200px) {
              max-width: 530px;
            }
          `}
        >
          We introduce your baby to 80+ ingredients, including superfoods and
          spices from around the world.
        </Body>
        <Box
          css={`
            display: none;
            ${ms("display: flex;")};
          `}
        >
          <LazyImage
            alt="faceless child with baby food and healthy items at a table"
            src={BlockTwo}
            width="100%"
            css="max-width: 99%;"
          />
        </Box>
        <Head>Blends</Head>
        <Body>
          Our organic, nutrient-dense blends are gradually introduced in 3
          stages.{" "}
        </Body>

        <Head>Bites</Head>

        <Body>Finger foods to perfect that pincer grasp.</Body>
        <Head>Puffs</Head>
        <Body>Salt-free, rice-free, guilt-free distraction tool.</Body>

        <div
          css={`
            margin-top: 32px;
            margin-bottom: 142px;
            ${ms("display: none;")};
          `}
        >
          <LinkButton />
        </div>
      </Box>
    </Box>
    <div
      css="background: rgb(251, 234, 223); width: 100%; position: relative; @media (min-width: 800px) {
            margin-top: -160px;
            z-index: 0;
          }"
    >
      <LazyImage
        src={BottomSolidsWave}
        alt="bottom solids"
        width="100%"
        css={css`
          align-self: flex-start;
          max-width: 100vw;

          @media (max-width: 800px) {
            background-color: #fffaf8;
          }
        `}
      />
      <LazyImage
        src={Peach}
        alt="cartoon peach"
        css={css`
          position: absolute;
          right: 0px;
          top: -100%;
          z-index: 10;
          bottom: auto;
          left: auto;
          @media (min-width: 1200px) {
            top: -25%;
          }
          @media (max-width: 800px) {
            top: -60%;
            width: 70px;
            right: 0%;
          }
        `}
      />
    </div>
  </React.Fragment>
);

const Head = styled(H3)`
  margin-top: 48px;
  margin-bottom: 8px;
  color: ${DarkBlue};

  ${ms("margin-bottom: 16px; margin-top: 30px; ")};
`;

const Bod = styled(Body)`
  /* nothing */
`;
