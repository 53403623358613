import {
  post,
  request,
  put,
  httpDelete,
  requestFormatted,
} from "../utils/request";
/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} email The email we are trying to verify
 * @param  {string}  password the password hash we are trying to verify
 *
 * @return {boolean} indicated whether or not signin worked
 */
export const getUser = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}`
  );

export const getUserStatusData = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/status-data`
  );

export const getUserSnacks = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/snacks`
  );

export const getUserBillingHistory = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/billing/history`
  );

export const getUserBalanceTransactions = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/balance_transactions`
  );

export const completeCancellation = async (id, description, reasonId) =>
  post(`users/${id}/cancel`, { reason: { description, id: reasonId } });

export const completeCancellationRequest = async (id, { message, reason }) =>
  post(`users/${id}/cancel/request`, { reason, message });

export const editMealPlan = async (id, menuId) =>
  put(`/api/users/${id}/subscription`, { menu_id: menuId });

export const addSource = async (body) => post(`sources`, body);

export const getUserBabyInfo = async (child_id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}baby_info/${child_id}`
  );

export const updateMealPlan = async (id, body) =>
  put(`users/${id}/subscription`, body);

export const updateSpecialMenu = async (id, body) =>
  put(`users/${id}/special-menus`, body);

export const updateChild = async (id, body) =>
  put(`users/${id}/children`, body);

export const updateChildNew = async (id, body) =>
  put(`users/${id}/children/info`, body);

export const getRecentOrders = async (id) =>
  requestFormatted(`users/${id}/recent`);

export const updateBilling = async (id, body) =>
  put(`users/${id}/billing`, body);

export const getBalanceHistory = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}/${id}`
  );

export const updateShippingAddress = async (id, body) =>
  put(`users/${id}/address`, body);
export const removeShippingAddress = async (id, body) =>
  httpDelete(`users/${id}/address`, body);

export const updateUser = async (id, body) => put(`users/${id}/`, body);
export const updatePassword = async (body) => post(`auth/reset`, body);

export const forgotPassword = async (body) => post(`auth/forgot`, body);

export const restorePassword = async (body) => post(`auth/restore`, body);

export const subscribe = async (id, body) =>
  post(`users/${id}/subscribe`, body);

export const subscribeSnacks = async (id, body) =>
  post(`users/${id}/subscribe/snacks`, body);

export const getAddresses = async (id) =>
  requestFormatted(`users/${id}/addresses`);

export const getMilestones = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/milestones`
  );

const plans = {
  weekly: [
    {
      meal_count: 7,
      per_day: 1,
      total: 35.0,
      plan_id: 121,
      plan_name: "bento-five",
      max_zone: 3,
    },
    {
      meal_count: 14,
      total: 65.0,
      per_day: 2,
      plan_id: 141,
      plan_name: "bento-ten",
      max_zone: 50,
    },
    {
      meal_count: 21,
      total: 90,
      per_day: 3,
      plan_id: 161,
      plan_name: "bento-twenty",
      max_zone: 50,
    },
    {
      meal_count: 8,
      per_day: 1,
      total: 35.0,
      plan_id: 202,
      plan_name: "bento-five",
      max_zone: 3,
    },
    {
      meal_count: 16,
      total: 65.0,
      per_day: 2,
      plan_id: 201,
      plan_name: "bento-ten",
      max_zone: 50,
    },
    {
      meal_count: 24,
      total: 90,
      per_day: 3,
      plan_id: 204,
      plan_name: "bento-twenty",
      max_zone: 50,
    },
  ],
  monthly: [
    {
      meal_count: 7,
      total: 112.0,
      per_day: 1,
      plan_id: 121,
      plan_name: "bento-five",
      max_zone: 3,
    },
    {
      meal_count: 14,
      per_day: 2,
      total: 208.0,
      plan_id: 141,
      plan_name: "bento-ten",
      max_zone: 50,
    },
    {
      meal_count: 21,
      per_day: 3,
      total: 288.0,
      plan_id: 161,
      plan_name: "bento-twenty",
      max_zone: 50,
    },
    {
      meal_count: 8,
      per_day: 1,
      total: 112.0,
      plan_id: 202,
      plan_name: "bento-five",
      max_zone: 3,
    },
    {
      meal_count: 16,
      total: 208.0,
      per_day: 2,
      plan_id: 201,
      plan_name: "bento-ten",
      max_zone: 50,
    },
    {
      meal_count: 24,
      total: 288.0,
      per_day: 3,
      plan_id: 204,
      plan_name: "bento-twenty",
      max_zone: 50,
    },
  ],
};

export const getBillingPlans = async () => plans;

export const sendReferralEmail = ({ email, name, note }) =>
  post(`referrals/send`, { email, name, note });

export const getReferrals = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}referrals`
  );

export const getZone = async (zip) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}zones?zip=${zip}`
  );

export const isValidReferral = async (referral_code) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }status/referral?referral_code=${referral_code}`
  );

export const getReferralLink = async () =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}referrals/link`
  );

export const getFrozenPacks = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/packs`
  );

export const submitFrozenPacks = (id, { special_menu_id, plan }) =>
  post(`users/${id}/packs`, { special_menu_id, plan });
export const getWinback = async (id, selected) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/winback?selected=${selected}`
  );

export const submitWinback = async (id, { offer }) =>
  post(`users/${id}/winback`, { offer });

export const getUserBrightback = async (id) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${process.env.NODE_APP_PATHNAME}users/${id}/brightback`
  );
