export const formatStripeResponse = stripeData => ({
  billing_country: "US",
  stripe_card_token: stripeData.token.id,
  billing_address: stripeData.token.card.address_line1,
  billing_city: stripeData.token.card.address_city,
  billing_state: stripeData.token.card.address_state,
  billing_zip: stripeData.token.card.address_zip
});

export default formatStripeResponse;
