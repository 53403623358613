import React from "react";
import "./styles/styles.css";
import checkImg from "../../../../../../../../assets/orders/green-check-icon.svg";

function Address({ address, handleClick }) {
  const formatAddress = (city, state, zip) => `${city  }, ${  state  } ${  zip}`;

  return (
    <div className="address_container">
      <div className="address_details">
        <div>{address.street}</div>
        {address.street2 !== "undefined" ? <div>{address.street2}</div> : null}
        <div>
          {formatAddress(address.city, address.state, address.postcode)}
        </div>
      </div>
      <div className="address_default">
        {address.selected ? (
          <div className="address_default_option default">
            <div>{address.default ? "Default" : "Selected"}</div>
            <img src={checkImg} />
          </div>
        ) : (
          <div
            onClick={() => handleClick(address)}
            className="address_default_option"
          >
            <div>Select</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Address;
