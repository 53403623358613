import spacetime from "spacetime";
import { getOrders } from "../../usecases/orders";

/*
 * Fetches and sorts previous orders.
 */
const getPreviousOrdersService = async () => {
  const ORDERS_PAGE = 1;
  const ORDERS_PER_PAGE = 10;
  const ORDERS_SORT_BY = "desc";
  const date = spacetime
    .now()
    .subtract(2, "years")
    .format("yyyy-MM-dd");
  let tomorrow = Date.now();
  tomorrow -= 48 * 60 * 60 * 1000;

  const orders = await getOrders(
    ORDERS_PAGE,
    ORDERS_PER_PAGE,
    ORDERS_SORT_BY,
    date
  );
  const sortedOrders = orders.reverse();
  const previous = sortedOrders.filter(
    order => new Date(order.delivery_date).getTime() < tomorrow
  );
  return previous.sort(
    (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date)
  );
};

export default getPreviousOrdersService;
