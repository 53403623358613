import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";

import { Sections } from "../../styledcomponents/layout";
import { useWindowSize } from "../../hooks";
import {
  Salare,
  RealFood,
  HighlightsFromKitchen,
  InsideTheBox,
  HowToGet,
  Quote,
  TalkOfTheTown,
  FutureCollabs
} from "./sections";

export const EduardoJordan = () => {
  const { width } = useWindowSize();
  return (
    <Sections>
      <Salare width={width} />
      <RealFood width={width} />
      <HighlightsFromKitchen width={width} />
      <InsideTheBox width={width} />
      <HowToGet width={width} />
      <Quote width={width} />
      <TalkOfTheTown width={width} />
      <FutureCollabs width={width} />
    </Sections>
  );
};
