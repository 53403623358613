import React from "react";
import "./styles/styles.css";

function EditJarsTooltip({ close }) {
  return (
    <div className="edit_jars_tooltip">
      The cutoff to edit orders is the Tuesday a week before the delivery date.
      <div onClick={close} className="edit_jars_tooltip_close">
        Got it!
      </div>
    </div>
  );
}

export default EditJarsTooltip;
