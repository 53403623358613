import React, { useState, useReducer } from "react";
import _ from "lodash";
import "./styles/styles.css";

import {
  getOrdersService,
  getPreviousOrdersService
} from "../../../../services/orders";
import { mapOrderAttributes } from "./mapOrderAttributes";
import { modalReducer } from "./modalAPI/reducer";

import { closeModalAction } from "./modalAPI/actions";
import TryBites from "./components/TryBites";
import Order from "../../../../components/Order";
import { useRequest } from "../../../../hooks";
import UpcomingPreviousToggle from "./components/UpcomingPreviousToggle";
import Modal from "./components/Modal";
import Overlay from "../../../../components/Overlay";

export function OrdersPage({ user }) {
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [modal, dispatch] = useReducer(modalReducer, null);

  const [upcomingOrders, areUpcomingOrdersLoading, e1] = useRequest(
    getOrdersService
  );
  const [previousOrders, arePreviousOrdersLoading, e2] = useRequest(
    getPreviousOrdersService
  );

  const isLoading = areUpcomingOrdersLoading || arePreviousOrdersLoading;
  if (e1 || e2) throw Error("error fetching data");
  if (isLoading) return "Loading...";

  // after everything is loaded, we assign the orders object based on whether we want to view the previous or upcoming
  const orders = showUpcoming ? upcomingOrders : previousOrders;

  return (
    <div className={`orders_page${modal ? " modalOpen" : ""}`}>
      <Overlay show={!!modal} />
      <UpcomingPreviousToggle
        upcoming={showUpcoming}
        showUpcoming={() => setShowUpcoming(true)}
        showPrevious={() => setShowUpcoming(false)}
      />
      {!_.isEmpty(orders) && orders[0].meal_plan_id < 162 ? <TryBites /> : ""}
      <div className={`orders_page_container${modal ? " modalOpen" : ""}`}>
        {!_.isEmpty(orders)
          ? orders.map(order => (
            <Order
              details={mapOrderAttributes(order.orderAttributes)}
              order={order}
              key={order.id}
              previous={!showUpcoming}
              dispatch={dispatch}
            />
            ))
          : "It looks like you don't have any orders here."}
      </div>
      {modal && (
        <Modal
          date={modal.date}
          order={modal.orderId}
          planId={modal.planId}
          title={modal.title}
          details={modal.details}
          options={modal.buttons}
          dispatch={dispatch}
          shipments={modal.shipments}
          yPos={window.scrollY}
          user={user}
          handleClose={() => dispatch(closeModalAction())}
        />
      )}
    </div>
  );
}
