import getOrderByIdService from "./getOrderById";
import getOrdersService from "./getOrders";
import pauseOrderService from "./pauseOrder";
import unpauseOrderService from "./unpauseOrder";
import updateOrderService from "./updateOrder";
import getPreviousOrdersService from "./getPreviousOrders";
import updateOrderAddressService from "./updateOrderAddress";
import { updateOrderPause } from "../../usecases/orders";

const togglePauseOrder = async ({ id, paused }) =>
  updateOrderPause(id, {
    paused: !paused,
    reward_center: paused ? 1 : 0,
  }).catch((e) => Error("There was an error placing your order"));

export {
  getOrderByIdService,
  getOrdersService,
  pauseOrderService,
  unpauseOrderService,
  updateOrderService,
  getPreviousOrdersService,
  updateOrderAddressService,
  togglePauseOrder,
};
