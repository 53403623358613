import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  BlueButton,
  texasBrown,
  texasOat2
} from "../../../styledcomponents";
import "styled-components/macro";
import { FacelessHat, HoustonBell2, HoustonApricot2 } from "../assets";
import { Body, H2 } from "../../../styledcomponents/typefaces";

export const JustForYou = ({ width }) => (
  <ForYouContainer>
    {width < 500 ? (
      <React.Fragment>
        <ImageRow>
          <img
            src={FacelessHat}
            alt="hat"
            css="position:absolute;width:100px;margin-left:20px;margin-top:-17px;"
          />
          <JarImage
            css="width:160px !important; max-height:160px !important"
            src={HoustonApricot2}
            alt="apr"
          />
          <JarImage
            css="width:180px !important;max-height:180px !important"
            src={HoustonBell2}
            alt="bell"
          />
        </ImageRow>

        <H2
          css={css`
            color: ${texasOrange};
            width: 70%;
            text-align: center;
          `}
        >
          We made a pack to celebrate y'all!
        </H2>

        <Body css="text-align:center; width: 70%; padding-top:15px;font-size:12pt !important;">
          Try our Texas Chili and Peach Puree, inspired by the rich and vibrant
          flavors of Houston!
        </Body>
        <Link to="/checkout" css="text-decoration:none">
          <HoustonButton value="Get the Houston Pack" />
        </Link>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <DesktopGrid>
          <Jar>
            <JarAndHat>
              <img
                src={FacelessHat}
                alt="hat"
                css="position:absolute;width:240px;margin-left:80px;margin-top:70px;"
              />
              <JarImage
                src={HoustonApricot2}
                alt="apr"
                css="align-self:center; justify-self:center;"
              />
            </JarAndHat>
            <H2
              css={css`
                color: ${texasOrange};
                width: 70%;
                text-align: center;
                align-self: center;
                justify-self: center;
              `}
            >
              We made a pack to celebrate y'all!
            </H2>
            <Link
              to="/checkout"
              css="align-self:center; justify-self:center; text-decoration:none; width:300px"
            >
              <HoustonButton
                css="font-size:15pt; justify-self:center"
                value="Get the Houston Pack"
              />
            </Link>
          </Jar>
          <Jar>
            <JarImage
              src={HoustonBell2}
              css="align-self:center; justify-self:center;width:460px !important"
              alt="bell"
            />
            <Body css="align-self:center; justify-self:center;text-align:center; width: 70%; padding-top:15px; font-size:15pt !important;">
              Try our Texas Chili and Peach Puree, inspired by the rich and
              vibrant flavors of Houston!
            </Body>
          </Jar>
        </DesktopGrid>
      </React.Fragment>
    )}
  </ForYouContainer>
);

const JarAndHat = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const DesktopGrid = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
`;

const Jar = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 380px 100px 100px;
`;

const ForYouContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  background: ${texasOat2};
  padding-bottom: 40px;

  @media (min-width: 500px) {
    width: 100%;
  }
`;

const ImageRow = styled.div`
  display: flex;
  align-items: center;
`;

const JarImage = styled.img`
  width: 200px;

  @media (min-width: 500px) {
    width: 440px;
    max-height: 500px;
  }
`;

const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  font-size: 11pt;
  width: 100%;
  margin-top: 40px;
  padding: 15px;
  box-shadow: 0px 2px 4px gray;

  @media (min-width: 500px) {
    width: 100%;
  }
`;
