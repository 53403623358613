import React from "react";
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../hooks";
import "styled-components/macro";
import { PressTestimonials } from "./Components/PressTestimonials";

import {
  Page,
  Box,
  H1_D,
  H1_M,
  H2_D,
  H2_M,
  H3_D,
  H3_M,
  H4_D,
  H4_M,
  Quote_D,
  Quote_M,
  Body1_D,
  Body1_M,
  Text,
  grape,
  BigButton,
  blueberry,
  blackberry,
  banana
} from "../../styledcomponents";

const ms = style => `@media(max-width: 800px){${style}}`;

export const PerfectingTheBitePage = () => {
  const { width } = useWindowWidth();
  return (
    <Page
      css={css`
        width: 100%;
        overflow-x: hidden;
      `}
    >
      <Link
        to="/checkout?coupon=50OFF"
        css={css`
          width: 100vw;
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1000;
          background-color: ${banana};
          display: none;
          background-image: url("https://email.helloyumi.com/assets/bannerStarsDesktop.svg");
          background-origin: padding-box;
          background-repeat: no-repeat;
          background-size: contain;
          ${ms("display: block;")};
        `}
      >
        <BigButton
          value="GET DEAL"
          css={css`
            background: ${blueberry};
            border: none;
            width: 50%;
            margin: 24px 25%;
            &:hover {
              border: none;
              background: white;
              border: 1px solid ${blueberry};
              color: ${blueberry};
            }

            ${ms("")};
          `}
        />
      </Link>
      <Box
        css={css`
          max-width: 1238px;
          padding-left: 32px;
          padding-right: 32px;
          width: 100%;
          margin: 0 auto;
          display: flex;
          position: relative;
          flex-direction: row;

          ${ms(
            "flex-direction: column; padding-left: 0px; padding-right: 0px; width: 100%; height: 100vh;"
          )};
        `}
      >
        {width >= 800 ? (
          <Box
            css={css`
              flex-basis: 55%;
              height: 560px;
              width: 100%;
              background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_UGCDesktop.jpg");
              background-repeat: no-repeat;
              background-position: bottom center;
              background-size: cover;
            `}
          >
            {" "}
          </Box>
        ) : (
          <Box
            name="top text"
            css={css`
              width: 100%;
              height: 500px;
              align-items: flex-start;
              text-align: left;

              ${ms(
                "height: 100vh; position: absolute; top: 0; bottom: 0; overflow-y:hidden; background-image: url('https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_UGCMobile.jpg'); background-repeat: no-repeat; background-position: bottom center; background-size: cover;"
              )};
            `}
          />
        )}
        <Box
          css={css`
            z-index: 10;
            flex-basis: 45%;
            padding-left: 100px;
            ${ms(
              "padding-left: 0px; flex-basis: 100%; margin: 10vh 24px 0 24px; text-align: center;"
            )};
          `}
        >
          <Text
            d={H1_D}
            m={H1_M}
            css={css`
              color: ${grape};
            `}
          >
            The Best Finger Foods For Your Babe
          </Text>
          <Text
            d={Body1_D}
            m={Body1_M}
            css={css`
              margin: 40px 0 56px;
              ${ms("margin: 16px 0;")};
            `}
          >
            Nutritious meals and munchies made with organic superfoods. For
            babes 10 months and up.
          </Text>
          <Text
            d={H4_D}
            m={H4_M}
            css={css`
              width: 100%;
              margin-bottom: 24px;
              font-size: 20px;
              color: ${blackberry};
              ${ms("margin-bottom: 16px;")};
            `}
          >
            For A Limited Time Only Get $40 Off Your First Month!
          </Text>
          <Link
            to="/checkout?coupon=50OFF"
            css={css`
              width: 100%;
            `}
          >
            <BigButton
              value="GET DEAL"
              css={css`
                background: ${grape};
                border: none;
                width: 100%;

                &:hover {
                  border: none;
                  background: ${grape};
                }
              `}
            />
          </Link>
        </Box>
      </Box>

      <Box
        css={css`
          max-width: 1238px;
          margin: 120px auto;
          padding: 0 32px;
          width: 100%;
          text-align: center;
          ${ms("margin: 80px 8px; width: auto; text-align: left;")};
        `}
      >
        <img
          src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_Brain.svg"
          css={css`
            height: 80px;
            margin: 0 0 24px;
            ${ms("height: 40px; margin: 0 0 16; display: none")};
          `}
        />
        <Text
          d={H2_D}
          m={H2_M}
          css={css`
            width: 100%;
            color: ${blueberry};
            ${ms("display: none")};
          `}
        >
          First Foods That Nourish
        </Text>
        <Box
          css={css`
            display: none;
            ${ms(
              "display: flex; flex-direction: row; justify-content: space-between; "
            )};
          `}
        >
          <Text
            d={H2_D}
            m={H2_M}
            css={css`
              flex: 0 0 50%;
              color: ${blueberry};
              ${ms("")};
            `}
          >
            First Foods That Nourish
          </Text>
          <img
            src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_Brain.svg"
            css={css`
              flex: 0 0 30%;
              width: 30%;
            `}
          />
        </Box>
        <Text
          d={Body1_D}
          m={Body1_M}
          css={css`
            margin: 24px 0 24px 0;
            ${ms("margin: 16px 0 16px 0;")};
          `}
        >
          At this moment, your baby’s brain is growing faster than it ever will.
          By the end of age 2 it is 80% of the adult brain. Because food fuels
          that growth, we make sure every tasty morsel is packed with nutrition.
          There’s no added preservatives, no sugar, just the good stuff.
        </Text>
        <Text
          d={H3_D}
          m={H3_M}
          css={css`
            width: 100%;
            color: ${blackberry};
          `}
        >
          It turns out you are what you eat.
        </Text>
        <Link
          to="/checkout?coupon=50OFF"
          css={css`
            width: 100%;
          `}
        >
          <BigButton
            value="Claim Your $40 Credit"
            css={css`
              background: ${blueberry};
              border: none;
              margin-top: 56px;
              &:hover {
                border: none;
                background: white;
                border: 1px solid ${blueberry};
                color: ${blueberry};
              }

              ${ms("margin-top: 40px;")};
            `}
          />
        </Link>
      </Box>

      <Box
        css={css`
          width: 100%;
          background-color: #b7aee5;
          display: block;
          height: auto;
          ${ms("height: auto;")};
        `}
      >
        <img
          src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_PerfectingDesktop.gif"
          css={css`
            width: 100%;
            float: right;
            ${ms("display: none")};
          `}
        />
        <img
          src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_Perfecting.gif"
          css={css`
            display: none;
            ${ms(
              "display: block; position: relative; width: 100%; height: auto; top: auto; left: auto;"
            )};
          `}
        />
      </Box>
      <Box
        css={css`
          margin: 120px auto;
          width: 100%;
          @media (max-width: 800px) {
            margin: 80px 0;
            width: 100%;
          }
        `}
      >
        <Box
          css={css`
            max-width: 1238px;
            width: 100%;
            padding: 0 32px;
            position: relative;
            ${ms("padding: 8px 24px")};
          `}
        >
          <Text
            d={H3_D}
            m={H3_M}
            css={css`
              width: 100%;
              color: ${blackberry};
              ${ms("")};
            `}
          >
            The Tot Box
          </Text>
          <img
            src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_Banana.svg"
            css={css`
              position: absolute;
              height: 35px;
              left: -10px;
              ${ms("left: 10px; top: -30px;")};
            `}
          />
          <img
            src="https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_Tomato.svg"
            css={css`
              position: absolute;
              height: 35px;
              left: 350px;
              top: -30px;
              ${ms("left: 50px;")};
            `}
          />
        </Box>

        <Box
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            -webkit-box-align: stretch;
            algin-items: stretch;
            -webkit-box-pack: center;
            justify-content: center;
            max-width: 1238px;
            width: 100%;
            padding: 0 16px;
            @media (max-width: 800px) {
              width: 100%;
            }
          `}
        >
          <Box
            css={css`
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: calc(25% - 32px);
              margin: 16px;
              display: block;
              height: 427px;
              background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_CubanoBite.png");
              background-repeat: no-repeat;
              background-position: top center;
              background-size: cover;
              border: 0.7px solid rgba(0, 0, 0, 0.12);
              @media (max-width: 800px) {
                flex-basis: calc(50% - 16px);
                height: 400px;
                margin: 8px;
                background-position: center;
              }

              @media (max-width: 500px) {
                background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_CubanoBiteMobile.png");
              }
              @media (max-width: 400px) {
                height: 320px;
              }
            `}
          />
          <Box
            css={css`
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: calc(25% - 32px);
              margin: 16px;
              display: block;
              height: 427px;
              background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_CarrotBite.png");
              background-repeat: no-repeat;
              background-position: top center;
              background-size: cover;
              border: 0.7px solid rgba(0, 0, 0, 0.12);
              @media (max-width: 800px) {
                flex-basis: calc(50% - 16px);
                height: 400px;
                margin: 8px;
                background-position: center;
              }
              @media (max-width: 500px) {
                background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_CarrotBiteMobile.png");
              }
              @media (max-width: 400px) {
                height: 320px;
              }
            `}
          />
          <Box
            css={css`
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: calc(25% - 32px);
              margin: 16px;
              display: block;
              height: 427px;
              background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_LentilBite.png");
              background-repeat: no-repeat;
              background-position: top center;
              background-size: cover;
              border: 0.7px solid rgba(0, 0, 0, 0.12);
              @media (max-width: 800px) {
                flex-basis: calc(50% - 16px);
                height: 400px;
                margin: 8px;
                background-position: center;
              }
              @media (max-width: 500px) {
                background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_LentilMobile.png");
              }
              @media (max-width: 400px) {
                height: 320px;
              }
            `}
          />
          <Box
            css={css`
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: calc(25% - 32px);
              margin: 16px;
              display: block;
              height: 427px;
              background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_BeetPuff.png");
              background-repeat: no-repeat;
              background-position: top center;
              background-size: cover;
              border: 0.7px solid rgba(0, 0, 0, 0.12);
              @media (max-width: 800px) {
                flex-basis: calc(50% - 16px);
                height: 400px;
                margin: 8px;
                background-position: center;
              }
              @media (max-width: 500px) {
                background-image: url("https://email.helloyumi.com/assets/_TotsLanding/TotsLanding_BeetPuffMobile.png");
              }
              @media (max-width: 400px) {
                height: 320px;
              }
            `}
          />
        </Box>
        <Link
          to="/checkout?coupon=50OFF"
          css={css`
            width: auto;
          `}
        >
          <BigButton
            value="GET DEAL"
            css={css`
              background: ${blueberry};
              border: none;
              margin-top: 56px;
              &:hover {
                border: none;
                background: white;
                border: 1px solid ${blueberry};
                color: ${blueberry};
              }

              ${ms("margin-top: 40px;")};
            `}
          />
        </Link>
      </Box>
      <Box
        css={css`
          width: 100%;
          padding: 120px 0;
          text-align: center;
          background-color: #b7aee5;

          ${ms("padding: 80px 0;")};
        `}
      >
        <Text
          d={Quote_D}
          m={Quote_M}
          css={css`
            max-width: 770px;
            width: 100%;
            color: white;
            ${ms("width: auto; margin: 0 48px;")};
          `}
        >
          “With Yumi, I realized that kids aren’t born with a chicken finger and
          french fry palate. One year later, my baby enjoys anything we eat.”
        </Text>
        <Text
          d={Body1_D}
          m={Body1_M}
          css={css`
            margin-top: 24px;
            color: white;
            ${ms("margin-top: 16px")};
          `}
        >
          - Sienna L, Los Angeles, CA
        </Text>
      </Box>
      <PressTestimonials width={width} />
    </Page>
  );
};
