import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import { H2, Body } from "./typefaces";
import { blackberry, squash, grapefruit, oat, pepper } from "./colors";
import { BlueButton } from "./forms";
import { getReviews } from "../usecases";
import { Swiper } from "./Swiper";

import { useRequest, useWindowSize } from "../hooks";
import { H4, Body1, H6 } from "./text";

const Kiwi = "https://email.helloyumi.com/assets/_Reviews/Reviews_Kiwi.svg";
const Star = "https://email.helloyumi.com/assets/_Reviews/Reviews_Star.svg";

export const ReviewsCarousel = ({ color = blackberry }) => {
  const [progress, setProgress] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [maxScroll, setMaxScroll] = useState(30500);
  const { width } = useWindowSize();

  const [reviews, loading, error] = useRequest(getReviews);

  if (loading) return "Loading...";
  if (error || !reviews) return <p>No reviews found.</p>;

  const returnedReviews = reviews.reviews;
  const chunkedReviews = _.chunk(returnedReviews, 10);
  const sortedReviews = _.orderBy(returnedReviews, ["score"], ["desc"]);

  return (
    <ReviewContainer progress={progress}>
      <TopSection>
        <img src={Kiwi} style={{ width: "75px" }} alt="kiwi" />
        <H2 style={{ color, paddingTop: "24px" }}>From the Yumi Family</H2>
        <Body style={{ padding: "20px", textAlign: "center" }}>
          The reviews are in! Parents and babies are loving Yumi.{" "}
        </Body>
        <BlueButton>
          <a
            href="/reviews"
            style={{ color: grapefruit, textDecoration: "none" }}
          >
            See All Reviews{" "}
          </a>
        </BlueButton>
      </TopSection>
      <Swiper
        class="swiper"
        onScroll={e =>
          handleScroll(e, setProgress, setScrollLeft, setMaxScroll)
        }
        color={color}
        scrollLeft={scrollLeft}
        maxScroll={maxScroll}
        windowWidth={width}
        render={() =>
          sortedReviews.map(review => (
            <ReviewCard key={review.id} review={review} width={width} />
          ))
        }
      />
      <ProgressDots>
        {Array(chunkedReviews.length)
          .fill()
          .map((el, i) => (
            <div
              key={i}
              style={{
                background: progress >= i + 1 ? color : "#eee",
                padding: "5px",
                marginLeft: "5px",
                borderRadius: "30px"
              }}
            />
          ))}
      </ProgressDots>
      <BlueButton
        style={{
          padding: "15px",
          marginTop: "20px",
          color: "white",
          width: "140px",
          background: color,
          justifySelf: "center"
        }}
      >
        <Link to="/checkout" style={{ textDecoration: "none", color: "white" }}>
          GET STARTED
        </Link>
      </BlueButton>
    </ReviewContainer>
  );
};

const handleScroll = (e, setProgress, setScrollLeft, setMaxScroll) => {
  if (!e.target.scrollTop && e.target.scrollLeft) {
    setMaxScroll(e.target.scrollWidth);
    setScrollLeft(e.target.scrollLeft);
    setProgress(
      Number((e.target.scrollLeft / e.target.scrollWidth) * 10).toFixed(0)
    );
  }
};

const ReviewCard = ({ review, onScroll, width }) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const { name, score, content, title } = review;

  const titleWithoutCharacterCodes = title
    .replace(/&#x27;/g, "'")
    .replace(/&quot;/g, `"`)
    .replace(/&amp;/g, "&");

  const contentWithoutCharacterCodes = content
    .replace(/&#x27;/g, "'")
    .replace(/&quot;/g, `"`)
    .replace(/&amp;/g, "&");

  const canContinueReading = content.split(" ").length > 30;

  const contentExcerpt = `${contentWithoutCharacterCodes
    .split(" ")
    .slice(0, 30)
    .join(" ")}${canContinueReading ? "..." : ""}`;

  return (
    <ReviewCardContainer
      style={{
        background: isRevealed && oat,
        outline: isRevealed && `1px solid ${squash}`
      }}
      onScroll={onScroll}
    >
      {isRevealed ? (
        <React.Fragment>
          <ContinueReadingButton
            style={{ color: pepper }}
            onClick={() => setIsRevealed(false)}
          >
            Close
          </ContinueReadingButton>
          <FullReview
            style={{
              borderBottom:
                (content.length > 600 || width < 1000) && `4px solid #e0c5a9`
            }}
          >
            <Body1 style={{ fontSize: "12pt" }}>
              {contentWithoutCharacterCodes}
            </Body1>
          </FullReview>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <UserBar>
            <div
              style={{
                padding: "10px",
                background: oat,
                borderRadius: "40px",
                display: "flex",
                justifyContent: "center",
                width: "25px"
              }}
            >
              <H6 style={{ fontSize: "14pt" }}> {name[0]}</H6>
            </div>
            <H4 style={{ fontSize: "12pt" }}> {name}</H4>
          </UserBar>
          <StarArea>
            <p style={{ color: grapefruit }}>
              {Array(score)
                .fill()
                .map((el, i) => (
                  <img
                    src={Star}
                    key={i}
                    style={{ width: "20px" }}
                    alt="review-star"
                  />
                ))}
            </p>
          </StarArea>

          <ReviewBody>
            <Body>{titleWithoutCharacterCodes}</Body>
            <Body1
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12pt",
                paddingTop: "20px",
                paddingBottom: "25px"
              }}
            >
              {contentExcerpt}
            </Body1>
          </ReviewBody>
          <Body1 style={{ alignSelf: "center", position: "relative" }}>
            {(width < 1000 || canContinueReading) && (
              <ContinueReadingButton onClick={() => setIsRevealed(true)}>
                Continue Reading
              </ContinueReadingButton>
            )}
          </Body1>
        </React.Fragment>
      )}
    </ReviewCardContainer>
  );
};

const ContinueReadingButton = styled(BlueButton)`
  font-size: 12pt;
  color: ${blackberry};
  align-self: center;
  outline: none;
  padding: 10px;
  @media (max-width: 1000px) {
    padding: 10px;
  }
`;

const FullReview = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  padding-top: 30px;
  animation: slideUp 0.2s forwards;
  overflow: scroll;
  @keyframes slideUp {
    0% {
      transform: translateY(300px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
const ReviewBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 350px;

  @media (max-width: 1000px) {
    overflow: scroll;
    padding-top: 20px;
  }
`;
const ProgressDots = styled.div`
  display: flex;
  padding-top: 30px;
`;
const UserBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  max-width: 100%;
  z-index: 1000;
  background: white;
`;
const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReviewCardContainer = styled.div`
  height: 380px;
  min-width: 280px;
  padding: 40px;
  margin-left: 20px;
  border-radius: 8px;
  box-shadow: 1px 1px 20px lightgray;

  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    height: 260px;
    min-width: 170px;
  }
`;

const StarArea = styled.div`
  display: flex;
`;

/* YOTPO GENERATED CODE W LIMITED CUSTOMIZATION <div
        style={{ width: "100%" }}
        className="yotpo yotpo-reviews-carousel"
        data-background-color="transparent"
        data-mode="top_rated"
        data-type="site"
        data-count="8"
        data-show-bottomline="1"
        data-autoplay-enabled="1"
        data-autoplay-speed="3000"
        data-show-navigation="1"
        data-testimonials-page-text="See All Reviews"
        data-testimonials-page-link="https://helloyumi.com/reviews"
      >
        &nbsp;
      </div> */
