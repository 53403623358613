import React, { useState } from "react";
import styled, { css } from "styled-components";
import { H2, Body } from "../../../styledcomponents/typefaces";
import { NewYork, goop } from "../../OneOffPurchase/assets";
import { yumiOrange, HyperLink } from "../../../styledcomponents";
import "styled-components/macro";
import { HalfPine, CrowdCarrot } from "../assets";

const FoodNWine =
  "https://email.helloyumi.com/assets/_HomePageRefresh/FoodNWine.svg";
const CNN = "https://email.helloyumi.com/assets/_HomePageRefresh/CNN.svg";
const Inc = "https://email.helloyumi.com/assets/_HomePageRefresh/Inc.svg";

const blurbs = [
  `"Make room, Gerber Babies, for the Goo-Goo Gourmets."`,
  `"Admit it: If you order these meals, your baby will eat more adventurously than you do."`,
  `"Yumi, an adorable new pediatrician/nutritionist-approved baby-food delivery company, makes the whole experience a pleasure."`,
  <a
    css={css`
      color: ${yumiOrange};
    `}
    href="https://money.cnn.com/video/technology/2018/02/06/yumi-subscription-delivery-baby-food.cnnmoney/index.html"
  >
    See the CNN video here.
  </a>,
  <a
    css={css`
      color: ${yumiOrange};
    `}
    href="https://www.inc.com/video/2-female-founders-share-the-importance-of-building-a-brand-not-just-a-product.html"
  >
    See the Inc. video here.
  </a>
];

export const CrowdPleaser = ({ width }) => {
  const [activeBlurb, setActiveBlurb] = useState(0);
  return (
    <CrowdContainer>
      <img
        src={HalfPine}
        alt="pine"
        css="position:absolute; right:0;@media (max-width: 1100px) {
   width:50px;
  }@media (max-width: 500px) {
   display:NONE;
  }"
      />
      <img
        src={CrowdCarrot}
        alt="pine"
        css="position:absolute; display:none; right:0; @media (max-width: 500px) {
   display:flex;
   
  }"
      />
      <H2
        css={css`
          color: ${yumiOrange};

          @media (max-width: 1000px) {
            text-align: center;
            font-size: 40px;
          }
          @media (max-width: 500px) {
            text-align: left;
            padding-left: 30px;
            font-size: 24px;
          }
        `}
      >
        Crowd Pleaser
      </H2>
      <CrowdRow>
        {width < 500 ? (
          <React.Fragment>
            <div
              css={css`
                display: flex;
                width: 47%;
                align-items: center;
                justify-content: space-around;
                @media (max-width: 1000px) {
                  width: 100%;
                  display: grid;
                  grid-template-columns: 180px 75px;
                  justify-content: flex-start;
                }
              `}
            >
              <HyperLink href="https://www.nytimes.com/2018/12/05/style/how-to-raise-a-foodie.html">
                <img
                  src={NewYork}
                  alt="new yak"
                  css="width:220px;@media(max-width:1000px){padding-top:10px; width:160px;}"
                />
              </HyperLink>
              <HyperLink href="https://www.foodandwine.com/news/yumi-organic-baby-food-subscription-delivery">
                <img
                  src={FoodNWine}
                  alt="food"
                  css="width:180px;@media(max-width:1000px){padding-top:10px; width:130px;}"
                />
              </HyperLink>
            </div>
            <div
              css={css`
                display: flex;
                width: 45%;
                align-items: center;
                justify-content: space-around;
                @media (max-width: 1000px) {
                  display: grid;
                  grid-template-columns: 90px 90px 90px;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                }
              `}
            >
              <HyperLink href="https://money.cnn.com/video/technology/2018/02/06/yumi-subscription-delivery-baby-food.cnnmoney/index.html">
                {" "}
                <img
                  src={CNN}
                  alt="cnn"
                  css="width:80px; @media(max-width:1000px){padding-top:20px; width:60px;}"
                />
              </HyperLink>
              <HyperLink href="https://goop.com/work/parenthood/time-saving-hacks-from-momlife/">
                <img
                  src={goop}
                  alt="goop"
                  css="width:110px;@media(max-width:1000px){padding-top:20px; width:70px;}"
                />
              </HyperLink>
              <img
                src={Inc}
                alt="inc"
                css="width:100px;@media(max-width:1000px){padding-top:20px; width:60px;}"
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LinkImage
              src={NewYork}
              onClick={() => setActiveBlurb(0)}
              active={activeBlurb === 0}
              alt="new yak"
              css={css`
                width: 220px;
                @media (max-width: 1000px) {
                  padding-top: 10px;
                  width: 160px;
                }
              `}
            />
            <LinkImage
              src={FoodNWine}
              onClick={() => setActiveBlurb(1)}
              active={activeBlurb === 1}
              alt="food"
              css={css`
                width: 180px;
                @media (max-width: 1000px) {
                  padding-top: 10px;
                  width: 130px;
                }
              `}
            />
            <LinkImage
              src={goop}
              onClick={() => setActiveBlurb(2)}
              active={activeBlurb === 2}
              alt="goop"
              css={css`
                width: 80px;
                @media (max-width: 1000px) {
                  padding-top: 20px;
                  width: 70px;
                }
              `}
            />
            <LinkImage
              src={CNN}
              onClick={() => setActiveBlurb(3)}
              active={activeBlurb === 3}
              alt="cnn"
              css={css`
                width: 80px;
                @media (max-width: 1000px) {
                  padding-top: 20px;
                  width: 60px;
                }
              `}
            />

            <LinkImage
              src={Inc}
              active={activeBlurb === 4}
              onClick={() => setActiveBlurb(4)}
              alt="inc"
              css={css`
                width: 80px;
                margin-top: -10px;
                @media (max-width: 1000px) {
                  margin-top: 0px;
                  padding-top: 20px;
                  width: 60px;
                }
              `}
            />
          </React.Fragment>
        )}
      </CrowdRow>

      {width > 500 && <Quote>{blurbs[activeBlurb]}</Quote>}
    </CrowdContainer>
  );
};

const LinkImage = styled.img`
  border-bottom: ${({ active }) =>
    active ? `2px solid ${yumiOrange}` : "none"};
  cursor: pointer;
`;

const Quote = styled(Body)`
  padding-top: 40px;
  display: flex;
  width: 60%;
  text-align: center;
  justify-content: center;
  font-size: 32px;

  @media (max-width: 1000px) {
    width: 85%;
  }
`;
const CrowdRow = styled.div`
  display: flex;
  width: 90%;
  padding-top: 50px;
  justify-content: space-evenly;

  @media (max-width: 1000px) {
    width: 80%;
    justify-content: Center;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 500px) {
    display: grid;
    grid-template-rows: 50px 50px;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-left: 30px;
  }
`;
const CrowdContainer = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
  align-items: center;
  display: flex;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (max-width: 1000px) {
    height: 600px;
    justify-content: center;
  }
  @media (max-width: 500px) {
    height: 280px;
    padding-top: 40px;
    justify-content: center;
    align-items: flex-start;
  }
`;
