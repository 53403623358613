import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  texasOrange,
  Body1,
  BlueButton,
  texasBrown
} from "../../../styledcomponents";
import "styled-components/macro";
import { TrayOfBites, PlainPuffs, PurpleTray } from "../assets";
import { H2, H3, Body } from "../../../styledcomponents/typefaces";

export const ScienceBased = ({ width }) => (
  <React.Fragment>
    <ScienceSection>
      <H3
        css={css`
          color: ${texasOrange};
          width: 70%;
          text-align: center;

          @media (min-width: 500px) {
            font-size: 40pt;
            line-height: 1em;
          }
        `}
      >
        {width > 500
          ? `Organic. Fresh. Nutrient Dense.`
          : `A science-based program for the first years of life.`}
      </H3>
    </ScienceSection>
    <ForYouContainer>
      {width < 500 ? (
        <React.Fragment>
          <img
            src={TrayOfBites}
            alt="tray"
            css="width:400px; margin-left:-300px; margin-top: -60px; position:relative; transform:rotate(120deg)"
          />
          <MidSection>
            <H2
              css={css`
                color: ${texasOrange};
                text-align: left;
                padding-top: 80px;

                @media (min-width: 500px) {
                  font-size: 35pt;
                }
              `}
            >
              We have fingerfoods too!
            </H2>
            <Body1 css="text-align:left; width: 90%; padding-top:5px; ">
              Try our nutrient dense frozen bites and super food puffs.
            </Body1>
          </MidSection>
          <img src={PlainPuffs} alt="puffs" css="height:320px; z-index:0;" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img
            src={PurpleTray}
            alt="tray"
            css="width:650px;margin-left:-170px; @media(min-width:1300px){margin-left:-300px;}@media(min-width:1500px){margin-left:-300px;}"
          />

          <RightSide>
            <Text>
              <H2
                css={css`
                  color: ${texasOrange};
                  text-align: left;
                  padding-top: 80px;
                  min-width: 400px;

                  @media (max-width: 800px) {
                    line-height: 1em !important;
                  }
                  @media (min-width: 500px) {
                    font-size: 35pt;
                  }
                `}
              >
                We have fingerfoods too!
              </H2>
              <Body
                css="text-align:left; font-size:17pt; line-height:1.1em; padding-top:40px; 
                @media (max-width: 800px) {
                width: 80%;
                }"
              >
                Try our nutrient dense frozen bites and super food puffs.
              </Body>
              <Link css="text-decoration:none" to="/checkout">
                <HoustonButton value="Get Started" />
              </Link>
            </Text>
            <img
              src={PlainPuffs}
              css="width:300px; margin-left:50px; margin-top:-50px"
              alt="plain"
            />
          </RightSide>
        </React.Fragment>
      )}
    </ForYouContainer>
  </React.Fragment>
);

const Text = styled.div`
  align-self: center;
  width: 100%;
`;
const HoustonButton = styled(BlueButton)`
  background: ${texasBrown};
  color: white;
  font-size: 14pt;
  padding: 20px;
  width: 300px;
  margin-top: 50px;
  box-shadow: 0px 2px 4px gray;
`;

const RightSide = styled.div`
  padding-left: 40px;
  width: 100%;
  display: flex;
  justify-self: center;
`;

const MidSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    min-width: 220px;
  }
`;

const ScienceSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: white;
  z-index: 80;

  @media (min-width: 500px) {
    height: 160px;
  }
`;
const ForYouContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff9f3;
  padding-bottom: 40px;

  @media (min-width: 500px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-rows: 500px;
    grid-template-columns: 500px 600px;
  }
`;
