import React, { useState } from "react";
import { updatePassword } from "../../../usecases/users";
import {
  InputField,
  FormContainer,
  ModalButton,
  ButtonContainer,
  Label,
  EditHeader,
  Card,
  CardTitle
} from "../components/styledComponents";
import PasswordIcon from "../../../../assets/icons/Dashboard-icons/lock-icon.svg";
import Modal from "../components/Modal";

const PasswordUpdateForm = ({ close, success }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const submitForm = async () => {
    if (newPassword !== confirmNewPassword)
      return setError("Passwords must match");
    if (newPassword.length < 4)
      return setError("New password must be at least four characters long");
    if (newPassword.includes(" "))
      return setError("New password cannot contain spaces");
    try {
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      await updatePassword({
        old_password: oldPassword,
        new_password: newPassword
      });
      success();
      setError("");
      close();
    } catch (e) {
      setError("Sorry. Something went wrong. Please try again");
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      return false;
    }
  };

  return (
    <React.Fragment>
      <EditHeader close={close}>Edit password</EditHeader>
      <FormContainer
        onSubmit={e => {
          e.preventDefault();
          submitForm();
        }}
        autoComplete="off"
      >
        <input
          type="hidden"
          value="hidden input to prevent password autofill"
        />
        <Label htmlFor="oldPassword">Old password</Label>
        <InputField
          type="password"
          name="oldPassword"
          autoComplete="new-password"
          onChange={e => {
            setOldPassword(e.target.value);
          }}
          value={oldPassword}
        />
        <Label htmlFor="newPassword">New password</Label>
        <InputField
          type="password"
          autoComplete="new-password"
          name="newPassword"
          onChange={e => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <Label htmlFor="confirmNewPassword">Confirm new password</Label>
        <InputField
          type="password"
          name="confirmNewPassword"
          autoComplete="new-password"
          onChange={e => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword}
        />
        <span style={{ color: "red" }}>{error}</span>
        <ButtonContainer>
          <ModalButton
            cancel
            type="button"
            value="cancel"
            onClick={e => {
              e.stopPropagation();
              close();
            }}
          />
          <ModalButton type="submit" value="save" />
        </ButtonContainer>
      </FormContainer>
    </React.Fragment>
  );
};
export const Password = ({ activeModal, openModal, close, success }) => (
  <Card onClick={openModal} icon={PasswordIcon}>
    <CardTitle>Password</CardTitle>
    <Modal isOpen={activeModal === "Password"} close={close}>
      <PasswordUpdateForm close={close} success={success} />
    </Modal>
  </Card>
);
