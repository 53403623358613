import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";

export const InnerGridTemplate = ({
  leftChildren,
  rightChildren,
  rightStyling = {},
  leftStyling = {},
  gridRow = {},
  gridDimensions = {},
  gridMidDimensions = {},
  gridMobileRows = {}
}) => (
  <InnerGrid
    gridDimensions={gridDimensions}
    gridMidDimensions={gridMidDimensions}
    gridRow={gridRow}
    gridMobileRows={gridMobileRows}
  >
    <div />
    <Left css={leftStyling}>{leftChildren}</Left>
    <Right css={rightStyling}>{rightChildren}</Right>
    <div />
  </InnerGrid>
);

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ gridDimensions }) =>
    gridDimensions && gridDimensions};

  grid-template-rows: ${({ gridRow }) => gridRow && gridRow};

  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: ${({ gridMidDimensions, gridDimensions }) =>
      gridMidDimensions || gridDimensions};
  }

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-template-rows: ${({ gridMobileRows }) =>
      gridMobileRows && gridMobileRows};
  }
`;
const Left = styled.div`
  width: 100%;
`;
const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
