import React, { useState } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import Select from "react-select";
import { H1, Body } from "../../../styledcomponents/typefaces";
import { yumiOrange, H5, oatCreamy } from "../../../styledcomponents";
import {
  Lead,
  Arsenic,
  Cadmium,
  ArsenicPPM,
  CadmiumPPM,
  LeadPPM
} from "../assets";

const metals = [
  {
    label: "Lead",
    value: 0,
    image: Lead,
    ppm: LeadPPM,
    why:
      "Studies show that exposure to lead, can cause learning and behavioral problems. According to the Academy of Nutrition and Dietetics, it can cause lasting problems with growth and development including lower IQ, Autism, ADHD, hearing problems, and Anemia."
  },
  {
    label: "Arsenic",
    value: 1,
    image: Arsenic,
    ppm: ArsenicPPM,
    why:
      "Early childhood exposure to arsenic can lead to increased risk of developing skin and bladder cancers, neurotoxicity and hormone disruption. (3) Several studies have also shown that arsenic exposure is correlated with lower IQ scores. Don't let organic labels lull you into a false sense of security. According to a study by the Clean Label Project, certified organic baby food products had twice the amount of arsenic vs. non-organic products."
  },
  {
    label: "Cadmium",
    value: 2,
    image: Cadmium,
    ppm: CadmiumPPM,
    why:
      "Cadmium is a toxic metal that has shown to have adverse effects on the heart, digestive system and can cause bone demineralization. (7) It has also been associated with learning disabilities. Cadmium is commonly released into the atmosphere through fossil fuel combustion and waste incineration. From there, it impacts our water and soil, and ultimately the crops we eat."
  }
];

export const AMapToTheMetals = ({ width }) => {
  const [activeMetal, setActiveMetal] = useState(0);
  return (
    <KeepContainer>
      <div />
      <InnerGrid>
        <TopBody>
          <H1
            css={css`
              font-size: 50px;
              color: ${yumiOrange};
              padding-top: 110px;

              @media (max-width: 1000px) {
                padding-top: 50px;
              }
            `}
          >
            A Map to the Metals
          </H1>
          <Body css="fony-size:24px; padding-top:15px; width:85%; @media(max-width:1000px){width:50%;}">
            Heavy metals occur naturally in soil—in fact, the USDA measures soil
            for mineral levels yearly. However, things go awry when food comes
            into contact with water run-off or soil contaminated through farming
            or industrial manufacturing practices.
          </Body>
        </TopBody>
        <MapSection>
          <MapCard>
            <img
              src={metals[activeMetal].image}
              css={css`
                width: 719px;
                height: 451px;
                @media (max-width: 1000px) {
                  width: 300px;
                  order: 2;
                  height: 180px;
                }
              `}
              alt="img"
            />
          </MapCard>
          <MapSelector>
            <Select
              css="width:100%; padding-top:20px;z-index:90; order: 1;"
              options={metals}
              value={metals[activeMetal]}
              onChange={newState => setActiveMetal(newState.value)}
            />{" "}
            {width < 500 && (
              <img
                src={metals[activeMetal].ppm}
                css="width:358px; padding-top:15px; padding-top:15px;margin-left:-15px; opacity: 1; transition: opacity: .3s ease-in; align-self:center; @media(max-width:1000px){width:344px;margin-left:-20px;}"
                alt="ppm"
              />
            )}
            <div css="@media(max-width:500px){order:2}">
              <H5 css="padding-top:30px;padding-bottom:20px;">
                Why it matters
              </H5>
              <Body css="font-size:16px;line-height:1.5em; ">
                {metals[activeMetal].why}
              </Body>
            </div>
            {width > 500 && (
              <img
                src={metals[activeMetal].ppm}
                css="width:358px; padding-top:15px; margin-left:-15px; @media(max-width:1000px){width:344px;margin-left:-20px;}"
                alt="ppm"
              />
            )}
          </MapSelector>
        </MapSection>
      </InnerGrid>
      <div />
    </KeepContainer>
  );
};

const MapCard = styled.div`
  max-width: 730px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    max-width: 300px;
  }
`;
const MapSelector = styled.div`
  padding-left: 50px;
  width: 100%;
  height: 509px;

  @media (max-width: 1000px) {
    padding-left: 0px;
  }
`;

const InnerGrid = styled.div`
  display: grid;
  grid-template-rows: 0.5frpx 1fr;
  grid-template-columns: 700px;
`;
const MapSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 1000px) {
    grid-template-columns: 300px;
    padding-left: 35px;
    padding-bottom: 160px;
  }
`;
const TopBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1000px) {
    padding-left: 35px;
  }
`;
const KeepContainer = styled.div`
  width: 100%;
  overflow: hidden;

  display: grid;
  align-items: center;
  grid-template-columns: 0.1fr 1fr 0.12fr;

  @media (max-width: 1000px) {
    background: ${oatCreamy};
    grid-template-columns: 350px;
  }
`;
