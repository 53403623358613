import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "styled-components/macro";
import { Detail, Label } from "../../styledcomponents/typefaces";
import {
  Box,
  HR,
  blackberry as BigBlue,
  HyperLink,
  LazyImage
} from "../../styledcomponents";
import { useWindowSize } from "../../hooks";

const getLogo = service => `https://email.helloyumi.com/assets/${service}.svg`;
const Logo = `https://email.helloyumi.com/assets/yumi_white.svg`;

export const Footer = () => {
  const { width } = useWindowSize();
  return (
    <Box
      full
      css={`
        background: ${BigBlue};
        padding-left: 3.125em;
        pading-right: 3.125em;
        color: white;
        @media (max-width: 500px) {
          margin-bottom: 70px;
        }
      `}
    >
      <Box
        row
        full
        css={`
          background: ${BigBlue};
          color: white;
          align-items: flex-start;
          justify-content: center;

          @media (max-width: 800px) {
            flex-direction: column;
          }
        `}
      >
        {width > 850 && (
          <Column
            css="
              margin-top: 3.75em;
              margin-right: 6.25em;
            "
          >
            <LazyImage src={Logo} alt="yumi logo white" height="30px" />
          </Column>
        )}
        <Column css="@media(min-width: 801px){margin-right:5.5em;}">
          <Label css="margin-top:3.75em; margin-bottom:1em;">HELLOYUMI</Label>
          <DetailLink to="/menu">Our Menu</DetailLink>
          <DetailLink to="/baby-led-weaning">Baby Led Weaning</DetailLink>
          <DetailLink to="/chef-series">Chef Series</DetailLink>
          <DetailLink to="/pricing">Pricing</DetailLink>
          <DetailLink to="/blog">Blog</DetailLink>
          <DetailLink to="/about">About</DetailLink>
        </Column>
        <Column css="@media(min-width: 801px){margin-right:5.5em;}">
          <Label css="margin-top: 3.75em; margin-bottom: 1em;">
            HELP CENTER
          </Label>
          <DetailLink href="https://helloyumi.kustomer.help/">FAQs</DetailLink>
          <DetailLink href="mailto:support@helloyumi.com">Email us</DetailLink>
          <DetailLink href="https://yumi.breezy.hr">Careers</DetailLink>
          <DetailLink href="https://business.helloyumi.com">
            Partnerships
          </DetailLink>
        </Column>

        <Column css="@media(min-width: 801px){margin-right:5.5em;}">
          <Label css="margin-top: 3.75em; margin-bottom: 1em;">FOLLOW US</Label>
          <Box row full css="justify-content: space-between;">
            {["facebook", "instagram", "twitter"].map(name => (
              <HyperLink href={`https://${name}.com/yumi`} key={name}>
                <LazyImage
                  src={getLogo(name)}
                  alt="social network logo"
                  key={name}
                  css="margin-right: 1.5em;"
                />
              </HyperLink>
            ))}
          </Box>
        </Column>
      </Box>
      <Box css="width: 500px; display: flex; align-items: flex-start; @media(max-width:800px){ width: 400px; } @media(max-width:500px){ width: 100%; margin-left: -10%; }">
        <Label css="margin-top: 3.75em; @media(max-width:500px){ margin-left: 5%; }">
          GET FRESH EMAILS
        </Label>
        <iframe
          src="https://email.helloyumi.com/assets/klaviyo.html"
          title="klaviyo iframe"
          css="border: none; width: 100%; margin-left: -20px; @media(max-width:500px){ margin-left: 0; }"
        />
      </Box>
      <Box
        row
        css="text-align: center; justify-content: flex-start; @media(max-width:800px){align-self: flex-start; align-items: flex-start; flex: 1; margin-bottom: 1em; flex-direction: column;}"
      >
        {width > 800 && (
          <HR css="background: white; opacity: .2; margin-bottom: 1.25em; height: .65px" />
        )}
        <Detail css="font-size: 14px; text-align; left; margin-bottom: 2.5em; margin-right:2em; ">
          &copy; 2020 Caer, Inc. All Rights Reserved
        </Detail>
        <Detail css="text-align: left; font-size: 14px;">
          <Link css="color: white; text-decoration: none;" to="/terms">
            Terms and Privacy
          </Link>
          <Link
            css="font-size: 14px; margin-left: 2em; color: white; text-decoration: none;"
            to="/terms"
          >
            Accessibility
          </Link>
        </Detail>
      </Box>
    </Box>
  );
};

const DetailLink = ({ to, href, children }) => {
  if (to)
    return (
      <Link to={to} css="text-decoration: none; color: white;">
        <Detail css="font-size: 16px;">{children}</Detail>
      </Link>
    );
  if (href)
    return (
      <HyperLink href={href} css="text-decoration: none; color:white;">
        <Detail css="font-size: 16px;">{children}</Detail>
      </HyperLink>
    );
};

const Column = styled(Box)`
  align-items: flex-start;
`;
