/* eslint-disable */
import React, { useState, useEffect, useReducer, useRef } from "react";
import _ from "lodash";
import spacetime from "spacetime";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import { getSpecificMenuService } from "../../../../services/menu/getSpecificMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { formatDate, formatDateWithDayOfWeek } from "../../../../utils/dates";
import EditMenuContainer from "./components/MenuContainer";

import {
  editMenuReducer,
  defaultState,
  setInitialStateAction,
  addToOrderAction,
  decrementItemAction,
  removeAllAction,
  incrementItemAction
} from "./editMenuReducer";
import { updateOrderAttributes } from "../../../../usecases/orders";
import { getOrderByIdService } from "../../../../services/orders";
import { getSpecialMenus } from "../../../../../app/usecases/checkout";
import { useRequest } from "../../../../../app/pages/NewAccountPage/hooks/useFetch";
import { getUser } from "../../../../../app/usecases/users";

const updateOrder = async (orderId, cart) => {
  const attributes = cart.snacks.concat(cart.items).map(item => ({
    menu_id: item.menu_id,
    quantity: item.quantity,
    meal_id: item.meal_id
  }));
  const special_menu_id = null;

  try {
    const update = await updateOrderAttributes(orderId, {
      attributes,
      special_menu_id
    });
    return update;
  } catch (err) {
    throw Error(`There was an issue updating order ${orderId}`, err);
  }
};

export function EditOrdersPage({ user }) {
  const isMounted = useRef(true);
  const [loading, setLoaded] = useState(true);
  const [menu, setMenu] = useState(null);
  const [date, setDate] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [cart, cartDispatch] = useReducer(editMenuReducer, defaultState);
  const [fixed, setFixed] = useState(false);
  const [saved, setSave] = useState(false);
  const [specialMenu, setSpecialMenu] = useState({});

  const handleSettingState = (orderId, orderDate, specificMenu, state) => {
    setOrderId(orderId);
    setDate(orderDate);
    setMenu(mapMenuAttributes(specificMenu));
    cartDispatch(setInitialStateAction(state));
    setLoaded(false);
  };

  const updateOrderHandler = async (orderId, cart) => {
    const update = await updateOrder(orderId, cart);
    if (update !== undefined) {
      setSave(true);
    }
  };

  const fetchSpecialMenu = async ({ delivery_date, meal_plan_id }) => {
    const specialMenuURL = (age, name) => {
      if (age === 0) return `https://email.helloyumi.com/assets/sm-${name}.png`;

      if (age === 5)
        return `https://email.helloyumi.com/assets/sm-Starters Only.png`;

      if (age < 9) return `https://email.helloyumi.com/assets/sm-6-8.png`;

      if (age >= 9) return `https://email.helloyumi.com/assets/sm-9-plus.png`;
    };

    const specialMenuText = (age, name) => {
      if (name === "Starters Only")
        return "Great for babes who want a little of everything. Get one jar of each Stage 1 meals.";

      if (age > 0)
        return "Babies grow at such an accelerated rate that’s it’s common to develop nutrient deficiencies. We select blends based on your baby’s age.";

      return "Great for babes who want a little of everything. Get a variety of stage 2 and 3 blends.";
    };

    try {
      const birthday = _.get(await getUser(user), "children[0].birthday");

      const specialMenuResponse = await getSpecialMenus(
        formatDate(delivery_date),
        spacetime(birthday).format("yyyy-mm-dd"),
        // selectedPlan.planId;
        meal_plan_id
      );
      setSpecialMenu(specialMenuResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMenu = async () => {
    const { order: orderParam, date: dateParam } = queryString.parse(
      window.location.search
    );

    const [menuResponse, orderResponse] = await Promise.all([
      getSpecificMenuService(dateParam),
      getOrderByIdService(orderParam)
    ]);
    console.log("***orderResponse: ", orderResponse);

    fetchSpecialMenu(orderResponse);
    if (orderResponse.user_id !== user) {
      throw Error("User not authorized.");
    }
    handleSettingState(
      orderParam,
      dateParam,
      menuResponse,
      createInitialState(orderResponse, menuResponse)
    );
  };

  useEffect(() => {
    isMounted.current = true;
    if (loading && isMounted.current) {
      fetchMenu();
    }

    document.addEventListener("scroll", () => {
      setFixed(window.scrollY > 50 || window.innerWidth < 1025);
    });

    return () => {
      isMounted.current = false;
      document.removeEventListener("scroll");
    };
  }, []);

  const createInitialState = (orderResponse, menuResponse) => {
    const items = [];
    let size = 0;
    for (const item of orderResponse.orderAttributes) {
      // const { menu_id, quantity, meal_id, meal_name } = item;
      // items.push({ menu_id, quantity: 0, meal_id, meal_name });
      // size += quantity;
    }
    const getQuantity = meal_id => {
      const item = items.find(i => i.meal_id === meal_id);
      return item ? item.quantity : 0;
    };

    return {
      totalItems: size,
      items,
      meal_plan: orderResponse.meal_plan_id,
      menu: menuResponse.sort(
        (item1, item2) =>
          getQuantity(item2.meal_id) - getQuantity(item1.meal_id)
      )
    };
  };

  const mapMenuAttributes = menu => {
    if (menu.length > 0) {
      return menu.map(item => {
        return {
          meal_id: item.meal_id,
          meal_name: item.meal_name,
          ingredients: item.ingredients,
          menu_id: item.id,
          stage: item.stage || 1,
          stageImg: `https://email.helloyumi.com/assets/stage${item.stage ||
            1}.svg`,
          img: `https://email.helloyumi.com/assets/0${item.meal_id}`
        };
      });
    }
  };

  return loading ? null : (
    <div>
      <Header
        date={formatDateWithDayOfWeek(date)}
        updateOrder={updateOrderHandler}
        orderId={orderId}
        cart={cart}
        fixed={fixed}
        dispatch={cartDispatch}
        removeAll={removeAllAction}
      />
      <EditMenuContainer
        menu={menu}
        cart={cart}
        dispatch={cartDispatch}
        addToOrder={addToOrderAction}
        incrementItem={incrementItemAction}
        decrementItem={decrementItemAction}
        fixed={fixed}
      />
      <Footer
        updateOrder={updateOrderHandler}
        orderId={orderId}
        cart={cart}
        fixed={fixed}
      />
      {saved ? <Redirect to="/orders" /> : null}
    </div>
  );
}
