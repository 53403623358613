export default {
event: (event, attr) => {
  if (window.dataLayer) {
    window.dataLayer.push({ event, eventAttr: attr, ...attr });
  }
},
push: (data) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
}
};
