import "whatwg-fetch";
import URL from "url";
import _ from "lodash";
import { store } from "../redux/store";
import { logoutSessionExpired } from "../redux/actions/Auth";

const credentials =
  (process.env.NODE_ENV && process.env.NODE_ENV !== "production") ||
  (process.env.NODE_ENV && process.env.NODE_ENV !== "staging")
    ? "same-origin"
    : "include";

const getOptions = (method, body) => {
  const baseOptions = {
    method,
    credentials,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
      Authorization: window.localStorage.getItem("feathers-jwt"),
    },
  };
  if (body) return { ...baseOptions, body: JSON.stringify(body) };
  return baseOptions;
};

const getPlainOptions = (method, body) => {
  const baseOptions = {
    method,
    credentials,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (body) return { ...baseOptions, body: JSON.stringify(body) };
  return baseOptions;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Stores returned JWT token into localstorage
 *
 * @param  {object} response The parsed JSON from the request
 *
 * @return {object}          The parsed JSON from the request
 */
function storeToken(response) {
  if (response.hasOwnProperty("accessToken")) {
    window.localStorage.setItem("feathers-jwt", response.accessToken);
  }
  return response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  // check if response is html
  if (response[0] === "<") {
    const error = new Error("An Error Occurred");
    error.response = response;
    throw error;
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 403 && window.location.pathname !== "/checkout") {
    store.dispatch(logoutSessionExpired());
    // prevents error occurring in response.json() above
    const error = new Error("Session Expired");
    error.response = response;
    throw error;
  }

  const error = new Error(_.get(response, "statusText", "An Error Occurred"));
  error.response = response;
  return response.json().then((r) => {
    error.details = r.details;
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const request = (url, options = getOptions("GET")) =>
  fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);

export const plainRequest = (url, options = getPlainOptions("GET")) =>
  fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);

/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} url       The URL we want to post onto
 * @param  {object} [body] The body we want to post
 *
 * @return {object}           The response data
 */
export const post = async (pathname, body) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_PATHNAME + pathname,
  });
  const options = getOptions("POST", body);
  return fetch(uri, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const postV2 = async (pathname, body) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_FEATHERS_PATHNAME + pathname,
  });
  const options = getOptions("POST", body);
  return fetch(uri, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const plainPost = async (uri, body) => {
  const options = getPlainOptions("POST", body);
  return fetch(uri, options)
    .then(checkStatus)
    .then(parseJSON);
};
/**
 * Register to a URL, returning a promise
 *
 * @param  {string} url       The URL we want to post onto
 * @param  {object} [body] The body we want to post
 *
 * @return {object}           The response data
 */
export const register = async (pathname, body) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_PATHNAME + pathname,
  });

  const options = getOptions("POST", body);
  return fetch(uri, options)
    .then(parseJSON)
    .then(storeToken);
};

/**
 * Puts to a URL, returning a promise
 *
 * @param  {string} url       The URL we want to post onto
 * @param  {object} [body] The body we want to post
 *
 * @return {object}           The response data
 */
export const put = async (pathname, body) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_PATHNAME + pathname,
  });

  const options = getOptions("PUT", body);
  try {
    return fetch(uri, options)
      .then(checkStatus)
      .then(parseJSON);
  } catch (error) {
    throw error;
  }
};

export const httpDelete = async (pathname, body) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_PATHNAME + pathname,
  });

  const options = getOptions("DELETE", body);
  try {
    return fetch(uri, options)
      .then(checkStatus)
      .then(parseJSON);
  } catch (error) {
    throw error;
  }
};

export const requestFormatted = (path, options) => {
  const uri = URL.format({
    protocol: process.env.NODE_APP_PROTOCOL,
    hostname: process.env.NODE_APP_HOSTNAME,
    port: process.env.NODE_APP_PORT,
    pathname: process.env.NODE_APP_PATHNAME + path,
  });

  if (!options) {
    options = {
      method: "GET",
      credentials,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
        Authorization: window.localStorage.getItem("feathers-jwt"),
      },
    };
  }

  return fetch(uri, options)
    .then(checkStatus)
    .then(parseJSON);
};

export const requestFormattedNew = (url, options) => {
  if (!options) {
    options = {
      method: "GET",
      credentials,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "*",
        Authorization: JSON.parse(window.localStorage.getItem("accessToken")),
      },
    };
  }

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
};
