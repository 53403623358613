import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Link } from "react-router-dom";
import { texasOrange, BlueButton } from "../../styledcomponents";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/_HeavyMetals/${name}${extension}`;

export const YumiLogo = getImageURL("YumiLogo", ".svg");
export const Carrot = getImageURL("TiltedCarrot", ".svg");

// 95 percent
export const NinetyFivePercent = getImageURL("95Percent", ".svg");

// Carrots
export const Farmer = getImageURL("lettuce_farmerhand", ".gif");

// How they get inside
export const Soil = getImageURL("Farm", ".svg");
export const Grill = getImageURL("Grill", ".svg");

// How we're different
export const BellPepperThree = getImageURL("BellPepperThree", ".jpeg");

// A Guide
export const SweetPotato = getImageURL("sweet-potato", ".svg");
export const Broccoli = getImageURL("Brocc", ".svg");
export const Soy = getImageURL("Soy", ".svg");
export const Rice = getImageURL("rice", ".svg");
export const Oats = getImageURL("bred", ".svg");

export const Blueberry = getImageURL("Blueberry", ".svg");
export const FruitJuice = getImageURL("Fruit Juice", ".svg");

export const Lead = getImageURL("Lead", ".png");
export const Arsenic = getImageURL("Arsenic", ".png");
export const Cadmium = getImageURL("Cadmium", ".png");
export const LeadPPM = getImageURL("LeadPPM", ".png");
export const ArsenicPPM = getImageURL("ArsenicPPM", ".png");
export const CadmiumPPM = getImageURL("CadmiumPPM", ".png");

// Fresh made weekly
export const BellPepperTwo = getImageURL("BellPepperTwo", ".jpeg");

export const OrangeLinkButton = ({ fixed = false, value, to, left = "" }) => (
  <Link to={to} css="text-decoration:none;">
    <OrangeButton
      fixed={fixed}
      css={css`
        padding-left: ${left};
      `}
      value={value}
    />
  </Link>
);

const OrangeButton = styled(BlueButton)`
  background: ${texasOrange};
  color: white;
  box-shadow: 0px 2px 4px #eee;
  padding: 20px;
  font-size: 14pt;
  width: 300px;
  position: ${({ fixed }) => fixed && "fixed"};
  bottom: ${({ fixed }) => fixed && "0"};
  margin-top: 20px;
  z-index: ${({ fixed }) => fixed && "100"};

  @media (max-width: 500px) {
    font-size: 11pt;
    width: ${({ fixed }) => (fixed ? "100%" : "200px")};
    margin-top: 0px;
    height: ${({ fixed }) => fixed && "80px"};
    font-size: ${({ fixed }) => fixed && "20px"};
    padding: 15px;
  }
`;
