/**
 * @function mapOrderAttributes
 * @param {Array} - orderData
 */
export const mapOrderAttributes = orderData => {
  if (orderData.length > 0) {
    return orderData.map(orderItem => ({
      menu_id: orderItem.menu_id,
      meal_id: orderItem.meal_id,
      meal_name: orderItem.meal_name,
      quantity: orderItem.quantity,
      ingredients: orderItem.ingredients,
      desc: "hehe",
      img:
        orderItem.meal_id > 0 &&
        ![1521, 1522, 1523, 1524].includes(orderItem.meal_id)
          ? `https://email.helloyumi.com/assets/0${orderItem.meal_id}`
          : ""
    }));
  }
  return [];
};
