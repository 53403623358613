import React, { useContext } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import {
  Border,
  SideBorder,
  SmallLogo,
  PinkGift,
  Mistletoe,
  RoundButton
} from "../assets";
import { H4, H3 } from "../../../styledcomponents/typefaces";
import _ from "lodash";
import { createChristmasCard } from "../../../usecases";

export const ChristmasCard = ({ ChristmasContext }) => {
  const CardContext = useContext(ChristmasContext);

  const isFormFull = _.every(CardContext.state.form);

  const submitForm = async () => {
    if (!isFormFull) {
      return CardContext.setState({
        error: "Make sure to complete your letter!"
      });
    }
    const card = await createChristmasCard(CardContext.state.form);

    if (!card || card === undefined)
      return CardContext.setState({
        error: "There was a problem processing your card. Please try again."
      });
    return CardContext.setState({
      pageIndex: 2
    });
  };

  return (
    <ChristmasContainer>
      <img
        src={Border}
        alt="border"
        css={css`
          top: 0px;
          position: absolute;

          @media (min-width: 500px) {
            top: 0;
          }
        `}
      />
      <img
        src={Border}
        alt="border"
        css={css`
          display: none;
          @media (max-width: 375px) {
            position: absolute;
            display: flex;
            bottom: 0;
          }
        `}
      />
      <img
        src={SideBorder}
        alt="side-border"
        css={css`
        
display:none;
        @media (max-width: 375px) {
           top: 0;
        position: absolute;
        z-index;0;
        display:flex;
          }
      `}
      />
      <img
        src={SideBorder}
        alt="side-border"
        css={css`
          display: none;

          @media (max-width: 375px) {
            top: 0;
            position: absolute;
            right: 0;
            display: flex;
            z-index: 0;
          }
        `}
      />
      <img
        src={SideBorder}
        alt="side-border"
        css={css`
          display: none;

          @media (max-width: 375px) {
            top: 355px;
            position: absolute;
            right: 0;
            z-index: 0;
            display: flex;
          }
        `}
      />{" "}
      <img
        src={SideBorder}
        alt="side-border"
        css={css`
          display: none;

          @media (max-width: 375px) {
            top: 355px;
            position: absolute;
            left: 0;
            z-index: 0;
            display: flex;
          }
        `}
      />
      <BodySection>
        <ImageRow>
          <img
            src={SmallLogo}
            alt="small"
            css={css`
              width: 99px;
              margin-top: 30px;
            `}
          />
          <img
            src={PinkGift}
            alt="pink"
            css={css`
              position: absolute;
              right: 20px;

              @media (min-width: 500px) {
                position: relative;
                right: -20px;
              }
            `}
          />
        </ImageRow>
        <LetterSection>
          Dear Yumi, <br />I'd like to nominate{" "}
          <InlineInput
            css={css`
              width: 170px;
            `}
            placeholder="name of parent"
            value={CardContext.state.form.parentName}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  parentName: e.target.value
                }
              })
            }
          />{" "}
          because they need a little TLC this holiday season. Specifically,
          <InlineInput
            css={css`
              width: 100%;
            `}
            placeholder="tell us why they need help."
            value={CardContext.state.form.whyTheyNeedHelp}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  whyTheyNeedHelp: e.target.value
                }
              })
            }
          />{" "}
          <br /> Yumi could help their family by{" "}
          <InlineInputLong
            css={css`
              width: 250px;
              height: 150px;
            `}
            placeholder="an idea that would make the holidays a little more enjoyable."
            value={CardContext.state.form.anIdea}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  anIdea: e.target.value
                }
              })
            }
          />
          <br /> They live in{" "}
          <InlineInput
            value={CardContext.state.form.city}
            placeholder="city"
            css={css`
              width: 170px;
            `}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  city: e.target.value
                }
              })
            }
          />,{" "}
          <InlineInput
            value={CardContext.state.form.state}
            placeholder="state"
            css={css`
              width: 100px;
            `}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  state: e.target.value
                }
              })
            }
          />.
          <br /> You can reach me at{" "}
          <InlineInput
            value={CardContext.state.form.email}
            placeholder="your email"
            css={css`
              width: 180px;
            `}
            onChange={e =>
              CardContext.setState({
                form: {
                  ...CardContext.state.form,
                  email: e.target.value
                }
              })
            }
          />{" "}
          to make magic happen.
        </LetterSection>
        <NameSection>
          <img src={Mistletoe} alt="mistle" />
          <Input>
            <H3
              css={css`
                color: #75403e;
              `}
            >
              XO
            </H3>
            <InlineInput
              css={css`
                font-size: 22px;
                width: 140px;
              `}
              placeholder="your name"
              value={CardContext.state.form.name}
              onChange={e =>
                CardContext.setState({
                  form: {
                    ...CardContext.state.form,
                    name: e.target.value
                  }
                })
              }
            />
          </Input>
        </NameSection>
        <Error>{CardContext.state.error}</Error>
        {isFormFull}
        <RoundButton
          css={css`
            background: #c47084;
            color: white;
            width: 248px;
            height: 56px;
            margin-bottom: 32px;
          `}
          value="Submit Nomination"
          onClick={() => submitForm()}
        />
      </BodySection>
    </ChristmasContainer>
  );
};

const Error = styled(H4)`
  color: red;
  font-size: 14px !important;
  padding: none;
`;

const Input = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`;
const NameSection = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  margin-bottom: 40px;
`;
const ImageRow = styled.div`
  display: flex;
`;
const BodySection = styled.div`
  display: flex;
  padding: 30px;
  align-items: center;
  flex-direction: column;
`;

const LetterSection = styled(H4)`
  font-size: 22px !important;
  padding-top: 40px;
  line-height: 2.2em !important;
  color: #75403e;
  width: 95%;
`;
const InlineInput = styled.input`
  color: #c47084;
  font-family: Platform-Regular !important;

  border-bottom: 1px dashed #c47084;
`;
const InlineInputLong = styled.textarea`
  color: #c47084;
  font-family: Platform-Regular !important;

  border-bottom: 1px dashed #c47084;
`;
const ChristmasContainer = styled.div`
  width: 375px;
  background: #fdebe0;
  @media (min-width: 500px) {
    box-shadow: 1px 2px 10px lightgray;
    margin-bottom: 40px;
  }
`;
