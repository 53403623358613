import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import { Body, H4 } from "../../../styledcomponents/typefaces";
import { YumiLogo, Building, PinkSlice, RoundButton } from "../assets";
import { Link } from "react-router-dom";
import { blackberry, blueberry } from "../../../styledcomponents";

export const ThankYou = ({ ChristmasContext }) => (
  <ThankYouContainer>
    <TopColumn>
      <img
        src={PinkSlice}
        alt="pink"
        css={css`
          width: 375px;
          position: relative;
          margin-top: -80px;
          @media (max-width: 1000px) {
            margin-top: -213px;
          }
          @media (max-width: 500px) {
            position: absolute;
            top: 0;
            margin-top: unset;
          }
        `}
      />
      <img
        src={YumiLogo}
        alt="yumi"
        css={css`
          margin-bottom: 20px;
          margin-top: 92px;

          @media (min-width: 500px) {
            margin-top: 40px;
          }
        `}
      />
      <H4
        css={css`
          text-align: center;
          color: white;
          font-size: 28px !important;
          line-height: 1.1em !important;
          margin-top: 30px;
        `}
      >
        Thanks for your nomination!
      </H4>
      <Body
        css={css`
          text-align: center;
          font-size: 24px !important;
          line-height: 1.4em !important;
          margin-bottom: 20px;
          color: white;
          margin-top: 15px;
        `}
      >
        Our elves will be in touch if your nominee is selected.
      </Body>{" "}
      <Body
        css={css`
          color: white;
        `}
      >
        <Link
          css={css`
            color: black;
            text-decoration: none;
          `}
          to="/"
        >
          <RoundButton
            css={css`
              background: white;
              height: 50px;
              width: 200px;
              font-size: 14px !important;
            `}
            value="
          Learn more about Yumi"
          />
        </Link>
      </Body>
    </TopColumn>
    <img
      src={Building}
      alt="build"
      css={css`
        margin-top: 60px;
      `}
    />
  </ThankYouContainer>
);

const TopColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  width: 86%;
  height: 250px;
  justify-content: space-evenly;

  @media (max-width: 1000px) {
    margin-top: 200px;
  }

  @media (max-width: 500px) {
    margin-top: 85px;
  }
  @media (min-width: 1000px) {
    margin-top: 115px;
  }
`;

const ThankYouContainer = styled.div`
  width: 375px;
  background: #c47084;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 500px) {
    box-shadow: 1px 2px 7px lightgray;
  }
`;
