import React from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";

import { H3 } from "../../../styledcomponents/typefaces";

export const FutureCollabs = ({ width }) => (
  <FutureCollabsContainer>
    <CloudRow>
      <H3
        style={{
          opacity: "none",
          order: "0"
        }}
      >
        Stay updated for Future Collabs
      </H3>
    </CloudRow>
    <CloudForm>
      <iframe
        title="klaviyo email"
        src="https://email.helloyumi.com/assets/chef-series/julia-sherman/klaviyo.html"
        css="border: none; width:100%; padding: 0; margin-bottom: 0; height: 90px; z-index:10;"
      />
    </CloudForm>
  </FutureCollabsContainer>
);

const CloudForm = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 600px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    padding-bottom: 10px;
    width: 300px !important;
  }
`;
const CloudRow = styled.div`
  display: flex;

  width: 800px;
  align-items: center;
  justify-content: center;
  height: 100px;
  @media (max-width: 1000px) {
    width: 400px !important;
  }
`;

const FutureCollabsContainer = styled.div`
  background: #b5c7aa;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
  }
`;
