import React from "react";
import styled from "styled-components";
import { FruitBannerImage } from "../assets";

export const FruitBanner = () => (
  <PageLayout>
    <img
      src={FruitBannerImage}
      alt="fruit"
      css="@media(max-width:1000px){width:800px}@media(max-width:500px){width:800px}"
    />
  </PageLayout>
);

const PageLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background: #fefefe;
  overflow: hidden;
`;
