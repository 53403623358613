import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ProfileIcon from "../../../../assets/icons/Dashboard-icons/profile-icon.svg";
import OrdersIcon from "../../../../assets/icons/Dashboard-icons/orders-icon.svg";
import HomeIcon from "../../../../assets/icons/Dashboard-icons/home-icon.svg";

const checkActive = (match) => (_, location) =>
  location.pathname.toLowerCase().includes(match);
export const ProfileNavBar = () => (
  <ProfileNav>
    <NavItem
      activeClassName="active"
      isActive={checkActive("settings")}
      to="/profile/settings"
    >
      Settings
    </NavItem>
  </ProfileNav>
);

export const MainNavBar = () => (
  <MainNav>
    <MainNavItem isActive={checkActive("not yet!")} to="">
      <Icon src={HomeIcon} alt="home" /> Home
    </MainNavItem>
    <MainNavItem to="/orders">
      <Icon src={OrdersIcon} alt="orders" />
      Orders
    </MainNavItem>
    <MainNavItem isActive={checkActive("profile")} to="/profile/settings">
      <Icon src={ProfileIcon} alt="profile" />
      Settings
    </MainNavItem>
  </MainNav>
);

const MainNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 50px;
  align-items: center;
  height: 50px;
  width: 100%;

  @media (max-width: 1000px) {
    position: fixed;
    bottom: 0;
    padding-right: 0px;
    background: white;
    width: 100%;
    justify-content: center;
    z-index: 100;
  }
`;
const MainNavItem = styled(NavLink)`
  font-family: "Platform-medium", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  margin-left: 16px;
  opacity: 0.4;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    opacity: 1;
  }

  @media (max-width: 1000px) {
    text-align: center;
    width: 33%;
    font-size: 12px;
    font-family: Avenir;
    text-transform: capitalize;
    margin-left: 0px;
  }
`;
const Icon = styled.img`
  height: 24px;
  display: block;
  margin: auto;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const ProfileNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  height: 50px;
  align-items: center;
  font-family: "Platform-Medium", sans-serif;
  font-size: 16px;
  @media (max-width: 1000px) {
    height: 32px;
    font-size: 12px;
  }
`;

const NavItem = styled(NavLink)`
  font-family: "Platform-Medium", sans-serif;
  color: grey;
  text-decoration: none;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  &.active {
    color: black;
    border-bottom: 3px solid black;
    text-decoration: none;
  }
`;
