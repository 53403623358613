import React from "react";
import * as Sentry from "@sentry/browser";
import NavigationBar from "../../components/NavigationBar";

class SentryBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      if (
        !window.location.pathname.includes("account", 0) &&
        !window.location.pathname.includes("profile", 0)
      ) {
        window.localStorage.clear();
      }

      // render fallback UI
      return (
        <body
          style={{
            height: "100vh",
            width: "100vw"
          }}
          css="
          h3 {font-size: 18pt;
          opacity: .6;
          margin-top: 0}"
        >
          <main>
            <NavigationBar history={this.props.history} hide={false} />
            <div
              className="content"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100vh",
                fontFamily: "avenir, sans-serif"
              }}
            >
              <h1
                style={{
                  fontSize: "40pt",
                  margin: "10px 0"
                }}
              >
                404
              </h1>
              <h2
                style={{
                  fontSize: "20pt",
                  opacity: ".6",
                  margin: "10px 0"
                }}
              >
                You Look Lost . . .
              </h2>
              <div
                className="emoji"
                style={{
                  margin: "10px 0 0 0",
                  fontSize: "100px"
                }}
              >
                🔦
              </div>
              <h3
                style={{
                  fontSize: "18pt",
                  opacity: ".6",
                  margin: "10px 0"
                }}
              >
                Want to go{" "}
                <button
                  type="button"
                  style={{
                    textDecoration: "underline",
                    fontSize: "18pt",
                    opacity: ".6",
                    marginTop: "0",
                    cursor: "pointer",
                    fontFamily: "avenir, sans-serif",
                    fontWeight: "600"
                  }}
                  onClick={() => window.location.reload()}
                >
                  back
                </button>?
              </h3>
            </div>
          </main>
        </body>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryBoundary;
