import React from "react";
import {
  injectStripe,
  PaymentRequestButtonElement
} from "react-stripe-elements";
import styled from "styled-components";

class ApplePayStripeForm extends React.Component {
  state = { canMakePayment: false };

  mounted = false;

  componentDidMount() {
    this.mounted = true;
    const { stripe, amount, setStripeData, shelf } = this.props;
    const paymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label:
          "This is just to authorize your card. You won't be billed right away.",
        amount: Math.floor(amount * 100)
      },
      requestPayerName: true
    });
    paymentRequest.on("token", ({ complete, ...data }) => {
      setStripeData(data);
      complete("success");
    });
    paymentRequest.canMakePayment().then(result => {
      if (!this.mounted) return;
      this.setState({
        canMakePayment: !!result,
        paymentRequest
      });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { canMakePayment, paymentRequest, shelf } = this.state;
    return canMakePayment ? (
      <React.Fragment>
        <PaymentContainer shelf={shelf}>
          <Text
            style={{
              margin: "0 0 20px 0",
              display: "block",
              textAlign: "center",
              color:"#658FCA"
            }}
          >
            Express Checkout{" "}
          </Text>
          <PaymentRequestButtonElement
            paymentRequest={paymentRequest}
            className="PaymentRequestButton"
            style={{
              margin: "1em",
              paymentRequestButton: {
                theme: "dark",
                height: shelf ? "20px" : "64px"
              }
            }}
          />
        </PaymentContainer>
        <Text
          style={{
            fontSize: shelf ? "14px" : "24px",
            marginTop: "25px",
            marginBottom: "25px"
          }}
        >
          Or
        </Text>
      </React.Fragment>
    ) : null;
  }
}

const PaymentContainer = styled.div`
  width: ${({ shelf }) => (shelf ? "200px" : "100%")}
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 16px 10px;
  border-radius: 8px;
`;

const Text = styled.span`
  font-family: "Avenir-Medium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  text-align: center;
`;

export default injectStripe(
  ({ applePayMessage, ...props }) =>
    applePayMessage ? (
      <ApplePayStripeForm applePayMessage={applePayMessage} {...props} />
    ) : null
);
