import "styled-components/macro";

const getImageURL = (name, extension = ".png") =>
  `https://email.helloyumi.com/assets/BLW/${name}${extension}`;

export const BLWKiwi = getImageURL("BLWKiwi", ".svg");

export const BabyHand = getImageURL("BabyHandPizza", ".png");

export const Banana = getImageURL("Banana", ".svg");

export const PizzaBites = getImageURL("PizzaBites", ".jpeg");

export const TotBoxGrab = getImageURL("TotBoxGrab", ".jpeg");

export const TrayOfBites = getImageURL("TrayOfBites", ".png");

export const WhitePuffs = getImageURL("WhitePuffs", ".png");

export const ColorfulJar = getImageURL("ColorfulJar", ".svg");

export const FruitBannerImage = getImageURL("FruitBanner", ".svg");
export const GreenCheck = getImageURL("Check", ".svg");
