const TOOLTIP = "TOOLTIP";
const SKIP = "SKIP";
const EDIT = "EDIT";
const UNSKIP = "UNSKIP";
const GET_MORE_OPTIONS = "GET_MORE_OPTIONS";
const FILLED = "FILLED";
const NOT_FILLED = "NOT_FILLED";
const RATE_UP = "RATE_UP";
const RATE_DOWN = "RATE_DOWN";

export const BUTTON_STYLES = {
  FILLED,
  NOT_FILLED
};

export const BUTTON_ACTIONS = {
  TOOLTIP,
  SKIP,
  EDIT,
  UNSKIP,
  GET_MORE_OPTIONS,
  RATE_UP,
  RATE_DOWN
};

export const UNEDITABLE_BUTTON_GROUP = {
  type: "UNEDITABLE",
  buttons: [
    {
      text: "Why can't I edit my jars?",
      desc:
        "Shows tooltip on hover explaining that order is too close to delivery date to edit",
      action: TOOLTIP,
      style: NOT_FILLED,
      img: null
    }
  ]
};

export const SKIPPED_BUTTON_GROUP = {
  type: "SKIPPED",
  buttons: [
    {
      text: "Unskip",
      desc: "Allows a skipped order to be unskipped",
      action: UNSKIP,
      style: FILLED,
      img: null
    }
  ]
};

export const EDITABLE_BUTTON_GROUP = {
  type: "EDITABLE",
  buttons: [
    {
      text: "Edit jars",
      desc: "Opens screen to edit order",
      action: EDIT,
      style: NOT_FILLED,
      img: null
    },
    {
      text: "Skip",
      desc: "Allows user to skip that weeks order",
      action: SKIP,
      style: NOT_FILLED,
      img: null
    },
    {
      text: "More",
      desc: "Opens more options modal",
      action: GET_MORE_OPTIONS,
      style: NOT_FILLED,
      img: null
    }
  ]
};

export const RATE_BUTTON_GROUP = {
  type: "RATE",
  buttons: [
    {
      text: null,
      desc: "Rate thumbs up",
      action: RATE_UP,
      style: FILLED,
      img: null
    },
    {
      text: null,
      desc: "Rate thumbs down",
      action: RATE_DOWN,
      style: FILLED,
      img: null
    }
  ]
};
