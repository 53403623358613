import React, { useContext } from "react";
import styled, { css } from "styled-components";
import "styled-components/macro";
import {
  YumiLogo,
  MobileSnowman,
  MobileGinger,
  MobilePenguin,
  RoundButton,
  BlueSlice
} from "../assets";
import { H4, Body } from "../../../styledcomponents/typefaces";
import { BlueButton, blackberry } from "../../../styledcomponents";

export const Introduction = ({ ChristmasContext }) => {
  const IntroContext = useContext(ChristmasContext);
  return (
    <IntroductionContainer>
      <TopColumn>
        <img
          src={BlueSlice}
          alt="pink"
          css={css`
            width: 375px;
            position: relative;
            margin-top: -80px;
            @media (max-width: 500px) {
              position: absolute;
              top: 0;
              margin-top: unset;
            }
          `}
        />
        <img
          src={YumiLogo}
          alt="yumi"
          css={css`
            margin-bottom: 30px;
            margin-top: 30px;
          `}
        />
        <H4
          css={css`
            text-align: center;
            color: white;
            font-size: 22px !important;
            line-height: 1.1em !important;
            font-family: Platform-Regular;
          `}
        >
          Nominate a parent who could use some holiday cheer and our elves will
          make magic.
        </H4>
        <Body
          css={css`
            text-align: center;
            font-size: 18px !important;
            line-height: 1.4em !important;
            margin-bottom: 20px;
            margin-top: 10px;
          `}
        >
          We believe all parents deserve extra help, because as we all know it
          literally takes a village. <br />
          <br />To celebrate the holidays, we want to bring some joy and cheer
          to families who could use a little extra love this season.
        </Body>
        <RoundButton
          css={css`
            background: ${blackberry};
            height: 69px;
            color: White;
            font-family: Platform-Regular;
            font-size: 18px !important;
            width: 230px;
          `}
          value="Nominate a parent"
          onClick={() =>
            IntroContext.setState({ ...IntroContext.state, pageIndex: 1 })
          }
        />
      </TopColumn>
      <BottomImageRow>
        <img
          src={MobileSnowman}
          alt="snow"
          css={css`
            left: 0;
            position: absolute;

            @media (min-width: 500px) {
              left: 0px;
              position: relative;
            }
          `}
        />
        <img
          src={MobileGinger}
          alt="ging"
          css={css`
            margin-top: 70px;
            position: relative;
          `}
        />
        <img
          src={MobilePenguin}
          alt="penguin"
          css={css`
            right: 0;
            position: absolute;
            @media (min-width: 500px) {
              right: 0px;
              position: relative;
            }
          `}
        />
      </BottomImageRow>
    </IntroductionContainer>
  );
};

const TopColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  width: 88%;
  height: 520px;
  justify-content: space-evenly;

  @media (min-width: 1000px) {
    margin-top: 70px;
  }
`;
const BottomImageRow = styled.div`
  display: flex;
  margin-bottom: 0px;
  width: 375px;
  justify-content: center;
`;
const IntroductionContainer = styled.div`
  width: 375px;
  background: #8db9db;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    box-shadow: 1px 2px 7px lightgray;
  }
`;
