import React, {Fragment, useEffect} from "react";
import styled from "styled-components";
import {H2} from '@team-yumi/ui'
import { useRedirect, useRequest } from "../../../hooks";
import { getBundle } from "../../../usecases/bundle";

export const StepFour = ({selectedPlan, planState, setPlanState, setMaxStep, trackEvent}) => {

  const {count, planId} = selectedPlan
  const {billingType, deliveries: curDeliveries, billingPeriod} = planState
  const [routeToStepFive, shouldRedirect] = useRedirect("/checkout_new/5");

  const [bundles] = useRequest( () => getBundle(planId))

  useEffect(() => {
    trackEvent("CheckoutStepFourLoaded", {step: 'bundle'});
  }, [])
  const bundleSelected = (deliveries, amount) => {
    setPlanState({
      deliveries,
      bundleAmount: amount,
      billingType: 'bundle'
    })
  }

  const subscriptionSelected = (interval) => {
    setPlanState({
      billingPeriod: interval,
      billingType: 'subscription'
    })
  }

  const isItemSelected = (interval, deliveries, type) => {
    if (billingType === 'bundle' && type === 'bundle') {
       return curDeliveries === deliveries
    }

    if (billingType === 'subscription' && type === 'subscription') {
      return interval === billingPeriod
    }

    return false
  }

  const checkout = () => {
    if ((billingType === 'bundle' && curDeliveries > 0 ) || (billingType === 'subscription')) {
      setMaxStep(5);
      trackEvent("CheckoutStepFourCompleted");

      shouldRedirect(true);
    }
  }

  return (
    <Container>
      <H2>Choose how you pay</H2>
      <CountDesc>We make and deliver our food fresh weekly You’re on the <ColorSpan>{count} a day</ColorSpan>, that’s <ColorSpan>{count * 7} jars per delivery</ColorSpan></CountDesc>

      {bundles.length && bundles.filter(({type}) => type ==='bundle').map(({title, descripition, meals, type}) => (
        <Fragment>
          <SmallHeader>{title}</SmallHeader>
          <SmallHeaderDesc>{descripition}</SmallHeaderDesc>

          <OptionCardContainer>
            {meals.map(({deliveries, amount, interval}, index) => (
              <OptionCard key={index} onClick={e => type === 'bundle' ? bundleSelected(deliveries, amount) : subscriptionSelected(interval)}>
                <OptionCardTop type={type}>
                  <OptionCardTopCount>{deliveries}</OptionCardTopCount>
                  <p style={{    fontFamily: "Ginger" }}>{deliveries > 1 ? 'Deliveries' : 'Delivery'} {type === 'bundle' ? '' : (interval === 'weekly' ? 'Per Week': 'Per Month')}</p>
                </OptionCardTop>

                <OptionCardBottom type={type} selected={isItemSelected(interval, deliveries, type)}>
                  <OptionCardBottomAmount>${amount}</OptionCardBottomAmount>
                  <p>{type === 'bundle' ? `Billed Once` : (interval === 'weekly' ? 'Billed Every Week': 'Billed Every Month')}</p>
                </OptionCardBottom>
              </OptionCard>
          ))}
          </OptionCardContainer>
        </Fragment>
      ))}

      <Button className='stepFourCheckoutButton' onClick={checkout}>Checkout</Button>

      {routeToStepFive()}
    </Container>
  );
};


const Container = styled.div`
  font-family: "Platform-Medium";
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 30px 0px 90px 26px;
  width: 100%;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1;
  }
  @media (min-width: 670px) {
    margin-top: 70px;
    width: 85%;
    background-color: white;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Header = styled.header`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: center;
`

const CountDesc = styled.p`
  margin-bottom: 43px;
  font-family: 'Ginger';
  text-align: center;
  margin: 0 auto;
  width: 80%;
   @media (min-width: 670px) {
   width: 450px;
  }
`

const ColorSpan = styled.span`
  color: #658FCA;
`

const SmallHeader = styled.div`
  font-weight: 500;
    font-family: 'Ginger';
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 60px;
  @media (min-width: 670px) {
  text-align: center;
  }
`

const SmallHeaderDesc = styled.div`
  margin-bottom: 50px;
  font-family: 'Ginger';
  @media (min-width: 670px) {
  text-align: center;
  }
`
const OptionCardContainer = styled.div`
  display: flex;
  overflow: auto;
  padding-bottom: 10px;
  
  @media (min-width: 670px) {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
  }
`

const OptionCard = styled.div`
  flex-shrink: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 198px;
  margin-right: 15px;
  color: white;
  cursor: pointer;
   @media (min-width: 670px) {
   justify-self: center;
  }
`

const OptionCardTop = styled.div`
  height: 92px;
  background-color: ${({type}) => type === 'bundle' ? '#658FCA' : '#334897'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
`

const OptionCardTopCount = styled.p`
  font-weight: 500;
  font-size: 50px;
  margin-bottom: 10px;
      font-family: 'Ginger';
`

const OptionCardBottom = styled(OptionCardTop)`
  background-color: ${({selected, type}) => selected ? (type === 'bundle' ? '#658FCA' : '#334897') : 'white'};
  color: ${({selected}) => selected ? 'white' : 'black'};
  height: 100px;
  font-family: 'Ginger';
`

const OptionCardBottomAmount = styled.p`
  font-family: Ginger;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 6px;
`

const Button = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #334897;
  font-size: 20px;
  font-weight: 500;
  color: white;
  outline: 0;
  cursor: pointer;
  
  @media (min-width: 670px) {
     position: initial;
     width: 228px;
     height: 52px;
     margin-top: 60px;
  }
`