import React from "react";
import styled from "styled-components";

export const ByChloeHero =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeHero.png";

export const ByChloeBabe =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeBabe.png";

export const ByChloeBlendsImage =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeBlends.jpeg";

export const SuperSmash =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/SuperSmashJar.png";

export const VIBBurg =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/VIBBurg.png";

export const BurgerMat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeBurgerMat.png";

export const ByChloeBurgerMat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeBurgerMat.png";

export const Mashable =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/Mashable.svg";

export const NYT =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/nyt.svg";

export const goop =
  "https://email.helloyumi.com/assets/chef-series/julia-sherman/goop.svg";

export const Cloud = "https://email.helloyumi.com/assets/chef-series/Cloud.svg";

export const FeedingBaby =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/FeedingBaby.jpeg";

export const SmallJar =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/SmallJar.png";

export const MixedJar =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/MixedJar.png";

export const BigBurger =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BigBurger.png";

export const Tomato =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/Tomato.png";

export const ByChloeBannerDesktop =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/By_Chloe_Banner_Destop.gif";

export const ByChloeBannerMobile =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/By_Chloe_Banner_Mobile.gif";

export const Star = styled.div`
  position: absolute;
  height: 18.67px;
  left: 23.61%;
  right: 75.28%;
  top: 224px;

  background: #000000;
`;

export const ByChloeLanding =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/ByChloeLanding.jpeg";

export const BowlOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BowlOnOat.png";

export const BabyOnOat =
  "https://email.helloyumi.com/assets/chef-series/by-chloe/BabyOnOat.png";
