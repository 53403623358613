import { updateOrderAddress } from "../../usecases/orders";

/*
 * Updates address for that week
 */
const addAddressService = async (orderId, address) => {
  const formattedAddress = address;
  delete formattedAddress.order_id;
  formattedAddress.default = 0;
  if (formattedAddress.street2 === null) {
    formattedAddress.street2 = "undefined";
  }

  const addressUpdate = await updateOrderAddress(orderId, formattedAddress);
  return addressUpdate;
};

export default addAddressService;
