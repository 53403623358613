import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useMergeState, useWindowWidth } from "../../hooks";
import { Introduction, ChristmasCard, ThankYou } from "./stages";

const pages = [Introduction, ChristmasCard, ThankYou];

const ChristmasContext = React.createContext();

const ChristmasProvider = ({ children }) => {
  const { width } = useWindowWidth();
  const [state, setState] = useMergeState({
    error: "",
    pageIndex: 0,
    form: {
      parentName: "",
      whyTheyNeedHelp: "",
      anIdea: "",
      email: "",
      name: "",
      city: "",
      state: ""
    }
  });

  const ActivePage = pages[state.pageIndex];

  return (
    <ChristmasContext.Provider
      value={{
        state,
        setState
      }}
    >
      <ChristmasContainer>
        <ActivePage ChristmasContext={ChristmasContext} width={width} />
      </ChristmasContainer>
    </ChristmasContext.Provider>
  );
};

export const Christmas = () => <ChristmasProvider />;

const ChristmasContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;

  @media (min-width: 500px) {
    padding-bottom: 20px;
  }
`;
