import React from "react";
import styled, { css } from "styled-components";

import {
  texasLightOrange,
  yumiOrange,
  blackberry,
  blueberry
} from "../../../styledcomponents";
import { LazyImage } from "../../../styledcomponents/LazyImage";

import { H1, Body } from "../../../styledcomponents/typefaces";
import "styled-components/macro";

import {
  OrangeLinkButton,
  Variety,
  Starter,
  MobileJar1,
  MobileJar2,
  MobileJar3,
  MobileJar4,
  MobileJar5,
  MobileJar6,
  MobileJar7,
  WinterBG,
  MobileBG
} from "../assets";
import { SimpleSliderHero } from "../../../styledcomponents/SimpleSlider";

const tickerText = ["freshly made.", "nutrient dense.", "delicious.", "here."];
const ReactRotatingText = require("react-rotating-text");

const Organic =
  "https://email.helloyumi.com/assets/_HomePageRefresh/Organic.png";

const Kosher = "https://email.helloyumi.com/assets/_HomePageRefresh/Asset.svg";

export const FutureIsFresh = ({ width }) => (
  <FreshContainer>
    <FreshInner>
      <LazyImage
        src={Variety}
        alt="var"
        css={css`
            position: absolute;
            width: 430px;
            right: 0;
            overflow: hidden;
            top: 130px;
            z-index: -10;
            @media (max-width: 1130px) {
              width: 350px;
            }
            @media (max-width: 1000px) {
              width: 300px;
            }
            @media (max-width: 750px) {
              width: 200px;
              display: none !important;
            }
            @media (max-width: 500px) {
              display: none !important;
            }
          `}
      />
      <LazyImage
        src={Starter}
        alt="star"
        css={css`
            position: absolute;
            z-index: -10;
            top: 30px;
            left: 0;
            @media (min-width: 1500px) {
              top: 230px;
            }
            @media (max-width: 1130px) {
              width: 350px;
            }
            @media (max-width: 1000px) {
              width: 300px;
            }
            @media (max-width: 750px) {
              width: 200px;
              display: none !important;
            }
            @media (max-width: 500px) {
              display: none !important;
            }
          `}
      />
      <MiddleSection>
        <H1
          css={css`
              color: ${texasLightOrange};
              width: 100%;
              font-size: 60px;
              line-height: 60px;
              margin-top: 45px;

              @media (max-width: 1000px) {
                font-size: 50px;
              }
              @media (max-width: 830px) {
                font-size: 44px;
              }
              @media (max-width: 600px) {
                width: 80%;
                margin-top: 0px;
              }
              @media (max-width: 500px) {
                text-align: left;
                font-size: 44px;
                width: 85%;
                line-height: 50px;
              }
            `}
        >
          The future of <br />baby food is{" "}
          {width > 1000 ? (
            <ReactRotatingText
              typingInterval={140}
              css="height:50px"
              items={tickerText}
              color={yumiOrange}
            />
          ) : (
            <span
              css={css`
                  color: ${yumiOrange};
                `}
            >
                freshly made.
              </span>
          )}
        </H1>
        <Body
          css={css`
              padding-top: 20px;
              @media (max-width: 1000px) {
                width: 70%;
              }
              @media (max-width: 500px) {
                font-size: 15px;
                width: 84%;
                text-align: left;
              }
            `}
        >
          Small batch baby food made and delivered weekly. <br />USDA organic
          and kosher certified blends.
        </Body>
        <div
          css="padding-top:33px;@media (max-width: 500px) {
            padding-top:20px;
          }"
        />
        <OrangeLinkButton
          css={css`
              outline: 1px solid red;
              pointer-events: auto;
            `}
          to="/checkout"
          value="GET STARTED"
        />
        <div
          css={css`
              display: flex;
              padding-top: 30px;
              width: 140px;
              align-items: center;
              justify-content: space-around;

              @media (max-width: 800px) {
                padding-top: 10px;
                width: 120px;
              }
            `}
        >
          <img
            alt="organic"
            css={css`
                width: 55px;
                height: 55px;
                @media (max-width: 800px) {
                  width: 40px;
                  height: 40px;
                }
              `}
            src={Organic}
          />{" "}
          <img
            src={Kosher}
            alt="kosher"
            css={css`
                width: 65px;
                height: 65px;
                @media (max-width: 800px) {
                  width: 50px;
                  height: 50px;
                }
              `}
          />
        </div>
      </MiddleSection>
    </FreshInner>

  </FreshContainer>
);

const FreshInner = styled.div`
  height: 606px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 530px;
  text-align: center;
  margin-top: 120px;
  z-index: 100;

  @media (max-width: 500px) {
    align-items: flex-start;

    margin-top: 100px;
    padding-left: 20px;
  }
`;

const FreshContainer = styled.div`
  display: flex;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
  margin: 0 auto;
  background: #fef0e8;
  width: 100%;
  position: relative;
  @media (max-width: 500px) {
    height: 500px;
  }
`;

const HolidayContainer = styled.div`
  width: 100%;
  display: flex;

  z-index: 0;
  overflow: hidden;

  background-image: url(${WinterBG});
  background-size: cover;

  @media (max-width: 500px) {
    background-image: url(${MobileBG});
    background-size: contain;
  }
`;
