/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, lazy, Suspense } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { createBrowserHistory } from "history";
import _ from "lodash";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { updateUserId } from "../../redux/actions/Checkout";

import "../styles/fonts.css";

import LoginPage from "../LoginPage/Loadable";
import LoginPageDev from "../LoginPageDev/Loadable";
import TermsPage from "../TermsPage/Loadable";
import PrivacyPage from "../PrivacyPage/Loadable";
import HelpPage from "../HelpPage/Loadable";
import AccessibilityPage from "../AccessibilityPage/Loadable";
import JVPage from "../JVPage/Loadable";
import NewAccountPage from "../../pages/NewAccountPage";
import NewOrderPage from "../../pages/NewOrderPage";
import GrowthCheckoutNew from "../../pages/GrowthCheckoutNew";
import GrowthCheckout from "../../pages/GrowthCheckout";
import GrowthCheckoutB from "../../pages/GrowthCheckoutB";
import AddOn from "../../pages/AddOnConfirm";
import { Confirmation } from "../../pages/Confirmation";
import { JuliaSherman } from "../../pages/ChefSeries/JuliaSherman";
import { ChefSeries } from "../../pages/ChefSeries";
import { PerfectingTheBitePage } from "../../pages/PerfectingTheBitePage";
import { Houston } from "../../pages/Houston";
import { GiftCheckout } from "../../pages/GiftAFriend/checkout";
import { ByChloe } from "../../pages/ByChloe";
import Chicago from "../../pages/Chicago";
import Menu from "../../pages/Menu";
import { OneOffPurchase } from "../../pages/OneOffPurchase";
import { Christmas } from "../../pages/Christmas";
import { OneOffPurchaseConfirmation } from "../../pages/OneOffPurchase/sections/OneOffPurchaseConfirmation";

import { QuadsCheckout } from "../../pages/QuadsCheckout";

import { Reviews } from "../../pages/Reviews";
import { Pricing } from "../../pages/Pricing";
import { BLWpage } from "../../pages/BLWpage";

import { Referrals } from "../../pages/Referrals";
import NewNavBar from "../NewNavBar";
import { Milestones } from "../../pages/Milestones";
import { About } from "../../pages/About";
import BitesRoutes from "../../pages/BitesPage";
import BlendsPage from "../../pages/Blends";
import TotBoxPage from "../../pages/TotBox";
import Nav from "../../pages/NavBar";

import { NewSplashPage } from "../../pages/HomePage";
import { HomePageRefresh } from "../../pages/HomePageRefresh";

import { Footer } from "../../pages/NewFooter/index";

import ResetPasswordPage from "../ResetPasswordPage";
import CleanPage from "../CleanPage";
import RouteWrapper from "./RouteWrapper";
import { SnacksAddOn } from "../../styledcomponents/containers/SnacksAddOn";
import requireAuth from "../../utils/requireAuth";
import { logout } from "../../redux/actions/Auth/index";
import { PrivateRoute } from "./PrivateRoute";
import { ByChloeLandingPage } from "../../pages/ByChloe/LandingPage";
import { EduardoJordan } from "../../pages/EduardoJordan";
import { GiftCard } from "../../pages/GiftCard";
import { HeavyMetals } from "../../pages/HeavyMetals";
import tracker from "../../utils/tracker";

const history = createBrowserHistory({
  hashType: "noslash", // the default
  basename: "", // the default
});

const AppWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const LazyDashboardPromise = import("../../pages/Dashboard");

const LazyDashboard = lazy(() => LazyDashboardPromise);

class App extends React.Component {
  constructor(props) {
    super(props);

    const uid =
      requireAuth.getCookie("user_id") || requireAuth.getCookie("userId");
    let user = null;
    if (uid && uid !== undefined && Number(uid) > 0) {
      this.props.addUserToGlobalStore({ id: uid });
      user = { id: uid, expiresAt: "2021-12-12T00:00:00.000Z" };
      requireAuth.deleteCookie("user_id");
      requireAuth.deleteCookie("userId");
    }

    this.state = { checkout: false, jv: false, user };
  }

  componentDidMount() {}

  showCheckout = () => {
    this.setState({ checkout: true });
  };

  hideCheckout = () => {
    this.setState({ checkout: false });
  };

  showJv = () => {
    this.setState({ jv: true });
  };

  hideJv = () => {
    this.setState({ jv: false });
  };

  isAuthenticated = () =>
    requireAuth.authenticated(
      this.state.user ? this.state.user : this.props.user,
      "private"
    );

  RouteWithNavBar = (navbar, footer) => (
    <Layout
      history={history}
      user={this.state.user || this.props.user}
      isAuthenticated={this.isAuthenticated}
      showJv={this.showJv}
      hideJv={this.hideJv}
      hideCheckout={this.hideCheckout}
      showCheckout={this.showCheckout}
      checkout={this.state.checkout}
      navbar={navbar}
      footer={footer}
    />
  );

  RouteWithNoNavBar = (navbar, footer) => (
    <Layout
      history={history}
      user={this.state.user || this.props.user}
      isAuthenticated={this.isAuthenticated}
      showJv={this.showJv}
      hideJv={this.hideJv}
      hideCheckout={this.hideCheckout}
      showCheckout={this.showCheckout}
      checkout={this.state.checkout}
      navbar={navbar}
      footer={null}
    />
  );

  render() {
    const { user } = this.state || this.props;

    return (
      <AppWrapper>
        <Router history={history}>
          <Switch>
            <Route
              path="/profile"
              component={() => this.RouteWithNavBar(false)}
            />
            <Route
              path="/orders"
              component={() => this.RouteWithNavBar(false)}
            />
            <Route
              path="/bites"
              component={() => this.RouteWithNavBar(false)}
            />
            <Route
              path="/confirmation"
              component={() => this.RouteWithNavBar(false)}
            />

            <Route
              path="/add-on"
              component={() => this.RouteWithNoNavBar(false)}
            />

            <Route
              path="/checkout/:step"
              render={({ location, match }) =>
                this.isAuthenticated() ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <GrowthCheckout match={match} location={location} />
                )
              }
            />

            <Route
              path="/checkout_new/:step"
              render={({ location, match }) =>
                this.isAuthenticated() ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <GrowthCheckoutNew match={match} location={location} />
                )
              }
            />

            <Route
              path="/checkout_b/:step"
              render={({ location, match }) =>
                this.isAuthenticated() ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <GrowthCheckoutB match={match} location={location} />
                )
              }
            />
            <Route
              path="/checkout"
              render={({ location }) => (
                <Redirect to={`/checkout/none${location.search}`} />
              )}
            />
            <Route
              path="/checkout_new"
              render={({ location }) => (
                <Redirect to={`/checkout_new/none${location.search}`} />
              )}
            />
            <Route
              path="/checkout_b"
              render={({ location }) => (
                <Redirect to={`/checkout_b/none${location.search}`} />
              )}
            />
            <Route
              path="/growth/checkout"
              component={({ location }) => (
                <Redirect to={`/growth/checkout/none${location.search}`} />
              )}
            />

            <Route path="/new" component={() => this.RouteWithNavBar(false)} />

            <Route path="/holiday-cheer-challenge" component={Christmas} />

            <Route
              path="/dashboard"
              render={() => this.RouteWithNavBar(false, false)}
            />

            <Route
              path="/one-off-confirmation"
              render={({ location }) =>
                location.state === undefined ? (
                  <Redirect to="/one-off" />
                ) : (
                  <OneOffPurchaseConfirmation
                    history={history}
                    user_id={location.state.user_id}
                    selectedPlan={location.state.selectedPlan}
                    selectedQuantity={location.state.selectedQuantity}
                  />
                )
              }
            />

            <Route
              path="/gift-checkout/:stage?"
              render={({ match, history, location }) => (
                <GiftCheckout
                  match={match}
                  location={location}
                  history={history}
                />
              )}
            />

            {this.RouteWithNavBar(true)}
          </Switch>
        </Router>
      </AppWrapper>
    );
  }
}
const Layout = ({
  isAuthenticated,
  showJv,
  hideJv,
  showCheckout,
  hideCheckout,
  checkout,
  history,
  user,
  jv,
  navbar,
  footer = true,
}) => {
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [history.location.pathname]
  );

  useEffect(() => {
    tracker.event("Identify", { userId: user.id });
  }, []);
  return (
    <React.Fragment>
      {navbar && <Nav history={history} />}
      <NewAccountPage isAuthenticated={isAuthenticated} user={user} />
      <NewOrderPage
        isAuthenticated={isAuthenticated}
        user={user}
        location={history.location}
      />

      <Suspense fallback={<div>loading...</div>}>
        <Route
          path="/dashboard"
          render={() =>
            isAuthenticated() ? (
              <LazyDashboard user={user} history={history} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    forward_route: window.location.pathname,
                    search: window.location.search,
                  },
                }}
              />
            )
          }
        />
      </Suspense>
      <BitesRoutes isAuthenticated={isAuthenticated} user={user} />
      <Route
        exact
        path="/"
        component={NewSplashPage}
        location={history.location}
      />
      <Route
        path="/help"
        render={() => {
          window.location = "https://helloyumi.kustomer.help/";
          return "Redirecting...";
        }}
      />
      <Route
        path="/blog"
        render={() => {
          window.location = "https://blog.helloyumi.com/";
          return "Redirecting...";
        }}
      />
      <Route path="/account" render={() => <Redirect to="/orders" />} />
      <Route
        path="/yumi-finger-foods"
        render={() => <PerfectingTheBitePage />}
      />
      <PrivateRoute
        path="/confirmation"
        component={() => <Confirmation history={history} user={user} />}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/add-on"
        component={() => <AddOn history={history} user={user} />}
        isAuthenticated={isAuthenticated}
      />

      <Route path="/julia-sherman" render={() => <JuliaSherman />} />

      <Route path="/gifts" component={GiftCard} />
      <Route path="/login" component={LoginPage} />
      <Route path="/logindev" component={LoginPageDev} />
      <Route path="/reviews" component={Reviews} />
      <Route path="/chef-series" component={ChefSeries} />
      <Route
        path="/vip"
        component={() => {
          window.location =
            "https://hello-yumi.com/9-reasons-to-try-60-off-month/";
          return "Redirecting...";
        }}
      />
      <Route path="/menu" component={Menu} />
      <Route path="/by-chloe" component={ByChloe} />
      <Route path="/parachute" component={Chicago} />
      <Route path="/yumi-by-chloe" component={ByChloeLandingPage} />
      <Route
        path="/gift-card"
        render={({ match, location }) => (
          <GiftCard
            match={match}
            user={user}
            location={location}
            history={history}
          />
        )}
      />
      <Route path="/baby-led-weaning" component={BLWpage} />
      <Route path="/houston" component={Houston} />
      <Route path="/heavy-metals" component={HeavyMetals} />
      <Route path="/metals" render={() => <Redirect to="/heavy-metals" />} />
      <Route path="/salare" component={EduardoJordan} />
      <Route
        path="/try196"
        component={() => {
          window.location =
            "https://hello-yumi.com/9-reasons-to-try-60-off-month/";
          return "Redirecting...";
        }}
      />
      <Route
        path="/try197"
        component={() => {
          window.location =
            "https://hello-yumi.com/9-reasons-to-try-60-off-month/";
          return "Redirecting...";
        }}
      />
      <Route path="/quads-checkout" component={QuadsCheckout} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/homepage-refresh" component={HomePageRefresh} />
    
      <Route path="/one-off" component={OneOffPurchase} />
      <Route path="/milestones" component={Milestones} />
      <Route path="/accessibility" component={AccessibilityPage} />
      <Route path="/help" component={HelpPage} />
      <Route
        path="/jon-&-vinnys"
        render={() => <JVPage showJv={showJv} hideJv={hideJv} />}
      />
      <Route path="/up/confirmation" render={() => <SnacksAddOn />} />
      <Route path="/clean" component={CleanPage} />
      {footer && <Footer />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  addUserToGlobalStore: (payload) => dispatch(updateUserId(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(App);
