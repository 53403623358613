import spacetime from "spacetime";
import { getOrders } from "../../usecases/orders";

/*
 * Fetches orders and returns an order by id.
 */
const getOrderByIdService = async (id) => {
  const ORDERS_PAGE = 1;
  const ORDERS_PER_PAGE = 10;
  const ORDERS_SORT_BY = "desc";
  const formattedDate = spacetime
    .now("America/Los_Angeles")
    .format("yyyy-MM-dd");

  const orders = await getOrders(
    ORDERS_PAGE,
    ORDERS_PER_PAGE,
    ORDERS_SORT_BY,
    formattedDate
  );
  const specificOrder = orders.filter((order) => order.id === parseInt(id, 10));

  return specificOrder[0];
};

export default getOrderByIdService;
