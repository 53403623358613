import { request, put, post } from "../utils/request";
/**
 * Posts to a URL, returning a promise
 *
 * @param  {string} email The email we are trying to verify
 * @param  {string}  password the password hash we are trying to verify
 *
 * @return {boolean} indicated whether or not signin worked
 */
export const getOrders = async (
  page = 1,
  perPage = 5,
  sort = "asc",
  date = "1970-01-01"
) =>
  request(
    `${process.env.NODE_APP_PROTOCOL}://${process.env.NODE_APP_HOSTNAME}:${
      process.env.NODE_APP_PORT
    }${
      process.env.NODE_APP_PATHNAME
    }orders?page=${page}&per_page=${perPage}&sort=${sort}&date=${date}`
  );

export const updateRating = async (orderId, attributeId, { rating, meal_id }) =>
  put(`orders/${orderId}/attributes/${attributeId}/rating`, {
    rating,
    meal_id,
  });

export const updateOrderAttributes = async (orderId, attributes) =>
  put(`orders/${orderId}/attributes`, attributes);

export const updateOrderAttributesAndQuads = async (orderId, attributes) =>
  put(`orders/${orderId}/attributes`, attributes);

export const updateOrderAddOn = async (orderId, body) =>
  post(`orders/${orderId}/addon`, body);

export const updateOrderAddress = async (orderId, address) =>
  put(`orders/${orderId}/address`, address);

export const updateOrderPause = async (orderId, attributes) =>
  put(`orders/${orderId}`, attributes);

export const placeSnackOrder = async (attributes) =>
  post(`orders/snacks`, attributes);

export const placeSnackAddOnOrder = async (attributes) =>
  post(`checkout/snacks`, attributes);

const milestones = [
  [
    {
      quantity: 2,
      name: "6 Month Box",
      stage: 1,
      special_menu_id: 4,
      meal_name: "Mango",
      meal_id: 1360,
      menu_id: 15,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 59,
      ingredients: "",
      age: 6,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "6 Month Box",
      stage: 1,
      special_menu_id: 4,
      meal_name: "Lentils",
      meal_id: 500,
      menu_id: 14,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 55,
      ingredients: "Lentils",
      age: 6,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "6 Month Box",
      stage: 1,
      special_menu_id: 4,
      meal_name: "Broccoli",
      meal_id: 801,
      menu_id: 9,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 35,
      ingredients: "Broccoli",
      age: 6,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "6 Month Box",
      stage: 1,
      special_menu_id: 4,
      meal_name: "Apricot",
      meal_id: 1420,
      menu_id: 13,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 51,
      ingredients: "Apricot",
      age: 6,
      priority: 0,
      size: 7,
      type: "age",
    },
  ],
  [
    {
      quantity: 2,
      name: "9 Month Box",
      stage: 3,
      special_menu_id: 13,
      meal_name: "Mango Buckwheat Pudding",
      meal_id: 473,
      menu_id: 5,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 620,
      ingredients: "Mango, Sweet Potato, Coconut Milk, Buckwheat Meal",
      age: 9,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "9 Month Box",
      stage: 3,
      special_menu_id: 13,
      meal_name: "Broccoli Mash Bowl",
      meal_id: 466,
      menu_id: 2,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 608,
      ingredients: "Sweet Potato, Broccoli, Amaranth, Apricot",
      age: 9,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "9 Month Box",
      stage: 3,
      special_menu_id: 13,
      meal_name: "Raspberry Chia Pudding",
      meal_id: 477,
      menu_id: 4,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 616,
      ingredients: "Raspberries, Coconut Milk, Quinoa, Banana",
      age: 9,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "9 Month Box",
      stage: 1,
      special_menu_id: 13,
      meal_name: "Broccoli",
      meal_id: 801,
      menu_id: 9,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 638,
      ingredients: "Broccoli",
      age: 9,
      priority: 0,
      size: 7,
      type: "age",
    },
  ],
  [
    {
      quantity: 2,
      name: "12 Month Box",
      stage: 3,
      special_menu_id: 22,
      meal_name: "Mango Buckwheat Pudding",
      meal_id: 473,
      menu_id: 5,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 1223,
      ingredients: "Mango, Sweet Potato, Coconut Milk, Buckwheat Meal",
      age: 12,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "12 Month Box",
      stage: 3,
      special_menu_id: 22,
      meal_name: "Broccoli Mash Bowl",
      meal_id: 466,
      menu_id: 2,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 1211,
      ingredients: "Sweet Potato, Broccoli, Amaranth, Apricot",
      age: 12,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "12 Month Box",
      stage: 3,
      special_menu_id: 22,
      meal_name: "Raspberry Chia Pudding",
      meal_id: 477,
      menu_id: 4,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 1219,
      ingredients: "Raspberries, Coconut Milk, Quinoa, Banana",
      age: 12,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "12 Month Box",
      stage: 2,
      special_menu_id: 22,
      meal_name: "Triple Berry Pie",
      meal_id: 1241,
      menu_id: 3,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 1215,
      ingredients:
        "Japanese Sweet Potato, Coconut Milk, Blackberries, Raspberries",
      age: 12,
      priority: 0,
      size: 7,
      type: "age",
    },
  ],
  [
    {
      quantity: 2,
      name: "14 Month Box",
      stage: 3,
      special_menu_id: 55,
      meal_name: "Mango Buckwheat Pudding",
      meal_id: 473,
      menu_id: 5,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 2028,
      ingredients: "Mango, Sweet Potato, Coconut Milk, Buckwheat Meal",
      age: 14,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "14 Month Box",
      stage: 2,
      special_menu_id: 55,
      meal_name: "Baby Borscht Soup",
      meal_id: 485,
      menu_id: 6,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 2033,
      ingredients: "Beets, Flax, Coconut Milk, Spinach",
      age: 14,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 1,
      name: "14 Month Box",
      stage: 3,
      special_menu_id: 55,
      meal_name: "Mango Buckwheat Pudding",
      meal_id: 473,
      menu_id: 5,
      menu_number: 0,
      start_date: "8/5/19 0:00",
      meal_plan_id: 2028,
      ingredients: "Mango, Sweet Potato, Coconut Milk, Buckwheat Meal",
      age: 14,
      priority: 0,
      size: 7,
      type: "age",
    },
    {
      quantity: 2,
      name: "14 Month Box",
      stage: 3,
      special_menu_id: 55,
      meal_name: "Raspberry Chia Pudding",
      meal_id: 477,
      menu_id: 4,
      menu_number: 1,
      start_date: "8/5/19 0:00",
      meal_plan_id: 2024,
      ingredients: "Raspberries, Coconut Milk, Quinoa, Banana",
      age: 14,
      priority: 0,
      size: 7,
      type: "age",
    },
  ],
];

export const getMilestoneOrders = async () => milestones;
