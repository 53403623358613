import * as types from "../actions/types";
import requireAuth from "../../utils/requireAuth";

const userReducer = (
  state = {
    id: "",
    expiresAt: new Date("2002-01-01")
  },
  action
) => {
  switch (action.type) {
    case types.ADD_USER:
      return {
        ...state,
        id: action.payload.id,
        expiresAt: action.payload.expiresAt
      };

    case types.UPDATE_USER:
      return {
        ...state,
        expiresAt: action.payload.expiresAt
      };

    case types.CLEAR_USER:
      requireAuth.clearPayloadCookie();

      return {
        ...state,
        id: "",
        expiresAt: new Date("2002-01-01")
      };

    default:
      return state;
  }
};

export default userReducer;
