import React from "react";

import styled, { css } from "styled-components";
import Button from "../Button";

class StickyFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: true
    };
  }

  //
  // /*
  //  * TODO: Adjust scrolling threshold according to screen size
  //  */
  componentDidMount() {
    if (window.location.pathname === "/") {
      const fixed = window.scrollY > 560;
      if (fixed !== this.state.fixed) {
        this.setState({ fixed });
      }

      document.addEventListener("scroll", () => {
        const fixed = window.scrollY > 560;
        if (fixed !== this.state.fixed) {
          this.setState({ fixed });
        }
      });
    }
  }

  buttonStyle = () => {
    let style = "false";

    if (this.props.hasOwnProperty("text") === false) {
      style = "true";
    }

    return style;
  };

  render = () => (
    <Footer
      className={this.props.className}
      style={this.props.style}
      fixed={this.state.fixed}
    >
      <Button
        dark
        text={this.props.text ? this.props.text : "Get Started"}
        third={!this.props.text}
        onClick={this.props.onClick}
        className={this.props.terms ? "checkout-step-one" : "checkout-step-two"}
      />
      {this.props.terms ? (
        <span>
          By clicking above, you agree to our{" "}
          <a href="/terms" target="_blank">
            Terms Of Service
          </a>{" "}
          and consent to our{" "}
          <a href="/privacy" target="_blank">
            Privacy Policy.
          </a>
        </span>
      ) : (
        ""
      )}
    </Footer>
  );
}

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  box-shadow: 0 2px 20px 0 #e9e9e9;

  span {
    text-align: center;
    font-size: 14px;
    max-width: 300px;
    margin: 10px 0;
    font-family: Avenir;
  }

  ${props =>
    props.fixed &&
    css`
      position: fixed;
    `};
`;

export default StickyFooter;
